import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import CoreInput from "../../../core/CoreInput";
import CoreTooltip from "../../../core/CoreTooltip";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import { useNotification } from "../../../../context/useNotification";
import { ENTER_KEYS } from "../../../../types/constants";
import classNames from "classnames";

const useStyles = makeStyles({
  input: {
    height: "35px",
    marginLeft: "5px",
    paddingRight: "5px",
  },
  icon: {
    opacity: 0.4,
    marginRight: "10px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
  },
});

interface Props {
  name: string[];
  setFilters: (value: string[]) => void;
  extendedClassName?: string;
  fullWidth?: boolean;
  toggleDrawer?: () => void;
  placeholder?: string;
}

const SearchFilter: FC<Props> = observer(
  ({
    fullWidth = false,
    name,
    setFilters,
    extendedClassName,
    toggleDrawer,
    placeholder,
  }) => {
    const [input, setInput] = useState("");
    const { t } = useTranslation("flow");
    const notification = useNotification();
    const classes = useStyles();

    const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInput(event.target.value);
    };

    const addSearchFilter = () => {
      if (name?.some((filter) => filter === input)) {
        notification.error(t("notification_filterExists"));
        return;
      }
      if (input && input.trim() !== "") {
        setFilters([...(name || []), input]);
        setInput("");
      }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (ENTER_KEYS.includes(event.code)) {
        addSearchFilter();
        setInput("");
      }
    };

    return (
      <CoreInput
        fullWidth={fullWidth}
        onKeyPress={handleKeyPress}
        value={input}
        onChange={handleSearchInput}
        placeholder={placeholder || t("coreInput_searchFilter")}
        className={classNames(classes.input, extendedClassName)}
        startAdornment={<SearchIcon className={classes.icon} />}
        endAdornment={
          <span className={classes.buttonsContainer}>
            <CoreTooltip title={t("add_filter")}>
              <span>
                <IconButton onClick={addSearchFilter} disabled={!input.length}>
                  <AddIcon />
                </IconButton>
              </span>
            </CoreTooltip>
            {toggleDrawer && (
              <CoreTooltip title={t("advanced_filters_action_label")}>
                <span>
                  <IconButton onClick={toggleDrawer}>
                    <FilterAltOutlinedIcon fontSize="small" />
                  </IconButton>
                </span>
              </CoreTooltip>
            )}
          </span>
        }
      />
    );
  }
);

export default SearchFilter;
