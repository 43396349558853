import { gql } from "@apollo/client";

export interface MUTATION_UPDATE_BILLING_RESPONSE {
  updateBillingCustomer: boolean;
}

export interface MUTATION_CHANGE_SUBSCRIPTION_PLAN_RESPONSE {
  changeSubscriptionPlan: boolean;
}

export const UPDATE_BILLING_CUSTOMER = gql`
  mutation updateBillingCustomer(
    $name: String!
    $email: String!
    $phone: String!
    $billingInformation: JSON
    $companyInformation: JSON
  ) {
    updateBillingCustomer(
      name: $name
      email: $email
      phone: $phone
      billingInformation: $billingInformation
      companyInformation: $companyInformation
    )
  }
`;

export interface MUTATION_UPDATE_SUBSCRIPTION_PLAN_RESPONSE {
  updateSubscriptionPlan: boolean;
}

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updateSubscriptionPlan($planId: String!) {
    updateSubscriptionPlan(planId: $planId)
  }
`;

export const CHANGE_SUBSCRIPTION_PLAN = gql`
  mutation changeSubscriptionPlan(
    $planId: String!
    $paymentMethodId: String
    $billingData: JSON
  ) {
    changeSubscriptionPlan(
      planId: $planId
      paymentMethodId: $paymentMethodId
      billingData: $billingData
    )
  }
`;

export interface MUTATION_UPDATE_DEFAULT_PAYMENT_METHOD_RESPONSE {
  updateBillingCustomerPaymentMethod: boolean;
}

export const UPDATE_DEFAULT_PAYMENT_METHOD = gql`
  mutation updateBillingCustomerPaymentMethod($paymentMethodId: String!) {
    updateBillingCustomerPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`;

export interface MUTATION_SEND_SALES_EMAIL_RESPONSE {
  sendSalesEmail: boolean;
}

export const SEND_SALES_EMAIL = gql`
  mutation sendSalesEmail($from: String!, $subject: String!, $content: JSON) {
    sendSalesEmail(from: $from, subject: $subject, content: $content)
  }
`;

export interface MUTATION_CANCEL_SUBSCRIPTION_RESPONSE {
  cancelSubscription: boolean;
}

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription
  }
`;
