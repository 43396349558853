import { ChatGptMessage } from "../types/interfaces";
import moment from "moment";

export default class ChatGptHelper {
  static messageBoxToApi = (list: ChatGptMessage[]): ChatGptMessage[] => {
    let formattedList = [] as ChatGptMessage[];

    if (list && list.length > 0) {
      list.forEach((message) => {
        // Convert into single answer message type (list formed from regenerate action) to send to BE
        if (Array.isArray(message.answer)) {
          formattedList = [
            ...formattedList,
            ...message.answer.map((answer) => ({
              ...message,
              answer,
            })),
          ];
        } else {
          formattedList = [...formattedList, message];
        }
      });
    }

    return formattedList;
  };

  static setNewAnswer = (
    list: ChatGptMessage[],
    indexToChange: number,
    answerToSet: string
  ): ChatGptMessage[] => {
    return list.map((message, index) =>
      index === indexToChange
        ? {
            ...message,
            answer: Array.isArray(message.answer)
              ? [...message.answer, answerToSet]
              : [message.answer, answerToSet],
          }
        : message
    );
  };

  static preProcessText = (text: string) => {
    try {
      if (text?.includes("${link}")) {
        return text.replace("${link}", "click here");
      }

      return text;
    } catch {
      return text;
    }
  };

  static handleDownloadConversation = (messages: ChatGptMessage[]) => {
    const conversationText = messages?.reduce((acc, message) => {
      const userMessage = message.question ? `[User]: ${message.question}` : "";
      const botMessage = message.answer
        ? Array.isArray(message.answer)
          ? message.answer.map((ans) => `[ClariBot]: ${ans}`).join("\n")
          : `[ClariBot]: ${message.answer}`
        : "";
      return `${acc}${userMessage}\n${botMessage}\n`;
    }, "");
    const fileName = `${moment().format("DD-MM-YY-HH-mm")}-claribot-chat.txt`;

    const blob = new Blob([conversationText], { type: "text/plain" });

    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);

    link.click();
    window.URL.revokeObjectURL(link.href);
  };
}
