import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import DoneIcon from "@mui/icons-material/Done";

import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

import { AppSuspense } from "../../main/AppSuspense";
import { authRoutes } from "../../../configs/routes";
import CoreButton from "../../core/CoreButton";
import { useStores } from "../../../stores/StoresProvider";
import NotFound from "../errorPages/NotFound";
import Logo from "../../../icons/Logo";
import Languages from "../../main/sidebar/account/Languages";

const SuccessfullyRegister: React.FC = observer(() => {
  const theme = useTheme();
  const history = useHistory();
  const useStyle = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "0 10px",
    },
    logo: {
      width: "210px",
      height: "30px",
    },
    icon: {
      fontSize: "45px",
      color: theme.palette.highlight.main,
    },
    container: {
      justifyContent: "center",
      marginBottom: "7rem",
    },
    button: {
      marginTop: "20px",
    },
  });

  const { t, ready } = useTranslation("successfully-register");
  const classes = useStyle();
  const { landingStore } = useStores();

  const handleClick = () => {
    history.push(authRoutes.Login());
    landingStore.resetFields();
  };

  if (!ready) {
    return <AppSuspense />;
  }

  const successfullyRegister =
    landingStore.userRegistration.isCreateSuccessfully;

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>
      <Grid container className={classes.grid}>
        <Grid item>
          <Box className={classes.box}>
            {successfullyRegister ? (
              <>
                <Logo fullLogoClassName={classes.logo} />
                <DoneIcon className={classes.icon} />
                <Typography variant="body1">
                  {t("activatingMessage")}
                </Typography>
                <Typography variant="body1">{t("checkEmail")}</Typography>
                <CoreButton
                  onClick={handleClick}
                  variant="outlined"
                  className={classes.button}
                >
                  {t("login")}
                </CoreButton>
              </>
            ) : (
              <>
                <NotFound />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default SuccessfullyRegister;
