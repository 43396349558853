import React, { FC } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { InputProps } from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { FormField } from "../../../types/interfaces";
import { FormFieldProps } from "../../../types/types";
import CoreInput from "../../core/CoreInput";
import { UserStore } from "../../../stores/UserStore";
import { ENTER_KEYS } from "../../../types/constants";
import CoreTooltip from "../../core/CoreTooltip";

interface Props {
  translation: TFunction;
  field: FormField;
  userStore: UserStore;
  errorText?: string;
  otherProps?: FormFieldProps;
  searchByFields: () => void;
  tooltipTitle: string;
}

export const SearchInput: FC<Props> = observer(
  ({
    field,
    translation,
    userStore,
    errorText,
    searchByFields,
    otherProps,
    tooltipTitle,
  }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      userStore.setFiltersInput(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
      // Fetch data only on ENTER press
      if (ENTER_KEYS.includes(event.code)) {
        userStore.setUserInputFilter(userStore.filterSearchInput);
        searchByFields();
      }
    };

    const removeFilters = () => {
      userStore.setFiltersInput("");
      userStore.setUserInputFilter("");
      searchByFields();
    };

    return (
      <CoreInput
        placeholder={translation(field.translationKey)}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        errorText={errorText}
        size="small"
        value={userStore.filterSearchInput || ""}
        startAdornment={
          <SearchIcon sx={{ opacity: 0.4, marginRight: "10px" }} />
        }
        endAdornment={
          <span>
            {!userStore.userInputFilter && (
              <CoreTooltip title={translation(tooltipTitle)}>
                <span>
                  <IconButton
                    onClick={() => {
                      userStore.setUserInputFilter(userStore.filterSearchInput);
                      searchByFields();
                    }}
                    disabled={!userStore.filterSearchInput}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </CoreTooltip>
            )}
            {userStore.userInputFilter && (
              <IconButton onClick={removeFilters}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            )}
          </span>
        }
        {...(otherProps as InputProps)}
      />
    );
  }
);
