import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import CoreConfirmModal from "../../../core/CoreConfirmModal";
import {
  FLOW_DATA_TYPES_BACKEND,
  FLOW_DATA_TYPES_FRONTEND,
} from "../../../../types/constants";

interface Props {
  isOpen: boolean;
  data: {
    identifier: string;
    action: string;
  } | null;
  onClose: (refresh?: boolean) => void;
}

const ConfirmActionModal: FC<Props> = ({ isOpen, data, onClose }) => {
  const { id, type } = useParams<{
    id: string;
    type: FLOW_DATA_TYPES_FRONTEND;
  }>();
  const { t } = useTranslation("flow");
  const { flowStore } = useStores();
  const notification = useNotification();
  const [executeActionInProgress, setExecuteActionInProgress] = useState(false);

  const handleConfirm = useCallback(() => {
    setExecuteActionInProgress(true);
    flowStore
      .executeFlowDataTypeAction(
        id,
        data?.identifier || "",
        FLOW_DATA_TYPES_BACKEND[type] || "",
        data?.action || ""
      )
      .then(() => {
        onClose(true);
        notification.success(t("execute_flow_data_type_action_success"));
      })
      .catch((error: Error) => {
        onClose();
        notification.error(
          t(error?.message || "execute_flow_data_type_action_error")
        );
      })
      .finally(() => {
        setExecuteActionInProgress(false);
      });
  }, [
    flowStore,
    id,
    data?.identifier,
    data?.action,
    type,
    onClose,
    notification,
    t,
  ]);

  return (
    <CoreConfirmModal
      open={isOpen}
      onClose={() => onClose(false)}
      title={
        data
          ? t(`${data?.action || ""}ModalTitle`, {
              type: t(FLOW_DATA_TYPES_BACKEND[type]),
            })
          : ""
      }
      cancelButtonLabel={t("cancelModalButton")}
      confirmButtonLabel={t("confirmModalButton")}
      onCancel={() => onClose(false)}
      onConfirm={handleConfirm}
      isDisable={executeActionInProgress}
    />
  );
};

export default ConfirmActionModal;
