import React, { ReactNode, Component } from "react";
import ReactDOM from "react-dom";

interface PortalProps {
  node?: HTMLElement;
  children: ReactNode;
}

export default class Portal extends Component<PortalProps> {
  private defaultNode: HTMLElement | null = null;

  componentDidMount() {
    this.renderPortal();
  }

  componentDidUpdate(prevProps: PortalProps) {
    if (
      prevProps.children !== this.props.children ||
      prevProps.node !== this.props.node
    ) {
      this.renderPortal();
    }
  }

  componentWillUnmount() {
    if (this.defaultNode) {
      ReactDOM.unmountComponentAtNode(this.defaultNode);
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  renderPortal() {
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement("div");
      document.body.appendChild(this.defaultNode);
    }

    let children = this.props.children;

    if (typeof children === "function") {
      children = React.cloneElement(children as unknown as React.ReactElement);
    }

    ReactDOM.render(
      children as unknown as React.DOMElement<
        React.DOMAttributes<Element>,
        Element
      >,
      this.props.node || this.defaultNode
    );
  }

  render() {
    return null;
  }
}
