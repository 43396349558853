import React, { useMemo } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import CoreNotificationBox from "../../../core/CoreNotificationBox";
import { useStores } from "../../../../stores/StoresProvider";
import { NotificationTypes } from "../../../../context/useNotification";

interface Props {
  t: TFunction;
  flowName: string;
  loadingCheckName: boolean;
  nameError: string;
  isOverViewTab?: boolean;
}

const BoxInfoContent: React.FC<Props> = ({
  t,
  flowName,
  loadingCheckName,
  nameError,
  isOverViewTab = false,
}) => {
  const { flowSettingsStore } = useStores();

  const useStyles = makeStyles({
    box: {
      maxWidth: isOverViewTab ? "540px" : "100%",
    },
  });

  const classes = useStyles();

  const notificationType = useMemo(() => {
    if (loadingCheckName) return NotificationTypes.info;
    if (nameError) return NotificationTypes.error;
    return NotificationTypes.success;
  }, [loadingCheckName, nameError]);

  const description = useMemo(() => {
    if (loadingCheckName) return t("createFlow_checkingName");
    if (nameError) return t(nameError);
    return t("createFlow_nameAvailable");
  }, [loadingCheckName, nameError, t]);

  const renderEmpty = useMemo(() => {
    if (isOverViewTab) {
      return flowName === flowSettingsStore.flow?.name;
    } else {
      return flowName === "";
    }
  }, [flowName, flowSettingsStore.flow?.name, isOverViewTab]);

  if (renderEmpty) {
    return <></>;
  }

  return (
    <Box className={classes.box}>
      <CoreNotificationBox
        description={description}
        type={notificationType}
        isLoading={loadingCheckName}
      />
    </Box>
  );
};

export default BoxInfoContent;
