import React, { FC, useMemo, useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

import { useStores } from "../../../../stores/StoresProvider";

import CoreInput from "../../../core/CoreInput";
import CoreDrawer from "../../../core/CoreDrawer";
import {
  FIELD_MAX_LENGTH,
  User,
  FlowInterface,
} from "../../../../types/interfaces";
import UserSetup from "../../addNewFlow/tabs/access/drawer/UserSetup";
import { useNotification } from "../../../../context/useNotification";
import CoreButton from "../../../core/CoreButton";
import { appRoutes } from "../../../../configs/routes";
import BoxInfoContent from "./BoxInfoContent";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  forDuplicateFlow?: boolean;
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  loadingContainer: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
    alignItems: "center",
    marginTop: "20px",
  },
  icon: {
    opacity: 0.4,
    marginRight: "10px",
  },
  input: {
    width: "100%",
  },
  confirmButton: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    padding: "15px 30px",
  },
  cardListStyle: {
    maxHeight: "665px",
    overflowY: "auto",
    marginTop: "10px",
    paddingTop: "20px",
  },
  box: {
    maxWidth: "540px",
  },
  infoInnerBox: {
    maxWidth: "540px",
  },
  searchBox: {
    paddingTop: "20px",
  },
});

const FlowSettingsDrawer: FC<Props> = observer(
  ({ onClose, isOpen, forDuplicateFlow = false }) => {
    const { userStore, flowSettingsStore, mainStore } = useStores();
    const notification = useNotification();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation("addFlow");
    const [userFilter, setUserFilter] = useState("");
    const [flowName, setFlowName] = useState<string>("");
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [loadingCreateUpdateFlow, setLoadingCreateUpdateFlow] =
      useState(false);
    const [loadingCheckName, setLoadingCheckName] = useState(false);
    const [nameError, setNameError] = useState("");
    const timer = 1000;

    const classes = useStyles();

    const users = useMemo(() => userStore.users, [userStore.users]);

    const handleRestrictInput = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setUserFilter(event.target.value);
    };

    const filteredUsers = useMemo(
      () =>
        users.filter((user) => {
          if (user.scope === "owner" || user.scope === "Owner") return;
          if (user.id === userStore.user?.id) return;
          if (userFilter) {
            const filteredByEmail = user.email.toLowerCase();
            const filteredByFirstName = user.first_name.toLowerCase();
            const filteredByLastName = user.last_name.toLowerCase();

            if (
              filteredByEmail.includes(userFilter.toLowerCase()) ||
              filteredByFirstName.includes(userFilter.toLowerCase()) ||
              filteredByLastName.includes(userFilter.toLowerCase())
            ) {
              return user;
            }
            return;
          }
          return user;
        }),
      [userFilter, userStore.user?.id, users]
    );

    useEffect(() => {
      if (!isOpen) return;

      if (flowSettingsStore.duplicateFlowConfig) {
        setSelectedUsers(
          userStore.users?.filter(
            (user) =>
              flowSettingsStore.duplicateFlowConfig?.access?.[user?.id] ===
              "restricted"
          ) || []
        );
        setFlowName(flowSettingsStore.duplicateFlowConfig?.name || "");
        return;
      }
      setSelectedUsers([]);
    }, [isOpen, flowSettingsStore.duplicateFlowConfig, userStore.users]);

    useEffect(() => {
      if (isOpen)
        userStore
          .getAllUsers()
          .catch((error: Error) =>
            notification.error(t(error?.message ?? "get_users_error"))
          );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
      if (!isOpen) return;
      setLoadingCheckName(true);

      const delay = setTimeout(() => {
        if (flowName) {
          checkName(flowName?.trim() || "");
        } else if (flowName?.length < 3) {
          setLoadingCheckName(false);
          setNameError("createFlow_nameTooShort");
        } else {
          setLoadingCheckName(false);
          setNameError("");
        }
      }, timer);

      return () => {
        clearTimeout(delay);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowName, isOpen]);

    const checkName = (name: string) => {
      if (name.length < 3) {
        setNameError("createFlow_nameTooShort");
        setLoadingCheckName(false);
        return;
      }

      setLoadingCheckName(true);
      setNameError("");
      flowSettingsStore
        .checkFlowName(name)
        .then((res) => {
          if (!res) {
            setNameError("createFlow_nameNotAvailable");
          }
        })
        .catch((error: Error) => {
          setNameError(error?.message || "createFlow_nameNotAvailable");
        })
        .finally(() => {
          setLoadingCheckName(false);
        });
    };

    const handleClick = (user: User) => {
      if (selectedUsers.includes(user)) {
        setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
      } else {
        setSelectedUsers([...selectedUsers, user]);
      }
    };

    const handleChangeFlowName = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFlowName(event.target.value);
    };

    const createFlow = (flowConfig: FlowInterface) => {
      flowSettingsStore
        .createFlow(flowConfig)
        .then((identifier) => {
          history.push(appRoutes.FlowSettings(identifier));
          mainStore.collapseSidebar();
          notification.success(t("createFlowSuccess"));
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "createFlowError"));
        })
        .finally(() => {
          setLoadingCreateUpdateFlow(false);
        });
    };

    const duplicateFlow = (flowConfig: FlowInterface) => {
      flowSettingsStore
        .duplicateFlow(id, flowConfig)
        .then((identifier) => {
          notification.success(t("duplicateFlowSuccess"));
          history.push(appRoutes.FlowSettings(identifier));
          mainStore.collapseSidebar();
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "duplicateFlowError"));
        })
        .finally(() => {
          setLoadingCreateUpdateFlow(false);
        });
    };

    const handleCreateOrUpdateFlow = useCallback(() => {
      let access: Record<string, string> | null = null;

      if (selectedUsers && selectedUsers.length > 0) {
        access = selectedUsers?.reduce((acc, user) => {
          acc[user.id.toString()] = "restricted";
          return acc;
        }, {} as Record<string, string>);
      }

      setLoadingCreateUpdateFlow(true);

      const flowConfig = { name: flowName, access: access } as FlowInterface;

      if (forDuplicateFlow) {
        duplicateFlow(flowConfig);
      } else {
        createFlow(flowConfig);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUsers, flowName, forDuplicateFlow]);

    const handleOnClose = () => {
      setFlowName("");
      setNameError("");
      setSelectedUsers([]);
      setUserFilter("");
      setLoadingCheckName(false);
      setLoadingCreateUpdateFlow(false);
      onClose();
    };

    return (
      <CoreDrawer
        isOpen={isOpen}
        onClose={handleOnClose}
        title={forDuplicateFlow ? t("duplicateFlow") : t("createFlow")}
      >
        <Box className={classes.container}>
          <CoreInput
            fullWidth
            title={t("flowNameTitle")}
            placeholder={t("createFlow_namePlaceholder")}
            value={flowName || ""}
            name="name"
            onChange={handleChangeFlowName}
            disabled={loadingCreateUpdateFlow}
            isMandatory
            description={t("createFlow_nameDescription")}
            inputProps={{
              maxLength: FIELD_MAX_LENGTH.NAME,
            }}
          />

          <BoxInfoContent
            t={t}
            flowName={flowName}
            loadingCheckName={loadingCheckName}
            nameError={nameError}
          />

          <Box className={classes.searchBox}>
            <CoreInput
              value={userFilter}
              onChange={handleRestrictInput}
              title={t("restrictUsersDescription")}
              placeholder={t("searchInput_users")}
              startAdornment={<SearchIcon className={classes.icon} />}
              fullWidth
              size="small"
              className={classes.input}
            />
          </Box>

          {!userStore.loadingUsers && filteredUsers?.length === 0 && (
            <Box className={classes.loadingContainer}>
              <Typography>{t("noUsers")}</Typography>
            </Box>
          )}

          <Box className={classes.cardListStyle}>
            {!userStore.loadingUsers ? (
              filteredUsers.map((user, index) => {
                return (
                  <UserSetup
                    key={index}
                    user={user}
                    users={selectedUsers}
                    handleClick={handleClick}
                    isLoading={loadingCreateUpdateFlow}
                  />
                );
              })
            ) : (
              <Box className={classes.loadingContainer}>
                <CircularProgress size="30px" color="inherit" />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.confirmButton}>
          <CoreButton
            variant="contained"
            onClick={handleCreateOrUpdateFlow}
            disabled={loadingCreateUpdateFlow || !flowName}
            isLoading={loadingCreateUpdateFlow}
          >
            {t("confirm")}
          </CoreButton>
        </Box>
      </CoreDrawer>
    );
  }
);

export default FlowSettingsDrawer;
