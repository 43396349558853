import React, { FC, useMemo } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { Box, useTheme } from "@mui/material";

import DiagramNodeHandle from "./DiagramNodeHandle";
import { useStores } from "../../../../../stores/StoresProvider";
import { NODE_HANDLE_TYPES, NODE_TYPES } from "../../../../../types/constants";
import DiagramNodeDeleteActions from "./DiagramNodeDeleteActions";
import NodesHelper from "../helper/nodesHelper";

interface NodeData {
  type: NODE_TYPES;
  // FIXME: Check if groupId is neeeded here
  onHandleClick?: (identifier: string, groupId?: string) => void;
  value: string;
  target?: string;
  source?: string;
  identifier: string;
}

interface NodeProps {
  data: NodeData;
}

export const DiagramStartNode: FC<NodeProps> = observer(({ data }) => {
  const { flowSettingsStore } = useStores();
  const reactFlow = useReactFlow();
  const theme = useTheme();

  const classes = makeStyles({
    editIcon: {
      fontSize: "8px",
      color: theme.palette.highlight.main,
    },
    icon: {
      fontSize: "14px",
    },
    typography: {
      minWidth: "40px",
      maxWidth: "70px",
      marginLeft: "5px",
      fontSize: "12px",
      textWrap: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    editButton: {
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderTop: "none",
      borderRight: "none",
      width: "3px",
      height: "3px",
      borderRadius: "0",
      borderBottomLeftRadius: "2px",
    },
    buttonBox: {
      visibility:
        flowSettingsStore.nodeAnchor?.id === data?.identifier
          ? "visible"
          : "hidden",
      position: "absolute",
      right: 0,
      top: 0,
    },
    textBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "5px",
      minWidth: "60px",
      maxWidth: "90px",
    },
    mainBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    diagramStartNode: {
      display: "flex",
      alignItems: "center",
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderRadius: "2px",
      borderLeft: `2px solid ${theme.palette.neutral.dark}`,
      height: "inherit",
      position: "relative",
    },
    diagramNodeHover: {
      "&:hover $buttonBox": {
        visibility: "visible",
      },
    },
  })();

  const { value, identifier } = data;

  const outgoing = useMemo(
    () => NodesHelper.findOutgoing(reactFlow, identifier),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowSettingsStore.layoutingNeeded]
  );

  return (
    <>
      <Box
        className={`${classes.diagramStartNode} ${classes.diagramNodeHover}`}
      >
        <Box className={classes.mainBox}>
          <Box className={classes.textBox}>
            <SystemUpdateAltIcon className={classes.icon} />
            <p className="nodrag" style={{ display: "flex" }}>
              <span className={classes.typography}>{value}</span>
            </p>
          </Box>

          <Box className={classes.buttonBox}>
            {flowSettingsStore.isDiagramEditable && (
              <div className="nodrag" style={{ marginTop: "-5px" }}>
                <DiagramNodeDeleteActions
                  identifier={identifier}
                  hasTarget={outgoing?.length > 0}
                  isStartNode={true}
                />
              </div>
            )}
          </Box>
        </Box>
      </Box>

      <DiagramNodeHandle
        type={NODE_HANDLE_TYPES.source}
        onHandleClick={() => {
          flowSettingsStore.setParentNodeId(identifier);
        }}
        hidden={outgoing.length > 0 || !flowSettingsStore.isDiagramEditable}
        identifier={identifier}
      />
    </>
  );
});
