import React, { FC, useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Divider } from "@mui/material";
import CoreButton from "../../../../core/CoreButton";
import CoreNotificationBox from "../../../../core/CoreNotificationBox";
import {
  NotificationTypes,
  useNotification,
} from "../../../../../context/useNotification";
import {
  RejectionInterface,
  FlowInterface,
} from "../../../../../types/interfaces";

import CoreConfirmModal from "../../../../core/CoreConfirmModal";
import ReasonsTable from "../../../addNewFlow/tabs/fieldsSetup/rejectReason/components/ReasonsTable";
import AddReasonDrawer from "../../../addNewFlow/tabs/fieldsSetup/rejectReason/components/AddReasonDrawer";
import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import CoreTabContainer from "../../../../core/CoreTabContainer";
import { AppSuspense } from "../../../../main/AppSuspense";

const useStyles = makeStyles({
  button: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonBox: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  mainContainer: {
    maxWidth: "800px",
    minHeight: "90px",
  },
});

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
}

const RejectReasonsTab: FC<Props> = observer(({ t, flowSettingsStore }) => {
  const notification = useNotification();

  const [flowRejectReasons, setFlowRejectReasons] = useState<
    RejectionInterface[]
  >([]);
  const [currentReason, setCurrentReason] = useState<
    RejectionInterface | undefined
  >(undefined);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setFlowRejectReasons(flowSettingsStore.flow?.rejectReasons || []);
  }, [flowSettingsStore.flow?.rejectReasons]);

  const handleClickEdit = useCallback(
    (args: string[]) => {
      const [code] = args;
      setCurrentReason(flowRejectReasons.find((item) => item.code === code));
      setIsDrawerOpen(true);
    },
    [flowRejectReasons]
  );

  const handleClickDelete = useCallback(
    (args: string[]) => {
      const [code] = args;
      setCurrentReason(flowRejectReasons.find((item) => item.code === code));
      setIsConfirmModalOpen(true);
    },
    [flowRejectReasons]
  );

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setCurrentReason(undefined);
  };

  const handleCloseModal = () => {
    setIsConfirmModalOpen(false);
    setCurrentReason(undefined);
  };

  const handleDelete = (reason?: RejectionInterface) => {
    const updatedRejectReasons = flowRejectReasons.filter(
      (item) => item.code !== reason?.code
    );

    flowSettingsStore
      .updateFlow({
        rejectReasons: updatedRejectReasons,
      } as FlowInterface)
      .then(() => {
        handleCloseModal();
        notification.success(t("updateFlowSuccess"));
        flowSettingsStore.triggerRefetchFlowSettings(true);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "updateFlowError"));
      });
  };

  return (
    <CoreTabContainer t={t} title={t(flowSettingsStore.selectedTab || "")}>
      {flowSettingsStore.loadingFlowSettings ? (
        <AppSuspense />
      ) : (
        <Box className={classes.mainContainer}>
          <CoreNotificationBox
            description={t("rejection_description")}
            type={NotificationTypes.info}
          >
            <Box className={classes.buttonBox}>
              <Divider orientation="vertical" />

              <CoreButton
                variant="contained"
                onClick={() => setIsDrawerOpen(true)}
                className={classes.button}
              >
                {t(`rejection_add`)}
              </CoreButton>
            </Box>
          </CoreNotificationBox>

          <ReasonsTable
            t={t}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
          />

          <AddReasonDrawer
            t={t}
            isOpen={isDrawerOpen}
            onClose={handleCloseDrawer}
            currentReason={currentReason}
            flowRejectReasons={flowRejectReasons}
          />

          <CoreConfirmModal
            open={isConfirmModalOpen}
            onClose={handleCloseModal}
            title={
              currentReason?.message
                ? t("modal_title", {
                    modalTitle: t(currentReason?.message),
                  })
                : ""
            }
            subtitle={t("modal_subtitle")}
            onCancel={handleCloseModal}
            onConfirm={() => handleDelete(currentReason)}
            cancelButtonLabel={t("cancelButtonLabel")}
            confirmButtonLabel={t("confirmButtonLabel")}
            isDisable={flowSettingsStore.loadingUpdateFlow}
          />
        </Box>
      )}
    </CoreTabContainer>
  );
});

export default RejectReasonsTab;
