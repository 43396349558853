import { gql } from "@apollo/client";
import { QADocument, QADocuments } from "../../types/interfaces";

export const GET_QA_SAMPLE = gql`
  query getQASample($page: Int!, $pageSize: Int!, $filters: QAFiltersInput) {
    getQASample(page: $page, pageSize: $pageSize, filters: $filters) {
      documents {
        name
        pageCount
        identifier
        createdAt
        updatedAt
        status
        pageUri
        flowName
      }
      totalDocuments
      amountOfPages
      currentPage
    }
  }
`;

export const GET_QA_DOCUMENTS_COUNT = gql`
  query getQADocumentsCount {
    getQADocumentsCount
  }
`;

export const GET_QA_DOCUMENTS = gql`
  query getQADocuments($page: Int!, $pageSize: Int!) {
    getQADocuments(page: $page, pageSize: $pageSize) {
      documents {
        flowName
        flowIdentifier
        name
        pageCount
        identifier
        createdAt
        updatedAt
        status
        pageUri
      }
      totalDocuments
      amountOfPages
      currentPage
    }
  }
`;

export interface QUERY_QA_SAMPLE_RESPONSE {
  getQASample: {
    documents: QADocument[];
    totalDocuments: number;
    amountOfPages: number;
    currentPage: number;
  };
}

export interface QUERY_QA_DOCUMENTS_COUNT_RESPONSE {
  getQADocumentsCount: number;
}

export interface QUERY_QA_DOCUMENTS_RESPONSE {
  getQADocuments: QADocuments;
}
