import { RootStore } from "./StoresProvider";
import { makeAutoObservable } from "mobx";
import { ApolloClient } from "@apollo/client";
import _isEmpty from "lodash/isEmpty";

import {
  GET_CONNECTIONS,
  GET_CONNECTION_CONFIG,
  GET_CONNECTION_FIELDS,
} from "./queries/storage";

import {
  ConnectionField,
  FormData,
  PreviewData,
  PreviewDataSource,
  ConnectionType,
} from "../types/interfaces";
import { GET_PREVIEW } from "./queries/preview";
import { STORAGE_DEFAULT_FIELDS } from "../types/constants";

type RepositoryFieldsType = {
  loadedFieldsId: string | null;
  fields: ConnectionField[] | [];
  metadata: FormData;
};
export class StorageStore {
  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  // Repository extra fields
  repositoryFields: RepositoryFieldsType = {
    loadedFieldsId: null,
    fields: [],
    metadata: {},
  };

  // New connection
  mandatoryFields = {
    default: [
      STORAGE_DEFAULT_FIELDS.name,
      STORAGE_DEFAULT_FIELDS.type,
    ] as string[],
    dynamic: [] as string[],
  };
  connectionType = "";
  dynamicFields: FormData = {};
  isFromUpload = false;

  setIsFromUpload = (value: boolean) => {
    this.isFromUpload = value;
  };

  setConnectionType = (type: string) => {
    this.connectionType = type;
  };

  resetRepositoryFields = () => {
    this.repositoryFields = { loadedFieldsId: null, fields: [], metadata: {} };
  };

  setRepositoryFields = (newFields: RepositoryFieldsType) => {
    this.repositoryFields = newFields;
  };

  setDynamicFields = (values: FormData) => {
    if (_isEmpty(values)) {
      this.dynamicFields = values;
      return;
    }

    this.dynamicFields = { ...this.dynamicFields, ...values };
  };

  setMandatoryFields = (
    key: STORAGE_DEFAULT_FIELDS.default | STORAGE_DEFAULT_FIELDS.dynamic,
    fields: string[]
  ) => {
    this.mandatoryFields[key] = fields;
  };

  clearFields = () => {
    // New connection
    this.mandatoryFields.dynamic = [];

    this.connectionType = "";
    this.dynamicFields = {};
  };

  getConnectionTypes = async (client: ApolloClient<unknown>) => {
    try {
      const {
        data: { getConnectionTypes },
        errors,
      } = await client.query<{
        getConnectionTypes: { conn_types: ConnectionType[] };
      }>({
        query: GET_CONNECTIONS,
      });

      if (!getConnectionTypes || (errors && errors?.[0])) {
        throw new Error();
      }

      return getConnectionTypes.conn_types;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  getConnectionConfig = async (client: ApolloClient<unknown>, id: string) => {
    try {
      const {
        data: { getConnectionConfig },
        errors,
      } = await client.query<{
        getConnectionConfig: {
          config: ConnectionField;
        };
      }>({
        query: GET_CONNECTION_CONFIG,
        variables: { id },
      });

      if (!getConnectionConfig || (errors && errors?.[0])) {
        throw new Error();
      }

      return getConnectionConfig.config;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  getConnectionFields = async (type: string) => {
    try {
      const {
        data: { getConnectionFields },
        errors,
      } = await this.root.client.query<{
        getConnectionFields: ConnectionField[];
      }>({
        query: GET_CONNECTION_FIELDS,
        variables: { type },
      });

      if (!getConnectionFields || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      return getConnectionFields;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  getPreview = async (
    client: ApolloClient<unknown>,
    config: PreviewDataSource
  ) => {
    try {
      const {
        data: { getPreview },
        errors,
      } = await client.query<{ getPreview: PreviewData }>({
        query: GET_PREVIEW,
        variables: { config },
      });

      if (!getPreview || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      return getPreview;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
