import React, { useState, useMemo } from "react";
import { useReactFlow } from "reactflow";
import { TFunction } from "i18next";

import {
  CircularProgress,
  Typography,
  Box,
  ListItem,
  ListItemButton,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CoreInput from "../../../core/CoreInput";
import CoreTooltip from "../../../core/CoreTooltip";
import CoreSelect from "../../../core/CoreSelect";
import DiagramNodeIcon from "./diagramBuilder/DiagramNodeIcon";
import { AssetType, FlowNode } from "../../../../types/interfaces";
import { useStores } from "../../../../stores/StoresProvider";

interface Props {
  t: TFunction;
  onSelect: (key: string) => void;
  loading: boolean;
}

const NodesListDisplay: React.FC<Props> = ({ t, onSelect, loading }) => {
  const { flowSettingsStore } = useStores();
  const reactFlow = useReactFlow();
  const theme = useTheme();

  const [searchFilter, setSearchFilter] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const classes = makeStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "15px",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: "25px",
    },
    optionsContainer: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      marginTop: "10px",
    },
    noSetups: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      padding: "20px",
    },
    search: {
      width: "100%",
    },
    card: {
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      border: "1px solid rgba(217,217,217,0.2)",
      minHeight: "80px",
      width: "100%",

      overflow: "hidden",
      "&:hover": {
        border: `1px solid`,
        cursor: "pointer",
      },
    },
    highlight: {
      color: theme.palette.highlight.main,
      width: "100%",
      overflow: "hidden",
      overflowWrap: "break-word",
      wordBreak: "break-all",
    },
    tooltip: {
      margin: "0px",
      padding: "16px 0 0 0",
    },
    iconTextBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: 0,
    },
    iconStyle: {
      fontSize: "25px",
    },
    svgIconStyle: {
      width: "25px",
    },
    iconAndText: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      gap: "10px",
    },
    text: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    filterBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "16px",
      gap: "10px",
    },
    searchBox: {
      minWidth: "70%",
    },
    category: {
      color: theme.palette.text.disabled,
      paddingTop: "10px",
      fontSize: "16px",
    },
    listItem: {
      padding: "8px 0px",
    },
    selectStyle: {
      ".MuiSelect-select": {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    name: {
      width: "100%",
    },
    selectPlaceholder: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })();

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value);
  };

  const diagramNodes = useMemo(
    () => reactFlow.getNodes() as FlowNode[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlow, flowSettingsStore.layoutingNeeded]
  );

  const filteredOptions = useMemo(() => {
    const nodesList = flowSettingsStore.nodesList;
    let filteredByCategory = nodesList
      ?.filter(
        (item) =>
          selectedCategories?.length === 0 ||
          selectedCategories?.includes(item?.category as string)
      )
      ?.filter((item) =>
        item.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())
      );

    // Node which will be linked by current node
    const currentParent = diagramNodes.find(
      (node) => node.id === flowSettingsStore.parentNodeId
    );
    // Parent group
    let parentNode = currentParent;

    // If current parent node has parentId, then we are in a group (extract group parent)
    if (currentParent?.parentId) {
      parentNode = diagramNodes.find(
        (node) => node.id === parentNode?.parentId
      );
    }

    // Extract latest configuration from fetched list (this in order to support old diagrams which won't contain the nodesFilter property)
    const nodeConfig = nodesList.find((item) => item.key === parentNode?.key);

    // We are within a conditional or loop
    // Node contains list of nodes to exclude from group
    // Filter nodes list to display in drawer
    if (
      (flowSettingsStore.conditionalPlaceholderFocus ||
        currentParent?.parentId) &&
      nodeConfig?.nodesFilter?.exclude &&
      nodeConfig?.nodesFilter?.exclude?.length > 0
    ) {
      filteredByCategory = filteredByCategory.filter(
        (node) => !nodeConfig?.nodesFilter?.exclude?.includes(node.key)
      );
    }

    return filteredByCategory;
  }, [searchFilter, flowSettingsStore, selectedCategories, diagramNodes]);

  const nodeCategories = useMemo(() => {
    return flowSettingsStore.nodeCategories;
  }, [flowSettingsStore.nodeCategories]);

  const getCategoryLabel = (key: string) => {
    return nodeCategories?.find((category) => category?.key === key)?.label;
  };

  const handleRenderSelect = (
    selected: unknown,
    placeholder: string
  ): React.ReactNode => {
    if (!selected || (selected as string[])?.length === 0) {
      return (
        <Typography className={classes.selectPlaceholder}>
          {t(placeholder)}
        </Typography>
      );
    }

    return (selected as string[])
      ?.map((item) => getCategoryLabel(item))
      ?.join(", ");
  };

  const groupedOptions = useMemo(
    () =>
      filteredOptions?.reduce<Record<string, AssetType[]>>((acc, item) => {
        const category = item?.category || "noCategory";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
        return acc;
      }, {}),
    [filteredOptions]
  );

  return (
    <Box className={classes.container}>
      <Typography>{t("nodeSelectSubtitle")}</Typography>

      <Box className={classes.filterBox}>
        <Box className={classes.searchBox}>
          <CoreInput
            onChange={onSearchChange}
            className={classes.search}
            placeholder={t("searchNode")}
          />
        </Box>

        <CoreSelect
          options={nodeCategories}
          value={selectedCategories}
          hasCheckbox={true}
          renderValue={(selected) =>
            handleRenderSelect(selected, "filterByCategory")
          }
          isMultipleSelect
          onChange={(event: SelectChangeEvent<unknown>) =>
            setSelectedCategories(event.target.value as string[])
          }
          inputStyle={classes.selectStyle}
        />
      </Box>

      {loading ? (
        <Box className={classes.loadingContainer}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        <Box className={classes.optionsContainer}>
          {groupedOptions && Object?.keys(groupedOptions)?.length > 0 ? (
            Object.keys(groupedOptions)
              ?.sort()
              ?.map((category) => (
                <Box key={category}>
                  <Typography className={classes.category}>
                    {getCategoryLabel(category)}
                  </Typography>
                  {groupedOptions[category] &&
                    groupedOptions[category]?.length > 0 &&
                    groupedOptions[category]?.map(
                      (item) =>
                        item && (
                          <CoreTooltip
                            key={item?.key}
                            title={item?.description}
                            placement="bottom"
                            className={classes.tooltip}
                            arrow
                          >
                            <ListItem
                              key={item.key}
                              className={classes.listItem}
                            >
                              <ListItemButton
                                className={classes.card}
                                onClick={() => onSelect(item.key)}
                              >
                                <Box className={classes.iconAndText}>
                                  <DiagramNodeIcon
                                    nodeKey={item.key}
                                    textBoxStyle={classes.iconTextBox}
                                    iconStyle={classes.iconStyle}
                                    svgIconStyle={classes.svgIconStyle}
                                  />
                                  <Box className={classes.text}>
                                    <Typography
                                      variant="h6"
                                      className={classes.name}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Typography className={classes.highlight}>
                                      {item.description}
                                    </Typography>
                                  </Box>
                                </Box>
                              </ListItemButton>
                            </ListItem>
                          </CoreTooltip>
                        )
                    )}
                </Box>
              ))
          ) : (
            <Box className={classes.noSetups}>
              <Typography>{t("noNodes")}</Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NodesListDisplay;
