import React from "react";

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

const DropboxIcon: React.FC<Props> = ({ fill, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "24"}
    height={height || "24"}
  >
    <path
      fill={fill}
      d="m3 6.2l5 3.19l5-3.19L8 3zm10 0l5 3.19l5-3.19L18 3zM3 12.55l5 3.19l5-3.19l-5-3.2zm15-3.2l-5 3.2l5 3.19l5-3.19zM8.03 16.8l5.01 3.2l5-3.2l-5-3.19z"
    />
  </svg>
);

export default DropboxIcon;
