import React, { memo, Suspense, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { useTheme, Box, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import { AppSuspense } from "../../../../main/AppSuspense";
import { backendRoutes } from "../../../../../configs/backendRoutes";
import { APP_IDS, IMAGE_SIZE } from "../../../../../types/constants";
import { CoreImage } from "../../../../core/CoreImage";
import RightClickMenu from "./components/RightClickMenu";
import { useNotification } from "../../../../../context/useNotification";
import { useStores } from "../../../../../stores/StoresProvider";

const ThumbnailPreviewer: React.FC = observer(() => {
  const { t } = useTranslation("document-validate");
  const notification = useNotification();
  const theme = useTheme();
  const { search } = useLocation<{ search: string }>();
  const history = useHistory();
  const { documentStore } = useStores();

  const [draggingPage, setDraggingPage] = useState<string | null>(null);
  const [thumbPanelHeight, setThumbPanelHeight] = useState("calc(100vh)");

  const documentRotation = documentStore.documentRotation;
  const selectedPages = documentStore.selectedPages;

  const setSelectedPages = (newPages: string[]) => {
    documentStore.setSelectedPages(newPages);
  };

  useEffect(() => {
    const topBar = document
      .getElementById(APP_IDS.appTopBar)
      ?.getBoundingClientRect();

    const toolBar = document
      .getElementById(APP_IDS.canvasToolbar)
      ?.getBoundingClientRect();

    const pageToolbar = document
      .getElementById(APP_IDS.appToolbar)
      ?.getBoundingClientRect();

    setThumbPanelHeight(
      `calc(100vh - ${topBar?.height ?? 0}px - ${toolBar?.height ?? 0}px - ${
        pageToolbar?.height ?? 0
      }px)`
    );
  }, []);

  //state for right click menu
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const classes = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      cursor: "auto",
      height: "100%",
    },
    thumbContainer: {
      width: "115px",
      maxHeight: thumbPanelHeight,
      padding: "5px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
      overflowY: "auto",
    },
    selectedPage: {
      overflow: "hidden",
      position: "relative",
      height: "100px",
      border: `4px solid ${alpha(theme.palette.highlight.main, 0.8)}`,
      "&:hover": {
        border: `4px solid ${theme.palette.secondary.main}`,
      },
    },
    currentPage: {
      overflow: "hidden",
      border: `4px solid ${alpha(theme.palette.highlight.main, 0.8)}`,
      height: "100px",
      position: "relative",
      "&:hover": {
        border: `4px solid ${theme.palette.secondary.main}`,
      },
      "&::before": {
        content: "''",
        textAlign: "center",
        fontSize: "70px",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: alpha(theme.palette.highlight.main, 0.2),
      },
    },
    unSelectedPage: {
      overflow: "hidden",
      height: "100px",
      border: `4px solid transparent`,
      "&:hover": {
        border: `4px solid ${theme.palette.secondary.main}`,
      },
    },
    thumbnail: { height: "100%", objectFit: "scale-down", maxWidth: "95px" },
    expandContainer: {
      height: "100%",
      width: "15px",
      cursor: "pointer",
      backgroundColor: alpha(theme.palette.primary.dark, 0.5),
      borderLeft: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        opacity: 0.7,
      },
    },
    expandIcon: { color: theme.palette.text.primary },
    container: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    draggingPage: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    selectionLength: {
      right: "-10px",
      top: "90px",
      borderRadius: "50%",
      height: "20px",
      width: "20px",
      position: "absolute",
      textAlign: "center",
      fontSize: "12px",
      background: theme.palette.highlight.main,
    },
    hidden: {
      opacity: "0.5",
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  })();

  //right click menu handle function
  const handleContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 10,
            mouseY: event.clientY - 10,
          }
        : null
    );
  };

  // right click menu close function
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const loadPage = (page: string) => {
    documentStore
      .loadDocumentPage(documentStore.document?.identifier ?? "", page)
      .catch((error: Error) => {
        notification.error(t(error?.message || "loadPageError"));
      });
  };

  const onThumbnailClick = (
    pageIdentifier: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    // Remove received url query params if exists
    if (search) {
      history.push(window.location.pathname);
    }

    if (event.ctrlKey) {
      if (!selectedPages.includes(pageIdentifier)) {
        const orderedSelectedPages = documentStore.newDocumentPagesOrder.filter(
          (page) => selectedPages.includes(page) || page === pageIdentifier
        );
        setSelectedPages(orderedSelectedPages);
        loadPage(pageIdentifier);
      } else {
        if (selectedPages.length === 1) return;
        const newPages = selectedPages.filter(
          (page) => page !== pageIdentifier
        );
        setSelectedPages(newPages);
        if (
          pageIdentifier === documentStore.documentPage?.identifier &&
          selectedPages.length > 1
        ) {
          loadPage(newPages[0]);
        }
      }
    } else {
      if (pageIdentifier === documentStore.documentPage?.identifier) return;
      setSelectedPages([pageIdentifier]);
      loadPage(pageIdentifier);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    //Handles multiple drag and drop
    if (
      selectedPages.includes(
        documentStore.newDocumentPagesOrder[result.source.index]
      ) &&
      selectedPages.length > 1
    ) {
      if (!result.destination) {
        setDraggingPage(null);
        return;
      }
      //mark all the selected items
      let markedItems = documentStore.newDocumentPagesOrder.map((item) => {
        if (selectedPages.includes(item)) {
          return {
            item,
            marked: true,
          };
        } else {
          return {
            item,
          };
        }
      });
      //remove the current dragging page from the marked items
      markedItems = markedItems.filter((item) => item.item !== draggingPage);

      //insert the selected pages to the new position
      markedItems.splice(
        result.destination.index,
        0,
        ...selectedPages.map((item) => {
          return {
            item,
            marked: false,
          };
        })
      );
      //remove the marked pages from the marked items
      const newItems = markedItems
        .filter((item) => !item.marked)
        .map((item) => item.item);
      documentStore.setNewDocumentPagesOrder(newItems);
    } else {
      //Handles single drag and drop
      if (
        !result.destination ||
        result.destination.index === result.source.index
      ) {
        setDraggingPage(null);
        return;
      }
      const tempArray = [...documentStore.newDocumentPagesOrder];
      const removed = tempArray.splice(result.source.index, 1);
      tempArray.splice(result.destination.index, 0, ...removed);
      documentStore.setNewDocumentPagesOrder(tempArray);
    }
    setDraggingPage(null);
  };

  const getClassName = (pageIdentifier: string) => {
    if (documentStore.documentPage?.identifier === pageIdentifier) {
      return classes.currentPage;
    } else if (selectedPages.includes(pageIdentifier)) {
      return classes.selectedPage;
    } else return classes.unSelectedPage;
  };

  const BarIcon = documentStore.expandedThumbPreviewer ? (
    <ArrowLeftIcon className={classes.expandIcon} />
  ) : (
    <ArrowRightIcon className={classes.expandIcon} />
  );
  // TODO: Split code to reduce rendering
  return (
    <Box>
      <Box className={classes.mainContainer} id={APP_IDS.thumbnailPreviewer}>
        {documentStore.expandedThumbPreviewer && (
          <DragDropContext
            onDragEnd={handleDragEnd}
            onBeforeCapture={(event) => {
              setDraggingPage(event.draggableId);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <Box
                  className={classes.thumbContainer}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {documentStore.newDocumentPagesOrder.map((page, index) => {
                    const getClass = () => {
                      if (draggingPage && selectedPages.includes(page)) {
                        if (draggingPage === page) {
                          return classes.draggingPage;
                        } else {
                          return classes.hidden;
                        }
                      } else {
                        return classes.container;
                      }
                    };

                    return (
                      <Draggable key={page} draggableId={page} index={index}>
                        {(provided, snapshot) => {
                          const pageUrl = backendRoutes.GetImagePreview(
                            documentStore.document?.pagesUrls[page] ?? "",
                            documentStore.document?.identifier ?? "",
                            IMAGE_SIZE.thumbnail
                          );

                          const isPageSelected =
                            documentStore.documentPage?.identifier === page;
                          const pageRotation = documentRotation?.[page] || 0;

                          return (
                            <Box
                              className={getClass()}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box
                                className={getClassName(page)}
                                onClick={(event) =>
                                  onThumbnailClick(page, event)
                                }
                                onContextMenu={(event) => {
                                  if (!selectedPages.includes(page)) {
                                    onThumbnailClick(page, event);
                                  }
                                  handleContextMenu(event);
                                }}
                              >
                                <Suspense fallback={<AppSuspense size={10} />}>
                                  <CoreImage
                                    path={pageUrl}
                                    className={classes.thumbnail}
                                    customStyle={
                                      pageRotation
                                        ? {
                                            transform: `rotate(${pageRotation}deg)`,
                                          }
                                        : {}
                                    }
                                  />
                                </Suspense>
                              </Box>
                              <Typography
                                fontSize="15px"
                                mt="5px"
                                color={
                                  isPageSelected
                                    ? theme.palette.highlight.main
                                    : "inherit"
                                }
                              >
                                #{index + 1}
                              </Typography>
                              {selectedPages.includes(page) &&
                                selectedPages.length > 1 &&
                                snapshot.isDragging && (
                                  <Box className={classes.selectionLength}>
                                    <Typography>
                                      {selectedPages.length}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  <RightClickMenu
                    open={contextMenu !== null}
                    onClose={handleCloseContextMenu}
                    anchorReference="anchorPosition"
                    documentPages={documentStore.newDocumentPagesOrder}
                    anchorPosition={
                      contextMenu !== null
                        ? {
                            top: contextMenu.mouseY,
                            left: contextMenu.mouseX,
                          }
                        : undefined
                    }
                    selectedIds={selectedPages}
                  />
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Box
          className={classes.expandContainer}
          onClick={documentStore.setExpandThumbPreviewer}
        >
          {BarIcon}
        </Box>
      </Box>
    </Box>
  );
});

export default memo(ThumbnailPreviewer);
