import React, { useState, useEffect } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";

import CoreDrawer from "../../../../../core/CoreDrawer";
import CoreHeader from "../../../../../core/table/CoreHeader";
import { EXPORT_HEADERS, FIELD_TYPE } from "../../../../../../types/constants";
import CoreInput from "../../../../../core/CoreInput";
import { GlobalEventType } from "../../../../../../types/types";
import CoreButton from "../../../../../core/CoreButton";
import { EXPORT_KEY, FormData } from "../../../../../../types/interfaces";
import CoreNotificationBox from "../../../../../core/CoreNotificationBox";
import AdvancedExportTable from "./AdvancedExportTable";

interface Props {
  exportType: string;
  isOpen: boolean;
  onClose: () => void;
  t: TFunction;
  handleChangeForm: (value: FormData) => void;
  formData: FormData;
}

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    paddingTop: "30px",
    flexDirection: "row-reverse",
  },
  title: {
    display: "flex",
    marginTop: "10px",
    "@media screen and (min-width:551px) and (max-width:700px)": {
      paddingLeft: "9%",
    },
    "@media screen and (min-width:701px)  and (max-width:850px)": {
      paddingLeft: "10%",
    },
    "@media screen and (min-width:851px)": {
      paddingLeft: "10.5%",
    },
    wordBreak: "break-word",
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
    width: "70%",
  },
  boxAccordionSummary: {
    display: "flex",
    paddingRight: "50px",
  },
  coreInput: {
    display: "flex",
    paddingTop: "10px",
    width: "40%",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  fieldsBoxStyle: {
    paddingTop: "5px",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "12px",
    padding: "0 16px 0 0",
  },
});

const AdvancedExport: React.FC<Props> = ({
  isOpen,
  onClose,
  t,
  formData,
  handleChangeForm,
  // exportType,
}) => {
  const [exportFields, setExportFields] = useState<EXPORT_KEY[]>([]);

  const classes = useStyles();

  useEffect(() => {
    if (isOpen) setExportFields(formData.exportFields as EXPORT_KEY[]);
  }, [formData, isOpen]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    exportKeyField: string
  ) => {
    const {
      target: { name, value, type, checked },
    } = e as GlobalEventType;

    e.stopPropagation();
    e.preventDefault();

    const newExportFields = exportFields.map((exportKey) =>
      exportKey.key === exportKeyField
        ? {
            ...exportKey,
            [name]: type === FIELD_TYPE.checkbox ? checked : value,
          }
        : exportKey
    );

    setExportFields(newExportFields);
  };

  const applyChanges = () => {
    handleChangeForm({
      exportFields: exportFields,
    });
    onClose();
  };

  return (
    <>
      <CoreDrawer
        isOpen={isOpen}
        onClose={onClose}
        title={t("customizeExportTitle")}
        hasBackButton
        onBack={onClose}
      >
        <CoreNotificationBox icon={false}>
          <Typography>{t("customizeExportDescription")}</Typography>
        </CoreNotificationBox>

        <Table>
          <CoreHeader
            loading={false}
            headers={EXPORT_HEADERS}
            columnWidth={{
              willExport: "10%",
              exportName: "35%",
              name: "45%",
            }}
          />
        </Table>
        <Box className={classes.optionsContainer}>
          {exportFields && exportFields?.length > 0 ? (
            exportFields
              ?.filter((item) => !item.headers)
              ?.map((exportKey) => {
                return (
                  <Box key={exportKey.key} className={classes.fieldsBoxStyle}>
                    <Accordion expanded={false}>
                      <Box className={classes.boxAccordionSummary}>
                        <AccordionSummary className={classes.accordionSummary}>
                          <Checkbox
                            checked={exportKey.willExport}
                            onChange={(e) => handleChange(e, exportKey.key)}
                            name="willExport"
                          />
                          <Typography className={classes.title}>
                            {`${exportKey.name} (${exportKey.key})`}
                          </Typography>
                        </AccordionSummary>
                        <Box className={classes.coreInput}>
                          <CoreInput
                            value={exportKey.exportKey}
                            onChange={(e) => handleChange(e, exportKey.key)}
                            name="exportKey"
                          />
                        </Box>
                      </Box>
                    </Accordion>
                  </Box>
                );
              })
          ) : (
            <Box className={classes.noData}>
              <Typography>{t("noDataToDisplay")}</Typography>
            </Box>
          )}

          <AdvancedExportTable
            exportFields={exportFields}
            setExportFields={setExportFields}
            handleChange={handleChange}
          />
        </Box>

        <Box className={classes.footer}>
          <CoreButton
            onClick={applyChanges}
            variant="contained"
            disabled={exportFields?.every((field) => !field.willExport)}
          >
            {t("confirm")}
          </CoreButton>
        </Box>
      </CoreDrawer>
    </>
  );
};

export default AdvancedExport;
