import React, { useState, memo } from "react";
import { Resizable, NumberSize, Enable, HandleStyles } from "re-resizable";

import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";

interface Props {
  onResizeExtraFunction?: () => void;
  children: React.ReactNode;
  enable?: Enable;
  handleStyles: HandleStyles;
  containerSizes: {
    default: number;
    min: number;
    max: number;
  };
}

const useStyles = makeStyles({
  handlerStyle: {},
  dragIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
});

const CoreResizableWidth: React.FC<Props> = ({
  children,
  onResizeExtraFunction,
  enable,
  handleStyles,
  containerSizes,
}) => {
  const classes = useStyles();

  const [containerSize, setContainerSize] = useState({
    width: containerSizes.default,
    height: "auto",
  });

  const handleResize = (
    _event: MouseEvent | TouchEvent,
    _direction: unknown,
    _elementRef: HTMLElement,
    delta: NumberSize
  ) => {
    const sidebarAnimationHandle = document.querySelector(
      ".addTransitionWidth"
    ) as unknown as HTMLElement;
    sidebarAnimationHandle.style.transition = "width 0.4s";
    const currentWidth = containerSize.width + delta.width;

    setContainerSize({
      ...containerSize,
      width: currentWidth,
    });
  };

  const handleStart = () => {
    const sidebarAnimationHandle = document.querySelector(
      ".addTransitionWidth"
    ) as unknown as HTMLElement;
    sidebarAnimationHandle.style.transition = "";
  };

  return (
    <Resizable
      minWidth={containerSizes.min}
      maxWidth={containerSizes.max}
      style={{
        padding: 0,
        position: "relative",
      }}
      size={{
        width: containerSize.width,
        height: containerSize.height,
      }}
      handleStyles={handleStyles}
      handleComponent={{
        left: (
          <div className={classes.dragIcon}>
            <MenuIcon style={{ fontSize: 10 }} />
          </div>
        ),
      }}
      enable={enable}
      className={`${classes.handlerStyle} addTransitionWidth`}
      onResizeStart={handleStart}
      onResize={() => onResizeExtraFunction && onResizeExtraFunction()}
      onResizeStop={handleResize}
    >
      {children}
    </Resizable>
  );
};

export default memo(CoreResizableWidth);
