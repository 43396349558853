import { gql } from "@apollo/client";
import { ChatGptMessage } from "../../types/interfaces";

export interface MUTATION_REGENERATE_CLARICHAT_RESPONSE {
  regenerateResponse: ChatGptMessage;
}

export const REGENERATE_CLARICHAT_RESPONSE = gql`
  mutation regenerateClarichatResponse(
    $question: JSON
    $config: JSON
    $flowIdentifier: String
    $language: String
  ) {
    regenerateClarichatResponse(
      question: $question
      config: $config
      flowIdentifier: $flowIdentifier
      language: $language
    ) {
      answer
      createdAt
      question
    }
  }
`;
