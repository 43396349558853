import React, { ReactElement } from "react";
import { TFunction } from "i18next";
import { ApolloClient } from "@apollo/client";

import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { CSSProperties } from "@mui/styles";
import { PaletteMode, ThemeOptions } from "@mui/material";
import {
  ColorPartial,
  CommonColors,
  PaletteTonalOffset,
  TypeAction,
  TypeBackground,
  TypeText,
} from "@mui/material/styles/createPalette";
import { SvgIconComponent } from "@mui/icons-material";

import { FIELD_TYPE, NODE_TYPES } from "./constants";
import { ColorPalette, FormFieldProps, Order } from "./types";
import { Base_DocumentZone } from "./interfaces/document";
import { FlowStore } from "../stores/FlowStore";
import { UserStore } from "../stores/UserStore";
import { PureAbility } from "@casl/ability";
import { Edge } from "reactflow";
import { FormFieldLanguageTypes } from "./enums";

// Theming
export interface Theme extends Omit<Partial<ThemeOptions>, "palette"> {
  // Referring to colors by the color name defeats the whole purpose of theming.
  palette: {
    mode: string;
    type: string;
    primary: ThemePalette;
    secondary: ThemePalette;
    error?: ThemePalette;
    warning?: ThemePalette;
    info?: ThemePalette;
    success?: ThemePalette;
    tonalOffset?: PaletteTonalOffset;
    contrastThreshold?: number;
    common?: Partial<CommonColors>;
    grey?: ColorPartial;
    text?: Partial<TypeText>;
    divider: string;
    action?: Partial<TypeAction>;
    background?: Partial<TypeBackground>;
  };
}

export interface ThemeModes {
  light: PaletteMode;
  dark: PaletteMode;
  mono: PaletteMode;
}

export interface Palette {
  main: ColorPalette;
  light: ColorPalette;
  dark: ColorPalette;
  contrastText: ColorPalette;
  primary: ColorPalette;
  default: ColorPalette;
  secondary: ColorPalette;
}

export interface ThemePalette {
  mode: string;
  primary: Palette;
  secondary: Palette;
  dark: Palette;
  neutral: Palette;
  error: Palette;
  buttons: Palette;
  highlight: Palette;
  switch: Palette;
  canvas: Palette;
  surface: Palette;
  text: Palette & Partial<TypeText>;
  divider: string;
  background: Palette & TypeBackground;
}

export interface ThemeToggle {
  displayName: string;
  value: string;
}

// Table
export interface TableHeaderDef {
  tableName?: string;
  accessor: string;
  translationKey?: string;
  label?: string | ReactElement;
  options?: {
    isHidden?: boolean;
    isSortable?: boolean;
    isSortActive?: boolean;
    sortDirection?: Order;
    onClick?: () => void;
  };
}

export interface TableRowStyleDef {
  [index: number]: CSSProperties;
}

export interface TableCellStyleDef {
  [rowIndex: number]: { [cellAccessor: string]: CSSProperties };
}

export interface StatusDef {
  type: string;
  details: {
    message: string;
    fields?: Record<string, string>;
    categories?: Record<string, string>;
    lineItems?: {
      [tableKey: string]: {
        [headerKey: string]: {
          [rowIndex: string]: string;
        };
      };
    };
  };
}

export interface TableContentDef {
  [key: string]: string | boolean | number | ReactElement | StatusDef;
}

export interface TableFooterDef {
  isHidden: boolean;
}

export interface SelectOptionDef extends CheckboxOptionDef {
  key: string;
  name?: string;
  label: string;
  value?: string;
  optGroup?: string;
  isDisabled?: boolean;
  icon?: JSX.Element | unknown;
  id?: string;
  hasReferenceValue?: boolean;
  suggestions?: SelectOptionDef[];
  type?: string;
  contents?: string; // Used in API Keys
  divider?: boolean;
  element?: React.ReactNode;
  isSvg?: boolean;
}

export interface CheckboxOptionDef {
  isParent?: boolean;
  parentKey?: string;
  isIndeterminate?: boolean;
  isChecked?: boolean;
  onClick?: () => void;
}

export interface FormField {
  translationKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupBy?: (option: any) => string;
  group?: string;
  key: string;
  type: FIELD_TYPE;
  options?: SelectOptionDef[];
  labelPlacement?: "bottom" | "end" | "start" | "top";
  errorText?: string;
  isTextArea?: boolean;
  props?: FormFieldProps;
  title?: string;
  tooltipLocation?: "title" | "input";
  isMandatory?: boolean;
  name?: string;
  extraActions?: ReactElement;
  showStrengthBar?: boolean;
  showRules?: boolean;
  validateField?: (formData: FormData) => boolean;
  customScopes?: string[];
  canContainSpacesOnly?: boolean;
  language?: FormFieldLanguageTypes;
  isMultipleSelect?: boolean;
  suggestions?: boolean;
  parameters?: AssetTemplateParameters[];
  preventValueCheck?: boolean;
  allowedTypes?: string[];
  isDynamicContentAllowed?: boolean;
  showToolbox?: boolean;
  allowNestedObjects?: boolean;
  allowListSchemaObjects?: boolean;
  disabled?: boolean;
  parents?: Record<string, unknown[]>;
  allowSchemaGenerator?: boolean;
  value?: string | number | boolean;
  onDelete?: (key: string) => void;
  forConnections?: boolean;
  cannotEditSchema?: boolean;
}

export interface SidebarOption {
  key: string;
  translationKey: string;
  path: string;
  icon?: SvgIconComponent;
}

export interface FormData {
  [key: string]: unknown;
}

export enum DrawerTypes {
  FORM_DRAWER = "form_drawer",
}

export enum FlowFieldsDrawerTypes {
  FIELD = "field",
  TEMPLATE = "template",
}

export interface ExtendedFile extends File {
  preview?: string;
}

export interface CardActions {
  tooltip?: string;
  icon: React.Component;
  onClick: (args: string[] | string) => void;
  label?: string;
}

// Flow

export interface Flow {
  identifier: string;
  name: string;
  access: Record<string, string>;
  description: string;
  manualValidate: boolean;
  flowType: string;
  fields?: FlowField[];
  categories: FlowCategory[];
  template?: FlowTemplate;
  storage: StorageOptions;
  dataExport: DataExportOptions;
  dataSource: DataSourceOptions;
  dataAlgorithms: AlgorithmsOptions;
  enhancements: EnhancementConfiguration[];
  approvals: ApprovalInterface[];
  rejectReasons: RejectionInterface[];
}

export interface FlowInterface {
  identifier: string;
  name: string;
  access: Record<string, string>;
  flowVersion: string;
  description: string;
  manualValidate: boolean;
  storage: StorageOptions;
  dataExport: DataExportOptions;
  dataSource: DataSourceOptions;
  rejectReasons: RejectionInterface[];
  template?: FlowTemplate;
  enhancements: EnhancementConfiguration[];
  approvals: ApprovalInterface[];
  dataAlgorithms: AlgorithmsOptions;
  flowType: string;
  fields: FlowField[];
  categories: FlowCategory[];
}

export interface DuplicatedFlowConfig {
  name: string;
  description: string;
  access: Record<string, string>;
}

export interface FlowSummary {
  identifier: string;
  name: string;
  description: string;
  flowType: string;
  fields?: FlowField[];
  categories: FlowCategory[];
  currentUserPermissions: Record<string, boolean>;
}

export interface FlowFilterKeys {
  fields: FlowFilterKeysTemplate[];
  categories: FlowFilterKeysTemplate[];
  metadata: FlowFilterKeysTemplate[];
}

export interface AdvancedFilter {
  field?: string;
  category?: string;
  operator?: string;
  type?: string;
  values?: string[];
  source?: string;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface FlowNode {
  key: string;
  identifier: string;
  name: string;
  description: string;
  parameters: { [key: string]: string };
  inputKey?: string;
  inputPath?: string;
  outputPath?: string;
  // Used for nodes of type group
  parentId?: string;
  nodesFilter?: {
    // This property excludes given nodes from withing a group
    // On create node within a group, received nodes won't be available
    exclude?: string[];
  };
  // Conditional node
  falseBranch?: boolean;
  trueBranch?: boolean;

  // designer props
  id: string;
  type: string;
  position: { x: number; y: number };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  source?: string[];
  target?: string[];
  extent?: string;
  style?: CSSProperties;
  refreshContextObjects?: boolean;
}

export interface NodeInputParameter {
  key: string;
  type: string;
}

export interface NodeData {
  type: NODE_TYPES;
  onHandleClick?: (identifier?: string, groupId?: string) => void;
  value: string;
  target?: string;
  source?: string;
  identifier: string;
  key: string;
  position?: { x?: number; y?: number };
  parentId?: string;
  // heightMult?: number;
  style?: CSSProperties;
  trueBranch?: boolean;
  falseBranch?: boolean;
}

export interface NodeMenuOption {
  onAction: () => void;
  icon: JSX.Element;
  label: string;
}

export interface FlowDiagram {
  identifier: string;
  version: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  specs: { nodes: FlowNode[]; edges: Edge<any>[] };
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  user: User;
  current: boolean;
}

export interface FlowSettingsMenuItem {
  name: string;
  onClick: () => void;
}

export interface StorageOptions {
  repoConnection: string | undefined | null;
  customRepo: boolean;
  // dataRetention: boolean;
  // deleteLocalFile: boolean;
  // deleteDoc: boolean;
  // timeToKeep: number;
  repoParameters: FormData;
}

export type StorageOptionsType =
  | "repoConnection"
  | "customRepo"
  | "dataRetention"
  | "deleteLocalFile"
  | "deleteDoc"
  | "timeToKeep"
  | "repoParameters";

export interface AlgorithmsOptions {
  algorithms: AssetConfiguration[];
  name?: string;
  parameters?: Record<string, unknown>;
  description?: string;
}

export type BackendAlgorithmsOptions = Record<string, AlgorithmsOptions>;

export interface DataExportOptions {
  dataExports: AssetConfiguration[];
  fields: AssetFields[];
}

export interface DataSourceOptions {
  sources: AssetConfiguration[];
  fields: AssetFields[];
}

export interface GetUsersAPI {
  rows: User[];
  count: number;
}

export interface User {
  id: number;
  email: string;
  password?: string;
  first_name: string;
  last_name: string;
  scope?: string;
  settings: JSON;
  default_ns: number;
  status: number;
  is_active: boolean;
  is_deleted: boolean;
  oauth_id?: string;
  __typename?: string;
}

export interface FlowTemplate {
  identifier: string;
  name: string;
  description: string;
  fields?: FlowField[];
  dataSource?: DataSource;
  dataAlgorithms?: Algorithms;
  dataExport?: DataExport;
}

export interface Algorithms {
  fields?: AssetFields[];
  algorithms?: AssetConfiguration[];
}

export interface DataSource {
  fields?: AssetFields[];
  sources?: AssetConfiguration[];
}

export interface DataExport {
  fields?: AssetFields[];
  dataExports?: AssetConfiguration[];
}

export interface AssetFields {
  name: string;
  key: string;
  value: string | number | boolean;
  type: string;
  options?: { key: string; label: string }[];
}

export interface FlowFieldCoords {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface FlowField {
  identifier?: string;
  name: string;
  key: string;
  originalKey?: string;
  source?: string;
  inputKey?: string;
  category: string;
  description: string;
  dataType?: DataType;
  isCustom?: boolean;
  zone?: string[];
  type?: string;
  box?: DocumentZoneBox;
  text?: string;
  pageIdentifier?: string;
  isHidden?: boolean;
  isTemplate?: boolean;
  isMandatory?: boolean;
  isVisible?: boolean;
}

export interface DataType {
  key?: string;
  name?: string;
  description?: string;
  identifier?: string;
  type?: string;
  parameters?: { [key: string]: unknown };
}

export interface FlowCategory {
  name: string;
  key: string;
  description: string;
  labels: SelectOptionDef[];
  optional: boolean;
  multipleSelect: boolean;
  type?: string;
}

export interface FlowFilterKeysTemplate {
  key: string;
  type: string;
  name: string;
  source?: string;
}

export interface FieldCustomProps {
  extraActions?: ReactElement;
  type?: string;
  hidePasswordController?: boolean;
  description?: string;
}

// Flow document - Canvas
export interface Document {
  identifier: string;
  flowIdentifier: string;
  name: string;
  src: string;
  pagesUrls: {
    [key: string]: string;
  };
  pageUri: string;
  pages: string[];
  createdAt: string;
  updatedAt: string;
  fields: Variable[];
  lineItems: DocumentLineItem[];
  categories: DocumentCategory[];
  pageCount: string;
  status: StatusDef;
  flowName?: string;
  isViewMode: boolean;
  rotation?: { [key: string]: number };
  values: { [key: string]: unknown };
  finishedAt: string;
  metadata: { [key: string]: string };
}

export interface DocumentData {
  data: DocumentPage;
}

export interface FlowDocuments {
  documents: Document[];
  totalDocuments: number;
  amountOfPages: number;
  currentPage: number;
}

export interface Documents {
  documents: Document[];
  totalDocuments: number;
  amountOfPages: number;
  currentPage: number;
}

export interface Logs {
  logId: string;
  documentIdentifier: string;
  node: string;
}

export interface DocumentsFilterProps {
  sortBy?: string;
  sortDirection?: string;
  status?: string[];
  date?: {
    startDate?: string | null;
    endDate?: string | null;
  };
  name?: string[];
  metadata?: DocumentsFilterProps[];
  manuallyUpdated?: boolean;
  flows?: string[];
  fields?: DocumentsFilterProps[];
  categories?: DocumentsFilterProps[];

  // Metadata filter
  field?: string;
  category?: string;
  operator?: string;
  values?: string[];
  type?: string;
  source?: string;
}

export interface FlowFilters {
  flowId?: string | null;
  flowFilters?: DocumentsFilterProps;
}
export interface DocumentPage {
  identifier: string;
  documentIdentifier: string;
  uri: string;
  pageNumber: number;
  ocrData: DocumentZone[];
  fields: JSON;
  lineItems: DocumentLineItem[];
  pageInformation: PageInformation;
  rotation: number;
}

export interface PageInformation {
  width: number;
  height: number;
}

export interface CheckedStatuses {
  status: string;
  isChecked: boolean;
}

export interface DocumentZone extends Base_DocumentZone {
  index: number;
  box: DocumentZoneBox;
  default?: boolean; // Marker for OCR zones
  disableTextAlter: boolean; // In advanced validation mode, once the text is updated, then it cannot be altered from zone intersection
  points: [number, number][];
  // Line Items
  key?: string;
  originalKey?: string; // Used for ignore column
  type: string;
  // TODO: Remove prop
  error?: string;
  message?: DocumentMessageType;
  details?: DocumentMessageType[];
}

export enum MESSAGE_LEVELS {
  error = "error",
  warning = "warning",
  info = "info",
  // UI only
  header = "header",
  subheader = "subheader",
  success = "success",
}

export interface DocumentMessageType {
  level: MESSAGE_LEVELS;
  message: string;
  // UI only
  isSubError?: boolean;
}

export interface DocumentLineItem {
  // Root table (we may have more than one table)
  key: string;
  type: string;
  category: string; // table
  // TODO: Remove prop
  error?: string;
  message?: DocumentMessageType;
  details?: DocumentMessageType[];
  coords: {
    root: DocumentZoneBox; // Main rect coords for entire table/ table edges
    lines: DocumentLineItemLine[]; // List of coords for columns and rows separators
  };
  // Table headers
  headers: DocumentZone[];
  // Table body
  data: DocumentLineItemRow[];
  pageIdentifier: string;
  name: string;
}

export type DocumentLineItemLine = [[number, number], [number, number]];

export interface DocumentLineItemRow {
  // Table row
  isIgnored: boolean;
  manuallyAdded: boolean;
  points: [number, number][];
  box: DocumentZoneBox;
  coords: DocumentZoneBox;
  cells: Record<string, DocumentLineItemCell>;
}

// Table cell
export type DocumentLineItemCell = DocumentZone;

export interface CategoryResults {
  label: string;
  key: string;
  score: number;
}
export interface DocumentCategory {
  type: string;
  multipleSelect: boolean;
  results: CategoryResults[];
  pageIdentifier: string;
  manuallyAdded: boolean;
}

export interface DocumentZoneBox {
  height: number;
  width: number;
  x: number;
  y: number;
  rotation?: number;
  scaleX?: number;
  scaleY?: number;
  top_left?: {
    x: number;
    y: number;
  };
  key?: string;
}

export interface APIDocumentZoneBox {
  height: number;
  width: number;
  rotation: number;
  top_left: {
    x: number;
    y: number;
  };
}

export interface APIDocumentZone extends Base_DocumentZone {
  box: DocumentZoneBox;
}

export interface LineItemHeader {
  key: string;
  text: string;
}

export interface LineItemBody {
  cells: { [key: string]: { text: string } };
}

export interface LineItemHistory {
  data: LineItemBody[];
  headers: LineItemHeader[];
}

export interface DocumentHistory {
  eventIdentifier: string;
  userId: number;
  user: User;
  contextId: number;
  actionCode: string;
  documentIdentifier: string;
  actionStatus: string;
  statusDetails: { new: string; old: string; message?: string };
  data: {
    fields: {
      [key: string]: { old_value: DocumentZone; new_value: DocumentZone };
    };
    lineItems: {
      [key: string]: {
        old_value: LineItemHistory | null;
        new_value: LineItemHistory | null;
      };
    };
  };
  time: string;
}

// connection form config
export interface ConnectionField {
  key: string;
  name: string;
  description: string;
  isMandatory: boolean;
  parentValue?: string;
  value: string | boolean;
  ftype: FIELD_TYPE;
  options?: { key: string; label: string }[];
  fields: ConnectionField[];
  translationKey: string;
  tooltipLocation?: "title" | "input";
  parents?: Record<string, unknown[]>;
  type?: string;
}

export interface Connection {
  identifier?: string;
  name: string;
  type: string;
  description: string;
  [key: string]: unknown;
}

export interface ConnectionType {
  type: string;
  name: string;
  icon: string;
}

export interface ConnectionFieldData {
  key: string;
  value: string;
}
export interface RejectionInterface {
  message: string;
  code: string;
  description?: string;
}

export interface AssetType {
  key: string;
  name: string;
  description: string;
  flowType: string;
  hasParams: boolean;
  parameters?: Record<string, unknown>;
  iconPath?: string;
  icon?: string;
  category?: string;
  type: string;
  nodesFilter?: {
    // This property excludes given nodes from withing a group
    // On create node within a group, received nodes won't be available
    exclude?: string[];
  };
}

export interface ApprovalInterface {
  key: string;
  name: string;
  operator: string;
  rules: RuleInterfaceBackend[];
}

export interface RuleInterfaceBackend {
  key: string;
  field: string;
  name: string;
  description: string;
  parameters: Record<string, unknown>;
  canEdit?: AssetCanDeleteCanEdit;
  canDelete?: AssetCanDeleteCanEdit;
  [key: string]: unknown;
}

export interface RuleInterface {
  key: string;
  name: string;
  description: string;
  parameters: RuleParameters[];
}

export interface RuleParameters {
  key: string;
  type: FIELD_TYPE;
  name: string;
  description: string;
  value?: number;
  isMandatory?: boolean;
  options?: SelectOptionDef[];
  isMultipleSelect?: boolean;
}

export interface AssetCanDeleteCanEdit {
  enabled: boolean;
  message: string;
}

export interface AssetConfiguration {
  key: string;
  identifier?: string;
  type: string;
  name: string;
  description: string;
  canEdit?: AssetCanDeleteCanEdit;
  canDelete?: AssetCanDeleteCanEdit;
  parameters: { [key: string]: string };
  isDefault?: boolean;
}

export interface EnhancementConfiguration {
  key: string;
  type: string;
  name: string;
  description: string;
  parameters: { [key: string]: string };
  inputFields: string[];
  outputFields: FlowField[];
  originalFieldOutput?: boolean;
  canEdit?: AssetCanDeleteCanEdit;
  canDelete?: AssetCanDeleteCanEdit;
}

export interface EnhancementParameters {
  key: string;
  type: FIELD_TYPE;
  name: string;
  description: string;
  value?: string;
  isMandatory?: boolean;
  options?: SelectOptionDef[];
  isMultipleSelect?: boolean;
  dataType?: string;
  disabled?: boolean;
  singleOutput?: boolean;
}

export interface AssetTemplateConfiguration {
  key: string;
  type: string;
  name: string;
  description: string;
  listKey: string;
  singleOutput?: boolean;
  parameters: AssetTemplateParameters[];
  nonEditableFields: string[];
  groups: {
    key: string;
    name: string;
    description?: string;
    warning?: string;
  }[];
  input: {
    dataTypes: string[];
    type: string;
    allowedTypes: string[];
  };
  output: {
    dataTypes: string[];
    type: string;
    allowedTypes: string[];
    schema_: JSON;
  };
  diagramSpec?: { nodes: FlowNode[]; edges: Edge<unknown>[] };
  refreshContextObjects?: boolean;
}
export interface AssetTemplateParameters {
  key: string;
  type: FIELD_TYPE;
  name: string;
  description: string;
  value?: string | number | boolean;
  isMandatory?: boolean;
  isTextArea?: boolean;
  canContainSpacesOnly?: boolean;
  isVisibleDuringCreation?: boolean;
  isMultipleSelect?: boolean;
  disabled?: boolean;
  options?: SelectOptionDef[];
  dataType?: string;
  parents?: Record<string, unknown[]>;
  language?: FormFieldLanguageTypes;
  suggestions?: boolean;
  parameters?: AssetTemplateParameters[];
  showToolbox?: boolean;
  isDynamicContentAllowed?: boolean;
  allowNestedObjects?: boolean;
  allowListSchemaObjects?: boolean;
  allowSchemaGenerator?: boolean;
  refreshContextObjects?: boolean;
  // If received as true, add connection action will be displayed
  allowAddConnection?: boolean;
}

//QA
export interface DocumentsResult {
  getDocuments: Documents;
}

export interface QADocuments {
  documents?: QADocument[];
  totalDocuments?: number;
  amountOfPages?: number;
  currentPage?: number;
}

export interface QAFilters {
  sessionId?: string;
  users?: string[];
  flows?: string[];
  date?: {
    startDate?: string | null;
    endDate?: string | null;
  };
  amount?: number;
  strategy?: string;
  update?: boolean;
  reset?: boolean;
  manuallyUpdated?: boolean;
  // Metadata filter
  field?: string;
  operator?: string;
  values?: string[];
  type?: string;
  source?: string;
}

export interface ApplyQAPayload {
  users?: string[];
  sessionId: string;
  assignedBy: string;
  excludeErrors: boolean;
}

export interface QADocument {
  identifier: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  status: StatusDef;
  pageUri: string;
  pageCount: string;
  flowName: string;
  flowIdentifier: string;
  metadata: {
    validatedBy: string;
  };
}

export interface PreviewData {
  files: Record<string, string>[] | [];
  documentCount: number;
  incomplete: boolean;
}

export interface PreviewDataSource {
  key: string;
  type: string;
  name: string;
  description: string;
  parameters: Record<string, string>;
}
export interface Ability {
  action: string;
  subject: string;
}
export interface HeaderActions {
  tooltip: string;
  disabledTooltip?: string;
  onClick?: () => void;
  startIcon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  label: string;
  ability?: Ability[];
  initiallyHidden?: boolean;
  divider?: boolean;
  color?: string;
}

export interface Point {
  x: number;
  y: number;
}

export enum FIELD_MAX_LENGTH {
  NAME = 60,
  DESCRIPTION = 256,
}

export interface Namespace {
  id: number;
  name: string;
  owner_id: string;
}

export interface NamespaceRole {
  scope: string;
  namespace: Namespace;
  user: User;
  is_active: boolean;
  is_deleted: boolean;
}

export interface IToken {
  exp: number;
  contextId: number;
  permissions: string;
  devSettings: string;
}

export interface AuthTokenResponse {
  isUserAuthenticated: boolean;
  isNamespace: boolean;
}

export interface InvitationData {
  email?: string;
  token: string;
}

export interface TABS_INTERFACE {
  title: string;
  page: React.FC<TAB_PROPS>;
  isMandatory?: boolean;
  updateTitle?: string;
}

export interface TAB_PROPS {
  startName: string;
  t: TFunction;
  title: string;
  flowStore: FlowStore;
  userStore: UserStore;
  client: ApolloClient<unknown>;
  isUpdateMode?: boolean;
  handleMenuChange: (index: number) => void;
  templatesLoading?: boolean;
}

export interface EXPORT_KEY {
  name: string;
  exportKey: string;
  key: string;
  willExport: boolean;
  headers: HEADER_EXPORT[] | null;
}

export interface EXPORT_FIELDS {
  [key: string]: {
    exportValue: string;
    headers?: Record<string, { exportValue: string }>;
  };
}

export interface HEADER_EXPORT {
  name: string;
  key: string;
  exportKey: string;
  willExport: boolean;
}

export interface RenderTree {
  id: string;
  name: string;
  children?: RenderTree[];
}

export interface ResultsRules {
  condition: string;
  field: string;
}

export interface ExportParameters {
  excludeSystemInfo: boolean;
  preventDuplicates: boolean;
  uniqueKey: SelectOptionDef[];
}

export interface RotationInfo {
  rotation: number;
  originalRotation: number;
}

export interface MenuItemData {
  uid?: string;
  label?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  callback?: (event: React.MouseEvent<HTMLElement>, item: MenuItemData) => void;
  items?: MenuItemData[];
  disabled?: boolean;
  sx?: SxProps;
}

export interface ChatLanguages {
  key: string;
  name: string;
}

export interface ChatGptMessage {
  answer: string | string[];
  createdAt: string;
  question: string;
  filter?: ChatGptMessageFilters;
  type: string;
}

export interface ChatGptMessageFilters {
  metadata: {
    field: string;
    operator: string;
    values: string[];
    type: string;
  }[];
}

export interface ChatGptSuggestions {
  question: string;
}

export interface FormattedOption {
  key: string | undefined;
  label: string;
  icon: unknown;
}

export interface DevSettingsParameters {
  key: string;
  options: [{ key: string; label: string }];
  value: string;
  type: string;
  name: string;
  description: string;
}

export interface DevSettings {
  [key: string]: string;
}

export type AppAbility = PureAbility<Actions>;
type Actions = "review" | "publish";

export interface MenuItem {
  path: string;
  component: React.FunctionComponent;
  abilityKey?: string;
  abilityAction?: string;
}

export interface InputOptions {
  category: string;
  key: string;
  type: string;
  value: string;
  isDynamicContent?: boolean; // TODO: Handle this after BE provides it
}

export interface Schema {
  type: string;
  properties?: { [key: string]: Schema };
  items?: Schema;
}

export interface NestedPropertyWithType {
  key: string;
  type: string;
}

export interface SchemaValidationResult {
  isValid: boolean;
  errorMessage?: string;
}

export interface NodeFilters {
  includeArrayNestedProperties?: boolean;
}

export interface NodeAnchor {
  id: string;
  anchor: HTMLElement;
}

export interface Variable {
  key: string;
  name: string;
  type: string;
  schema: Schema;
  nodeIdentifier: string;
  text?: string | boolean | null;
  isParent?: boolean;
  parentKey?: string;
  items?: { properties?: { [key: string]: Schema } };
  // TODO: Fetch when BE is ready
  // isDynamicContent?: string;
}

export interface DocumentField {
  type: string;
  name: string;
  description: string;
  key: string;
  text: string;
  box: DocumentZoneBox;
  pageIdentifier: string;
  score: number | null;
  manuallyAdded: false;
  skipValidation: false;
}

export interface EXPORTED_VARIABLE {
  [key: string]: {
    exportValue: string;
  };
}

export interface NodeRule {
  key: string;
  name: string;
  description: string;
  parameters: Record<string, unknown>;
}

export interface ContextSetting {
  key: string;
  parameters: Record<string, string>;
}
