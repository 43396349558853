import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import GroupsIcon from "@mui/icons-material/Groups";
import LanIcon from "@mui/icons-material/Lan";
import { SvgIconComponent } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";

import { appRoutes } from "../../../configs/routes";
import { AbilityTuple, MongoAbility, MongoQuery } from "@casl/ability";
import UserAccount from "../userProfile/UserAccount";
import Team from "../team/Team";
import { Subscription } from "./components/subscription/Subscription";
import Connections from "../connections/Connections";
import IndexingSettings from "../indexingSettings/IndexingSettings";

export type MenuItem = {
  name: string;
  icon: SvgIconComponent;
  path: string;
  component: React.FunctionComponent;
  abilityKey?: string;
  abilityAction?: string;
};

export const MENU_ITEMS: MenuItem[] = [
  {
    name: "myAccount",
    icon: PersonIcon,
    path: appRoutes.UserAccount(),
    component: UserAccount,
  },
  {
    name: "team",
    icon: GroupsIcon,
    path: appRoutes.Team(),
    component: Team,
  },
  {
    name: "subscription",
    icon: SubscriptionsIcon,
    path: appRoutes.AccountBilling(),
    component: Subscription,
    abilityKey: "subscription",
    abilityAction: "read",
  },
  {
    name: "connections",
    icon: LanIcon,
    path: appRoutes.Connections(),
    component: Connections,
    abilityKey: "connections",
    abilityAction: "read",
  },
  {
    name: "indexing",
    icon: LanIcon,
    path: appRoutes.Indexing(),
    component: IndexingSettings,
    abilityKey: "connections",
    abilityAction: "read",
  },
];

export const getSettingsMenuConfig = (
  permissions?: MongoAbility<AbilityTuple, MongoQuery>
): MenuItem[] => {
  if (!permissions) return [];

  return MENU_ITEMS.filter((item) => {
    if (
      !item.abilityAction ||
      !item.abilityKey ||
      permissions.can(item.abilityAction, item.abilityKey)
    ) {
      return true;
    }

    return false;
  });
};
