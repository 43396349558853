//Library imports
import React, { FC } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

// Design imports
import { makeStyles } from "@mui/styles";
import {
  alpha,
  styled,
  useTheme,
  Theme,
  Box,
  Grid,
  Toolbar,
  IconButton,
  AppBar as MuiAppBar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Internal imports
import { APP_IDS, DRAWER_STATE } from "../../types/constants";
import { useStores } from "../../stores/StoresProvider";
import Logo from "../../icons/Logo";
import SidebarAccountClosed from "./sidebar/account/AccountClosed";
import { appRoutes } from "../../configs/routes";
import Languages from "./sidebar/account/Languages";
import ReportIssueButton from "../pages/flow/reportIssues/ReportIssueButton";
import CoreTooltip from "../core/CoreTooltip";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== DRAWER_STATE.open,
})(({ theme }: { theme: Theme }) => ({
  zIndex: theme.zIndex.drawer - 1,
  boxShadow: "none",
  width: `100%`,
}));
interface Props {
  t: TFunction;
}
const AppTopbar: FC<Props> = observer(({ t }) => {
  const history = useHistory();
  const { mainStore } = useStores();
  const theme = useTheme();

  const useStyles = makeStyles({
    gridItemTitleContainer: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      width: "90%",
    },
    toolbar: {
      backgroundColor: "transparent",
      minHeight: "64px",
      boxShadow: "none",
    },
    boxHelp: {
      marginRight: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "auto",
      gap: "15px",
    },
    logo: {
      margin: "auto 0",
      cursor: "pointer",
    },
  });

  const classes = useStyles();

  const handleClickCollapse = () => {
    mainStore.toggleSidebar();
  };

  return (
    <AppBar
      position="fixed"
      theme={theme}
      id={APP_IDS.appTopBar}
      sx={{
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        backgroundImage: "none",
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item className={classes.gridItemTitleContainer}>
            {!mainStore.hideExpandSidebar && (
              <IconButton
                edge="start"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleClickCollapse}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Logo
              fullLogoClassName={classes.logo}
              fullLogoOnClick={() => history.push(appRoutes.Home())}
            />
            <Box className={classes.boxHelp}>
              <CoreTooltip title={t("reportIssueTooltip")}>
                <IconButton edge="start" aria-label="menu">
                  <ReportIssueButton isTopBar={true} />
                </IconButton>
              </CoreTooltip>
              <Languages t={t} />
              <SidebarAccountClosed t={t} />

              {/* <CoreTooltip title={`${t("questionMarkIcon_tooltip")}`}>
                <IconButton edge="start" aria-label="menu" sx={{ mr: 2 }}>
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </CoreTooltip> */}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

export default AppTopbar;
