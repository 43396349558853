import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";

import CoreButton from "../../../../core/CoreButton";
import { useStores } from "../../../../../stores/StoresProvider";
import { useNotification } from "../../../../../context/useNotification";
import { appRoutes } from "../../../../../configs/routes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  paperRoot: {
    padding: "15px 20px",
  },
  modalText: {
    textAlign: "center",
  },
});

export const CancelSubscriptionConfirmationModal: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const notification = useNotification();
  const { subscriptionStore } = useStores();
  const { t } = useTranslation("subscription");

  const [isOperationInProgress, setOperationInProgress] = useState(false);

  const cancelSubscription = () => {
    setOperationInProgress(true);

    subscriptionStore
      .cancelSubscription()
      .then(() => {
        notification.success(t("subscription_cancel_success"));
        history.push(appRoutes.AccountBilling());
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "subscription_cancel_fail"));
        setOperationInProgress(false);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.paperRoot }}
    >
      <DialogTitle>
        <Typography className={classes.modalText}>
          {t("subscription_cancel_confirm_modal_title")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2" className={classes.modalText}>
          {t("subscription_cancel_confirm_modal_subtitle")}
        </Typography>
      </DialogContent>

      <DialogActions>
        <CoreButton
          onClick={onClose}
          variant="neutral"
          disabled={isOperationInProgress}
        >
          {t("subscription_cancel_confirm_modal_cancel_btn")}
        </CoreButton>

        <CoreButton
          onClick={cancelSubscription}
          variant="contained"
          disabled={isOperationInProgress}
          isLoading={isOperationInProgress}
        >
          {t("subscription_cancel_confirm_modal_confirm_btn")}
        </CoreButton>
      </DialogActions>
    </Dialog>
  );
};
