import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./StoresProvider";
import { DateRange } from "@mui/lab/DateRangePicker";

import {
  GET_CONTEXT_EVENTS,
  GET_CONTEXT_EVENT,
  GET_CONTEXT_EVENTS_RESPONSE,
  GET_CONTEXT_EVENT_RESPONSE,
  GET_CONTEXT_EVENT_LOG_STACKTRACE_RESPONSE,
  GET_CONTEXT_EVENT_LOG_STACKTRACE,
} from "./queries/activityLog";
import { ActivityLogHelper } from "../components/pages/activityLogs/helpers/ActivityLogHelper";
import {
  ContextEvent,
  ContextEventLogStackTrace,
} from "../types/interfaces/contextEvent";
import {
  ActivityLogsFilters,
  DEFAULT_ACTIVITY_LOGS_FILTERS,
} from "../types/constants";

export class ActivityLogStore {
  root: RootStore;

  isPageLoading: boolean; // First load
  isRefreshData: boolean;
  isTracingLoading: boolean;
  activityLogs: ContextEvent[];
  totalData: number;
  totalPages: number;
  currentPage: number;
  filters: ActivityLogsFilters;
  pageSize = 20;

  constructor(root: RootStore) {
    this.root = root;

    this.isPageLoading = true;
    this.isTracingLoading = false;
    this.isRefreshData = false;
    // Table data
    this.activityLogs = [];
    this.totalData = 0;
    this.totalPages = 0;
    this.currentPage = 1;

    // Filters
    this.filters = DEFAULT_ACTIVITY_LOGS_FILTERS;

    makeAutoObservable(this, { root: false });
  }

  resetStore = (resetFilters = true) => {
    this.isPageLoading = true;
    this.isTracingLoading = false;
    this.activityLogs = [];
    this.totalData = 0;
    this.totalPages = 0;
    this.currentPage = 1;

    if (resetFilters) {
      this.filters = DEFAULT_ACTIVITY_LOGS_FILTERS;
    }
  };

  setIsRefreshData(value: boolean): void {
    this.isRefreshData = value;
  }

  loadActivityLog = async (extraFilters?: ActivityLogsFilters | undefined) => {
    this.isTracingLoading = true;

    try {
      const {
        data: { getContextEvents },
        errors,
      } = await this.root.client.query<GET_CONTEXT_EVENTS_RESPONSE>({
        query: GET_CONTEXT_EVENTS,
        variables: {
          page: this.currentPage,
          pageSize: this.pageSize,
          sortBy: "time",
          sortDirection: "desc",
          filters: ActivityLogHelper.formatQueryFilters({
            ...this.filters,
            ...(extraFilters || {}),
          }),
        },
      });

      if (!getContextEvents || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.isTracingLoading = false;
        this.isPageLoading = false;
        this.isRefreshData = false;
        this.activityLogs = getContextEvents.events;
        this.totalData = getContextEvents.totalEvents;
        this.totalPages = getContextEvents.amountOfPages;
      });
    } catch (error) {
      runInAction(() => {
        this.isTracingLoading = false;
        this.isPageLoading = false;
        this.activityLogs = [];
        this.isRefreshData = false;
      });

      throw error;
    }
  };

  getActivityLogItemData = (id: string): Promise<ContextEvent> => {
    this.isTracingLoading = false;

    return this.root.client
      .query<GET_CONTEXT_EVENT_RESPONSE>({
        query: GET_CONTEXT_EVENT,
        variables: {
          id,
          page: this.currentPage,
          pageSize: 20, // No need to have this configurable for infinite logs
        },
      })
      .then(({ data: { getContextEvent }, errors }) => {
        if (errors) {
          throw new Error();
        }
        return getContextEvent;
      });
  };

  getLogStackTrace = (
    eventIdentifier: string,
    logIdentifier: string
  ): Promise<ContextEventLogStackTrace> => {
    return this.root.client
      .query<GET_CONTEXT_EVENT_LOG_STACKTRACE_RESPONSE>({
        query: GET_CONTEXT_EVENT_LOG_STACKTRACE,
        variables: {
          eventIdentifier,
          logIdentifier,
        },
      })
      .then(({ data: { getContextEventLogStackTrace }, errors }) => {
        if (errors) {
          throw new Error();
        }
        return getContextEventLogStackTrace;
      });
  };

  setUrlFilters = (search: string) => {
    if (!search) {
      return;
    }

    const searchParams = new URLSearchParams(search);

    const flowFilter = searchParams?.get("flow");
    const documentFilter = searchParams?.get("document");

    if (flowFilter) {
      this.filters = {
        ...this.filters,
        flowsFilter: [flowFilter],
      };
    }

    if (documentFilter) {
      this.filters = {
        ...this.filters,
        inputSearchFilter: [documentFilter],
      };
    }
  };

  setCurrentPage = (newPage: number) => {
    this.currentPage = newPage;
  };

  setPageSize = (newPageSize: number) => {
    this.pageSize = newPageSize;
  };

  setFilters = (
    key: string,
    value: string[] | DateRange<Date> | null | unknown
  ) => {
    this.filters = {
      ...this.filters,
      [key]: value,
    };
    this.currentPage = 1;
  };

  resetFilters = (filtersToSkip?: string[]) => {
    if (filtersToSkip && filtersToSkip?.length > 0) {
      // Manually reset and exclude received properties
      let filtersToReset = {} as ActivityLogsFilters;

      Object.keys(DEFAULT_ACTIVITY_LOGS_FILTERS).forEach((filterKey) => {
        if (filtersToSkip.includes(filterKey)) {
          const value = (
            DEFAULT_ACTIVITY_LOGS_FILTERS as unknown as {
              [key: string]: unknown;
            }
          )[filterKey];

          filtersToReset = {
            ...filtersToReset,
            [filterKey]: value,
          };
        }
      });

      this.filters = filtersToReset;
    } else {
      this.filters = DEFAULT_ACTIVITY_LOGS_FILTERS;
    }

    this.currentPage = 1;
  };
}
