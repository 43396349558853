import { SelectOptionDef } from "../../../../../../types/interfaces";
import {
  BillingCountry,
  BILLING_FIELD_CONFIG,
} from "../../../../../../types/interfaces/subscription";

export const Fields = {
  name: "name",
  email: "email",
  phone: "phone",
  country: "country",
  city: "city",
  line1: "line1",
  line2: "line2",
  postalCode: "postalCode",
  // Company
  company: "company",
  taxId: "taxId",
  registrationNumber: "registrationNumber",
};

export const BILLING_FIELD_TYPES = {
  country_postal: "country_postal",
  input: "input",
  select: "select",
  email: "email",
};

export const MAIN_FIELDS = [
  {
    name: Fields.name,
    title: "billing_customer_name",
    isMandatory: true,
    type: BILLING_FIELD_TYPES.input,
    placeholder: "billing_customer_name_placeholder",
  },
  {
    name: Fields.email,
    title: "billing_email_title",
    description: "billing_email_subtitle",
    type: BILLING_FIELD_TYPES.input,
    isMandatory: true,
    placeholder: "billing_email_subtitle_placeholder",
  },
  {
    name: Fields.phone,
    title: "billing_customer_phone",
    placeholder: "billing_customer_phone_placeholder",
    type: BILLING_FIELD_TYPES.input,
    isMandatory: true,
    props: {
      type: "number",
    },
  },
] as BILLING_FIELD_CONFIG[];

export const getBillingInformationFields = (
  countries?: BillingCountry[]
): BILLING_FIELD_CONFIG[] => {
  return [
    {
      name: Fields.country,
      title: "billing_country_title",
      placeholder: "billing_country_title_placeholder",
      type: BILLING_FIELD_TYPES.select,
      props: {
        options:
          (countries?.map((op) => ({
            label: op.name,
            key: op.code,
          })) as SelectOptionDef[]) || [],
      },
      isMandatory: true,
    },
    {
      name: Fields.city,
      title: "billing_city_title",
      placeholder: "billing_city_title_placeholder",
      type: BILLING_FIELD_TYPES.country_postal,
      isMandatory: true,
    },
    {
      name: Fields.line1,
      title: "billing_line1_title",
      placeholder: "billing_line1_title_subtitle",
      type: BILLING_FIELD_TYPES.input,
      isMandatory: true,
    },
    {
      name: Fields.line2,
      title: "billing_line2_title",
      placeholder: "billing_line2_title_placeholder",
      type: BILLING_FIELD_TYPES.input,
    },
  ] as BILLING_FIELD_CONFIG[];
};

export const getCompanyInformationFields = (
  countries?: BillingCountry[],
  returnFullList = false
): BILLING_FIELD_CONFIG[] => {
  return [
    {
      name: Fields.name,
      title: "billing_company_name",
      placeholder: "billing_company_name",
      type: BILLING_FIELD_TYPES.input,
    },
    ...(!returnFullList
      ? []
      : [
          {
            name: Fields.email,
            title: "billing_company_email",
            isMandatory: true,
            placeholder: "billing_company_email_placeholder",
            type: BILLING_FIELD_TYPES.email,
          },
          {
            name: Fields.country,
            title: "billing_company_country",
            isMandatory: true,
            placeholder: "billing_company_country_placeholder",
            type: BILLING_FIELD_TYPES.select,
            props: {
              options:
                (countries?.map((op) => ({
                  label: op.name,
                  key: op.code,
                })) as SelectOptionDef[]) || [],
            },
          },
          {
            name: Fields.city,
            title: "billing_company_city",
            isMandatory: true,
            placeholder: "billing_company_city_placeholder",
            type: BILLING_FIELD_TYPES.country_postal,
          },
          {
            name: Fields.line1,
            isMandatory: true,
            title: "billing_company_line1",
            placeholder: "billing_company_line1",
            type: BILLING_FIELD_TYPES.input,
          },
          {
            name: Fields.line2,
            title: "billing_company_line2",
            placeholder: "billing_company_line2",
            type: BILLING_FIELD_TYPES.input,
          },
          {
            name: Fields.taxId,
            isMandatory: true,
            title: "billing_company_taxId",
            placeholder: "billing_company_taxId",
            type: BILLING_FIELD_TYPES.input,
          },
          {
            name: Fields.registrationNumber,
            title: "billing_company_registration_number",
            placeholder: "billing_company_registration_number",
            type: BILLING_FIELD_TYPES.input,
          },
        ]),
  ] as BILLING_FIELD_CONFIG[];
};
