import React, { FC, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import {
  BaseEdge,
  Edge,
  EdgeLabelRenderer,
  EdgeProps,
  getSmoothStepPath,
  useReactFlow,
} from "reactflow";

import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

import { IconButton, useTheme } from "@mui/material";

import { useStores } from "../../../../../stores/StoresProvider";
import { DIAGRAM_NODE_CATEGORIES } from "../../../../../types/constants";

export const DiagramLink: FC<EdgeProps> = observer(
  ({ id, sourceX, sourceY, targetX, targetY }) => {
    const theme = useTheme();
    const { flowSettingsStore } = useStores();
    const reactFlow = useReactFlow();
    const btnRef = useRef<HTMLDivElement | null>(null);

    const [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

    const classes = makeStyles({
      addIconButton: {
        borderRadius: "25px",
        border: `1px solid ${theme.palette.neutral.dark}`,
        backgroundColor: theme.palette.secondary.main,
        padding: "0",
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      addIcon: {
        fontSize: "14px",
        color: theme.palette.highlight.main,
      },
      edgeLabel: {
        position: "absolute",
        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
        fontSize: 12,
        pointerEvents: "all",
        cursor: "pointer",
        opacity: 0,
        zIndex: 100,
        "&:hover": {
          opacity: 1,
        },
        "&::before": {
          content: '""',
          position: "absolute",
          top: "-5px",
          bottom: "-5px",
          left: "-5px",
          right: "-5px",
          zIndex: -1,
          cursor: "pointer",
          pointerEvents: "all",
        },
      },
    })();

    const hidden = useMemo(() => {
      const currentEdge = reactFlow
        .getEdges()
        .find((edge) => edge.id === id) as Edge<unknown>;

      const targetNode = reactFlow.getNode(currentEdge.target);

      return targetNode?.type === DIAGRAM_NODE_CATEGORIES.placeholder;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, reactFlow, flowSettingsStore.layoutingNeeded]);

    const onEdgeClick = () => {
      flowSettingsStore.setDesignerModalAddMode(true);
      flowSettingsStore.setSelectedEdgeId(id);
    };

    return (
      <>
        <BaseEdge
          style={{
            stroke: theme.palette.neutral.dark,
          }}
          path={edgePath}
        />

        {flowSettingsStore.isDiagramEditable && !hidden && (
          <EdgeLabelRenderer>
            <div className={`${classes.edgeLabel} nodrag nopan`} ref={btnRef}>
              <div className="edgebutton" onClick={onEdgeClick}>
                <IconButton className={classes.addIconButton}>
                  <AddIcon className={classes.addIcon} />
                </IconButton>
              </div>
            </div>
          </EdgeLabelRenderer>
        )}
      </>
    );
  }
);
