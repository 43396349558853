import { makeAutoObservable, runInAction } from "mobx";
import Konva from "konva";
import { v4 as uuidv4 } from "uuid";
import { GraphQLError } from "graphql";
import { ApolloClient } from "@apollo/client";
import _union from "lodash/union";
import { isEmpty, uniqBy } from "lodash";
import { AxiosInstance } from "axios";

import {
  Document,
  DocumentZone,
  DocumentPage,
  FlowField,
  FlowDocuments,
  DocumentsResult,
  DocumentHistory,
  DocumentCategory,
  DocumentsFilterProps,
  DocumentLineItem,
  DocumentLineItemLine,
  DocumentLineItemRow,
  DocumentZoneBox,
  RotationInfo,
  Variable,
  DocumentField,
  DocumentMessageType,
} from "../types/interfaces";

import {
  CANVAS_ACTIONS,
  EDITABLE_ZONE_ACTIONS,
  DOCUMENT_STATUSES,
  VALIDATION_MODES,
  LOCAL_STORAGE_KEYS,
  DELETE_LINE_RANGE,
  FlowEnum,
} from "../types/constants";

import {
  QUERY_GET_FLOW_DOCUMENTS_RESULT,
  QUERY_GET_DOCUMENT_PAGE_RESULT,
  QUERY_GET_DOCUMENT_RESULT,
  QUERY_DOCUMENT_HISTORY_RESPONSE,
  GET_DOCUMENT,
  GET_FLOW_DOCUMENTS,
  GET_DOCUMENT_PAGE,
  GET_DOCUMENT_HISTORY,
  QUERY_DOCUMENT_HISTORY_EVENT_RESPONSE,
  GET_DOCUMENT_HISTORY_EVENT,
  GET_DOCUMENTS,
  QUERY_GET_DOCUMENT_DATA,
  GET_DOCUMENT_DATA,
} from "./queries/documents";

import CanvasHelper from "../helper/canvas/canvasHelper";

import {
  MUTATION_DELETE_DOCUMENTS_RESPONSE,
  MUTATION_MOVE_DOCUMENTS_RESPONSE,
  MUTATION_REJECT_DOCUMENT_RESPONSE,
  MUTATION_RETRY_DOCUMENT_RESPONSE,
  MUTATION_UPDATE_DOCUMENT_RESPONSE,
  REJECT_DOCUMENT,
  RETRY_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENTS,
  MOVE_DOCUMENTS,
  REORDER_DOCUMENT_PAGES,
  MUTATION_REORDER_DOCUMENT_PAGES_RESPONSE,
  EXTRACT_DOCUMENT_PAGES,
  MUTATION_EXTRACT_DOCUMENT_PAGES_RESPONSE,
  MUTATION_DELETE_DOCUMENT_PAGES_RESPONSE,
  DELETE_DOCUMENT_PAGES,
  MUTATION_UNLOCK_DOCUMENT_RESPONSE,
  UNLOCK_DOCUMENT,
  MUTATION_LINE_ITEMS_BORDER_TABLE_DETECT_RESPONSE,
  LINE_ITEMS_BORDER_TABLE_DETECT,
  MUTATION_RETRY_DOCUMENTS_RESPONSE,
  MUTATION_RERUN_DOCUMENTS_RESPONSE,
  RERUN_DOCUMENTS,
  RETRY_DOCUMENTS,
  MUTATION_GET_SIMILAR_DOCUMENTS_RESPONSE,
  GET_SIMILAR_DOCUMENTS,
  GET_SIMILAR_TEMPLATES,
  MUTATION_GET_SIMILAR_TEMPLATES_RESPONSE,
} from "./mutations/documents";

import { RootStore } from "./StoresProvider";

import { DocumentStageCoords } from "../types/types";
import ZoneHelper from "../helper/canvas/zoneHelper";
import { DOCUMENT_VALIDATION_TYPES, FIELD_DATA_TYPES } from "../types/enums";
import { backendRoutes } from "../configs/backendRoutes";
import { DOCUMENTS_SUBSCRIPTIONS } from "./subscriptions/documents";
import LineItemHeaderHelper from "../helper/canvas/lineItemHeaderHelper";
import LineItemsHelper from "../helper/canvas/lineItemsHelper";
import LocalStorageHelper from "../helper/localStorageHelper";

export class DocumentStore {
  root: RootStore;

  // View page
  currentFlowDocuments: FlowDocuments = {
    documents: [],
    totalDocuments: 0,
    amountOfPages: 0,
    currentPage: 0,
  };

  selectedDocuments: Document[] = [];
  documentsCount = 0;
  loadingDocuments = false;
  previewID: string | null = null;
  tableFilters: string[] = [];
  sortBy: string | null = null;
  sortDirection: string | null = null;
  currentTableFilters: { [key: string]: number | string[] } = {};
  viewMode: null | boolean = null;
  isUploadModalOpen = false;
  isUploadTableInProgress = false;
  temporaryAction: CANVAS_ACTIONS | null = null;
  newDocumentPagesOrder: string[] = [];
  movingLineIndex: {
    position: [[number, number], [number, number]];
    index: number;
  } | null = null;
  mousePos: { x: number; y: number } | null = null;
  shouldUpdateDocumentCoords: Date | null = null;

  // Validation page
  validationMode = VALIDATION_MODES.advanced;
  isDocumentLoading = false;
  focusedZones: string[] = []; // Zones focused/selected in canvas
  drawingZone: DocumentZone | undefined; // The zone which is being drawn in the canvas
  document: Document | null = null; // Full document
  focusedFieldCanvas: string | null = null; // Focused field in editor section
  //TODO: To be removed
  documentFields: DocumentZone[] | null = null;
  fields: DocumentField[] = [];
  documentCategories: DocumentCategory[] = [];
  documentPage: DocumentPage | null = null; // Current document page
  canvasZones: DocumentZone[] = []; // Zones specific for a page -documentPage
  documentRotation: { [key: string]: number } = {}; // Rotation, page based
  updatedRotations: { [key: string]: RotationInfo } = {}; // Updated rotations, page based
  canvasAction: CANVAS_ACTIONS = CANVAS_ACTIONS.pan; // Enabled canvas action
  fitAction: CANVAS_ACTIONS | undefined = CANVAS_ACTIONS.fitWidth; // Document fit
  pageLoading = false;
  isMouseOnCanvas = false;
  initialCanvasZones: DocumentZone[] = [];
  initialDocumentFields: DocumentZone[] | null = null;
  updatingPage = false;
  canvasScaleSize = 1;
  scaleCoords: DocumentStageCoords = { x: 0, y: 0 };
  expandedThumbPreviewer = true;
  multiSelectMode = false;
  coordMap = {} as { [key: string]: string };
  isOcrVisible = false;
  isTransforming = false;
  isGetNextDocumentChecked = true;
  lastSeenDocument: string | null = null; // Used for document history
  isRejectReasonsPopupOpen = false;
  disabledMode = false;
  ocrSearchQuery = "";

  // LineItems
  isLineItemBorderFetching = false; // Used for detecting table
  lineItems: DocumentLineItem[] = []; // Line items (Tables)
  draftLines: DocumentLineItemLine[] = []; // Used when moving lines (for better performance, lines were separated from root object)
  lineItemsMode = false; // Line items mode
  currentEditingCell: { rowIndex: number; colKey: string } | null = null;
  newLineConfig: DocumentLineItemLine | null = null; // Used for splitting a table column (the new column must be configured)
  lineItemHeaderInUse: string | null = null; // Used for adding an unused lineItem header (add it to flow)
  lineItemHeaderTypeUpdate: string | null = null; // Used for opening a drawer for ignore header or replace it
  manualLineItemMode = false;
  selectedPages: string[] = []; // Used on Caanvas page thumbnails navigation
  // Error panel
  messagePanelData: null | DocumentMessageType[] = null;

  constructor(root: RootStore) {
    const { fitAction } = CanvasHelper.getLocalStorageConfig();

    this.root = root;
    this.fitAction = (fitAction || CANVAS_ACTIONS.fitWidth) as
      | CANVAS_ACTIONS
      | undefined;

    makeAutoObservable(this);
  }

  get hoveredDocument(): Document | undefined {
    if (
      !this.previewID ||
      !this.currentFlowDocuments.documents ||
      this.currentFlowDocuments.documents?.length === 0
    ) {
      return undefined;
    }

    return this.currentFlowDocuments.documents?.find(
      (item) => item.identifier === this.previewID
    );
  }

  get documentStatus(): string | undefined {
    return this.document?.status?.type;
  }

  get isDocumentValid(): boolean {
    return !!(this.document && this.document.identifier);
  }

  get isSimpleMode(): boolean {
    return this.validationMode === VALIDATION_MODES.simple;
  }

  get isQaMode(): boolean {
    // Maybe define later
    return window?.location?.pathname?.includes("/qa/");
  }

  get isLineItemsMode(): boolean {
    return this.lineItems.some(
      (lineItem) =>
        lineItem.key === this.focusedFieldCanvas ||
        lineItem.type === this.focusedFieldCanvas
    );
  }

  get hasLineItemFieldTypes(): boolean {
    return this.root.flowStore.flow?.fields?.some(
      (field) => field.dataType?.key === FIELD_DATA_TYPES.tableDataType
    ) as boolean;
  }

  get focusedLineItem(): DocumentLineItem | undefined {
    return this.lineItems?.find(
      (lineItem) =>
        //TODO: Check this
        lineItem.type === this.focusedFieldCanvas ||
        (lineItem.key === this.focusedFieldCanvas &&
          lineItem.pageIdentifier === this.documentPage?.identifier)
    );
  }

  get focusedFlowField(): FlowField | undefined {
    return this.root.flowStore.flow?.fields?.find(
      (item) => item.key === this.focusedFieldCanvas
    );
  }

  get focusedLineItemHeaders(): FlowField[] {
    const fieldRef = this.root.flowStore.flow?.fields?.find(
      (field) => field.key === this.focusedFieldCanvas
    );
    return (fieldRef?.dataType?.parameters?.headers ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (fieldRef as unknown as any)?.["headers"] ||
      []) as FlowField[];
  }

  get focusedDocumentLineItemHeaders(): { key: string; type: string }[] {
    const fieldRef = this.lineItems?.find(
      (lineItem) => lineItem.type === this.focusedFieldCanvas
    );
    return (fieldRef?.headers || []) as { key: string; type: string }[];
  }

  get unusedLineItemHeaders(): DocumentZone[] | undefined {
    const lineItemHeaders = this.focusedLineItem?.headers || [];
    const configuredHeaders = this.focusedFlowField?.dataType?.parameters
      ?.headers as DocumentZone[];

    // Key checks
    const lineItemHeaderKeys = lineItemHeaders?.map((item) => item.key);
    const configuredHeaderKeys = configuredHeaders?.map((item) => item.key);

    const unusedLineItemHeaders = lineItemHeaders?.filter(
      (item) => !configuredHeaderKeys?.includes(item.key as string)
    );
    const unusedConfiguredHeaders = configuredHeaders?.filter(
      (item) => !lineItemHeaderKeys?.includes(item.key)
    );

    // Unused columns
    return [
      ...uniqBy(
        [...(unusedLineItemHeaders || []), ...(unusedConfiguredHeaders || [])],
        "key"
      ),
    ];
  }

  get usedLineItemHeaders(): DocumentZone[] | undefined {
    const lineItemHeaders = this.focusedLineItem?.headers || [];
    const configuredHeaders = this.focusedFlowField?.dataType?.parameters
      ?.headers as DocumentZone[];

    // Key checks
    const lineItemHeaderKeys = lineItemHeaders?.map((item) => item.key);
    const configuredHeaderKeys = configuredHeaders?.map((item) => item.key);

    const usedLineItemHeaders = lineItemHeaders?.filter((item) =>
      configuredHeaderKeys?.includes(item.key as string)
    );

    const usedConfiguredHeaders = configuredHeaders?.filter((item) =>
      lineItemHeaderKeys?.includes(item.key as string)
    );

    // used columns
    return [
      ...uniqBy(
        [...(usedLineItemHeaders || []), ...(usedConfiguredHeaders || [])],
        "key"
      ),
    ];
  }

  get originalSplitColumn(): DocumentZone | FlowField | undefined | null {
    if (!this.focusedFieldCanvas) {
      return null;
    }

    const flowHeaders = this.focusedFlowField?.dataType?.parameters
      ?.headers as FlowField[];
    const lineItem = this.focusedLineItem as DocumentLineItem;

    const originalColKey = CanvasHelper.getColumnKeyToSplit(
      this.newLineConfig as DocumentLineItemLine,
      lineItem
    ) as string;

    if (!originalColKey) {
      return null;
    }

    return [...flowHeaders, ...(lineItem?.headers || [])]?.find(
      (item) => item.key === originalColKey
    );
  }

  get rotation(): number {
    const currentPageIdentifier = this.documentPage?.identifier || "";

    return this.documentRotation?.[currentPageIdentifier] || 0;
  }

  get documentHasZones(): boolean {
    return this.canvasZones && this.canvasZones?.length > 0;
  }

  setMessagePanelData(data: DocumentMessageType[] | null = null) {
    this.messagePanelData = data;
  }

  setSelectedPages = (selectedPages: string[]) => {
    this.selectedPages = selectedPages;

    // also reset additional settings when page is changed
    this.messagePanelData = null;
  };

  setShouldUpdateDocumentCoords = (date: Date | null) => {
    this.shouldUpdateDocumentCoords = date;
  };

  setOcrSearchQuery = (query: string) => {
    this.ocrSearchQuery = query;
  };

  setMovingLineIndex = (
    movingLine: {
      position: [[number, number], [number, number]];
      index: number;
    } | null
  ) => {
    this.movingLineIndex = movingLine;
    this.draftLines = movingLine
      ? this.focusedLineItem?.coords?.lines || []
      : [];
  };

  setCanvasMousePos = (pos: { x: number; y: number } | null) => {
    this.mousePos = pos;
  };

  setLineItemsMode = (mode: boolean) => {
    this.lineItemsMode = mode;
  };

  setDisabledMode = (mode: boolean) => {
    this.disabledMode = mode;
    this.setViewMode(mode);
  };

  focusLineItem = (fieldKey: string) => {
    const focusedLineItemIdentifier = this.lineItems?.find(
      (item) =>
        item.type === fieldKey &&
        item.pageIdentifier === this.documentPage?.identifier
    )?.type;

    if (focusedLineItemIdentifier) {
      this.focusedZones = [focusedLineItemIdentifier];
    }

    this.setFocusFieldCanvas(fieldKey);
    this.setLineItemsMode(true);
  };

  setLines = (
    lineItemIdentifier: string,
    line: DocumentLineItemLine,
    index: number
  ) => {
    this.draftLines = this.draftLines.map((item, i) => {
      if (i === index) {
        return line;
      }
      return item;
    });
  };

  setLineItems = (lineItems: DocumentLineItem[]) => {
    this.lineItems = lineItems;
  };

  setIsUploadTableInProgress = (isInProgress: boolean) => {
    this.isUploadTableInProgress = isInProgress;
  };

  setCurrentEditingCell = (
    cell: { rowIndex: number; colKey: string } | null
  ) => {
    this.currentEditingCell = cell;
  };

  setManualLineItemMode = (manualLineItemMode: boolean) => {
    this.manualLineItemMode = manualLineItemMode;
  };

  checkAllCurrentFlowDocuments = (isChecked: boolean) => {
    const docs = this.currentFlowDocuments.documents;

    if (docs.length > 0) {
      if (isChecked) {
        this.selectedDocuments = this.selectedDocuments.filter(
          (id) => !docs.map((doc) => doc).includes(id)
        );
      } else {
        this.selectedDocuments = _union(
          this.selectedDocuments,
          docs.map((doc) => doc)
        );
      }
    }
  };

  setGetNextDocumentChecked = (isChecked: boolean) => {
    this.isGetNextDocumentChecked = isChecked;
  };

  checkDocument = (doc: Document) => {
    if (
      this.selectedDocuments.some(
        (selectedDoc) => selectedDoc.identifier === doc.identifier
      )
    ) {
      this.selectedDocuments = this.selectedDocuments.filter(
        (item) => item.identifier !== doc.identifier
      );
    } else {
      this.selectedDocuments = [...this.selectedDocuments, doc];
    }
  };

  checkDocuments = (documents: Document[]) => {
    this.selectedDocuments = documents;
  };

  uncheckAllDocuments = () => {
    this.selectedDocuments = [];
  };

  setLastSeenDocument = (id: string | null) => {
    this.lastSeenDocument = id;
  };

  clearStore = () => {
    // this.documents = [];
    this.documentsCount = 0;
  };

  resetDocument = () => {
    this.document = null;
    this.viewMode = null;
    this.documentPage = null;
    this.messagePanelData = null;
  };

  setDocumentCategories = (categories: DocumentCategory[]) => {
    this.documentCategories = categories;
  };

  resetCurrentFlowDocuments = (): void => {
    this.currentFlowDocuments = {
      documents: [],
      totalDocuments: 0,
      amountOfPages: 0,
      currentPage: 0,
    };
    this.loadingDocuments = false;
  };

  toggleUploadModal = (value: boolean): void => {
    this.isUploadModalOpen = value;
  };

  toggleLineItemHeaderUse = (headerKey: string | null): void => {
    this.lineItemHeaderInUse = headerKey;
  };

  toggleLineItemHeaderTypeChange = (headerKey: string | null): void => {
    this.lineItemHeaderTypeUpdate = headerKey;
  };

  getRow = (rowYCoord: number): DocumentLineItemRow | null => {
    let lineItemRow: DocumentLineItemRow | null = null;
    this.lineItems.forEach((lineItem) => {
      if (
        lineItem.type === this.focusedFieldCanvas &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        lineItemRow = LineItemsHelper.getRow(lineItem, rowYCoord);
      }
    });
    return lineItemRow;
  };

  ignoreLineItemRow = (rowYCoord: number): void => {
    this.lineItems = this.lineItems.map((lineItem) => {
      if (
        lineItem.type === this.focusedFieldCanvas &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        return LineItemsHelper.ignoreOrEnableRow(lineItem, rowYCoord);
      }

      return lineItem;
    });
  };

  isRowIgnored = (rowYCoord: number): boolean => {
    let isIgnored = false;

    this.lineItems.forEach((lineItem) => {
      if (
        lineItem.type === this.focusedFieldCanvas &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        isIgnored = LineItemsHelper.isRowIgnored(lineItem, rowYCoord);
      }
    });

    return isIgnored;
  };

  isHeaderIgnored = (headerKey: string): boolean => {
    const unusedHeaders = this.unusedLineItemHeaders?.map(
      (header) => header.key
    );

    if (unusedHeaders?.includes(headerKey ?? "")) {
      return true;
    }
    return false;
  };

  setNewLineDraft = (newLineConfig: DocumentLineItemLine | null): void => {
    this.newLineConfig = newLineConfig;
  };

  setInitialCanvasZones(
    canvasZones: DocumentZone[],
    documentFields: DocumentZone[] | null
  ): void {
    this.initialCanvasZones = canvasZones;
    this.initialDocumentFields = documentFields;
  }

  handleCancelFocusedFieldCanvas = (resetAction: boolean) => {
    this.canvasZones = this.initialCanvasZones;
    //TODO: Fix this
    // this.documentFields = this.initialDocumentFields;
    this.clearFocusZones();
    this.focusedFieldCanvas = null;

    if (resetAction) {
      this.changeCanvasAction(CANVAS_ACTIONS.pan);
    }
  };

  getFlowDocuments = async (
    flowId: string,
    page = 1,
    pageSize?: number,
    filters?: DocumentsFilterProps
  ) => {
    const {
      data: { getFlowDocuments },
      errors,
    } = await this.root.client.query<QUERY_GET_FLOW_DOCUMENTS_RESULT>({
      query: GET_FLOW_DOCUMENTS,
      variables: {
        flowId,
        page,
        pageSize: pageSize || 10,
        filters: filters || {},
      },
    });

    if (!getFlowDocuments || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getFlowDocuments;
  };

  loadFlowDocuments = async (
    flowId: string,
    page = 1,
    pageSize?: number,
    customFilters: DocumentsFilterProps = {}
  ) => {
    try {
      this.loadingDocuments = true;

      const filters = this.root.flowStore.filters?.flowFilters;

      const { sortBy, sortDirection, ...others } =
        (filters as unknown as DocumentsFilterProps) ?? {};

      const newFilters = {
        sortBy,
        sortDirection,
        filters: { ...others, ...customFilters },
      };

      const getFlowDocuments = await this.getFlowDocuments(
        flowId,
        page,
        pageSize,
        newFilters
      );

      // If user is not in same page, then no further actions
      if (!window.location.pathname.includes(flowId)) {
        return;
      }

      runInAction(() => {
        this.currentFlowDocuments = {
          ...getFlowDocuments,
          documents: [...(getFlowDocuments.documents ?? [])],
        };
        this.loadingDocuments = false;
      });
    } catch (error) {
      runInAction(() => {
        this.currentFlowDocuments = {
          documents: [],
          totalDocuments: 0,
          amountOfPages: 0,
          currentPage: 0,
        };
        this.loadingDocuments = false;
      });
    }
  };

  setMultiselect = (multiselect: boolean) => {
    this.multiSelectMode = multiselect;
  };

  setPreview = (id: string | null) => {
    this.previewID = id;
  };

  setTableFilters = (
    filters: string[],
    sortBy: string,
    sortDirection: string,
    enableSaveInStorage = false
  ) => {
    this.tableFilters = filters;
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;

    if (enableSaveInStorage) {
      // Save in localStorage
      const storageKey =
        this.root.flowStore.flowSummary?.identifier || "All flows";
      LocalStorageHelper.setValue(storageKey, {
        sortBy,
        sortDirection,
        tableHeaders: filters,
      });
    }
  };

  setCurrentTableFilters = (currentFilters: {
    [key: string]: number | string[];
  }) => {
    this.currentTableFilters = currentFilters;
  };

  setDocumentRotation = (documentRotation: { [key: string]: number }) => {
    this.documentRotation = documentRotation;
  };

  setViewMode = (viewMode: boolean | null) => {
    this.viewMode = viewMode;
  };

  setNewDocumentPagesOrder = (pages: string[]) => {
    this.newDocumentPagesOrder = pages;
  };

  checkIfCategoryHasDocuments(statusCategory: string) {
    return this.currentFlowDocuments.documents?.some(
      (doc) => doc.status.type === statusCategory
    );
  }

  // DOCUMENT VALIDATION

  loadDocumentPage = async (documentId: string, pageIdentifier: string) => {
    try {
      this.pageLoading = true;
      this.documentPage = null;

      const {
        data: { getDocumentPage },
        errors,
      } = await this.root.client.query<QUERY_GET_DOCUMENT_PAGE_RESULT>({
        query: GET_DOCUMENT_PAGE,
        variables: { pageIdentifier, documentId },
      });

      if (!getDocumentPage || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
      const { ocrData, ...otherPageDetails } = getDocumentPage;

      runInAction(() => {
        this.documentPage = {
          ...otherPageDetails,
          pageInformation: {
            width: otherPageDetails.pageInformation.width ?? 0,
            height: otherPageDetails.pageInformation.height ?? 0,
          },
        } as DocumentPage;
        this.canvasZones = CanvasHelper.apiZoneToZone(
          ocrData,
          this.documentPage?.rotation,
          this.documentPage?.pageInformation?.width,
          this.documentPage?.pageInformation?.height,
          true,
          this.documentFields,
          otherPageDetails.identifier
        );
        this.isDocumentLoading = false;
        this.pageLoading = false;
      });
    } catch {
      runInAction(() => {
        this.isDocumentLoading = false;
        this.pageLoading = false;
      });
    }
  };

  loadDocument = async (
    client: ApolloClient<unknown>,
    documentId?: string,
    isPageLoading = true,
    flowId?: string,
    documentIdentifier?: string,
    pageIdentifier?: string // Page to load (currently received through url)
  ) => {
    try {
      this.isDocumentLoading = isPageLoading;

      const filters = this.root.flowStore.filters?.flowFilters;

      const { sortBy, sortDirection, ...others } =
        (filters as unknown as DocumentsFilterProps) ?? {};

      const newFilters = {
        sortBy,
        sortDirection,
        filters: { ...others },
      };

      const {
        data: { getFlowDocument },
        errors,
      } = await client.query<QUERY_GET_DOCUMENT_RESULT>({
        query: GET_DOCUMENT,
        variables: {
          documentId,
          flowId: flowId,
          state: this.isQaMode
            ? DOCUMENT_VALIDATION_TYPES.qa
            : DOCUMENT_VALIDATION_TYPES.validation,
          filters: newFilters,
          documentIdentifier,
        },
      });

      if (
        (!getFlowDocument && getFlowDocument !== null) ||
        (errors && errors?.[0])
      ) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      if (!getFlowDocument) {
        runInAction(() => {
          this.document = null;
          this.isDocumentLoading = false;
        });
        return false;
      }

      let flowDocumentData = getFlowDocument || {};
      const documentData = await this.loadDocumentData(
        documentId || documentIdentifier
      );

      if (documentData && documentData?.data && documentData?.data?.fields) {
        flowDocumentData = {
          ...(flowDocumentData || {}),
          fields: documentData.data.fields as unknown as Variable[],
        };
      }

      if (documentData && documentData?.data && documentData?.data?.lineItems) {
        flowDocumentData = {
          ...(flowDocumentData || {}),
          lineItems: LineItemsHelper.injectCellErrors(
            documentData.data.lineItems
          ),
        };
      }

      runInAction(() => {
        this.document = flowDocumentData;
        this.fields = flowDocumentData.fields as unknown as DocumentField[];
        this.lineItems = flowDocumentData.lineItems;
        this.documentCategories = flowDocumentData.categories;
        this.documentRotation = flowDocumentData.rotation ?? {};
        this.updatedRotations = {};

        this.focusedFieldCanvas = null;
        this.newDocumentPagesOrder = flowDocumentData.pages;
        if (
          flowDocumentData.status.type !== DOCUMENT_STATUSES.requiresUserInput
        ) {
          this.validationMode = VALIDATION_MODES.advanced;
        }
        this.viewMode = this.root.userStore.currentUserPermissions?.can(
          "validateDocuments",
          "flows"
        )
          ? flowDocumentData.isViewMode
          : true;
        this.lastSeenDocument = flowDocumentData.identifier;
      });

      // Load OCR data for first page
      if (flowDocumentData.pages?.length > 0) {
        this.selectedPages = [pageIdentifier || flowDocumentData.pages[0]];
        void this.loadDocumentPage(
          flowDocumentData.identifier,
          pageIdentifier || flowDocumentData.pages[0]
        ).then(() => {
          this.setDocumentRotation({
            ...this.documentRotation,
            [this.documentPage?.identifier || ""]:
              this.documentPage?.rotation || 0,
          });
        });
      }

      return true;
    } catch (error) {
      runInAction(() => {
        // Also clear previous loaded document
        this.isDocumentLoading = false;
        this.document = null;
        this.canvasZones = [];
        this.documentFields = [];
        this.focusedFieldCanvas = null;
      });
      return false;
    }
  };

  loadDocumentData = async (documentId?: string) => {
    const {
      data: { getFlowDocumentData },
      errors,
    } = await this.root.client.query<QUERY_GET_DOCUMENT_DATA>({
      query: GET_DOCUMENT_DATA,
      variables: { documentId: documentId },
    });

    if (errors && errors?.[0]) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getFlowDocumentData;
  };

  changeCanvasAction = (action: CANVAS_ACTIONS, actionChangeOnly?: boolean) => {
    if (
      !actionChangeOnly &&
      this.focusedFieldCanvas &&
      this.canvasAction !== action &&
      !EDITABLE_ZONE_ACTIONS.includes(action)
    ) {
      // Also clear focused field if enabled
      this.focusedFieldCanvas = null;
      this.lineItemsMode = false;
    }

    this.canvasAction = action;
  };

  setMouseOnCanvas = (val: boolean) => {
    this.isMouseOnCanvas = val;
  };

  changeTemporaryAction = (action: CANVAS_ACTIONS | null) => {
    this.temporaryAction = action;
  };

  setFitAction = (action: CANVAS_ACTIONS) => {
    this.fitAction = action;

    localStorage.setItem(LOCAL_STORAGE_KEYS.validationFitAction, action);
  };

  setRotation = (rotation: number, notifier?: () => void) => {
    const currentPageIdentifier = this.documentPage?.identifier || "";

    if (currentPageIdentifier) {
      if (this.updatedRotations?.[currentPageIdentifier]) {
        this.updatedRotations[currentPageIdentifier].rotation = rotation;
      } else {
        this.updatedRotations = {
          ...this.updatedRotations,
          [currentPageIdentifier]: {
            rotation: rotation,
            originalRotation: this.rotation,
          },
        };
      }

      const rotationUpdated =
        this.updatedRotations[currentPageIdentifier].rotation !==
        this.updatedRotations[currentPageIdentifier].originalRotation;

      if (notifier && rotationUpdated) {
        notifier();
      }

      // Update view mode and disabled mode only if document can be edited
      if (this.document?.status?.type === DOCUMENT_STATUSES.requiresUserInput)
        this.setDisabledMode(rotationUpdated);

      this.documentRotation = {
        ...(this.documentRotation || {}),
        [currentPageIdentifier]: rotation,
      };
    }
  };

  resetRotation = () => {
    const currentPageIdentifier = this.documentPage?.identifier || "";

    this.setRotation(
      this.updatedRotations?.[currentPageIdentifier]?.originalRotation
    );
  };

  setFocusZones = (newValues: string[] | null) => {
    if (newValues && newValues.length > 0) {
      this.focusedZones = newValues;
    } else {
      this.focusedZones = [];
    }
  };

  setZoneTransforming = (value: boolean): void => {
    this.isTransforming = value;
  };

  setFocusZone = (newValue: string | null, errorNotification?: () => void) => {
    if (!newValue) {
      this.clearFocusZones();
      return;
    }

    const currentZone = this.canvasZones.find(
      (zone) => zone.identifier === newValue
    );

    if (currentZone?.category || currentZone?.type) {
      if (!this.multiSelectMode) {
        const currentCategory = currentZone.category ?? currentZone.type ?? "";
        this.focusedFieldCanvas = currentCategory;
        this.focusedZones = this.canvasZones
          .filter(
            (zone) =>
              zone.category === currentCategory || zone.type === currentCategory
          )
          .map((zone) => zone.identifier);
      } else {
        if (errorNotification) {
          errorNotification();
        }
      }
      return;
    } else {
      if (this.multiSelectMode) {
        const docField = this.documentFields?.find(
          (item) => item.identifier === newValue
        );

        if (docField) {
          this.handleCancelFocusedFieldCanvas(false);
          this.focusedFieldCanvas = docField.category || docField.type || "";
          this.focusedZones = [newValue];
        } else {
          const selectedDoc = this.documentFields?.find(
            (item) =>
              item.type === this.focusedFieldCanvas ||
              item.category === this.focusedFieldCanvas
          );
          if (selectedDoc?.pageIdentifier !== currentZone?.pageIdentifier) {
            this.focusedZones = [];
          }
          this.focusedZones = [...(this.focusedZones || []), newValue];
        }
      } else {
        this.focusedZones = [newValue];
      }
    }
    // Set category for the new selected zone
    this.canvasZones = this.canvasZones.map((zone) =>
      zone.identifier === newValue
        ? {
            ...zone,
            pageIdentifier: this.documentPage?.identifier,
            category: this.focusedFieldCanvas as string,
          }
        : { ...zone, pageIdentifier: this.documentPage?.identifier }
    );
  };

  setIsRejectReasonsPopupOpen = (value: boolean) => {
    this.isRejectReasonsPopupOpen = value;
  };

  clearFocusZones = () => {
    this.focusedZones = [];
  };

  /**
   *
   * @param zone Modified zone
   * @param resetText When manually changing the text of the zone, the zone intersecting should be disabled in order to keep the new text update
   * @returns
   */
  updateZone = (
    zone: DocumentZone,
    e?: Konva.KonvaEventObject<Event>,
    edit?: boolean
  ) => {
    const fields = this.documentFields || [];
    const isZoneSet = fields?.some(
      (field) => field.type === zone.type || field.type === zone.key
    );

    let updatedZone = isZoneSet
      ? zone
      : {
          ...zone,
          pageIdentifier: this.documentPage?.identifier,
          type: zone?.type || (zone as unknown as FlowField)?.["key"],
        };

    // Extract intersected zones
    let scaledZone = updatedZone;

    if (e) {
      // Zone must be scaled in order to extract the correct intersected zones
      scaledZone = ZoneHelper.preprocessZone(
        e,
        updatedZone,
        this.scaleCoords,
        this.canvasScaleSize,
        edit
      );
    }

    if (!isEmpty(scaledZone.box)) {
      const intersectedZones = this.canvasZones
        ?.filter((zone) => ZoneHelper.intersectRect(scaledZone.box, zone.box))
        ?.sort((a, b) => a.index - b.index);

      if (!this.isSimpleMode) {
        if (!zone.disableTextAlter) {
          updatedZone = {
            ...updatedZone,
            box: {
              ...updatedZone.box,
              width: scaledZone.box.width * (scaledZone.box?.scaleX || 1),
              height: scaledZone.box.height * (scaledZone.box?.scaleY || 1),
            },
            text: intersectedZones?.map((zone) => zone.text)?.join(" ") || "",
          };
        }

        const intersectedZoneIds = intersectedZones.map(
          (item) => item.identifier
        );

        // Clear previous selections and set the new ones if available
        this.canvasZones = this.canvasZones.map((originalZone) =>
          intersectedZoneIds.includes(originalZone.identifier)
            ? {
                ...originalZone,
                category: this.focusedFieldCanvas as string,
                pageIdentifier: this.documentPage?.identifier,
              }
            : originalZone.category === this.focusedFieldCanvas
            ? { ...originalZone, category: "" }
            : originalZone
        );
        this.focusedZones = [updatedZone.identifier];
      }
    } else {
      updatedZone = {
        ...updatedZone,
        text: zone.text,
      };
    }

    if (isZoneSet) {
      // Replace existing one
      this.documentFields = fields.map((field) =>
        zone.type === field.type &&
        field.pageIdentifier === this.documentPage?.identifier
          ? updatedZone
          : field
      );
      return;
    }
    this.documentFields = [...fields, updatedZone];
  };

  deleteZone = (zoneIdentifiers: string[]) => {
    const fields = this.documentFields || [];
    if (!fields || fields?.length === 0) {
      return;
    }

    this.documentFields = fields.filter(
      (zone) => !zoneIdentifiers.includes(zone.type)
    );

    this.canvasZones = this.canvasZones?.map((zone) =>
      zoneIdentifiers.includes(zone.identifier)
        ? { ...zone, category: "" }
        : zone
    );
  };

  removeFieldZones = () => {
    const fields = this.fields || [];
    if (!fields || fields?.length === 0) {
      return;
    }

    this.fields = this.fields?.map((field) =>
      field.key === this.focusedFieldCanvas ? { ...field, text: null } : field
    ) as DocumentField[];

    //TODO: Fix this after zone implementation
    // Clear canvas zones if set in simple mode
    // if (
    //   this.canvasZones?.some(
    //     (zone) => zone.category === this.focusedFieldCanvas
    //   )
    // ) {
    //   const selectedZoneIds = this.canvasZones
    //     .filter((zone) => zone.category === this.focusedFieldCanvas)
    //     ?.map((zone) => zone.identifier);

    //   this.canvasZones = this.canvasZones.map((zone) =>
    //     zone.category === this.focusedFieldCanvas
    //       ? { ...zone, category: "" }
    //       : zone
    //   );

    //   // Clear selections if exists
    //   if (
    //     this.focusedZones.some((identifier) =>
    //       selectedZoneIds.includes(identifier)
    //     )
    //   ) {
    //     this.focusedZones = this.focusedZones.filter(
    //       (identifier) => !selectedZoneIds.includes(identifier)
    //     );
    //   }
    // }
  };

  initDrawingZone = (zone: DocumentZone) => {
    const zoneIdentifier = uuidv4();
    this.drawingZone = {
      ...(zone || {}),
      default: false,
      text: "",
      score: 1, // TODO: Remove after AP update
      textScore: 1,
      segmentationScore: 1,
      identifier: zoneIdentifier,
      pageIdentifier: this.documentPage?.identifier as string,
    };
  };

  setScale = (scale: number) => {
    this.canvasScaleSize = scale;

    localStorage.setItem(LOCAL_STORAGE_KEYS.validationScaleFactor, `${scale}`);
  };

  setScaleCoords = (scaleCoords: { x: number; y: number }) => {
    this.scaleCoords = scaleCoords;
  };

  setDrawingZone = (zone: DocumentZone | undefined) => {
    this.drawingZone = zone;
  };

  setFocusFieldCanvas = (fieldKey: string | null) => {
    this.focusedFieldCanvas = fieldKey;
  };

  setValidationMode = (newMode: VALIDATION_MODES) => {
    this.validationMode = newMode;
  };

  setFields = (fields: DocumentField[]) => {
    this.fields = fields;
  };

  linkCurrentSelections = () => {
    const category = this.focusedFieldCanvas;
    const linkedIdentifiers = this.focusedZones;

    if (!category || linkedIdentifiers?.length === 0) {
      this.drawingZone = undefined;
      return;
    }

    const linkedZones = CanvasHelper.getLinkedZones(
      this.canvasZones,
      this.documentFields || [],
      linkedIdentifiers
    );

    if (linkedZones.length === 0) {
      this.drawingZone = undefined;
      return;
    }

    const focusedIdentifiers = linkedZones?.map((zone) => zone.identifier);

    this.canvasZones = this.canvasZones.map((zone) =>
      focusedIdentifiers.includes(zone.identifier)
        ? { ...zone, category }
        : zone.category === category
        ? { ...zone, category: "" }
        : zone
    );

    // TODO: Refactor/reduce code

    let refZone = this.drawingZone;

    if (!this.drawingZone) {
      // Merge existing zones
      if (linkedZones.length > 0) {
        refZone = ZoneHelper.mergeZones(
          linkedZones,
          this.focusedFieldCanvas as string,
          this.documentPage?.identifier as string
        );
      }
    }

    let newText = "";

    // Init field
    if (!this.documentFields?.some((field) => field.type === category)) {
      if (refZone) {
        const newZones = this.canvasZones.filter((zone) =>
          ZoneHelper.intersectRect((refZone as DocumentZone).box, zone.box)
        );
        this.focusedZones = [refZone.identifier];
        newText = newZones.map((zone) => zone.text).join(" ");
      }
      const newIdentifier = uuidv4();
      this.documentFields = [
        ...(this.documentFields || []),
        {
          text: newText,
          default: false,
          type: category,
          pageIdentifier: this.documentPage?.identifier,
          manuallyAdded: true,
          identifier: newIdentifier,
          box: refZone?.box || {},
        } as DocumentZone,
      ];
      this.focusedZones = [newIdentifier];
    } else {
      this.documentFields = this.documentFields?.map((item) => {
        if (item.type === category) {
          if (refZone?.box) {
            const newZones = this.canvasZones.filter((zone) =>
              ZoneHelper.intersectRect((refZone as DocumentZone).box, zone.box)
            );
            this.focusedZones = [refZone.identifier];
            this.canvasZones = this.canvasZones.map((zone) => {
              if (
                newZones.some(
                  (newZone) => newZone.identifier === zone.identifier
                )
              ) {
                return { ...zone, category };
              }
              return zone;
            });

            newText = newZones.map((zone) => zone.text).join(" ");
          }

          return {
            ...item,
            pageIdentifier: this.documentPage?.identifier,
            identifier: refZone?.identifier || item.identifier,
            default: false,
            box:
              linkedIdentifiers?.length === 1 &&
              linkedIdentifiers[0] === item.identifier
                ? item.box
                : refZone?.box || {},
            text: newText,
          };
        }

        return item;
      }) as DocumentZone[];
    }

    this.drawingZone = undefined;
  };

  sortFieldsByCategory = (fields: FlowField[]) => {
    let categories = fields.reduce((acc, field) => {
      const category = acc.find((el) => el.name === field.category);
      if (category) {
        category.fields.push(field);
      } else {
        acc.push({ name: field.category, fields: [field] });
      }
      return acc;
    }, [] as { name: string; fields: FlowField[] }[]);

    // Add lineItems as empty and separated category
    if (this.lineItems?.length > 0) {
      categories = [
        ...(categories || []),
        {
          name: "",
          fields: this.lineItems,
        },
      ] as { name: string; fields: FlowField[] }[];
    }

    return categories;
  };

  //TODO: Refactor code to remove duplication
  // isDrawNewZone - getNextFocusedFieldCanvas was called on draw new zone mode button
  getNextFocusedFieldCanvas = (isDrawNewZone: boolean) => {
    this.fields = this.fields?.filter((field) => field?.text !== "");

    //TODO: FIX THIS
    const categories = this.sortFieldsByCategory(
      (this.fields as unknown as FlowField[]) || []
    );
    const fields = categories.map((category) => category.fields).flat();

    const currentFieldIndex = fields.findIndex(
      (field) => field.key === this.focusedFieldCanvas
    );

    // if (this.focusedZones.length > 0 && !this.focusedFieldCanvas) {
    //   const focusedDocumentField = this.documentFields?.find(
    //     (field) => field.identifier === this.focusedZones[0]
    //   );

    //   if (!focusedDocumentField) {
    //     const focusedDocumentLineItem = this.lineItems?.find(
    //       (lineItem) => lineItem.type === this.focusedZones[0]
    //     );
    //     this.setFocusFieldCanvas(focusedDocumentLineItem?.type ?? "");
    //   }

    //   if (focusedDocumentField?.type) {
    //     this.setFocusFieldCanvas(focusedDocumentField.type);
    //   }

    //   this.linkCurrentSelections();
    //   this.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
    //   return;
    // }

    let nextField = fields.find((field, index) => {
      if (
        index > currentFieldIndex &&
        !this.documentFields?.find((docField) => docField.key === field.key)
        // &&
        // !this.lineItems?.find((docField) => docField.type === field.key)
      )
        return field;
      return;
    });

    if (!nextField) {
      nextField = fields.find((field) => {
        if (
          !this.fields?.find((docField) => docField.key === field.key)
          //  &&
          // !this.lineItems?.find((docField) => docField.type === field.key)
        )
          return field;
        return;
      });
    }

    if (fields.length > 1 && nextField) {
      if (nextField.dataType?.key === FIELD_DATA_TYPES.tableDataType) {
        this.lineItemsMode = true;
      } else {
        this.lineItemsMode = false;
      }
      this.focusedFieldCanvas = nextField?.key;
      this.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
    } else {
      if (fields[0].dataType?.key === FIELD_DATA_TYPES.tableDataType) {
        this.lineItemsMode = true;
      } else {
        this.lineItemsMode = false;
      }
      if (isDrawNewZone) {
        this.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
      } else {
        this.changeCanvasAction(CANVAS_ACTIONS.pan);
      }
    }
  };

  getTabNextFocusedFieldCanvas = () => {
    const categories = this.sortFieldsByCategory(
      (this.fields as unknown as FlowField[]) || []
    );
    const fields = categories.map((category) => category.fields).flat();

    //focus next field/lineItem from the list
    const currentFieldIndex = fields.findIndex(
      (field) => field.key === this.focusedFieldCanvas
    );

    if (currentFieldIndex === -1) {
      this.focusedFieldCanvas = fields[0]?.key;
    } else {
      this.focusedFieldCanvas =
        currentFieldIndex < fields.length - 1
          ? fields[currentFieldIndex + 1].key
          : fields[0].key;
    }
  };

  getTabLastFocusedFieldCanvas = () => {
    //TODO: Check this
    const categories = this.sortFieldsByCategory(
      (this.fields as unknown as FlowField[]) || []
    );
    const fields = categories.map((category) => category.fields).flat();

    //focus next field/lineItem from the list
    const currentFieldIndex = fields.findIndex(
      (field) => field.key === this.focusedFieldCanvas
    );

    if (currentFieldIndex === -1) {
      this.focusedFieldCanvas = fields[fields.length - 1]?.key;
    } else {
      this.focusedFieldCanvas =
        currentFieldIndex === 0
          ? fields[fields.length - 1]?.key
          : fields[currentFieldIndex - 1].key;
    }
  };

  updateDocumentPage = async (
    client: ApolloClient<MUTATION_UPDATE_DOCUMENT_RESPONSE>,
    draft?: boolean
  ) => {
    try {
      this.updatingPage = true;

      const response = await client.mutate<MUTATION_UPDATE_DOCUMENT_RESPONSE>({
        mutation: UPDATE_DOCUMENT,
        variables: {
          documentId: this.document?.identifier || "",
          data: {
            fields: this.fields || [],
            lineItems: LineItemsHelper.lineItemToApiLineItem(this.lineItems),
            categories: this.documentCategories,
            // Status of QA documents should not be updated
            status: this.isQaMode
              ? this.document?.status?.type
              : DOCUMENT_STATUSES.running,
            metadata: {
              rotation: this.updatedRotations
                ? Object.fromEntries(
                    Object.entries(this.updatedRotations).map(
                      ([pageIdentifier, rotationInfo]) => {
                        return [pageIdentifier, rotationInfo.rotation];
                      }
                    )
                  )
                : {},
            },
            draft: draft ?? false,
          },
          state: this.isQaMode
            ? DOCUMENT_VALIDATION_TYPES.qa
            : DOCUMENT_VALIDATION_TYPES.validation,
        },
      });

      const {
        data: { updateDocument },
        errors,
      } = response as {
        data: { updateDocument: boolean };
        errors: GraphQLError[];
      };

      if (!updateDocument || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      // After a document is updated and removed from QA, update QA doc count
      // FIXME: Keep for later use
      // if (this.isQaMode) {
      //   void this.root.qaStore.loadQADocumentsCount();
      // }

      runInAction(() => {
        this.updatingPage = false;
      });
    } catch (error) {
      runInAction(() => {
        this.updatingPage = false;
      });
      throw error;
    }
  };

  rejectDocument = async (
    client: ApolloClient<unknown>,
    code: number,
    message: string
  ) => {
    try {
      this.updatingPage = true;

      const response = await client.mutate<MUTATION_REJECT_DOCUMENT_RESPONSE>({
        mutation: REJECT_DOCUMENT,
        variables: {
          documentId: this.document?.identifier || "",
          code: code,
          message: message,
          state: this.isQaMode
            ? DOCUMENT_VALIDATION_TYPES.qa
            : DOCUMENT_VALIDATION_TYPES.validation,
        },
      });

      const {
        data: { rejectDocument },
        errors,
      } = response as {
        data: { rejectDocument: boolean };
        errors: GraphQLError[];
      };

      if (!rejectDocument || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      // After a document is rejected and removed from QA, update QA doc count
      //FIXME: Keep for later use
      // if (this.isQaMode) {
      //   void this.root.qaStore.loadQADocumentsCount();
      // }

      runInAction(() => {
        this.updatingPage = false;
      });
    } catch (error) {
      runInAction(() => {
        this.updatingPage = false;
      });
      throw error;
    }
  };

  retryDocument = async (client: ApolloClient<unknown>, documentId: string) => {
    try {
      this.updatingPage = true;

      const response = await client.mutate<MUTATION_RETRY_DOCUMENT_RESPONSE>({
        mutation: RETRY_DOCUMENT,
        variables: {
          documentId: documentId,
        },
      });

      const {
        data: { retryDocument },
        errors,
      } = response as unknown as {
        data: { retryDocument: boolean };
        errors: GraphQLError[];
      };

      if (!retryDocument || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
      runInAction(() => {
        this.updatingPage = false;
      });
    } catch (error) {
      runInAction(() => {
        this.updatingPage = false;
      });
      throw error;
    }
  };

  retryDocuments = async (
    client: ApolloClient<unknown>,
    status: string,
    documents: string[] = [],
    flowId: string | null
  ) => {
    const response = await client.mutate<MUTATION_RETRY_DOCUMENTS_RESPONSE>({
      mutation: RETRY_DOCUMENTS,
      variables: {
        documents: documents.length > 0 ? documents : null,
        status,
        flowId,
      },
    });

    const {
      data: { retryDocuments },
      errors,
    } = response as unknown as {
      data: { retryDocuments: boolean };
      errors: GraphQLError[];
    };

    if (!retryDocuments || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    runInAction(() => {
      this.updatingPage = false;
    });
  };

  rerunDocuments = async (
    client: ApolloClient<unknown>,
    statuses: string[],
    documents: string[] = []
  ) => {
    const response = await client.mutate<MUTATION_RERUN_DOCUMENTS_RESPONSE>({
      mutation: RERUN_DOCUMENTS,
      variables: {
        documents: documents.length > 0 ? documents : [],
        statuses,
      },
    });

    const {
      data: { rerunDocuments },
      errors,
    } = response as unknown as {
      data: { rerunDocuments: boolean };
      errors: GraphQLError[];
    };
    if (!rerunDocuments || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
  };

  getSimilarDocuments = async (
    client: ApolloClient<unknown>,
    documents: string[] = []
  ) => {
    const response =
      await client.mutate<MUTATION_GET_SIMILAR_DOCUMENTS_RESPONSE>({
        mutation: GET_SIMILAR_DOCUMENTS,
        variables: {
          documents: documents.length > 0 ? documents : null,
        },
      });

    const {
      data: { getSimilarDocuments },
      errors,
    } = response as unknown as {
      data: { getSimilarDocuments: { [key: string]: string[] } };
      errors: GraphQLError[];
    };
    if (!getSimilarDocuments || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    return response;
  };

  getSimilarTemplates = async (
    client: ApolloClient<unknown>,
    flowId: string,
    documents: string[] = []
  ) => {
    const response =
      await client.mutate<MUTATION_GET_SIMILAR_TEMPLATES_RESPONSE>({
        mutation: GET_SIMILAR_TEMPLATES,
        variables: {
          flowId,
          documents: documents.length > 0 ? documents : null,
        },
      });

    const {
      data: { getSimilarTemplates },
      errors,
    } = response as unknown as {
      data: { getSimilarTemplates: { [key: string]: string[] } };
      errors: GraphQLError[];
    };

    if (!getSimilarTemplates || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return response;
  };

  downloadFile = async (
    client: AxiosInstance | undefined,
    identifier: string
  ) => {
    await client
      ?.get(backendRoutes.DownloadDocument(identifier), {
        responseType: "blob",
      })
      .then((response) => {
        if (response?.status !== 200) {
          throw new Error("errorFetch");
        }

        const url = window.URL.createObjectURL(response.data as Blob);
        const a = document.createElement("a");

        a.href = url;
        a.download = `Document - ${identifier}.pdf`;
        a.click();
      })
      .catch(() => {
        throw new Error("errorFetch");
      });
  };

  clearDocument = () => {
    this.canvasZones = [];
    this.canvasAction = CANVAS_ACTIONS.pan;
    this.focusedFieldCanvas = null;
  };

  clearCanvas = (resetAction = true) => {
    this.scaleCoords = { x: 0, y: 0 };
    this.movingLineIndex = null;
    this.canvasScaleSize = 1;
    this.validationMode = VALIDATION_MODES.advanced;
    this.drawingZone = undefined;
    this.documentRotation = {};
    this.updatedRotations = {};
    this.disabledMode = false;
    this.lineItems = [];
    this.draftLines = [];
    this.selectedPages = [];
    this.isLineItemBorderFetching = false;

    // Action must be kept when changing pages
    if (resetAction) {
      this.canvasAction = CANVAS_ACTIONS.pan;
    }
  };

  setExpandThumbPreviewer = () => {
    this.expandedThumbPreviewer = !this.expandedThumbPreviewer;
  };

  reorderDocumentPages = async (
    client: ApolloClient<unknown>,
    pages: string[]
  ) => {
    try {
      this.updatingPage = true;

      const response =
        await client.mutate<MUTATION_REORDER_DOCUMENT_PAGES_RESPONSE>({
          mutation: REORDER_DOCUMENT_PAGES,
          variables: {
            documentId: this.document?.identifier || "",
            pages: pages.map((page, index) => ({
              identifier: page,
              pageNumber: index,
            })),
          },
        });

      const {
        data: { reorderDocumentPages },
        errors,
      } = response as unknown as {
        data: { reorderDocumentPages: boolean };
        errors: GraphQLError[];
      };

      if (!reorderDocumentPages || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
      runInAction(() => {
        this.updatingPage = false;
      });
    } catch (error) {
      runInAction(() => {
        this.updatingPage = false;
      });
      throw error;
    }
  };

  extractDocumentPages = async (
    client: ApolloClient<unknown>,
    pages: string[],
    targetFlow: string
  ) => {
    try {
      this.updatingPage = true;

      const response =
        await client.mutate<MUTATION_EXTRACT_DOCUMENT_PAGES_RESPONSE>({
          mutation: EXTRACT_DOCUMENT_PAGES,
          variables: {
            documentId: this.document?.identifier || "",
            pages: pages,
            targetFlow: targetFlow,
          },
        });

      const {
        data: { extractDocumentPages },
        errors,
      } = response as unknown as {
        data: { extractDocumentPages: boolean };
        errors: GraphQLError[];
      };

      if (!extractDocumentPages || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
      runInAction(() => {
        this.updatingPage = false;
      });
    } catch (error) {
      runInAction(() => {
        this.updatingPage = false;
      });
      throw error;
    }
  };

  deleteDocumentPages = async (
    client: ApolloClient<unknown>,
    pages: string[]
  ) => {
    try {
      this.updatingPage = true;

      const response =
        await client.mutate<MUTATION_DELETE_DOCUMENT_PAGES_RESPONSE>({
          mutation: DELETE_DOCUMENT_PAGES,
          variables: {
            documentId: this.document?.identifier || "",
            pages: pages,
          },
        });

      const {
        data: { deleteDocumentPages },
        errors,
      } = response as unknown as {
        data: { deleteDocumentPages: boolean };
        errors: GraphQLError[];
      };

      if (!deleteDocumentPages || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
      runInAction(() => {
        this.updatingPage = false;
      });
    } catch (error) {
      runInAction(() => {
        this.updatingPage = false;
      });
      throw error;
    }
  };

  getAllDocuments = async (page = 1, pageSize?: number) => {
    const filters = this.root.flowStore.filters?.flowFilters;
    const { sortBy, sortDirection, ...others } =
      (filters as unknown as DocumentsFilterProps) || {};

    const newPayload = {
      sortBy,
      sortDirection,
      filters: { ...others },
    };

    this.loadingDocuments = true;
    try {
      const response = await this.root.client.query<DocumentsResult>({
        query: GET_DOCUMENTS,
        variables: {
          page,
          pageSize: pageSize || 10,
          payload: newPayload,
        },
      });

      const {
        data: { getDocuments },
        errors,
      } = response;

      // If user is not in same page, then no further actions
      if (window.location.pathname?.includes("/view")) {
        return;
      }

      if (!getDocuments || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.currentFlowDocuments = {
          ...getDocuments,
          documents: [...(getDocuments.documents ?? [])],
        };
        this.loadingDocuments = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingDocuments = false;
      });
      throw error;
    }
  };

  moveSelectedDocuments = async (
    client: ApolloClient<unknown>,
    destinationFlow: string
  ) => {
    const response = await client.mutate<MUTATION_MOVE_DOCUMENTS_RESPONSE>({
      mutation: MOVE_DOCUMENTS,
      variables: {
        destinationFlow,
        documents:
          this.selectedDocuments.length > 0
            ? this.selectedDocuments.map((doc) => doc.identifier)
            : [this.document?.identifier],
      },
    });

    const {
      data: { moveDocuments },
      errors,
    } = response as {
      data: { moveDocuments: boolean };
      errors: GraphQLError[];
    };

    if (!moveDocuments || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    runInAction(() => {
      this.selectedDocuments = [];
    });
  };

  deleteSelectedDocuments = async (client: ApolloClient<unknown>) => {
    const response = await client.mutate<MUTATION_DELETE_DOCUMENTS_RESPONSE>({
      mutation: DELETE_DOCUMENTS,
      variables: {
        documents: this.selectedDocuments.map((doc) => doc.identifier),
      },
    });
    const {
      data: { deleteDocuments },
      errors,
    } = response as {
      data: { deleteDocuments: boolean };
      errors: GraphQLError[];
    };

    if (!deleteDocuments || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
    runInAction(() => {
      this.selectedDocuments = [];
    });
  };

  toggleOcr = () => {
    this.isOcrVisible = !this.isOcrVisible;
  };

  // Document history
  getDocumentHistory = (
    client: ApolloClient<unknown>
  ): Promise<DocumentHistory[]> => {
    return client
      .query<QUERY_DOCUMENT_HISTORY_RESPONSE>({
        query: GET_DOCUMENT_HISTORY,
        variables: {
          documentId: this.document?.identifier || "",
        },
      })
      .then(({ data: { getDocumentHistory }, errors }) => {
        if (!getDocumentHistory || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        return getDocumentHistory;
      });
  };

  getDocumentHistoryEvent = (
    client: ApolloClient<unknown>,
    eventIdentifier: string
  ): Promise<DocumentHistory> => {
    return client
      .query<QUERY_DOCUMENT_HISTORY_EVENT_RESPONSE>({
        query: GET_DOCUMENT_HISTORY_EVENT,
        variables: {
          documentId: this.document?.identifier || "",
          eventIdentifier,
        },
      })
      .then(({ data: { getDocumentHistoryEvent }, errors }) => {
        if (!getDocumentHistoryEvent || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        return getDocumentHistoryEvent;
      });
  };

  unlockDocument = async (
    client: ApolloClient<unknown>,
    documentId: string
  ) => {
    const response = await client.mutate<MUTATION_UNLOCK_DOCUMENT_RESPONSE>({
      mutation: UNLOCK_DOCUMENT,
      variables: {
        documentId,
      },
    });

    const {
      data: { unlockDocument },
      errors,
    } = response as {
      data: { unlockDocument: boolean };
      errors: GraphQLError[];
    };

    if (!unlockDocument || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }
  };

  // LINEITEMS
  initLineItem = (): string => {
    // If table extract operation fails, this will enable manual lineItem configure
    const newLineItem = {
      category: FlowEnum.table,
      type: this.focusedFieldCanvas,
      pageIdentifier: this.documentPage?.identifier as string,
      coords: {
        root: {
          ...this.drawingZone?.box,
          x: this.drawingZone?.box?.x as number,
          y: this.drawingZone?.box?.y as number,
          width: this.drawingZone?.box?.width as number,
          height: this.drawingZone?.box?.height as number,
          rotation: this.drawingZone?.box?.rotation || 0,
        },
        lines: [],
      },
      headers: this.focusedLineItemHeaders?.[0]
        ? [
            {
              ...this.focusedLineItemHeaders[0],
              text: this.focusedLineItemHeaders[0]?.name || "",
              box: {
                x: this.drawingZone?.box?.x as number,
                y: this.drawingZone?.box?.y as number,
                width: this.drawingZone?.box?.width as number,
                height: this.drawingZone?.box?.height as number,
                rotation: this.drawingZone?.box?.rotation || 0,
                text: "",
              },
              points: CanvasHelper.initRowPoints(
                (this.drawingZone?.box || {}) as DocumentZoneBox
              ),
            },
          ]
        : [],
      data: [],
    } as unknown as DocumentLineItem;

    // If lineItem is defined for current page, then the lineItem must be replaced (must be set for current page)
    if (
      this.lineItems?.some(
        (lineItem) => lineItem.type === this.focusedFieldCanvas
      )
    ) {
      this.lineItems = this.lineItems.map((lineItem) =>
        lineItem.type === this.focusedFieldCanvas ? newLineItem : lineItem
      );
    } else {
      this.lineItems = [
        ...(this.lineItems || []),
        newLineItem,
      ] as DocumentLineItem[];
    }

    return this.focusedFieldCanvas as string;
  };

  initEmptyLineItemRow = (focusedColumnKey: string) => {
    this.lineItems = this.lineItems.map((lineItem) => {
      if (
        this.focusedFieldCanvas === lineItem.type &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        const rowInit = LineItemsHelper.initEmptyRow(lineItem.headers);

        this.setCurrentEditingCell({
          rowIndex: (lineItem?.data || []).length,
          colKey: focusedColumnKey,
        });

        this.setManualLineItemMode(true);

        return {
          ...(lineItem || {}),
          data: [...(lineItem?.data || {}), rowInit],
        };
      }

      return lineItem;
    });
  };

  deleteLineItemRow = (rowIndex: number) => {
    this.lineItems = this.lineItems.map((lineItem) => {
      if (
        this.focusedFieldCanvas === lineItem.type &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        return {
          ...(lineItem || {}),
          data: lineItem?.data?.filter((_, index) => index !== rowIndex),
        };
      }

      return lineItem;
    });
  };

  initLineItemRowLine = (
    type: string,
    newLine: DocumentLineItemLine,
    remove = false
  ) => {
    this.lineItems = this.lineItems?.map((lineItem) => {
      if (
        type === lineItem.type &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        let newLineItem = lineItem;
        let newLineItemCoords = [...(lineItem?.coords?.lines || [])];

        if (!remove) {
          newLineItem = CanvasHelper.splitTableRow(
            lineItem,
            newLine,
            this.canvasZones
          );

          newLineItemCoords = [...newLineItemCoords, newLine];
        } else {
          // exclude intersected line
          newLineItemCoords = newLineItemCoords.filter((line) => {
            const lineTop = newLine[0][1] - DELETE_LINE_RANGE;
            const lineBottom = newLine[1][1] + DELETE_LINE_RANGE;
            return !(line[0][1] >= lineTop && line[1][1] <= lineBottom);
          });

          if (
            [...(lineItem?.coords?.lines || [])].length !==
            newLineItemCoords.length
          ) {
            newLineItem = CanvasHelper.mergeTableRow(lineItem, newLine);
          }
        }

        return {
          ...newLineItem,
          coords: {
            ...(lineItem.coords || {}),
            lines: newLineItemCoords,
          },
        };
      }

      return lineItem;
    });
  };

  splitColumnLine = (
    lineInit: DocumentLineItemLine,
    targetColumn: DocumentZone
  ) => {
    this.lineItems = this.lineItems.map((lineItem) => {
      if (
        this.focusedFieldCanvas === lineItem.type &&
        lineItem.pageIdentifier === this.documentPage?.identifier
      ) {
        const updatedHeaders = lineItem.headers?.map((header) => {
          if (header.key === targetColumn.key) {
            const headerBox = {
              ...header.box,
              width: lineInit[0][0] - (header.box?.x || 0),
            } as DocumentZoneBox;

            return {
              ...header,
              box: headerBox,
              text:
                CanvasHelper.updateCellText(
                  {
                    ...header,
                    box: headerBox,
                  },
                  this.canvasZones
                ).text || "",
            };
          } else {
            return header;
          }
        });

        const newId = uuidv4();
        const updatedRows = lineItem.data?.map((row) => {
          let newCell = row.cells[targetColumn.key as string];

          Object.entries(row.cells).forEach(([key, cell]) => {
            if (key === targetColumn.key) {
              [row.cells[key], newCell] = CanvasHelper.splitTableCell(
                cell,
                lineInit[0][0],
                this.canvasZones,
                true
              );
            }
          });

          return {
            ...row,
            cells: {
              ...row.cells,
              [newId]: newCell,
            },
          };
        });

        const newWidth =
          targetColumn.box?.width -
          (lineInit[0][0] - (targetColumn.box?.x || 0));

        const newHeader = {
          ...targetColumn,
          key: newId,
          identifier: newId,
          box: {
            ...targetColumn.box,
            x: lineInit[0][0],
            width: newWidth,
          },
        };

        updatedHeaders?.push(newHeader);

        return {
          ...lineItem,
          headers: updatedHeaders,
          coords: {
            ...(lineItem.coords || {}),
            lines: [...(lineItem?.coords?.lines || []), this.newLineConfig],
          },
          data: updatedRows,
        } as DocumentLineItem;
      }

      return lineItem;
    });
  };

  mergeLineItemColumns = (lineInit: DocumentLineItemLine) => {
    const intersectedColumns = CanvasHelper.getLineIntersectedColumns(
      lineInit,
      this.focusedLineItem as DocumentLineItem
    );

    const columnInformation = {
      isLeftColumnKept: true,
      keep: intersectedColumns?.leftColumn,
      remove: intersectedColumns?.rightColumn,
    };

    // This list will be updated based on merged columns
    const originalHeaders = this.focusedLineItemHeaders;

    this.lineItems = this.lineItems.map((lineItem) => {
      if (this.focusedFieldCanvas !== lineItem.type) {
        return lineItem;
      }

      const { updatedLineItem, headers } = CanvasHelper.mergeTableColumns(
        columnInformation,
        lineItem,
        originalHeaders,
        this.canvasZones
      );

      // Will be called once
      // Update table headers after merge is successful
      this.root.flowStore.updateLineItemHeaders(headers);

      return {
        ...lineItem,
        ...updatedLineItem,
      } as DocumentLineItem;
    });
  };

  updateLineItemRoot = (
    lineItem: DocumentZone,
    e?: Konva.KonvaEventObject<Event>,
    edit?: boolean
  ) => {
    const { type } = lineItem || {};

    let scaledZone = lineItem;

    if (e) {
      // Zone must be scaled in order to extract the correct intersected zones
      scaledZone = ZoneHelper.preprocessZone(
        e,
        lineItem,
        this.scaleCoords,
        this.canvasScaleSize,
        edit
      );
    }

    this.lineItems = this.lineItems?.map((lineItem) =>
      lineItem.type === type
        ? {
            ...lineItem,
            coords: {
              ...(lineItem?.coords || {}),
              root: {
                ...scaledZone.box,
              },
              lines: CanvasHelper.resizeTableLines(
                lineItem?.coords?.lines,
                scaledZone
              ),
            },

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            headers: CanvasHelper.resizeHeaderCells(
              lineItem.headers,
              scaledZone.box,
              lineItem.coords?.root
            ),

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
            data: CanvasHelper.resizeDataCells(
              lineItem?.data || [],
              scaledZone.box,
              lineItem?.coords?.root
            ),
          }
        : lineItem
    );
  };

  updateCurrentLineItemData = (data: DocumentLineItemRow[]) => {
    const currentLineItem = this.lineItems.find(
      (item) => item.type === this.focusedFieldCanvas
    );

    if (!currentLineItem) {
      return;
    }

    this.lineItems = this.lineItems.map((item) =>
      item.type === this.focusedFieldCanvas
        ? {
            ...item,
            data,
          }
        : item
    );
  };

  updateManualLineItemCell = (
    zone: DocumentZone,
    event?: Konva.KonvaEventObject<Event>
  ) => {
    try {
      this.lineItems = this.lineItems.map((lineItem) => {
        if (lineItem.type === this.focusedFieldCanvas) {
          // Scale coords
          const scaledZone = event
            ? ZoneHelper.preprocessZone(
                event,
                zone,
                this.scaleCoords,
                this.canvasScaleSize,
                true
              )
            : zone;

          // Extract new text (in case zone vas moved or resized)
          const scaledZoneText =
            this.canvasZones
              ?.filter((zoneItem) =>
                ZoneHelper.intersectRect(scaledZone.box, zoneItem.box)
              )
              ?.sort((a, b) => a.index - b.index)
              ?.map((zone) => zone.text)
              ?.join(" ") || "";

          return {
            ...lineItem,
            data: lineItem?.data?.map((row, index) => {
              // Update focused lineItem
              if (index === this.currentEditingCell?.rowIndex) {
                const cellName = this.currentEditingCell.colKey;
                const cell = {
                  ...row.cells[cellName],
                  box: scaledZone?.box,
                  text: scaledZoneText,
                };

                return {
                  ...row,
                  cells: {
                    ...(row.cells || {}),
                    [cellName]: cell,
                  },
                };
              }

              return row;
            }),
          };
        }

        return lineItem;
      });
    } catch {
      // No need to handle this, UI must not crush
    }
  };

  // This function is called when first creating a zone in canvas for a manual lineItem cell
  initManualLineItemCell = (zones: DocumentZone[]) => {
    try {
      const text = zones
        ?.sort((a, b) => a.index - b.index)
        ?.map((zone) => zone.text)
        ?.join(" ");

      this.lineItems = this.lineItems.map((lineItem) => {
        if (lineItem.type === this.focusedFieldCanvas) {
          return {
            ...lineItem,
            data: lineItem?.data?.map((row, index) => {
              // Update focused lineItem
              if (index === this.currentEditingCell?.rowIndex) {
                const cellName = this.currentEditingCell.colKey;
                const cell = {
                  ...row.cells[cellName],
                  box: this.drawingZone?.box || row.cells[cellName]?.box,
                  text,
                };

                return {
                  ...row,
                  cells: {
                    ...(row.cells || {}),
                    [cellName]: cell,
                  },
                };
              }

              return row;
            }),
          };
        }

        return lineItem;
      });
    } catch {
      // No need to handle this, UI must not crush
    }
  };

  lineItemsBorderTableDetect = async (zone: DocumentZone) => {
    const response =
      await this.root.client.mutate<MUTATION_LINE_ITEMS_BORDER_TABLE_DETECT_RESPONSE>(
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          mutation: LINE_ITEMS_BORDER_TABLE_DETECT,
          variables: {
            documentId: this.document?.identifier || "",
            pageIdentifier: this.documentPage?.identifier,
            coords: zone.box,
          },
        }
      );

    return response;
  };

  detectLineItemFromImage = async (
    uploadedImage: File,
    box:
      | {
          x: number;
          y: number;
          width: number;
          height: number;
        }
      | null
      | undefined,
    client: AxiosInstance | undefined
  ) => {
    const formData = new FormData();
    formData.append("files", uploadedImage);
    if (box) {
      formData.append("coords", JSON.stringify({ ...box, rotation: 0 }));
    }

    const response = await client?.post(
      backendRoutes.DetectLineItemsFromImage(),
      formData
    );

    if (!response) {
      throw new Error();
    }

    return response;
  };

  convertImage = async (
    uploadedImage: File,
    client: AxiosInstance | undefined
  ) => {
    const formData = new FormData();
    formData.append("files", uploadedImage);

    const response = await client?.post(backendRoutes.ProcessImage(), formData);

    if (!response) {
      throw new Error();
    }

    return response;
  };

  handleLineItemBorderDetect = async (zone: DocumentZone): Promise<string> => {
    try {
      this.isLineItemBorderFetching = true;
      const response = await this.lineItemsBorderTableDetect(zone);

      const {
        data: { extractLineItem },
        errors,
      } = response as unknown as {
        data: { extractLineItem: DocumentLineItem };
        errors: GraphQLError[];
      };

      if (!extractLineItem || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      const formattedNewLineItem = {
        ...(extractLineItem || {}),
        type: this.focusedFieldCanvas,
        pageIdentifier: this.documentPage?.identifier as string,
      } as unknown as DocumentLineItem;

      // If lineItem is already defined for current focused field, then replace with new configuration
      // If user wants to detect again, we need to just update current lineItem
      const isFirstDetect = !this.lineItems?.some(
        (item) => item.type === this.focusedFieldCanvas
      );

      runInAction(() => {
        this.lineItems = isFirstDetect
          ? this.lineItems.concat(formattedNewLineItem)
          : this.lineItems.map((lineItem) =>
              lineItem.type === this.focusedFieldCanvas
                ? formattedNewLineItem
                : lineItem
            );
        this.isLineItemBorderFetching = false;
      });

      return formattedNewLineItem.type;
    } catch (error) {
      runInAction(() => {
        this.isLineItemBorderFetching = false;
      });
      throw error;
    }
  };

  remakeColumns = (
    lineItem: DocumentLineItem,
    index: number,
    newLine: DocumentLineItemLine
  ) => {
    try {
      this.lineItems = this.lineItems.map((item) => {
        if (item.type === lineItem.type) {
          const formattedLine = {
            ...lineItem,
            coords: {
              ...lineItem.coords,
              lines:
                this.draftLines?.length > 0
                  ? this.draftLines
                  : lineItem.coords.lines,
            },
          };

          return CanvasHelper.remakeTableColumnsAfterLineMove(
            formattedLine,
            index,
            newLine,
            this.canvasZones
          ).updatedLineItem;
        }
        return item;
      });
    } catch {
      /** */
    }
  };

  remakeLines = (
    lineItem: DocumentLineItem,
    index: number,
    newLine: DocumentLineItemLine
  ) => {
    try {
      this.lineItems = this.lineItems.map((item) => {
        if (item.type === lineItem.type) {
          const formattedLine = {
            ...lineItem,
            coords: {
              ...lineItem.coords,
              lines:
                this.draftLines?.length > 0
                  ? this.draftLines
                  : lineItem.coords.lines,
            },
          };

          return CanvasHelper.remakeTableRowsAfterLineMove(
            formattedLine,
            index,
            newLine,
            this.canvasZones
          ).updatedLineItem;
        }
        return item;
      });
    } catch {
      /** */
    }
  };

  // This function will link configured table headers from flow with detected lineItem result returned from /detection
  linkDetectedHeaders = (mappedKeys: { [key: string]: string }) => {
    this.lineItems = this.lineItems?.map((lineItem) => {
      if (lineItem?.type !== this.focusedFieldCanvas) {
        return lineItem;
      }

      return CanvasHelper.linkDetectedHeaders(lineItem, mappedKeys);
    });
  };

  replaceLineItemColumn = (originalColumn: string, newColumn: string) => {
    try {
      this.lineItems = this.lineItems?.map((lineItem) => {
        if (
          lineItem.type === this.focusedFieldCanvas &&
          lineItem.pageIdentifier === this.documentPage?.identifier
        ) {
          const headers = LineItemHeaderHelper.replaceHeaders(
            lineItem.headers,
            originalColumn,
            newColumn
          );
          const data = CanvasHelper.replaceData(
            headers,
            lineItem.data,
            originalColumn,
            newColumn
          );

          return {
            ...lineItem,
            headers,
            data,
          };
        }

        return lineItem;
      });
    } catch {
      /** */
    }
  };

  // DOC SUBSCRIPTION

  documentsSubscription = (
    client: ApolloClient<unknown>,
    flowIdentifier: string,
    documentIds: string[],
    contextId: string
  ) => {
    const docSubscription = client.subscribe({
      query: DOCUMENTS_SUBSCRIPTIONS,
      variables: {
        contextId,
        flowIdentifier,
        documentIds,
      },
    });

    const subscriptionObserver = docSubscription.subscribe({
      next: (response: {
        data: {
          documentStatusChanged: {
            document: Document;
          };
        };
      }) => {
        const {
          data: {
            documentStatusChanged: { document },
          },
        } = response;

        runInAction(() => {
          this.currentFlowDocuments = {
            ...this.currentFlowDocuments,
            documents: this.currentFlowDocuments.documents.map((doc) =>
              doc.identifier === document.identifier &&
              (!doc.updatedAt ||
                Date.parse(doc.updatedAt) < Date.parse(document.updatedAt))
                ? document
                : doc
            ),
          };
        });
      },
    });

    return subscriptionObserver;
  };
}
