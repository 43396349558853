import Box from "@mui/material/Box";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { AppSuspense } from "../../main/AppSuspense";
import CoreButton from "../../core/CoreButton";
import { useHistory, useParams } from "react-router-dom";
import { appRoutes } from "../../../configs/routes";
import { useStores } from "../../../stores/StoresProvider";
import { useApolloClient } from "@apollo/client";
import { useNotification } from "../../../context/useNotification";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  heading1: {
    fontSize: "40px",
    fontWeight: 700,
    margin: "25px",
  },
  subheading: {
    fontSize: "20px",
    fontWeight: 700,
  },
  boxButtons: {
    gap: "10px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
  button: {
    margin: "0 10px",
  },
});

const RetryNextDocument: FC = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { t, ready } = useTranslation("document-validate");
  const classes = useStyles();
  const history = useHistory();
  const { documentStore } = useStores();
  const client = useApolloClient();
  const notification = useNotification();

  if (!ready) {
    return <AppSuspense />;
  }

  const retryDocument = () => {
    documentStore
      .loadDocument(
        client,
        undefined,
        true,
        flowId,
        documentStore.document?.identifier ?? ""
      )
      .then((hasNextDoc) => {
        if (hasNextDoc)
          history.replace(
            appRoutes.DocumentView(flowId, documentStore.document?.identifier)
          );
        else {
          history.push(appRoutes.FlowDetails(flowId));
          notification.info(t("noMoreDocs"));
        }
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "errorGettingNextDoc"));
      });
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.heading1}>
        {t("headingRetryNextDoc")}
      </Typography>
      <Typography className={classes.subheading}>
        {t("heading2RetryNextDoc")}
      </Typography>
      <Typography className={classes.subheading}>
        {t("heading3RetryNextDoc")}
      </Typography>
      <Box className={classes.boxButtons}>
        <CoreButton
          variant="outlined"
          className={classes.button}
          onClick={() => history.push(appRoutes.FlowDetails(flowId))}
        >
          {t("goBack")}
        </CoreButton>
        <CoreButton
          variant="outlined"
          className={classes.button}
          onClick={retryDocument}
        >
          {t("retry")}
        </CoreButton>
      </Box>
    </Box>
  );
};

export default RetryNextDocument;
