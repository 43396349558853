import { gql } from "@apollo/client";
import {
  AssetTemplateConfiguration,
  AssetType,
  EnhancementConfiguration,
  Flow,
  FlowFilterKeys,
  FlowSummary,
  FlowTemplate,
  RejectionInterface,
  RuleInterface,
  FlowDiagram,
  Variable,
} from "../../types/interfaces";
import { FlowDataCategoryItem } from "../../types/interfaces/flow";

export interface FlowInfo {
  identifier: string;
  name: string;
  description: string;
  flowType?: string;
}
export interface GET_FLOWS_TYPE {
  getFlows: Flow[];
}

export interface GET_FLOW_TYPE {
  getFlow: Flow & FlowTemplate;
}

export interface GET_FLOW_SUMMARY_TYPE {
  getFlowSummary: FlowSummary;
}

export interface GET_FLOW_FILTER_KEYS_TYPE {
  getFlowFilterKeys: FlowFilterKeys;
}

export interface GET_REJECTIONS_TYPE {
  getAllRejections: RejectionInterface[];
}

export interface GET_ASSET_LIST_TYPE {
  getAssetList: AssetType[];
}

export interface GET_DIAGRAM_ASSET_LIST_TYPE {
  getAssetDiagramList: AssetType[];
}

export interface GET_ASSET_CONFIG_TYPE {
  getAssetConfig: AssetTemplateConfiguration;
}

export interface GET_TOKENS_ASSETS {
  getProviderTokens: {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  };
}

export interface GET_CONNECTION_FILES {
  getConnectionFiles: { data: { path: string; isFolder: boolean }[] };
}

export interface GET_WORD {
  getWord: string;
}

export interface GET_ENHANCEMENTS {
  getEnhancements: EnhancementConfiguration[];
}
export interface GET_RULES {
  getRules: RuleInterface[];
}

export interface GET_FLOW_CATEGORY_DATA {
  getFlowCategoryData: FlowDataCategoryItem[];
}

export interface GET_FLOW_DIAGRAMS_TYPE {
  getFlowDiagrams: FlowDiagram[];
}

export interface GET_FLOW_DIAGRAM_TYPE {
  getFlowDiagram: FlowDiagram;
}

export interface GET_LATEST_FLOW_DIAGRAM_TYPE {
  getLatestFlowDiagram: FlowDiagram;
}

export interface GET_EXPORTABLE_OBJECTS_TYPE {
  getExportableObjects: Variable[];
}

export interface GET_CONTEXT_OBJECTS_TYPE {
  getContextObjects: Variable[];
}

//TODO: Change this after python implementation
export const GET_ALL_FLOWS = gql`
  query getFlows {
    getFlows {
      identifier
      name
      description
      fields
      categories
      flowType
    }
  }
`;

export const GET_FLOW = gql`
  query getFlow($id: String!) {
    getFlow(id: $id) {
      identifier
      name
      description
      fields
      manualValidate
      template
      dataSource
      dataExport
      dataAlgorithms
      storage
      flowType
      categories
      enhancements
      approvals
      access
      rejectReasons {
        code
        description
        message
      }
    }
  }
`;

export const GET_FLOW_SUMMARY = gql`
  query getFlowSummary($id: String!) {
    getFlowSummary(id: $id) {
      identifier
      name
      description
      fields
      flowType
      categories
      currentUserPermissions
    }
  }
`;

export const GET_FLOW_FILTER_KEYS = gql`
  query getFlowFilterKeys($id: String!) {
    getFlowFilterKeys(id: $id) {
      fields
      categories
      metadata
    }
  }
`;

export const GET_FLOW_TEMPLATES = gql`
  query getFlowTemplates {
    getFlowTemplates {
      identifier
      name
      description
      fields {
        name
        key
        isCustom
        isMandatory
        isVisible
        category
        description
        dataType {
          type
          key
          name
          description
          parameters
        }
      }
      dataSource
      dataAlgorithms
      dataExport
    }
  }
`;

export const CHECK_FLOW_NAME = gql`
  query checkFlowName($flowName: String!) {
    checkFlowName(flowName: $flowName)
  }
`;

export interface CHECK_FLOW_NAME_RESPONSE {
  checkFlowName: boolean;
}

export const GET_ALL_REJECTIONS = gql`
  query getAllRejections($identifier: String!) {
    getAllRejections(identifier: $identifier) {
      message
      code
      description
    }
  }
`;

export const GET_ASSET_LIST = gql`
  query getAssetList($assetType: String!, $showParams: Boolean) {
    getAssetList(assetType: $assetType, showParams: $showParams) {
      key
      name
      description
      flowType
      hasParams
      type
      iconPath
      icon
      category
      parameters
    }
  }
`;

export const GET_DIAGRAM_ASSET_LIST = gql`
  query getAssetDiagramList {
    getAssetDiagramList {
      key
      name
      description
      flowType
      hasParams
      type
      iconPath
      icon
      category
      parameters
      nodesFilter
    }
  }
`;

export const GET_ASSET_CONFIG = gql`
  query getAssetConfig($key: String!) {
    getAssetConfig(key: $key) {
      key
      name
      description
      type
      singleOutput
      nonEditableFields
      refreshContextObjects
      nodesFilter
      groups {
        key
        name
        description
      }
      input {
        dataTypes
        type
        allowedTypes
      }
      output {
        dataTypes
        type
        schema_
      }
      diagramSpec
      parameters {
        key
        name
        description
        type
        isMandatory
        isMultipleSelect
        canContainSpacesOnly
        isVisibleDuringCreation
        allowAddConnection
        parents
        dataType
        disabled
        value
        customScopes
        suggestions
        group
        allowedTypes
        isDynamicContentAllowed
        allowListSchemaObjects
        showToolbox
        allowNestedObjects
        language
        options {
          hasReferenceValue
          key
          label
          contents
        }
        parameters {
          key
          name
          description
          type
          isMandatory
          isMultipleSelect
          canContainSpacesOnly
          parents
          dataType
          disabled
          value
          customScopes
          suggestions
          allowSchemaGenerator
          group
          options {
            hasReferenceValue
            key
            label
            contents
          }
        }
      }
    }
  }
`;

export interface GET_TEST_DATA_EXPORT {
  dataExportTest: boolean;
}

export const DATA_EXPORT_TEST = gql`
  query dataExportTest($config: JSON) {
    dataExportTest(config: $config)
  }
`;

export const GET_CONNECTION_FILES_QUERY = gql`
  query getConnectionFiles($connectionId: String!) {
    getConnectionFiles(connectionId: $connectionId)
  }
`;

export const GET_TOKENS = gql`
  query getProviderTokens($type: String!, $code: String!) {
    getProviderTokens(type: $type, code: $code) {
      accessToken
      refreshToken
      idToken
    }
  }
`;

export const GET_FLOW_CATEGORY_DATA_QUERY = gql`
  query getFlowCategoryData($type: String!, $flowId: String!) {
    getFlowCategoryData(type: $type, flowId: $flowId) {
      key
      type
      identifier
      name
      parameters
      description
      createdAt
      updatedAt
      lastRun
      processedDocuments
      status
      actions
      nonEditableFields
      canDelete {
        enabled
        message
        linkedFlows
      }
      canEdit {
        enabled
        message
      }
    }
  }
`;

export const GET_FLOW_DIAGRAMS = gql`
  query getFlowDiagrams($flowId: String!) {
    getFlowDiagrams(flowId: $flowId) {
      identifier
      version
      createdAt
      updatedAt
      createdBy
      user {
        first_name
        last_name
      }
      current
    }
  }
`;

export const GET_FLOW_DIAGRAM = gql`
  query getFlowDiagram($flowId: String!, $diagramId: String!) {
    getFlowDiagram(flowId: $flowId, diagramId: $diagramId) {
      identifier
      version
      createdAt
      updatedAt
      createdBy
      current
      specs
    }
  }
`;

export const GET_LATEST_FLOW_DIAGRAM = gql`
  query getLatestFlowDiagram($flowId: String!) {
    getLatestFlowDiagram(flowId: $flowId) {
      identifier
      version
      createdAt
      updatedAt
      createdBy
      current
      specs
    }
  }
`;

export const GET_EXPORTABLE_OBJECTS = gql`
  query getExportableObjects($flowId: String!, $diagramId: String!) {
    getExportableObjects(flowId: $flowId, diagramId: $diagramId) {
      key
      name
      type
      schema
    }
  }
`;

export const GET_CONTEXT_OBJECTS = gql`
  query getContextObjects(
    $flowId: String!
    $nodes: [DiagramNode]
    $edges: [DiagramEdge]
  ) {
    getContextObjects(flowId: $flowId, nodes: $nodes, edges: $edges) {
      key
      name
      type
      schema
      nodeIdentifier
    }
  }
`;
