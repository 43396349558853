import React, { FC, useEffect, useState } from "react";
import { TFunction } from "i18next";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CoreDrawer from "../../../../../../core/CoreDrawer";
import CoreInput from "../../../../../../core/CoreInput";
import CoreButton from "../../../../../../core/CoreButton";
import { useNotification } from "../../../../../../../context/useNotification";

import { codeRegex } from "../../../../../../../types/regex";
import {
  RejectionInterface,
  FlowInterface,
} from "../../../../../../../types/interfaces";
import { REASONS_FIELDS } from "../../../../../../../types/constants";
import { useStores } from "../../../../../../../stores/StoresProvider";

interface Props {
  t: TFunction;
  currentReason: RejectionInterface | undefined;
  isOpen: boolean;
  onClose: () => void;
  flowRejectReasons: RejectionInterface[];
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "20px",
  },
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingTop: "30px",
  },
});

const AddReasonDrawer: FC<Props> = ({
  t,
  currentReason,
  isOpen,
  onClose,
  flowRejectReasons,
}) => {
  const classes = useStyles();
  const notification = useNotification();
  const { flowSettingsStore } = useStores();

  const [formData, setFormData] = useState<RejectionInterface | undefined>();

  const title = currentReason ? t("editReason_title") : t("addReason_title");
  const buttonText = currentReason
    ? t("editReason_button")
    : t("addReason_button");

  const isFormInvalid =
    !formData?.message || formData?.message?.trim() === "" || !formData?.code;

  useEffect(() => {
    if (currentReason) {
      setFormData(currentReason);
    }

    return () => {
      setFormData(undefined);
    };
  }, [currentReason, isOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.name === REASONS_FIELDS.code &&
      !codeRegex.test(event.target.value)
    ) {
      return;
    }
    setFormData({
      ...(formData || {}),
      [event.target.name]: event.target.value,
    } as RejectionInterface);
  };

  const handleUpdateFlow = (updatedReasons: RejectionInterface[]) => {
    flowSettingsStore
      .updateFlow({
        rejectReasons: updatedReasons,
      } as FlowInterface)
      .then(() => {
        onClose();
        notification.success(t("updateFlowSuccess"));
        flowSettingsStore.triggerRefetchFlowSettings(true);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "updateFlowError"));
      });
  };

  const handleCreate = () => {
    if (!isFormInvalid) {
      const condition = currentReason
        ? currentReason.code !== formData.code &&
          (flowSettingsStore.flow?.rejectReasons ?? []).some(
            (reason) => reason.code === formData.code
          )
        : (flowSettingsStore.flow?.rejectReasons ?? []).some(
            (reason) => reason.code === formData.code
          );

      if (condition) {
        notification.error(t("duplicateCodeError"));
        return;
      }

      if (currentReason) {
        const updatedRejectReasons = flowRejectReasons.map((reason) =>
          reason.code === currentReason.code ? formData : reason
        );

        handleUpdateFlow(updatedRejectReasons);
      } else {
        handleUpdateFlow([...(flowRejectReasons || []), formData]);
      }
    }
  };

  return (
    <CoreDrawer isOpen={isOpen} onClose={onClose} title={title}>
      <Box className={classes.container}>
        <CoreInput
          placeholder={t("createReason_namePlaceholder")}
          fullWidth
          title={t("reasonMessage")}
          value={t(formData?.message as string) || ""}
          name={REASONS_FIELDS.message}
          onChange={handleChange}
          description={t("message_tooltip")}
        />

        <CoreInput
          placeholder={t("createReason_descriptionPlaceholder")}
          fullWidth
          title={t("reasonDescription")}
          value={t(formData?.description as string) || ""}
          name="description"
          onChange={handleChange}
          description={t("description_tooltip")}
        />

        <CoreInput
          placeholder={t("createReason_codePlaceholder")}
          title={t("reasonCode")}
          value={formData?.code || ""}
          name={REASONS_FIELDS.code}
          onChange={handleChange}
          type="text"
          description={t("code_tooltip")}
        />
      </Box>

      <Box className={classes.footer}>
        <CoreButton
          onClick={handleCreate}
          disabled={isFormInvalid || flowSettingsStore.loadingUpdateFlow}
          isLoading={flowSettingsStore.loadingUpdateFlow}
          variant="contained"
        >
          {buttonText}
        </CoreButton>
      </Box>
    </CoreDrawer>
  );
};

export default AddReasonDrawer;
