import React, { FC, memo, useMemo, CSSProperties } from "react";
import { observer } from "mobx-react";

import { Box, Typography, useTheme } from "@mui/material";

import BillingHelper from "../../../../../helper/billingHelper";

interface Props {
  price: number | string | undefined;
  currency: string;
  contractTerm?: string; // period
  discount?: number;
  isHeader?: boolean;
  styleProps?: CSSProperties;
  containerProps?: CSSProperties;
}

export const Component: FC<Props> = observer(
  ({
    price,
    discount,
    currency,
    contractTerm,
    styleProps = {},
    containerProps = {},
    isHeader,
  }) => {
    const theme = useTheme();

    // If discount is received, then use it to display it as price the client will pay
    const sum = useMemo(
      () =>
        BillingHelper.convertSumToCurrency(
          (discount || price) as number,
          currency
        ),
      [discount, price, currency]
    );

    // Is discount is received, use the original price (without discount applied) to display it to the client
    const originalSum = useMemo(
      () =>
        discount
          ? BillingHelper.convertSumToCurrency(price as number, currency)
          : "",
      [price, discount, currency]
    );

    const periodSpan =
      isHeader && !!contractTerm ? (
        <span style={{ fontSize: "14px", fontStyle: "italic" }}>
          /{contractTerm}
        </span>
      ) : (
        <></>
      );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: isHeader ? "center" : "flex-start",
          ...containerProps,
        }}
      >
        <Typography
          sx={{
            color: isHeader ? theme.palette.highlight.main : "inherit",
            textAlign: "center",
            ...styleProps,
          }}
          variant={isHeader ? "h5" : "body1"}
        >
          {sum}
          {periodSpan}
        </Typography>

        {discount && (
          <Typography
            variant={isHeader ? "body1" : "caption"}
            sx={{
              textDecoration: "line-through",
              marginLeft: "10px",
              opacity: 0.6,
            }}
          >
            {originalSum}
            {periodSpan}
          </Typography>
        )}
      </Box>
    );
  }
);

export const SubscriptionPrice = memo(Component);
