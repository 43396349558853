import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { MenuList } from "@mui/material";

import ExtractDocumentPages from "./ExtractDocumentPagesModal";
import DeleteDocumentPages from "./DeleteDocumentPagesModal";
import {
  DOCUMENT_STATUSES,
  DOCUMENT_STATUS,
} from "../../../../../../types/constants";
import { useStores } from "../../../../../../stores/StoresProvider";

interface Props {
  open: boolean;
  onClose: () => void;
  anchorReference: "anchorPosition" | "anchorEl";
  anchorPosition:
    | {
        top: number;
        left: number;
      }
    | undefined;
  selectedIds: string[];
  documentPages: string[];
}

const useStyles = makeStyles({
  rightClickMenuText: {
    marginLeft: 10,
  },
});

const RightClickMenu: FC<Props> = observer(
  ({
    onClose,
    open,
    anchorReference,
    anchorPosition,
    selectedIds,
    documentPages,
  }) => {
    const classes = useStyles();
    const { documentStore, userStore } = useStores();
    const { t } = useTranslation("documents");
    const [deleteDocumentPagesOpen, setDeleteDocumentPagesOpen] =
      useState(false);
    const [extractDocumentPagesOpen, setExtractDocumentPagesOpen] =
      useState(false);

    const handleDeleteModal = () => {
      setDeleteDocumentPagesOpen(!deleteDocumentPagesOpen);
      onClose();
    };
    const handleExtractModal = () => {
      setExtractDocumentPagesOpen(!extractDocumentPagesOpen);
      onClose();
    };

    const canExtractPages =
      (userStore.currentUserPermissions?.can("updateDocuments", "flows") &&
        userStore.currentUserPermissions?.can("importDocuments", "flows")) ??
      false;

    const canDeletePages = userStore.currentUserPermissions?.can(
      "updateDocuments",
      "flows"
    );

    const MenuOptions = [
      {
        name: "extract",
        icon: <CopyAllIcon fontSize="small" />,
        action: handleExtractModal,
        visible:
          (documentStore.documentStatus === DOCUMENT_STATUSES.error ||
            documentStore.documentStatus ===
              DOCUMENT_STATUSES.requiresUserInput) &&
          canExtractPages,
      },
      {
        name: "delete",
        icon: <DeleteIcon fontSize="small" />,
        action: handleDeleteModal,
        visible:
          (documentStore.documentStatus === DOCUMENT_STATUSES.error ||
            documentStore.documentStatus ===
              DOCUMENT_STATUSES.requiresUserInput) &&
          canDeletePages,
      },
      {
        name: "clone",
        icon: <CopyAllIcon fontSize="small" />,
        action: handleExtractModal,
        visible:
          documentStore.documentStatus === DOCUMENT_STATUSES.done &&
          canExtractPages,
      },
    ];

    return (
      <>
        {documentStore.documentStatus !== DOCUMENT_STATUSES.running &&
          documentStore.documentStatus !== DOCUMENT_STATUS.created &&
          MenuOptions.some((option) => option.visible) && (
            <Menu
              open={open}
              onClose={onClose}
              anchorReference={anchorReference}
              anchorPosition={anchorPosition}
            >
              <MenuList>
                {MenuOptions.map(
                  (option) =>
                    option.visible && (
                      <MenuItem onClick={option.action} key={option.name}>
                        {option.icon}
                        <span className={classes.rightClickMenuText}>
                          {t(option.name)}
                        </span>
                      </MenuItem>
                    )
                )}
              </MenuList>
            </Menu>
          )}
        {/* extract pages modal */}
        {canExtractPages && (
          <ExtractDocumentPages
            closeRightClickMenu={onClose}
            open={extractDocumentPagesOpen}
            handleClose={handleExtractModal}
            selectedPages={selectedIds}
            documentPages={documentPages}
          />
        )}
        {/* delete pages modal */}
        {canDeletePages && (
          <DeleteDocumentPages
            closeRightClickMenu={onClose}
            open={deleteDocumentPagesOpen}
            handleClose={handleDeleteModal}
            selectedPages={selectedIds}
            documentPages={documentPages}
          />
        )}
      </>
    );
  }
);

export default RightClickMenu;
