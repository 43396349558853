import React from "react";
import { TFunction } from "i18next";

import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { FormData, FormField, SelectOptionDef } from "../../types/interfaces";
import CoreTooltip from "../core/CoreTooltip";
import { FormFieldProps } from "../../types/types";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface Props {
  translation: TFunction;
  field: FormField;
  onChange: (value: FormData, key: string) => void;
  value: string | boolean | number | unknown;
  otherProps?: FormFieldProps;
  errorText?: string;
  fullWidth?: boolean;
  inputTitleSize?: string;
  description?: string;
  tooltipLocation?: "title" | "input";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupBy?: (option: any) => string;
}

export const AutocompleteNoAddRenderer: React.FC<Props> = ({
  field,
  onChange,
  translation,
  value = "",
  otherProps = {},
  errorText,
  fullWidth = true,
  inputTitleSize,
  description,
  tooltipLocation = "title",
  groupBy,
}) => {
  const title = field.translationKey ? translation(field.translationKey) : "";
  const options = field.options ?? [];
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const classes = makeStyles({
    infoContainer: {
      display: "flex",
      alignItems: "center",
    },
    description: {
      marginLeft: "5px",
      fontSize: "20px",
      marginBottom: "3px",
    },
    icon: {
      alignSelf: "center",
      marginLeft: "15px",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    formText: {
      margin: "auto 0",
      height: "auto",
    },
    autocompleteBox: {
      display: "flex",
      width: "100%",
    },
    autocomplete: {
      width: "100%",
      borderRadius: "2px",
    },
    cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
    boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
  })();

  const { disabled } = otherProps;

  return (
    <FormControl size="small" error={!!errorText} fullWidth={fullWidth}>
      <Box className={classes.infoContainer}>
        {title && (
          <Typography
            variant="subtitle2"
            style={{ fontSize: inputTitleSize }}
            color={
              errorText
                ? currentTheme === THEME_MODES.light
                  ? theme.palette.error.main
                  : theme.palette.error.light
                : theme.palette?.text.primary
            }
          >
            {title}
            {field.isMandatory && <span className={classes.asterisk}>*</span>}
          </Typography>
        )}
        {title && description && tooltipLocation === "title" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.description} />
          </CoreTooltip>
        )}
      </Box>
      <Box className={classes.autocompleteBox}>
        <Autocomplete
          title={field.name}
          value={value || null}
          isOptionEqualToValue={(option, value) => {
            if (typeof value === "string") {
              return value === (option as SelectOptionDef).key;
            }
            return (value as string[]).includes(
              (option as SelectOptionDef).key
            );
          }}
          renderOption={(props, option) => {
            const opt = option as SelectOptionDef;
            return (
              <li {...props} key={opt.key}>
                <Typography noWrap>{opt.label}</Typography>
              </li>
            );
          }}
          readOnly={field.props?.readOnly}
          options={options}
          size="small"
          disabled={disabled}
          className={classes.autocomplete}
          multiple={field.props?.multiple}
          handleHomeEndKeys
          noOptionsText={translation("noOptions")}
          getOptionLabel={(option) => {
            if (typeof option === "string")
              return options.find((item) => item.key === option)?.label || "";
            return (
              options.find(
                (item) => item.key === (option as SelectOptionDef).key
              )?.label || ""
            );
          }}
          groupBy={groupBy}
          onChange={(event, newValue) => {
            if (newValue instanceof Array) {
              onChange(
                {
                  [field.key]: (newValue as unknown[]).map((item) => {
                    return typeof item === "string"
                      ? item
                      : (item as SelectOptionDef).key;
                  }),
                },
                field.key
              );
            } else {
              onChange(
                {
                  [field.key]: newValue
                    ? (newValue as SelectOptionDef).key
                    : null,
                },
                field.key
              );
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={translation(field.translationKey)}
            />
          )}
        />

        {title && description && tooltipLocation === "input" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.icon} />
          </CoreTooltip>
        )}
      </Box>
      {errorText && (
        <Box className={classes.boxError}>
          <CancelOutlinedIcon className={classes.cancelIcon} />

          <FormHelperText error={!!errorText} className={classes.formText}>
            {errorText}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};
