import React, { FC, useState } from "react";
import { TFunction } from "i18next";

import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import {
  IconButton,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Theme,
  alpha,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useStores } from "../../../../stores/StoresProvider";
import CoreInput from "../../../core/CoreInput";
import CoreButton from "../../../core/CoreButton";
import { useNotification } from "../../../../context/useNotification";

interface Props {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  isTopBar: boolean;
  theme: Theme;
  documentId?: string;
}

const ReportIssuePopup: FC<Props> = observer(
  ({ t, isOpen, onClose, documentId, theme, isTopBar }) => {
    const client = useApolloClient();
    const { flowStore, userStore } = useStores();
    const notification = useNotification();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");

    const useStyles = makeStyles({
      title: {
        fontWeight: "bolder",
        fontSize: "24px",
      },
      content: {
        "&.MuiDialogContent-root": {
          alignSelf: "stretch",
        },
        padding: "0 40px 20px 40px",
      },
      dialogPaper: {
        "& .MuiDialog-paper": {
          padding: 0,
          maxWidth: "700px",
        },
      },
      divider: {
        marginRight: "1rem",
        marginLeft: "1rem",
        border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      },
      iconButton: {
        position: "absolute",
        right: 8,
        top: 8,
      },
      message: {
        fontWeight: "bold",
        wordBreak: "break-all",
        paddingTop: "20px",
        fontSize: "14px",
      },
      buttonBox: {
        display: "flex",
        gap: "10px",
        padding: "10px",
      },
      formInput: {
        paddingTop: "20px",
      },
    });

    const classes = useStyles();

    const handleConfirm = () => {
      setIsLoading(true);
      if (isTopBar === true) {
        userStore
          .reportEvent(client, message)
          .then(() => {
            onClose();
            setMessage("");
            notification.success(t("issueReported"));
          })
          .catch((error: Error) => {
            notification.error(t(error.message || "errorReportIssue"));
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        flowStore
          .reportIssue(client, documentId ?? "", message)
          .then(() => {
            onClose();
            setMessage("");
            notification.success(t("issueReported"));
          })
          .catch((error: Error) => {
            notification.error(t(error.message || "errorReportIssue"));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    const handleOnClose = () => {
      onClose();
      setMessage("");
    };

    return (
      <Dialog
        fullWidth
        open={isOpen}
        onClose={handleOnClose}
        className={classes.dialogPaper}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>
            {isTopBar
              ? t("reportIssueTitle")
              : t("reportIssueTitleForDocument")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleOnClose}
            className={classes.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider className={classes.divider} />

        <DialogContent className={classes.content}>
          <Typography className={classes.message}>
            {t("reportIssueMessage")}
          </Typography>

          <CoreInput
            multiline
            rows={9}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            formControlClass={classes.formInput}
            disabled={isLoading}
          />
        </DialogContent>
        <Divider className={classes.divider} />
        <DialogActions>
          <Box className={classes.buttonBox}>
            <CoreButton
              onClick={handleOnClose}
              disabled={isLoading}
              variant="outlined"
            >
              {t("cancelReportButton")}
            </CoreButton>

            <CoreButton
              onClick={handleConfirm}
              disabled={isLoading || message.trim().length === 0}
              isLoading={isLoading}
              variant="contained"
            >
              {t("reportButton")}
            </CoreButton>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
);

export default ReportIssuePopup;
