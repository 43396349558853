import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// eslint-disable-next-line import/no-named-as-default-member
void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: "en",
    lng: localStorage.getItem("i18nextLng") || "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
  });

export default i18n;
