import React, { memo } from "react";

import Grid from "@mui/material/Grid";

import { CurrentPackageCard } from "./CurrentPackageCard";
import { CurrentPackageDetails } from "./CurrentPackageDetails";
import { UpgradeToPlanCard } from "./UpgradeToPlanCard";

const Component = () => {
  const canBeUpgraded = true;

  return (
    <Grid container>
      <Grid item xs={12}>
        <CurrentPackageCard />
      </Grid>

      {canBeUpgraded && (
        <Grid item xs={12}>
          <UpgradeToPlanCard />
        </Grid>
      )}

      <Grid item xs={12}>
        <CurrentPackageDetails />
      </Grid>
    </Grid>
  );
};

export const DetailCards = memo(Component);
