import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import CorePageContainer from "../../core/CorePageContainer";
import { AppSuspense } from "../../main/AppSuspense";

const useStyle = makeStyles({
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    height: "100%",
    "@media screen and (max-width:1200px)": {
      height: "auto",
      flexDirection: "column",
      gap: "1rem",
    },
  },
});

const Documents: React.FC = observer(() => {
  const { t, ready } = useTranslation("flow");

  const classes = useStyle();

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <CorePageContainer label={t("documents")}>
      <Box className={classes.content}></Box>
    </CorePageContainer>
  );
});

export default Documents;
