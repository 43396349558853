import React from "react";

import { TFunction } from "i18next";

import { FormField } from "../../types/interfaces";
import JsonEditorRenderer from "./JsonEditorRenderer";
import GenericEditorRenderer from "./GenericEditorRenderer";
import { FormFieldLanguageTypes } from "../../types/enums";
import DynamicContentJsonEditorRenderer from "./DynamicContentJsonEditorRenderer";

interface Props {
  onChange: (value: Record<string, string>) => void;
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  errorText: string | undefined;
  disableDiagramField?: boolean;
}

const CodeRenderer: React.FC<Props> = ({
  onChange,
  value,
  field,
  errorText,
  translation,
  disableDiagramField,
}) => {
  switch (field.language) {
    case FormFieldLanguageTypes.xml:
      return (
        <GenericEditorRenderer
          onChange={onChange}
          value={value}
          field={field}
          errorText={errorText}
          translation={translation}
          modeOverwrite={FormFieldLanguageTypes.xml}
          disableDiagramField={disableDiagramField}
        />
      );
    case FormFieldLanguageTypes.json:
      return field.isDynamicContentAllowed ? (
        <DynamicContentJsonEditorRenderer
          onChange={onChange}
          value={value}
          field={field}
          errorText={errorText}
          translation={translation}
          disableDiagramField={disableDiagramField}
        />
      ) : (
        <JsonEditorRenderer
          onChange={onChange}
          value={value}
          field={field}
          errorText={errorText}
          translation={translation}
          disableDiagramField={disableDiagramField}
        />
      );
    default:
      return (
        <GenericEditorRenderer
          onChange={onChange}
          value={value}
          field={field}
          errorText={errorText}
          translation={translation}
          disableDiagramField={disableDiagramField}
        />
      );
  }
};

export default CodeRenderer;
