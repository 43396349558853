import React from "react";
import { TFunction } from "i18next";

import {
  Autocomplete,
  TextField,
  createFilterOptions,
  styled,
} from "@mui/material";

import { FormData, FormField, SelectOptionDef } from "../../types/interfaces";
import { DYNAMIC_FIELDS } from "../../types/constants";

const filter = createFilterOptions<SelectOptionDef>();

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.dark,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

interface Props {
  field: FormField;
  value: unknown;
  onChange: (value: FormData) => void;
  formData: FormData;
  translation: TFunction;
  options: SelectOptionDef[];
  disableDiagramField?: boolean;
}

const FileNameAndDynamicExportAutocomplete: React.FC<Props> = ({
  field,
  value,
  onChange,
  formData,
  translation,
  options,
  disableDiagramField = false,
}) => {
  return (
    <Autocomplete
      multiple
      value={(value as SelectOptionDef[]) ?? []}
      freeSolo
      disabled={disableDiagramField || false}
      groupBy={(option) => option.type ?? DYNAMIC_FIELDS.userInput}
      filterOptions={(options, params) => {
        const allOptions = [
          ...options,
          ...((formData[field.key] as SelectOptionDef[]) ?? []).filter(
            (option) => option.type === DYNAMIC_FIELDS.userInput
          ),
        ];
        const filtered = filter(allOptions, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = allOptions.some(
          (option) => inputValue === option.key || inputValue === option.label
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            key: inputValue,
            label: translation("labelAddValue", {
              value: inputValue,
            }),
            type: DYNAMIC_FIELDS.userInput,
          });
        }

        return filtered;
      }}
      onChange={(_, newValue, reason) => {
        const lastVal = newValue[newValue.length - 1];
        if (typeof lastVal === "string") {
          onChange({
            ...formData,
            [field.key]: [
              ...((formData[field.key] as SelectOptionDef[]) ?? []),
              {
                label: lastVal,
                key: lastVal,
                type: DYNAMIC_FIELDS.userInput,
              },
            ],
          });
        } else if (
          lastVal?.type === DYNAMIC_FIELDS.userInput &&
          reason !== "removeOption"
        ) {
          onChange({
            ...formData,
            [field.key]: [
              ...((formData[field.key] as SelectOptionDef[]) ?? []),
              {
                label: lastVal.key,
                key: lastVal.key,
                type: lastVal.type,
              },
            ],
          });
        } else {
          onChange({
            ...formData,
            [field.key]: newValue,
          });
        }
      }}
      options={options ?? []}
      clearOnBlur
      selectOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={
            (value as SelectOptionDef[])?.length > 0
              ? ""
              : translation("selectFieldsForFileName")
          }
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{translation(params.group)}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

export default FileNameAndDynamicExportAutocomplete;
