import Box from "@mui/material/Box";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { AppSuspense } from "../../main/AppSuspense";

interface Props {
  title?: string;
  subtitle?: string;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  codeError: {
    fontSize: "50px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  subtitle: {
    marginTop: "20px",
  },
});

// TODO: Redesign component
const NotFound: FC<Props> = ({ title, subtitle }) => {
  const { t, ready } = useTranslation("core");
  const classes = useStyles();

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.codeError}>404</Typography>
      <Typography className={classes.title}>
        {title || t("notFoundTitle")}
      </Typography>
      <Typography className={classes.subtitle}>
        {subtitle || t("notFoundSubtitle")}
      </Typography>
    </Box>
  );
};

export default NotFound;
