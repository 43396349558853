import React, { useMemo, useState } from "react";
import { useReactFlow } from "reactflow";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
  styled,
  Popper,
  alpha,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import {
  FlowNode,
  FormData,
  FormField,
  InputOptions,
} from "../../types/interfaces";
import CoreTooltip from "../core/CoreTooltip";
import { FormFieldProps } from "../../types/types";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";
import ConfigHelper from "../../helper/configHelper";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | boolean | number | unknown;
  onChange: (value: FormData) => void;
  errorText?: string;
  otherProps?: FormFieldProps;
  type?: string;
  description?: string;
  tooltipLocation?: "title" | "input";
  fullWidth?: boolean;
}

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: alpha(theme.palette.text.disabled, 0.7),
  cursor: "pointer",
  backgroundColor: theme.palette.background.paper,
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const StyledPopper = styled(Popper)(({ theme }) => ({
  ".MuiAutocomplete-listbox": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const NodeInputRenderer: React.FC<Props> = observer(
  ({
    translation,
    field,
    value = "",
    onChange,
    errorText,
    otherProps = {},
    description,
    tooltipLocation,
    fullWidth = true,
  }) => {
    const theme = useTheme();
    const reactFlow = useReactFlow();

    const { mainStore, flowSettingsStore } = useStores();
    const [open, setOpen] = useState(false);
    const { currentTheme } = mainStore;

    const classes = makeStyles({
      infoContainer: {
        display: "flex",
        alignItems: "center",
      },
      description: {
        marginLeft: "5px",
        fontSize: "20px",
        marginBottom: "3px",
      },
      icon: {
        alignSelf: "center",
        marginLeft: "15px",
      },
      asterisk: {
        color: theme.palette.error.main,
        marginLeft: "5px",
      },
      formText: {
        margin: "auto 0",
        height: "auto",
      },
      autocompleteBox: {
        display: "flex",
        width: "100%",
      },
      autocomplete: {
        width: "100%",
        borderRadius: "2px",
      },
      cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
      boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
      groupBy: {
        color: theme.palette.text.secondary,
      },
    })();

    const diagramNodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );
    const diagramEdges = useMemo(
      () => reactFlow.getEdges(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const nodeInputOptions = useMemo(
      () =>
        flowSettingsStore.getNodeInputOptions(
          diagramNodes,
          diagramEdges,
          field,
          translation
        ) as InputOptions[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        flowSettingsStore.nodeInputOptions,
        field?.allowedTypes,
        diagramNodes,
        diagramEdges,
        flowSettingsStore.nodeIdToEdit,
        flowSettingsStore.layoutingNeeded,
        flowSettingsStore.parentNodeId,
        translation,
      ]
    );

    const selectedOption = useMemo(() => {
      if (!nodeInputOptions || nodeInputOptions?.length === 0) {
        return value as InputOptions;
      }

      return nodeInputOptions?.find((option) => option.key === value) || null;
    }, [nodeInputOptions, value]);

    const onSelectChange = (_: React.SyntheticEvent, newValue: unknown) => {
      const val = newValue as InputOptions;
      const fieldValue = val?.key || null;

      onChange({ [field.key]: fieldValue });
    };

    const getOptionLabel = (option: InputOptions | string) => {
      return typeof option === "string" ? option || "" : option?.value || "";
    };

    return (
      <FormControl size="small" error={!!errorText} fullWidth={fullWidth}>
        <Box className={classes.infoContainer}>
          {field.name && (
            <Typography
              variant="subtitle2"
              color={
                errorText
                  ? currentTheme === THEME_MODES.light
                    ? theme.palette.error.main
                    : theme.palette.error.light
                  : theme.palette?.text.primary
              }
            >
              {field.name}
              {field.isMandatory && <span className={classes.asterisk}>*</span>}
            </Typography>
          )}

          {field.name && description && tooltipLocation === "title" && (
            <CoreTooltip title={description}>
              <InfoOutlinedIcon className={classes.description} />
            </CoreTooltip>
          )}
        </Box>

        <Box className={classes.autocompleteBox}>
          <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
              option?.key === value?.key ||
              option?.category === value?.key ||
              //Prevent warning when type of value is string because nodeInputOptions is empty or contains only context variables
              typeof value === "string"
            }
            filterOptions={(options, { inputValue }) =>
              ConfigHelper.filterOptionsByTerm(options, inputValue)
            }
            title={field.name}
            options={nodeInputOptions}
            value={selectedOption}
            size="small"
            disabled={otherProps.disabled}
            className={classes.autocomplete}
            onChange={onSelectChange}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            noOptionsText={translation("noOptions")}
            groupBy={(option) => option?.category}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader style={{ cursor: "default" }}>
                  {params.group}
                </GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={translation(field.translationKey)}
                variant="outlined"
              />
            )}
            PopperComponent={(props) => <StyledPopper {...props} />}
          />

          {field.name && description && tooltipLocation === "input" && (
            <CoreTooltip title={description}>
              <InfoOutlinedIcon className={classes.icon} />
            </CoreTooltip>
          )}
        </Box>

        {errorText && (
          <Box className={classes.boxError}>
            <CancelOutlinedIcon className={classes.cancelIcon} />

            <FormHelperText error={!!errorText} className={classes.formText}>
              {errorText}
            </FormHelperText>
          </Box>
        )}
      </FormControl>
    );
  }
);

export default NodeInputRenderer;
