import React from "react";
import { TFunction } from "i18next";

import {
  FilterOptionsState,
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import { FormData, FormField } from "../../types/interfaces";
import CoreTooltip from "../core/CoreTooltip";
import { FormFieldProps } from "../../types/types";
import { useStores } from "../../stores/StoresProvider";
import { THEME_MODES } from "../../types/constants";

interface Props {
  translation: TFunction;
  field: FormField;
  onChange: (value: FormData) => void;
  value: string | boolean | number | unknown;
  otherProps?: FormFieldProps;
  errorText?: string;
  fullWidth?: boolean;
  inputTitleSize?: string;
  description?: string;
  tooltipLocation?: "title" | "input";
}

const filter = createFilterOptions<{
  key: string;
  label?: string;
  inputValue?: string;
}>();

interface Option {
  key: string;
  label?: string;
  inputValue?: string;
}

export const AutocompleteRenderer: React.FC<Props> = ({
  field,
  onChange,
  translation,
  value = "",
  otherProps = {},
  errorText,
  fullWidth = true,
  inputTitleSize,
  description,
  tooltipLocation = "title",
}) => {
  const title = field.name;
  const [options, setOptions] = React.useState(field.options ?? []);
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const classes = makeStyles({
    infoContainer: {
      display: "flex",
      alignItems: "center",
    },
    description: {
      marginLeft: "5px",
      fontSize: "20px",
      marginBottom: "3px",
    },
    icon: {
      alignSelf: "center",
      marginLeft: "15px",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    formText: {
      margin: "auto 0",
      height: "auto",
    },
    autocompleteBox: {
      display: "flex",
      width: "100%",
    },
    autocomplete: {
      width: "100%",
      borderRadius: "2px",
    },
    cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
    boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
  })();

  const { disabled } = otherProps;

  const getOptionLabel = (option: unknown) => {
    const opt = option as Option | string;

    // Value selected with enter, right from the input
    if (typeof opt === "string") {
      return opt;
    }

    // Add option created dynamically
    if (opt.inputValue) {
      return opt.inputValue;
    }

    // Regular option
    return opt.label || opt.key;
  };

  const getInputLabel = (opValue: string) => {
    return (
      options?.find((item) => item?.key === opValue)?.label ||
      opValue ||
      value ||
      ""
    );
  };

  const getFilteredOptions = (
    options: unknown[],
    params: FilterOptionsState<unknown>
  ) => {
    const opt = options as Option[];
    const filtered = filter(opt, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = opt.some((option) => inputValue === option.key);
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        key: `${translation("add")} "${inputValue}"`,
        label: `${translation("add")} "${inputValue}"`,
      });
    }
    return filtered;
  };

  const onSelectChange = (_: React.SyntheticEvent, newValue: unknown) => {
    const val = newValue as Option;
    let fieldValue = val?.key || "";

    if (typeof newValue === "string") {
      fieldValue = newValue;
    } else if (val && val.inputValue) {
      setOptions([
        ...options,
        {
          key: val.inputValue,
          label: val.inputValue,
        },
      ]);
      fieldValue = val.inputValue;
    }

    onChange({ [field.key]: fieldValue ?? "" });
  };

  return (
    <FormControl size="small" error={!!errorText} fullWidth={fullWidth}>
      <Box className={classes.infoContainer}>
        {title && (
          <Typography
            variant="subtitle2"
            style={{ fontSize: inputTitleSize }}
            color={
              errorText
                ? currentTheme === THEME_MODES.light
                  ? theme.palette.error.main
                  : theme.palette.error.light
                : theme.palette?.text.primary
            }
          >
            {title}
            {field.isMandatory && <span className={classes.asterisk}>*</span>}
          </Typography>
        )}

        {title && description && tooltipLocation === "title" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.description} />
          </CoreTooltip>
        )}
      </Box>

      <Box className={classes.autocompleteBox}>
        <Autocomplete
          title={field.name}
          value={value}
          options={options}
          size="small"
          disabled={disabled}
          className={classes.autocomplete}
          onChange={onSelectChange}
          filterOptions={getFilteredOptions}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          noOptionsText={translation("noOptions")}
          getOptionLabel={getOptionLabel}
          renderOption={(props, option) => (
            <li {...props}>{(option as Option).label}</li>
          )}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...(params?.inputProps || {}),
                value: getInputLabel(params?.inputProps?.value as string),
              }}
              placeholder={translation(field.translationKey)}
            />
          )}
        />

        {title && description && tooltipLocation === "input" && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.icon} />
          </CoreTooltip>
        )}
      </Box>

      {errorText && (
        <Box className={classes.boxError}>
          <CancelOutlinedIcon className={classes.cancelIcon} />

          <FormHelperText error={!!errorText} className={classes.formText}>
            {errorText}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};
