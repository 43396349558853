import { gql, ApolloClient } from "@apollo/client";

export interface NotifySentryEventMutation {
  createUser: boolean;
}

export const NOTIFY_SENTRY_EVENT = gql`
  mutation notifySentryEvent($message: String, $stack: String, $name: String) {
    notifySentryEvent(message: $message, stack: $stack, name: $name)
  }
`;

/**
 * Sentry init from client side is blocked by adblock, so a third party between client and sentry was introduced
 * This function sends the error to our backend and then sent to frontend sentry
 * @param error
 */
export const notifySentryEvent = (
  error: Error,
  apolloClient: ApolloClient<unknown>
): void => {
  apolloClient
    .query<NotifySentryEventMutation>({
      query: NOTIFY_SENTRY_EVENT,
      variables: {
        message: error?.message,
        stact: error?.stack,
        name: error?.name,
      },
    })
    .catch(console.error);
};
