import React from "react";

import Chip, { ChipProps } from "@mui/material/Chip";

import CoreTooltip from "./CoreTooltip";

interface FilterTagProps extends ChipProps {
  onDelete?: (text: string) => void;
  showTooltip?: boolean;
}

const CoreTag: React.FC<FilterTagProps> = ({
  onDelete,
  showTooltip,
  label,
  ...otherProps
}) => {
  const handleDelete = (item: string) => {
    if (onDelete) {
      onDelete(item);
    }
  };

  const chipProps = onDelete
    ? { onDelete: (item: string) => handleDelete(item) }
    : {};

  const chip = (
    <Chip label={label} variant="outlined" {...chipProps} {...otherProps} />
  );

  if (showTooltip) {
    return (
      <CoreTooltip
        title={
          label as
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
        }
        placement="bottom"
        sx={{ background: "transparent" }}
      >
        {chip}
      </CoreTooltip>
    );
  }

  return chip;
};

export default CoreTag;
