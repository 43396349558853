//Module exports
import React, { FC, useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import _get from "lodash/get";

//Internal imports
import { ASSET_PROPERTIES, ASSET_TYPES } from "../../../../../types/constants";
import { AssetConfiguration } from "../../../../../types/interfaces";
import AssetConfig from "./AssetConfig";
import AssetListDisplay from "./AssetListDisplay";
import CreateConnection from "../../../connections/CreateConnection";
import { useStores } from "../../../../../stores/StoresProvider";
import CoreDrawer from "../../../../core/CoreDrawer";

interface Props {
  t: TFunction;
  type: ASSET_TYPES;
  selectedItemPosition?: number | undefined;
  isDrawerOpen: boolean;
  toggleDrawer: (isOpen: boolean) => void;
  isAlgorithmConfig?: boolean;
  isOnConfig: boolean | null;
  setIsOnConfig: (isConfig: boolean | null) => void;
  flowDataSources?: AssetConfiguration[];
  flowDataExports?: AssetConfiguration[];
}

const AssetsDrawer: FC<Props> = observer(
  ({
    t,
    type,
    selectedItemPosition,
    isDrawerOpen,
    toggleDrawer,
    isOnConfig,
    setIsOnConfig,
    isAlgorithmConfig,
    flowDataSources,
    flowDataExports,
  }) => {
    const { flowSettingsStore } = useStores();
    const [assetCategoryKey, setAssetCategoryKey] = useState("");
    const [openConn, setOpenConn] = useState(false);
    const [openFileBrowser, setOpenFileBrowser] = useState(false);
    const [shouldRefetchAsset, setShouldRefetchAsset] = useState(false);

    const assetType = isAlgorithmConfig ? ASSET_TYPES.algorithms : type;

    const assetListItems = useMemo(() => {
      //TODO: Improve this after other asset types are fixed
      if (type === ASSET_TYPES.dataSource) {
        return flowDataSources ?? [];
      }
      if (type === ASSET_TYPES.dataExport) {
        return flowDataExports ?? [];
      }
      return _get(
        flowSettingsStore.flow,
        `${assetType}.${ASSET_PROPERTIES[assetType]}`
      ) as unknown as AssetConfiguration[];
    }, [
      flowDataSources,
      flowDataExports,
      assetType,
      flowSettingsStore.flow,
      type,
    ]);

    useEffect(() => {
      if (selectedItemPosition !== undefined && selectedItemPosition > -1) {
        setAssetCategoryKey(assetListItems[selectedItemPosition].key);
      }
    }, [selectedItemPosition, assetListItems]);

    const toggle = (value: boolean) => {
      toggleDrawer(value);
      setAssetCategoryKey("");
      if (!value) setIsOnConfig(null);
      if (openFileBrowser) setOpenFileBrowser(false);
    };

    const onSelect = (key: string) => {
      setAssetCategoryKey(key);
      setIsOnConfig(true);
    };

    const handleOnBack = () => {
      if (isOnConfig && !openFileBrowser) setIsOnConfig(false);
      if (openFileBrowser) setOpenFileBrowser(false);
      setAssetCategoryKey("");
    };

    const onConnClose = (goBack = false) => {
      if (!goBack) {
        toggle(false);
      }
      setOpenConn(false);
    };

    const setDrawerTitle = () => {
      if (openFileBrowser) return t("fileBrowserTitle");

      if (isOnConfig && selectedItemPosition !== undefined)
        return t(`${type}_edit`);

      return t(`${type}_add`);
    };

    useEffect(() => {
      if (isDrawerOpen) {
        setAssetCategoryKey("");
        if (openFileBrowser) setOpenFileBrowser(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDrawerOpen]);

    return (
      <>
        <CoreDrawer
          isOpen={isDrawerOpen}
          onClose={() => toggle(false)}
          hasBackButton={
            (isOnConfig === true &&
              (selectedItemPosition === undefined ||
                selectedItemPosition === -1)) ||
            openFileBrowser
          }
          onBack={handleOnBack}
          title={setDrawerTitle()}
        >
          {isOnConfig === false && (
            <AssetListDisplay
              onSelect={onSelect}
              t={t}
              type={type}
              subtitle={t("drawer_title", {
                title: t(`${type}_title`),
              })}
            />
          )}

          {isOnConfig === true && (
            <AssetConfig
              onClose={() => toggle(false)}
              t={t}
              assetCategoryKey={assetCategoryKey}
              type={type}
              list={assetListItems}
              selectedItemPosition={selectedItemPosition}
              setOpenConn={setOpenConn}
              openFileBrowser={openFileBrowser}
              setOpenFileBrowser={setOpenFileBrowser}
              isAlgorithmConfig={isAlgorithmConfig}
              shouldRefetchAsset={shouldRefetchAsset}
              setShouldRefetchAsset={setShouldRefetchAsset}
            />
          )}
        </CoreDrawer>

        <CreateConnection
          isOpen={openConn}
          onClose={onConnClose}
          hasBackButton
          onBack={onConnClose}
          setShouldRefetchAsset={setShouldRefetchAsset}
        />
      </>
    );
  }
);

export default AssetsDrawer;
