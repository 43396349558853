import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { ApolloClient, useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import { useTheme, Box, alpha, Typography } from "@mui/material";

import { useStores } from "../../../stores/StoresProvider";
import { MUTATION_UPDATE_DOCUMENT_RESPONSE } from "../../../stores/mutations/documents";
import CorePageContainer from "../../core/CorePageContainer";
import { CorePageMessage } from "../../core/CorePageMessage";
import DocumentEditor from "./validator/DocumentEditor";
import DocumentCanvas from "./validator/DocumentCanvas";
import { DocInfoButton } from "./validator/canvas/DocInfoButton";
import { AppSuspense } from "../../main/AppSuspense";
import { APP_IDS, DOCUMENT_STATUS } from "../../../types/constants";
import { useNotification } from "../../../context/useNotification";
import { appRoutes } from "../../../configs/routes";
import CoreResizableWidth from "../../core/CoreResizableWidth";
import { DocumentValidateActions } from "./buttons/DocumentValidateActions";
import { useApiClient } from "../../../api/useApiClient";
import ReportIssueButton from "../flow/reportIssues/ReportIssueButton";
import { CoreStatusTag } from "../../core/CoreStatusTag";

const getUrlIdentifier = (search: string) => {
  if (!search) {
    return "";
  }

  const searchParams = new URLSearchParams(search);

  return searchParams?.get("page") || "";
};

const enable = {
  top: false,
  right: false,
  bottom: false,
  left: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const DocumentValidate: FC = observer(() => {
  const { search } = useLocation<{ search: string }>();
  const history = useHistory();
  const { t, ready } = useTranslation("document-validate");
  const apolloClient = useApolloClient();
  const apiClient = useApiClient();

  const { flowId, documentId } = useParams<{
    documentId: string;
    flowId: string;
  }>();

  const notification = useNotification();
  const { documentStore, flowStore, mainStore, userStore } = useStores();
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);

  const redirectPage =
    // FIXME: Keep for later use
    //  documentStore.isQaMode
    //   ? appRoutes.QaDocuments():
    flowId ? appRoutes.FlowDetails(flowId) : appRoutes.Flows();

  const theme = useTheme();

  const classes = makeStyles({
    content: {
      padding: 0,
    },
    canvasContent: {
      padding: "0px !important",
    },
    canvasContainer: {
      width: `calc(100% - ${
        document.getElementById("containerZones")?.offsetWidth ?? 0
      }px)`,
    },
    gridContainer: {
      overflow: "hidden",
      display: "flex",
    },
    documentEditorContainer: {
      marginLeft: "auto",
    },
    docInfo: {
      display: "flex",
      alignItems: "center",
      marginRight: "4px",
      margin: "3px",
      marginLeft: "auto",
    },
    linkButton: {
      fontSize: "14px",
      color: alpha(theme.palette.highlight.main, 0.8),
      whiteSpace: "nowrap",
      overflow: "hidden",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    saveDraftButton: {
      marginRight: "10px",
    },
    outSideButtons: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
    },
  })();

  const currentDoc = documentStore.document;

  useEffect(() => {
    const pageIdentifier = getUrlIdentifier(search);

    if (!currentDoc)
      flowStore
        .loadFlow(apolloClient, flowId, false, false, false, false, false)
        .then(() => {
          documentStore
            .loadDocument(
              apolloClient,
              documentId,
              undefined,
              undefined,
              undefined,
              pageIdentifier
            )
            .catch(() => {
              notification.error(t("errorLoadingDocument"));
            });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "errorLoadingFlow"));
        });

    mainStore.collapseSidebar(true);

    return () => {
      //If the document is not in viewMode, we unlock it
      if (documentStore.isDocumentValid && !documentStore?.document?.isViewMode)
        documentStore
          .unlockDocument(apolloClient, documentId)
          .catch((error: Error) => {
            notification.error(t(error?.message || "errorUnlockingDocument"));
          });
      documentStore.clearCanvas();
      documentStore.resetDocument();
      mainStore.resetSidebar();
      documentStore.setPreview(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    documentStore.setInitialCanvasZones(
      documentStore.canvasZones,
      documentStore.documentFields
    );
    documentStore.setManualLineItemMode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.focusedFieldCanvas]);

  if (
    !ready ||
    documentStore.isDocumentLoading ||
    flowStore.isFlowDetailsLoading
  ) {
    return <AppSuspense />;
  }

  const goToNextDoc = () => {
    documentStore
      .loadDocument(apolloClient, undefined, undefined, flowId, documentId)
      .then((hasNextDoc) => {
        if (hasNextDoc)
          history.replace(
            appRoutes.DocumentView(flowId, currentDoc?.identifier)
          );
        else {
          history.push(appRoutes.FlowDetails(flowId));
          notification.info(t("noMoreDocs"));
        }
      })
      .catch((error: Error) => {
        history.push(appRoutes.RetryNextDocument(flowId));
        notification.error(t(error?.message || "errorGettingNextDoc"));
      });
  };

  const downloadDocument = () => {
    setIsDownloadInProgress(true);
    documentStore
      .downloadFile(apiClient, documentId ?? "")
      .then(() => {
        notification.success(t("downloadDocumentSuccess"));
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "downloadDocumentError"));
      })
      .finally(() => setIsDownloadInProgress(false));
  };

  const handleSaveDraft = () => {
    documentStore
      .updateDocumentPage(
        apolloClient as ApolloClient<MUTATION_UPDATE_DOCUMENT_RESPONSE>,
        true
      )
      .then(() => {
        if (documentStore.isGetNextDocumentChecked) {
          documentStore.setViewMode(true);
          goToNextDoc();
        } else {
          history.push(appRoutes.FlowDetails(flowId));
        }
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "errorSavingUpdates"));
      });
  };

  return (
    <CorePageContainer
      label={currentDoc?.name || t("title")}
      canGoBack={true}
      contentClass={classes.content}
      childrenClass={classes.canvasContent}
      redirectPage={redirectPage}
      maxHeight={{ tablet: 70 }}
      titleActions={
        currentDoc?.metadata?.draft ? (
          <CoreStatusTag
            label={t("draftLabel")}
            type={DOCUMENT_STATUS.requiresUserInput}
            disableCasing
            styleProps={{ marginLeft: "10px" }}
          />
        ) : undefined
      }
      extraHeaderContent={
        <>
          {!documentStore.viewMode && (
            <span className={classes.saveDraftButton}>
              <Typography
                className={classes.linkButton}
                onClick={() => handleSaveDraft()}
              >
                {t("saveDraft")}
              </Typography>
            </span>
          )}

          <Box className={classes.outSideButtons}>
            <ReportIssueButton documentId={documentId} isTopBar={false} />
            <DocInfoButton />
          </Box>

          {documentStore.isDocumentValid && (
            <DocumentValidateActions
              t={t}
              documentStore={documentStore}
              userStore={userStore}
              flowStore={flowStore}
              download={downloadDocument}
              isDownloadInProgress={isDownloadInProgress}
            />
          )}
        </>
      }
      extraHeaderActionsButtonDisabled={isDownloadInProgress}
      headerContentClass={classes.docInfo}
    >
      {!currentDoc ? (
        <CorePageMessage
          title={t("documentNotFound")}
          subtitle={t("documentNotFoundDescription")}
        />
      ) : (
        <Box className={classes.gridContainer}>
          <Box id={APP_IDS.canvasContainer} className={classes.canvasContainer}>
            <DocumentCanvas />
          </Box>

          <Box
            className={classes.documentEditorContainer}
            id={APP_IDS.docEditor}
          >
            <CoreResizableWidth
              handleStyles={{
                left: {
                  width: "10px",
                  backgroundColor: alpha(theme.palette.primary.dark, 0.5),
                  borderLeft: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                  borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                  marginLeft: "5px",
                },
              }}
              containerSizes={{
                default: 360,
                min: 360,
                max: window.innerWidth / 2 || 450,
              }}
              enable={enable}
              onResizeExtraFunction={() => {
                documentStore.setShouldUpdateDocumentCoords(new Date());
              }}
            >
              <DocumentEditor
                flowStore={flowStore}
                documentStore={documentStore}
                translation={t}
                goToNextDoc={goToNextDoc}
              />
            </CoreResizableWidth>
          </Box>
        </Box>
      )}
    </CorePageContainer>
  );
});

export default DocumentValidate;
