import React, { useMemo } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DangerousIcon from "@mui/icons-material/Dangerous";
import WarningIcon from "@mui/icons-material/Warning";
import CircularProgress from "@mui/material/CircularProgress";
import BlockIcon from "@mui/icons-material/Block";
import AddTaskIcon from "@mui/icons-material/AddTask";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import { Info, SvgIconComponent } from "@mui/icons-material";
import { Typography, TypographyProps, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { DocumentStages, DocumentHistoryStages } from "../../types/enums";
import CoreTooltip from "./CoreTooltip";

interface StatusProps {
  type: string;
  label?: string;
  tooltip?: {
    message?: string;
    fields?: string[];
  };
  labelProps?: TypographyProps;
}

interface StatusItem {
  component: SvgIconComponent | typeof CircularProgress;
  color: string;
  size?: number;
}

const useStyles = makeStyles({
  typography: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "5px",
  },
});

export const Status: React.FC<StatusProps> = ({
  type,
  label,
  tooltip = {},
  labelProps = {},
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const STATUS_ICONS: Record<string, StatusItem> = useMemo(
    () => ({
      [DocumentHistoryStages.FAILED]: {
        component: DangerousIcon,
        color: theme.palette.error.main,
      },
      [DocumentHistoryStages.SUCCESS]: {
        component: Info,
        color: theme.palette.text.disabled,
      },
      [DocumentStages.ERROR]: {
        component: DangerousIcon,
        color: theme.palette.error.main,
      },
      [DocumentStages.DONE]: {
        component: CheckCircleOutlineIcon,
        color: theme.palette.success.dark,
      },
      [DocumentStages.REQUIRES_USER_INPUT]: {
        component: WarningIcon,
        color: theme.palette.warning.main,
      },
      [DocumentStages.RUNNING]: {
        component: CircularProgress,
        color: theme.palette.info.main,
        size: 19,
      },
      [DocumentStages.REJECTED]: {
        component: BlockIcon,
        color: theme.palette.error.light,
      },
      [DocumentStages.CREATED]: {
        component: AddTaskIcon,
        color: theme.palette.success.dark,
      },
      unknown: {
        component: QuestionMarkIcon,
        color: theme.palette.warning.main,
      },
    }),
    [theme]
  );

  const {
    component: StatusIcon,
    color,
    ...otherProps
  } = STATUS_ICONS[type] ?? STATUS_ICONS.unknown;

  if (!StatusIcon) {
    return null;
  }

  return (
    <Typography
      variant="subtitle2"
      className={classes.typography}
      {...labelProps}
    >
      <CoreTooltip title={tooltip.message ?? ""}>
        <StatusIcon
          className={classes.icon}
          style={{ color }}
          fontSize="small"
          {...(otherProps ?? {})}
        />
      </CoreTooltip>
      {label}
    </Typography>
  );
};
