import React, { useMemo } from "react";
import { observer } from "mobx-react";

import { Box, SvgIcon } from "@mui/material";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

import { useStores } from "../../../../../stores/StoresProvider";
import CoreTooltip from "../../../../core/CoreTooltip";
import { AssetType } from "../../../../../types/interfaces";

interface Props {
  nodeKey: string;
  textBoxStyle: string;
  iconStyle: string;
  svgIconStyle: string;
  forDiagram?: boolean;
}

const DiagramNodeIcon: React.FC<Props> = observer(
  ({ nodeKey, textBoxStyle, iconStyle, svgIconStyle, forDiagram = false }) => {
    const { flowSettingsStore } = useStores();

    const node = useMemo(
      () => flowSettingsStore.nodesList?.find((node) => node.key === nodeKey),
      [flowSettingsStore.nodesList, nodeKey]
    );

    const content = (node: AssetType) => {
      return (
        <Box className={textBoxStyle}>
          {node?.iconPath ? (
            <img key={node?.key} src={node?.iconPath} />
          ) : node?.icon ? (
            <SvgIcon key={node?.key} className={svgIconStyle}>
              <g dangerouslySetInnerHTML={{ __html: node?.icon }} />
            </SvgIcon>
          ) : (
            <SystemUpdateAltIcon key={node?.key} className={iconStyle} />
          )}
        </Box>
      );
    };

    if (!node) {
      return (
        <Box className={textBoxStyle}>
          <SystemUpdateAltIcon className={iconStyle} />
        </Box>
      );
    }

    return forDiagram ? (
      <CoreTooltip
        title={
          <span style={{ display: "block", textAlign: "center" }}>
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              {node?.name || ""}
            </span>
            <br />
            <span style={{ fontSize: "12px" }}>{node?.description || ""}</span>
          </span>
        }
        placement="bottom"
      >
        {content(node)}
      </CoreTooltip>
    ) : (
      content(node)
    );
  }
);

export default DiagramNodeIcon;
