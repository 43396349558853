import React, { FC, useMemo, useCallback } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Typography, useTheme } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";

import { DocumentStore } from "../../../../../stores/DocumentStore";
import { DocumentLineItem } from "../../../../../types/interfaces";
import { FIELD_DATA_TYPES } from "../../../../../types/enums";
import { CANVAS_ACTIONS } from "../../../../../types/constants";
import CoreButton from "../../../../core/CoreButton";
import { useNotification } from "../../../../../context/useNotification";
import { MessageTooltipBox } from "../canvas/messagePanel/MessageTooltipBox";

interface Props {
  documentStore: DocumentStore;
  field: DocumentLineItem;
  translation: TFunction;
}

const CanvasLineItem: FC<Props> = observer(
  ({ documentStore, field, translation }) => {
    const theme = useTheme();
    const notification = useNotification();
    const { focusedFieldCanvas, documentPage, lineItems } = documentStore;

    const classes = makeStyles({
      lineItemButtonContainer: {
        width: "100%",
        alignItems: "center",
      },
      warningIcon: {
        marginLeft: "10px",
        fontSize: 20,
      },
      coreButton: {
        width: "100%",
        gap: "10px",
        justifyContent: "flex-start",
        color:
          //TODO: Fix this, consider removing field.key
          focusedFieldCanvas === field.key || focusedFieldCanvas === field.type
            ? theme.palette.highlight.main
            : theme.palette.text.primary,
      },
    })();

    const docLineItem = useMemo(
      () => lineItems?.find((lineItem) => lineItem.type === field.type),
      [field.type, lineItems]
    ) as unknown as DocumentLineItem;

    const isDisabled = useMemo(() => {
      !!docLineItem?.pageIdentifier &&
        docLineItem?.pageIdentifier !== documentPage?.identifier &&
        docLineItem?.type !== focusedFieldCanvas;
    }, [
      docLineItem?.pageIdentifier,
      docLineItem?.type,
      documentPage?.identifier,
      focusedFieldCanvas,
    ]) as unknown as boolean;

    const onClickTable = (field: DocumentLineItem) => {
      if (!documentStore.viewMode) {
        documentStore.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
      } else {
        documentStore.changeCanvasAction(CANVAS_ACTIONS.pan);
      }
      documentStore.focusLineItem(field.type);
      const zone = documentStore.lineItems.find(
        (item) => item.type === field.type
      );
      documentStore.setFocusZone(zone?.type || null);
      documentStore.setFocusFieldCanvas(field ? field?.type : null);

      if (
        zone &&
        zone?.pageIdentifier !== documentStore.documentPage?.identifier
      ) {
        documentStore.setSelectedPages([zone.pageIdentifier]);
        documentStore
          .loadDocumentPage(
            documentStore.document?.identifier as string,
            zone.pageIdentifier
          )
          .then(() => {
            if (zone.type !== FIELD_DATA_TYPES.tableDataType) {
              documentStore.setFocusZone(
                zone?.type || (zone as unknown as string)
              );
            }
          })
          .catch((error: Error) => {
            notification.error(
              translation(error?.message || "error_loading_page")
            );
          });
      }
    };

    const handleKeyDown = (
      e: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>
    ) => {
      if (e.key === "Tab") {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const handleKeyUp = useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
        // Go to next lineItem if current lineItem is focused
        if (e.key === "Tab") {
          e.preventDefault();
          e.stopPropagation();
          e.currentTarget.blur();
          documentStore.getTabNextFocusedFieldCanvas();
        }

        if (e.key === "Escape") {
          documentStore.handleCancelFocusedFieldCanvas(true);
        }
      },
      [documentStore]
    );

    return (
      <CoreButton
        variant="text"
        className={classes.coreButton}
        containerClass={classes.lineItemButtonContainer}
        onClick={() => onClickTable(field)}
        onKeyUp={handleKeyUp}
        onKeyDown={handleKeyDown}
        disabled={isDisabled}
      >
        <TableRowsIcon />
        <Typography color="inherit">{field.name}</Typography>
        {field?.message || (field?.details && field?.details?.length > 0) ? (
          <MessageTooltipBox
            message={field.message}
            details={field.details}
            translation={translation}
          />
        ) : (
          <></>
        )}
      </CoreButton>
    );
  }
);

export default CanvasLineItem;
