import { gql } from "@apollo/client";

export const GET_PREVIEW = gql`
  query getPreview($config: JSON) {
    getPreview(config: $config) {
      files
      documentCount
      incomplete
    }
  }
`;
