import React, { FC } from "react";
import { TFunction } from "i18next";

import { SvgIconProps, useTheme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CoreTooltip from "./CoreTooltip";
interface TabsOptionsDef {
  label: string;
  icon?: React.ComponentType<SvgIconProps> | undefined;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void | undefined;
  tooltipText?: string;
}
interface Props extends TabsProps {
  options?: TabsOptionsDef[];
  t: TFunction;
  showTooltip?: boolean;
}

const CoreTabs: FC<Props> = ({ options, t, showTooltip, ...extended }) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    tabs: {
      "& .MuiTabs-scrollButtons": {
        "&.Mui-disabled": { opacity: "0.5" },
        "&:hover": { color: theme.palette.highlight.main },
        "@media screen and (max-width:599.95px)": {
          display: "inline-flex",
        },
      },
      "& .MuiTabs-scroller": {
        overflow: "auto !important",
      },
    },
    tab: {
      textTransform: "none",
      marginLeft: "5px",
      marginRight: "5px",
      padding: 0,
      "&.MuiButtonBase-root": {
        minHeight: "50px",
        paddingLeft: "20px",
        paddingRight: "20px",
        "&.Mui-selected": {
          color: theme.palette.highlight.main,
        },
      },
    },
    labelStyle: {
      color: "inherit",
    },
  });

  const classes = useStyles();

  return (
    <Tabs
      {...extended}
      className={classes.tabs}
      TabIndicatorProps={{
        style: { background: theme.palette.highlight.main },
      }}
    >
      {options?.map((tab) => {
        const Icon = tab.icon;

        const tabComponent = (
          <Tab
            className={classes.tab}
            key={tab.label}
            icon={Icon && <Icon />}
            label={
              <Typography variant="body1" className={classes.labelStyle}>
                {t(tab.label)}
              </Typography>
            }
            onClick={tab.onClick}
            iconPosition="start"
          />
        );

        if (showTooltip) {
          return (
            <CoreTooltip key={tab.label} title={t(tab.tooltipText ?? "")}>
              {tabComponent}
            </CoreTooltip>
          );
        }

        return tabComponent;
      })}
    </Tabs>
  );
};

export default CoreTabs;
