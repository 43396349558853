import React, { FC } from "react";
import { TFunction } from "i18next";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import CoreTooltip from "../../core/CoreTooltip";

const useStyle = makeStyles({
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  icon: { fontSize: "20px" },
});

interface Props {
  id: string;
  openModal: (id: string) => void;
  t: TFunction;
  openConn: (id: string) => void;
  canUpdateConnections: boolean;
  canDeleteConnections: boolean;
}

export const TableActions: FC<Props> = ({
  id,
  openModal,
  t,
  openConn,
  canDeleteConnections,
  canUpdateConnections,
}) => {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.buttonsContainer}>
        {canUpdateConnections && (
          <CoreTooltip title={t("updateConnection_tooltip")} placement="left">
            <IconButton onClick={() => openConn(id)}>
              <EditIcon className={classes.icon} />
            </IconButton>
          </CoreTooltip>
        )}

        {canDeleteConnections && (
          <CoreTooltip title={t("removeConnection_tooltip")} placement="right">
            <IconButton onClick={() => openModal(id)}>
              <DeleteIcon className={classes.icon} />
            </IconButton>
          </CoreTooltip>
        )}
      </Box>
    </>
  );
};
