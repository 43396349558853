import React, { FC } from "react";

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { CSSProperties } from "@mui/styles";

//interfaces
import {
  TableHeaderDef,
  TableContentDef,
  TableRowStyleDef,
  TableCellStyleDef,
} from "../../types/interfaces";
import { HOVER_COLUMNS } from "../../types/constants";
// Internal imports
import CoreBody from "./table/CoreBody";
import CoreFooter from "./table/CoreFooter";
import CoreHeader from "./table/CoreHeader";
import CoreDraggableBody from "./table/CoreDraggableBody";

interface Props {
  headers: TableHeaderDef[];
  isLoading?: boolean;
  data: TableContentDef[];
  currentPage?: number;
  numberOfPages?: number;
  columnWidth?: TableContentDef;
  rowHeight?: TableContentDef;
  hoverActions?: boolean;
  noVisibleCells?: number; // Number of columns to display once the row is hovered (if hoverActions is enabled)
  isPaginated?: boolean; // Is enabled by default. Will display pagination component (table footer)
  customRowStyles?: TableRowStyleDef; // Customize entire row
  customCellStyle?: TableCellStyleDef; // Customize one cell
  globalCellStyle?: CSSProperties; // Customize all cells
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPageOptions?: {
    key: string;
    label: string;
  }[];
  rowsPerPage?: number;
  totalCount?: number;
  changePage?: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  handleSorting?: (cell: TableHeaderDef) => void;
  handleRowClick?: (event: React.MouseEvent<unknown>, id: string) => void;
  isTableHeadersList?: boolean;
  isDraggable?: boolean;
  setContent?: (content: TableContentDef[]) => void;
  hideHeaders?: boolean;
  onHoverRowTableBody?: boolean;
}

/**
 * Extracts only the accessors defined in the header
 * @param headers
 * @param data
 * @returns
 */
const formatTableData = (
  headers: TableHeaderDef[],
  data: TableContentDef[]
) => {
  if (!data || data?.length === 0) {
    return [];
  }

  return data.map((item) => {
    let formattedItem = {} as TableContentDef;

    headers.forEach((header) => {
      formattedItem = {
        ...formattedItem,
        [header.accessor]: item[header.accessor] || "-",
      };
    });

    if (item[HOVER_COLUMNS.actions])
      formattedItem = {
        ...formattedItem,
        actions: item[HOVER_COLUMNS.actions],
      };

    return formattedItem;
  });
};

const CoreTable: FC<Props> = ({
  headers,
  data,
  isPaginated = true,
  currentPage,
  changePage,
  rowsPerPage,
  rowsPerPageOptions,
  setRowsPerPage,
  numberOfPages,
  totalCount,
  handleRowClick,
  handleSorting,
  isLoading,
  columnWidth,
  rowHeight,
  hoverActions,
  noVisibleCells = 1,
  customRowStyles,
  customCellStyle,
  globalCellStyle,
  isTableHeadersList = false,
  isDraggable = false,
  setContent,
  hideHeaders = false,
  onHoverRowTableBody,
}) => {
  const tableData = formatTableData(headers, data);

  return (
    <TableContainer>
      <Table aria-label="core table">
        {!hideHeaders && (
          <CoreHeader
            loading={isLoading}
            headers={headers}
            handleSorting={handleSorting}
            columnWidth={columnWidth}
            rowHeight={rowHeight}
          />
        )}

        {isDraggable ? (
          <CoreDraggableBody
            globalCellStyle={globalCellStyle}
            headers={headers}
            content={data}
            rowsNumber={headers.length}
            handleRowClick={handleRowClick}
            columnWidth={columnWidth}
            rowHeight={rowHeight}
            hoverActions={hoverActions}
            noVisibleCells={noVisibleCells}
            customRowStyles={customRowStyles}
            customCellStyle={customCellStyle}
            setContent={setContent}
          />
        ) : (
          <CoreBody
            globalCellStyle={globalCellStyle}
            headers={headers}
            content={tableData}
            rowsNumber={headers.length}
            handleRowClick={handleRowClick}
            columnWidth={columnWidth}
            rowHeight={rowHeight}
            hoverActions={hoverActions}
            noVisibleCells={noVisibleCells}
            customRowStyles={customRowStyles}
            customCellStyle={customCellStyle}
            onHoverRow={onHoverRowTableBody}
          />
        )}
      </Table>
      {isPaginated &&
        data.length !== 0 &&
        !isTableHeadersList &&
        changePage && (
          <CoreFooter
            setRowsPerPage={setRowsPerPage}
            page={currentPage ?? 0}
            changePage={changePage}
            numberOfPages={numberOfPages ?? 0}
            rowsPerPage={rowsPerPage ?? 0}
            rowsPerPageOptions={rowsPerPageOptions}
            totalCount={totalCount}
          />
        )}
    </TableContainer>
  );
};

export default CoreTable;
