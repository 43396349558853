import React, { FC, memo, useState } from "react";
import { Group, Rect } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Theme, alpha } from "@mui/material";
import { observer } from "mobx-react";

import { DocumentZone } from "../../../../../../types/interfaces";

interface Props {
  theme: Theme;
  zone: DocumentZone;
  isReadOnly?: boolean | null;
  cellIndex: number;
  isGrey: boolean;
  rowIndex: number;
  currentEditingCell: {
    rowIndex: number;
    colKey: string;
  } | null;
  setCurrentEditingCell: (
    cell: {
      rowIndex: number;
      colKey: string;
    } | null
  ) => void;
  isHeader?: boolean;
  onZoneClick?: () => void;
  isRowHovered?: boolean;
  handleContextMenu?: (e: KonvaEventObject<PointerEvent>) => void;
}

const ZONE_HOVER_LIMIT = 3;

const ZoneCell: FC<Props> = observer(
  ({
    theme,
    zone,
    isGrey,
    isReadOnly,
    rowIndex,
    currentEditingCell,
    isHeader,
    setCurrentEditingCell,
    onZoneClick,
    isRowHovered,
    handleContextMenu,
  }) => {
    const [isHovered, toggleHover] = useState(false);

    const getFill = () => {
      if (isHovered || isRowHovered) {
        return alpha(theme.palette.canvas.dark, 0.3);
      }

      if (
        !isHeader &&
        currentEditingCell?.colKey === zone?.key &&
        currentEditingCell?.rowIndex === rowIndex
      ) {
        return alpha(theme.palette.canvas.light, 0.3);
      }

      if (isGrey) {
        return alpha(theme.palette.surface.dark, 0.25);
      }

      return "transparent";
    };

    // TODO: Keep for later use
    // const unusedColumnProps = useMemo(
    //   () => ({
    //     isGrey,
    //     zoneWidth: zone?.box?.width,
    //     zoneHeight: zone?.box?.height,
    //     theme,
    //   }),
    //   [isGrey, theme, zone?.box?.height, zone?.box?.width]
    // );

    const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
      toggleHover(true);
      // Disable cursor change if zone is header and its not unassigned
      if (!isGrey && isHeader) {
        return;
      }

      const stage = e.target.getStage();
      if (stage) {
        const container = stage.container();
        container.style.cursor = "pointer";
      }
    };

    const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
      toggleHover(false);
      // Disable cursor change if zone is header and its not unassigned
      if (!isGrey && isHeader) {
        return;
      }
      const stage = e.target.getStage();
      if (stage) {
        const container = stage.container();
        container.style.cursor = "default";
      }
    };

    const handleOnClick = () => {
      if (onZoneClick) {
        onZoneClick();
      }
    };

    if (isReadOnly) {
      return (
        <Rect
          rotation={zone?.box?.rotation || 0}
          width={zone?.box.width}
          height={zone?.box?.height}
          strokeScaleEnabled={false}
          fill="transparent"
        />
      );
    }

    return (
      <Group x={zone?.box?.x ?? 0} y={zone?.box?.y ?? 0}>
        <Rect
          rotation={zone?.box?.rotation || 0}
          width={zone?.box?.width}
          height={zone?.box?.height}
          strokeScaleEnabled={false}
          fill={getFill()}
        />

        {/* TODO: maybe replace this with a tiling svg pattern
        <ZoneCellUnusedLines dataProps={unusedColumnProps} />*/}

        {/* Used for Hovering */}
        <Rect
          x={isHeader ? 0 : ZONE_HOVER_LIMIT}
          y={isHeader ? 0 : ZONE_HOVER_LIMIT}
          fill="transparent"
          width={
            isHeader
              ? zone?.box?.width
              : zone?.box?.width - (ZONE_HOVER_LIMIT + ZONE_HOVER_LIMIT)
          }
          height={
            isHeader
              ? zone?.box?.height
              : zone?.box?.height - (ZONE_HOVER_LIMIT + ZONE_HOVER_LIMIT)
          }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleOnClick}
          onContextMenu={handleContextMenu}
          onDblClick={
            // Disable double click for table headers
            isHeader
              ? undefined
              : () => {
                  setCurrentEditingCell({
                    // TODO: Rename
                    rowIndex,
                    colKey: zone?.key as string,
                  });
                  setTimeout(
                    () =>
                      document
                        .getElementById(`${rowIndex}`)
                        ?.scrollIntoView(false),
                    100
                  );
                }
          }
        />
      </Group>
    );
  }
);
export default memo(ZoneCell);
