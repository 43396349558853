import { TFunction } from "i18next";
import React, { FC } from "react";

import { makeStyles } from "@mui/styles";

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import CoreInput from "../../../../core/CoreInput";
import CoreSelect from "../../../../core/CoreSelect";
import { BILLING_FIELD_CONFIG } from "../../../../../types/interfaces/subscription";
import { BILLING_FIELD_TYPES } from "./resources/billingFields";

const useStyles = makeStyles({
  grid: {
    padding: "5px 10px",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  description: {
    opacity: 0.6,
    fontSize: "12px",
    marginTop: "10px",
  },
  groupContainer: {
    display: "flex",
    flexDirection: "row",
  },
  divider: {
    width: "20px",
  },
});

interface Props {
  translate: TFunction;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  field: BILLING_FIELD_CONFIG;
  value: string;
  error: string | undefined;
  postalCode?: string; // Specific for city-postalCode group
  postalCodeError?: string | null;
  onUpdate?: (fieldName: string, newValue: string) => void;
}

export const BillingField: FC<Props> = ({
  translate,
  isReadOnly,
  isDisabled,
  field,
  value,
  error,
  postalCode,
  postalCodeError,
  onUpdate,
}) => {
  const classes = useStyles();

  const {
    name,
    title,
    description,
    placeholder,
    isMandatory,
    type,
    props = {},
  } = field;

  const theme = useTheme();

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={6} className={classes.itemContainer}>
        <Typography>
          {translate(title)}{" "}
          {isMandatory ? (
            <span style={{ color: theme.palette.error.main }}>*</span>
          ) : (
            <></>
          )}
        </Typography>

        {description && (
          <Typography className={classes.description}>
            {translate(description)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={6} className={classes.itemContainer}>
        {type === BILLING_FIELD_TYPES.country_postal ? (
          <Box className={classes.groupContainer}>
            <CoreInput
              disabled={isDisabled}
              readOnly={isReadOnly}
              {...props}
              errorText={error ? translate(error) : ""}
              placeholder={placeholder ? translate(placeholder) : ""}
              value={value}
              onChange={(e) => onUpdate && onUpdate("city", e.target.value)}
            />
            <Box className={classes.divider} />
            <CoreInput
              readOnly={isReadOnly}
              disabled={isDisabled}
              {...props}
              placeholder={translate("billing_city_postal_code_placeholder")}
              type="number"
              value={postalCode}
              errorText={postalCodeError ? translate(postalCodeError) : ""}
              onChange={(e) =>
                onUpdate && onUpdate("postalCode", e.target.value)
              }
            />
          </Box>
        ) : type === BILLING_FIELD_TYPES.select ? (
          <CoreSelect
            readOnly={isReadOnly}
            disabled={isDisabled}
            {...props}
            errorText={error ? translate(error) : ""}
            placeholder={placeholder ? translate(placeholder) : ""}
            value={value}
            onChange={(e) =>
              onUpdate && onUpdate(name, (e?.target?.value as string) || "")
            }
          />
        ) : (
          // By default input
          <CoreInput
            readOnly={isReadOnly}
            disabled={isDisabled}
            {...props}
            errorText={error ? translate(error) : ""}
            placeholder={placeholder ? translate(placeholder) : ""}
            value={value}
            onChange={(e) => onUpdate && onUpdate(name, e.target.value)}
          />
        )}
      </Grid>
    </Grid>
  );
};
