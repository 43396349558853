import React from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import DiagramNodeIcon from "./DiagramNodeIcon";

interface Props {
  nodeKey: string;
  value: string;
  nodeTitleStyle: string;
}

const useStyles = makeStyles({
  textBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "5px",
  },
  iconTextBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "transparent",
    padding: 0,
  },
  iconStyle: {
    fontSize: "14px",
  },
  svgIconStyle: {
    width: "15px",
  },
});

const DiagramNodeLabel: React.FC<Props> = observer(
  ({ nodeKey, value, nodeTitleStyle }) => {
    const classes = useStyles();

    return (
      <Box className={classes.textBox}>
        <DiagramNodeIcon
          nodeKey={nodeKey}
          textBoxStyle={classes.iconTextBox}
          iconStyle={classes.iconStyle}
          svgIconStyle={classes.svgIconStyle}
          forDiagram={true}
        />

        <span className="nodrag" style={{ display: "flex" }}>
          <span className={nodeTitleStyle}>{value}</span>
        </span>
      </Box>
    );
  }
);

export default DiagramNodeLabel;
