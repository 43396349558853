import { makeAutoObservable, runInAction } from "mobx";
import { RootStore } from "./StoresProvider";
import { ApolloClient } from "@apollo/client";
import {
  CREATE_IDENTITY,
  CREATE_IDENTITY_TYPE,
  DELETE_IDENTITY,
  DELETE_IDENTITY_TYPE,
  EDIT_IDENTITY,
  EDIT_IDENTITY_TYPE,
  UPDATE_TARGET_IDENTITY,
  UPDATE_TARGET_IDENTITY_TYPE,
} from "./mutations/identity";
import { GraphQLError } from "graphql";
import { Ability, DevSettings } from "../types/interfaces";
import {
  FILTER_CURRENT_USER_IDENTITY,
  FILTER_CURRENT_USER_IDENTITY_IDENTITY,
  FILTER_IDENTITY,
  FILTER_IDENTITY_TYPE,
  GET_IDENTITY,
  GET_IDENTITY_TYPE,
} from "./queries/identity";

export class IdentityStore {
  root: RootStore;
  loadingCreateIdentity = false;
  loadingUpdateIdentity = false;
  loadingDeleteIdentity = false;
  loadingGetIdentity = false;
  loadingFilterIdentity = false;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  filterIdentity = async (
    client: ApolloClient<unknown>,
    appId?: string,
    permissionSchema?: Record<string, Record<string, string>>
  ) => {
    this.loadingFilterIdentity = true;

    const response = await client.query<FILTER_IDENTITY_TYPE>({
      query: FILTER_IDENTITY,
      variables: { appId, permissionSchemaFilters: permissionSchema },
    });

    const {
      data: { filterIdentity },
      errors,
    } = response;

    if (!filterIdentity || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return filterIdentity;
  };

  getIdentity = async (client: ApolloClient<unknown>, appId: string) => {
    this.loadingFilterIdentity = true;

    const response = await client.query<GET_IDENTITY_TYPE>({
      query: GET_IDENTITY,
      variables: { appId },
    });

    const {
      data: { getIdentity },
      errors,
    } = response;

    if (!getIdentity || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    return getIdentity;
  };

  getUserCurrentNamespaceIdentity = async (
    client: ApolloClient<unknown>,
    userId: string
  ) => {
    const response = await client.query<FILTER_CURRENT_USER_IDENTITY_IDENTITY>({
      query: FILTER_CURRENT_USER_IDENTITY,
      variables: {
        userId,
      },
    });

    const {
      data: { filterIdentityForUser },
      errors,
    } = response;

    if (!filterIdentityForUser || (errors && errors?.[0])) {
      if (errors?.[0]) throw new Error(errors?.[0]?.message);
      else throw new Error();
    }

    runInAction(() => {
      this.loadingCreateIdentity = false;
    });

    return filterIdentityForUser;
  };

  createIdentity = async (client: ApolloClient<unknown>) => {
    // TODO: Implement createIdentity
    this.loadingCreateIdentity = true;

    try {
      const response = await client.mutate<CREATE_IDENTITY_TYPE>({
        mutation: CREATE_IDENTITY,
        variables: {},
      });

      const {
        data: { createIdentity },
        errors,
      } = response as unknown as {
        data: { createIdentity: string };
        errors: GraphQLError[];
      };
      if (!createIdentity || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingCreateIdentity = false;
      });

      return createIdentity;
    } catch (error) {
      runInAction(() => {
        this.loadingCreateIdentity = false;
      });
      throw error;
    }
  };

  editIdentity = async (
    client: ApolloClient<unknown>,
    identity: {
      devSettingsConfig: DevSettings;
      permissionSchema?: Record<string, unknown>;
    }
  ) => {
    this.loadingCreateIdentity = true;

    try {
      const response = await client.mutate<EDIT_IDENTITY_TYPE>({
        mutation: EDIT_IDENTITY,
        variables: {
          identity,
        },
      });

      const {
        data: { editIdentity },
        errors,
      } = response as unknown as {
        data: { editIdentity: string };
        errors: GraphQLError[];
      };
      if (!editIdentity || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingCreateIdentity = false;
      });

      return editIdentity;
    } catch (error) {
      runInAction(() => {
        this.loadingCreateIdentity = false;
      });
      throw error;
    }
  };

  deleteIdentity = async (
    client: ApolloClient<unknown>,
    accessIds: string[]
  ) => {
    this.loadingCreateIdentity = true;

    try {
      const response = await client.mutate<DELETE_IDENTITY_TYPE>({
        mutation: DELETE_IDENTITY,
        variables: {
          accessIds,
        },
      });

      const {
        data: { deleteIdentity },
        errors,
      } = response as unknown as {
        data: { deleteIdentity: string };
        errors: GraphQLError[];
      };
      if (!deleteIdentity || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingUpdateIdentity = false;
      });

      return deleteIdentity;
    } catch (error) {
      runInAction(() => {
        this.loadingUpdateIdentity = false;
      });
      throw error;
    }
  };

  updateTargetIdentity = async (
    client: ApolloClient<unknown>,
    target: string,
    updatedSchema: Ability[] | Record<string, Ability[]>
  ) => {
    this.loadingCreateIdentity = true;

    try {
      const response = await client.mutate<UPDATE_TARGET_IDENTITY_TYPE>({
        mutation: UPDATE_TARGET_IDENTITY,
        variables: {
          appId: `namespace/${this.root.userStore.namespace?.id ?? ""}/`,
          target,
          updatedSchema,
        },
      });

      const {
        data: { updateTargetIdentity },
        errors,
      } = response as unknown as {
        data: { updateTargetIdentity: string };
        errors: GraphQLError[];
      };
      if (!updateTargetIdentity || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingUpdateIdentity = false;
      });

      return updateTargetIdentity;
    } catch (error) {
      runInAction(() => {
        this.loadingUpdateIdentity = false;
      });
      throw error;
    }
  };
}
