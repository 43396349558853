import React, { FC } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import IconButton from "@mui/material/IconButton";

import CoreTooltip from "../../core/CoreTooltip";
import { useNotification } from "../../../context/useNotification";

const useStyle = makeStyles({
  copyButton: {
    display: "flex",
  },
});

interface Props {
  t: TFunction;
  text: string;
  isDisabled?: boolean;
}

export const CopyToClipboardAction: FC<Props> = ({
  t,
  text,
  isDisabled = false,
}) => {
  const classes = useStyle();
  const notification = useNotification();

  const copyToClipboard = () => {
    void navigator.clipboard.writeText(text);
    notification.success(t("textCopied"));
  };

  return (
    <>
      <CoreTooltip title={t("copyText") ?? ""}>
        <span>
          <IconButton
            size="small"
            className={classes.copyButton}
            onClick={copyToClipboard}
            disabled={isDisabled}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </span>
      </CoreTooltip>
    </>
  );
};
