import React, { useState, useMemo } from "react";
import { TFunction } from "i18next";

import { IconButton, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  FormField,
  FormData,
  AssetTemplateConfiguration,
  AssetTemplateParameters,
  FlowInterface,
  Flow,
  FIELD_MAX_LENGTH,
} from "../../../types/interfaces";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { FIELD_TYPE, FlowEnum } from "../../../types/constants";
import ConfigHelper from "../../../helper/configHelper";
import { useStores } from "../../../stores/StoresProvider";
import CreateConnection from "../connections/CreateConnection";
import IndexingFieldView from "./IndexingFieldView";

const getFieldsConfig = (
  t: TFunction,
  asset: AssetTemplateConfiguration,
  fieldsErrors: {
    [key: string]: string;
  },
  formData: FormData,
  flow: FlowInterface,
  flows: Flow[],
  onClickConnection: () => void,
  setFormData: (data: FormData) => void,
  canCreateConnection: boolean,
  forDiagram?: boolean,
  hideNameAndDescription?: boolean,
  disabled?: boolean
) => {
  const fields = asset?.parameters ?? [];
  const getValue = (key: string) => {
    if (key === FlowEnum.rootPath) {
      const path = formData[key] as string;
      if (path) {
        const parts = path.split("/").filter((part) => part);
        if (parts.length > 1) return `.../${parts[parts.length - 1]}`;
        if (parts.length === 1) return parts[0];
        return "/";
      }
    }
    return formData[key] ?? "";
  };

  const getExtraActions = (field: AssetTemplateParameters) => {
    if (field.allowAddConnection && canCreateConnection)
      return {
        extraActions: (
          <IconButton onClick={onClickConnection}>
            <AddIcon />
          </IconButton>
        ),
      };

    return {};
  };

  const notForDiagramConfig = [
    ...ConfigHelper.getFieldsConfig(t, fieldsErrors),
    {
      translationKey: "description",
      key: "description",
      type: FIELD_TYPE.input,
      errorText: fieldsErrors?.["description"],
      isMandatory: false,
      tooltipLocation: "input",
      props: {
        title: t("description"),
        description: t("description"),
        inputProps: {
          maxLength: FIELD_MAX_LENGTH.DESCRIPTION,
        },
      },
    },
  ];

  return [
    ...(!hideNameAndDescription
      ? !forDiagram
        ? notForDiagramConfig
        : ConfigHelper.getFieldsConfig(t, fieldsErrors)
      : []),
    ...fields.map((field) => {
      const parentKeys = Object.keys(field.parents ?? {});
      let render = true;
      parentKeys.forEach((key) => {
        if (!field.parents?.[key].includes(formData[key])) {
          render = false;
        }
      });

      if (!render) return {} as FormField;

      if (formData?.[field?.key] === undefined) {
        setFormData({ ...formData, [field.key]: field.value });
      }

      return {
        ...field,
        translationKey: field.name,
        key: field.key,
        type:
          (field.type as unknown as string) === FlowEnum.flow
            ? FIELD_TYPE.select
            : field.type,
        errorText: fieldsErrors?.[field.key],
        isMandatory: field.isMandatory || false,
        tooltipLocation: "input",
        canContainSpacesOnly: field.canContainSpacesOnly,
        ...getExtraActions(field),
        props: {
          title: field.name,
          readOnly: field.key === FlowEnum.rootPath,
          description: field.description,
          value: getValue(field.key),
          disabled: !disabled || false,
        },
        ...ConfigHelper.getOptions(field, flow, flows, formData),
      };
    }),
  ] as FormField[];
};

interface Props {
  t: TFunction;
  isEditable: boolean;
  formData: FormData;
  assetConfig: AssetTemplateConfiguration;
  fieldsErrors: {
    [key: string]: string;
  };
  setFormData: (data: FormData) => void;
  refreshAssetConfig: () => void;
}

const IndexingSettingsConfig: React.FC<Props> = ({
  t,
  isEditable,
  formData,
  fieldsErrors,
  assetConfig,
  setFormData,
  refreshAssetConfig,
}) => {
  const { userStore, flowStore, flowSettingsStore } = useStores();
  const [openConn, setOpenConn] = useState(false);

  const canCreateConnection =
    userStore.currentUserPermissions?.can("create", "connections") || false;

  const formattedFields = useMemo(
    () =>
      getFieldsConfig(
        t,
        assetConfig,
        fieldsErrors,
        formData,
        flowSettingsStore.flow as FlowInterface,
        flowStore.flows,
        () => setOpenConn(true),
        setFormData,
        canCreateConnection,
        false,
        true,
        isEditable
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      assetConfig?.parameters,
      fieldsErrors,
      formData,
      setOpenConn,
      isEditable,
    ]
  );

  const onFormChange = (value: FormData) => {
    setFormData({ ...formData, ...value });
  };

  const refreshData = () => {
    refreshAssetConfig();
  };

  return (
    <>
      <Box style={{ maxWidth: "800px" }}>
        {isEditable ? (
          <CoreFormRenderer
            fields={formattedFields as unknown as FormField[]}
            translation={t}
            onChange={onFormChange}
            data={formData}
          />
        ) : (
          <Box marginTop="16px">
            {formattedFields &&
              formattedFields?.length > 0 &&
              formattedFields?.map((field, index) => {
                if (Object.keys(field)?.length > 0) {
                  return (
                    <IndexingFieldView
                      key={index}
                      title={field?.name || "-"}
                      value={(field?.value || "-") as unknown as string}
                      fieldDescription={field?.props?.description || ""}
                    />
                  );
                }
                return null;
              })}
          </Box>
        )}
      </Box>

      <CreateConnection
        isOpen={openConn}
        onClose={() => setOpenConn(false)}
        setShouldRefetchAsset={refreshData}
      />
    </>
  );
};

export default IndexingSettingsConfig;
