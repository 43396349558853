import { makeAutoObservable, runInAction } from "mobx";
import { GraphQLError } from "graphql/error";

import { RootStore } from "./StoresProvider";
import { ApplyQAPayload, QADocument, QAFilters } from "../types/interfaces";
import {
  GET_QA_DOCUMENTS,
  GET_QA_DOCUMENTS_COUNT,
  GET_QA_SAMPLE,
  QUERY_QA_DOCUMENTS_COUNT_RESPONSE,
  QUERY_QA_DOCUMENTS_RESPONSE,
  QUERY_QA_SAMPLE_RESPONSE,
} from "./queries/qa";
import { APPLY_QA, MUTATION_APPLY_QA_RESPONSE } from "./mutations/qa";

export class QAStore {
  root: RootStore;

  isPageLoading: boolean; // First load
  isDataLoading: boolean;
  documents: QADocument[];
  currentQACount = 0;
  // Table
  currentPage: number;
  pageSize: number;
  totalPages: number;
  loadingAllDocuments: boolean;
  loadingQA: boolean;
  sessionId: string | undefined;

  constructor(root: RootStore) {
    this.root = root;

    this.isPageLoading = true;
    this.isDataLoading = false;
    this.documents = [];
    // Table
    this.currentPage = 1;
    this.pageSize = 20;
    this.totalPages = 0;
    this.loadingAllDocuments = false;
    this.loadingQA = false;

    this.sessionId = "";

    makeAutoObservable(this, { root: false });
  }

  resetStore = (): void => {
    this.isPageLoading = true;
    this.documents = [];
  };

  async loadDocuments(): Promise<void> {
    if (!this.isPageLoading) {
      this.isPageLoading = true;
    }

    try {
      const response =
        await this.root.client.query<QUERY_QA_DOCUMENTS_RESPONSE>({
          query: GET_QA_DOCUMENTS,
          variables: {
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        });

      const {
        data: { getQADocuments },
        errors,
      } = response;

      if (!getQADocuments || (errors && errors?.[0])) {
        throw new Error();
      }
      runInAction(() => {
        this.isPageLoading = false;
        this.documents = getQADocuments.documents || [];
        this.totalPages = getQADocuments.amountOfPages || 0;
      });
    } catch (error) {
      runInAction(() => {
        this.isPageLoading = false;
        this.documents = [];
        this.totalPages = 0;
      });
    }
  }

  setCurrentPage(newPage: number): void {
    this.currentPage = newPage;
  }

  setSessionId(newSessionId: string | undefined): void {
    this.sessionId = newSessionId;
  }

  loadQADocumentsCount = async () => {
    if (!this.root.userStore.user || !this.root.userStore.namespace) return;
    const response =
      await this.root.client.query<QUERY_QA_DOCUMENTS_COUNT_RESPONSE>({
        query: GET_QA_DOCUMENTS_COUNT,
      });

    const {
      data: { getQADocumentsCount = 0 },
    } = response;

    runInAction(() => {
      this.currentQACount = getQADocumentsCount;
    });
  };

  getQASample = async (filters: QAFilters, page = 1, pageSize = 10) => {
    this.loadingAllDocuments = true;

    try {
      const response = await this.root.client.query<QUERY_QA_SAMPLE_RESPONSE>({
        query: GET_QA_SAMPLE,
        variables: {
          page: page,
          pageSize: pageSize,
          filters: {
            ...filters,
            sessionId: this.sessionId,
          },
        },
      });

      const {
        data: { getQASample },
        errors,
      } = response;

      if (!getQASample || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }
      runInAction(() => {
        this.loadingAllDocuments = false;
      });

      return {
        ...getQASample,
        documents: [...(getQASample.documents ?? [])],
      };
    } catch (error) {
      runInAction(() => {
        this.loadingAllDocuments = false;
      });
      throw error;
    }
  };

  applyQA = async (payload: ApplyQAPayload) => {
    this.loadingQA = true;

    try {
      const { users, assignedBy, excludeErrors } = payload;

      const response =
        await this.root.client.mutate<MUTATION_APPLY_QA_RESPONSE>({
          mutation: APPLY_QA,
          variables: {
            users,
            sessionId: this.sessionId,
            assignedBy,
            excludeErrors,
          },
        });

      const {
        data: { applyQA },
        errors,
      } = response as {
        data: { applyQA: boolean };
        errors: GraphQLError[];
      };

      if (!applyQA || (errors && errors?.[0])) {
        if (errors?.[0]) throw new Error(errors?.[0]?.message);
        else throw new Error();
      }

      runInAction(() => {
        this.loadingQA = false;
      });

      return applyQA;
    } catch (error) {
      runInAction(() => {
        this.loadingQA = false;
      });
      throw error;
    }
  };
}
