import Snackbar from "@mui/material/Snackbar";
import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import AlertTitle from "@mui/material/AlertTitle";
import { makeStyles } from "@mui/styles";

import React, { FC } from "react";
import { NotificationTypes } from "./index";

import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

interface PropsInput {
  type: NotificationTypes;
  title?: string;
  message?: string;
  onClose: () => void;
  open: boolean;
  position?: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "top";
  };
}

const CoreNotification: FC<PropsInput> = ({
  title,
  message,
  type,
  onClose,
  open,
  position = {
    horizontal: "right",
    vertical: "bottom",
  },
}) => {
  const classes = makeStyles({
    box: {
      minWidth: "400px",
      borderTop: "2px solid transparent",
      borderBottom: "2px solid transparent",
      borderLeft: `4px solid transparent`,
      borderLeftColor: `${type}.main`,
    },
    title: {
      fontWeight: "bold",
    },
    alert: {
      borderRadius: 0,
      alignItems: "center",
    },
  })();

  return (
    <Snackbar
      open={open}
      anchorOrigin={position}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Box className={classes.box}>
        <Alert
          severity={type as AlertColor | undefined}
          className={classes.alert}
          elevation={2}
          iconMapping={{
            error: <ErrorOutlineOutlinedIcon />,
            warning: <WarningAmberOutlinedIcon />,
            success: <CheckCircleOutlineOutlinedIcon />,
            info: <InfoOutlinedIcon />,
          }}
        >
          <AlertTitle className={classes.title}>{title}</AlertTitle>
          {message}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default CoreNotification;
