import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";

import { SERVER_URL } from "../configs/backendRoutes";
import {
  getAccessToken,
  retrieveAccessToken,
  setAccessToken,
} from "../helper/authHelper";

declare module "axios" {
  export interface AxiosRequestConfig {
    sent?: boolean;
  }
}

export function getApiClient(
  configOverride: Partial<AxiosRequestConfig> = {}
): AxiosInstance {
  const accessToken = getAccessToken();

  const { headers, ...additionalProperties } = configOverride || {};

  const axiosConfig: AxiosRequestConfig = {
    baseURL: SERVER_URL,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    },
    ...additionalProperties,
  };

  const client = axios.create(axiosConfig);

  // Response interceptor for API calls
  client.interceptors.response.use(
    (res) => res,
    async function (error: AxiosError) {
      const originalRequest =
        error.config as InternalAxiosRequestConfig<unknown>;
      // If call fails with 401 error code then refresh token and retry call
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest?.sent
      ) {
        originalRequest.sent = true; // Set call as "sent" to not retry in loop

        const accessToken = await retrieveAccessToken();
        setAccessToken(accessToken); // Update "token" in "store" to prevent additional calls

        return client({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${accessToken ?? ""}`,
          },
        });
      }

      switch (error.config?.method) {
        case "get":
          return Promise.reject("apiClient:errorFetch");
        case "post":
          return Promise.reject("apiClient:errorPost");
        case "put":
          return Promise.reject("apiClient:errorPut");
        case "delete":
          return Promise.reject("apiClient:errorDelete");

        default:
          return Promise.reject("apiClient:errorNetwork");
      }
    }
  );

  return client;
}
