import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";

import { FlowHelper } from "../filters/helpers/FlowHelper";
import { useStores } from "../../../../stores/StoresProvider";
import CoreCheckbox from "../../../core/CoreCheckbox";
import { useNotification } from "../../../../context/useNotification";
import { FIELD_DATA_TYPES } from "../../../../types/enums";

interface Props {
  t: TFunction;
  flowId: string;
  anchor: null | HTMLElement;
  onClose: () => void;
}

const useStyles = makeStyles({
  list: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "250px",
    overflow: "auto",
  },
  popover: {
    marginTop: "40px",
    marginLeft: "10px",
  },
  text: {
    marginLeft: "-10px",
  },
});

export const DocumentsMenuAction: FC<Props> = observer(
  ({ t, flowId, anchor, onClose }) => {
    const { documentStore, flowStore, mainStore } = useStores();
    const classes = useStyles();
    const notification = useNotification();

    const handleCheckOption = (key: string) => {
      if (
        documentStore.tableFilters &&
        documentStore.tableFilters.includes(key)
      ) {
        if (documentStore.tableFilters?.length === 1) {
          notification.error(t("noTableColumn"));
          return;
        }

        documentStore.setTableFilters(
          documentStore.tableFilters.filter((option) => option !== key),
          documentStore.sortBy as string,
          documentStore.sortDirection as string,
          true
        );
        return;
      }

      documentStore.setTableFilters(
        [...documentStore.tableFilters, key],
        documentStore.sortBy as string,
        documentStore.sortDirection as string,
        true
      );
    };

    const columnFilters = useMemo(() => {
      const filters =
        mainStore.languageFlag === "GB"
          ? { ...FlowHelper.generateColumnFilters(flowId ?? "") }
          : { ...FlowHelper.generateRoColumnFilters(flowId ?? "") };
      if (!flowStore.flowSummary) return filters;

      if (flowStore?.flowSummary?.flowType === "classification") {
        flowStore?.flowSummary?.categories?.forEach((item) => {
          filters[item.key] = item.name;
        });
      } else {
        flowStore?.flowSummary?.fields
          ?.filter(
            (field) => field.dataType?.key !== FIELD_DATA_TYPES.tableDataType
          )
          ?.forEach((item) => {
            filters[item.key] = item.name;
          });
      }

      return filters;
    }, [mainStore.languageFlag, flowId, flowStore.flowSummary]);

    return (
      <Popover
        open={Boolean(anchor)}
        onClose={onClose}
        anchorEl={anchor}
        className={classes.popover}
      >
        <List disablePadding className={classes.list}>
          {Object.keys(columnFilters).map((key) => {
            return (
              <ListItem
                key={key}
                dense
                disablePadding
                onClick={() => handleCheckOption(key)}
              >
                <ListItemButton>
                  <CoreCheckbox
                    checked={documentStore.tableFilters.includes(key)}
                  />
                  <ListItemText className={classes.text}>
                    {columnFilters[key]}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    );
  }
);
