/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Help } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import CoreButton from "../../core/CoreButton";
import { FormData } from "../../../types/interfaces";
import { FIELD_TYPE } from "../../../types/constants";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import CoreTooltip from "../../core/CoreTooltip";
import { passwordRegex } from "../../../types/regex";

const useStyles = makeStyles({
  title: {
    fontWeight: "bolder",
    fontSize: "18px",
  },
  actions: {
    "&.MuiDialogActions-root": {
      padding: "1rem",
    },
  },
  content: {
    "&.MuiDialogContent-root": {
      alignSelf: "stretch",
    },
    margin: "1rem",
  },
  dialogPaper: {
    "& .MuiDialog-paper": {
      padding: 0,
    },
  },
  divider: {
    marginRight: "2.5rem",
    marginLeft: "2.5rem",
  },
  actionsContainer: {
    width: "40px",
    marginTop: "50px",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tooltip: {
    marginBottom: "65px",
  },
});

interface Props {
  id: number | null;
  setUserId: Dispatch<SetStateAction<number | null>>;
}
const data: FormData = {
  password: "",
  confirmPassword: "",
};

const PasswordUpdateModal: FC<Props> = observer(({ id, setUserId }) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { userStore } = useStores();
  const notification = useNotification();
  const { t } = useTranslation("team");

  const [isOperationInProgress, setOperationInProgress] = useState(false);
  const [formData, setFormData] = useState<FormData>(data);

  const isFormInvalid =
    !formData?.password ||
    (formData?.password as string).trim() === "" ||
    !passwordRegex.test(formData?.password as string) ||
    !formData?.confirmPassword ||
    (formData?.confirmPassword as string).trim() === "" ||
    formData.password !== formData.confirmPassword;

  const fields = [
    {
      translationKey: "passwordInput_placeholder",
      key: "password",
      type: FIELD_TYPE.password,
      props: {
        title: t("passwordInput_placeholder"),
        disabled: isOperationInProgress,
      },
    },
    {
      translationKey: "confirmPasswordInput_placeholder",
      key: "confirmPassword",
      type: FIELD_TYPE.password,
      props: {
        title: t("confirmPasswordInput_placeholder"),
        disabled: isOperationInProgress,
      },
    },
  ];

  useEffect(() => {
    setFormData(data);
  }, [userStore.isPasswordModalOpened]);

  const closeModal = () => {
    setUserId(null);
    userStore.togglePasswordModal(false);
  };

  const handleInput = (value: FormData) => setFormData(value);

  const handleUpdate = () => {
    if (formData && id) {
      setOperationInProgress(true);

      userStore
        .updatePassword(client, formData.password as string, id)
        .then(() => {
          userStore.togglePasswordModal(false);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "update_error"));
        })
        .finally(() => {
          setUserId(null);
          setOperationInProgress(false);
        });
    }
  };

  return (
    <Dialog
      fullWidth
      open={userStore.isPasswordModalOpened}
      onClose={closeModal}
      className={classes.dialogPaper}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography className={classes.title}>{t("modal_title")}</Typography>
      </DialogTitle>

      <Divider className={classes.divider} />
      <DialogContent className={classes.content}>
        <Box className={classes.dialogContent}>
          <CoreFormRenderer
            fields={fields}
            translation={t}
            data={formData}
            onChange={handleInput}
          />
          <Box className={classes.actionsContainer}>
            <CoreTooltip
              title={t("password_tooltip") || ""}
              className={classes.tooltip}
            >
              <IconButton>
                <Help />
              </IconButton>
            </CoreTooltip>

            <CoreTooltip title={t("confirmPassword_tooltip") || ""}>
              <IconButton>
                <Help />
              </IconButton>
            </CoreTooltip>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <CoreButton
          disabled={isOperationInProgress}
          onClick={closeModal}
          variant="neutral"
        >
          {t("cancel")}
        </CoreButton>

        <CoreButton
          disabled={isOperationInProgress || isFormInvalid}
          autoFocus
          onClick={handleUpdate}
          isLoading={isOperationInProgress}
          variant="contained"
        >
          {t("update")}
        </CoreButton>
      </DialogActions>
    </Dialog>
  );
});

export default PasswordUpdateModal;
