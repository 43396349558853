import React, { useEffect, useState, useMemo } from "react";
import { Resizable, NumberSize } from "re-resizable";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";

import { useTheme, alpha, IconButton, MenuList } from "@mui/material";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import { useStores } from "../../../../stores/StoresProvider";
import { getSettingsMenuConfig } from "../menuConfig";
import MenuContainer from "./MenuContainer";
import { AppSuspense } from "../../../main/AppSuspense";

const CONTAINER_SIZES = {
  default: 225,
  min: 80,
  max: 225,
};

const SettingsMenu: React.FC = () => {
  const theme = useTheme();
  const { ready } = useTranslation("settings");
  const { mainStore, userStore } = useStores();

  const [isExpanded, setIsExpanded] = useState(true);
  const [containerSize, setContainerSize] = useState({
    width: CONTAINER_SIZES.default,
    height: "auto",
  });

  const menuItems = useMemo(
    () => getSettingsMenuConfig(userStore.currentUserPermissions),
    [userStore.currentUserPermissions]
  );

  useEffect(() => {
    mainStore.collapseSidebar();

    return () => {
      mainStore.resetSidebar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = makeStyles({
    menuList: {
      padding: 0,
      marginBottom: "8px",
      width: "100%",
      paddingTop: "3px",
    },
    iconButton: {
      zIndex: 10,
      top: "50%",
      right: "-18px",
      position: "absolute",
      color: theme.palette.divider,
      width: "15px",
      borderRadius: 0,
    },
    handlerStyle: {
      "&> div > div": {
        width: "15px !important",
        backgroundColor: alpha(theme.palette.primary.dark, 0.5),
        borderLeft: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        right: "-17px !important",
        "&:hover": {
          opacity: 0.7,
        },
      },
    },
  })();

  const updateSize = () => {
    const sidebarAnimationHandle = document.querySelector(
      ".addTransition"
    ) as unknown as HTMLElement;

    if (isExpanded) {
      sidebarAnimationHandle.style.transition = "width 0.4s";
      setContainerSize({ ...containerSize, width: CONTAINER_SIZES.min });
      setIsExpanded(false);
      return;
    }

    setContainerSize({ ...containerSize, width: CONTAINER_SIZES.default });
    setIsExpanded(true);
  };

  const handleResize = (
    _event: MouseEvent | TouchEvent,
    _direction: unknown,
    _elementRef: HTMLElement,
    delta: NumberSize
  ) => {
    const sidebarAnimationHandle = document.querySelector(
      ".addTransition"
    ) as unknown as HTMLElement;
    sidebarAnimationHandle.style.transition = "width 0.4s";
    const currentWidth = containerSize.width + delta.width;

    if (currentWidth === CONTAINER_SIZES.min) {
      setIsExpanded(false);
    }

    if (currentWidth === CONTAINER_SIZES.max) {
      setIsExpanded(true);
    }

    setContainerSize({
      ...containerSize,
      width: currentWidth,
    });
  };

  const handleStart = () => {
    const sidebarAnimationHandle = document.querySelector(
      ".addTransition"
    ) as unknown as HTMLElement;
    sidebarAnimationHandle.style.transition = "";
  };

  const BarIcon = isExpanded ? ArrowLeftIcon : ArrowRightIcon;

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Resizable
      minWidth={CONTAINER_SIZES.min}
      maxWidth={CONTAINER_SIZES.max}
      style={{
        padding: 0,
        marginTop: "64px",
        position: "relative",
      }}
      size={{
        width: containerSize.width,
        height: containerSize.height,
      }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      className={`${classes.handlerStyle} addTransition`}
      onResizeStart={handleStart}
      onResizeStop={handleResize}
    >
      <IconButton className={classes.iconButton} onClick={updateSize}>
        <BarIcon />
      </IconButton>

      <MenuList className={classes.menuList}>
        <MenuContainer options={menuItems} />
      </MenuList>
    </Resizable>
  );
};

export default SettingsMenu;
