import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Radio, Typography, useTheme } from "@mui/material";

import { useStores } from "../../../../../../stores/StoresProvider";
import { BILLING_CARD_TYPES } from "../../../../../../types/interfaces/subscription";

import { StripeCard } from "../StripeCard";

const useStyles = makeStyles({
  optionName: {
    display: "flex",
    flexDirection: "column",
  },
  titleName: {
    marginRight: "5px",
  },
  gridItemCheckbox: {
    width: "45px",
  },
  gridItemCardDetails: {
    flex: 1,
    alignSelf: "center",
  },
});

export const PaymentMethod: FC = observer(() => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("subscription");
  const { subscriptionStore } = useStores();

  const isExisting = subscriptionStore.cardType === BILLING_CARD_TYPES.existing;
  const isAddCard = subscriptionStore.cardType === BILLING_CARD_TYPES.new;

  const handleChange = () => {
    // Keep for later use
  };

  const changeOption = (type: string) => {
    if (type !== subscriptionStore.cardType) {
      subscriptionStore.setCardType(type);
    }
  };

  return (
    <>
      <React.Fragment>
        {subscriptionStore.activeSubscription?.card ? (
          <>
            <Divider />

            <Grid
              onClick={() => changeOption(BILLING_CARD_TYPES.existing)}
              container
              direction="row"
              sx={{
                padding: "15px 10px",
                cursor: "pointer",
                background: isExisting ? theme.palette.primary.dark : "auto",
              }}
            >
              <Grid item className={classes.gridItemCheckbox}>
                <Radio checked={isExisting} />
              </Grid>

              <Grid item className={classes.gridItemCardDetails}>
                <Box className={classes.optionName}>
                  <Typography className={classes.titleName}>
                    {t("subscription_upgrade_payment_use_current_card", {
                      cardNumber:
                        subscriptionStore.activeSubscription?.card || "-",
                    })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <></>
        )}

        <Divider />

        <Grid
          onClick={() => changeOption(BILLING_CARD_TYPES.new)}
          container
          direction="row"
          sx={{
            cursor: "pointer",
            padding: "15px 10px",
            background: isAddCard ? theme.palette.primary.dark : "auto",
          }}
        >
          <Grid item className={classes.gridItemCheckbox}>
            <Radio checked={isAddCard} />
          </Grid>

          <Grid item className={classes.gridItemCardDetails}>
            <Box className={classes.optionName}>
              <Typography className={classes.titleName}>
                {t("subscription_upgrade_payment_add_card")}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {isAddCard && (
          <Grid
            container
            direction="row"
            sx={{
              cursor: "pointer",
              padding: "0px 10px 15px 50px",
              background: theme.palette.primary.dark,
            }}
          >
            <Grid item className={classes.gridItemCardDetails}>
              <Box className={classes.optionName}>
                <Box sx={{ maxWidth: "450px", width: "100%" }}>
                  <StripeCard
                    emptyMessage={t(
                      "subscription_upgrade_payment_card_mandatory"
                    )}
                    onChange={handleChange}
                    containerProps={{ margin: "0px 0px 20px 0px" }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    </>
  );
});
