import React, { FC, useMemo, useState } from "react";
import { observer } from "mobx-react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { makeStyles } from "@mui/styles";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import CoreTooltip from "./CoreTooltip";

interface Props {
  label?: string;
  options: {
    displayName: string;
    value: string;
  }[];
  description?: string;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

interface ToggleItem {
  displayName: string;
  value: string;
}

export const CoreToggle: FC<Props> = observer(
  ({ label, options, description, disabled = false, onChange, value }) => {
    const theme = useTheme();

    const [selectedOption, setSelectedOption] = useState<string>(value ?? "");

    const columnWidth = useMemo(() => {
      const numOptions = options.length;

      return numOptions ? 100 / numOptions : numOptions;
    }, [options.length]);

    const isSelectedOption = (option: string) => option === selectedOption;

    const getSelectedIndex = () => {
      const indexFound = options.findIndex((option) =>
        isSelectedOption(option.value)
      );
      return indexFound > -1 ? indexFound : 0;
    };

    const createToggleOption = (item: ToggleItem) => {
      const { value, displayName } = item;

      const selectOption = (value: string) => {
        if (disabled) return;
        setSelectedOption(value);
        onChange && onChange(value);
      };

      return (
        <Box
          key={value}
          className={`${classes.toggleOption} ${
            isSelectedOption(value) ? classes.selected : ""
          } `}
          onClick={() => selectOption(value)}
        >
          {displayName || value}
        </Box>
      );
    };

    const classes = makeStyles({
      mainContainer: {
        width: "100%",
      },
      toggleBox: {
        width: "100%",
        display: "flex",
      },
      toggleWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      toggleContainer: {
        width: "100%",
        margin: "0 auto",
        position: "relative",
        background: "transparent",
        border: "1px solid",
        borderRadius: "2px",
      },
      toggleOption: {
        display: "inline-block",
        position: "relative",
        zIndex: 1,
        textAlign: "center",
        height: "30px",
        lineHeight: "30px",
        cursor: "pointer",
        color: alpha(theme.palette.text.primary, 0.5),
        fontSize: "1em",
        width: `${columnWidth}%`,
      },
      selected: {
        color: theme.palette.text.primary,
        cursor: "initial",
      },
      toggle: {
        position: "absolute",
        height: "100%",
        bottom: "0",
        left: "0",
        transition: "all 0.4s ease-in-out",
        borderRadius: "2px",
        background: alpha(theme.palette.highlight.main, 0.75),
        width: `${columnWidth}%`,
        transform: `translateX(${100 * getSelectedIndex()}%)`,
        WebkitTransform: `translateX(${100 * getSelectedIndex()}%)`,
        MozTransform: `translateX(${100 * getSelectedIndex()}%)`,
        msTransform: `translateX(${100 * getSelectedIndex()}%)`,
      },
      icon: {
        alignSelf: "center",
        marginLeft: "15px",
      },
    })();

    return (
      <Box className={classes.mainContainer}>
        {label && <Typography variant="subtitle2">{label}</Typography>}

        <Box className={classes.toggleBox}>
          <Box className={classes.toggleWrapper}>
            <Box className={classes.toggleContainer}>
              {options.map((item) => createToggleOption(item))}
              <Box className={classes.toggle} />
            </Box>
          </Box>

          {description && (
            <CoreTooltip title={description}>
              <InfoOutlinedIcon className={classes.icon} />
            </CoreTooltip>
          )}
        </Box>
      </Box>
    );
  }
);
