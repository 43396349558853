export enum DocumentStages {
  CREATED = "created",
  RUNNING = "running",
  ERROR = "error",
  REJECTED = "rejected",
  REQUIRES_USER_INPUT = "requiresUserInput",
  DONE = "done",
  WAITING = "waiting",
}

export enum DocumentHistoryStages {
  SUCCESS = "success",
  FAILED = "failed",
}

export enum DOCUMENT_VALIDATION_TYPES {
  validation = "validation",
  qa = "qa",
}

export enum ContextEventStatuses {
  SUCCESS = "success",
  ERROR = "error",
  PAUSE = "pause",
}

export enum ContextEventTypes {
  UI = "ui",
  EXECUTE = "execute",
  TRIGGERS = "triggers",
  SYSTEM = "system",
  API = "api",
}

export enum ActivityLogsFilterTypes {
  flowsFilter = "flowsFilter",
  eventTypeFilter = "eventTypeFilter",
}

export enum FlowTypes {
  extraction = "extraction",
  classification = "classification",
}

export enum FIELD_DATA_TYPES {
  textDataType = "textDataType",
  dateDataType = "dateDataType",
  numberDataType = "numberDataType",
  dropdownDataType = "dropdownDataType",
  tableDataType = "tableDataType",
}

export enum FLOW_DATA_CATEGORY_ITEM_STATUSES {
  RUNNING = "running",
  PAUSED = "paused",
}

export enum FormFieldLanguageTypes {
  java = "java",
  charp = "csharp",
  json = "json",
  python = "python",
  xml = "xml",
  yaml = "yaml",
}
