import React, { FC } from "react";

import { makeStyles } from "@mui/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/material";
import CoreTooltip from "./CoreTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const useStyles = makeStyles({
  box: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  checkbox: {
    alignSelf: "center",
  },
});

interface Props extends CheckboxProps {
  labelPlacement?: "bottom" | "end" | "start" | "top";
  label?: string | undefined;
  description?: string;
}

const CoreCheckbox: FC<Props> = ({
  labelPlacement = "end",
  label,
  description,
  ...extended
}) => {
  const classes = useStyles();

  return (
    <FormControl>
      <Box className={classes.box}>
        <FormControlLabel
          control={<Checkbox {...extended} />}
          label={label || ""}
          labelPlacement={labelPlacement}
        />

        {description && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.checkbox} />
          </CoreTooltip>
        )}
      </Box>
    </FormControl>
  );
};

export default CoreCheckbox;
