import React from "react";

import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";

import CoreInput from "../../core/CoreInput";

type Props = {
  title: string;
  value: string;
  fieldDescription: string;
};

const useStyle = makeStyles({
  container: {
    marginTop: "16px",
  },
  title: {
    opacity: 0.6,
    fontSize: "14px",
  },
});

const IndexingFieldView: React.FC<Props> = ({
  title,
  value,
  fieldDescription,
}) => {
  const classes = useStyle();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>{title || ""}</Typography>
      <CoreInput
        readOnly={true}
        value={value || "-"}
        description={fieldDescription || ""}
        tooltipLocation="input"
      />
    </Box>
  );
};

export default IndexingFieldView;
