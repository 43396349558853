import React, { FC } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { ImagePreview } from "../../../core/table/miscellaneous/ImagePreview";
import { useStores } from "../../../../stores/StoresProvider";

export const DocumentPreviewDrawer: FC<{ t: TFunction }> = observer(({ t }) => {
  const { documentStore } = useStores();

  if (documentStore.hoveredDocument) {
    return (
      <ImagePreview
        imageUri={documentStore.hoveredDocument.pageUri}
        name={documentStore.hoveredDocument.name}
        pages={parseInt(documentStore.hoveredDocument.pageCount)}
        t={t}
        status={documentStore.hoveredDocument.status}
        documentStore={documentStore}
      />
    );
  }

  return <></>;
});
