import React, { FC } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import CoreTag from "../../../core/CoreTag";
import { FILTER_PROPS } from "../../../../types/constants";

interface Props {
  t: TFunction;
  manuallyUpdated: boolean | undefined;
  setFilters: (key: string, value: string | string[] | unknown) => void;
}

const FilterManuallyUpdatedTag: FC<Props> = observer(
  ({ t, manuallyUpdated, setFilters }) => {
    const tagValue = manuallyUpdated
      ? t("manuallyUpdatedFilter_yes")
      : t("manuallyUpdatedFilter_no");

    return (
      <CoreTag
        label={tagValue}
        variant="outlined"
        onDelete={() => setFilters(FILTER_PROPS.manuallyUpdated, undefined)}
      />
    );
  }
);

export default FilterManuallyUpdatedTag;
