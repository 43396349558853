import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { useTheme, alpha, Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import { DocumentZone, DocumentField } from "../../../../../types/interfaces";
import CoreInput from "../../../../core/CoreInput";
import {
  THEME_MODES,
  CANVAS_ACTIONS,
  EDITABLE_ZONE_ACTIONS,
  VAL_TYPES,
} from "../../../../../types/constants";
import { useStores } from "../../../../../stores/StoresProvider";
import CoreTooltip from "../../../../core/CoreTooltip";
import { useNotification } from "../../../../../context/useNotification";
import { CopyToClipboardAction } from "../../../team/CopyToClipboardAction";
import CoreCheckbox from "../../../../core/CoreCheckbox";
import CanvasFieldActions from "./CanvasFieldActions";

interface Props {
  field: DocumentField;
  translation: TFunction;
  error?: string;
}

const CanvasField: React.FC<Props> = ({ field, translation, error }) => {
  const {
    documentStore,
    mainStore: { currentTheme },
  } = useStores();
  const theme = useTheme();
  const notification = useNotification();

  const [isFocused, setIsFocused] = useState(false);

  const classes = makeStyles({
    customFormControl: {
      padding: "2px 5px 5px 5px",
      "&:hover": {
        backgroundColor:
          currentTheme === THEME_MODES.light
            ? theme.palette.canvas.contrastText
            : "rgba(120, 123, 143, 0.08)",
      },
    },
    focusedFormControl: {
      background: alpha(theme.palette.neutral.main, 0.3),
      border: `1px solid ${alpha(theme.palette.neutral.main, 0.7)}`,
    },
    warningIcon: {
      marginLeft: "10px",
      fontSize: 20,
    },
  })();

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    document,
    documentPage,
    fields,
    focusedFieldCanvas,
    viewMode,
    updatingPage,
    canvasAction,
  } = documentStore;

  const docField = fields?.find((item) => item.key === field.key);

  const fieldValue = useMemo(() => {
    if (!docField) {
      return "";
    }

    if (docField?.type === VAL_TYPES.boolean) {
      return docField?.text === "true";
    }

    return docField?.text || "";
  }, [docField]);

  const isTextInvalid =
    !docField ||
    !docField.text ||
    (typeof docField.text === "string" && docField.text?.trim() === "");

  const isDisabled =
    updatingPage ||
    viewMode ||
    (!isTextInvalid &&
      !!docField?.pageIdentifier &&
      docField?.pageIdentifier !== documentPage?.identifier &&
      docField.key !== focusedFieldCanvas);

  useEffect(() => {
    const input = inputRef.current;

    if (focusedFieldCanvas === field.key) {
      const docField = fields?.find((item) => item.key === field.key);
      if (
        docField &&
        docField.pageIdentifier !== documentPage?.identifier &&
        !isFocused
      ) {
        documentStore
          .loadDocumentPage(
            document?.identifier as string,
            docField?.pageIdentifier
          )
          .then(() => {
            setIsFocused(true);
            input?.focus();
          })
          .catch(() => {
            notification.error(translation("error_loading_page"));
          });
      } else {
        setIsFocused(true);
        input?.focus();
      }
    } else {
      input?.blur();
      setIsFocused(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.key, focusedFieldCanvas, documentPage?.identifier]);

  const handleKeyDown = (
    e: React.KeyboardEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLDivElement
    >
  ) => {
    if (e.key === "Tab") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleKeyUp = useCallback(
    (
      e: React.KeyboardEvent<
        HTMLTextAreaElement | HTMLInputElement | HTMLDivElement
      >
    ) => {
      // Go to next field if current field is focused
      if (e.key === "Tab") {
        e.preventDefault();
        e.stopPropagation();
        if (e.shiftKey) {
          documentStore.getTabLastFocusedFieldCanvas();
        } else {
          documentStore.getTabNextFocusedFieldCanvas();
        }
      }

      if (e.key === "Enter") {
        documentStore.getNextFocusedFieldCanvas(false);
      }
      if (e.key === "Escape") {
        documentStore.handleCancelFocusedFieldCanvas(true);
      }
    },
    [documentStore]
  );

  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      documentStore.setFields([
        ...fields.map((item) =>
          item.key === field.key ? { ...item, text: event.target.value } : item
        ),
      ]);
    },
    [documentStore, fields, field.key]
  );

  const onChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      documentStore.setFields([
        ...fields.map((item) =>
          item.key === field.key
            ? { ...item, text: event?.target?.checked?.toString() || "false" }
            : item
        ),
      ]);
    },
    [documentStore, fields, field.key]
  );

  const handleFocusedFields = useCallback(
    (field: DocumentField | null, zone?: DocumentZone) => {
      if (isFocused) return;
      documentStore.setLineItemsMode(false);
      documentStore.setFocusFieldCanvas(field ? field?.key : null);
      documentStore.setFocusZone(
        zone?.identifier || (zone as unknown as string)
      );
      if (zone && zone.pageIdentifier !== documentPage?.identifier) {
        void documentStore.loadDocumentPage(
          document?.identifier as string,
          zone?.pageIdentifier as string
        );
      }
      if (!viewMode) {
        documentStore.changeCanvasAction(CANVAS_ACTIONS.drawNewZone);
      }
      documentStore.setFocusZone(zone?.identifier || null);
      documentStore.setFocusFieldCanvas(field ? field?.key : null);

      if (zone && zone?.pageIdentifier !== documentPage?.identifier) {
        documentStore.setSelectedPages([zone.pageIdentifier as string]);
        documentStore
          .loadDocumentPage(
            document?.identifier as string,
            zone.pageIdentifier as string
          )
          .then(() => {
            documentStore.setFocusZone(
              zone?.identifier || (zone as unknown as string)
            );
          })
          .catch((error: Error) => {
            notification.error(
              translation(error?.message || "error_loading_page")
            );
          });
      }
    },
    [
      viewMode,
      documentStore,
      documentPage?.identifier,
      document?.identifier,
      notification,
      translation,
      isFocused,
    ]
  );

  const isEditable = useMemo(
    () => EDITABLE_ZONE_ACTIONS.includes(canvasAction) && !viewMode,
    [canvasAction, viewMode]
  );

  const handleInputType = (type: string) => {
    if (type === VAL_TYPES.number || type === VAL_TYPES.integer) {
      return VAL_TYPES.number;
    }

    return VAL_TYPES.text;
  };

  const fieldRenderer = (field: DocumentField) => {
    switch (field.type) {
      case VAL_TYPES.string:
      case VAL_TYPES.integer:
      case VAL_TYPES.number:
        return (
          <CoreInput
            formControlClass={
              (isFocused ? classes.focusedFormControl : "") +
              " " +
              classes.customFormControl
            }
            type={handleInputType(field.type)}
            inputRef={inputRef}
            key={`${field.key || ""}`}
            title={field.name || field.key}
            titleIcon={
              error && (
                <CoreTooltip title={error} placement="right">
                  <WarningIcon
                    color="warning"
                    className={classes.warningIcon}
                  />
                </CoreTooltip>
              )
            }
            value={fieldValue}
            readOnly={!isEditable || isDisabled}
            // If view mode, then remove -disabled- property in order to allow field focus (canvas zone focus)
            {...(!viewMode
              ? {
                  disabled: isDisabled,
                }
              : {})}
            {...(!isEditable || isDisabled
              ? { style: { pointerEvents: "none" } }
              : {})}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onChange={onChangeInput}
            endAdornment={
              isFocused &&
              isEditable &&
              fieldValue && (
                <CopyToClipboardAction
                  t={translation}
                  text={fieldValue as string}
                ></CopyToClipboardAction>
              )
            }
            // {...(inputVariant ? { variant: inputVariant } : {})}
          />
        );
      case VAL_TYPES.boolean:
        return (
          <Box
            className={classes.customFormControl}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            component="div"
          >
            <CoreCheckbox
              onChange={onChangeCheckbox}
              checked={fieldValue as boolean}
              label={field.name}
              tabIndex={-1}
            />
          </Box>
        );
      default:
        return <> </>;
    }
  };

  return (
    <>
      <div onClick={() => handleFocusedFields(field)}>
        {fieldRenderer(field)}
      </div>
      {isFocused && (
        <CanvasFieldActions
          field={field}
          documentStore={documentStore}
          translation={translation}
          handleFocusedFields={handleFocusedFields}
          isDisabled={isDisabled}
        />
      )}
    </>
  );
};

export default CanvasField;
