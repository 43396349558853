import React, { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useStores } from "../../../../../../../stores/StoresProvider";
import CoreButton from "../../../../../../core/CoreButton";
import { CoreCard } from "../../../../../../core/CoreCard";
import {
  BILLING_CONTRACT_TERMS,
  PREDEFINED_PLANS,
  SUBSCRIPTION_TAB_INDEXES,
  SUBSCRIPTION_TAB_KEYS,
} from "../../../../../../../types/interfaces/subscription";
import { UpdateCreditCardDrawer } from "../UpdateCreditCardDrawer";
import BillingHelper from "../../../../../../../helper/billingHelper";
import { SubscriptionPrice } from "../../SubscriptionPrice";

interface DetailsCardProps {
  title: string;
  subtitle: string | React.ReactNode | undefined;
  extraSubtitle?: string | React.ReactNode | undefined;
  action: string;
  onActionClick: () => void;
}

const useStyles = makeStyles({
  container: {
    marginTop: "10px",
  },
  title: {
    opacity: 0.6,
    fontSize: "12px",
    marginBottom: "10px",
  },
  action: {
    fontWeight: "normal",
    fontSize: "12px",
    marginTop: "15px",
    borderColor: "transparent",
    padding: "0px",
    "&:hover": {
      borderColor: "transparent",
      backgroundColor: "transparent",
    },
  },
  subtitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
});

const DetailsCard: FC<DetailsCardProps> = ({
  title,
  subtitle,
  extraSubtitle,
  action,
  onActionClick,
}) => {
  const classes = useStyles();

  return (
    <CoreCard>
      <Typography className={classes.title}>{title}</Typography>

      <Box className={classes.subtitleContainer}>
        {typeof subtitle === "string" ? (
          <Typography>{subtitle || "-"}</Typography>
        ) : (
          subtitle
        )}
        {extraSubtitle}
      </Box>

      <CoreButton
        variant="outlined"
        className={classes.action}
        onClick={onActionClick}
      >
        {action}
      </CoreButton>
    </CoreCard>
  );
};

export const CurrentPackageDetails: FC = () => {
  const { t } = useTranslation("subscription");
  const classes = useStyles();
  const { subscriptionStore } = useStores();

  const [isDrawerOpened, toggleDrawer] = useState(false);

  const isFreePlan =
    subscriptionStore.activeSubscription?.plan === PREDEFINED_PLANS.free;

  // Handled card number
  const subscriptionCard = useMemo(
    () =>
      subscriptionStore.activeSubscription?.card
        ? `${t("hiddenCardNumber")}  ${
            subscriptionStore.activeSubscription?.card
          }`
        : t("active_subscription_card_no_card"),
    [subscriptionStore.activeSubscription?.card, t]
  );

  // Plan renews when plan expires
  const renewDate = useMemo(
    () =>
      !isFreePlan && subscriptionStore?.activeSubscription?.expiresAt
        ? BillingHelper.formatTimestamp(
            subscriptionStore?.activeSubscription?.expiresAt
          )
        : "-",
    [isFreePlan, subscriptionStore?.activeSubscription?.expiresAt]
  );

  const handleReceiptsClick = (): void => {
    subscriptionStore.setTabIndex(
      SUBSCRIPTION_TAB_INDEXES[SUBSCRIPTION_TAB_KEYS.receipts]
    );
  };

  const handleBillingClick = (): void => {
    subscriptionStore.setTabIndex(
      SUBSCRIPTION_TAB_INDEXES[SUBSCRIPTION_TAB_KEYS.billingDetails]
    );
  };

  const handleCardActionClick = (): void => {
    toggleDrawer(true);
  };

  if (!subscriptionStore.activeSubscription) {
    return <></>;
  }

  return (
    <>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} md={12} lg={4}>
          <DetailsCard
            title={t("active_subscription_renew_date")}
            subtitle={renewDate}
            action={t("active_subscription_receipts_action")}
            onActionClick={handleReceiptsClick}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <DetailsCard
            title={
              subscriptionStore.activeSubscription?.period ===
              BILLING_CONTRACT_TERMS.year
                ? t("active_subscription_annual_price")
                : t("active_subscription_monthly_price")
            }
            subtitle={
              <SubscriptionPrice
                price={subscriptionStore.activeSubscription?.price}
                currency={subscriptionStore.activeSubscription.currency}
                contractTerm={subscriptionStore.activeSubscription?.period}
                discount={subscriptionStore.activeSubscription?.discount}
              />
            }
            action={t("active_subscription_billing_action")}
            onActionClick={handleBillingClick}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <DetailsCard
            title={t("active_subscription_card")}
            subtitle={subscriptionCard}
            action={t("active_subscription_credit_card_action")}
            onActionClick={handleCardActionClick}
          />
        </Grid>
      </Grid>

      <UpdateCreditCardDrawer
        isOpened={isDrawerOpened}
        onClose={() => toggleDrawer(false)}
      />
    </>
  );
};
