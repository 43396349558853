import queryString from "query-string";

export interface TokenProps {
  token: string;
}

export default class UrlHelper {
  static getUrlQueryParams(): string {
    const parsed = queryString.parse(location.search);
    const token = parsed.token;
    return token as string;
  }
}
