import React, { FC, useEffect, useState } from "react";
import { TFunction } from "i18next";

import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { FlowDataCategoryItem } from "../../../../types/interfaces/flow";
import { DataCategoryTable } from "./DataCategoryTable";
import { DataCategoryFilterBox } from "./DataCategoryFilterBox";

interface Props {
  t: TFunction;
  type: string;
  flowId: string;
  refreshData?: boolean;
  setRefreshData: (refreshData: boolean) => void;
}

export const DataCategoryContent: FC<Props> = ({
  t,
  type,
  flowId,
  refreshData,
  setRefreshData,
}) => {
  const { flowStore } = useStores();
  const notification = useNotification();

  const [textSearch, setTextSearch] = useState("");
  const [data, setData] = useState<FlowDataCategoryItem[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const fetchData = () => {
    setIsDataLoading(true);
    flowStore
      .getFlowCategoryData(type, flowId)
      .then((result) => {
        setData(result);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "flowCategoryDataError"));
      })
      .finally(() => {
        setRefreshData(false);
        setIsDataLoading(false);
      });
  };

  useEffect(() => {
    setData([]);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (refreshData) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData]);

  return (
    <>
      <DataCategoryFilterBox
        t={t}
        textSearch={textSearch}
        updateTextSearch={(updatedSearch: string) =>
          setTextSearch(updatedSearch)
        }
      />

      <DataCategoryTable
        t={t}
        data={data}
        textSearch={textSearch}
        fetchData={fetchData}
        isLoading={isDataLoading}
      />
    </>
  );
};
