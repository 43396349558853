import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import CoreDrawer from "../../core/CoreDrawer";
import CoreButton from "../../core/CoreButton";
import { useStores } from "../../../stores/StoresProvider";
import FlowHelper from "../../../helper/flowHelper";
import {
  AssetTemplateConfiguration,
  AssetTemplateParameters,
  Variable,
  FormData,
  FormField,
} from "../../../types/interfaces";
import { FIELD_TYPE, FlowEnum } from "../../../types/constants";
import { CoreFormRenderer } from "../../core/CoreFormRenderer";
import { useNotification } from "../../../context/useNotification";
import { useApiClient } from "../../../api/useApiClient";
import { AppSuspense } from "../../main/AppSuspense";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  t: TFunction;
}

const useTheme = makeStyles({
  box: {
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    padding: "30px 0 0 0 ",
    flexDirection: "row-reverse",
  },
});

const getFieldOptions = (field: AssetTemplateParameters) => {
  return field.options ?? [];
};

const renderedFieldsKeys = (
  formData: FormData,
  fields: AssetTemplateParameters[]
) => {
  return fields
    ?.filter((field) => {
      const parentKeys = Object.keys(field.parents ?? {});
      let render = true;
      parentKeys.forEach((key) => {
        if (!field.parents?.[key].includes(formData[key])) {
          render = false;
        }
      });

      return render;
    })
    .map((field) => field.key);
};

const getFieldsConfig = (
  t: TFunction,
  fields: AssetTemplateParameters[],
  fieldsErrors: {
    [key: string]: string;
  },
  formData: FormData
) => {
  return fields
    .map((field) => {
      const parentKeys = Object.keys(field.parents ?? {});
      let render = true;
      parentKeys.forEach((key) => {
        if (!field.parents?.[key].includes(formData[key])) {
          render = false;
        }
      });

      if (!render) return {} as FormField;

      return {
        ...field,
        translationKey: field.name,
        key: field.key,
        type:
          (field.type as unknown as string) === FlowEnum.flow
            ? FIELD_TYPE.select
            : field.type,
        errorText: fieldsErrors?.[field.key],
        isMandatory: field.isMandatory || false,
        tooltipLocation: "input",
        canContainSpacesOnly: field.canContainSpacesOnly,
        props: {
          title: field.name,
          description: field.description,
          value: formData[field.key],
        },
        ...getFieldOptions(field),
      };
    })
    .filter((field) => field);
};

const ExportFlow: React.FC<Props> = ({ isOpen, onClose, t }) => {
  const [assetConfig, setAssetConfig] =
    useState<AssetTemplateConfiguration | null>(null);
  const [formData, setFormData] = useState<FormData>({});
  const [formFields, setFormFields] = useState<FormField[]>([]);
  const [fieldsErrors, setFieldsErrors] = useState<{
    [key: string]: string;
  }>({});
  const [variables, setVariables] = useState<Variable[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const classes = useTheme();
  const apiClient = useApiClient();
  const notification = useNotification();
  const { id } = useParams<{ id: string }>();
  const { flowStore, flowSettingsStore } = useStores();

  const resetDrawer = () => {
    setFormData({});
    setFormFields([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      getAssetAndVariables();
    }

    return () => {
      resetDrawer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (assetConfig) {
      const fields = getFieldsConfig(
        t,
        assetConfig.parameters,
        fieldsErrors,
        formData
      );
      setFormFields(fields as FormField[]);
    }
  }, [assetConfig, fieldsErrors, formData, t]);

  const getAssetAndVariables = () => {
    setIsLoading(true);

    const assetConfig = flowStore.getAssetConfiguration("exportDocuments");

    const variables = flowSettingsStore
      .getExportableObjects(id)
      .catch(() => null);

    Promise.all([variables, assetConfig])
      .then(([variablesResponse, assetConfigResponse]) => {
        const config = assetConfigResponse.getAssetConfig;
        setAssetConfig(config);
        const formData = {
          ...config.parameters.reduce(
            (acc, field) => ({
              ...acc,
              [field.key]: field.value,
              ...(field.type === FIELD_TYPE.exportFields && {
                exportFields:
                  (variablesResponse &&
                    FlowHelper.getExportVariables(variablesResponse || [])) ||
                  [],
              }),
            }),
            {}
          ),
        };

        if (variablesResponse) {
          setVariables(variablesResponse);
        }
        setFormData(formData);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "configError"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onFormChange = (value: FormData, key: string) => {
    setFieldsErrors({
      ...fieldsErrors,
      [key]: "",
    });
    setFormData({ ...value });
  };

  const onExport = () => {
    setIsExportInProgress(true);
    const { exportFields, ...otherParams } = formData;

    const newExportFields = FlowHelper.buildExportVariables({
      exportFields: exportFields,
    });

    const shownKeys = renderedFieldsKeys(
      formData,
      assetConfig?.parameters || []
    );

    let errors = {};
    shownKeys.forEach((key) => {
      if (
        !formData[key] &&
        formFields.find((field) => field?.key === key)?.isMandatory
      ) {
        errors = { ...errors, [key]: t("validationFieldsError") };
      }
    });

    if (Object.keys(errors).length > 0) {
      setFieldsErrors(errors);
      setIsExportInProgress(false);
      return;
    }

    const removeHidden = Object.keys(otherParams).reduce((acc, key) => {
      if (shownKeys && shownKeys.includes(key)) {
        return {
          ...acc,
          [key]: otherParams[key],
        };
      }
      return acc;
    }, {});

    const data = {
      ...removeHidden,
      exportFields: newExportFields,
    };

    flowStore
      .exportFlow(apiClient, data)
      .then(() => {
        notification.success(t("exportFlowDocumentsSuccess"));
        onClose();
      })
      .catch(() => {
        //TODO: Improve error handling here by displaying incoming error
        notification.error(t("exportFlowDocumentsError"));
      })
      .finally(() => {
        setIsExportInProgress(false);
      });
  };
  return (
    <>
      <CoreDrawer
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        title={t("customizeExportTitle")}
        onBack={onClose}
      >
        <Box className={classes.box}>
          {isLoading || isExportInProgress ? (
            <AppSuspense />
          ) : (
            <CoreFormRenderer
              fields={formFields}
              translation={t}
              onChange={onFormChange}
              data={formData}
              variables={variables}
            />
          )}
        </Box>

        <Box className={classes.footer}>
          <CoreButton
            isLoading={isExportInProgress}
            disabled={
              isExportInProgress ||
              (formData?.exportFields as Variable[])?.length === 0
            }
            onClick={() => onExport()}
            variant="contained"
          >
            {t("confirm")}
          </CoreButton>
        </Box>
      </CoreDrawer>
    </>
  );
};

export default ExportFlow;
