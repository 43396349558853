import React, { FC, useEffect, useState, useMemo } from "react";
import { TFunction } from "i18next";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Theme, useTheme } from "@mui/material";

import CoreTable from "../../../core/CoreTable";
import CoreAccordion from "../../../core/CoreAccordion";
import { useNotification } from "../../../../context/useNotification";
import { useStores } from "../../../../stores/StoresProvider";
import {
  DocumentHistory,
  LineItemBody,
  LineItemHeader,
  LineItemHistory,
  TableCellStyleDef,
  TableContentDef,
} from "../../../../types/interfaces";
import { CoreStatusTag } from "../../../core/CoreStatusTag";
import { DOCUMENT_STATUS } from "../../../../types/constants";

const useStyles = makeStyles({
  loading: { alignSelf: "center" },
  drawerSubtitle: {
    marginBottom: "20px",
  },
  noDataAvailable: { textAlign: "center" },
  lineTitleStyle: {
    opacity: 0.5,
    marginTop: "12px",
    marginBottom: "6px",
  },
});

interface Props {
  translation: TFunction;
  eventIdentifier: string | null;
}

interface LineItemAccordionProps {
  data: {
    title: string;
    oldValue: LineItemHistory | null;
    newValue: LineItemHistory | null;
  };
  translation: TFunction;
}

const TABLE_HEADERS = [
  {
    accessor: "field",
    translationKey: "history_item_preview_drawer_field_label",
  },
  {
    accessor: "old",
    translationKey: "history_item_preview_drawer_old_value_label",
  },
  {
    accessor: "new",
    translationKey: "history_item_preview_drawer_new_value_label",
  },
];

// Extract all header keys from all data and cells entries
const extractBodyKeys = (
  oldEntries?: LineItemBody[],
  newEntries?: LineItemBody[]
) => {
  let allKeys: string[] = [];

  oldEntries?.forEach((oldEntry) => {
    allKeys = [...(Object.keys(oldEntry.cells || {}) || [])];
  });

  newEntries?.forEach((newEntry) => {
    allKeys = [...allKeys, ...(Object.keys(newEntry.cells || {}) || [])];
  });

  return new Set([...allKeys]);
};

// Calculate maximum possible iterations depending on which data array has more entries
const getMaximumRows = (
  oldEntries?: LineItemBody[],
  newEntries?: LineItemBody[]
) => Math.max(oldEntries?.length || 0, newEntries?.length || 0);

// Extract all header keys from old and new and build a new unique array
const extractHeaderKeys = (
  oldKeys?: LineItemHeader[],
  newKeys?: LineItemHeader[]
) =>
  new Set([
    ...(Object.values(oldKeys || {})?.map((item) => item.key) || []),
    ...(Object.values(newKeys || {})?.map((item) => item.key) || []),
  ]);

// Based on all extracted keys, retrieve header data from each array
const extractHeaderData = (
  allKeys: string[],
  oldKeysData?: LineItemHeader[],
  newKeysData?: LineItemHeader[]
) => {
  const finalFormattedData: TableContentDef[] = [];

  allKeys.forEach((key) => {
    const foundOldValue =
      oldKeysData?.find((oldEntry) => oldEntry.key === key)?.text || "";
    const foundNewValue =
      newKeysData?.find((newEntry) => newEntry.key === key)?.text || "";

    const hasDifferentValue = foundOldValue !== foundNewValue;

    finalFormattedData.push({
      field: key,
      old: foundOldValue,
      new: hasDifferentValue ? (
        <CoreStatusTag
          label={foundNewValue}
          type={DOCUMENT_STATUS.requiresUserInput}
          disableCasing
        />
      ) : (
        foundNewValue
      ),
    });
  });

  return finalFormattedData;
};

// Based on maximum keys number and unique keys, retrieve body data from each array
const extractBodyData = (
  maximumKeys: number,
  allKeysNames: string[],
  oldKeysData?: LineItemBody[],
  newKeysData?: LineItemBody[],
  theme?: Theme
) => {
  const finalArray: TableContentDef[] = [];

  for (let i = 0; i <= maximumKeys - 1; i++) {
    allKeysNames.forEach((key) => {
      const foundOldValue = oldKeysData?.[i]?.cells[key]?.text || "";
      const foundNewValue = newKeysData?.[i]?.cells[key]?.text || "";

      const hasDifferentValue = foundOldValue !== foundNewValue;

      // Build a separate entry for each possible row
      finalArray.push({
        field: key,
        old: foundOldValue,
        new: hasDifferentValue ? (
          //TODO: Apparently MUI tag component doesn't support multiple line wrap, check for possible improvements here
          key === "description" ? (
            <Typography
              style={{
                color: theme?.palette.warning.main,
                fontSize: "14px",
              }}
            >
              {foundNewValue}
            </Typography>
          ) : (
            <CoreStatusTag
              label={foundNewValue}
              type={DOCUMENT_STATUS.requiresUserInput}
              disableCasing
            />
          )
        ) : (
          foundNewValue
        ),
      });
    });
  }

  // Sort keys alphabetically
  return finalArray.sort((a, b) => (a.field > b.field ? 1 : -1));
};

const generateCustomStyleHeaders = (
  data: TableContentDef[],
  theme: Theme
): TableCellStyleDef => {
  if (!data || data?.length === 0) {
    return {};
  }

  let response = {} as TableCellStyleDef;

  data.forEach((element, index) => {
    if (element?.old !== element?.new) {
      response = {
        ...response,
        [index]: { new: { backgroundColor: theme.palette.neutral.dark } },
      };
    }
  });

  return response;
};

const LineItemAccordion: FC<LineItemAccordionProps> = ({
  data,
  translation,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [expandAccordion, setExpandAccordion] = useState(false);

  const translatedHeaders = useMemo(
    () =>
      TABLE_HEADERS.map((header) => ({
        ...header,
        label: translation(header.translationKey),
      })),
    [translation]
  );

  const uniqueHeaderKeys = useMemo(
    () => extractHeaderKeys(data?.oldValue?.headers, data?.newValue?.headers),
    [data]
  );

  const uniqueBodyKeys = useMemo(
    () => extractBodyKeys(data?.oldValue?.data, data?.newValue?.data),
    [data]
  );

  const maximumBodyRows = useMemo(
    () => getMaximumRows(data?.oldValue?.data, data?.newValue?.data),
    [data]
  );

  const formattedHeaderData = useMemo(
    () =>
      extractHeaderData(
        [...uniqueHeaderKeys],
        data?.oldValue?.headers,
        data?.newValue?.headers
      ),
    [data, uniqueHeaderKeys]
  );

  const formattedBodyData = useMemo(
    () =>
      extractBodyData(
        maximumBodyRows,
        [...uniqueBodyKeys],
        data?.oldValue?.data,
        data?.newValue?.data,
        theme
      ),
    [data, maximumBodyRows, uniqueBodyKeys, theme]
  );

  const customCellStyleHeaders = useMemo(
    () => generateCustomStyleHeaders(formattedHeaderData, theme),
    [formattedHeaderData, theme]
  );

  const customCellStyleBody = useMemo(
    () => generateCustomStyleHeaders(formattedBodyData, theme),
    [formattedBodyData, theme]
  );

  return (
    <CoreAccordion
      title={data?.title || "-"}
      expanded={expandAccordion}
      onChange={() => setExpandAccordion(!expandAccordion)}
    >
      <Typography className={classes.lineTitleStyle}>
        {translation("history_table_headers_title")}
      </Typography>
      <CoreTable
        isLoading={false}
        isPaginated={false}
        headers={translatedHeaders}
        data={formattedHeaderData}
        customCellStyle={customCellStyleHeaders}
      />
      <Typography className={classes.lineTitleStyle}>
        {translation("history_table_body_title")}
      </Typography>
      <CoreTable
        isLoading={false}
        isPaginated={false}
        headers={translatedHeaders}
        data={formattedBodyData}
        customCellStyle={customCellStyleBody}
      />
    </CoreAccordion>
  );
};

const formatTableData = (data: DocumentHistory | undefined) => {
  if (
    !data ||
    !data.data ||
    !data.data.fields ||
    Object.keys(data.data.fields)?.length === 0
  ) {
    return [];
  }

  let response = [] as {
    field: string;
    old: string | React.ReactElement;
    new: string | React.ReactElement;
  }[];

  Object.keys(data.data.fields).forEach((fieldName) => {
    const fieldValue = data.data.fields[fieldName];

    const oldValue = fieldValue.old_value?.text || "-";
    const newValue = fieldValue.new_value?.text || "-";
    const isDiff = oldValue !== newValue;

    response = [
      ...response,
      {
        field: fieldName,
        old: oldValue,
        new: isDiff ? (
          <CoreStatusTag
            label={newValue}
            type={DOCUMENT_STATUS.requiresUserInput}
          />
        ) : (
          newValue
        ),
      },
    ];
  });

  return response;
};

const generateCustomCellStyle = (
  data: DocumentHistory | undefined,
  theme: Theme
): TableCellStyleDef => {
  if (
    !data ||
    !data.data ||
    !data.data.fields ||
    Object.keys(data.data.fields)?.length === 0
  ) {
    return {};
  }

  let response = {} as TableCellStyleDef;

  Object.keys(data.data.fields).forEach((fieldName, index) => {
    const fieldValue = data.data.fields[fieldName];

    const oldValue = fieldValue.old_value?.text;
    const newValue = fieldValue.new_value?.text;

    if (oldValue !== newValue) {
      response = {
        ...response,
        [index]: { new: { backgroundColor: theme.palette.neutral.dark } },
      };
    }
  });

  return response;
};

const HistoryEventDetails: FC<Props> = ({ translation, eventIdentifier }) => {
  const classes = useStyles();
  const theme = useTheme();
  const notification = useNotification();
  const apolloClient = useApolloClient();
  const { documentStore } = useStores();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState<DocumentHistory | undefined>(undefined);

  useEffect(() => {
    setIsDataLoading(true);

    documentStore
      .getDocumentHistoryEvent(apolloClient, eventIdentifier as string)
      .then((eventDetails) => {
        setData(eventDetails);
        setIsDataLoading(false);
      })
      .catch((error: Error) => {
        notification.error(
          translation(error?.message || "history_fetch_all_error")
        );
        setIsDataLoading(false);
        setData(undefined);
      });
  }, [eventIdentifier, documentStore, apolloClient, notification, translation]);

  const formattedData = useMemo(() => formatTableData(data), [data]);
  const customCellStyle = useMemo(
    () => generateCustomCellStyle(data, theme),
    [data, theme]
  );
  const translatedHeader = useMemo(
    () =>
      TABLE_HEADERS.map((header) => ({
        ...header,
        label: translation(header.translationKey),
      })),
    [translation]
  );

  return (
    <>
      {isDataLoading ? (
        <CircularProgress className={classes.loading} size={30} />
      ) : !data ? (
        <Typography className={classes.noDataAvailable}>
          {translation("history_item_preview_drawer_no_details_available")}
        </Typography>
      ) : (
        <>
          <Typography className={classes.drawerSubtitle}>
            {translation("history_item_preview_drawer_subtitle")}
          </Typography>

          {formattedData.length > 0 && (
            <CoreTable
              isLoading={false}
              isPaginated={false}
              headers={translatedHeader}
              data={formattedData}
              customCellStyle={customCellStyle}
            />
          )}
          {data?.data?.lineItems &&
            Object.keys(data?.data?.lineItems)?.length > 0 &&
            Object.entries(data.data.lineItems).map(([key, value]) => (
              <LineItemAccordion
                key={key}
                data={{
                  title: key,
                  newValue: value.new_value,
                  oldValue: value.old_value,
                }}
                translation={translation}
              />
            ))}
        </>
      )}
    </>
  );
};

export default HistoryEventDetails;
