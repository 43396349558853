import React, { useEffect, useState } from "react";
import { TFunction } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useStores } from "../../../stores/StoresProvider";
import NamespacePermissions from "./NamespacePermissions";
import { Ability } from "../../../types/interfaces";
import { AppSuspense } from "../../main/AppSuspense";
import CoreButton from "../../core/CoreButton";
import { useNotification } from "../../../context/useNotification";

interface Props {
  t: TFunction;
  onClose: () => void;
  userId: number | null;
}

const useStyles = makeStyles({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
    minHeight: "300px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto",
    width: "100%",
    padding: "35px",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "12px",
    padding: "0 16px 0 16px",
  },
});

const EditPermissions: React.FC<Props> = ({ t, onClose, userId }) => {
  const classes = useStyles();
  const [identity, setIdentity] = useState<
    Ability[] | Record<string, Ability[]>
  >({});
  const notification = useNotification();
  const [permissions, setPermissions] = useState<Ability[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOperationInProgress, setOperationInProgress] = useState(false);
  const { identityStore } = useStores();
  const client = useApolloClient();
  useEffect(() => {
    setLoading(true);
    identityStore
      .getUserCurrentNamespaceIdentity(client, userId?.toString() ?? "")
      .then((res) => {
        setIdentity(res);
        setPermissions((res as Record<string, Ability[]>).permissions);
      })
      .catch((error: Error) =>
        notification.error(t(error?.message ?? "permissions_changed_error"))
      )
      .finally(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      setPermissions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = () => {
    setOperationInProgress(true);

    identityStore
      .updateTargetIdentity(client, userId?.toString() ?? "", {
        ...identity,
        permissions,
      })
      .then(() => notification.success(t("permissions_changed_success")))
      .catch((error: Error) =>
        notification.error(t(error?.message ?? "permissions_changed_error"))
      )
      .finally(() => {
        setOperationInProgress(false);
        onClose();
      });
  };

  return (
    <Box className={classes.contentContainer}>
      {loading ? (
        <AppSuspense />
      ) : (
        <>
          <Box className={classes.optionsContainer}>
            <NamespacePermissions
              t={t}
              permissions={permissions}
              setPermissions={setPermissions}
              checkboxDisabled={isOperationInProgress}
            />
          </Box>
          <Box className={classes.buttonsContainer}>
            <CoreButton
              onClick={handleUpdate}
              isLoading={isOperationInProgress}
              disabled={isOperationInProgress}
              variant="contained"
            >
              {t("changePermButton")}
            </CoreButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EditPermissions;
