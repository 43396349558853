import { gql } from "@apollo/client";

export interface MUTATION_UPDATE_USER_RESPONSE {
  updateUser: boolean;
}

export interface MUTATION_UPDATE_NAMESPACE_IS_ACTIVE_RESPONSE {
  updateNamespaceIsActive: boolean;
}
export interface MUTATION_UPDATE_PASSWORD_RESPONSE {
  updatePassword: boolean;
}

export interface MUTATION_DELETE_USER_RESPONSE {
  deleteUser: boolean;
}

export interface MUTATION_DELETE_CONN_RESPONSE {
  deleteConnection: boolean;
}

export interface MUTATION_RETRY_DOCUMENT_UPLOAD {
  retryDocument: boolean;
}

export interface MUTATION_CREATE_CONN_RESPONSE {
  createConnection: Record<string, string>;
}

export interface MUTATION_UPDATE_CONN_RESPONSE {
  updateConnection: boolean;
}

export interface MUTATION_CREATE_USER_RESPONSE {
  createUser: { created: boolean; fromLink: boolean };
}
export interface MUTATION_LOGIN_WITH_GOOGLE_RESPONSE {
  loginWithGoogle: Record<string, string>;
}
export interface MUTATION_LOGOUT_USER_RESPONSE {
  logoutUser: boolean;
}

export interface MUTATION_LOGIN_USER_RESPONSE {
  loginUser: Record<string, string>;
}

export interface MUTATION_SELECT_NAMESPACE_RESPONSE {
  selectNamespace: Record<string, string>;
}

export interface MUTATION_REMOVE_CTX_RESPONSE {
  removeCTX: string;
}

export interface MUTATION_UPDATE_DEFAULT_NAMESPACE_RESPONSE {
  updateDefaultNamespace: boolean;
}

export interface MUTATION_ACTIVATING_ACCOUNT_RESPONSE {
  activatingAccount: boolean;
}
export interface MUTATION_RESET_PASSWORD_RESPONSE {
  resetPassword: boolean;
}

export interface MUTATION_REPORT_EVENT_RESPONSE {
  reportEvent: boolean;
}

export interface MUTATION_UPDATE_CONTEXT_SETTINGS {
  updateContextSettings: boolean;
}

export const CREATE_USER = gql`
  mutation createUser(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $token: String
  ) {
    createUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      token: $token
    )
  }
`;

export const LOGIN_USER = gql`
  mutation loginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password)
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userData: UserInput, $id: Int) {
    updateUser(userData: $userData, id: $id)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String, $id: Int) {
    updatePassword(password: $password, id: $id)
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: Int) {
    deleteUser(id: $id)
  }
`;

export const DELETE_CONN = gql`
  mutation deleteConnection($id: String) {
    deleteConnection(id: $id)
  }
`;

export const CREATE_CONN = gql`
  mutation createConnection($config: JSON) {
    createConnection(config: $config)
  }
`;

export const TEST_CONN = gql`
  mutation testConnection($config: JSON) {
    testConnection(config: $config)
  }
`;

export const UPDATE_CONN = gql`
  mutation updateConnection($id: String, $config: JSON) {
    updateConnection(id: $id, config: $config)
  }
`;

export const LOGIN_WITH_GOOGLE = gql`
  mutation loginWithGoogle($code: String, $token: String) {
    loginWithGoogle(code: $code, token: $token)
  }
`;

export const LOGOUT_USER = gql`
  mutation logoutUser {
    logoutUser
  }
`;

export const SELECT_NAMESPACE = gql`
  mutation selectNamespace($namespaceId: Int) {
    selectNamespace(namespaceId: $namespaceId)
  }
`;

export const REMOVE_CTX = gql`
  mutation removeCTX {
    removeCTX
  }
`;

export const UPDATE_DEFAULT_NAMESPACE = gql`
  mutation updateDefaultNamespace($namespaceId: Int) {
    updateDefaultNamespace(namespaceId: $namespaceId)
  }
`;

export const UPDATE_NAMESPACE_IS_ACTIVE = gql`
  mutation updateNamespaceIsActive($userId: Int, $isActive: Boolean) {
    updateNamespaceIsActive(userId: $userId, isActive: $isActive)
  }
`;

export const ACTIVATING_ACCOUNT = gql`
  mutation activatingAccount($token: String) {
    activatingAccount(token: $token)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $resetToken: String!) {
    resetPassword(password: $password, resetToken: $resetToken)
  }
`;

export const REPORT_EVENT = gql`
  mutation reportEvent($message: String!) {
    reportEvent(message: $message)
  }
`;

export const UPDATE_CONTEXT_SETTINGS = gql`
  mutation updateContextSettings($settingsKey: String!, $config: JSON) {
    updateContextSettings(settingsKey: $settingsKey, config: $config)
  }
`;
