import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { FormControl, useTheme } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { TFunction } from "i18next";

import { useStores } from "../../../../../stores/StoresProvider";
import {
  Connection,
  ConnectionField,
  FormData,
  StorageOptions,
} from "../../../../../types/interfaces";
import { Loader } from "../../../../core/Loader";
import { ConnectionConfigRenderer } from "../../../connections/ConnectionConfigRenderer";
import { useNotification } from "../../../../../context/useNotification";

const useStyles = makeStyles({
  loadingContainer: {
    textAlign: "center",
    marginTop: "20px",
  },
});

type RepositoryFieldsType = {
  repoConnection: Connection;
  t: TFunction;
  flowStorage: StorageOptions | undefined;
  setFlowStorage: (value: StorageOptions | undefined) => void;
  isEditable: boolean;
};

const RepositoryFields: React.FC<RepositoryFieldsType> = observer(
  ({ repoConnection, t, flowStorage, setFlowStorage, isEditable }) => {
    const { storageStore } = useStores();
    const theme = useTheme();
    const classes = useStyles();
    const mounted = useRef(false);
    const notification = useNotification();

    const [isLoading, setIsLoading] = useState(false);

    const { identifier, type } = repoConnection;
    const { loadedFieldsId, fields } = storageStore.repositoryFields;

    const setExtraFields = (value: FormData) => {
      if (flowStorage?.repoParameters) {
        setFlowStorage({
          ...flowStorage,
          repoParameters: {
            ...flowStorage.repoParameters,
            ...value,
          },
        });
      }
    };

    const data = flowStorage?.repoParameters ?? {};
    useEffect(() => {
      mounted.current = true;

      return () => {
        mounted.current = false;
      };
    }, []);

    useEffect(() => {
      const shouldFetch = identifier && loadedFieldsId !== identifier;
      if (shouldFetch) {
        setIsLoading(true);
        storageStore
          .getConnectionFields(type)
          .then((connectionFields) => {
            // Metadata to be used for Review tab
            const metadata: FormData = {};

            // Initial values for adding fields
            const initialValues: FormData = {};
            const updatedFields = connectionFields.map((field) => {
              metadata[field.key] = {
                name: field.name,
                isMandatory: field.isMandatory,
              };
              initialValues[field.key] = "";

              if (data[field.key]) {
                return {
                  ...field,
                  value: data[field.key],
                };
              }
              return field;
            });
            storageStore.setRepositoryFields({
              loadedFieldsId: identifier,
              fields: updatedFields as ConnectionField[],
              metadata,
            });
          })
          .catch((error: Error) =>
            notification.error(t(error?.message || "connection_fields_error"))
          )
          .finally(() => mounted.current && setIsLoading(false));
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identifier, loadedFieldsId]);

    if (isLoading) {
      return (
        <div className={classes.loadingContainer}>
          <Loader color={theme.palette.neutral.light} loading size={14} />
        </div>
      );
    }

    const handleChange = (newValue: FormData) => {
      setExtraFields(newValue);
    };

    return (
      <FormControl variant="standard">
        <ConnectionConfigRenderer
          config={fields}
          translation={t}
          data={data}
          onChange={handleChange}
          disableForm={!isEditable}
        />
      </FormControl>
    );
  }
);

export default RepositoryFields;
