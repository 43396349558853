import { PaletteMode } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";

//themes
import { THEME_MODES } from "../types/constants";
import { DarkTheme } from "./themes/DarkTheme";
import { LightTheme } from "./themes/LightTheme";
import { MonoTheme } from "./themes/MonoTheme";
import { initMuiIconButton } from "./components/IconButton";
import { initMuiDrawer } from "./components/Drawer";
import { initMuiAppBar } from "./components/AppBar";
import { initMuiListItemButton } from "./components/ListItemButton";
import { initMuiChip } from "./components/Chip";
import { initMuiTableCell } from "./components/TableCell";
import { initMuiButton } from "./components/Button";
import { initMuiCheckbox } from "./components/CheckBox";
import { initMuiInput } from "./components/Input";
import { initMuiInputLabel } from "./components/InputLabel";
import { initMuiSwitch } from "./components/Switch";
import { initMuiMenuItem } from "./components/MenuItem";
import { initMuiDivider } from "./components/Divider";
import { initMuiCssBaseline } from "./components/CssBaseline";
import { initMuiTab } from "./components/Tab";
import { initMuiCircularProgress } from "./components/CircularProgress";
import { initMuiRadio } from "./components/Radio";
import { initMuiLinearProgress } from "./components/LinearProgress";

declare module "@mui/material/styles" {
  interface Palette {
    type: string; // custom theme type (ex: mono, light, dark etc)
    transparency: Palette["primary"];
    neutral: Palette["primary"];
    canvas: Palette["primary"];
    buttons: Palette["primary"];
    highlight: Palette["primary"];
    switch: Palette["primary"];
    surface: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    transparency?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
    canvas?: PaletteOptions["primary"];
    buttons?: PaletteOptions["primary"];
    highlight?: PaletteOptions["primary"];
    switch: PaletteOptions["primary"];
    surface: PaletteOptions["primary"];
  }

  interface PaletteColor {
    50: PaletteColor;
    100: PaletteColor;
  }
}

const THEMES = {
  [THEME_MODES.dark]: DarkTheme,
  [THEME_MODES.light]: LightTheme,
  [THEME_MODES.mono]: MonoTheme,
};
/**
 *
 * @param mode - theme mode type
 * @returns Theme
 */
export const getCurrentTheme = (mode: PaletteMode): Theme => {
  const currentTheme = THEMES[mode] || DarkTheme;

  return createTheme({
    ...currentTheme,
    // FIXME: Keep for later use
    components: {
      MuiButton: initMuiButton(currentTheme),
      // MuiMenu: initMuiMenu(currentTheme),
      MuiOutlinedInput: initMuiInput(currentTheme),
      // MuiSelect: initMuiSelect(currentTheme),
      MuiMenuItem: initMuiMenuItem(currentTheme),
      MuiCheckbox: initMuiCheckbox(currentTheme),
      MuiAppBar: initMuiAppBar(currentTheme),
      MuiDrawer: initMuiDrawer(currentTheme),
      MuiListItemButton: initMuiListItemButton(currentTheme),
      MuiLinearProgress: initMuiLinearProgress(currentTheme),
      // MuiToolbar: initMuiToolbar(currentTheme),
      // MuiTooltip: initMuiTooltip(currentTheme),
      MuiChip: initMuiChip(currentTheme),
      // MuiTypography: initMuiTypography(currentTheme),
      MuiTableCell: initMuiTableCell(currentTheme),
      // MuiTableRow: initMuiTableRow(currentTheme),
      // MuiTableHead: initMuiTableHead(currentTheme),
      MuiTab: initMuiTab(currentTheme),
      // MuiTabs: initMuiTabs(currentTheme),
      MuiIconButton: initMuiIconButton(currentTheme),
      //MuiIcon: initMuiIcon(currentTheme),
      // MuiPagination: initMuiPagination(),
      // MuiPaginationItem: initMuiPaginationItem(currentTheme),
      MuiSwitch: initMuiSwitch(currentTheme),
      // MuiPopover: initMuiPopover(currentTheme),
      // MuiAccordion: initMuiAccordion(currentTheme),
      MuiInputLabel: initMuiInputLabel(currentTheme),
      // MuiDialog: initMuiDialog(currentTheme),
      MuiDivider: initMuiDivider(currentTheme),
      // MuiListItemText: initMuiListItemText(currentTheme),
      // MuiPaper: initMuiPaper(currentTheme),
      // MuiFormHelperText: initMuiFormHelperText(currentTheme),
      MuiCircularProgress: initMuiCircularProgress(currentTheme),
      // MuiFormControlLabel: initMuiFormControlLabel(),
      // MuiTableSortLabel: initMuiTableSortLabel(currentTheme),
      MuiCssBaseline: initMuiCssBaseline(currentTheme),
      MuiRadio: initMuiRadio(currentTheme),
    },
  });
};
