import React from "react";

import { useTheme, Paper, alpha } from "@mui/material";

import { Loader } from "../core/Loader";

interface Props {
  size?: number;
}

export const AppSuspense: React.FC<Props> = ({ size }) => {
  const theme = useTheme();
  const color = alpha(theme.palette.highlight.main, 0.5);

  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        boxShadow: "none",
        backgroundImage: "unset",
      }}
    >
      <Loader color={color} loading={true} size={size || 20} />
    </Paper>
  );
};
