import React, { FC } from "react";
import { TFunction } from "i18next";

import { FormField, FormData } from "../../types/interfaces";
import CoreTextarea from "../core/CoreTextarea";
import { FormFieldProps } from "../../types/types";
import SuggestionsTextArea from "./suggestions/SuggestionsTextArea";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  onChange: (value: FormData) => void;
  otherProps?: FormFieldProps;
  errorText?: string;
  description?: string;
  tooltipLocation?: "title" | "input";
  extraActions?: React.ReactNode;
  disableDiagramField?: boolean;
}

export const TextareaRenderer: FC<Props> = ({
  field,
  translation,
  onChange,
  errorText,
  value,
  otherProps = {},
  description,
  tooltipLocation,
  extraActions,
  disableDiagramField,
}) => {
  const { disabled } = otherProps;

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = { [field.key]: event.target.value };

    const pointer = event.target.selectionStart;
    const element = event.target;
    window.requestAnimationFrame(() => {
      element.selectionStart = pointer;
      element.selectionEnd = pointer;
    });

    onChange(newValue);
  };

  return field.isDynamicContentAllowed || field.showToolbox ? (
    <SuggestionsTextArea
      t={translation}
      onChange={onChange}
      disabled={disabled}
      value={(value || "") as unknown as string}
      placeholder={translation(field.translationKey)}
      field={field}
      isMandatory={field?.isMandatory}
      errorText={errorText}
      description={translation(description || "")}
      tooltipLocation={tooltipLocation}
      disableDiagramField={disableDiagramField}
    />
  ) : (
    <CoreTextarea
      placeholder={translation(field.translationKey)}
      isMandatory={field.isMandatory}
      onChange={handleTextareaChange}
      errorText={errorText}
      value={(value || "") as unknown as string}
      title={field.name}
      description={translation(description || "")}
      tooltipLocation={tooltipLocation}
      extraActions={extraActions}
      disabled={disabled}
    />
  );
};
