import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";

import { useNotification } from "../../../../context/useNotification";
import { DocumentStore } from "../../../../stores/DocumentStore";
import CoreTooltip from "../../../core/CoreTooltip";
import { useStores } from "../../../../stores/StoresProvider";

interface Props {
  id: string;
  documentStore: DocumentStore;
  page: number;
  applyFilters: (page: number) => void;
}

export const DocumentRetryAction: React.FC<Props> = observer(
  ({ id, documentStore, applyFilters, page }) => {
    const client = useApolloClient();
    const notification = useNotification();
    const { t } = useTranslation("flow");
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const { userStore } = useStores();
    const handleRetry = () => {
      setLoading(true);
      notification.info(t("retryInProgress"));

      documentStore
        .retryDocument(client, id)
        .then(() => notification.success(t("retrySuccess")))
        .catch((error: Error) => {
          notification.error(t(error?.message || "retryError"));
        })
        .finally(() => {
          setLoading(false);
          applyFilters(page);
        });
    };

    const canRetryDoc = userStore.currentUserPermissions?.can(
      "updateDocuments",
      "flows"
    );

    if (!canRetryDoc) return null;

    if (loading)
      return (
        <CircularProgress
          size={"20px"}
          sx={{
            color: theme.palette.highlight.main,
            marginLeft: "8px",
          }}
        />
      );

    return (
      <CoreTooltip title={`${t("retry_tooltip")}`}>
        <IconButton onClick={() => handleRetry()}>
          <ReplayIcon fontSize="small" />
        </IconButton>
      </CoreTooltip>
    );
  }
);
