import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import { Box, alpha, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

import {
  HeaderActions,
  TableContentDef,
  TableHeaderDef,
} from "../../../types/interfaces";
import CoreInput from "../../core/CoreInput";
import CoreConfirmModal from "../../core/CoreConfirmModal";
import { TableActions } from "./TableActions";
import CoreTable from "../../core/CoreTable";
import { HOVER_COLUMNS } from "../../../types/constants";
import { useNotification } from "../../../context/useNotification";
import { useStores } from "../../../stores/StoresProvider";
import CoreTooltip from "../../core/CoreTooltip";
import CorePageContainer from "../../core/CorePageContainer";
import CreateConnection from "./CreateConnection";
import CoreButton from "../../core/CoreButton";

const colWidth: TableContentDef = {
  select: "5%",
  name: "40%",
};

const tableHeader: TableHeaderDef[] = [
  {
    accessor: HOVER_COLUMNS.name,
    translationKey: "tableHeader_Name",
    options: {
      isSortable: false,
    },
  },
  {
    accessor: "description",
    translationKey: "tableHeader_Description",
    options: {
      isSortable: false,
    },
  },
  {
    accessor: "type",
    translationKey: "tableHeader_Type",
  },
];

const Connections: FC = observer(() => {
  const { t, ready } = useTranslation("connections");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [connectionId, setConnectionId] = useState("");
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");

  const apolloClient = useApolloClient();
  const notification = useNotification();
  const { userStore } = useStores();
  const theme = useTheme();

  const useStyle = makeStyles({
    inputContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "25px",
    },
    icon: { opacity: 0.4, marginRight: "10px" },
    descriptionBox: { width: "200px" },
    inputBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
    },
    input: {
      maxWidth: "300px",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      padding: "15px 10px 10px 10px",
      "@media screen and (max-width:768px)": {
        display: "flex",
        flexDirection: "column",
      },
    },
    button: {
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
        color: `${theme.palette.highlight.main} !important`,
      },
    },
  });

  const classes = useStyle();

  const refetchData = () => {
    setIsLoading(true);
    userStore.currentUserPermissions?.can("read", "connections") &&
      userStore
        .loadAllConnections(apolloClient)
        .catch(() => {
          notification.error(t("fetchConnectionsError"));
        })
        .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    void refetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (id: string) => {
    toggleModal();
    setConnectionId(id);
  };

  const handleOpenConn = (id: string) => {
    toggleConn();
    setConnectionId(id);
  };

  const toggleConn = () => {
    if (userStore.isConnectionDrawerOpened) {
      setConnectionId("");
    }
    userStore.toggleConnectionDrawer(!userStore.isConnectionDrawerOpened);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setConnectionId("");
    }
    setIsModalOpen(!isModalOpen);
  };

  const deleteConnection = () => {
    setIsOperationInProgress(true);

    userStore
      .deleteConnection(apolloClient, connectionId)
      .catch((error: Error) => {
        notification.error(t(error?.message || "deleteConnectionError"));
      })
      .finally(() => {
        setIsOperationInProgress(false);
        toggleModal();
        void refetchData();
      });
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const clearButton = () => {
    setInput("");
    void refetchData();
  };

  const canUpdateConnections =
    userStore.currentUserPermissions?.can("update", "connections") ?? false;
  const canDeleteConnections =
    userStore.currentUserPermissions?.can("delete", "connections") ?? false;

  const formattedData: TableContentDef[] =
    userStore.connections && userStore.connections.length > 0
      ? userStore.connections
          .filter(
            (connection) =>
              connection.name.includes(input) ||
              connection.description.includes(input) ||
              connection.type.includes(input)
          )
          .map((item) => ({
            name: item.name,
            type: t(item.type),
            [HOVER_COLUMNS.actions]: (
              <TableActions
                id={item.identifier || ""}
                openModal={handleOpen}
                t={t}
                openConn={handleOpenConn}
                canUpdateConnections={canUpdateConnections}
                canDeleteConnections={canDeleteConnections}
              />
            ),
            description: (
              <Box className={classes.descriptionBox}>{item.description}</Box>
            ),
          }))
      : [];

  const formattedHeaders: TableHeaderDef[] = [
    ...tableHeader.map((item) => ({
      ...item,
      label: item.translationKey ? t(item.translationKey) : item.label,
    })),
  ];

  const clearFilterButton = input && (
    <CoreTooltip title={t("remove_filter")}>
      <IconButton onClick={clearButton}>
        <RemoveCircleOutlineIcon />
      </IconButton>
    </CoreTooltip>
  );

  const headerActions: HeaderActions[] = [
    {
      label: t("button_createConnection"),
      tooltip: t("button_createConnection"),
      onClick: () => userStore.toggleConnectionDrawer(true),
      startIcon: <AddIcon />,
      ability: [
        {
          action: "create",
          subject: "connections",
        },
      ],
      disabled: isLoading,
    },
  ];

  return (
    <CorePageContainer
      label={t("title")}
      extraHeaderActions={headerActions}
      isPageLoading={!ready}
      isNotFlowPage={true}
      fixedExtraHeaderActions={
        userStore.currentUserPermissions?.can("read", "connections") && (
          <CoreTooltip title={t("refresh_table")}>
            <Box>
              <CoreButton
                variant="neutral"
                size="large"
                onClick={() => refetchData()}
                startIcon={<CachedOutlinedIcon />}
                className={classes.button}
                disabled={isLoading}
              >
                {t("refresh_table")}
              </CoreButton>
            </Box>
          </CoreTooltip>
        )
      }
    >
      <Box className={classes.mainContainer}>
        <Box className={classes.inputContainer}>
          <Box className={classes.inputBox}>
            <CoreInput
              size="small"
              startAdornment={<SearchIcon className={classes.icon} />}
              placeholder={t("searchFilters")}
              endAdornment={clearFilterButton}
              value={input}
              onChange={handleInput}
              boxClassname={classes.input}
            />
          </Box>
        </Box>

        <CoreTable
          hoverActions={canUpdateConnections || canDeleteConnections}
          isPaginated={false}
          headers={formattedHeaders}
          isLoading={userStore.loadingConnections}
          data={formattedData}
          columnWidth={colWidth}
          noVisibleCells={2}
        />
        <CoreConfirmModal
          open={isModalOpen}
          onClose={toggleModal}
          onCancel={toggleModal}
          onConfirm={deleteConnection}
          title={t("modal_deleteTitle")}
          subtitle={t("modal_deleteSubtitle")}
          cancelButtonLabel={t("cancelButtonLabel")}
          confirmButtonLabel={t("confirmButtonLabel")}
          isDisable={isOperationInProgress}
        />

        <CreateConnection
          isOpen={userStore.isConnectionDrawerOpened}
          onClose={toggleConn}
          id={connectionId}
        />
      </Box>
    </CorePageContainer>
  );
});

export default Connections;
