import React, { FC } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Typography, IconButton, Box } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import Logo from "../../../../icons/Logo";

interface Props {
  t: TFunction;
  title: string;
  hasBackButton: boolean;
  onBack?: () => void;
  onClose: () => void;
  isClariChatTab?: boolean;
}

const ChatBoxTitleContainer: FC<Props> = observer(
  ({ t, title, hasBackButton, onBack, onClose, isClariChatTab = false }) => {
    const useStyles = makeStyles({
      arrow: {
        fontSize: 15,
      },
      container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      },
      logoStyle: {
        margin: "auto 0",
        width: "120px",
        paddingLeft: "10px",
      },
    });

    const classes = useStyles();

    return (
      <Box className={classes.container}>
        {isClariChatTab ? (
          <>
            <IconButton
              onClick={onBack}
              style={{
                visibility: hasBackButton && onBack ? "visible" : "hidden",
              }}
            >
              <ArrowBackIosNew className={classes.arrow} />
            </IconButton>

            <Typography variant="h6">{t(title)}</Typography>

            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <Logo fullLogoClassName={classes.logoStyle} />
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </>
        )}
      </Box>
    );
  }
);

export default ChatBoxTitleContainer;
