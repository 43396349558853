import { Components, ThemeOptions } from "@mui/material";
import { ThemePalette } from "../../types/interfaces";

export const initMuiAppBar = (theme: ThemeOptions): Components["MuiAppBar"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        backgroundColor: palette.secondary.main,
        borderRight: "none",
      },
    },
  };
};
