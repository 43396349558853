import React, { useMemo, useState } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

import { useTheme, Box } from "@mui/material";
import CoreButton from "../../../core/CoreButton";
import CoreConfirmModal from "../../../core/CoreConfirmModal";
import { useStores } from "../../../../stores/StoresProvider";
import { FLOW_SETTINGS_TABS } from "../../../../types/constants";
import { useNotification } from "../../../../context/useNotification";
import { FlowInterface } from "../../../../types/interfaces";

interface Props {
  t: TFunction;
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
  onCancel: () => void;
  disableSaveButton: boolean;
  editButtonDisabled: boolean;
  form: FlowInterface;
}

const FlowTabHeaderActions: React.FC<Props> = observer(
  ({
    t,
    isEditable,
    setIsEditable,
    onCancel,
    disableSaveButton,
    editButtonDisabled,
    form,
  }) => {
    const { flowSettingsStore } = useStores();
    const notification = useNotification();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const theme = useTheme();

    const classes = makeStyles({
      button: {
        "&:hover": {
          color: `${theme.palette.highlight.main} !important`,
        },
      },
      buttonsBox: {
        display: "flex",
        paddingRight: "10px",
        gap: "10px",
      },
      editButtonBox: {
        paddingRight: "10px",
      },
    })();

    const modalTitle = useMemo(() => {
      if (flowSettingsStore.selectedTab === FLOW_SETTINGS_TABS.overview) {
        return t("changeFlowNameModalTitle");
      }
      return t("changeStorageSettingsModalTitle");
    }, [flowSettingsStore.selectedTab, t]);

    const handleConfirm = () => {
      flowSettingsStore
        .updateFlow(form)
        .then(() => {
          notification.success(t("updateFlowSuccess"));
          setIsModalOpened(false);
          setIsEditable(false);
          flowSettingsStore.triggerRefetchFlowSettings(true);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "updateFlowError"));
        });
    };

    const handleModalFalse = () => {
      setIsModalOpened(false);
    };

    return (
      <>
        {isEditable ? (
          <Box className={classes.buttonsBox}>
            <CoreButton
              onClick={onCancel}
              variant="neutral"
              size="large"
              className={classes.button}
            >
              {t("cancel")}
            </CoreButton>
            <CoreButton
              onClick={() => setIsModalOpened(true)}
              variant="neutral"
              size="large"
              startIcon={<DoneIcon />}
              className={classes.button}
              disabled={disableSaveButton}
            >
              {t("save")}
            </CoreButton>
          </Box>
        ) : (
          <Box className={classes.editButtonBox}>
            <CoreButton
              onClick={() => setIsEditable(true)}
              variant="neutral"
              size="large"
              startIcon={<EditIcon />}
              className={classes.button}
              disabled={editButtonDisabled}
            >
              {t("edit")}
            </CoreButton>
          </Box>
        )}

        <CoreConfirmModal
          open={isModalOpened}
          onClose={handleModalFalse}
          title={modalTitle}
          cancelButtonLabel={t("cancel")}
          confirmButtonLabel={t("confirm")}
          onCancel={handleModalFalse}
          onConfirm={handleConfirm}
          isDisable={flowSettingsStore.loadingUpdateFlow}
        />
      </>
    );
  }
);

export default FlowTabHeaderActions;
