import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { Divider, useTheme } from "@mui/material";

import { useStores } from "../../../../stores/StoresProvider";
import { FormData, FormField } from "../../../../types/interfaces";
import { emailRegex, passwordRegex } from "../../../../types/regex";
import { UserRegistration } from "../constants";
import { FIELD_TYPE, THEME_MODES } from "../../../../types/constants";
import { AppSuspense } from "../../../main/AppSuspense";
import CoreButton from "../../../core/CoreButton";
import { CoreFormRenderer } from "../../../core/CoreFormRenderer";
import { appRoutes, authRoutes } from "../../../../configs/routes";
import Languages from "../../../main/sidebar/account/Languages";
import GoogleIcon from "@mui/icons-material/Google";
import { useGoogleLogin } from "@react-oauth/google";
import i18n from "../../../../i18n";
import { useHistory } from "react-router-dom";

interface Errors {
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
}

const Register: FC = observer(() => {
  const client = useApolloClient();
  const history = useHistory();
  const [isRecaptchaVerified, setIsRecaptchaVerified] =
    useState<boolean>(false);

  const {
    userStore,
    mainStore: { currentTheme, settings },
    landingStore,
    landingStore: {
      setUserRegistration,
      userRegistration: {
        firstName,
        lastName,
        email,
        password,
        confirmPassword,
        subscriptionPlanId,
        isGoogleLogin,
      },
    },
  } = useStores();

  const [fields, setFields] = useState<FormData>({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    subscriptionPlanId,
  });
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogleRegister, setIsLoadingGoogleRegister] = useState(false);
  const [registerWithGoogleError, setRegisterWithGoogleError] = useState("");
  const { t, ready } = useTranslation("landingPages");
  const theme = useTheme();
  const useStyles = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    pageContainer: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flex: 1,
    },
    registrationContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: "300px",
      overflow: "auto",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      margin: "auto",
    },
    divider: { margin: "20px 0px" },
    groupButtons: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    button: {
      textTransform: "none",
      height: "40px",
      margin: "10px 0px",
      display: "flex",
      alignItems: "center",
    },
    googleIcon: { fontSize: "25px", marginRight: "5px" },
    errorText: {
      color: theme.palette.error.main,
    },
    error: {
      justifyContent: "center",
      display: "flex",
    },
  });

  const classes = useStyles();

  const handleInput = (value: FormData, changedKey: string) => {
    setFields({ ...fields, ...value });
    setErrors({
      ...errors,
      [changedKey as keyof Errors]: "",
    });
  };

  const validateEmail = async (email: string) => {
    if (!email || email.trim() === "") {
      return t("email_error");
    }
    if (!emailRegex.test(email)) {
      return t("email_invalidForm");
    }

    let err = "";
    const isEmailUsed = await userStore
      .checkIfEmailIsUsed(client, email)
      .then((res) => res)
      .catch(() => {
        err = t("email_checkError");
      });

    if (err) {
      return err;
    }

    if (isEmailUsed) {
      return t("email_alreadyUsed");
    }
    return "";
  };

  const handleGoogleRegister = useGoogleLogin({
    onSuccess: (response) => {
      setIsLoadingGoogleRegister(true);

      // eslint-disable-next-line @typescript-eslint/naming-convention
      userStore
        .loginWithGoogle(client, response.code)
        .then(({ newUser }) => {
          newUser ? landingStore.goNext() : history.push(appRoutes.Home());
        })
        .catch((error: Error) => {
          // If error key is not defined in translation, then provide default one
          const errorMessageKey = i18n.exists(error?.message)
            ? error?.message
            : "internalServerError";
          setRegisterWithGoogleError(t(errorMessageKey));
        })
        .finally(() => {
          setIsLoadingGoogleRegister(false);
        });
    },
    flow: "auth-code",
  });

  const handleNextStep = async (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLFormElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setIsLoading(true);
    const emailError = await validateEmail(fields.email as string);

    const passwordError =
      !fields || !fields?.password || (fields?.password as string).trim() === ""
        ? t("password_error")
        : !passwordRegex.test(fields?.password as string)
        ? t("passwordValidation_error")
        : "";

    const confirmPasswordError =
      !fields ||
      !fields?.confirmPassword ||
      (fields?.confirmPassword as string).trim() === ""
        ? t("password_error")
        : !passwordRegex.test(fields?.confirmPassword as string)
        ? t("passwordValidation_error")
        : fields?.confirmPassword !== fields?.password
        ? t("confirmPassword_error")
        : "";

    const firstNameError =
      !fields ||
      !fields?.firstName ||
      (fields?.firstName as string).trim() === ""
        ? t("firstName_error")
        : "";

    const lastNameError =
      !fields || !fields?.lastName || (fields?.lastName as string).trim() === ""
        ? t("lastName_error")
        : "";

    setErrors({
      email: emailError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
      firstName: firstNameError,
      lastName: lastNameError,
    });
    setIsLoading(false);
    if (
      !emailError &&
      !passwordError &&
      !confirmPasswordError &&
      !firstNameError &&
      !lastNameError &&
      fields
    ) {
      const registerFields = {
        email: fields.email,
        firstName: fields.firstName,
        lastName: fields.lastName,
        password: fields.password,
        confirmPassword: fields.confirmPassword,
        subscriptionPlanId: fields.subscriptionPlanId,
      };
      setUserRegistration(registerFields as UserRegistration);
      landingStore.goNext();
    }
  };

  const registerFields: FormField[] = [
    {
      translationKey: "ownerFirstNamePlaceholder",
      key: "firstName",
      type: FIELD_TYPE.input,
      props: {
        title: t("ownerFirstName"),
      },
      errorText: errors?.firstName as string,
    },
    {
      translationKey: "ownerLastNamePlaceholder",
      key: "lastName",
      type: FIELD_TYPE.input,
      props: {
        title: t("ownerLastName"),
      },
      errorText: errors?.lastName as string,
    },
    {
      translationKey: "ownerEmailPlaceholder",
      key: "email",
      type: FIELD_TYPE.input,
      props: {
        title: t("ownerEmail"),
      },
      errorText: errors?.email as string,
    },
    {
      translationKey: "ownerPasswordPlaceholder",
      key: "password",
      type: FIELD_TYPE.password,
      tooltipLocation: "title",
      props: {
        title: t("ownerPassword"),
        description: t("passwordRequirements"),
      },
      showStrengthBar: true,
      showRules: false,
      errorText: errors?.password,
    },
    {
      translationKey: "ownerConfirmPasswordPlaceholder",
      key: "confirmPassword",
      type: FIELD_TYPE.password,
      props: {
        title: t("ownerConfirmPassword"),
      },
      errorText: errors?.confirmPassword,
    },
  ];

  const verifyTokenRecaptcha = (token: string | null) => {
    if (!token) return;
    userStore
      .verifyTokenRecaptcha(client, token)
      .then(() => {
        setIsRecaptchaVerified(true);
      })
      .catch(() => {
        setIsRecaptchaVerified(false);
      });
  };

  useEffect(() => {
    if (userStore.user || isGoogleLogin) {
      if (userStore.user) {
        landingStore.setUserRegistration({
          email: userStore.user.email,
          firstName: userStore.user.first_name,
          lastName: userStore.user.last_name,
        });
      }
      landingStore.goNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingStore, userStore.loadingUser, userStore.user]);

  if (!ready || userStore.loadingUser) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>

      <Container maxWidth="xs" className={classes.pageContainer}>
        <Box className={classes.registrationContainer}>
          <Typography variant="h4" fontWeight="600" mb="10px">
            {t("ownerHeading")}
          </Typography>
          <form
            onSubmit={(e) =>
              handleNextStep(e as React.KeyboardEvent<HTMLFormElement>)
            }
          >
            <CoreFormRenderer
              fields={registerFields}
              translation={t}
              data={fields}
              onChange={handleInput}
            />
            <Box className={classes.groupButtons}>
              <CoreButton
                isLoading={isLoading}
                onClick={handleNextStep}
                variant="contained"
                className={classes.button}
                disabled={Boolean(
                  (!isRecaptchaVerified && settings?.recaptchaSiteKey) ||
                    isLoading ||
                    isLoadingGoogleRegister
                )}
                type="submit"
              >
                {t("nextStep")}
              </CoreButton>
              <CoreButton
                onClick={handleGoogleRegister}
                disabled={Boolean(
                  (!isRecaptchaVerified && settings?.recaptchaSiteKey) ||
                    isLoading ||
                    isLoadingGoogleRegister
                )}
                variant="outlined"
                isFullWidth
                className={classes.button}
                isLoading={isLoadingGoogleRegister}
              >
                <GoogleIcon className={classes.googleIcon} />
                {t("ownerRegisterWithGoogle")}
              </CoreButton>
              {registerWithGoogleError && (
                <Box className={classes.error}>
                  <Typography className={classes.errorText}>
                    {registerWithGoogleError}
                  </Typography>
                </Box>
              )}
              {settings?.recaptchaSiteKey && (
                <ReCAPTCHA
                  sitekey={settings.recaptchaSiteKey}
                  onChange={verifyTokenRecaptcha}
                  theme={currentTheme === THEME_MODES.light ? "light" : "dark"}
                  style={{
                    borderRadius: "4px",
                  }}
                  onExpired={() => setIsRecaptchaVerified(false)}
                  hl={localStorage.getItem("i18nextLng") || "en"}
                />
              )}
            </Box>
          </form>
          <Divider className={classes.divider} />
          <Box className={classes.footer}>
            <Link
              href={authRoutes.Login()}
              color={theme.palette.text.primary}
              underline="none"
            >
              {t("have_account")}
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
});

export default Register;
