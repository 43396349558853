import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Konva from "konva";
import { observer } from "mobx-react";

import { Theme } from "@mui/material";

import { DocumentZone } from "../../../../../../types/interfaces";
import {
  CANVAS_ACTIONS,
  EDITABLE_ZONE_ACTIONS,
  NONEDITABLE_ZONE_ACTIONS,
} from "../../../../../../types/constants";
import Zone from "./Zone";
import ZoneDraft from "./ZoneDraft";
import { useNotification } from "../../../../../../context/useNotification";
import { DocumentStore } from "../../../../../../stores/DocumentStore";

export const ZonesRenderer: FC<{ documentStore: DocumentStore; theme: Theme }> =
  observer(({ documentStore, theme }) => {
    const notification = useNotification();
    const { t } = useTranslation("document-validate");

    const zones = useMemo(
      () =>
        [
          ...(documentStore.canvasZones || []),
          ...(documentStore.isSimpleMode
            ? []
            : documentStore.documentFields || []),
        ]?.filter((zone) => zone?.box),
      [
        documentStore.canvasZones,
        documentStore.isSimpleMode,
        documentStore.documentFields,
      ]
    );

    const showErrorNotification = () => {
      notification.error(t("fieldAlreadySelected"));
    };

    const onZoneUpdate = useCallback(
      (updatedZone: DocumentZone, e?: Konva.KonvaEventObject<Event>) => {
        if (EDITABLE_ZONE_ACTIONS.includes(documentStore.canvasAction)) {
          documentStore.updateZone(updatedZone, e, true);
        }
      },
      [documentStore]
    );

    const onZoneFocus = useCallback(
      (identifier: string | null) => {
        if (EDITABLE_ZONE_ACTIONS.includes(documentStore.canvasAction)) {
          documentStore.setFocusZone(identifier, showErrorNotification);
          documentStore.linkCurrentSelections();
          if (!documentStore.focusedFieldCanvas) {
            const focusedDocumentField = documentStore.documentFields?.find(
              (field) => field.identifier === identifier
            );
            if (focusedDocumentField?.type) {
              documentStore.setFocusFieldCanvas(focusedDocumentField.type);
            }
          }
        }
        if (NONEDITABLE_ZONE_ACTIONS.includes(documentStore.canvasAction)) {
          documentStore.setFocusZone(identifier);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [documentStore.canvasAction, documentStore.isSimpleMode]
    );

    return (
      <>
        {zones?.length > 0 &&
          zones.map((zone, index) => {
            // Do not draw zone if it does not belong to the current page
            if (
              (zone?.pageIdentifier &&
                zone?.pageIdentifier !==
                  documentStore.documentPage?.identifier) ||
              (!documentStore.isSimpleMode && zone.category)
            ) {
              return null;
            }

            const isZoneFocused =
              documentStore.focusedZones.includes(zone.identifier || "") ||
              zone.type === documentStore.focusedFieldCanvas ||
              zone.category === documentStore.focusedFieldCanvas;

            const isZoneEditable =
              [CANVAS_ACTIONS.drawNewZone].includes(
                documentStore.canvasAction
              ) &&
              !zone.default &&
              !documentStore.isSimpleMode &&
              !documentStore.temporaryAction;

            return (
              <Zone
                key={`${(zone.identifier || zone.type) ?? ""}-${index}`}
                zone={zone as unknown as DocumentZone}
                isDraggable={
                  !documentStore.isSimpleMode &&
                  !zone.default &&
                  !documentStore.viewMode &&
                  documentStore.canvasAction === CANVAS_ACTIONS.drawNewZone &&
                  documentStore.temporaryAction === null
                }
                isReadOnly={
                  (!documentStore.focusedFieldCanvas && zone.default) ||
                  (!documentStore.isSimpleMode && !!zone.category) ||
                  documentStore.viewMode
                }
                isEditable={isZoneEditable}
                isFocused={isZoneFocused}
                theme={theme}
                store={documentStore}
                onUpdate={onZoneUpdate}
                onFocus={onZoneFocus}
                searchQuery={documentStore.ocrSearchQuery}
              />
            );
          })}

        {[CANVAS_ACTIONS.drawNewZone].includes(documentStore.canvasAction) && (
          <ZoneDraft
            store={documentStore}
            theme={theme}
            isSelectMode={documentStore.isSimpleMode}
          />
        )}
      </>
    );
  });
