import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

import { useTheme, Box, alpha } from "@mui/material";

import CoreButton from "../../../core/CoreButton";
import { useStores } from "../../../../stores/StoresProvider";
import CoreTooltip from "../../../core/CoreTooltip";

const FlowLogsTabHeaderActions: React.FC = observer(() => {
  const { t } = useTranslation("activityLogs");
  const { activityLogStore } = useStores();
  const theme = useTheme();

  const classes = makeStyles({
    extraActionsBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonsBox: {
      display: "flex",
      paddingRight: "10px",
      gap: "10px",
    },
    button: {
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
        color: `${theme.palette.highlight.main} !important`,
      },
    },
  })();

  return (
    <Box className={classes.extraActionsBox}>
      <Box className={classes.buttonsBox}>
        <CoreTooltip title={t("action_refresh")}>
          <Box>
            <CoreButton
              variant="neutral"
              size="large"
              onClick={() => activityLogStore.setIsRefreshData(true)}
              startIcon={<CachedOutlinedIcon />}
              className={classes.button}
              disabled={
                activityLogStore.isRefreshData || activityLogStore.isPageLoading
              }
            >
              {t("action_refresh")}
            </CoreButton>
          </Box>
        </CoreTooltip>
      </Box>
    </Box>
  );
});

export default FlowLogsTabHeaderActions;
