import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import classNames from "classnames";
import { debounce } from "lodash";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { Op } from "quill/core";
import "./QuillMonoStyle.css";

import { makeStyles } from "@mui/styles";
import { Box, IconButton, useTheme, alpha, Typography } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import FlashIcon from "@mui/icons-material/FlashOn";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useStores } from "../../../stores/StoresProvider";
import { THEME_MODES, SuggestionsModalTabs } from "../../../types/constants";
import SuggestionsModal from "./SuggestionsModal";
import {
  FlowNode,
  FormData,
  FormField,
  InputOptions,
} from "../../../types/interfaces";
import CoreTooltip from "../../core/CoreTooltip";

interface Props {
  t: TFunction;
  value: string | number | unknown;
  onChange: (value: FormData) => void;
  placeholder?: string;
  disabled?: boolean;
  field: FormField;
  isMandatory?: boolean;
  errorText?: string;
  description?: string;
  tooltipLocation?: "title" | "input";
  disableDiagramField?: boolean;
}

const EDITOR_OPTIONS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "align",
  "size",
  "header",
  "color",
  "background",
];

const quillDefaultToolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  [{ align: [] }],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["link", "image", "video", "formula"],
  [{ color: [] }, { background: [] }],
  ["clean"],
];

const SuggestionsTextArea: React.FC<Props> = observer(
  ({
    t,
    value,
    onChange,
    disabled,
    field,
    isMandatory = false,
    errorText,
    description,
    tooltipLocation,
    disableDiagramField,
  }) => {
    const { mainStore, flowSettingsStore } = useStores();
    const theme = useTheme();
    const reactFlow = useReactFlow();

    const [modalTab, setModalTab] = useState("");
    const { quill, quillRef } = useQuill({
      theme: "snow",
      formats: EDITOR_OPTIONS,
      readOnly: !flowSettingsStore.isDiagramEditable,
      modules: {
        toolbar: field.showToolbox ? quillDefaultToolbarOptions : false,
      },
    });

    const classes = makeStyles({
      textarea: {
        width: "100%",
        borderRadius: "2px",
        resize: "vertical",
        backgroundColor:
          mainStore.currentTheme === THEME_MODES.light
            ? theme.palette.primary.main
            : theme.palette.surface.dark,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        overflow: "auto",
        fontSize: "14px",
        textWrap: "wrap",
        opacity: disabled ? "0.6" : "1",
        padding: "8.5px 14px",
      },
      mainBox: {
        display: "flex",
        width: "100%",
      },
      iconsBox: {
        display: "flex",
        flexDirection: "column",
      },
      modal: {
        display: "flex",
        justifyContent: "center",
      },
      textareaBox: {
        width: "100%",
        height: "100%",
        backgroundColor:
          mainStore.currentTheme === THEME_MODES.mono
            ? alpha(theme.palette.surface.dark, 0.8)
            : "inherit",
        "& .ql-editor": {
          minHeight: "6.875rem",
        },
      },
      asterisk: {
        color: theme.palette.error.main,
        marginLeft: "5px",
      },
      cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
      boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
      formText: {
        margin: "auto 0",
        height: "auto",
      },
      icon: {
        alignSelf: "center",
        marginLeft: "15px",
      },
      container: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      areaBox: {
        display: "flex",
        width: "100%",
      },
    })();

    const diagramNodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );
    const diagramEdges = useMemo(
      () => reactFlow.getEdges(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const quillClass =
      mainStore.currentTheme === THEME_MODES.mono ? "quill-root" : undefined;
    const combinedQuillClasses = classNames(classes.textareaBox, quillClass);

    const allContextVariables = useMemo(
      () =>
        flowSettingsStore.getNodeInputOptions(
          diagramNodes,
          diagramEdges,
          field,
          t
        ) as InputOptions[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        flowSettingsStore.nodeInputOptions,
        field?.allowedTypes,
        diagramNodes,
        diagramEdges,
        flowSettingsStore.nodeIdToEdit,
        flowSettingsStore.layoutingNeeded,
        flowSettingsStore.parentNodeId,
        t,
      ]
    );

    const options = useMemo(() => {
      if (!allContextVariables) {
        return [];
      }

      return allContextVariables;
    }, [allContextVariables]);

    const handleOnClose = () => {
      flowSettingsStore.setSuggestionsModalOpen(false);
      setModalTab("");
    };

    // const handleClickFunction = () => {
    //   flowSettingsStore.setSuggestionsModalOpen(true);
    //   setModalTab(SuggestionsModalTabs.function);
    // };

    const handleClickDynamicContent = () => {
      flowSettingsStore.setSuggestionsModalOpen(true);
      setModalTab(SuggestionsModalTabs.dynamicContent);
    };

    const setTextInQuill = (text: string) => {
      if (quill) {
        const range = quill.getSelection();
        let cursorPosition;

        if (range) {
          cursorPosition = range?.index;
        } else {
          cursorPosition = quill.getLength() - 1;
        }

        quill.insertText(cursorPosition, `{${text}} `);

        onChange({
          [field.key]: {
            ops: quill.getContents().ops,
            html: quill.getSemanticHTML(),
          },
        });

        quill.setSelection(cursorPosition + `{${text}} `?.length);
      }
    };

    useEffect(() => {
      if (quill) {
        const { ops } = value as { ops: Op[] };

        quill.setContents(ops, "silent");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quill]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnChange = useCallback(
      debounce(() => {
        if (quill) {
          onChange({
            [field.key]: {
              ops: quill.getContents().ops || [],
              html: quill.getSemanticHTML(),
            },
          });
        }
      }, 300),
      [quill, field.key, onChange]
    );

    useEffect(() => {
      if (quill) {
        quill.on("text-change", debouncedOnChange);

        quill?.clipboard?.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
          delta.ops = delta?.ops?.map((op) => ({
            insert: op?.insert,
          }));
          return delta;
        });
      }

      return () => {
        if (quill) {
          quill.off("text-change", debouncedOnChange);
        }
      };
    }, [quill, debouncedOnChange]);

    return (
      <>
        {field.name ? (
          <Typography variant="subtitle2">
            {field.name}
            {isMandatory && <span className={classes.asterisk}>*</span>}
          </Typography>
        ) : (
          <></>
        )}

        <Box className={classes.mainBox}>
          <Box className={classes.iconsBox}>
            {/* <IconButton onClick={handleClickFunction}>
              <FunctionIcon />
            </IconButton> */}
            {field.isDynamicContentAllowed && (
              <IconButton onClick={handleClickDynamicContent}>
                <FlashIcon />
              </IconButton>
            )}
          </Box>

          <Box className={!field?.showToolbox ? classes.container : ""}>
            <Box className={classes.areaBox}>
              <div className={combinedQuillClasses}>
                <div ref={quillRef} />
              </div>

              {description && tooltipLocation === "input" && (
                <CoreTooltip title={description}>
                  <InfoOutlinedIcon className={classes.icon} />
                </CoreTooltip>
              )}
            </Box>

            {errorText && (
              <Box className={classes.boxError}>
                <CancelOutlinedIcon className={classes.cancelIcon} />

                <FormHelperText
                  error={!!errorText}
                  className={classes.formText}
                >
                  {errorText}
                </FormHelperText>
              </Box>
            )}
          </Box>
        </Box>

        <SuggestionsModal
          t={t}
          onClose={handleOnClose}
          setModalTab={setModalTab}
          selectedModalTab={modalTab}
          suggestions={options}
          setTextInQuill={setTextInQuill}
          disableDiagramField={disableDiagramField}
        />
      </>
    );
  }
);

export default SuggestionsTextArea;
