import React from "react";
import { useDropzone } from "react-dropzone";
import { TFunction } from "i18next";

import { Box, IconButton, InputAdornment } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { FormData, FormField } from "../../types/interfaces";
import { FormFieldProps } from "../../types/types";
import CoreInput from "../core/CoreInput";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  onChange: (value: FormData) => void;
  errorText?: string;
  otherProps?: FormFieldProps;
  type?: string;
  hidePasswordController?: boolean;
  description?: string;
  tooltipLocation?: "title" | "input";
}
export const FileUploadRenderer: React.FC<Props> = ({
  field,
  translation,
  onChange,
  errorText,
  value,
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/x-python-code": [".py"],
    },
    onDrop: (acceptedFiles) => {
      onChange({ [field.key]: acceptedFiles[0] } as unknown as FormData);
    },
  });

  const discardFiles = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onChange({ [field.key]: null } as unknown as FormData);
  };

  return (
    <>
      <CoreInput
        onClick={open}
        placeholder={translation(field.translationKey)}
        value={value ? (value as { name: string }).name : ""}
        disabled
        title={field.name}
        errorText={errorText}
        error={!!errorText}
        endAdornment={
          <InputAdornment position="end">
            {value ? (
              <IconButton onClick={discardFiles}>
                {<RemoveCircleIcon />}
              </IconButton>
            ) : null}
          </InputAdornment>
        }
      />

      <Box {...getRootProps()}>
        <input {...getInputProps()} />
      </Box>
    </>
  );
};
