import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import ActivityLogsPage from "./pages/ActivityLogsPage";
import { ActivityLogDetailsPage } from "./pages/ActivityLogDetailsPage";
import { appRoutes } from "../../../configs/routes";
import { useStores } from "../../../stores/StoresProvider";

const ACTIVITY_LOGS_ROUTES = [
  {
    path: appRoutes.ContextEvents(),
    component: ActivityLogsPage,
    exact: true,
  },
  {
    path: appRoutes.ContextEvent(),
    component: ActivityLogDetailsPage,
    exact: true,
  },
];

const ActivityLogs: React.FC = () => {
  const { activityLogStore } = useStores();

  useEffect(() => {
    return () => {
      activityLogStore.resetStore(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {ACTIVITY_LOGS_ROUTES.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      ))}
    </Switch>
  );
};

export default ActivityLogs;
