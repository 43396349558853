import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { TFunction } from "i18next";

import { useDropzone } from "react-dropzone";

import { makeStyles } from "@mui/styles";
import { Box, Typography, Divider } from "@mui/material";

import {
  ExtendedFile,
  FlowDiagram,
  FlowInterface,
  User,
} from "../../../../types/interfaces";
import CoreDrawer from "../../../core/CoreDrawer";
import CoreButton from "../../../core/CoreButton";
import ImportFlowCustomConfiguration from "./ImportFlowCustomConfiguration";
import { useNotification } from "../../../../context/useNotification";
import FlowHelper from "../../../../helper/flowHelper";
import ImportFlowDragAndDropZone from "./ImportFlowDragAndDropZone";
import { useStores } from "../../../../stores/StoresProvider";
import { appRoutes } from "../../../../configs/routes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  t: TFunction;
}

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingTop: "30px",
  },
  section: {
    marginTop: "20px",
  },
  divider: {
    paddingTop: "5px",
  },
  boxNameAndAccess: {
    paddingTop: "20px",
  },
});

const ImportFlow: React.FC<Props> = ({ isOpen, onClose, t }) => {
  const notification = useNotification();
  const { flowSettingsStore, mainStore } = useStores();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [flowName, setFlowName] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [file, setFile] = useState<ExtendedFile | null>(null);
  const [validFlowObject, setValidFlowObject] = useState<FlowInterface | null>(
    null
  );
  const [validDiagramObject, setValidDiagramObject] =
    useState<FlowDiagram | null>(null);
  const [isOnDrop, setIsOnDrop] = useState(false);

  const classes = useStyles();

  const handleDrop = async (files: File[]) => {
    if (!files?.length) {
      notifyFlowError(t("fileTypeError"));
      setIsOnDrop(false);
      return;
    }

    setFile(files[0]);
    await processFile(files[0]);
    setIsOnDrop(false);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: handleDrop,
    onDragEnter: () => setIsOnDrop(true),
    onDragLeave: () => setIsOnDrop(false),
    accept: {
      "application/json": [".json"],
    },
  });

  const processFile = async (file: File) => {
    try {
      const processedConfig = await FlowHelper.preprocessFlowImportFile(
        file,
        () => notifyDiagramError(t("invalidDiagramConfig"))
      );

      const { flow, flowDiagram } = processedConfig;

      setValidFlowObject(flow as unknown as FlowInterface);

      if (flowDiagram) {
        setValidDiagramObject(flowDiagram as unknown as FlowDiagram);
      }
    } catch (e) {
      notifyFlowError(t("invalidFlowConfig"));
    }
  };

  const notifyFlowError = (message: string) => {
    discardFile();
    notification.error(message);
  };

  const notifyDiagramError = (message: string) => {
    notification.error(message);
  };

  const handleOnClose = () => {
    setFile(null);
    setValidFlowObject(null);
    setFlowName("");
    setSelectedUsers([]);
    setValidDiagramObject(null);
    setValidFlowObject(null);
    onClose();
  };

  const importFlowAndDiagram = (flowConfig: FlowInterface) => {
    flowSettingsStore
      .importFlow(flowConfig)
      .then((identifier) => {
        mainStore.collapseSidebar();

        if (!validDiagramObject) {
          history.push(appRoutes.FlowSettings(identifier));
        }

        notification.success(t("importFlowSuccess"));

        if (validDiagramObject) {
          const {
            specs: { nodes, edges },
          } = validDiagramObject;

          flowSettingsStore
            .importFlowDiagram(identifier, {
              nodes,
              edges,
            } as unknown as FlowDiagram)
            .then(() => {
              history.push(appRoutes.FlowSettings(identifier));
            })
            .catch((error: Error) => {
              history.push(appRoutes.FlowSettings(identifier));
              notification.error(t(error?.message || "importFlowDiagramError"));
            });
        }
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "importFlowError"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const discardFile = () => {
    setFile(null);
    setValidFlowObject(null);
  };

  const handleConfirm = () => {
    let access: Record<string, string> | null = null;

    if (selectedUsers && selectedUsers.length > 0) {
      access = selectedUsers?.reduce((acc, user) => {
        acc[user.id.toString()] = "restricted";
        return acc;
      }, {} as Record<string, string>);
    }

    const flowConfig = {
      ...validFlowObject,
      name: flowName,
      access: access,
    } as FlowInterface;

    setLoading(true);

    importFlowAndDiagram(flowConfig);
  };

  return (
    <CoreDrawer
      isOpen={isOpen}
      onClose={handleOnClose}
      title={t("importFlowTitle")}
    >
      <ImportFlowDragAndDropZone
        t={t}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        fileName={file?.name || ""}
        isOnDrop={isOnDrop}
        open={open}
        isJsonValid={!!validFlowObject}
      />

      <Typography className={classes.section}>
        {t("updateConfiguration")}
      </Typography>

      <Divider className={classes.divider} />

      <Box className={classes.boxNameAndAccess}>
        <ImportFlowCustomConfiguration
          flowConfig={validFlowObject}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          flowName={flowName}
          setFlowName={setFlowName}
        />
      </Box>

      <Box className={classes.footer}>
        <CoreButton
          variant="contained"
          onClick={handleConfirm}
          disabled={!validFlowObject || loading || !flowName}
          isLoading={loading}
        >
          {t("confirm")}
        </CoreButton>
        <CoreButton
          variant="outlined"
          onClick={handleOnClose}
          disabled={loading}
        >
          {t("cancel")}
        </CoreButton>
      </Box>
    </CoreDrawer>
  );
};

export default ImportFlow;
