import React, { FC } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";

import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import { useStores } from "../../../../../stores/StoresProvider";
import {
  NODE_CONDITIONAL_BRANCHES,
  NODE_HANDLE_TYPES,
  NODE_TYPES,
} from "../../../../../types/constants";
import { FlowNode, NodeData } from "../../../../../types/interfaces";
import DiagramNode from "./DiagramNode";
import DiagramNodeHandleButton from "./DiagramNodeHandleButton";
import DiagramNodeHandle from "./DiagramNodeHandle";

interface NodeProps {
  data: NodeData;
  groupId?: string;
}

const DiagramPlaceholder: FC<NodeProps> = observer(({ data, groupId }) => {
  const { flowSettingsStore } = useStores();
  const reactFlow = useReactFlow();
  const theme = useTheme();

  const classes = makeStyles({
    diagramNodeContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px dashed ${theme.palette.neutral.dark}`,
      borderRadius: "2px",
      height: "inherit",
      width: "inherit",
      position: "relative",
    },
    noChildrenIcon: {
      color: theme.palette.neutral.dark,
    },
  })();

  const nodeData = reactFlow.getNode(data.identifier) as unknown as FlowNode;

  const { onHandleClick, identifier } = data;

  const onConditionAddClick = () => {
    flowSettingsStore.setSelectedEdgeId(undefined);
    flowSettingsStore.setConditionalPlaceholderFocus(identifier);
    flowSettingsStore.setDesignerModalAddMode(true);

    if (onHandleClick) {
      onHandleClick && onHandleClick(identifier);
    }
  };

  if (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    nodeData?.data?.type === NODE_TYPES.passthrough &&
    (nodeData?.[NODE_CONDITIONAL_BRANCHES.trueBranch] ||
      nodeData?.[NODE_CONDITIONAL_BRANCHES.falseBranch])
  ) {
    return (
      <>
        <DiagramNodeHandle
          type={NODE_HANDLE_TYPES.target}
          hidden={true}
          identifier={identifier}
        />

        <Box className={classes.diagramNodeContainer}>
          {flowSettingsStore.isDiagramEditable ? (
            <DiagramNodeHandleButton
              onClick={onConditionAddClick}
              extraStyles={{ top: "unset", left: "unset" }}
            />
          ) : (
            <RemoveCircleOutlineIcon
              fontSize="small"
              className={classes.noChildrenIcon}
            />
          )}
        </Box>
      </>
    );
  }

  return <DiagramNode data={data} groupId={groupId} />;
});

export default DiagramPlaceholder;
