import React, { SVGProps, FC } from "react";
import { useTheme } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RejectDocumentIcon: FC = (props: SVGProps<any>) => {
  const theme = useTheme();

  return (
    <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 2V8C12 9.10457 12.8954 10 14 10H20V20C20 21.1046 19.1046 22 18 22H11.1904C12.3112 20.8321 13 19.2465 13 17.5C13 13.9101 10.0899 11 6.5 11C5.6141 11 4.76959 11.1772 4 11.4982V4C4 2.89543 4.89543 2 6 2H12Z"
        fill={theme.palette.text.primary}
      />
      <path
        d="M13.5 2.5V8C13.5 8.27614 13.7239 8.5 14 8.5H19.5L13.5 2.5Z"
        fill={theme.palette.text.primary}
      />
      <path
        d="M6.5 12C9.53757 12 12 14.4624 12 17.5C12 20.5376 9.53757 23 6.5 23C3.46243 23 1 20.5376 1 17.5C1 14.4624 3.46243 12 6.5 12ZM8.97823 15.7313C9.1735 15.5361 9.1735 15.2195 8.97823 15.0242C8.78297 14.829 8.46639 14.829 8.27113 15.0242L6.50133 16.793L4.73246 15.0241C4.5372 14.8289 4.22061 14.8289 4.02535 15.0241C3.83009 15.2194 3.83009 15.536 4.02535 15.7312L5.79401 17.4999L4.02535 19.2675C3.83009 19.4628 3.83009 19.7794 4.02535 19.9746C4.22061 20.1699 4.5372 20.1699 4.73246 19.9746L6.50112 18.207L8.27095 19.9768C8.46621 20.1721 8.78279 20.1721 8.97806 19.9768C9.17332 19.7816 9.17332 19.465 8.97806 19.2697L7.20843 17.5001L8.97823 15.7313Z"
        fill={theme.palette.text.primary}
      />
    </svg>
  );
};
