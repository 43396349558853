import React from "react";

import { TFunction } from "i18next";

import EditorJson from "../pages/connections/components/EditorJson";
import { FormField } from "../../types/interfaces";
import { FormFieldLanguageTypes } from "../../types/enums";

interface Props {
  onChange: (value: Record<string, string>) => void;
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  errorText: string | undefined;
  modeOverwrite?: FormFieldLanguageTypes;
  disableDiagramField?: boolean;
}

const GenericEditorRenderer: React.FC<Props> = ({
  onChange,
  value,
  field,
  errorText,
  translation,
  modeOverwrite,
  disableDiagramField = false,
}) => {
  const handleChange = (updatedValue: string) => {
    onChange({ [field.key]: updatedValue } as Record<string, string>);
  };

  return (
    <EditorJson
      onChange={handleChange}
      value={value as string | undefined}
      errorText={errorText}
      label={translation(field.translationKey ?? "")}
      isMandatory={field.isMandatory}
      mode={modeOverwrite ?? field.language}
      disableDiagramField={disableDiagramField}
    />
  );
};

export default GenericEditorRenderer;
