import React, { useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";

// Material ui
import { makeStyles } from "@mui/styles";
import {
  alpha,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";

import { APP_IDS } from "../../types/constants";
import { useHistoryContext } from "../../context/useHistoryContext";
import { HeaderActions } from "../../types/interfaces";
import CoreTooltip from "./CoreTooltip";
import CoreButton from "./CoreButton";
import { AppSuspense } from "../main/AppSuspense";
import { useStores } from "../../stores/StoresProvider";

export interface Props {
  label?: string;
  canGoBack?: boolean;
  extraHeaderActions?: HeaderActions[];
  extraHeaderContent?: React.ReactNode; // React components directly
  titleActions?: React.ReactNode;
  subtitle?: string;
  contentClass?: string;
  toolbarClass?: string;
  childrenClass?: string;
  pagesBack?: number;
  redirectPage?: string;
  isPageLoading?: boolean;
  maxHeight?: { tablet: number };
  extraHeaderMenuException?: boolean;
  isNotFlowPage?: boolean;
  headerContentClass?: string;
  children?: React.ReactNode;
  extraHeaderActionsButtonDisabled?: boolean;
  showMoreVertIcon?: boolean;
  fixedExtraHeaderActions?: React.ReactNode;
  fixedExtraHeaderClass?: string;
  fixExtraHeaderContent?: boolean;
}

const CorePageContainer: React.FC<Props> = observer(
  ({
    isPageLoading,
    subtitle,
    label,
    children,
    canGoBack,
    titleActions,
    extraHeaderActions,
    extraHeaderContent,
    contentClass,
    toolbarClass,
    childrenClass,
    pagesBack = 1,
    redirectPage,
    extraHeaderMenuException = false,
    isNotFlowPage = false,
    headerContentClass,
    extraHeaderActionsButtonDisabled = false,
    showMoreVertIcon = true,
    fixedExtraHeaderActions,
    fixedExtraHeaderClass,
    fixExtraHeaderContent,
  }) => {
    const { userStore, flowStore } = useStores();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [mobileAnchorEl, setMobileAnchorEl] = useState<null | HTMLElement>(
      null
    );
    const mobileOpen = Boolean(mobileAnchorEl);

    const theme = useTheme();

    const useStyles = makeStyles({
      childrenContainer: {
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        borderRadius: "0px",
        backgroundImage: "none",
        marginTop: 64,
        overflowY: "auto",
      },
      childrenContainerNotFlowPage: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        borderRadius: "0px",
        backgroundImage: "none",
        overflowY: "auto",
      },
      toolbar: {
        display: "flex",
        flexDirection: "row",
        padding: "0 30px",
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        alignItems: "center",
        minHeight: 70,
        justifyContent: "space-between",
        "@media screen and (max-width:1500px)": {
          flexDirection: "row",
          justifyContent: "space-between",
          minHeight: 70,
        },
      },
      toolbarExtraHeaderMenuException: {
        display: "flex",
        flexDirection: "row",
        padding: "0 30px",
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        alignItems: "center",
        minHeight: 70,
        "@media screen and (max-width:1500px)": {
          flexDirection: "row",
          justifyContent: "space-between",
          minHeight: 70,
        },
      },
      titleNotFlowPage: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "750px",
        textAlign: "left",
      },
      title: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "750px",
        textAlign: "left",
        "@media screen and (min-width:400px)": {
          maxWidth: "100px",
        },
        "@media screen and (min-width:650px)": {
          maxWidth: "120px",
        },
        "@media screen and (min-width:768px)": {
          maxWidth: "150px",
        },
        "@media screen and (min-width:900px)": {
          maxWidth: "250px",
        },
        "@media screen and (min-width:961px)": {
          maxWidth: "400px",
        },
        "@media screen and (min-width:1100px)": {
          maxWidth: "500px",
        },
        "@media screen and (min-width:1200px)": {
          maxWidth: "600px",
        },
        "@media screen and (min-width:1501px)": {
          maxWidth: "1000px",
        },
      },
      subtitle: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "750px",
        textAlign: "left",
        "@media screen and (min-width:400px)": {
          maxWidth: "100px",
        },
        "@media screen and (min-width:650px)": {
          maxWidth: "120px",
        },
        "@media screen and (min-width:768px)": {
          maxWidth: "150px",
        },
        "@media screen and (min-width:900px)": {
          maxWidth: "250px",
        },
        "@media screen and (min-width:960px)": {
          maxWidth: "320px",
        },
        "@media screen and (min-width:1100px)": {
          maxWidth: "500px",
        },
        "@media screen and (min-width:1200px)": {
          maxWidth: "600px",
        },
        "@media screen and (min-width:1501px)": {
          maxWidth: "1000px",
        },
      },
      iconButton: {
        marginRight: "10px",
      },
      actions: {
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        margin: "3px",
        marginLeft: "auto",
        "@media screen and (max-width:1500px)": {
          display: "none",
        },
      },
      fixedActions: {
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        margin: "3px",
        marginLeft: "auto",
      },
      actionsExtraHeaderMenuException: {
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        marginLeft: "auto",
        "@media screen and (max-width:1500px)": {
          display: "none",
        },
      },
      mobileActions: {
        textAlign: "right",
        marginTop: 5,
        "@media screen and (min-width:1501px)": {
          display: "none",
        },
        "@media screen and (max-width:1500px)": {
          display: "block",
        },
      },
      mobileActionsExtraHeaderMenuException: {
        textAlign: "right",
        "@media screen and (min-width:1501px)": {
          display: "none",
        },
        "@media screen and (max-width:1500px)": {
          display: "block",
        },
      },
      children: {
        height: "calc(100vh - 135px)",
        display: "flex",
        flexDirection: "column",
      },
      button: {
        marginLeft: "5px",
        textTransform: "none",
        transition: "none",
        display: "flex",
        alignItems: "stretch",
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
          backgroundColor: alpha(theme.palette.highlight.light, 0.08),
          color: `${theme.palette.highlight.main} !important`,
        },
      },
      divider: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
      },
      mobileList: {
        "& ul": {
          padding: 0,
          boxShadow: "11px 16px 7px -10px rgba(0,0,0,0.43)",
        },
      },
      headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      headerActions: {
        margin: "3px",
        marginLeft: "5px",
        textTransform: "none",
        transition: "none",
        display: "flex",
        alignItems: "stretch",
        color: "inherit",
      },
      menuItem: {
        background: theme.palette.background.paper,
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        "&:hover": {
          color: `${theme.palette.highlight.main} !important`,
        },
      },
      iconStyle: {
        color: "inherit",
      },
      moreVertIcon: {
        borderRadius: 100,
        minWidth: 0,
        padding: "4px 5px",
      },
      arrowBack: {
        fontSize: 15,
      },
    });

    const classes = useStyles();
    const historyCustom = useHistoryContext();

    const handleClick = (
      event: React.MouseEvent<HTMLElement>,
      isMobile: boolean
    ) => {
      isMobile
        ? setMobileAnchorEl(event.currentTarget)
        : setAnchorEl(event.currentTarget);
    };

    const handleClose = (isMobile: boolean) => {
      isMobile ? setMobileAnchorEl(null) : setAnchorEl(null);
    };

    const childrenContainerClasses = isNotFlowPage
      ? classes.childrenContainerNotFlowPage
      : classes.childrenContainer;

    const containerClasses = contentClass
      ? classNames([childrenContainerClasses, contentClass])
      : childrenContainerClasses;

    const childrenClasses = childrenClass
      ? classNames([classes.children, childrenClass])
      : classes.children;

    const titleClasses = isNotFlowPage
      ? classes.titleNotFlowPage
      : classes.title;

    const extraHeaderContentClass = fixExtraHeaderContent
      ? classes.fixedActions
      : headerContentClass
      ? headerContentClass
      : classes.actions;

    let toolbarClasses = toolbarClass
      ? classNames([toolbarClass, classes.toolbar])
      : classes.toolbar;

    let actionsClasses = classes.actions;

    let mobileActionsClasses = classes.mobileActions;

    if (extraHeaderMenuException) {
      toolbarClasses = toolbarClass
        ? classNames([toolbarClass, classes.toolbarExtraHeaderMenuException])
        : classes.toolbarExtraHeaderMenuException;

      actionsClasses = classes.actionsExtraHeaderMenuException;
      mobileActionsClasses = classes.mobileActionsExtraHeaderMenuException;
    }

    const handleBackButton = () => {
      historyCustom?.goBack({ pagesBack, redirectPage });
    };

    if (isPageLoading !== undefined && isPageLoading) {
      return <AppSuspense />;
    }

    const emitActionsMenu = (
      headerActions: HeaderActions[],
      isMobile = false
    ) => {
      const onOptionClick = (
        headerAction: HeaderActions,
        isMobile: boolean
      ) => {
        handleClose(isMobile);

        headerAction.onClick && headerAction.onClick();
      };

      return (
        headerActions.length > 0 && (
          <div>
            {showMoreVertIcon && (
              <CoreButton
                variant="neutral"
                onClick={(event) => handleClick(event, isMobile)}
                size="medium"
                className={classes.moreVertIcon}
                disabled={extraHeaderActionsButtonDisabled}
              >
                {extraHeaderActionsButtonDisabled ? (
                  <CircularProgress size="25px" />
                ) : (
                  <MoreVertIcon sx={{ fontSize: 20 }} />
                )}
              </CoreButton>
            )}
            <Menu
              anchorEl={isMobile ? mobileAnchorEl : anchorEl}
              open={isMobile ? mobileOpen : open}
              onClose={() => handleClose(isMobile)}
              className={classes.mobileList}
            >
              <MenuList dense>
                {headerActions.map((headerAction, index) => {
                  const ability = headerAction.ability ?? [];
                  const canDo =
                    ability.length === 0
                      ? true
                      : ability.every(
                          (ability) =>
                            userStore.currentUserPermissions?.can(
                              ability.action,
                              ability.subject
                            ) ||
                            flowStore.currentFlowPermissions?.can(
                              ability.action,
                              ability.subject
                            )
                        );
                  if (canDo)
                    return (
                      <div key={`submenu-${headerAction.label}-${index}`}>
                        <MenuItem
                          key={index}
                          onClick={() => onOptionClick(headerAction, isMobile)}
                          disabled={headerAction.disabled}
                          className={classes.menuItem}
                        >
                          <ListItemIcon className={classes.iconStyle}>
                            {headerAction.startIcon}
                          </ListItemIcon>
                          <Typography className={classes.headerActions}>
                            {headerAction.label}
                          </Typography>
                        </MenuItem>
                        {headerAction.divider && (
                          <Divider className={classes.divider} />
                        )}
                      </div>
                    );
                  return null;
                })}
              </MenuList>
            </Menu>
          </div>
        )
      );
    };

    return (
      <Paper className={containerClasses} sx={{ boxShadow: "none" }}>
        <Box id={APP_IDS.appToolbar} className={toolbarClasses}>
          <Box className={classes.headerBox}>
            {canGoBack && (
              <IconButton
                className={classes.iconButton}
                onClick={() => handleBackButton()}
              >
                <ArrowBackIosNew className={classes.arrowBack} />
              </IconButton>
            )}
            <Box>
              <Typography variant="h6" className={titleClasses} title={label}>
                {label}
              </Typography>
              {subtitle && (
                <Typography
                  className={classes.subtitle}
                  variant="body2"
                  title={subtitle}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>

            {titleActions}
          </Box>
          {extraHeaderContent && (
            <Box className={extraHeaderContentClass}>{extraHeaderContent}</Box>
          )}
          {extraHeaderActions && (
            <Box className={classes.fixedActions}>
              {fixedExtraHeaderActions && (
                <Box className={fixedExtraHeaderClass}>
                  {fixedExtraHeaderActions}
                </Box>
              )}
              {extraHeaderActions && (
                <Box className={actionsClasses}>
                  {extraHeaderActions
                    .filter((headerAction) => !headerAction.initiallyHidden)
                    .map((headerAction, index) => {
                      const ability = headerAction.ability ?? [];
                      const canDo =
                        ability.length === 0
                          ? true
                          : ability.every(
                              (ability) =>
                                userStore.currentUserPermissions?.can(
                                  ability.action,
                                  ability.subject
                                ) ||
                                flowStore.currentFlowPermissions?.can(
                                  ability.action,
                                  ability.subject
                                )
                            );
                      if (canDo)
                        return (
                          <CoreTooltip title={headerAction.tooltip} key={index}>
                            <Box>
                              <CoreButton
                                variant="neutral"
                                size="large"
                                onClick={headerAction.onClick}
                                disabled={headerAction.disabled}
                                startIcon={headerAction.startIcon}
                                className={classes.button}
                              >
                                {headerAction.label}
                              </CoreButton>
                            </Box>
                          </CoreTooltip>
                        );
                      return null;
                    })}
                  {emitActionsMenu(
                    extraHeaderActions.filter(
                      (headerAction) => headerAction.initiallyHidden
                    )
                  )}
                </Box>
              )}
            </Box>
          )}
          {extraHeaderActions && (
            <Box className={mobileActionsClasses}>
              {emitActionsMenu(extraHeaderActions, true)}
            </Box>
          )}
        </Box>

        <Box className={childrenClasses}>{children}</Box>
      </Paper>
    );
  }
);

export default CorePageContainer;
