import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import Logo from "../../../../src/icons/Logo";

import { AppSuspense } from "../../main/AppSuspense";
import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import { appRoutes, authRoutes } from "../../../configs/routes";

const InvitationLandingPage: React.FC = () => {
  const [dataVerifying, setDataVerifying] = useState(true);
  const notification = useNotification();

  const { userStore } = useStores();
  const { t } = useTranslation("team");

  const useStyles = makeStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
    },
    logo: {
      height: 40,
      width: 250,
    },
    welcome: {
      marginTop: "10px",
    },
    message: {
      marginTop: "15px",
      marginBottom: "15px",
      textTransform: "uppercase",
      textAlign: "center",
    },
  });

  // Get invitation JWT token from URL
  const { token } = useParams<{ token: string }>();
  const apolloClient = useApolloClient();
  const history = useHistory();
  useEffect(() => {
    userStore
      .getInvitationLinkData(apolloClient, token ?? "")
      .then((data) => {
        notification.success(t(data.successNotification));
        switch (data.redirect) {
          case "register":
            history.push(authRoutes.Register());
            break;
          case "login":
            history.push(authRoutes.Login());
            break;
          case "namespace":
            void userStore.removeCTX(apolloClient);
            history.push(authRoutes.Namespaces());
            break;
          default:
            history.push(appRoutes.Home());
        }
      })
      .catch((error: Error) => {
        if (userStore.user) {
          history.push(appRoutes.Home());
        }
        notification.error(t(error?.message || "linkError"));
      })
      .finally(() => {
        setDataVerifying(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();

  if (dataVerifying) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.container}>
      <Logo fullLogoClassName={classes.logo} />
      <Typography className={classes.welcome}>{t("welcome")}</Typography>
      <Typography variant="h5" className={classes.message}>
        {t("linkExpiredPage")}
      </Typography>

      <Typography variant="h6" style={{ textAlign: "center" }}>
        {t("tryAgain")}
      </Typography>
    </Box>
  );
};

export default InvitationLandingPage;
