import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import {
  alpha,
  useTheme,
  Box,
  ListItemText,
  ListItemButton,
} from "@mui/material";

import { MenuItem as ListMenuItem } from "../menuConfig";
import { useStores } from "../../../../stores/StoresProvider";
import { AppSuspense } from "../../../main/AppSuspense";
import { appRoutes } from "../../../../configs/routes";

interface Props {
  options: ListMenuItem[];
}

const MenuContainer: React.FC<Props> = observer(({ options }) => {
  const { t, ready } = useTranslation("settings");
  const theme = useTheme();
  const { mainStore } = useStores();

  const classes = makeStyles({
    menuItemLink: {
      display: "block",
      marginTop: "10px",
      marginBottom: "10px",
      textDecoration: "none",
      "&.Mui-selected": {
        color: theme.palette.highlight.main,
        "& .MuiTypography-root": {
          color: theme.palette.highlight.main,
        },
      },
    },
    menuItem: {
      margin: "0 10px",
      height: "32px",
      padding: "8px 16px 8px 16px",
      color: alpha(theme.palette.highlight.light, 0.5),
      "&.Mui-selected": {
        color: theme.palette.highlight.main,
        "& .MuiTypography-root": {
          color: theme.palette.highlight.main,
        },
      },
    },
    icon: {
      marginRight: "10px",
      height: "20px",
    },
    text: {
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  })();

  if (!ready) return <AppSuspense />;

  return (
    <Box>
      {options.map(({ name, icon: Icon, path }, index) => (
        <Link to={path} key={index} className={classes.menuItemLink}>
          <ListItemButton
            className={classes.menuItem}
            selected={
              mainStore.currentPage === path ||
              (mainStore.currentPage === appRoutes.SubscriptionPlanUpgrade() &&
                path === appRoutes.AccountBilling())
            }
          >
            <Icon className={classes.icon} />

            <ListItemText className={classes.text}>{t(name)}</ListItemText>
          </ListItemButton>
        </Link>
      ))}
    </Box>
  );
});

export default MenuContainer;
