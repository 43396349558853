import React, { useState, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import CoreButton from "../../core/CoreButton";
import {
  AssetConfiguration,
  AssetTemplateParameters,
  FormData,
  FormField,
} from "../../../types/interfaces";
import CoreInput from "../../core/CoreInput";
import CoreTooltip from "../../core/CoreTooltip";
import { useStores } from "../../../stores/StoresProvider";
import ApprovalDrawer from "../../pages/flow/flow-settings/conditional/RulesDrawer";
import DeleteVariableAction from "../nodeList/DeleteVariableAction";

interface Props {
  translation: TFunction;
  formData: FormData;
  field: FormField;
  value: string | boolean | number | unknown;
  onChange: (value: FormData) => void;
  onDelete?: (key: string) => void;
}

const useStyles = makeStyles({
  mainBox: { display: "flex", flexDirection: "column" },
  buttonBox: {
    paddingTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
  },
  boxInput: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
  },
  mainInputBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  iconStyle: {
    marginLeft: "5px",
    marginRight: "-11px",
  },
});

const ConditionalNodeRenderer: React.FC<Props> = observer(
  ({ translation, formData, field, value, onChange, onDelete }) => {
    const classes = useStyles();
    const { flowSettingsStore } = useStores();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState<
      number | undefined
    >();
    const [isOnConfig, setIsOnConfig] = useState<boolean>(false);

    const handleAddNewRule = (newValue: { [key: string]: unknown }) => {
      onChange({
        [field.key]: [
          ...((formData[field.key] as AssetTemplateParameters[]) || []),
          newValue,
        ],
      });
    };

    const handleEditRule = (newValue: { [key: string]: unknown }) => {
      const updatedList = (
        (formData[field.key] as AssetTemplateParameters[]) || []
      )?.map((item, index) => {
        if (index === selectedItemIndex) {
          return newValue;
        }

        return item;
      });

      onChange({
        [field.key]: updatedList,
      });
    };

    const handleCloseDrawer = () => {
      if (isOnConfig) setIsOnConfig(false);
      if (selectedItemIndex !== undefined) setSelectedItemIndex(undefined);
      setIsOpen(false);
    };

    const onClickAdd = () => {
      setIsOpen(true);
      if (isOnConfig) setIsOnConfig(false);
    };

    const onClickEdit = (index: number) => {
      setIsOpen(true);
      setSelectedItemIndex(index);
      setIsOnConfig(true);
    };

    const handleTooltipDescription = useMemo(() => {
      if (flowSettingsStore.isDiagramEditable) {
        return translation("clickToEditRule");
      }
      return translation("clickToViewRule");
    }, [translation, flowSettingsStore.isDiagramEditable]);

    const getListValues = useMemo(() => {
      return (formData?.rules as AssetConfiguration[]) || [];
    }, [formData]);

    return (
      <Box className={classes.mainBox}>
        <Box className={classes.buttonBox}>
          <CoreButton
            onClick={onClickAdd}
            disabled={!flowSettingsStore.isDiagramEditable}
          >
            {translation("addNewRule")}
          </CoreButton>
        </Box>

        <Box className={classes.mainInputBox}>
          {((value as FormData[]) || [])?.map(
            (variable: FormData, index: number) => (
              <Box key={index} className={classes.boxInput}>
                <CoreInput key={index} value={variable.name} readOnly={true} />

                <DeleteVariableAction
                  onDelete={onDelete}
                  itemKey={variable?.key as string}
                  fieldKey={field?.key}
                  t={translation}
                />

                <CoreTooltip title={handleTooltipDescription}>
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() => onClickEdit(index)}
                  >
                    <SettingsIcon />
                  </IconButton>
                </CoreTooltip>
              </Box>
            )
          )}
        </Box>

        <ApprovalDrawer
          t={translation}
          isOpen={isOpen}
          onClose={handleCloseDrawer}
          onAdd={handleAddNewRule}
          onEdit={handleEditRule}
          list={getListValues}
          selectedItemPosition={selectedItemIndex}
          setSelectedItemPosition={setSelectedItemIndex}
          isOnConfig={isOnConfig}
          setIsOnConfig={setIsOnConfig}
        />
      </Box>
    );
  }
);

export default ConditionalNodeRenderer;
