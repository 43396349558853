import React from "react";
import { TFunction } from "i18next";
import ChatGptMessageBox from "./ChatGptMessageBox";
import { CHAT_GPT_MESSAGE_TYPES } from "../../../../types/constants";
import { ChatGptMessage } from "../../../../types/interfaces";

interface Props {
  t: TFunction;
  messages: ChatGptMessage[];
  isHomeChat: boolean;
}

const MessageRenderer: React.FC<Props> = ({ t, messages, isHomeChat }) => {
  return (
    <>
      {
        //Show default message in popup
        !isHomeChat && messages?.length === 0 ? (
          <ChatGptMessageBox
            type={CHAT_GPT_MESSAGE_TYPES.claritext}
            text={t("no_messages_text")}
            isHomeChat={isHomeChat}
            hideRefreshIcon={true}
          />
        ) : (
          messages &&
          messages?.length > 0 &&
          messages?.map((message, index) => (
            <React.Fragment key={index}>
              {message?.question ? (
                <ChatGptMessageBox
                  isHomeChat={isHomeChat}
                  enableTypeWritter={index === messages?.length - 1}
                  position={index}
                  type={CHAT_GPT_MESSAGE_TYPES.user}
                  text={message.question}
                />
              ) : (
                <></>
              )}

              {message?.answer ? (
                <ChatGptMessageBox
                  isHomeChat={isHomeChat}
                  enableTypeWritter={index === messages?.length - 1}
                  position={index}
                  type={CHAT_GPT_MESSAGE_TYPES.claritext}
                  text={message.answer}
                  filters={message.filter}
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          ))
        )
      }
    </>
  );
};

export default MessageRenderer;
