import React, { FC, useMemo, useState } from "react";
import { TFunction } from "i18next";
import _ from "lodash";

import {
  DocumentsFilterProps,
  FlowCategory,
  FlowField,
  FlowFilterKeys,
  SelectOptionDef,
} from "../../../../types/interfaces";
import CoreTag from "../../../core/CoreTag";
import { AdvancedFiltersDrawer } from "./AdvancedFiltersDrawer";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";

interface Props {
  t: TFunction;
  filters: DocumentsFilterProps[];
  flowFields: FlowField[] | FlowCategory[] | undefined;
  setFilters: (updatedFilters: DocumentsFilterProps[]) => void;
}

export const FiltersMetadataDisplay: FC<Props> = ({
  t,
  filters,
  setFilters,
  flowFields,
}) => {
  const { flowStore } = useStores();
  const notification = useNotification();
  const [focusedFilterIndex, setFocusedFilterIndex] = useState<
    number | undefined
  >();
  const [flowFilterKeys, setFlowFilterKeys] = useState<FlowFilterKeys>();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const flowType = useMemo(() => {
    const flowTypes = _.uniq(flowStore.flows.map((flow) => flow.flowType));
    const allFlowsType = flowTypes.length === 1 ? flowTypes[0] : "";
    return flowStore?.flowSummary?.flowType ?? allFlowsType ?? "";
  }, [flowStore?.flowSummary?.flowType, flowStore.flows]);

  const formattedFilterOptions = useMemo(() => {
    let result: (SelectOptionDef | string)[] = [];
    Object.keys(flowFilterKeys || {})?.map((filterKey) => {
      if (
        Object.values(
          (flowFilterKeys as unknown as FlowFilterKeys)[
            filterKey as "fields" | "metadata" | "categories"
          ]
        ).length > 0
      ) {
        result = [
          ...result,
          {
            key: filterKey,
            value: filterKey.charAt(0).toUpperCase() + filterKey.slice(1),
            label: filterKey.charAt(0).toUpperCase() + filterKey.slice(1),
            type: filterKey,
            isDisabled: true,
          },
          ...(flowFilterKeys?.[
            filterKey as "fields" | "metadata" | "categories"
          ]
            ?.filter((field) =>
              filterKey === "fields" ? field.source === "flowConfig" : field
            )
            ?.map((field) => {
              return {
                key: field.key,
                value: field.name || field.key,
                label: field.name || field.key,
                type: filterKey,
                source: field.source,
              };
            }) as unknown as string[]),
        ];
      }
    });

    const computedFields =
      flowFilterKeys?.fields?.filter(
        (field) => field.source === "enhancement"
      ) || [];

    if (computedFields.length > 0)
      result = [
        ...result,
        {
          key: t("computedFields"),
          value: t("computedFields"),
          label: t("computedFields"),
          type: "fields",
          isDisabled: true,
        },
        ...(computedFields?.map((field) => {
          return {
            key: field.key,
            value: field.name || field.key,
            label: field.name || field.key,
            type: "fields",
            source: field.source,
          };
        }) as unknown as string[]),
      ];

    return result;
  }, [flowFilterKeys, t]);

  const formattedAllFlowsFilterOptions = useMemo(() => {
    let result: (SelectOptionDef | string)[] = [];

    result = [
      ...result,
      ...(flowFields?.map((field) => ({
        key: field.key,
        value: field.key,
        label: field.name,
        type: field.type,
      })) as SelectOptionDef[]),
    ];
    return result;
  }, [flowFields]);

  const handleRemoveMetadata = (position: number) => {
    setFilters(filters?.filter((_, index) => index !== position));
  };

  const onFocus = (focusedIndex: number) => {
    setFocusedFilterIndex(focusedIndex);
    if (flowStore.flowSummary?.identifier) {
      setIsDataLoading(true);
      flowStore
        .loadFlowFilterKeys(
          flowStore.flowSummary?.identifier as unknown as string
        )
        .then((response) => {
          const fields = response.fields.map((res) => ({
            key: res.key,
            name: res.name,
            type: res.type,
            source: flowStore?.flowSummary?.fields?.find(
              (field) => field.key === res.key
            )?.source,
          }));
          setFlowFilterKeys({
            fields: fields,
            metadata: response.metadata,
            categories: response.categories,
          });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "errorFilterKeys"));
        })
        .finally(() => setIsDataLoading(false));
    }
  };

  const handleUpdateMetadata = (updatedMetadata: DocumentsFilterProps) => {
    setFilters(
      filters?.map((item, index) =>
        index === focusedFilterIndex ? updatedMetadata : item
      )
    );
    setFocusedFilterIndex(undefined);
  };

  return (
    <>
      {filters?.map((item, index) => {
        const key = item.field || item.category;

        return (
          <CoreTag
            key={`${key as string}-${index}`}
            variant="outlined"
            showTooltip={true}
            label={`${key || "-"} ${item.operator || "-"} ${
              item.values?.join(",") || "-"
            }`}
            onDelete={() => {
              handleRemoveMetadata(index);
            }}
            onDoubleClick={() => onFocus(index)}
            style={{ maxWidth: 800 }}
          />
        );
      })}

      <AdvancedFiltersDrawer
        translation={t}
        isOpened={focusedFilterIndex !== undefined}
        fieldOptions={
          flowStore.flowSummary?.identifier
            ? (formattedFilterOptions as unknown as SelectOptionDef[])
            : (formattedAllFlowsFilterOptions as unknown as SelectOptionDef[])
        }
        filterForm={
          focusedFilterIndex !== undefined
            ? filters?.[focusedFilterIndex]
            : undefined
        }
        onClose={() => setFocusedFilterIndex(undefined)}
        onConfirm={handleUpdateMetadata}
        showAddFilterButton={true}
        isLoading={isDataLoading}
        flowType={flowType}
      />
    </>
  );
};
