//Module exports
import React, { FC, useState, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

//Design imports
import {
  Box,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Typography,
  SelectChangeEvent,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

//Internal imports
import CoreTooltipInputLabel from "../../../../core/CoreTooltipInputLabel";

import {
  SelectOptionDef,
  StorageOptions,
  FormattedOption,
  Connection,
} from "../../../../../types/interfaces";
import CoreSelect from "../../../../core/CoreSelect";
import { useStores } from "../../../../../stores/StoresProvider";

import { FlowEnum } from "../../../../../types/constants";
import RepositoryFields from "./RepositoryFields";
import CreateConnection from "../../../connections/CreateConnection";

interface Props {
  t: TFunction;
  isEditable: boolean;
  handleInput: (
    event:
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<unknown>
  ) => void;
  flowStorage: StorageOptions | undefined;
  setFlowStorage: (value: StorageOptions | undefined) => void;
  options: FormattedOption[];
  loadingConnections: boolean;
  connections: Connection[];
}

const RepositorySettings: FC<Props> = observer(
  ({
    t,
    isEditable,
    handleInput,
    flowStorage,
    setFlowStorage,
    options,
    loadingConnections,
    connections,
  }) => {
    const { userStore } = useStores();

    const [isOpen, setIsOpen] = useState(false);

    const useStyles = makeStyles({
      mainContainer: {
        maxWidth: "300px",
      },
      title: {
        marginTop: "20px",
        marginBottom: "10px",
      },
      innerForm: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "32px",
      },
      secondInput: { marginTop: "20px" },
      input: {
        marginTop: "5px",
      },
      repoConnection: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
      },
      iconButton: {
        alignSelf: "center",
        marginLeft: "4px",
        marginRight: "-45px",
        visibility: !isEditable ? "hidden" : "visible",
      },
    });

    const classes = useStyles();

    //This determines whether repository fields should be shown
    const repoConnection = useMemo(() => {
      return connections.find(
        (connection) => connection.identifier === flowStorage?.repoConnection
      );
    }, [connections, flowStorage?.repoConnection]);

    const canCreateConnection = userStore.currentUserPermissions?.can(
      "create",
      "connections"
    );

    return (
      <Box className={classes.mainContainer}>
        <Typography className={classes.title}>
          {t("storage_repositorySettings_title")}
        </Typography>

        <FormGroup>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={flowStorage?.customRepo || false}
                  onChange={handleInput}
                  name={FlowEnum.customRepo}
                />
              }
              label={
                <CoreTooltipInputLabel
                  tooltip={t("storage_customRepository_tooltip")}
                  label={t("customRepo")}
                />
              }
            />
          </div>

          <Box className={classes.innerForm}>
            <FormControl variant="standard" className={classes.secondInput}>
              <CoreTooltipInputLabel
                disabled={!flowStorage?.customRepo || !isEditable}
                tooltip={t("storage_repoConnection_tooltip")}
                label={`${t("repoConnection")} *`}
              />

              <Box display="flex" flexDirection="row">
                <CoreSelect
                  placeholder={t("repoConnection_placeholder")}
                  selectInputStyle={classes.repoConnection}
                  options={options as unknown as SelectOptionDef[]}
                  name="repoConnection"
                  value={
                    !loadingConnections ? flowStorage?.repoConnection || "" : ""
                  }
                  disabled={
                    !flowStorage?.customRepo ||
                    loadingConnections ||
                    !isEditable
                  }
                  onChange={handleInput}
                  formControlClass={classes.input}
                />
                {canCreateConnection && (
                  <IconButton
                    onClick={() => setIsOpen(true)}
                    disabled={!flowStorage?.customRepo || !isEditable}
                    className={classes.iconButton}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </Box>
            </FormControl>

            {repoConnection && (
              <RepositoryFields
                repoConnection={repoConnection}
                t={t}
                flowStorage={flowStorage}
                setFlowStorage={setFlowStorage}
                isEditable={isEditable}
              />
            )}
          </Box>
        </FormGroup>

        <CreateConnection
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          isRepoConn={true}
        />
      </Box>
    );
  }
);

export default RepositorySettings;
