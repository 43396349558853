import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react";

import {
  Box,
  Drawer,
  Divider,
  Typography,
  IconButton,
  useTheme,
  DrawerProps,
  alpha,
} from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import LocalStorageHelper from "../../helper/localStorageHelper";
import { LOCAL_STORAGE_KEYS } from "../../types/constants";
import { useStores } from "../../stores/StoresProvider";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onBack?: () => void;
  title?: string;
  customClasses?: Record<string, string>;
  hasBackButton?: boolean;
  hasDivider?: boolean;
  props?: DrawerProps;
  children: React.ReactNode;
  isResizable?: boolean;
  isForNodes?: boolean;
}

type DrawerWidth = {
  drawerWidth: number;
};

const MIN_DRAWER_WIDTH = 300;

const extractDrawerWidth = () => {
  const storageWidth = LocalStorageHelper.getValue<DrawerWidth>(
    LOCAL_STORAGE_KEYS.appSettings,
    { drawerWidth: 0 }
  );

  // If property is not set, return default
  if (!storageWidth || !storageWidth?.drawerWidth) {
    return window.innerWidth * 0.5 - 50;
  }

  // If property exceeds maximum accepted size, return max size
  if (storageWidth?.drawerWidth > window.innerWidth - 50) {
    return window.innerWidth - 50;
  }

  return storageWidth?.drawerWidth;
};

const CoreDrawer: FC<Props> = observer(
  ({
    children,
    isOpen,
    title,
    onClose,
    onBack,
    customClasses,
    hasBackButton = false,
    hasDivider = true,
    props,
    isResizable = true,
    isForNodes = false,
  }) => {
    const { flowSettingsStore } = useStores();
    const theme = useTheme();
    const [width, setWidth] = useState<number>(window.innerWidth * 0.5 - 50);

    const classes = makeStyles({
      container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        padding: "0px 30px 30px 30px",
      },
      drawerTitle: {
        display: "flex",
        justifyContent: "center",
        flex: 1,
        padding: "16px",
      },
      divider: {
        marginBottom: "24px",
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: "none",
        width,
      },
      headerBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 16px",
      },
      arrow: {
        fontSize: 15,
      },
      dragger: {
        width: "10px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: alpha(theme.palette.divider, 0.2),
        "&:hover": {
          opacity: 0.7,
        },
      },
      dragIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      },
    })();

    const handleMouseUp = () => {
      document.removeEventListener("mouseup", handleMouseUp, true);
      document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseDown = () => {
      document.addEventListener("mouseup", handleMouseUp, true);
      document.addEventListener("mousemove", handleMouseMove, true);
    };

    const maxDrawerWidth = useMemo(() => {
      return isForNodes ? window.innerWidth - 410 : window.innerWidth - 50;
    }, [isForNodes]);

    const handleMouseMove = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        if (e.buttons !== 0) {
          const newWidth = document.body.offsetWidth - e.clientX;

          if (newWidth > MIN_DRAWER_WIDTH && newWidth < maxDrawerWidth) {
            setWidth(newWidth);
            LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.appSettings, {
              drawerWidth: newWidth,
            });
          }
        }
      },
      [maxDrawerWidth]
    );

    useEffect(() => {
      if (isOpen && isResizable) {
        const storageWidth = extractDrawerWidth();
        setWidth(storageWidth);
      }
    }, [isOpen, isResizable]);

    const handleClose = (event: React.MouseEvent, reason: string) => {
      if (event && reason === "backdropClick") {
        return;
      }
      onClose();
    };

    const isDrawerResizable = useMemo(() => {
      return isResizable && !flowSettingsStore.isSuggestionsModalOpen;
    }, [isResizable, flowSettingsStore.isSuggestionsModalOpen]);

    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        {...props}
      >
        {isDrawerResizable && (
          <div
            onMouseDown={() => handleMouseDown()}
            className={classes.dragger}
          >
            <div className={classes.dragIcon}>
              <MenuIcon style={{ fontSize: 10 }} />
            </div>
          </div>
        )}

        <Box className={classes.headerBox}>
          <IconButton
            style={{
              visibility: hasBackButton && onBack ? "visible" : "hidden",
            }}
            onClick={onBack}
          >
            <ArrowBackIosNew className={classes.arrow} />
          </IconButton>

          {title && (
            <>
              <Typography variant="h6" className={classes.drawerTitle}>
                {title}
              </Typography>
            </>
          )}
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        {hasDivider && <Divider className={classes.divider} />}
        <Box className={customClasses?.container ?? classes.container}>
          {children}
        </Box>
      </Drawer>
    );
  }
);

export default CoreDrawer;
