import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";

import CoreTabContainer from "../../../../core/CoreTabContainer";
import CoreTable from "../../../../core/CoreTable";
import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import { AppSuspense } from "../../../../main/AppSuspense";
import { FlowDiagram, TableContentDef } from "../../../../../types/interfaces";
import { useNotification } from "../../../../../context/useNotification";
import { VersionDisplayCell } from "../versions/VersionDisplayCell";
import DateHelper from "../../../../../helper/dateHelper";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
}

const TABLE_HEADER_KEYS = {
  identifier: "versions_table_identifier",
  version: "versions_table_version",
  createdAt: "versions_table_createdAt",
  createdBy: "versions_table_createdBy",
} as { [key: string]: string };

export const VersionsTab: FC<Props> = ({ t, flowSettingsStore }) => {
  const { id } = useParams<{ id: string }>();
  const notifier = useNotification();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [versions, setVersions] = useState<FlowDiagram[]>([]);

  const tableHeaders = useMemo(
    () =>
      Object.keys(TABLE_HEADER_KEYS).map((headerKey) => ({
        label: t(TABLE_HEADER_KEYS[headerKey]),
        accessor: headerKey,
      })),
    [t]
  );

  const tableRows = useMemo(
    () =>
      versions?.length > 0
        ? versions
            .sort((a, b) => b.version - a.version)
            .map((version) => ({
              ...version,
              version: (
                <VersionDisplayCell
                  version={version.version}
                  isCurrent={version.current}
                  t={t}
                />
              ),
              createdAt: version.createdAt
                ? DateHelper.timeStringFormat(version.createdAt)
                : "-",
              createdBy: version?.user?.first_name
                ? `[${version?.user?.first_name} ${version?.user?.last_name}]`
                : "-",
            }))
        : [],
    [versions, t]
  );

  useEffect(() => {
    flowSettingsStore
      .getFlowDiagrams(id)
      .then((versions) => {
        if (versions?.length > 0) {
          setVersions(versions);
        }
        setIsDataLoading(false);
      })
      .catch((error: Error) => {
        setIsDataLoading(false);
        notifier.error(error?.message || t("flowVersionsFetchFail"));
      });
  }, [flowSettingsStore, notifier, t, id]);

  return (
    <CoreTabContainer t={t} title={t(flowSettingsStore.selectedTab || "")}>
      {isDataLoading || flowSettingsStore.loadingFlowSettings ? (
        <AppSuspense />
      ) : (
        <CoreTable
          headers={tableHeaders}
          data={tableRows as unknown as TableContentDef[]}
          isPaginated={false}
        />
      )}
    </CoreTabContainer>
  );
};
