import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import { AppSuspense } from "../../main/AppSuspense";
import CorePageContainer from "../../core/CorePageContainer";
import { useNotification } from "../../../context/useNotification";
import IndexingSettingsHeaderActions from "./IndexingSettingsHeaderActions";
import { SETTINGS_TYPES } from "../../../types/constants";
import IndexingSettingsConfig from "./IndexingSettingsConfig";
import {
  AssetTemplateConfiguration,
  FormData,
} from "../../../types/interfaces";
import { useStores } from "../../../stores/StoresProvider";

const useStyles = makeStyles({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "10px",
  },
});

const IndexingSettings: FC = observer(() => {
  const { t, ready } = useTranslation("settings");
  const { userStore, flowStore } = useStores();
  const notification = useNotification();
  const classes = useStyles();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);

  const [assetConfig, setAssetConfig] = useState<AssetTemplateConfiguration>();
  const [formData, setFormData] = useState<FormData>({});
  const [originalForm, setOriginalForm] = useState<FormData>({});
  const [fieldsErrors, setFieldsErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    Promise.all([fetchContextSetting(), fetchAssetConfig()])
      .then(() => {
        setIsPageLoading(false);
      })
      .catch((error: Error) => {
        notification.error(error.message);
        setIsPageLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContextSetting = () => {
    //TODO: Remove array check after python fix
    return userStore
      .getContextSetting(SETTINGS_TYPES.indexing)
      .then((settings) => {
        if (settings && !Array.isArray(settings)) {
          setFormData(settings.parameters || {});
          setOriginalForm(settings.parameters || {});
        }
      });
  };

  const fetchAssetConfig = () => {
    return flowStore
      .getAssetConfiguration(SETTINGS_TYPES.indexingSettings)
      .then((assetConfig) => {
        setAssetConfig(assetConfig.getAssetConfig);
      });
  };

  const refreshAssetConfig = () => {
    setIsPageLoading(true);

    fetchAssetConfig()
      .then(() => {
        setIsPageLoading(false);
      })
      .catch(() => {
        setIsPageLoading(false);
        // TODO: show notifier
      });
  };

  const onCancelEdit = () => {
    setIsEditable(false);
    setFormData(originalForm);
    setFieldsErrors({});
  };

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <CorePageContainer
      isNotFlowPage
      fixExtraHeaderContent
      label={t("indexing")}
      extraHeaderContent={
        isPageLoading ? (
          <></>
        ) : (
          <IndexingSettingsHeaderActions
            isEditable={isEditable}
            formData={formData}
            assetConfig={assetConfig as AssetTemplateConfiguration}
            onCancel={onCancelEdit}
            onToggleEditMode={(state: boolean) => setIsEditable(state)}
            setFormErrors={(errors) => setFieldsErrors(errors)}
          />
        )
      }
    >
      {isPageLoading ? (
        <AppSuspense />
      ) : (
        <Box className={classes.mainBox}>
          <IndexingSettingsConfig
            t={t}
            isEditable={isEditable}
            formData={formData}
            fieldsErrors={fieldsErrors}
            assetConfig={assetConfig as AssetTemplateConfiguration}
            setFormData={setFormData}
            refreshAssetConfig={refreshAssetConfig}
          />
        </Box>
      )}
    </CorePageContainer>
  );
});

export default IndexingSettings;
