import React, { FC } from "react";
import { observer } from "mobx-react";
import { isArray, uniqBy } from "lodash";

import { makeStyles } from "@mui/styles";
import { Box, SelectChangeEvent } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import {
  DocumentCategory,
  FlowCategory,
} from "../../../../../types/interfaces";
import CoreSelect from "../../../../core/CoreSelect";
import { useStores } from "../../../../../stores/StoresProvider";
import CoreTooltip from "../../../../core/CoreTooltip";

interface Props {
  categories: FlowCategory[] | undefined;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  box: {
    padding: "15px",
  },
  warningIcon: {
    marginLeft: "10px",
    fontSize: 20,
  },
  select: { marginBottom: "10px", width: "100%" },
});

const CanvasCategories: FC<Props> = observer(({ categories, isDisabled }) => {
  const classes = useStyles();
  const { documentStore } = useStores();
  const handleChangeOptions = (event: SelectChangeEvent<unknown>) => {
    const { name, value } = event.target as HTMLInputElement;

    const docCategories = [...documentStore.documentCategories];

    const getNewResults = (category: DocumentCategory) => {
      if (category.multipleSelect) {
        return (value as unknown as string[]).map((item) => ({
          key: item,
          label: item,
          score: 1,
        }));
      }
      return [
        {
          key: value,
          label: value,
          score: 1,
        },
      ];
    };

    if (!docCategories.find((category) => category.type === name)) {
      docCategories.push({
        type: name,
        manuallyAdded: true,
        multipleSelect: isArray(value),
        pageIdentifier: documentStore.documentPage?.identifier ?? "",
        results: [],
      });
    }

    const updatedCategories = docCategories.map((category) => {
      if (category.type === name) {
        return {
          ...category,
          manuallyAdded: true,
          results: getNewResults(category),
        };
      }
      return category;
    });
    documentStore.setDocumentCategories(updatedCategories);
  };

  const getValue = (category: FlowCategory) => {
    const result = documentStore?.documentCategories.find(
      (item) => item.type === category.key
    )?.results;

    if (category.multipleSelect) {
      if (!result) return [];
      return result?.map((item) => item.key);
    }
    return result?.[0].key ?? "";
  };

  const errors = documentStore.document?.status.details.categories;

  const getOptions = (category: FlowCategory) => {
    const results = documentStore.documentCategories?.flatMap(
      (docCategory) => docCategory.results
    );

    const labels = category.labels.map((label) => {
      return {
        key: label.key,
        label: label.key,
        score: results.find((item) => item.key === label.key)?.score ?? 0,
      };
    });

    const result = uniqBy(
      labels.flatMap((item) => item),
      "key"
    );

    return result;
  };

  return (
    <Box className={classes.box}>
      {categories?.map((category) => {
        const categoryOptions = getOptions(category);
        return (
          <CoreSelect
            MenuProps={{
              marginThreshold: 0,
            }}
            titleIcon={
              errors?.[category.key] && (
                <CoreTooltip title={errors[category.key]} placement="right">
                  <WarningIcon
                    color="warning"
                    className={classes.warningIcon}
                  />
                </CoreTooltip>
              )
            }
            className={classes.select}
            key={category.key}
            name={category.key}
            description={category.description}
            title={category.name}
            isMultipleSelect={category.multipleSelect}
            options={categoryOptions}
            onChange={handleChangeOptions}
            value={getValue(category)}
            disabled={isDisabled}
          />
        );
      })}
    </Box>
  );
});

export default CanvasCategories;
