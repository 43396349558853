import React, { FC, memo, useMemo } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";

import { Box, Theme, Typography, useTheme } from "@mui/material";

import { DocumentStore } from "../../../../../stores/DocumentStore";

import { SelectOptionDef } from "../../../../../types/interfaces";
import LineItemFieldActions from "./LineItemFieldActions";
import CanvasField from "./CanvasField";
import CanvasLineItem from "./CanvasLineItem";

interface Props {
  documentStore: DocumentStore;
  category: SelectOptionDef;
  translation: TFunction;
  theme: Theme;
  showEmpty: boolean;
}

const ZoneCategoryGroup: FC<Props> = observer(
  ({ documentStore, category, translation, showEmpty }) => {
    const theme = useTheme();
    const classes = makeStyles({
      container: {
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        gap: "0.25rem",
        marginTop: "10px",
      },
      fieldCategoryTitle: {
        opacity: 0.7,
        fontSize: "15px",
        paddingLeft: "1rem",
        textAlign: "right",
      },
      lineItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      },
      icon: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0",
        marginTop: "-5px",
      },
      iconButton: {
        color: theme.palette.success.dark,
      },
    })();
    const { documentFields, focusedFieldCanvas } = documentStore;

    const fields = useMemo(() => {
      // eslint-disable-next-line
      return documentStore.fields;
    }, [documentStore.fields]);

    //TODO: FIX THIS
    // const filledCategories = useMemo(
    //   () =>
    //     categoryFields.flatMap((field) =>
    //       documentFields?.find((item) => item.type === field.key) ? field : []
    //     ),
    //   [categoryFields, documentFields]
    // );

    if (
      showEmpty
      //  &&
      // filledCategories.length === categoryFields.length &&
      // !filledCategories.find((field) => field.key === focusedFieldCanvas)
    ) {
      return null;
    }

    const errors = documentStore.document?.status?.details?.fields;

    // TODO: Reduce rendering further
    return (
      <div className={classes.container}>
        <Typography className={classes.fieldCategoryTitle}>
          {category.label}
        </Typography>

        {fields?.length > 0 &&
          fields?.map((field) => {
            // Get linked zone coords
            const docField = documentFields?.find(
              (item) => item.type === field.key
            );

            const isTextInvalid =
              !docField || !docField.text || docField.text?.trim() === "";

            const isFieldFocused = field.key === focusedFieldCanvas;

            if (
              showEmpty &&
              !isTextInvalid &&
              !isFieldFocused
              // ||
              // field.isVisible === false
            ) {
              return null;
            }

            return (
              <CanvasField
                key={field.key}
                field={field}
                translation={translation}
                error={errors?.[field.key]}
              />
            );
          })}

        {documentStore.lineItems &&
          documentStore.lineItems?.length > 0 &&
          documentStore.lineItems?.map((field) => {
            //TODO: Fix this, check for type/key
            const actionBoxVisible = focusedFieldCanvas === field.type;

            return (
              <Box key={field.type} className={classes.lineItem}>
                <CanvasLineItem
                  documentStore={documentStore}
                  translation={translation}
                  field={field}
                />
                {actionBoxVisible && (
                  <Box className={classes.icon}>
                    <LineItemFieldActions
                      documentStore={documentStore}
                      translation={translation}
                      field={field}
                      iconButtonClass={classes.iconButton}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
      </div>
    );
  }
);

export default memo(ZoneCategoryGroup);
