import React, { FC, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { ApolloClient } from "@apollo/client";

import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Radio,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { MessageOutlined } from "@mui/icons-material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { useNotification } from "../../../../../context/useNotification";
import { RejectionInterface } from "../../../../../types/interfaces";
import { RejectDocumentIcon } from "../../../../../icons/RejectDocumentIcon";
import CoreTooltip from "../../../../core/CoreTooltip";
import CoreButton from "../../../../core/CoreButton";
import AddReason from "../../../addNewFlow/tabs/fieldsSetup/rejectReason/canvasModal/AddReason";
import { appRoutes } from "../../../../../configs/routes";
import { useStores } from "../../../../../stores/StoresProvider";

interface Props {
  open: boolean;
  client: ApolloClient<unknown>;
}

const DocumentRejectPopup: FC<Props> = observer(({ open, client }) => {
  const { flowStore, documentStore, userStore } = useStores();

  const theme = useTheme();
  const { t } = useTranslation("flow");
  const history = useHistory();
  const notification = useNotification();
  const [selectedItem, setSelectedItem] = useState<RejectionInterface | null>(
    null
  );
  const { flowId } = useParams<{ flowId: string }>();

  useEffect(() => {
    if (open) {
      getRejections();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const classes = makeStyles({
    title: {
      fontWeight: "bolder",
      fontSize: "18px",
    },
    actions: {
      "&.MuiDialogActions-root": {
        padding: "1rem",
      },
    },
    content: {
      "&.MuiDialogContent-root": {
        alignSelf: "stretch",
      },
      padding: "20px 40px",
    },
    dialogPaper: {
      "& .MuiDialog-paper": {
        padding: 0,
      },
    },
    divider: {
      marginRight: "2.5rem",
      marginLeft: "2.5rem",
    },
    optionContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "left",
      gap: "10px",
      cursor: "pointer",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      maxHeight: "20rem",
      overflow: "auto",
      height: "250px",
    },
    radio: {
      "&.Mui-checked": {
        color: theme.palette.highlight.main,
      },
    },
    loadingContainer: {
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      overflow: "hidden",
      width: "100%",
    },
    noData: {
      margin: "20px",
      textAlign: "center",
    },
    message: {
      marginLeft: "auto",
    },
    addButton: {
      marginLeft: "auto",
    },
  })();

  const handleConfirm = () => {
    const reason = flowStore.rejections.find(
      (item) => item.code === selectedItem?.code
    );

    if (reason) {
      documentStore
        .rejectDocument(client, Number(reason?.code), reason?.message)
        .then(() => {
          if (documentStore.isGetNextDocumentChecked) {
            documentStore
              .loadDocument(
                client,
                undefined,
                undefined,
                flowId,
                documentStore.document?.identifier ?? ""
              )
              .then((hasNextDoc) => {
                if (hasNextDoc)
                  history.replace(
                    appRoutes.DocumentView(
                      flowId,
                      documentStore.document?.identifier
                    )
                  );
                else {
                  history.push(appRoutes.FlowDetails(flowId));
                  notification.info(t("noMoreDocs"));
                }
              })
              .catch((error: Error) => {
                history.push(appRoutes.RetryNextDocument(flowId));
                notification.error(t(error?.message || "errorGettingNextDoc"));
              });
          } else {
            history.push(appRoutes.FlowDetails(flowId));
          }
        })
        .catch((error: Error) =>
          notification.error(t(error?.message || "errorDocumentReject"))
        )
        .finally(() => documentStore.setIsRejectReasonsPopupOpen(false));
    }
  };

  const closeReasonModal = () => {
    flowStore.toggleRejectionModal(null);
  };

  const openReasonModal = () => {
    flowStore.toggleRejectionModal("add");
  };

  const handleClose = () => {
    documentStore.setIsRejectReasonsPopupOpen(false);
    setSelectedItem(null);
  };

  const getRejections = () => {
    flowStore
      .getAllRejections(client)
      .then(() => {
        if (
          flowStore.flow?.rejectReasons &&
          flowStore.flow?.rejectReasons?.length > 0
        ) {
          setSelectedItem(flowStore.flow?.rejectReasons[0]);
        }
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "fetchReasonsError"));
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        open={documentStore.isRejectReasonsPopupOpen}
        onClose={handleClose}
        className={classes.dialogPaper}
      >
        <DialogTitle id="alert-dialog-title">
          <Box className={classes.optionContainer}>
            <RejectDocumentIcon />

            <Typography className={classes.title}>
              {t("selectReason_title")}
            </Typography>
            {userStore.currentUserPermissions?.can("update", "flows") && (
              <CoreTooltip title={`${t("addReason_tooltip")}`}>
                <IconButton
                  className={classes.addButton}
                  onClick={openReasonModal}
                >
                  <AddIcon />
                </IconButton>
              </CoreTooltip>
            )}
          </Box>
        </DialogTitle>

        <Divider className={classes.divider} />

        <DialogContent className={classes.content}>
          <Box className={classes.mainContainer}>
            <Typography>{t("selectReason_content")}</Typography>
            {flowStore.loadingRejections ? (
              <Box className={classes.loadingContainer}>
                <CircularProgress size="30px" />
              </Box>
            ) : flowStore.rejections.length > 0 ? (
              <List>
                {(flowStore.rejections ?? []).map((item, index) => (
                  <ListItem
                    key={index}
                    className={classes.optionContainer}
                    onClick={() =>
                      !documentStore.updatingPage && setSelectedItem(item)
                    }
                    disabled={documentStore.updatingPage}
                  >
                    <Radio
                      checked={selectedItem?.code === item.code}
                      value={item.code}
                      className={classes.radio}
                      disabled={documentStore.updatingPage}
                    />
                    <Typography>{t(item.message)} </Typography>
                    <IconButton className={classes.message}>
                      <MessageOutlined />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box className={classes.noData}>{t("noReasons")}</Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <CoreButton
            onClick={handleClose}
            variant="neutral"
            disabled={documentStore.updatingPage}
          >
            {t("cancel")}
          </CoreButton>

          <CoreButton
            autoFocus
            onClick={handleConfirm}
            variant="contained"
            disabled={!selectedItem || documentStore.updatingPage}
            isLoading={documentStore.updatingPage}
          >
            {t("confirm")}
          </CoreButton>
        </DialogActions>
      </Dialog>
      <AddReason closeModal={closeReasonModal} />
    </>
  );
});

export default DocumentRejectPopup;
