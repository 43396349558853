import { gql } from "@apollo/client";

export interface MUTATION_GENERATE_API_KEY_RESPONSE {
  generateApiKey: string;
}

export interface MUTATION_DELETE_API_KEY_RESPONSE {
  deleteApiKey: string;
}

export interface MUTATION_ACTIVATE_API_KEY_RESPONSE {
  activateApiKey: string;
}

export interface MUTATION_DEACTIVATE_API_KEY_RESPONSE {
  deactivateApiKey: string;
}

export const GENERATE_APY_KEY = gql`
  mutation generateApiKey($active: Boolean) {
    generateApiKey(active: $active)
  }
`;

export const DELETE_API_KEY = gql`
  mutation deleteApiKey($identifier: String) {
    deleteApiKey(identifier: $identifier)
  }
`;

export const ACTIVATE_API_KEY = gql`
  mutation activateApiKey($identifier: String) {
    activateApiKey(identifier: $identifier)
  }
`;

export const DEACTIVATE_API_KEY = gql`
  mutation deactivateApiKey($identifier: String) {
    deactivateApiKey(identifier: $identifier)
  }
`;
