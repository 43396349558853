import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import { useStores } from "../../../stores/StoresProvider";
import { DocumentsTable } from "./table/DocumentsTable";
import FilterBar from "./filters/FilterBar";
import FiltersDisplay from "./filters/FiltersDisplay";
import { useNotification } from "../../../context/useNotification";
import {
  DEFAULT_FILTER_ALL_FLOWS,
  DEFAULT_FILTER_FLOW,
  LOCAL_STORAGE_KEYS,
} from "../../../types/constants";
import {
  Document,
  DocumentsFilterProps,
  Flow,
} from "../../../types/interfaces";
import { appRoutes } from "../../../configs/routes";
import { FiltersMetadataDisplay } from "./filters/FiltersMetadataDisplay";
import AdvancedFilterHelper from "../../../helper/flowHelper";
import LocalStorageHelper from "../../../helper/localStorageHelper";
import FlowDetailsWrapper from "./FlowDetailsWrapper";
import { DocumentPreviewDrawer } from "./table/DocumentPreviewDrawer";
import ChatGptActionComponent from "./chat-gpt/ChatGptActionComponent";

const DEFAULT_TABLE_HEADER_KEYS = {
  all: ["flowName", "name", "createdAt", "updatedAt", "status"],
  flow: ["name", "numberOfPages", "createdAt", "updatedAt", "status"],
};

const useStyles = makeStyles({
  container: {
    marginTop: "10px",
    gap: "10px",
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "20px",
  },
});

type FlowFilters = {
  tableHeaders: string[];
  sortBy: string;
  sortDirection: string;
};

const FlowDetails: FC = observer(() => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const notification = useNotification();
  const { t, ready } = useTranslation("flow");
  const { ready: addFlowReady } = useTranslation("addFlow");
  const { flowStore, documentStore, flowSettingsStore } = useStores();

  const classes = useStyles();

  const [paginationFilters, setPaginationFilters] = useState({
    currentPage: 1,
    rowsPerPage: 10,
  });

  const defaultFilter = useMemo(
    () => (id ? DEFAULT_FILTER_FLOW : DEFAULT_FILTER_ALL_FLOWS),
    [id]
  );

  const flowType = useMemo(() => {
    const flowTypes = _.uniq(flowStore.flows.map((flow) => flow.flowType));
    const allFlowsType = flowTypes.length === 1 ? flowTypes[0] : "";
    return flowStore?.flowSummary?.flowType ?? allFlowsType ?? "";
  }, [flowStore?.flowSummary?.flowType, flowStore.flows]);

  const setLocalStorageFilters = useCallback(() => {
    const flowStorageKey = id || "All flows";
    const storageKey = id ? `current-${id}` : "current-All flows";

    const storeFlowFilters = LocalStorageHelper.getValue<string[]>(
      flowStorageKey,
      []
    ) as unknown as FlowFilters;

    const storageData = LocalStorageHelper.getValue<{
      selectedDocuments: Document[];
      currentPage: number;
    }>(storageKey, {
      currentPage: 1,
      selectedDocuments: [],
    });

    const storageItemsPerPage = LocalStorageHelper.getValue<{
      rowsPerPage: number;
    }>(LOCAL_STORAGE_KEYS.flowSettings, {
      rowsPerPage: 10,
    });

    // Clear currentPage for further actions
    LocalStorageHelper.setValue(storageKey, { ...storageData, currentPage: 1 });

    if (typeof storageData?.selectedDocuments !== "undefined") {
      documentStore.checkDocuments(storageData?.selectedDocuments || []);
    }

    setPaginationFilters({
      currentPage: storageData?.currentPage,
      rowsPerPage: storageItemsPerPage?.rowsPerPage,
    });

    const defaultHeaderKeys = id
      ? DEFAULT_TABLE_HEADER_KEYS.flow
      : DEFAULT_TABLE_HEADER_KEYS.all;

    if (storeFlowFilters.tableHeaders?.length > 0) {
      documentStore.setTableFilters(
        storeFlowFilters.tableHeaders,
        storeFlowFilters.sortBy,
        storeFlowFilters.sortDirection
      );
    } else {
      documentStore.setTableFilters(defaultHeaderKeys, "createdAt", "desc");
    }

    applyFilters(storageData?.currentPage, storageItemsPerPage?.rowsPerPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore, id]);

  // Used for fetching data first time, setting localStorage data
  useEffect(() => {
    if (ready && addFlowReady) {
      if (id) {
        flowStore
          .loadFlowSummary(id)
          .then(() => setLocalStorageFilters())
          .catch((error: Error) => {
            history.replace(appRoutes.Flows());
            notification.error(t(error?.message || "flowNotFound"));
          });
      } else {
        flowStore
          .getAllFlows(true)
          .then(() => setLocalStorageFilters())
          .catch((error: Error) => {
            notification.error(t(error?.message || "fetchFlowsError"));
          });
      }
    }

    return () => {
      if (id) {
        documentStore.resetCurrentFlowDocuments();
        flowStore.resetCurrentFlow();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addFlowReady, ready, setLocalStorageFilters, id]);

  // Clear data
  useEffect(() => {
    return () => {
      documentStore.uncheckAllDocuments();
      documentStore.setLastSeenDocument(null);
      documentStore.resetCurrentFlowDocuments();
      flowSettingsStore.setDuplicateFlowConfig(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilters = (filterKey: string, value: unknown) => {
    if (
      filterKey === "fields" ||
      filterKey === "categories" ||
      filterKey === "metadata"
    ) {
      flowStore.setFiltersValues({
        ...flowStore?.filters?.flowFilters,
        [filterKey]: (value as DocumentsFilterProps[])
          ?.filter((item) => item?.type === filterKey)
          .map((item) => {
            if (flowType === "classification") {
              if (item.type === "categories") {
                return {
                  category: item.category,
                  operator: item.operator,
                  values: item.values,
                  type: item.type,
                };
              } else {
                return {
                  field: item.category || item.field,
                  operator: item.operator,
                  values: item.values,
                  type: item.type,
                };
              }
            } else
              return {
                field: item.field,
                operator: item.operator,
                values: item.values,
                type: item.type,
              };
          }),
      });
    } else {
      flowStore.setFiltersValues({
        ...flowStore?.filters?.flowFilters,
        [filterKey]: value,
      });
    }
    documentStore.checkDocuments([]);
    setPaginationFilters({ ...paginationFilters, currentPage: 1 });
    applyFilters(1);
  };

  const setBulkFilters = (
    updatedFilters: DocumentsFilterProps,
    resetPage?: boolean
  ) => {
    if (resetPage) {
      setPaginationFilters({ ...paginationFilters, currentPage: 1 });
    }
    flowStore.setFiltersValues(updatedFilters);
    applyFilters(resetPage ? 1 : undefined);
  };

  const resetFilters = (newFilter: DocumentsFilterProps) => {
    flowStore.setFiltersValues(newFilter);
    documentStore.checkDocuments([]);
    setPaginationFilters({ ...paginationFilters, currentPage: 1 });
    applyFilters(1);
  };

  const metadataFilters = useMemo(() => {
    const fields =
      (flowStore.filters?.flowFilters?.fields?.map((field) => ({
        ...field,
        type: "fields",
        source:
          flowStore?.flowSummary?.fields?.find(
            (item) => item.key === field.field
          )?.source || "",
      })) as unknown as DocumentsFilterProps[]) || [];

    const categories =
      (flowStore.filters?.flowFilters?.categories?.map((category) => ({
        ...category,
        type: "categories",
      })) as unknown as DocumentsFilterProps[]) || [];

    const metadata =
      (flowStore.filters?.flowFilters?.metadata?.map((metadata) => ({
        ...metadata,
        type: "metadata",
      })) as unknown as DocumentsFilterProps[]) || [];

    const dataRef =
      flowType === "classification"
        ? [...categories, ...metadata]
        : [...fields, ...metadata];

    return dataRef || [];
  }, [
    flowStore.filters?.flowFilters?.categories,
    flowStore.filters?.flowFilters?.fields,
    flowStore.filters?.flowFilters?.metadata,
    flowStore?.flowSummary?.fields,
    flowType,
  ]);

  const flowFieldOptions = useMemo(() => {
    let filteredFlows = [] as Flow[];

    if (id) {
      if (flowStore.flowSummary) {
        filteredFlows = [flowStore.flowSummary as unknown as Flow];
      }
    } else if (
      flowStore.filters?.flowFilters?.flows &&
      flowStore.filters?.flowFilters.flows.length > 0
    ) {
      filteredFlows = flowStore.flows.filter((flow) =>
        flowStore.filters?.flowFilters?.flows?.includes(flow.identifier)
      );
    } else {
      filteredFlows = flowStore.flows || [];
    }

    return flowType === "classification"
      ? AdvancedFilterHelper.extractCommonCategories(filteredFlows)
      : AdvancedFilterHelper.extractCommonFields(filteredFlows);
  }, [
    flowStore.filters?.flowFilters?.flows,
    flowStore.flowSummary,
    flowStore.flows,
    flowType,
    id,
  ]);

  const applyFilters = (
    newPage = paginationFilters?.currentPage,
    newRowsPerPage = paginationFilters?.rowsPerPage
  ) => {
    const promiseFunction = id
      ? documentStore.loadFlowDocuments(id, newPage, newRowsPerPage)
      : documentStore.getAllDocuments(newPage, newRowsPerPage);

    promiseFunction.catch((error: Error) => {
      notification.error(t(error?.message || "documents_fetch_error"));
    });
  };

  const setCurrentPage = (currentPage: number) => {
    setPaginationFilters({ ...paginationFilters, currentPage });
    applyFilters(currentPage);
  };

  const setRowsPerPage = (rowsPerPage: number) => {
    setPaginationFilters({ rowsPerPage, currentPage: 1 });
    applyFilters(1, rowsPerPage);

    // Store rowsPerPage only in localStorage
    LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.flowSettings, {
      rowsPerPage,
    });
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowStore.refetchForChatGptFilters]);

  return (
    <ChatGptActionComponent>
      <FlowDetailsWrapper refreshData={() => applyFilters()}>
        <Box>
          <FilterBar
            t={t}
            filters={flowStore.filters?.flowFilters ?? {}}
            setFilters={setFilters}
            resetFilters={resetFilters}
            page={paginationFilters?.currentPage ?? 1}
            id={id ?? ""}
            defaultFilter={defaultFilter}
            flowFieldOptions={flowFieldOptions}
          />

          <Box className={classes.container}>
            <FiltersDisplay
              t={t}
              filters={flowStore.filters?.flowFilters ?? {}}
              setFilters={setFilters}
            />

            <FiltersMetadataDisplay
              t={t}
              flowFields={flowFieldOptions}
              filters={metadataFilters as unknown as DocumentsFilterProps[]}
              setFilters={(updatedFilters) =>
                setFilters(
                  metadataFilters?.find(
                    (filter) => !updatedFilters?.includes(filter)
                  )?.type as unknown as string,
                  updatedFilters
                )
              }
            />
          </Box>

          <DocumentsTable
            t={t}
            flowId={id ?? ""}
            setCurrentPage={setCurrentPage}
            page={paginationFilters?.currentPage ?? 1}
            rowsPerPage={paginationFilters?.rowsPerPage}
            setRowsPerPage={(rowsPerPage) =>
              setRowsPerPage(rowsPerPage as number)
            }
            setFilters={setBulkFilters}
            refreshData={() => applyFilters()}
          />
        </Box>

        <DocumentPreviewDrawer t={t} />
      </FlowDetailsWrapper>
    </ChatGptActionComponent>
  );
});

export default FlowDetails;
