import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";
import { THEME_MODES } from "../../types/constants";

export const initMuiChip = (theme: ThemeOptions): Components["MuiChip"] => {
  const palette = theme.palette as ThemePalette;
  const isLightMode = theme.palette?.mode === THEME_MODES.light;

  return {
    styleOverrides: {
      root: {
        background: isLightMode
          ? palette.secondary.light
          : palette.neutral.dark,
        "& .MuiChip-label": {
          fontSize: 14,
        },
      },
    },
  };
};
