import React, { FC, useState } from "react";
import { TFunction } from "i18next";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Menu, useTheme } from "@mui/material";
import AccountMenu from "./AccountMenu";
import { THEME_MODES } from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";

interface Props {
  t: TFunction;
}

const SidebarAccountClosed: FC<Props> = ({ t }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const useStyle = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
    },
    paper: {
      padding: "10px",
      background: "none",
      backgroundColor:
        currentTheme === THEME_MODES.dark
          ? theme.palette.secondary.main
          : theme.palette.secondary.light,
    },
    list: {
      width: "250px",
      padding: "0px",
    },
    iconButton: {
      margin: "0 0 0 -12px",
    },
  });

  const classes = useStyle();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.mainContainer}>
      <IconButton onClick={handleClick} className={classes.iconButton}>
        <AccountBoxIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
      >
        <Box>
          <AccountMenu t={t} handleCloseMenu={handleClose} />
        </Box>
      </Menu>
    </Box>
  );
};

export default SidebarAccountClosed;
