import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import {
  Menu,
  useTheme,
  Box,
  IconButton,
  MenuList,
  MenuItem,
} from "@mui/material";
import Flag from "react-flagkit";
import { useStores } from "../../../../stores/StoresProvider";
import { THEME_MODES } from "../../../../types/constants";

const ChatGptLanguageSelector: React.FC = observer(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const { t } = useTranslation("chatGpt");
  const theme = useTheme();
  const {
    mainStore,
    chatGptStore: {
      chatLanguages,
      setSelectedChatLanguage,
      selectedChatLanguage,
    },
  } = useStores();
  const { currentTheme } = mainStore;

  const useStyle = makeStyles({
    listItem: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    selectedLanguage: {
      backgroundColor:
        mainStore.currentTheme === THEME_MODES.dark
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
    },
    paper: {
      background: "none",
      backgroundColor:
        currentTheme === THEME_MODES.dark
          ? theme.palette.secondary.main
          : theme.palette.secondary.light,
    },
    list: {
      width: "150px",
      padding: "0px",
    },
    flag: {
      width: "20px",
      height: "20px",
    },
    flagBox: {
      display: "flex",
      flex: 1,
    },
    menuText: {
      fontSize: "14px",
      display: "flex",
      flex: 1,
    },
  });

  const classes = useStyle();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGBlanguage = (countryCode: string) => {
    if (countryCode === "en") {
      return "GB";
    }
    return countryCode.toUpperCase();
  };

  const changeLanguage = (countryCode: string) => {
    setSelectedChatLanguage(countryCode);
    handleClose();
  };

  const countryCode = useMemo(() => {
    if (selectedChatLanguage === "en") {
      return "GB";
    }
    return selectedChatLanguage.toUpperCase();
  }, [selectedChatLanguage]);

  return (
    <Box>
      <IconButton edge="start" aria-label="menu" onClick={handleClick}>
        <Flag country={countryCode} className={classes.flag} />
      </IconButton>

      {chatLanguages && chatLanguages?.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleClose}
          classes={{
            paper: classes.paper,
            list: classes.list,
          }}
        >
          <MenuList>
            {chatLanguages?.map((country, index) => {
              const languageCode = handleGBlanguage(country?.key);
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    changeLanguage(country.key);
                  }}
                  className={`
                ${
                  countryCode === languageCode
                    ? `${classes.selectedLanguage} `
                    : ""
                }${classes.listItem}
              `}
                >
                  <Box className={classes.flagBox}>
                    <Flag country={languageCode} className={classes.flag} />
                  </Box>

                  <span className={classes.menuText}>{t(country.name)}</span>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
});

export default ChatGptLanguageSelector;
