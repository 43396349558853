import { gql } from "@apollo/client";

import {
  ContextEvent,
  ContextEventLogStackTrace,
} from "../../types/interfaces/contextEvent";

export const GET_CONTEXT_EVENTS = gql`
  query getContextEvents(
    $page: Int!
    $pageSize: Int!
    $sortBy: String
    $sortDirection: String
    $filters: JSON
  ) {
    getContextEvents(
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      filters: $filters
    ) {
      totalEvents
      amountOfPages
      currentPage
      events {
        eventType
        eventIdentifier
        status
        time
        eventData
      }
    }
  }
`;

export const GET_CONTEXT_EVENT = gql`
  query getContextEvent($id: String!, $page: Int!, $pageSize: Int!) {
    getContextEvent(id: $id, page: $page, pageSize: $pageSize) {
      eventIdentifier
      eventType
      eventData
      status
      time
      logs
      totalLogs
      amountOfPages
      currentPage
    }
  }
`;

export const GET_CONTEXT_EVENT_LOG_STACKTRACE = gql`
  query getContextEventLogStackTrace(
    $eventIdentifier: String!
    $logIdentifier: String!
  ) {
    getContextEventLogStackTrace(
      eventIdentifier: $eventIdentifier
      logIdentifier: $logIdentifier
    ) {
      level
      stacktrace
      eventIdentifier
      message
      time
      logProperties
    }
  }
`;

export interface GET_CONTEXT_EVENTS_RESPONSE {
  getContextEvents: {
    events: ContextEvent[];
    totalEvents: number;
    amountOfPages: number;
    currentPage: number;
  };
}

export interface GET_CONTEXT_EVENT_RESPONSE {
  getContextEvent: ContextEvent;
}

export interface GET_CONTEXT_EVENT_LOG_STACKTRACE_RESPONSE {
  getContextEventLogStackTrace: ContextEventLogStackTrace;
}
