import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { HISTORY_ACTIONS, LOCAL_STORAGE_KEYS } from "../../types/constants";
import LocalStorageHelper from "../../helper/localStorageHelper";

interface UsePrevLocationProps {
  goBack: (back: { pagesBack?: number; redirectPage?: string }) => void;
}

export const usePrevLocation = (): UsePrevLocationProps => {
  const history = useHistory();
  const location = history.location;

  // Count for redirects in application
  // Set to 2 because the first history action is a POP, which is the initial load of the page
  const [redirect, setRedirect] = useState(2);

  // Used to count redirects in case browser back button is used
  useEffect(() => {
    if (history.action === HISTORY_ACTIONS.push) setRedirect(redirect + 1);

    if (history.action === HISTORY_ACTIONS.pop) setRedirect(redirect - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // If the back button is used from external link, goes to parent component, else the user is redirected back
  const goBack = useCallback(
    (back: { pagesBack?: number; redirectPage?: string }) => {
      const { pagesBack = 1, redirectPage } = back;

      if (redirect > 1) {
        history.goBack();
        return;
      }

      // currently set from flow table
      const prevLink = LocalStorageHelper.getValue(
        LOCAL_STORAGE_KEYS.prevPageRoot,
        ""
      );
      if (prevLink) {
        LocalStorageHelper.removeProperty(LOCAL_STORAGE_KEYS.prevPageRoot);
        history.push(prevLink);
        return;
      }

      if (redirectPage) {
        history.push(redirectPage);
        return;
      }

      const array = location.pathname.split("/").slice(0, -pagesBack);
      const url = array.join("/");
      history.push(url);
      return;
    },
    [history, location.pathname, redirect]
  );

  return { goBack };
};
