import React, { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { makeStyles } from "@mui/styles";
import {
  alpha,
  useTheme,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useStores } from "../../../../../../../stores/StoresProvider";
import { SubscriptionUsageHistory } from "../../../../../../../types/interfaces/subscription";
import CoreButton from "../../../../../../core/CoreButton";
import { CoreCard } from "../../../../../../core/CoreCard";
import CoreAccordion from "../../../../../../core/CoreAccordion";
import CoreTable from "../../../../../../core/CoreTable";
import { TableContentDef } from "../../../../../../../types/interfaces";
import BillingHelper from "../../../../../../../helper/billingHelper";
import { useNotification } from "../../../../../../../context/useNotification";
import { backendRoutes } from "../../../../../../../configs/backendRoutes";

const useStyles = makeStyles({
  root: {
    padding: "0px !important",
    margin: "30px 60px",
  },
  progressContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "100px",
  },
  contentBox: {
    margin: "30px 20px",
  },
  divider: {
    marginTop: "20px",
  },
  historyItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  downloadReportAction: {
    marginRight: "10px",
    textDecoration: "none",
  },
  paginationContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px",
  },
  dataBox: {
    maxHeight: "350px",
    overflow: "auto",
  },
  circularProgress: {
    marginRight: "10px",
  },
});

const DATA_TABLE_HEADERS = [
  {
    accessor: "type",
    label: "usage_history_table_header_type",
  },
  {
    accessor: "total",
    label: "usage_history_table_header_total",
  },
  {
    accessor: "used",
    label: "usage_history_table_header_used",
  },
];

const formatTableItem = (item: SubscriptionUsageHistory): TableContentDef[] => {
  let data = [] as TableContentDef[];

  if (item?.included) {
    data = [{ type: "included", ...item?.included }];
  }

  if (item?.extra) {
    data = [...data, { type: "extra", ...item?.extra }];
  }

  return data;
};

const Component = () => {
  const { t, ready } = useTranslation("subscription");
  const theme = useTheme();
  const classes = useStyles();
  const notifier = useNotification();
  const { subscriptionStore } = useStores();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dataUsage, setData] = useState<SubscriptionUsageHistory[]>([]);
  const [page, setCurrentPage] = useState(1);
  const [downloadingReportIndex, setDownloadingReportIndex] = useState<
    number | null
  >(null);
  const [hasNext, setHasNext] = useState(true);

  const tableHeaders = useMemo(
    () =>
      DATA_TABLE_HEADERS.map((header) => ({
        ...header,
        label: t(header.label),
      })),
    [t]
  );

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsPageLoading(true);

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadData = () => {
    subscriptionStore
      .getUsageHistory(page)
      .then((response) => {
        setData(response?.items);
        setHasNext(response?.pages > page);
        setIsDataLoading(false);
        setIsPageLoading(false);
      })
      .catch(() => {
        setIsDataLoading(false);
        setIsPageLoading(false);
      });
  };

  const downloadReport = (
    event: React.MouseEvent<HTMLElement>,
    reportIndex: number,
    reportDate: string
  ) => {
    event?.preventDefault();
    event?.stopPropagation();

    setDownloadingReportIndex(reportIndex);

    void BillingHelper.downloadFile(
      backendRoutes.DownloadUsageHistory(
        subscriptionStore.billingCustomer?.id as string,
        reportDate
      )
    )
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${t("usageReport")} - ${reportDate}.csv`;
        a.click();
        setDownloadingReportIndex(null);
      })
      .catch(() => {
        notifier.error(t("usage_history_download_usage_error"));
        setDownloadingReportIndex(null);
      });
  };

  if (isDataLoading || !ready) {
    return (
      <Box className={classes.progressContainer}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CoreCard className={classes.root}>
      {!dataUsage || dataUsage?.length === 0 ? (
        <Box className={classes.contentBox}>
          <Typography>{t("usage_history_no_data")}</Typography>
        </Box>
      ) : (
        <Box className={classes.dataBox}>
          {dataUsage?.map((item, index) => (
            <React.Fragment key={index}>
              {index !== 0 && <Divider />}

              <CoreAccordion
                key={index}
                iconSx={{
                  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
                  color: alpha(theme.palette.divider, 0.7),
                  width: "35px",
                  height: "30px",
                  borderRadius: "4px",
                }}
                hasDivider={false}
                elevation={0}
                summaryChildren={
                  <Box className={classes.historyItemContainer}>
                    <Typography>
                      {moment(Number(item.date) * 1000).format("LL")}
                    </Typography>
                    <CoreButton
                      isLoading={index === downloadingReportIndex}
                      disabled={downloadingReportIndex !== null}
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      className={classes.downloadReportAction}
                      onClick={(e) => downloadReport(e, index, item.date)}
                    >
                      {t(
                        index === downloadingReportIndex
                          ? "usage_history_downloading_report_label_btn"
                          : "usage_history_download_report_label_btn"
                      )}
                    </CoreButton>
                  </Box>
                }
              >
                <CoreTable
                  isPaginated={false}
                  headers={tableHeaders}
                  isLoading={false}
                  data={formatTableItem(item)}
                />
              </CoreAccordion>
            </React.Fragment>
          ))}
        </Box>
      )}

      <Divider />

      <Box className={classes.paginationContainer}>
        {isPageLoading && (
          <CircularProgress size={20} className={classes.circularProgress} />
        )}

        <ButtonGroup variant="outlined">
          <Button
            size="small"
            disabled={page < 2 || isPageLoading}
            onClick={() => setCurrentPage(page - 1)}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            size="small"
            disabled={
              isPageLoading || !dataUsage || dataUsage?.length === 0 || !hasNext
            }
            onClick={() => setCurrentPage(page + 1)}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </ButtonGroup>
      </Box>
    </CoreCard>
  );
};

export const UsageHistory = memo(Component);
