import { ThemeOptions } from "@mui/material";

import { THEME_MODES } from "../../types/constants";

export const MonoTheme: ThemeOptions = {
  palette: {
    mode: THEME_MODES.dark,
    primary: {
      main: "#787b8f",
      light: "#E2E2E2",
      dark: "#3c3d47",
    },
    secondary: {
      main: "#515465",
      light: "#4a4956",
      dark: "#363844",
    },
    background: {
      paper: "#454750",
      default: "#454750",
    },
    neutral: {
      main: "#7A7A7A",
      light: "#E2E2E2",
      dark: "#5B5B5B",
    },
    canvas: {
      main: "#6088A4",
      light: "rgb(95, 163, 211, 0.2)",
      dark: "#787B8F",
      contrastText: "#D7D7D9",
    },
    surface: {
      main: "#6088A4",
      light: "rgb(95, 163, 211, 0.2)",
      dark: "#3C3C3C",
      contrastText: "#D7D7D9",
    },
    highlight: {
      main: "#04A4E3",
      light: "#ffffff",
      dark: "#3C414B",
      contrastText: "#FFFFFF",
    },
    buttons: {
      main: "#00C3FF",
      light: "#70EBFF",
      dark: "#000000", // unused
    },
    text: {
      primary: "#D7D7D9",
      secondary: "#ffffff",
      disabled: "#96969c",
    },

    divider: "#D9D9D9",

    switch: {
      main: "#FFFFFF",
      light: "#a7caed",
      dark: "#405a70",
    },

    error: {
      main: "#CE381D",
      light: "#FFB3BA",
      dark: "#97000E",
      50: "#4f3037",
      100: "#f77c7b",
    },
    warning: {
      main: "#EABE58",
      dark: "#B5811E",
      50: "#46391b",
      100: "#ffde8a",
    }, // unused
    info: { main: "#5886DC", 50: "#4e96d21a", 100: "#4E96D2" }, // unused
    success: { main: "#8EC77C", light: "#c9e9c9", dark: "#4a824a" },
  },
  zIndex: {
    mobileStepper: 100,
    speedDial: 105,
    appBar: 110,
    drawer: 120,
    modal: 130,
    snackbar: 140,
    tooltip: 150,
  },
};
