import { ThemeOptions } from "@mui/material";

import { THEME_MODES } from "../../types/constants";

export const LightTheme: ThemeOptions = {
  palette: {
    mode: THEME_MODES.light,
    primary: {
      main: "#ECECEC",
      light: "#F2F2F2",
      dark: "#D0D0D0",
    },
    secondary: {
      main: "#F8F8F8",
      light: "#E6E6E6",
      dark: "#cfcfcf",
    },
    background: {
      paper: "#f2f2f2",
      default: "#f2f2f2",
    },
    neutral: {
      main: "#7A7A7A",
      light: "#E2E2E2",
      dark: "#5B5B5B",
    },
    canvas: {
      main: "#6088A4",
      light: "rgb(95, 163, 211, 0.2)",
      dark: "#787B8F",
      contrastText: "#D7D7D9",
    },
    surface: {
      main: "#6088A4",
      light: "#C4C4C4",
      dark: "#EBEBEB",
      contrastText: "#000000",
    },
    buttons: {
      main: "#00C3FF",
      light: "#70EBFF",
      dark: "#D3D3D3",
    },
    highlight: {
      main: "#015CDA",
      light: "#000000",
      dark: "#CCCCCC",
      contrastText: "#FFFFFF",
    },
    text: {
      primary: "#000000",
      secondary: "#292828",
      disabled: "#292828",
    },
    divider: "#000000",
    switch: {
      main: "#000000",
      light: "#a7caed",
      dark: "#405a70",
    },
    error: {
      main: "#CE381D",
      light: "#FFB3BA",
      dark: "#97000E",
      50: "#fdeded",
      100: "#5f2120",
    },
    warning: {
      main: "#EABE58",
      dark: "#B5811E",
      50: "#fff4e5",
      100: "#663c00",
    },
    info: { main: "#5886DC", 50: "#e5f6fd", 100: "#014361" },
    success: { main: "#8EC77C", light: "#c9e9c9", dark: "#4a824a" },
  },
  typography: {
    allVariants: {
      color: "#000",
    },
  },
  zIndex: {
    mobileStepper: 100,
    speedDial: 105,
    appBar: 110,
    drawer: 120,
    modal: 130,
    snackbar: 140,
    tooltip: 150,
  },
};
