import React, { FC } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HttpsIcon from "@mui/icons-material/Https";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { useNotification } from "../../../context/useNotification";
import { useStores } from "../../../stores/StoresProvider";

const useStyles = makeStyles({
  actionIcon: {
    fontSize: "20px",
  },
});

interface Props {
  identifier: string;
  isActive: boolean;
  t: TFunction;
  onDeleteToggle: (apiKey: string) => void;
  onActivateToggle: (apiKey: string) => void;
}

export const ApiKeyTableActions: FC<Props> = ({
  identifier,
  isActive,
  t,
  onDeleteToggle,
  onActivateToggle,
}) => {
  const notification = useNotification();
  const classes = useStyles();
  const { userStore } = useStores();

  const onCopyToClipboard = (apiKey: string) => {
    void navigator.clipboard.writeText(apiKey);
    notification.success(t("apiKeyCopied"));
  };

  const onStateChange = (apiKey: string) => {
    onActivateToggle(apiKey);
  };

  const onDeleteKey = (apiKey: string) => {
    onDeleteToggle(apiKey);
  };

  return (
    <>
      <Tooltip title={t("tableApiKeyCopyToClipboard") || ""}>
        <IconButton size="small" onClick={() => onCopyToClipboard(identifier)}>
          <ContentCopyIcon className={classes.actionIcon} />
        </IconButton>
      </Tooltip>

      <>
        {userStore.currentUserPermissions?.can("update", "apiKeys") && (
          <Tooltip
            title={
              t(isActive ? "tableStatusDeactivate" : "tableStatusActivate") ||
              ""
            }
          >
            <IconButton size="small" onClick={() => onStateChange(identifier)}>
              {isActive ? (
                <NoEncryptionGmailerrorredIcon className={classes.actionIcon} />
              ) : (
                <HttpsIcon className={classes.actionIcon} />
              )}
            </IconButton>
          </Tooltip>
        )}
        {userStore.currentUserPermissions?.can("delete", "apiKeys") && (
          <Tooltip title={t("tableDeleteKey") || ""}>
            <IconButton size="small" onClick={() => onDeleteKey(identifier)}>
              <DeleteForeverIcon className={classes.actionIcon} />
            </IconButton>
          </Tooltip>
        )}
      </>
    </>
  );
};
