import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import makeStyles from "@mui/styles/makeStyles";
import { Box, alpha, useTheme } from "@mui/material";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

import CorePageContainer from "../../../core/CorePageContainer";
import { useStores } from "../../../../stores/StoresProvider";
import FiltersContent from "../components/FiltersContent";
import DataTable from "../components/DataTable";
import { useNotification } from "../../../../context/useNotification";
import LocalStorageHelper from "../../../../helper/localStorageHelper";
import {
  ActivityLogsFilters,
  LOCAL_STORAGE_KEYS,
} from "../../../../types/constants";
import CoreTooltip from "../../../core/CoreTooltip";
import CoreButton from "../../../core/CoreButton";
import { ActivityLogsFilterTypes } from "../../../../types/enums";

interface Props {
  flowId?: string;
}

const extractStorageRowsPerPage = () => {
  return (
    LocalStorageHelper.getValue<{
      rowsPerPage: number;
    }>(LOCAL_STORAGE_KEYS.activityLogsPagination, {
      rowsPerPage: 10,
    }).rowsPerPage || 10
  );
};

const ActivityLogsPage: FC<Props> = observer(({ flowId }) => {
  const notification = useNotification();
  const { search } = useLocation<{ search: string }>();
  const { t } = useTranslation("activityLogs");
  const theme = useTheme();

  const classes = makeStyles({
    button: {
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
        color: `${theme.palette.highlight.main} !important`,
      },
    },
  })();

  const {
    flowStore: { getAllFlows },
    activityLogStore,
    userStore,
  } = useStores();

  const [paginationFilters, setPaginationFilters] = useState({
    rowsPerPage: extractStorageRowsPerPage(),
  });

  const setRowsPerPage = (rowsPerPage: number) => {
    activityLogStore.setCurrentPage(1);
    setPaginationFilters({
      rowsPerPage,
    });

    LocalStorageHelper.setValue(LOCAL_STORAGE_KEYS.activityLogsPagination, {
      rowsPerPage,
    });
  };

  const setLocalStorageFilters = useCallback(() => {
    const activityLogsPagination = LocalStorageHelper.getValue<{
      rowsPerPage: number;
    }>(LOCAL_STORAGE_KEYS.activityLogsPagination, {
      rowsPerPage: 10,
    });

    setPaginationFilters({
      rowsPerPage: activityLogsPagination?.rowsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isRefreshData, currentPage, filters } = activityLogStore;

  const loadActivityLogs = useCallback(() => {
    const staticFilter = flowId
      ? ({
          [ActivityLogsFilterTypes.flowsFilter]: [flowId],
        } as unknown as ActivityLogsFilters)
      : undefined;

    activityLogStore
      .loadActivityLog(staticFilter)
      .then(() => setLocalStorageFilters())
      .catch((error: Error) => {
        notification.error(t(error?.message || "fetchFlowsError"));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      activityLogStore.resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set possible filters received through url
    activityLogStore.setUrlFilters(search);

    if (userStore.currentUserPermissions?.can("read", "flows") && !flowId) {
      getAllFlows().catch((error: Error) => {
        notification.error(t(error?.message || "fetchFlowsError"));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    activityLogStore.setPageSize(paginationFilters.rowsPerPage);
    loadActivityLogs();
  }, [
    currentPage,
    filters,
    activityLogStore,
    paginationFilters.rowsPerPage,
    loadActivityLogs,
  ]);

  useEffect(() => {
    if (isRefreshData) {
      loadActivityLogs();
    }
  }, [
    isRefreshData,
    activityLogStore,
    notification,
    t,
    setLocalStorageFilters,
    loadActivityLogs,
  ]);

  return (
    <CorePageContainer
      label={t("tracingTitle")}
      isPageLoading={activityLogStore.isPageLoading}
      fixExtraHeaderContent={true}
      extraHeaderContent={
        <CoreTooltip title={t("action_refresh")}>
          <Box>
            <CoreButton
              variant="neutral"
              size="large"
              onClick={() => activityLogStore.setIsRefreshData(true)}
              startIcon={<CachedOutlinedIcon />}
              className={classes.button}
              disabled={isRefreshData}
            >
              {t("action_refresh")}
            </CoreButton>
          </Box>
        </CoreTooltip>
      }
    >
      <FiltersContent
        unsupportedFilters={
          flowId ? [ActivityLogsFilterTypes.flowsFilter] : undefined
        }
      />

      <DataTable
        rowsPerPage={paginationFilters?.rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage as number)}
        totalCount={activityLogStore.totalData}
      />
    </CorePageContainer>
  );
});

export default ActivityLogsPage;
