import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import _startCase from "lodash/startCase";

import { makeStyles } from "@mui/styles";
import {
  useTheme,
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";

import {
  ContextEvent,
  ContextEventData,
} from "../../../../types/interfaces/contextEvent";
import DateHelper from "../../../../helper/dateHelper";
import { appRoutes } from "../../../../configs/routes";
import CoreTextLink from "../../../core/CoreTextLink";
import ActivityLogsHelper from "../../../../helper/activityLogsHelper";

const FIELDS = [
  "context_event_summary_status",
  "context_event_summary_time",
  "context_event_summary_flow",
  "context_event_summary_document_identifier",
  "context_event_summary_page_identifier",
  "context_event_summary_initiated_by",
];

export const SummaryTab: FC<{ data: ContextEvent | undefined }> = ({
  data,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("activityLogs");
  const eventData = data?.eventData as ContextEventData;

  const classes = makeStyles({
    detailsLabelContent: {
      width: "200px",
      color: theme.palette.neutral.main,
      borderBottom: "none",
      padding: "0",
    },
    labelStyle: {
      fontSize: "14px",
      marginBottom: "10px",
    },
    valueStyle: {
      fontSize: "14px",
      marginBottom: "10px",
      overflowWrap: "break-word",
    },
    valueDetailsContent: {
      borderBottom: "none",
      padding: "0",
      width: "auto",
    },
  })();

  const formatFieldValue = (fieldKey: string): string | React.ReactNode => {
    switch (fieldKey) {
      case "context_event_summary_operation":
        return _startCase(data?.eventType || "-");
      case "context_event_summary_step":
        if (eventData?.method) {
          return `[${eventData.method}] ${eventData?.route || ""}`;
        }
        return eventData?.step || eventData?.node || "-";
      case "context_event_summary_status":
        return _startCase(data?.status || "-");
      case "context_event_summary_time":
        return data?.time ? DateHelper.timeStringFormat(data.time) : "-";
      case "context_event_summary_flow":
        if (eventData?.flow && eventData?.flowName) {
          return (
            <Box className={classes.valueStyle}>
              <CoreTextLink
                label={eventData.flowName || ""}
                to={appRoutes.FlowDetails(eventData.flow)}
              />
            </Box>
          );
        }
        return (
          <Box className={classes.valueStyle}>{eventData?.flowName || "-"}</Box>
        );
      case "context_event_summary_document_identifier":
        if (eventData?.flow) {
          return (
            <Box className={classes.valueStyle}>
              <CoreTextLink
                to={appRoutes.DocumentView(
                  eventData?.flow,
                  eventData?.documentIdentifier
                )}
                label={eventData?.documentIdentifier}
              />
            </Box>
          );
        }
        return (
          <Box className={classes.valueStyle}>
            {eventData.documentIdentifier}
          </Box>
        );
      case "context_event_summary_page_identifier":
        if (eventData?.flow && eventData?.documentIdentifier) {
          return (
            <Box className={classes.valueStyle}>
              <CoreTextLink
                to={`${appRoutes.DocumentView(
                  eventData?.flow,
                  eventData?.documentIdentifier
                )}?page=${eventData.pageIdentifier}`}
                label={eventData?.pageIdentifier}
              />
            </Box>
          );
        }
        return (
          <Box className={classes.valueStyle}>{eventData.pageIdentifier}</Box>
        );
      case "context_event_summary_initiated_by": {
        return eventData?.user && eventData?.user?.email
          ? `${ActivityLogsHelper.initiatedByFormat(eventData)} [${
              eventData?.user?.email
            }]`
          : ActivityLogsHelper.initiatedByFormat(eventData);
      }
      default:
        return "-";
    }
  };

  return (
    <Table>
      <TableBody>
        {FIELDS.map((fieldTranslation, index) => {
          if (
            (fieldTranslation === "context_event_summary_document_identifier" &&
              !eventData?.documentIdentifier) ||
            (fieldTranslation === "context_event_summary_page_identifier" &&
              !eventData?.pageIdentifier)
          ) {
            return null;
          }

          const fieldValue = formatFieldValue(fieldTranslation);
          return (
            <TableRow key={index}>
              <TableCell className={classes.detailsLabelContent}>
                <Typography
                  key={fieldTranslation}
                  className={classes.labelStyle}
                >
                  {t(fieldTranslation)}
                </Typography>
              </TableCell>
              <TableCell className={classes.valueDetailsContent}>
                <Box key={fieldTranslation} className={classes.valueStyle}>
                  {fieldValue}
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
