import React from "react";

interface Props {
  fill?: string;
  width?: string;
  height?: string;
}

const AzureIcon: React.FC<Props> = ({ fill, width, height }) => (
  <svg
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M7.9699 0.670881C8.05001 0.450579 7.96631 0.204197 7.76859 0.0782743C7.57087 -0.0476482 7.31221 -0.0193008 7.14645 0.146456L3.14645 4.14646C3.10147 4.19143 3.06549 4.24458 3.04043 4.30305L0.0404292 11.3031C-0.0257774 11.4575 -0.00994065 11.6349 0.0825812 11.7753C0.175103 11.9156 0.33193 12 0.500002 12H3.5C3.71025 12 3.89805 11.8685 3.9699 11.6709L7.9699 0.670881Z"
      fill={fill}
      style={{ marginRight: "100px" }}
    />
    <path
      d="M9.95518 2.29311C9.87232 2.1108 9.68867 1.99553 9.48847 2.00014C9.28827 2.00476 9.11014 2.12838 9.03576 2.31431L7.03576 7.31431C6.97096 7.47633 6.9953 7.66041 7.1 7.80001L9.68377 11.245L4.34189 13.0257C4.10924 13.1032 3.96718 13.338 4.00646 13.5801C4.04574 13.8222 4.25477 14 4.5 14H14.5C14.67 14 14.8284 13.9136 14.9204 13.7707C15.0124 13.6278 15.0255 13.4479 14.9552 13.2931L9.95518 2.29311Z"
      fill={fill}
    />
  </svg>
);

export default AzureIcon;
