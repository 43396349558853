export const appRoutes = {
  RetryNextDocument: (flowId = ":flowId"): string =>
    `/flow/${flowId}/retry-next-document`,
  FlowDetails: (id = ":id"): string => `/flows/view/${id}`,
  FlowDataCategories: (id = ":id", type = ":type"): string =>
    `/flows/view/${id}/${type}`,
  FlowSettings: (id = ":id"): string => `/flows/settings/${id}/`,
  Management: (): string => "/management",
  System: (): string => "/system",
  News: (): string => "/news",
  Documentation: (): string => "/documentation",

  Documents: (): string => "/documents",
  DocumentView: (flowId = ":flowId", documentId = ":documentId"): string =>
    `/flows/view/${flowId}/documents/${documentId}`,
  ListDocuments: (flowId = ":flowId"): string => `/flows/view/${flowId}`,

  // SETTINGS ROUTES AND SUB-ROUTES
  Settings: (): string => "/settings",
  SubscriptionPlanUpgrade: (): string => "/settings/subscription/upgrade",
  Invoices: (): string => "/settings/invoices",
  AccountApiKeys: (): string => "/settings/api-keys",
  Connections: (): string => "/settings/connections",
  Indexing: (): string => "/settings/indexing",
  AccountBilling: (): string => "/settings/billing",
  Team: (): string => "/settings/team",
  Rejections: (): string => "/settings/rejections",
  UserAccount: (): string => "/settings/account",
  DevSettings: (): string => "/settings/dev-settings",

  Home: (): string => "/",
  AddFlow: (): string => "/flows/create",
  ContextEvents: (): string => "/activity-log",
  ContextEvent: (id = ":id"): string => `/activity-log/${id}`,
  Statistics: (): string => "/statistics",
  HumanStatistics: (): string => "/statistics/human",
  MachineStatistics: (): string => "/statistics/machine",
  Flows: (): string => "/flows",
  ChatGpt: (): string => "/chat",

  // QA
  QA: (): string => "/qa/assign",
  QaDocuments: (): string => "/qa/documents",
  QaDocumentView: (flowId = ":flowId", documentId = ":documentId"): string =>
    `/qa/documents/${flowId}/${documentId}/review`,
};

export const authRoutes = {
  Login: (): string => "/login",
  Register: (): string => "/register",
  SuccessfullyRegister: (): string => "/register/success",
  ForgotPassword: (): string => "/forgot-password",
  ResetPassword: (): string => "/reset-password",
  ActivatingAccount: (): string => "/email/activate",
  ResendActivation: (): string => "/email/resend",
  SignUpJourney: (): string => "/signup-journey",
  Invitation: (): string => "/invitation/:token",
  Namespaces: (): string => "/namespaces",
};
