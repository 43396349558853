import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Divider,
  Typography,
  IconButton,
  Slide,
  useTheme,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";

import ChatGptInput from "./ChatGptInput";
import { useStores } from "../../../../stores/StoresProvider";
import { AppSuspense } from "../../../main/AppSuspense";
import Logo from "../../../../icons/Logo";
import SearchingResponse from "./SearchingResponse";
import MessageRenderer from "./MessageRenderer";
import { appRoutes } from "../../../../configs/routes";
import ChatGptHelper from "../../../../helper/chatGptHelper";
import ChatGptLanguageSelector from "./ChatGptLanguageSelector";

interface Props {
  onClose?: () => void;
}

const HomeChatGpt: FC<Props> = observer(({ onClose }) => {
  const { pathname } = useLocation();
  const { t, ready } = useTranslation("home");
  const theme = useTheme();
  const homeChatGptRef = useRef<HTMLDivElement | null>(null);
  const {
    chatGptStore: {
      isResponseLoading,
      setIsResponseGenerating,
      setIsResponseLoading,
      homeMessages,
      clearHomeMessages,
      isScrollDisabled,
    },
    userStore,
  } = useStores();
  const [triggerNewTopic, setTriggerNewTopic] = useState({});

  const useStyles = makeStyles({
    container: {
      height: "calc(100vh - 90px)",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    boxChatGptContainer: {
      width: "100%",
      overflow: "hidden",
    },
    newTopicButton: {
      backgroundColor: "transparent",
      border: !isResponseLoading
        ? `1px solid ${theme.palette.highlight.main}`
        : `1px solid ${theme.palette.text.disabled}`,
      borderRadius: "50%",
      width: "54px",
      height: "50px",
      transition: "width 0.3s ease, border-radius 0.3s ease",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      position: "relative",
      padding: 0,
      "& .icon": {
        width: "18px",
        height: "18px",
        color: !isResponseLoading
          ? theme.palette.highlight.main
          : theme.palette.text.disabled,
        position: "absolute",
        left: "15px",
        transform: "translateX(0)",
      },
      "& .button-text": {
        opacity: 0,
        whiteSpace: "nowrap",
        transition: "opacity 0.3s ease",
        color: !isResponseLoading
          ? theme.palette.highlight.main
          : theme.palette.text.disabled,
        fontSize: "15px",
        visibility: "hidden",
        position: "absolute",
        left: "40px",
      },
      "&:hover": {
        width: "150px",
        borderRadius: "25px",
        "& .button-text": {
          opacity: 1,
          visibility: "visible",
        },
        "& .icon": {
          marginLeft: 0,
          transform: "translateX(0)",
          left: "15px",
        },
      },
    },
    chatGptContainer: {
      display: "flex",
      flexDirection: "column",
      padding: "40px 0px 0px 40px",
      marginTop: "40px",
      width: "100%",
      flexWrap: "wrap",
      overflow: "hidden",
    },
    boxForWidth: {
      width: "100%",
    },
    boxContent: {
      overflow: "auto",
      width: "100%",
      height: "100%",
      marginBottom: "15px",
      padding: "0px 40px",
    },
    inputBox: {
      marginTop: "10px",
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    box: {
      width: "800px",
      display: "flex",
      flexWrap: "nowrap",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    },
    closeBox: {
      position: "absolute",
      right: "30px",
    },
    firstMessageBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "150px",
    },
    logoDimension: {
      width: "25px",
      height: "30px",
    },
    firstMessageText: {
      marginTop: "10px",
      fontSize: "24px",
    },
    downloadWithCancel: {
      fontSize: "14px",
      whiteSpace: "nowrap",
      textAlign: "right",
      overflow: "hidden",
      paddingRight: "25px",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
        color: theme.palette.highlight.main,
      },
    },
    link: {
      fontSize: "14px",
      whiteSpace: "nowrap",
      textAlign: "right",
      overflow: "hidden",
      padding: "10px 15px",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
        color: theme.palette.highlight.main,
      },
      position: "absolute",
      right: "50px",
    },
    language: {
      position: "absolute",
      right: "20px",
    },
    languageNoMessage: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "15px",
    },
    optionsBox: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingTop: "15px",
    },
    optionsBoxClose: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      right: "30px",
    },
  });

  const classes = useStyles();

  const handleNewTopic = () => {
    setIsResponseGenerating(false);
    setIsResponseLoading(false);
    clearHomeMessages();
    setTriggerNewTopic({});
  };

  useEffect(() => {
    return () => {
      setIsResponseGenerating(false);
      setIsResponseLoading(false);
      clearHomeMessages();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNewTopic]);

  useEffect(() => {
    if (
      homeMessages?.length > 1 &&
      homeChatGptRef?.current &&
      !isScrollDisabled
    )
      homeChatGptRef?.current?.scrollIntoView(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeMessages]);

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.boxChatGptContainer}>
      <Slide
        direction="up"
        in={userStore.isHomeChatGptOpened || pathname === appRoutes.ChatGpt()}
        mountOnEnter
        unmountOnExit
        style={{ height: "100%" }}
      >
        <div>
          <Box
            className={
              !onClose ? classes.chatGptContainer : classes.boxForWidth
            }
          >
            {onClose && (
              <Box className={classes.optionsBoxClose}>
                <>
                  {homeMessages?.length > 0 && (
                    <Typography
                      className={classes.downloadWithCancel}
                      onClick={() =>
                        ChatGptHelper.handleDownloadConversation(homeMessages)
                      }
                    >
                      {t("downloadChat")}
                    </Typography>
                  )}

                  <ChatGptLanguageSelector />

                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </>
              </Box>
            )}

            <Box className={classes.container}>
              {homeMessages?.length === 0 && (
                <>
                  <Box className={classes.languageNoMessage}>
                    <ChatGptLanguageSelector />
                  </Box>
                  <Box className={classes.firstMessageBox}>
                    <Logo
                      threeStripes={true}
                      threeStripesClassName={classes.logoDimension}
                    />

                    <Typography className={classes.firstMessageText}>
                      {t("no_messages_text")}
                    </Typography>
                  </Box>
                </>
              )}

              {homeMessages?.length > 0 && !onClose && (
                <Box className={classes.optionsBox}>
                  <Typography
                    className={classes.link}
                    onClick={() =>
                      ChatGptHelper.handleDownloadConversation(homeMessages)
                    }
                  >
                    {t("downloadChat")}
                  </Typography>
                  <Box className={classes.language}>
                    <ChatGptLanguageSelector />
                  </Box>
                </Box>
              )}

              <Box className={classes.boxContent} id="homeChatGptBox">
                <MessageRenderer
                  t={t}
                  messages={homeMessages}
                  isHomeChat={true}
                />

                {isResponseLoading && <SearchingResponse t={t} />}

                <div ref={homeChatGptRef} id="homeChatGptDiv" />
              </Box>

              <Divider />

              <Box className={classes.inputBox}>
                <Box className={classes.box}>
                  <button
                    className={classes.newTopicButton}
                    onClick={handleNewTopic}
                    disabled={isResponseLoading}
                  >
                    <ChatIcon className="icon" />
                    <Typography className="button-text">
                      {t("newTopic")}
                    </Typography>
                  </button>

                  <ChatGptInput
                    placeholder="type_question"
                    inputIcon={<ArrowUpwardIcon />}
                    isForHome={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Slide>
    </Box>
  );
});

export default HomeChatGpt;
