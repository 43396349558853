import { makeAutoObservable, runInAction } from "mobx";

import { RootStore } from "./StoresProvider";
import {
  GET_ACTIVE_SUBSCRIPTION,
  GET_ACTIVE_SUBSCRIPTION_RECEIPTS,
  GET_ACTIVE_SUBSCRIPTION_USAGE,
  GET_BILLING_COUNTRIES,
  GET_BILLING_CUSTOMER,
  GET_BILLING_SETUP_INTENT_KEY,
  GET_SUBSCRIPTION_PACKAGES,
  GET_SUBSCRIPTION_USAGE_HISTORY,
  QUERY_ACTIVE_SUBSCRIPTION_RESPONSE,
  QUERY_BILLING_COUNTRIES_RESPONSE,
  QUERY_BILLING_CUSTOMER_RESPONSE,
  QUERY_BILLING_INTENT_RESPONSE,
  QUERY_SUBSCRIPTION_PACKAGES_RESPONSE,
  QUERY_SUBSCRIPTION_RECEIPTS_RESPONSE,
  QUERY_SUBSCRIPTION_USAGE_HISTORY_RESPONSE,
  QUERY_SUBSCRIPTION_USAGE_RESPONSE,
} from "./queries/subscription";
import {
  SubscriptionPlan,
  BillingCustomer,
  SubscriptionReceipt,
  SubscriptionUsage,
  BillingCountry,
  BILLING_CONTRACT_TERMS,
  BillingIntentAPI,
  BILLING_CARD_TYPES,
  PaginatedSubscriptionUsageHistory,
  BillingCustomerCompany,
} from "../types/interfaces/subscription";
import {
  CHANGE_SUBSCRIPTION_PLAN,
  MUTATION_CHANGE_SUBSCRIPTION_PLAN_RESPONSE,
  MUTATION_SEND_SALES_EMAIL_RESPONSE,
  MUTATION_UPDATE_BILLING_RESPONSE,
  MUTATION_UPDATE_DEFAULT_PAYMENT_METHOD_RESPONSE,
  MUTATION_UPDATE_SUBSCRIPTION_PLAN_RESPONSE,
  SEND_SALES_EMAIL,
  UPDATE_BILLING_CUSTOMER,
  UPDATE_DEFAULT_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION_PLAN,
  MUTATION_CANCEL_SUBSCRIPTION_RESPONSE,
  CANCEL_SUBSCRIPTION,
} from "./mutations/subscription";
import { GET_ALL_FLOWS, GET_FLOWS_TYPE } from "./queries/flows";
import { GET_NAMESPACES, QUERY_GET_NAMESPACES_RESPONSE } from "./queries/users";

import BillingHelper from "../helper/billingHelper";
import { Flow, NamespaceRole } from "../types/interfaces";

/**
 * Store which contains data regarding the subscription of the current context
 */
export class SubscriptionStore {
  root: RootStore;

  isDataLoading: boolean;
  currentTabIndex: number;
  activeSubscription: SubscriptionPlan | null;
  namespaces: NamespaceRole[];
  flows: Flow[];
  billingCustomer: BillingCustomer | null;
  billingCompany: BillingCustomerCompany | null;
  countries: BillingCountry[];

  // Upgrade subscription
  billingSetupIntent: BillingIntentAPI;
  subscriptionPlans: SubscriptionPlan[];
  upgradePlanId: string | null;
  draftUpgradePlanId: string | null; // Used when user clicks upgrade to next package action
  contractTerm: string;
  changeSubscriptionInProgress: boolean;
  flatBillingCustomer: { [key: string]: string }; // Flatten billing information needed for better form handling
  flatBillingCompany: { [key: string]: string };
  flatBillingErrors: { [key: string]: string };
  flatCompanyErrors: { [key: string]: string };
  cardType: string;
  validSteps: { [key: string]: boolean };

  constructor(root: RootStore) {
    this.root = root;

    this.isDataLoading = true;
    this.currentTabIndex = 0;
    this.activeSubscription = null;
    this.namespaces = [];
    this.flows = [];
    this.billingCustomer = null;
    this.billingCompany = null;
    this.billingSetupIntent = {
      publishableKey: "",
      clientSecret: "",
      provider: "",
    };
    this.subscriptionPlans = [];
    this.upgradePlanId = null;
    this.draftUpgradePlanId = null;
    this.contractTerm = BILLING_CONTRACT_TERMS.month;
    this.countries = [];
    this.changeSubscriptionInProgress = false;
    this.flatBillingCustomer = {};
    this.flatBillingCompany = {};
    this.flatBillingErrors = {};
    this.flatCompanyErrors = {};
    this.cardType = BILLING_CARD_TYPES.existing;
    this.validSteps = {
      plan: false,
      price: false,
      billing: false,
      review: false,
    };

    makeAutoObservable(this);
  }

  get activePlan(): SubscriptionPlan | undefined {
    try {
      return this.subscriptionPlans?.find(
        (plan) => plan.id === this.activeSubscription?.priceId
      );
    } catch {
      return undefined;
    }
  }

  get activePlanMonthlyVariant(): SubscriptionPlan | undefined {
    try {
      return this.subscriptionPlans?.find(
        (plan) =>
          plan.plan === this.activeSubscription?.plan &&
          plan.period === BILLING_CONTRACT_TERMS.month
      );
    } catch {
      return undefined;
    }
  }

  get nextSubscription(): SubscriptionPlan | undefined {
    try {
      const currentPlan = this.activePlan;
      const plans = this.subscriptionPlans;

      if (!currentPlan || !currentPlan?.subscriptionProperties?.order) {
        throw new Error();
      }

      // Fetch next annual type plan
      if (currentPlan?.period === BILLING_CONTRACT_TERMS.year) {
        const annualPlans = plans?.filter(
          (plan) => plan.period === BILLING_CONTRACT_TERMS.year
        );
        const currentPlanIndex = annualPlans?.findIndex(
          (item) => item.id === currentPlan.id
        );
        return annualPlans?.[currentPlanIndex + 1];
      }

      // Fetch next monthly type plan
      const monthlyPlans = plans?.filter(
        (plan) => plan.period === BILLING_CONTRACT_TERMS.month
      );
      const currentPlanIndex = monthlyPlans?.findIndex(
        (item) => item.id === currentPlan.id
      );

      return monthlyPlans?.[currentPlanIndex + 1];
    } catch {
      return undefined;
    }
  }

  get selectedSubscription(): SubscriptionPlan | undefined {
    try {
      return this.subscriptionPlans?.find(
        (item) => item.id === this.upgradePlanId
      );
    } catch {
      return undefined;
    }
  }

  resetStore(): void {
    this.isDataLoading = true;
    this.activeSubscription = null;
    this.currentTabIndex = 0;
    this.contractTerm = BILLING_CONTRACT_TERMS.month;
    this.cardType = BILLING_CARD_TYPES.existing;
  }

  setCardType(cardType: string): void {
    this.cardType = cardType;
  }

  setChangeSubscriptionInProgress(value: boolean): void {
    this.changeSubscriptionInProgress = value;
  }

  setValidSteps(newData: { [key: string]: boolean }): void {
    this.validSteps = {
      ...this.validSteps,
      ...newData,
    };
  }

  setBillingFormErrors(newErrors: { [key: string]: string }): void {
    this.flatBillingErrors = newErrors;
  }

  setCompanyFormErrors(newErrors: { [key: string]: string }): void {
    this.flatCompanyErrors = newErrors;
  }

  setTabIndex(newIndex: number): void {
    this.currentTabIndex = newIndex;
  }

  clearPackages(): void {
    this.activeSubscription = null;
    this.subscriptionPlans = [];
    this.upgradePlanId = null;
    this.validSteps = {
      plan: false,
      price: false,
      billing: false,
      review: false,
    };
    this.billingSetupIntent = {
      publishableKey: "",
      clientSecret: "",
      provider: "",
    };
  }

  setFlattenCustomerData(updatedData: { [key: string]: string }): void {
    this.flatBillingCustomer = updatedData;
  }

  setFlattenCompanyData(updatedData: { [key: string]: string }): void {
    this.flatBillingCompany = updatedData;

    if (!updatedData?.["name"]) {
      // Reset errors
      this.setCompanyFormErrors({});
    }
  }

  async loadPackageUpgradeData(): Promise<void> {
    try {
      runInAction(() => {
        this.isDataLoading = true;
      });

      const [
        billingSetupIntent,
        billingCustomer,
        activeSubscription,
        subscriptionPlans,
        countries,
      ] = await Promise.all([
        this.getBillingSetupIntent(),
        this.getBillingCustomer(),
        this.getActiveSubscription().catch(() => null),
        this.getSubscriptionPlans(),
        this.getBillingCountries(),
      ]);

      runInAction(() => {
        this.billingSetupIntent = billingSetupIntent;
        this.activeSubscription = activeSubscription;
        this.subscriptionPlans = subscriptionPlans;
        this.isDataLoading = false;
        this.upgradePlanId =
          this.draftUpgradePlanId || activeSubscription?.priceId || "";
        this.contractTerm =
          activeSubscription?.period || BILLING_CONTRACT_TERMS.month;
        this.draftUpgradePlanId = null;
        this.countries = countries || [];
        this.flatBillingCustomer =
          BillingHelper.flattenBillingData(billingCustomer);
        this.flatBillingCompany = BillingHelper.flattenBillingCompanyData(
          billingCustomer?.companyInformation
        );
        this.cardType = activeSubscription?.card
          ? BILLING_CARD_TYPES.existing
          : BILLING_CARD_TYPES.new;
      });
    } catch {
      runInAction(() => {
        this.isDataLoading = false;
      });
    }
  }

  async loadSubscriptionData(canReadFlows: boolean): Promise<void> {
    try {
      runInAction(() => {
        this.isDataLoading = true;
      });

      const billingCustomer = await this.getBillingCustomer();

      let activeSubscription = null as null | SubscriptionPlan;
      let subscriptionPlans = [] as SubscriptionPlan[];
      let countries = [] as BillingCountry[];
      let namespaces = [] as NamespaceRole[];
      let flows = [] as Flow[];

      if (billingCustomer.id) {
        const [
          activeSub,
          currentPlans,
          countryList,
          currentNamespaces,
          currentFlows,
        ] = await Promise.all([
          this.getActiveSubscription().catch(() => null),
          this.getSubscriptionPlans(),
          this.getBillingCountries(),
          this.getCurrentNamespaces(),
          ...(canReadFlows ? [this.getCurrentFlows()] : []),
        ]);
        activeSubscription = activeSub;
        subscriptionPlans = currentPlans;
        countries = countryList;
        namespaces = [...currentNamespaces.rows];
        flows = currentFlows;
      }

      runInAction(() => {
        this.activeSubscription = activeSubscription;
        this.isDataLoading = false;
        this.billingCustomer = billingCustomer;
        this.billingCompany = billingCustomer?.companyInformation || null;
        this.subscriptionPlans = subscriptionPlans;
        this.countries = countries;
        this.namespaces = namespaces;
        this.flows = flows;
      });
    } catch {
      runInAction(() => {
        this.isDataLoading = false;
      });
    }
  }

  async getBillingSetupIntent(): Promise<BillingIntentAPI> {
    return this.root.client
      .query<QUERY_BILLING_INTENT_RESPONSE>({
        query: GET_BILLING_SETUP_INTENT_KEY,
      })
      .then(({ data: { billingSetupIntent }, errors }) => {
        if (!billingSetupIntent || (errors && errors?.[0])) {
          throw new Error();
        }

        return billingSetupIntent;
      });
  }

  async getBillingCustomer(): Promise<BillingCustomer> {
    return this.root.client
      .query<QUERY_BILLING_CUSTOMER_RESPONSE>({
        query: GET_BILLING_CUSTOMER,
      })
      .then(({ data: { billingCustomer }, errors }) => {
        if (!billingCustomer || (errors && errors?.[0])) {
          throw new Error();
        }

        return billingCustomer;
      });
  }

  async getActiveSubscription(): Promise<SubscriptionPlan> {
    return this.root.client
      .query<QUERY_ACTIVE_SUBSCRIPTION_RESPONSE>({
        query: GET_ACTIVE_SUBSCRIPTION,
      })
      .then(({ data: { activeSubscription }, errors }) => {
        if (!activeSubscription || (errors && errors?.[0])) {
          throw new Error();
        }

        return {
          ...activeSubscription,
          ...(activeSubscription.details || {}),
        } as SubscriptionPlan;
      });
  }

  async getSubscriptionPlans(): Promise<SubscriptionPlan[]> {
    const {
      data: { subscriptionPlans },
      errors,
    } = await this.root.client.query<QUERY_SUBSCRIPTION_PACKAGES_RESPONSE>({
      query: GET_SUBSCRIPTION_PACKAGES,
    });

    if (errors) {
      return [];
    }

    return BillingHelper.sortSubscriptionPlans(subscriptionPlans);
  }

  async getReceipts(): Promise<SubscriptionReceipt[]> {
    return this.root.client
      .query<QUERY_SUBSCRIPTION_RECEIPTS_RESPONSE>({
        query: GET_ACTIVE_SUBSCRIPTION_RECEIPTS,
      })
      .then(({ data: { activeSubscriptionReceipts }, errors }) => {
        if (!activeSubscriptionReceipts || (errors && errors?.[0])) {
          throw new Error();
        }

        return activeSubscriptionReceipts;
      });
  }

  async getUsageHistory(
    page: number
  ): Promise<PaginatedSubscriptionUsageHistory> {
    return this.root.client
      .query<QUERY_SUBSCRIPTION_USAGE_HISTORY_RESPONSE>({
        query: GET_SUBSCRIPTION_USAGE_HISTORY,
        variables: {
          page,
          size: 5,
        },
      })
      .then(({ data: { subscriptionUsageHistory }, errors }) => {
        if (!subscriptionUsageHistory || (errors && errors?.[0])) {
          throw new Error();
        }

        return subscriptionUsageHistory;
      });
  }

  async getBillingCountries(): Promise<BillingCountry[]> {
    return this.root.client
      .query<QUERY_BILLING_COUNTRIES_RESPONSE>({
        query: GET_BILLING_COUNTRIES,
      })
      .then(({ data: { billingCountries }, errors }) => {
        if (!billingCountries || (errors && errors?.[0])) {
          throw new Error();
        }

        return billingCountries;
      })
      .catch(() => {
        return [];
      });
  }

  async getCurrentNamespaces(): Promise<{
    count: number;
    rows: NamespaceRole[];
  }> {
    const {
      data: { getNamespaces },
      errors,
    } = await this.root.client.query<QUERY_GET_NAMESPACES_RESPONSE>({
      query: GET_NAMESPACES,
    });
    if (!getNamespaces || errors?.[0]) {
      throw new Error();
    }

    return getNamespaces;
  }

  async getCurrentFlows(): Promise<Flow[]> {
    const {
      data: { getFlows },
      errors,
    } = await this.root.client.query<GET_FLOWS_TYPE>({
      query: GET_ALL_FLOWS,
    });
    if (!getFlows || errors?.[0]) {
      throw new Error();
    }

    return getFlows;
  }

  async getOverviewData(
    start: number,
    end: number,
    type: string,
    namespaces: string[],
    flows: string[]
  ): Promise<SubscriptionUsage> {
    return this.root.client
      .query<QUERY_SUBSCRIPTION_USAGE_RESPONSE>({
        query: GET_ACTIVE_SUBSCRIPTION_USAGE,
        variables: { start, end, type, namespaces, flows },
      })
      .then(({ data: { activeSubscriptionUsage }, errors }) => {
        if (!activeSubscriptionUsage || (errors && errors?.[0])) {
          throw new Error();
        }

        return activeSubscriptionUsage;
      });
  }

  async updateBilling(formData: BillingCustomer): Promise<boolean> {
    return this.root.client
      .mutate<MUTATION_UPDATE_BILLING_RESPONSE>({
        mutation: UPDATE_BILLING_CUSTOMER,
        variables: formData,
      })
      .then(({ data, errors }) => {
        if (!data || !data?.updateBillingCustomer || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        void this.loadSubscriptionData(
          this.root.userStore.currentUserPermissions?.can("read", "flows") ??
            false
        );

        return true;
      });
  }

  setPlanToUpgradeId(planId: string): void {
    this.upgradePlanId = planId;
  }

  setPlanToUpgradeDraft(planId: string): void {
    this.draftUpgradePlanId = planId;
  }

  setContractTermType(contractTerm: string): void {
    this.contractTerm = contractTerm;
  }

  // Will update only subscription, no billing customer update or other change
  upgradeToPlan(id?: string): Promise<boolean> {
    this.changeSubscriptionInProgress = true;

    return this.root.client
      .mutate<MUTATION_UPDATE_SUBSCRIPTION_PLAN_RESPONSE>({
        mutation: UPDATE_SUBSCRIPTION_PLAN,
        variables: {
          planId: id || this.upgradePlanId,
        },
      })
      .then(({ data, errors }) => {
        if (!data || !data?.updateSubscriptionPlan || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        return true;
      })
      .catch((error) => {
        runInAction(() => {
          this.changeSubscriptionInProgress = false;
        });
        throw error;
      });
  }

  changeSubscription(paymentMethodId?: string): Promise<boolean> {
    this.changeSubscriptionInProgress = true;

    let selectedPlan = this.subscriptionPlans?.find(
      (item) => item.id === this.upgradePlanId
    );

    if (selectedPlan && selectedPlan.period !== this.contractTerm) {
      const yearlyPlan = this.subscriptionPlans?.find(
        (item) =>
          item.plan === selectedPlan?.plan && item.period === this.contractTerm // TODO: Move to a getter
      );
      if (yearlyPlan) {
        selectedPlan = yearlyPlan;
      }
    }

    return this.root.client
      .mutate<MUTATION_CHANGE_SUBSCRIPTION_PLAN_RESPONSE>({
        mutation: CHANGE_SUBSCRIPTION_PLAN,
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          paymentMethodId,
          planId: selectedPlan?.id,
          billingData: BillingHelper.undoFlattenBillingData(
            this.flatBillingCustomer,
            this.flatBillingCompany,
            this.countries
          ),
        },
      })
      .then(({ data, errors }) => {
        if (!data || !data?.changeSubscriptionPlan || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        runInAction(() => {
          this.changeSubscriptionInProgress = false;
        });
        return true;
      })
      .catch((error) => {
        runInAction(() => {
          this.changeSubscriptionInProgress = false;
        });
        throw error;
      });
  }

  cancelSubscription(): Promise<boolean> {
    return this.root.client
      .mutate<MUTATION_CANCEL_SUBSCRIPTION_RESPONSE>({
        mutation: CANCEL_SUBSCRIPTION,
      })
      .then(({ data, errors }) => {
        if (!data || !data?.cancelSubscription || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        return true;
      });
  }

  updateCustomerDefaultCard(paymentMethodId: string): Promise<boolean> {
    return this.root.client
      .mutate<MUTATION_UPDATE_DEFAULT_PAYMENT_METHOD_RESPONSE>({
        mutation: UPDATE_DEFAULT_PAYMENT_METHOD,
        variables: {
          paymentMethodId,
        },
      })
      .then(({ data, errors }) => {
        if (
          !data ||
          !data?.updateBillingCustomerPaymentMethod ||
          (errors && errors?.[0])
        ) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        return data?.updateBillingCustomerPaymentMethod;
      });
  }
  sendContactSalesMessage(
    from: string,
    subject: string,
    content: {
      [key: string]: string;
    }
  ): Promise<boolean> {
    return this.root.client
      .mutate<MUTATION_SEND_SALES_EMAIL_RESPONSE>({
        mutation: SEND_SALES_EMAIL,
        variables: {
          from,
          subject,
          content,
        },
      })
      .then(({ data, errors }) => {
        if (!data || !data?.sendSalesEmail || (errors && errors?.[0])) {
          if (errors?.[0]) throw new Error(errors?.[0]?.message);
          else throw new Error();
        }

        return data?.sendSalesEmail;
      });
  }
}
