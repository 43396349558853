import { TFunction } from "i18next";
import React, { FC } from "react";

import { SelectProps } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { FormField, FormData } from "../../types/interfaces";
import { FormFieldProps } from "../../types/types";
import CoreSelect from "../core/CoreSelect";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  onChange: (value: FormData) => void;
  errorText?: string;
  description?: string;
  otherProps?: FormFieldProps;
  extraActions?: React.ReactNode;
  forConnections?: boolean;
}

export const SelectRenderer: FC<Props> = ({
  translation,
  field,
  value = "",
  onChange,
  otherProps,
  errorText,
  description,
  extraActions,
  forConnections,
}) => {
  const handleChange = (
    event: SelectChangeEvent<string | number | boolean | unknown>
  ) => {
    const newValue = { [field.key]: event.target.value };
    onChange(newValue);
  };

  return (
    <CoreSelect
      placeholder={translation(field.translationKey)}
      options={field.options ?? []}
      onChange={handleChange}
      value={value}
      errorText={errorText}
      title={field.name}
      description={translation(description || "")}
      isMandatory={field.isMandatory}
      extraActions={extraActions}
      forConnections={forConnections}
      {...(otherProps as SelectProps)}
    />
  );
};
