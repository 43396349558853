import React, { FC } from "react";
import { TFunction } from "i18next";
import { useParams } from "react-router";
import { useDropzone } from "react-dropzone";
import { observer } from "mobx-react";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { useTheme } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

import CoreButton from "../../../core/CoreButton";
import { ExtendedFile } from "../../../../types/interfaces";
import { DOCUMENT_UPLOAD_STATUS } from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { useApiClient } from "../../../../api/useApiClient";
import UploadedFilesDisplay from "./UploadedFilesDisplay";

interface Props {
  t: TFunction;
  active: boolean;
  handleClose: (refreshData?: boolean) => void;
}

export const UploadDocuments: FC<Props> = observer(
  ({ active, t, handleClose }) => {
    const theme = useTheme();
    const useStyles = makeStyles({
      main: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3px",
        width: "100%",
      },
      mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "100%",
        height: "100%",
        justifyContent: "space-between",
      },
      divider: {
        marginRight: "1rem",
        marginLeft: "1rem",
      },
      contentContainer: {
        display: "flex",
        flexDirection: "row",
        maxHeight: "450px",
        height: "100%",
        justifyContent: "center",
        padding: "2rem 2rem 0 2 rem",
        gap: "2rem",
        alignItem: "center",
        "@media screen and (max-width:1200px)": {
          overflow: "auto",
          display: "block",
        },
      },
      uploadBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "5px",
        height: "100%",
        width: "40%",
        "@media (max-width:1200px)": {
          width: "100%",
          justifyContent: "center",
        },
      },
      modalHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingTop: "1.5rem",
        paddingRight: "20px",
        paddingLeft: "30px",
      },
      dragAndDropBox: {
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.neutral.main,
        border: "5px dashed",
        borderColor: theme.palette.secondary.main,
        justifyContent: "center",
        flexDirection: "column",
        opacity: "0.4",
      },
      dropTitle: {
        fontSize: "1.5rem",
      },
      dropIcon: {
        fontSize: "8.5rem",
      },
      modalFooter: {
        width: "100%",
        height: "100%",
        maxHeight: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "20px 20px 20px 0",
      },
      paper: {
        minHeight: "50%",
        width: "70%",
        minWidth: "300px",
        display: "block",
      },
      boxSupportedFiles: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        opacity: "0.4",
      },
      supportedFiles: {
        fontWeight: "bold",
        marginBottom: "10px",
      },
      boxDnDText: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "2rem",
      },
      btnUpload: {
        margin: "10px 0",
      },
      modal: {
        display: "flex",
        justifyContent: "center",
      },
    });

    const apiClient = useApiClient();
    const { flowStore } = useStores();
    const notification = useNotification();
    const { id } = useParams<{ id: string }>();
    const classes = useStyles();

    const [files, setFiles] = React.useState<ExtendedFile[]>([]);
    const [isOnDrop, setIsOnDrop] = React.useState(false);

    const { getRootProps, getInputProps, open } = useDropzone({
      multiple: true,
      noClick: true,
      noKeyboard: true,
      onDrop: (newFiles: File[]) => {
        if (newFiles.length > 0) {
          setFiles([
            ...files,
            ...newFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ]);
        } else {
          notification.error(t("fileTypeError"));
        }
        setIsOnDrop(false);
      },
      onDragEnter: () => setIsOnDrop(true),
      onDragLeave: () => setIsOnDrop(false),

      accept: {
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
        "image/tiff": [".tiff"],
        "application/pdf": [".pdf"],
      },
    });

    const createEvent = (status: DOCUMENT_UPLOAD_STATUS, index: number) => {
      return new CustomEvent(`upload-${index}`, {
        detail: {
          status: status,
        },
      });
    };

    const uploadFile = async (
      file: ExtendedFile,
      index: number
    ): Promise<boolean> => {
      window.dispatchEvent(
        createEvent(DOCUMENT_UPLOAD_STATUS.upload_running, index)
      );

      try {
        await flowStore.sendDocuments(file, apiClient, id ?? "");
        window.dispatchEvent(
          createEvent(DOCUMENT_UPLOAD_STATUS.upload_success, index)
        );
        return true;
      } catch {
        window.dispatchEvent(
          createEvent(DOCUMENT_UPLOAD_STATUS.upload_failed, index)
        );
        return false;
      }
    };

    const sendData = () => {
      const uploadPromises = files.map((file, index) =>
        uploadFile(file, index)
      );

      Promise.all(uploadPromises)
        .then((results) => {
          if (results.every((result) => result)) {
            handleClose(true);
            setFiles([]);
            notification.success(t("uploadSuccess"));
          } else {
            notification.error(t("uploadError"));
          }
        })
        .catch(() => {
          notification.error(t("uploadError"));
        });
    };

    const discardFiles = () => {
      handleClose();
      setFiles([]);
    };

    return (
      <Modal className={classes.modal} open={active} onClose={discardFiles}>
        <Box className={classes.main} {...getRootProps()}>
          <input {...getInputProps()} />

          <Paper className={classes.paper}>
            <Box className={classes.mainContainer}>
              <div className={classes.modalHeader}>
                <Typography variant="h6">
                  {t("uploadDocumentsTitle")}
                </Typography>

                <IconButton onClick={discardFiles}>
                  <CloseOutlinedIcon />
                </IconButton>
              </div>

              <Divider className={classes.divider} />

              <Box className={classes.contentContainer}>
                {isOnDrop ? (
                  <Box
                    className={classes.dragAndDropBox}
                    style={{ width: files.length > 0 ? "50%" : "100%" }}
                  >
                    <CloudUploadOutlinedIcon className={classes.dropIcon} />
                    <Typography className={classes.dropTitle}>
                      {t("dropDocMessage")}
                    </Typography>
                  </Box>
                ) : (
                  <Box className={classes.uploadBox}>
                    <Box className={classes.boxDnDText}>
                      <Typography variant="h6">{t("dragAndDrop")}</Typography>
                      <Typography variant="h6">{t("or")}</Typography>
                      <CoreButton
                        className={classes.btnUpload}
                        onClick={open}
                        variant="outlined"
                        disabled={flowStore.isDocumentUploading}
                      >
                        {t("browseFiles")}
                      </CoreButton>
                    </Box>
                    <Box className={classes.boxSupportedFiles}>
                      <Typography
                        variant="caption"
                        className={classes.supportedFiles}
                      >
                        {t("supportedFiles")}
                      </Typography>
                      <Typography variant="caption">
                        {t("supportedFiles_List")}
                      </Typography>
                    </Box>
                  </Box>
                )}

                {files.length > 0 && (
                  <UploadedFilesDisplay
                    files={files}
                    t={t}
                    onUpdate={(updatedFiles) => setFiles(updatedFiles)}
                    isDisabled={flowStore.isDocumentUploading}
                  />
                )}
              </Box>

              <Box className={classes.modalFooter}>
                <CoreButton
                  onClick={sendData}
                  variant="contained"
                  disabled={flowStore.isDocumentUploading || files.length === 0}
                  isLoading={flowStore.isDocumentUploading}
                >
                  {t("upload")}
                </CoreButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Modal>
    );
  }
);
