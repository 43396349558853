import React, { useMemo } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import {
  FlowNode,
  FormData,
  FormField,
  SelectOptionDef,
} from "../../types/interfaces";
import CoreSelect from "../core/CoreSelect";
import { useStores } from "../../stores/StoresProvider";
import { NODES_ALLOWED_TYPES } from "../../types/constants";
import NodesHelper from "../pages/flow/flow-settings/helper/nodesHelper";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  onChange: (value: FormData) => void;
  errorText?: string;
  type?: string;
  description?: string;
}

const ExecutionSchemaRenderer: React.FC<Props> = observer(
  ({ translation, field, value = [], onChange, errorText, description }) => {
    const { flowSettingsStore } = useStores();
    const theme = useTheme();
    const reactFlow = useReactFlow();

    const classes = makeStyles({
      placeholderStyle: {
        "&.MuiButtonBase-root": {
          "&.MuiMenuItem-root": {
            "&.Mui-disabled": {
              opacity: 0.7,
            },
          },
        },
      },
      optionStyle: {
        width: "100%",
        height: "100%",
        "&.MuiButtonBase-root": {
          "&.MuiMenuItem-root": {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    })();

    const nodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const edges = useMemo(
      () => reactFlow.getEdges(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const handleChange = (
      event: SelectChangeEvent<string | number | boolean | unknown>
    ) => {
      const newValue = { [field.key]: event.target.value };
      onChange(newValue);
    };

    const contextVariables = useMemo(() => {
      const allowedTypes = NodesHelper.validateAllowedTypes(
        field?.allowedTypes
      );
      const diagramNodes = NodesHelper.attachSource(nodes, edges);

      return flowSettingsStore
        .getAllContextVariables(diagramNodes)
        ?.filter((variable) => {
          if (
            allowedTypes?.some(
              (allowedType) => allowedType === NODES_ALLOWED_TYPES.any
            )
          ) {
            return true;
          }

          return allowedTypes?.some(
            (allowedType) => allowedType === variable?.type
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      nodes,
      edges,
      flowSettingsStore.nodeIdToEdit,
      flowSettingsStore.parentNodeId,
      field?.allowedTypes,
    ]);

    const formattedValue = useMemo(() => {
      if (!value || (value as string[])?.length === 0) {
        return [];
      }

      if (Array.isArray(value)) {
        return value as string[];
      }

      return [value as string];
    }, [value]);

    const formattedOptions = useMemo(() => {
      if (!contextVariables) {
        return [];
      }

      return contextVariables.map((variable) => {
        if (variable?.isParent) {
          const children = contextVariables?.filter(
            (child) => child?.parentKey === variable?.key
          );

          const selectedChildren = ((value as string[]) || [])?.filter((item) =>
            children?.map((child) => child?.key)?.includes(item)
          );

          const isParentCheck =
            selectedChildren?.length === children?.length &&
            selectedChildren?.length > 0
              ? true
              : false;

          const isIndeterminate =
            selectedChildren?.length > 0 &&
            selectedChildren?.length < children?.length
              ? true
              : false;

          return {
            ...variable,
            isIndeterminate: isIndeterminate,
            isChecked: isParentCheck,
            onClick: () => {
              let newValue;

              if (isParentCheck) {
                newValue = new Set(
                  ((value as string[]) || [])?.filter(
                    (item) =>
                      !children?.map((child) => child?.key)?.includes(item)
                  )
                );
              } else {
                newValue = new Set([
                  ...((value as string[]) || []),
                  ...children.map((child) => child?.key),
                ]);
              }

              onChange({ [field.key]: Array.from(newValue) });
            },
          };
        }

        return variable;
      }) as unknown as SelectOptionDef[];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contextVariables, value, field.key]);

    const handleRenderSelect = (
      selected: unknown,
      placeholder: string
    ): React.ReactNode => {
      if (!selected || (selected as string[])?.length === 0) {
        return <Typography>{translation(placeholder)}</Typography>;
      }

      const selectedItems = (selected as string[]) || [];

      const selectedItemsString = selectedItems
        ?.map((item) => {
          const selectedOption = formattedOptions?.find(
            (option) => option.key === item
          );

          return selectedOption ? selectedOption.label : "";
        })
        .join(", ");

      return <Typography>{selectedItemsString}</Typography>;
    };

    return (
      <CoreSelect
        placeholder={translation("contextVariables")}
        options={formattedOptions}
        onChange={handleChange}
        value={formattedValue || []}
        errorText={errorText}
        isMultipleSelect={field.isMultipleSelect}
        title={field.name}
        description={translation(description || "")}
        isMandatory={field.isMandatory}
        hasCheckbox={true}
        renderValue={(selected) =>
          handleRenderSelect(selected, "selectContextVariables")
        }
        disabled={!flowSettingsStore.isDiagramEditable}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiList-root.MuiMenu-list": {
                "& .Mui-disabled": {
                  color: theme.palette.text.disabled,
                },
                backgroundColor: theme.palette.background.paper,
              },
            },
          },
        }}
        placeholderStyle={classes.placeholderStyle}
        isForSchema={true}
        optionStyle={classes.optionStyle}
      />
    );
  }
);

export default ExecutionSchemaRenderer;
