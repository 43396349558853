import React, { FC } from "react";

import { CSSProperties } from "@mui/styles";
import { useTheme, Avatar, Theme } from "@mui/material";

import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PauseTwoToneIcon from "@mui/icons-material/PauseTwoTone";

interface Props {
  level: string | undefined;
}

export enum LOG_LEVEL_TYPES {
  fatal = "fatal",
  alert = "alert",
  critical = "critical",
  error = "error",
  warn = "warn",
  warning = "warning",
  info = "info",
  notice = "notice",
  success = "success",
  debug = "debug",
  pause = "pause",
}

const getContent = (level: string | undefined): React.ReactNode => {
  switch (level?.toLowerCase()) {
    case LOG_LEVEL_TYPES.fatal:
    case LOG_LEVEL_TYPES.alert:
    case LOG_LEVEL_TYPES.critical:
      return "!!!";
    case LOG_LEVEL_TYPES.error:
      return "!!";
    case LOG_LEVEL_TYPES.warn:
    case LOG_LEVEL_TYPES.warning:
      return "!";
    case LOG_LEVEL_TYPES.info:
    case LOG_LEVEL_TYPES.notice:
      return "!";
    case LOG_LEVEL_TYPES.success:
      return <CheckOutlinedIcon sx={{ fontSize: "15px" }} />;
    case LOG_LEVEL_TYPES.pause:
      return <PauseTwoToneIcon sx={{ fontSize: "15px" }} />;
    case LOG_LEVEL_TYPES.debug:
      return <BugReportOutlinedIcon sx={{ fontSize: "15px" }} />;
    default:
      return "!";
  }
};

const getStyles = (level: string | undefined, theme: Theme): CSSProperties => {
  const commonStyles = {
    borderRadius: "100%",
    width: "40px",
    height: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  } as CSSProperties;

  let levelStyles = {} as CSSProperties;

  switch (level?.toLowerCase()) {
    case LOG_LEVEL_TYPES.fatal:
    case LOG_LEVEL_TYPES.alert:
    case LOG_LEVEL_TYPES.critical:
      levelStyles = {
        backgroundColor: theme.palette.error.main,
      };
      break;
    case LOG_LEVEL_TYPES.error:
      levelStyles = {
        backgroundColor: theme.palette.error.main,
      };
      break;
    case LOG_LEVEL_TYPES.warn:
    case LOG_LEVEL_TYPES.warning:
    case LOG_LEVEL_TYPES.pause:
      levelStyles = {
        backgroundColor: theme.palette.warning.main,
        transform: "rotate(180deg)",
      };
      break;
    case LOG_LEVEL_TYPES.info:
    case LOG_LEVEL_TYPES.notice:
    case LOG_LEVEL_TYPES.success:
      levelStyles = {
        backgroundColor: theme.palette.info.main,
      };
      break;
    case LOG_LEVEL_TYPES.debug:
      levelStyles = {
        backgroundColor: theme.palette.info.main,
      };
      break;
    default:
  }

  return { ...commonStyles, ...levelStyles };
};

export const CoreLevelIcon: FC<Props> = ({ level }) => {
  const theme = useTheme();

  const content = getContent(level);
  const style = getStyles(level, theme);

  return (
    <Avatar
      sx={{
        ...style,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-end",
        width: "15px",
        height: "15px",
        fontSize: "14px",
        color: theme.palette.background.paper,
        fontWeight: "bold",
      }}
    >
      {content}
    </Avatar>
  );
};
