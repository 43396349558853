import React, { useMemo } from "react";
import { observer } from "mobx-react";
import Showdown from "react-showdown";

import { makeStyles } from "@mui/styles";
import {
  alpha,
  useTheme,
  Box,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

import { MessageLevelIcon } from "./MessageLevelIcon";
import { useStores } from "../../../../../../stores/StoresProvider";
import { MESSAGE_LEVELS } from "../../../../../../types/interfaces";
import { THEME_MODES } from "../../../../../../types/constants";

const MESSAGE_TEXT_VARIANT = {
  [MESSAGE_LEVELS.error]: "body2",
  [MESSAGE_LEVELS.warning]: "body2",
  [MESSAGE_LEVELS.info]: "body2",
  [MESSAGE_LEVELS.header]: "subtitle1",
  [MESSAGE_LEVELS.subheader]: "subtitle2",
  [MESSAGE_LEVELS.success]: "body2",
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
    paddingLeft: "9px",
    height: "inherit",
  },
  headerRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gridGap: "10px",
    height: "45px",
  },
  messagesRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    overflow: "auto",
  },
  messageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflowWrap: "break-word",
    wordWrap: "break-word",
    wordBreak: "break-word",
  },
  messageIcon: {
    alignSelf: "center",
    width: "20px",
    height: "20px",
  },
});

export const MessagesPanel = observer(() => {
  const { documentStore, mainStore } = useStores();
  const theme = useTheme();
  const classes = useStyles();

  const messageDetails = documentStore.messagePanelData;

  const exitPanel = () => {
    documentStore.setMessagePanelData(null);
  };

  const title = useMemo(() => {
    if (documentStore.isLineItemsMode) {
      return (
        documentStore.focusedLineItem?.name || documentStore.focusedFieldCanvas
      );
    }
    return (
      documentStore.documentFields?.find(
        (item) => item.key === documentStore.focusedFieldCanvas
      )?.name || documentStore.focusedFieldCanvas
    );
  }, [
    documentStore.documentFields,
    documentStore.focusedFieldCanvas,
    documentStore.focusedLineItem?.name,
    documentStore.isLineItemsMode,
  ]);

  const getMessageLevelStyles = (level: MESSAGE_LEVELS) => {
    switch (level) {
      case MESSAGE_LEVELS.info:
        return {
          color: theme.palette.info["100"] || "inherit",
          backgroundColor:
            mainStore.currentTheme === THEME_MODES.light
              ? theme.palette.info["50"] || "inherit"
              : alpha(theme.palette.highlight.main, 0.05),
        };
      case MESSAGE_LEVELS.warning:
        return {
          color: theme.palette.warning["100"] || "inherit",
          backgroundColor:
            mainStore.currentTheme === THEME_MODES.light
              ? theme.palette.warning["50"] || "inherit"
              : alpha(theme.palette.warning.main, 0.05),
        };
      case MESSAGE_LEVELS.error:
        return {
          color: theme.palette.error["100"] || "inherit",
          backgroundColor:
            mainStore.currentTheme === THEME_MODES.light
              ? theme.palette.error["50"] || "inherit"
              : alpha(theme.palette.error.dark, 0.05),
        };
      default:
        return {};
    }
  };

  if (!messageDetails) {
    return <></>;
  }

  return (
    <Box className={classes.root}>
      {/* Header */}
      <Box className={classes.headerRoot}>
        <IconButton size="medium" onClick={exitPanel}>
          <ChevronLeftIcon />
        </IconButton>

        <Typography variant="body1">{title}</Typography>
      </Box>

      <Divider />

      {/* Messages display */}
      <Box className={classes.messagesRoot}>
        {messageDetails.map((messageData, index) => {
          // Define the containsHTML function within the map function

          return (
            <React.Fragment key={index}>
              <Box className={classes.messageBox}>
                {messageData?.isSubError ? (
                  <SubdirectoryArrowRightIcon
                    sx={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      fontSize: "20px",
                      color: alpha(theme.palette.text.primary, 0.5),
                    }}
                  />
                ) : (
                  <></>
                )}

                <Box
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "20px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingBottom:
                      messageData.level === MESSAGE_LEVELS.header
                        ? "0px"
                        : "20px",

                    gridGap: "10px",
                    flex: 1,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(getMessageLevelStyles(messageData.level) as any),
                    ...(messageData.isSubError
                      ? {
                          borderLeft: `1px solid ${alpha(
                            theme.palette.divider,
                            0.2
                          )}`,
                        }
                      : {}),
                  }}
                >
                  {/* Fixed width and height to have a symmetrical alignment with error message */}
                  <Box className={classes.messageIcon}>
                    <MessageLevelIcon level={messageData.level} />
                  </Box>

                  <Box
                    sx={{
                      color: "inherit",
                      overflowWrap: "break-word",
                    }}
                  >
                    <Showdown
                      markdown={messageData?.message || "-"}
                      components={{
                        p: ({ children }) => (
                          <Typography
                            sx={{
                              color: "inherit",
                              overflowWrap: "break-word",
                              margin: 0,
                            }}
                            variant={
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
                              MESSAGE_TEXT_VARIANT[messageData.level] as any
                            }
                          >
                            {children}
                          </Typography>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              {messageData.level === MESSAGE_LEVELS.header ? (
                <></>
              ) : (
                <Divider />
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
});
