import React, { FC, useState } from "react";
import { TFunction } from "i18next";
import { makeStyles } from "@mui/styles";
import { Menu, useTheme, Box, IconButton } from "@mui/material";
import Flag from "react-flagkit";
import { THEME_MODES, LANGUAGES } from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";
import LanguagesMenu from "./LanguagesMenu";

interface Props {
  t: TFunction;
}

const Languages: FC<Props> = ({ t }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const { mainStore } = useStores();
  const { currentTheme } = mainStore;

  const useStyle = makeStyles({
    paper: {
      background: "none",
      backgroundColor:
        currentTheme === THEME_MODES.dark
          ? theme.palette.secondary.main
          : theme.palette.secondary.light,
    },
    list: {
      width: "150px",
      padding: "0px",
    },
    flag: {
      width: "20px",
      height: "20px",
    },
  });

  const classes = useStyle();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton edge="start" aria-label="menu" onClick={handleClick}>
        <Flag country={mainStore.languageFlag} className={classes.flag} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
      >
        <LanguagesMenu countries={LANGUAGES} t={t} />
      </Menu>
    </Box>
  );
};

export default Languages;
