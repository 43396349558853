import {
  DOCUMENT_FILTERS,
  DOCUMENT_FILTERS_ALL_FLOWS,
  DOCUMENT_FILTERS_ALL_FLOWS_RO,
  DOCUMENT_FILTERS_RO,
} from "../../../../../types/constants";
import { TableHeaderDef } from "../../../../../types/interfaces";

// TODO: Add more operators based on what is supported on python side after implementation
export const OPERATORS = {
  CONTAINS: "CONTAINS",
  IN: "IN",
  NOT_IN: "NOT_IN",
};

export class FlowHelper {
  static get tableHead(): TableHeaderDef[] {
    return [
      { accessor: "select" },
      {
        accessor: "name",
        translationKey: "tableHeader_documentName",
        options: {
          isSortable: true,
        },
      },
      {
        accessor: "createdAt",
        translationKey: "tableHeader_createdAt",
        options: {
          isSortable: true,
        },
      },

      {
        accessor: "updatedAt",
        translationKey: "tableHeader_updatedAt",
        options: {
          isSortable: true,
        },
      },
      {
        accessor: "status",
        translationKey: "tableHeader_status",
      },
      {
        accessor: "numberOfPages",
        translationKey: "tableHeader_nrOfPages",
        options: {
          isSortable: true,
        },
      },
    ];
  }

  static get tableHeadAllFlows(): TableHeaderDef[] {
    return [
      { accessor: "select" },
      {
        accessor: "flowName",
        translationKey: "tableHeader_flowName",
        options: {
          isSortable: true,
        },
      },
      {
        accessor: "name",
        translationKey: "tableHeader_documentName",
        options: {
          isSortable: true,
        },
      },
      {
        accessor: "createdAt",
        translationKey: "tableHeader_createdAt",
        options: {
          isSortable: true,
          isSortActive: true,
          sortDirection: "desc",
        },
      },
      {
        accessor: "updatedAt",
        translationKey: "tableHeader_updatedAt",
        options: {
          isSortable: true,
        },
      },
      {
        accessor: "status",
        translationKey: "tableHeader_status",
      },
    ];
  }

  static generateTableHead(id: string): TableHeaderDef[] {
    if (id) {
      return this.tableHead;
    }
    return this.tableHeadAllFlows;
  }

  static generateColumnFilters = (id: string) => {
    if (id) {
      return DOCUMENT_FILTERS;
    }
    return DOCUMENT_FILTERS_ALL_FLOWS;
  };

  static generateRoColumnFilters = (id: string) => {
    if (id) {
      return DOCUMENT_FILTERS_RO;
    }
    return DOCUMENT_FILTERS_ALL_FLOWS_RO;
  };

  // FIXME: Extend when field types are introduced on backend
  static getTypeOperators(type = "input"): string[] {
    switch (type) {
      case "input":
      default:
        return [OPERATORS.CONTAINS, OPERATORS.IN, OPERATORS.NOT_IN];
    }
  }
}
