import React, { FC } from "react";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import { createStyles, makeStyles, CSSProperties } from "@mui/styles";

import { TableContentDef } from "../../../types/interfaces";
import { FIELD_TYPE, HOVER_COLUMNS } from "../../../types/constants";

interface Props {
  cell: keyof TableContentDef;
  row: TableContentDef;
  columnWidth?: TableContentDef;
  rowHeight?: TableContentDef;
  styleProps?: CSSProperties;
}

const useStyles = makeStyles(() =>
  createStyles({
    cellContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);

export const CoreCell: FC<Props> = ({
  cell,
  row,
  columnWidth = {},
  rowHeight = {},
  styleProps = {},
}) => {
  const classes = useStyles();

  return (
    <TableCell
      sx={{
        width: (columnWidth[cell] as string) || undefined,
        height: (rowHeight[cell] as string) || undefined,
        ...styleProps,
      }}
      padding={FIELD_TYPE.checkbox}
      colSpan={cell === HOVER_COLUMNS.actions ? 3 : 1}
    >
      <Box className={classes.cellContainer}>
        {(row[cell] as string) || "-"}
      </Box>
    </TableCell>
  );
};
