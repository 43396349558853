import React, { FC, useMemo } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import Box from "@mui/material/Box";

import CoreTooltip from "../../../../../core/CoreTooltip";
import { DocumentMessageType } from "../../../../../../types/interfaces";
import { MessageLevelIcon } from "./MessageLevelIcon";
import { useStores } from "../../../../../../stores/StoresProvider";

interface Props {
  translation: TFunction;
  message?: DocumentMessageType;
  details?: DocumentMessageType[];
}

export const MessageTooltipBox: FC<Props> = observer(
  ({ message, details, translation }) => {
    const { documentStore } = useStores();

    const hintMessage = useMemo(
      () => message?.message || translation("fieldWithMessage"),
      [message, translation]
    );

    const hasDetails = useMemo(() => details && details?.length > 0, [details]);

    const onOpenMessagesPanel = () => {
      if (details && details?.length > 0) {
        documentStore.setMessagePanelData(details);
      }
    };

    return (
      <CoreTooltip title={hintMessage} titleCharLimit={350} placement="top">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            cursor: hasDetails ? "pointer" : "auto",
          }}
          onClick={onOpenMessagesPanel}
        >
          <MessageLevelIcon level={message?.level} />
        </Box>
      </CoreTooltip>
    );
  }
);
