import { alpha, Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiIconButton = (
  theme: ThemeOptions
): Components["MuiIconButton"] => {
  const palette = theme.palette as ThemePalette;
  return {
    styleOverrides: {
      root: {
        color: alpha(palette.highlight.light as string, 0.7),
        "&:hover": {
          color: palette.highlight.main,
        },
      },
    },
  };
};
