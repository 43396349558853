import React, { useMemo } from "react";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import CoreTooltip from "../../core/CoreTooltip";
import { useStores } from "../../../stores/StoresProvider";
import {
  VariablesAndFields,
  NODE_ASSET_TYPE,
  NODES_PARAMETERS_KEYS,
} from "../../../types/constants";
import { FlowNode } from "../../../types/interfaces";

interface Props {
  onDelete?: (key: string) => void;
  itemKey: string;
  fieldKey: string;
  t: TFunction;
}

const useStyles = makeStyles({
  iconStyle: {
    marginLeft: "5px",
    marginRight: "-11px",
  },
});

const DeleteVariableAction: React.FC<Props> = observer(
  ({ onDelete, itemKey, fieldKey, t }) => {
    const { flowSettingsStore } = useStores();
    const reactFlow = useReactFlow();
    const classes = useStyles();

    const nodes = useMemo(
      () => reactFlow?.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const isUsedInNodes = useMemo(() => {
      if (!flowSettingsStore.isDiagramEditable) {
        return false;
      }

      if (fieldKey !== VariablesAndFields.variables) {
        return false;
      }

      return nodes.some((node) => {
        const pathIncludesKey = [node?.inputPath, node?.outputPath]?.some(
          (path) => path?.includes(itemKey)
        );

        const specialCaseConditions =
          [
            NODE_ASSET_TYPE.claritextOcr,
            NODE_ASSET_TYPE.dataEncoding,
          ]?.includes(node?.key as NODE_ASSET_TYPE) &&
          [
            NODES_PARAMETERS_KEYS.executionSchema,
            NODES_PARAMETERS_KEYS.inputVariables,
          ]?.some((param) => {
            const paramValues = node?.parameters?.[param];
            if (Array.isArray(paramValues)) {
              return (
                paramValues?.includes(itemKey) ||
                paramValues?.some((value: string) => value?.includes(itemKey))
              );
            }
            return false;
          });

        return pathIncludesKey || specialCaseConditions;
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      flowSettingsStore.layoutingNeeded,
      flowSettingsStore.isDiagramEditable,
      fieldKey,
      itemKey,
      nodes,
    ]);

    const handleTitle = useMemo(() => {
      if (!flowSettingsStore.isDiagramEditable) {
        return "";
      }

      return !isUsedInNodes
        ? fieldKey === VariablesAndFields.variables
          ? t("clickToDeleteVariable")
          : t("clickToDeleteRule")
        : t("variableInUse");
    }, [isUsedInNodes, t, fieldKey, flowSettingsStore.isDiagramEditable]);

    if (!flowSettingsStore.isDiagramEditable || !onDelete) {
      return <></>;
    }

    return (
      <CoreTooltip title={handleTitle}>
        <span>
          <IconButton
            className={classes.iconStyle}
            onClick={() => onDelete(itemKey)}
            disabled={isUsedInNodes}
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </CoreTooltip>
    );
  }
);

export default DeleteVariableAction;
