import React, { FC, useState } from "react";
import { TFunction } from "i18next";
import PasswordStrengthBar from "react-password-strength-bar";

import { IconButton, Box, Typography } from "@mui/material";
import { InputProps } from "@mui/material/Input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { createStyles, makeStyles } from "@mui/styles";

import { FormField, FormData } from "../../types/interfaces";
import { FormFieldProps } from "../../types/types";
import CoreInput from "../core/CoreInput";
import { INPUT_TYPES } from "../../types/constants";

interface Props {
  translation: TFunction;
  field: FormField;
  value: string | number | boolean | unknown;
  onChange: (value: FormData) => void;
  errorText?: string;
  otherProps?: FormFieldProps;
  type?: string;
  hidePasswordController?: boolean;
  description?: string;
  tooltipLocation?: "title" | "input";
  extraActions?: React.ReactNode;
}

interface Rules {
  lowerCase: boolean;
  upperCase: boolean;
  number: boolean;
  specialChar: boolean;
  length: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    rulesBox: {
      display: "flex",
      alignItems: "center",
      margin: "auto",
    },
    containerRules: {
      display: "flex",
    },
    ruleIcon: {
      width: "15px",
    },
  })
);

export const InputRenderer: FC<Props> = ({
  field,
  translation,
  onChange,
  errorText,
  otherProps = {},
  value,
  description,
  type,
  tooltipLocation,
  extraActions,
}) => {
  const { hidePasswordController = false, ...otherCustomProps } = otherProps;

  const [showPassword, setShowPassword] = useState(false);

  const [rules, setRules] = useState<Rules>({
    lowerCase: false,
    upperCase: false,
    number: false,
    specialChar: false,
    length: false,
  });

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordEndAdornment = type === INPUT_TYPES.password &&
    !hidePasswordController && (
      <IconButton onClick={handlePasswordVisibility}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    );

  const inputType =
    type === INPUT_TYPES.password && showPassword ? INPUT_TYPES.input : type;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = { [field.key]: event.target.value };

    if (field?.showStrengthBar) {
      const password = event.target.value;
      const lowerCase = /(?=.*[a-z])/.test(password);
      const upperCase = /(?=.*[A-Z])/.test(password);
      const number = /(?=.*[0-9])/.test(password);
      const specialChar = /(?=.*[^A-Za-z0-9])/.test(password);
      const length = /(?=.{8,})/.test(password);

      setRules({
        lowerCase,
        upperCase,
        number,
        specialChar,
        length,
      });
    }
    onChange(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <CoreInput
        placeholder={translation(field.translationKey)}
        isMandatory={field.isMandatory}
        onChange={handleChange}
        errorText={errorText}
        value={value || ""}
        type={inputType}
        title={field.name}
        endAdornment={passwordEndAdornment}
        description={translation(description || "")}
        tooltipLocation={tooltipLocation}
        extraActions={extraActions}
        isTextArea={field.isTextArea}
        {...(otherCustomProps as InputProps)}
      />
      {field.showStrengthBar && (
        <PasswordStrengthBar
          password={value as string}
          scoreWords={[
            translation("tooShort"),
            translation("weak"),
            translation("okay"),
            translation("good"),
            translation("strong"),
          ]}
          shortScoreWord={translation("tooShort")}
        />
      )}
      {field.showRules && (
        <Box className={classes.containerRules}>
          {Object.keys(rules).map((rule) => {
            return (
              <Box key={rule} className={classes.rulesBox}>
                {rules[rule as keyof Rules] ? (
                  <CheckCircleOutlineIcon
                    color="success"
                    className={classes.ruleIcon}
                  />
                ) : (
                  <CancelIcon color="error" className={classes.ruleIcon} />
                )}
                <Typography typography="subtitle2">{rule}</Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};
