import moment from "moment";
import {
  Fields,
  getBillingInformationFields,
  getCompanyInformationFields,
  MAIN_FIELDS,
} from "../components/pages/settingsPage/components/subscription/resources/billingFields";
import { DATE_FORMAT } from "../types/constants";
import {
  BillingCountry,
  BillingCustomer,
  BillingCustomerCompany,
  BillingCustomerInformation,
  SubscriptionPlan,
} from "../types/interfaces/subscription";
import { emailRegex } from "../types/regex";

export const EMPTY_COMPANY_DATA = {
  name: null,
  email: null,
  address: {
    line1: null,
    line2: null,
    city: null,
    postalCode: null,
  },
  country: {
    name: null,
    code: null,
  },
  registrationNumber: null,
  taxId: null,
};

export default class BillingHelper {
  static convertSumToCurrency(
    value: number | string | undefined,
    currency: string | undefined
  ): string | number {
    if (!currency) {
      return value || "0";
    }

    try {
      const sum = value || 0;

      const validatedValue =
        typeof sum === "string"
          ? sum?.includes(".")
            ? parseFloat(sum)
            : parseInt(sum)
          : sum;

      const parsedVal = validatedValue.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      });

      return parsedVal;
    } catch {
      return value || "0";
    }
  }

  static sortSubscriptionPlans(plans: SubscriptionPlan[]): SubscriptionPlan[] {
    let filteredPlans = [] as SubscriptionPlan[];

    if (!plans || plans?.length === 0) {
      return filteredPlans;
    }

    filteredPlans = plans
      ?.sort(
        (plan1, plan2) =>
          Number(plan1?.subscriptionProperties?.order || "0") -
          Number(plan2?.subscriptionProperties?.order || "0")
      )
      ?.filter((plan) => plan?.subscriptionProperties?.visible === "true");

    return filteredPlans;
  }

  static formatBillingInformationData(
    data: BillingCustomerInformation | null | undefined
  ): {
    [key: string]: string;
  } {
    try {
      if (!data) {
        return {};
      }

      const { country, address, ...otherData } = data;

      return {
        ...otherData,
        ...address,
        country: country?.code || "",
      } as { [key: string]: string };
    } catch {
      return {};
    }
  }

  static flattenBillingData(customer: BillingCustomer): {
    [key: string]: string;
  } {
    try {
      const { id, name, phone, email, billingInformation } = customer;
      let response = {
        id,
        name,
        phone: phone || "",
        email,
      } as { [key: string]: string };

      if (billingInformation) {
        const { address, country, registrationNumber, taxId } =
          billingInformation;

        response = {
          ...response,
          ...(address || {}),
          country: country?.code || "",
          registrationNumber,
          taxId,
        };
      }

      return response;
    } catch (err) {
      return {};
    }
  }

  static flattenBillingCompanyData(
    company: BillingCustomerCompany | undefined
  ): {
    [key: string]: string;
  } {
    try {
      if (!company) {
        return {};
      }

      const { name, email, address, country, registrationNumber, taxId } =
        company;

      return {
        name,
        email,
        registrationNumber,
        taxId,
        ...(address || {}),
        country: country?.code || "",
      } as { [key: string]: string };
    } catch (err) {
      return {};
    }
  }

  static undoFlattenBillingData(
    flatCustomerData: {
      [key: string]: string;
    },
    flatCompanyData: {
      [key: string]: string;
    },
    countries: BillingCountry[]
  ): BillingCustomer | null {
    try {
      const {
        id,
        name,
        phone,
        email,
        country,
        registrationNumber,
        taxId,
        line1,
        line2,
        city,
        postalCode,
      } = flatCustomerData;

      const countryObj = countries?.find((item) => item.code === country);

      let payload = {
        id,
        name,
        phone,
        email,
        billingInformation: {
          address: {
            line1,
            line2,
            city,
            postalCode,
          },
          country: countryObj,
          registrationNumber,
          taxId,
        },
        companyInformation: EMPTY_COMPANY_DATA,
      } as BillingCustomer;

      // Add company details
      if (flatCompanyData?.["name"]) {
        const {
          name,
          email,
          country,
          registrationNumber,
          taxId,
          line1,
          line2,
          city,
          postalCode,
        } = flatCompanyData;
        const countryObj = countries?.find((item) => item.code === country);

        payload = {
          ...payload,
          companyInformation: {
            name,
            email,
            address: {
              line1,
              line2,
              city,
              postalCode,
            },
            country: countryObj as {
              name: string;
              code: string;
            },
            registrationNumber,
            taxId,
          },
        };
      }

      return payload;
    } catch {
      return null;
    }
  }

  static validateBillingForm(
    form: {
      [key: string]: string;
    },
    isForPlanSteps?: boolean,
    isCompanyForm?: boolean,
    isMainForm?: boolean
  ): { [key: string]: string } | null {
    let errors = {} as { [key: string]: string };

    let fields;
    if (isForPlanSteps) {
      fields = isCompanyForm
        ? getCompanyInformationFields([], true)
        : [...MAIN_FIELDS, ...getBillingInformationFields()];
    } else {
      fields = isCompanyForm
        ? getCompanyInformationFields([], true)
        : isMainForm
        ? MAIN_FIELDS
        : getBillingInformationFields();
    }

    fields.forEach((fieldConfig) => {
      if (!(fieldConfig.name in form) && !fieldConfig.isMandatory) {
        return;
      }

      const fieldValue = form?.[fieldConfig.name];
      if (fieldConfig.isMandatory && !fieldValue) {
        errors = {
          ...errors,
          [fieldConfig.name]: "billing_field_is_mandatory",
        };
      }

      switch (fieldConfig.name) {
        case Fields.email:
          if (!emailRegex.test(fieldValue)) {
            errors = {
              ...errors,
              [fieldConfig.name]: "billing_field_email_invalid",
            };
          }
          break;
        case Fields.city:
          if (!form?.[Fields.postalCode]) {
            errors = {
              ...errors,
              [Fields.postalCode]: "billing_field_is_mandatory",
            };
          }
          break;
        default:
        // Add more if needed
      }
    });

    if (Object.keys(errors)?.length > 0) {
      return errors;
    }

    return null;
  }

  static validateEmail(email: string): string | null {
    if (!email || email?.trim() === "") {
      return "billing_field_is_mandatory";
    }

    if (!emailRegex.test(email)) {
      return "billing_field_email_invalid";
    }

    return null;
  }

  static getActiveSubExpireDays(
    subscription: SubscriptionPlan | null
  ): number | null {
    if (
      !subscription ||
      subscription?.status !== "active" ||
      !subscription?.expiresAt
    ) {
      return null;
    }

    const currentDate = moment();
    const expireDate = moment(subscription?.expiresAt * 1000);

    return expireDate.diff(currentDate, "days");
  }

  static formatTimestamp(timestamp: number): string {
    return moment(timestamp * 1000).format(DATE_FORMAT.date);
  }

  static downloadFile = (url: string): Promise<Blob> => {
    return (
      fetch(url, {
        method: "GET",
        credentials: "include",
      })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .then((response) => {
          if (response?.status !== 200) {
            throw new Error();
          }

          return response.blob();
        })
    );
  };
}
