import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Pagination, Typography } from "@mui/material";
import CoreSelect from "../CoreSelect";
import { makeStyles } from "@mui/styles";

interface Props {
  page: number;
  changePage: (_: React.ChangeEvent<unknown>, newPage: number) => void;
  numberOfPages: number;
  rowsPerPage: number;
  totalCount?: number;
  setRowsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPageOptions?: {
    key: string;
    label: string;
  }[];
  isFixed?: boolean;
}

const CoreFooter: FC<Props> = ({
  page,
  rowsPerPage,
  totalCount,
  changePage,
  numberOfPages,
  rowsPerPageOptions,
  setRowsPerPage,
  isFixed = true,
}) => {
  const classes = makeStyles({
    footerSelect: {
      width: "70px !important",
    },
    selectHeight: {
      height: "32px",
    },
    textLabel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      margin: "10px",
      position: isFixed ? "fixed" : "relative",
    },
    customTypography: {
      fontSize: "0.875rem",
    },
    mainContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "220px",
      margin: "10px",
      position: "relative",
      minHeight: "30px",
    },
    pagination: {
      right: "20px",
      position: isFixed ? "fixed" : "relative",
    },
  })();
  const { t } = useTranslation("core");
  const options =
    typeof rowsPerPageOptions === "undefined" ? [] : rowsPerPageOptions;

  const documentRangeStart = (page - 1) * rowsPerPage + 1;
  const documentRangeEnd = Math.min(
    documentRangeStart + rowsPerPage - 1,
    totalCount ?? 0
  );

  return (
    <Box className={classes.mainContainer}>
      {rowsPerPageOptions && (
        <Box className={classes.textLabel}>
          <Typography className={classes.customTypography}>
            {t("itemsPerPage")}
          </Typography>

          <Box className={classes.footerSelect}>
            <CoreSelect
              formControlClass={classes.footerSelect}
              selectInputStyle={classes.selectHeight}
              options={options}
              value={rowsPerPage}
              onChange={(e) => {
                if (!setRowsPerPage) return;
                setRowsPerPage(parseInt(e.target.value as string) ?? 0);
              }}
            />
          </Box>
          <Typography
            className={classes.customTypography}
          >{`${documentRangeStart}-${documentRangeEnd} ${t("paginationNumber", {
            documentsNumber: totalCount ?? 0,
          })} `}</Typography>
        </Box>
      )}

      <Box className={classes.pagination}>
        <Pagination
          page={page ?? 0}
          count={numberOfPages ?? 0}
          onChange={changePage}
          variant="outlined"
          shape="rounded"
          boundaryCount={1}
          siblingCount={0}
        />
      </Box>
    </Box>
  );
};

export default CoreFooter;
