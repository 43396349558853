import React, { FC, useState, ChangeEventHandler } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { IconButton, useTheme, Box } from "@mui/material";

import CoreTooltip from "../../../core/CoreTooltip";
import CoreInput from "../../../core/CoreInput";
import {
  CHAT_GPT_MESSAGE_TYPES,
  ENTER_KEYS,
} from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { ChatGptMessage } from "../../../../types/interfaces";
import { AppSuspense } from "../../../main/AppSuspense";
import { appRoutes } from "../../../../configs/routes";

interface Props {
  placeholder: string;
  inputIcon: React.ReactNode;
  isForHome?: boolean;
}

const ChatGptInput: FC<Props> = observer(
  ({ isForHome = false, placeholder, inputIcon }) => {
    const { t, ready } = useTranslation("chatGpt");
    const { id } = useParams<{ id: string }>();
    const { pathname } = useLocation();
    const theme = useTheme();
    const notification = useNotification();
    const {
      chatGptStore: {
        addMessage,
        isResponseGenerating,
        isResponseLoading,
        addHomeMessage,
        getChatGptResponseHelper,
        setIsScrollDisabled,
      },
    } = useStores();

    const [input, setInput] = useState("");

    const inputIsDisabled =
      !input.length ||
      input?.trim()?.length < 4 ||
      isResponseGenerating ||
      isResponseLoading;

    const classes = makeStyles({
      input: {
        width: "100%",
        fontSize: "14px",
        height: "60px",
        "&.MuiOutlinedInput-root": {
          borderRadius: 0,
        },
      },
      textArea: {
        background: "none",
        width: "100%",
        minHeight: "80px",
        maxHeight: "500px",
        borderRadius: "5px",
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        padding: "10px 55px 10px 10px",
        overflow: "auto",
        resize: "vertical",
        fontSize: "14px",
        textWrap: "wrap",
        outline: "none",
      },
      boxForHome: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        width: "100%",
      },
      inputIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.dark,
      },
      sendButton: {
        position: "absolute",
        bottom: 25,
        right: 15,
        transform: "scale(0.8)",
      },
    })();

    const handleCoreInputChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setInput(event.target.value);
    };

    const handleTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = (
      event
    ) => {
      setIsScrollDisabled(false);
      setInput(event.target.value);
    };

    const addFunction = () => {
      if (!isResponseGenerating && !isResponseLoading) {
        const userMessage = {
          question: input?.trim(),
          type: CHAT_GPT_MESSAGE_TYPES.user,
          filter: {},
          answer: "",
        } as ChatGptMessage;

        if (!isForHome) {
          addMessage(userMessage);
        } else {
          addHomeMessage(userMessage);
        }

        void getChatGptResponseHelper(
          input,
          isForHome,
          !!id && pathname.includes(appRoutes.FlowDetails(id)) ? id : ""
        ).catch((error: Error) => {
          notification.error(t(error?.message || "getResponseError"));
        });
        setInput("");
      }
    };

    const handleQuestionAddFromTextArea = (
      event: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      if (input?.trim() && !event.shiftKey && event.code === "Enter") {
        event.preventDefault();
        addFunction();
      }
    };

    const handleQuestionAddFromInput = (
      event: React.KeyboardEvent<HTMLDivElement>
    ) => {
      if (input?.trim() && ENTER_KEYS.includes(event.code)) {
        addFunction();
      }
    };

    const handleAdd = () => {
      if (input?.trim()) {
        addFunction();
      }
    };

    if (!ready) {
      return <AppSuspense />;
    }

    if (isForHome) {
      return (
        <Box className={classes.boxForHome}>
          <textarea
            placeholder={t(placeholder)}
            onChange={handleTextAreaChange}
            value={input}
            className={classes.textArea}
            onKeyPress={handleQuestionAddFromTextArea}
            rows={2}
          />
          <CoreTooltip
            title={t("send_question_tooltip")}
            placement="top"
            className={classes.sendButton}
          >
            <span>
              <IconButton
                onClick={handleAdd}
                disabled={inputIsDisabled}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "10px",
                  backgroundColor: inputIsDisabled
                    ? theme.palette.text.disabled
                    : theme.palette.highlight.light,
                }}
              >
                <Box className={classes.inputIcon}>{inputIcon}</Box>
              </IconButton>
            </span>
          </CoreTooltip>
        </Box>
      );
    }

    return (
      <CoreInput
        onKeyPress={handleQuestionAddFromInput}
        value={input}
        onChange={handleCoreInputChange}
        className={classes.input}
        placeholder={t(placeholder)}
        disableBorder={true}
        endAdornment={
          <CoreTooltip title={t("send_question_tooltip")}>
            <span>
              <IconButton onClick={handleAdd} disabled={inputIsDisabled}>
                {inputIcon}
              </IconButton>
            </span>
          </CoreTooltip>
        }
      />
    );
  }
);

export default ChatGptInput;
