import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, useTheme, Grid, Typography } from "@mui/material";

import NewReleasesIcon from "@mui/icons-material/NewReleases";

import { useStores } from "../../../../../../../stores/StoresProvider";
import CoreButton from "../../../../../../core/CoreButton";
import { CoreCard } from "../../../../../../core/CoreCard";
import { appRoutes } from "../../../../../../../configs/routes";
import { SubscriptionPlanDrawer } from "../SubscriptionPlanDrawer";
import { ContactSalesDrawer } from "../../ContactSalesDrawer";

export const UpgradeToPlanCard = observer(() => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation("subscription");
  const { subscriptionStore, userStore } = useStores();
  const classes = makeStyles({
    container: {
      maxWidth: "100% !important",
      width: "100%",
      display: "flex",
      marginTop: "20px",
      borderRadius: "2px",
      backgroundColor: theme.palette.primary.dark,
    },
    description: {
      opacity: 0.6,
      fontSize: "12px",
      marginTop: "10px",
    },
    actionsContainer: {
      margin: "auto",
    },
    upgradeBtn: {
      marginLeft: "10px",
    },
    planContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    textContainer: {
      paddingLeft: "10px",
    },
    icon: {
      color: theme.palette.warning.main,
    },
  })();

  const [isModalOpened, toggleModal] = useState(false);
  const [isContactSalesOpened, toggleContactSalesModal] = useState(false);

  const nextSubscription = subscriptionStore.nextSubscription;

  const handleUpgradeRedirect = () => {
    subscriptionStore.setPlanToUpgradeDraft(nextSubscription?.id as string);
    history.push(appRoutes.SubscriptionPlanUpgrade());
  };

  // There is no next subscription plan to upgrade to
  if (!nextSubscription) {
    return <></>;
  }

  return (
    <>
      <CoreCard className={classes.container}>
        <Grid container>
          <Grid item flex="1" className={classes.planContainer}>
            <NewReleasesIcon className={classes.icon} />

            <Box className={classes.textContainer}>
              <Typography>
                {t("active_subscription_upgrade_title", {
                  name: nextSubscription?.name || "-",
                })}
              </Typography>

              <Typography className={classes.description}>
                {nextSubscription?.description || "-"}
              </Typography>
            </Box>
          </Grid>

          <Grid item className={classes.actionsContainer}>
            {!!subscriptionStore.activeSubscription && (
              <CoreButton variant="outlined" onClick={() => toggleModal(true)}>
                {t("active_subscription_learn_more")}
              </CoreButton>
            )}
            {userStore.currentUserPermissions?.can("update", "subscription") &&
              (!nextSubscription?.subscribable ? (
                <CoreButton
                  variant="contained"
                  className={classes.upgradeBtn}
                  onClick={() => toggleContactSalesModal(true)}
                >
                  {t("contact_sales_action_label")}
                </CoreButton>
              ) : (
                <CoreButton
                  variant="contained"
                  className={classes.upgradeBtn}
                  onClick={handleUpgradeRedirect}
                >
                  {t("active_subscription_upgrade")}
                </CoreButton>
              ))}
          </Grid>
        </Grid>
      </CoreCard>

      <SubscriptionPlanDrawer
        isOpen={isModalOpened}
        onClose={() => toggleModal(false)}
        subscriptionPlan={nextSubscription}
        handleRedirect={handleUpgradeRedirect}
      />

      <ContactSalesDrawer
        planId={nextSubscription?.id || ""}
        planName={nextSubscription?.name || ""}
        isOpen={isContactSalesOpened}
        onClose={() => toggleContactSalesModal(false)}
      />
    </>
  );
});
