import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Typography,
  useTheme,
  alpha,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ChatGptTabs,
  CHAT_GPT_MESSAGE_TYPES,
} from "../../../../types/constants";
import { useStores } from "../../../../stores/StoresProvider";
import {
  ChatGptSuggestions,
  ChatGptMessage,
} from "../../../../types/interfaces";
import { useNotification } from "../../../../context/useNotification";
import { appRoutes } from "../../../../configs/routes";

interface Props {
  t: TFunction;
}

const ChatHomeTab: React.FC<Props> = observer(({ t }) => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const {
    chatGptStore: {
      setTabToReturn,
      setChatGptViewTab,
      isDataLoading,
      getChatGptSuggestions,
      isResponseGenerating,
      isResponseLoading,
      addMessage,
      setIsOnSuggestions,
      clearMessages,
      getChatGptResponseHelper,
    },
  } = useStores();
  const notification = useNotification();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<ChatGptSuggestions[]>([]);

  const classes = makeStyles({
    container: {
      width: "100%",
      padding: "0 20px 0 20px",
    },
    helpMessage: {
      fontSize: "20px",
      padding: "40px 0 30px 0",
    },
    questionStyle: {
      fontSize: "14px",
      cursor: "pointer",
      borderRadius: "10px",
      border: `1px solid ${alpha(theme.palette.primary.main, 1)}`,
      maxHeight: "80px",
      padding: "12px 16px",
      marginBottom: "10px",
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
      },
    },
    listItemStyle: {
      padding: 0,
    },
    contentLoading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      flex: "1",
    },
    suggestionsText: {
      paddingTop: "70px",
      marginBottom: "20px",
    },
  })();

  const handleOnClick = (questionToAsk: string) => {
    clearMessages();
    setIsOnSuggestions(true);
    setTabToReturn(ChatGptTabs.home);

    if (!isResponseGenerating && !isResponseLoading) {
      const userMessage = {
        question: questionToAsk?.trim(),
        type: CHAT_GPT_MESSAGE_TYPES.user,
        filter: {},
        answer: "",
      } as ChatGptMessage;

      addMessage(userMessage);

      void getChatGptResponseHelper(
        questionToAsk,
        false,
        !!id && pathname.includes(appRoutes.FlowDetails(id)) ? id : ""
      ).catch((error: Error) => {
        notification.error(t(error?.message || "getResponseError"));
      });
    }
    setChatGptViewTab(ChatGptTabs.clariChat);
  };

  const handleAskClick = () => {
    setTabToReturn(ChatGptTabs.home);
    setChatGptViewTab(ChatGptTabs.clariChat);
  };

  useEffect(() => {
    setIsLoading(true);
    getChatGptSuggestions()
      .then((getChatGptSuggestions) => {
        setSuggestions(getChatGptSuggestions);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "getSuggestionsError")); //TODO: add translation
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDataLoading) {
    return (
      <Box className={classes.contentLoading}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Typography className={classes.helpMessage}>
        {t("helpMessage")}
      </Typography>

      <Typography className={classes.questionStyle} onClick={handleAskClick}>
        {t("askQuestion")}
      </Typography>

      <Typography variant="h6" className={classes.suggestionsText}>
        {t("Suggestions")}
      </Typography>

      {isLoading ? (
        <Box className={classes.contentLoading}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {suggestions &&
            suggestions.length > 0 &&
            suggestions.map((suggestion, index) => (
              <React.Fragment key={index}>
                {index === 0 && <Divider />}

                <ListItem key={index} className={classes.listItemStyle}>
                  <ListItemButton
                    onClick={() => handleOnClick(suggestion.question)}
                  >
                    <ListItemText
                      primary={suggestion.question}
                      primaryTypographyProps={{
                        fontSize: "14px",
                        paddingLeft: 0,
                      }}
                    />
                    <ChevronRightIcon />
                  </ListItemButton>
                </ListItem>

                <Divider />
              </React.Fragment>
            ))}
        </List>
      )}
    </Box>
  );
});

export default ChatHomeTab;
