import React, { FC, useEffect, useMemo, useState } from "react";
import { BeatLoader } from "react-spinners";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Box,
  IconButton,
  SelectChangeEvent,
  Typography,
  alpha,
  useTheme,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
} from "@mui/material";
import CoreButton from "../../../../../core/CoreButton";
import CoreSelect from "../../../../../core/CoreSelect";
import CoreTooltip from "../../../../../core/CoreTooltip";
import CoreNotificationBox from "../../../../../core/CoreNotificationBox";
import { NotificationTypes } from "../../../../../../context/useNotification";
import { useStores } from "../../../../../../stores/StoresProvider";
import { FlowField } from "../../../../../../types/interfaces";

interface Props {
  t: TFunction;
  isOpen: boolean;
  isDetectInProgress: boolean;
  isDetectSuccessfully: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title: string;
  subtitle: string;
  color: string;
}

export const LineDetectingLoader: FC<Props> = observer(
  ({
    t,
    subtitle,
    title,
    isOpen,
    isDetectInProgress,
    isDetectSuccessfully,
    setIsOpen,
    color,
  }) => {
    const { flowStore, documentStore } = useStores();
    const theme = useTheme();

    const allOptions = documentStore.focusedLineItem?.headers?.map(
      (header) => ({
        key: header.key ?? "",
        label: header.text ?? "",
      })
    );

    const flowTableHeaders = useMemo(
      () =>
        (
          flowStore.flow?.fields?.find(
            (item) => item.key === documentStore.focusedFieldCanvas
          )?.dataType?.parameters?.headers as FlowField[]
        )?.filter((header) => header.source !== "enhancement"),
      [documentStore.focusedFieldCanvas, flowStore.flow?.fields]
    );

    const [mappedKeys, setMappedKeys] = useState<{ [key: string]: string }>({});

    useEffect(() => {
      if (
        isOpen &&
        flowTableHeaders?.length > 0 &&
        allOptions?.length &&
        allOptions?.length > 0
      ) {
        const defaultMappedKeys = {} as { [key: string]: string };

        flowTableHeaders?.forEach((header) => {
          allOptions?.forEach((option) => {
            if (header.key === option.key) {
              defaultMappedKeys[option.key] = option.key;
            }
          });
        });
        setMappedKeys(defaultMappedKeys);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentStore.lineItems]);

    const useStyles = makeStyles({
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
      },
      title: {
        marginTop: "10px",
        marginBottom: "5px",
      },
      subtitle: {
        opacity: 0.7,
        fontSize: "13px",
      },
      dialog: {
        backgroundImage: "unset",
        backgroundColor: theme.palette?.background.paper,
        maxWidth: "640px",
      },
      detectLineItemHeader: {
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
        marginBottom: "20px",
      },
      detectLineItemModalActions: {
        padding: "20px",
      },
      dialogContentClass: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },
      infoContainer: {
        padding: "0px 20px 0px 20px",
      },
      origColText: {
        fontSize: "12px",
        opacity: 0.6,
        textAlign: "right",
      },
      detectedColText: {
        fontSize: "12px",
        opacity: 0.6,
      },
      firstCell: {
        borderBottom: "none",
        borderRight: "solid 1px white",
        textAlign: "right",
        paddingTop: "0",
        paddingBottom: "10px",
        height: "100%",
        minWidth: "190px",
      },
      secondCell: {
        borderBottom: "none",
        paddingTop: "0",
        paddingBottom: "10px",
        width: "100%",
      },
      option: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        maxWidth: "370px",
      },
      input: {
        maxWidth: "250px",
        display: "flex",
        flexDirection: "row",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      endAdornment: {
        marginRight: "10px",
      },
    });
    const classes = useStyles();

    const handleSelect = (
      event:
        | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        | SelectChangeEvent<unknown>,
      headerKey: string
    ) => {
      setMappedKeys({
        ...mappedKeys,
        [headerKey]: event.target.value as string,
      });
    };

    const handleConfirm = () => {
      documentStore.linkDetectedHeaders(mappedKeys);
      setMappedKeys({});
      setIsOpen(false);
    };

    const handleIgnore = () => {
      setIsOpen(false);
      setMappedKeys({});
    };

    const clearOption = (headerKey: string) => {
      const updatedObject = { ...mappedKeys };
      delete updatedObject[headerKey];
      setMappedKeys(updatedObject);
    };

    useEffect(() => {
      if (
        isDetectSuccessfully === false &&
        isOpen === true &&
        isDetectInProgress === false
      )
        setIsOpen(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDetectSuccessfully, isDetectInProgress]);

    const selectOptions = (key: string) => {
      return allOptions?.filter(
        (option) =>
          !Object.values(mappedKeys ?? {}).includes(option.key) ||
          option.key === key
      );
    };

    const selectEndAdornment = (key: string) => {
      return (
        mappedKeys[key] && (
          <Box className={classes.endAdornment}>
            <CoreTooltip title={t("remove_option")}>
              <IconButton
                onClick={() => {
                  clearOption(key);
                }}
              >
                <ClearIcon />
              </IconButton>
            </CoreTooltip>
          </Box>
        )
      );
    };

    return (
      <Dialog
        open={isOpen}
        fullWidth={isDetectInProgress ? false : true}
        classes={{
          paper: classes.dialog,
        }}
      >
        {isDetectInProgress ? (
          <DialogContent>
            <Box className={classes.container}>
              <BeatLoader color={color} loading size={14} />
              <DialogContentText className={classes.title}>
                {title}
              </DialogContentText>
              <DialogContentText className={classes.subtitle}>
                {subtitle}
              </DialogContentText>
            </Box>
          </DialogContent>
        ) : (
          <>
            {isDetectSuccessfully && (
              <>
                <DialogTitle className={classes.detectLineItemHeader}>
                  {t("linkTableHeadersTitle")}
                </DialogTitle>
                <Box className={classes.infoContainer}>
                  <CoreNotificationBox
                    description={t("lineItems_link_table_headers_note")}
                    type={NotificationTypes.info}
                  />
                </Box>
                <DialogContent className={classes.dialogContentClass}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.firstCell}>
                          <Typography className={classes.origColText}>
                            {t("lineItems_link_table_headers_original_col")}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.secondCell}>
                          <Typography className={classes.detectedColText}>
                            {t("lineItems_link_table_headers_detected_col")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {flowTableHeaders
                        ?.filter((header) => header.isVisible)
                        .map((header, index) => {
                          const options = selectOptions(mappedKeys[header.key]);
                          const endAdornment = selectEndAdornment(header.key);

                          return (
                            <TableRow key={index}>
                              <TableCell className={classes.firstCell}>
                                <Typography>{header.name}</Typography>
                              </TableCell>
                              <TableCell className={classes.secondCell}>
                                <CoreSelect
                                  placeholder={t("coreSelectPlaceholder")}
                                  value={mappedKeys[header.key]}
                                  onChange={(e) => {
                                    handleSelect(e, header.key);
                                  }}
                                  options={options}
                                  endAdornment={endAdornment}
                                  optionStyle={classes.option}
                                  inputStyle={classes.input}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </DialogContent>

                <DialogActions className={classes.detectLineItemModalActions}>
                  <CoreButton
                    autoFocus
                    onClick={handleIgnore}
                    variant="outlined"
                  >
                    {t("ignore")}
                  </CoreButton>
                  <CoreButton
                    autoFocus
                    onClick={handleConfirm}
                    variant="contained"
                  >
                    {t("confirm")}
                  </CoreButton>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    );
  }
);
