import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

import {
  useTheme,
  Box,
  Menu,
  MenuList,
  Typography,
  ListItemIcon,
  MenuItem,
  Divider,
  alpha,
} from "@mui/material";

import CoreButton from "../../../core/CoreButton";
import CoreConfirmModal from "../../../core/CoreConfirmModal";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { HeaderActions } from "../../../../types/interfaces";
import CoreTooltip from "../../../core/CoreTooltip";

interface Props {
  loadData: () => void;
  hasApiKeys: boolean;
  isLoading: boolean;
}

const APIkeysTabHeaderActions: React.FC<Props> = observer(
  ({ loadData, hasApiKeys, isLoading }) => {
    const { t } = useTranslation("apikeys");
    const { userStore, flowSettingsStore } = useStores();
    const notification = useNotification();
    const [isKeyGenerating, toggleGenerateAction] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();

    const classes = makeStyles({
      button: {
        marginLeft: "5px",
        textTransform: "none",
        transition: "none",
        display: "flex",
        alignItems: "stretch",
        backgroundColor: theme.palette.background.paper,
        "&:hover": {
          backgroundColor: alpha(theme.palette.highlight.light, 0.08),
          color: `${theme.palette.highlight.main} !important`,
        },
      },
      extraActionsBox: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      buttonsBox: {
        display: "flex",
        paddingRight: "10px",
        gap: "10px",
      },
      moreVertIcon: {
        borderRadius: 100,
        minWidth: 0,
        padding: "4px 5px",
      },
      extraHeaderActions: {
        textAlign: "right",
        marginTop: 5,
        display: "block",
      },
      divider: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
      },
      menuList: {
        "& ul": {
          padding: 0,
          boxShadow: "11px 16px 7px -10px rgba(0,0,0,0.43)",
        },
      },
      menuItemAction: {
        margin: "3px",
        marginLeft: "5px",
        textTransform: "none",
        transition: "none",
        display: "flex",
        alignItems: "stretch",
        color: "inherit",
      },
      menuItem: {
        background: theme.palette.background.paper,
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        "&:hover": {
          color: `${theme.palette.highlight.main} !important`,
        },
        "&.Mui-disabled": {
          pointerEvents: "auto",
        },
      },
      menuCategory: {
        background: theme.palette.background.paper,
        "&:hover": {
          background: theme.palette.background.paper,
        },
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        cursor: "default",
        opacity: 0.8,
        fontSize: "14px",
      },
      iconStyle: {
        color: "inherit",
      },
    })();

    const headerActions: HeaderActions[] = [
      {
        label: t("generateKeyAction"),
        tooltip: t("generateTooltip"),
        onClick: () => setIsModalOpen(true),
        startIcon: <AddIcon />,
        ability: [
          {
            action: "create",
            subject: "apiKeys",
          },
        ],
        disabled: isLoading,
      },
    ];

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const onOptionClick = (headerAction: HeaderActions) => {
      if (headerAction.onClick) {
        setAnchorEl(null);
        headerAction.onClick();
      }
    };

    const onGenerateNewKey = () => {
      toggleGenerateAction(true);

      // If namespace does not have an api key, when creating a new one, also make it as active
      // TODO: Include flow identifier after python BE is ready
      userStore
        .generateApiKey(!hasApiKeys)
        .then(() => {
          loadData();
          toggleGenerateAction(false);
          setIsModalOpen(false);
          notification.success(t("generateApiKeySuccess"));
        })
        .catch(() => {
          toggleGenerateAction(false);
          notification.error(t("generateApiKeyFail"));
        });
    };

    return (
      <Box className={classes.extraActionsBox}>
        <Box className={classes.buttonsBox}>
          <CoreTooltip title={t("refreshApiKeys")}>
            <Box>
              <CoreButton
                variant="neutral"
                size="large"
                onClick={() => loadData()}
                startIcon={<CachedOutlinedIcon />}
                className={classes.button}
                disabled={isLoading}
              >
                {t("refreshApiKeys")}
              </CoreButton>
            </Box>
          </CoreTooltip>
        </Box>

        <Box className={classes.extraHeaderActions}>
          {headerActions.length > 0 && (
            <div>
              {!flowSettingsStore.isDiagramEditable && (
                <CoreButton
                  variant="neutral"
                  onClick={(event) => handleClick(event)}
                  size="medium"
                  className={classes.moreVertIcon}
                >
                  <MoreVertIcon sx={{ fontSize: 20 }} />
                </CoreButton>
              )}

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                className={classes.menuList}
              >
                <MenuList dense>
                  {headerActions.map((headerAction, index) => {
                    return (
                      <div key={`submenu-${headerAction.label}-${index}`}>
                        <MenuItem
                          key={index}
                          onClick={() => onOptionClick(headerAction)}
                          disabled={headerAction.disabled}
                          className={
                            headerAction.onClick
                              ? classes.menuItem
                              : classes.menuCategory
                          }
                        >
                          {headerAction.startIcon && (
                            <ListItemIcon className={classes.iconStyle}>
                              {headerAction.startIcon}
                            </ListItemIcon>
                          )}

                          <Typography className={classes.menuItemAction}>
                            {headerAction.disabledTooltip &&
                            headerAction.disabled === true ? (
                              <CoreTooltip
                                title={t(headerAction.disabledTooltip)}
                              >
                                <span>{headerAction.label}</span>
                              </CoreTooltip>
                            ) : (
                              headerAction.label
                            )}
                          </Typography>
                        </MenuItem>
                        {headerAction.divider && (
                          <Divider className={classes.divider} />
                        )}
                      </div>
                    );
                  })}
                </MenuList>
              </Menu>
            </div>
          )}
        </Box>

        <CoreConfirmModal
          open={isModalOpen}
          title={t("generateModal_title")}
          subtitle={t("apiKeyWarning")}
          isDisable={isKeyGenerating}
          onConfirm={onGenerateNewKey}
          onCancel={() => setIsModalOpen(false)}
          onClose={() => setIsModalOpen(false)}
          confirmButtonLabel={t("modalConfirmButton")}
          cancelButtonLabel={t("modalCancelButton")}
        />
      </Box>
    );
  }
);

export default APIkeysTabHeaderActions;
