import React, { FC, memo } from "react";
import { observer } from "mobx-react";

import { Theme } from "@mui/material";

import { DocumentStore } from "../../../../../../stores/DocumentStore";
import Zone from "./Zone";

interface Props {
  store: DocumentStore;
  theme: Theme;
  isSelectMode?: boolean;
}

/**
 * User for drawing new zone and for multiple zone select
 */
const ZoneDraft: FC<Props> = observer(({ store, theme, isSelectMode }) => {
  const { drawingZone } = store;

  if (!drawingZone) {
    return null;
  }

  return (
    <Zone
      theme={theme}
      zone={drawingZone}
      isFocused={true}
      isSelectMode={isSelectMode}
      store={store}
    />
  );
});

export default memo(ZoneDraft);
