import React from "react";
import { TFunction } from "i18next";

import {
  IconButton,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  alpha,
  useTheme,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import EditorJson from "../../pages/connections/components/EditorJson";
import CoreNotificationBox from "../../core/CoreNotificationBox";
import { NotificationTypes } from "../../../context/useNotification";
import CoreTooltip from "../../core/CoreTooltip";

interface Props {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  editorValue: string | undefined;
  showPreviewError?: boolean;
  modalMaxWidth: string;
  editorHeight?: string;
  copySchemaAction?: () => void;
}

const SchemaPreviewerModal: React.FC<Props> = ({
  t,
  isOpen,
  onClose,
  editorValue,
  modalMaxWidth,
  editorHeight,
  copySchemaAction,
  showPreviewError = false,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    dialogPaper: {
      "& .MuiDialog-paper": {
        padding: 0,
        maxWidth: modalMaxWidth,
      },
    },
    content: {
      "&.MuiDialogContent-root": {
        alignSelf: "stretch",
      },
      padding: "0 40px 20px 40px",
    },
    iconButton: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    divider: {
      marginRight: "1rem",
      marginLeft: "1rem",
      border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
    },
    editorBox: {
      paddingTop: "20px",
    },
    copyActionBox: {
      display: "flex",
      justifyContent: "flex-end",
    },
  });

  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      className={classes.dialogPaper}
      BackdropProps={{
        style: { backgroundColor: "transparent" },
      }}
    >
      <DialogTitle>
        <Typography>{t("schemaPreview")}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.iconButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider className={classes.divider} />

      <DialogContent className={classes.content}>
        <Box className={classes.editorBox}>
          {showPreviewError ? (
            <CoreNotificationBox
              type={NotificationTypes.error}
              description={t("schemaPreviewError")}
            />
          ) : (
            <EditorJson
              isReadOnly={true}
              value={editorValue}
              editorHeight={editorHeight}
            />
          )}

          {copySchemaAction && (
            <Box className={classes.copyActionBox}>
              <CoreTooltip title={t("copyOutputSchema")}>
                <IconButton
                  onClick={copySchemaAction}
                  style={{ color: theme.palette.canvas.dark }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </CoreTooltip>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SchemaPreviewerModal;
