// Module imports
import React, { FC, useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";

// Design imports
import { makeStyles } from "@mui/styles";
import { SelectChangeEvent, Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddIcon from "@mui/icons-material/Add";

//Internal imports
import {
  DocumentsFilterProps,
  QAFilters,
  SelectOptionDef,
} from "../../../../types/interfaces";
import { ENTER_KEYS } from "../../../../types/constants";
import { FlowHelper } from "./helpers/FlowHelper";
import CoreDrawer from "../../../core/CoreDrawer";
import CoreSelect from "../../../core/CoreSelect";
import CoreInput from "../../../core/CoreInput";
import CoreTag from "../../../core/CoreTag";
import CoreButton from "../../../core/CoreButton";
import CoreTooltip from "../../../core/CoreTooltip";
import ClearIcon from "@mui/icons-material/Clear";
import { AppSuspense } from "../../../main/AppSuspense";

const useStyles = makeStyles({
  formContainer: {
    marginTop: "20px",
    flex: "1",
  },
  valuesContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxHeight: "200px",
    overflow: "auto",
  },
  actionsContainer: {
    display: "flex",
    gap: "15px",
    width: "100%",
    justifyContent: "center",
    padding: "30px 0 0 0 ",
  },
  valueFilterChip: {
    marginRight: "5px",
    marginBottom: "5px",
    borderRadius: "5px",
  },
  valueContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  addButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginRight: "-10px",
  },
  infoIcon: {
    alignSelf: "center",
    marginLeft: "12px",
    marginTop: "5px",
  },
  infoTooltip: {
    display: "flex",
    flexDirection: "column",
  },
  fieldTitle: {
    marginBottom: "15px",
    width: "100%",
  },
  clearButton: {
    display: "flex",
    position: "absolute",
    marginLeft: "5px",
    right: "50px",
  },
});

interface Props {
  translation: TFunction;
  isOpened: boolean;
  fieldOptions: SelectOptionDef[];
  filterForm?: DocumentsFilterProps | QAFilters; // Used for editing
  disableOperator?: boolean; // Used to hide operator drop down
  onClose: () => void;
  onConfirm: (
    filters: DocumentsFilterProps | QAFilters,
    filterKey?: string
  ) => void;
  showAddFilterButton?: boolean;
  flowType?: string;
  isLoading?: boolean;
  showManuallyUpdatedFilter?: boolean;
  manuallyUpdated?: boolean;
  updateManuallyUpdatedFilter?: (value: boolean | undefined) => void;
}

const DEFAULT_FORM = {
  field: "",
  category: "",
  type: "",
  operator: "",
  source: "",
};

export const AdvancedFiltersDrawer: FC<Props> = ({
  translation,
  isOpened,
  disableOperator,
  filterForm,
  fieldOptions = [],
  onClose,
  onConfirm,
  showAddFilterButton = false,
  flowType,
  isLoading,
  showManuallyUpdatedFilter,
  manuallyUpdated,
  updateManuallyUpdatedFilter,
}) => {
  const classes = useStyles();
  const [inputQuery, setInputQuery] = useState("");
  const [filters, setFilters] = useState<DocumentsFilterProps>(DEFAULT_FORM);
  const [manuallyUpdatedFilter, setManuallyUpdatedFilter] = useState("none");
  const [appliedValues, setValues] = useState<string[]>([]);
  const [open, setOpen] = useState({
    field: false,
    value: false,
  });

  const fieldOperators = useMemo(() => {
    // No need to execute the below operations in this case
    if (disableOperator) {
      return [];
    }
    const operators = FlowHelper.getTypeOperators();
    return operators?.map((operator) => ({
      key: operator,
      value: operator,
      label: operator,
    }));
  }, [disableOperator]); // TODO: Add based on filters?.field when field type is implemented on backend side

  const selectedField = useMemo(
    () =>
      filters?.field
        ? fieldOptions?.find((option) => option.key === filters?.field)
        : undefined,
    [filters?.field, fieldOptions]
  );

  const formattedAppliedValues = useMemo(
    () =>
      appliedValues?.length > 0 &&
      selectedField?.suggestions &&
      selectedField?.suggestions?.length > 0
        ? selectedField?.suggestions
            ?.filter((op) => appliedValues.includes(op.key))
            ?.map((op) => op.label)
        : appliedValues,
    [selectedField, appliedValues]
  );

  const isFilterValid = useMemo(() => {
    const fieldOrCategory =
      flowType === "classification"
        ? filters?.category !== ""
        : filters?.field !== "";
    const operator = disableOperator ? true : filters?.operator !== "";
    const values = appliedValues?.length > 0;

    if (
      (showManuallyUpdatedFilter && !fieldOrCategory) ||
      (fieldOrCategory && operator && values) ||
      (showManuallyUpdatedFilter && fieldOrCategory && operator && values)
    ) {
      return true;
    }

    return false;
  }, [
    showManuallyUpdatedFilter,
    flowType,
    filters,
    disableOperator,
    appliedValues,
  ]);

  const formatFieldsCategoryOptions = useMemo(() => {
    return [
      {
        key: "",
        label: translation("noneOption"),
        value: "",
      },
      ...fieldOptions,
    ];
  }, [fieldOptions, translation]);

  useEffect(() => {
    if (isOpened) {
      if (manuallyUpdated) {
        // TODO: Add values in a constant
        setManuallyUpdatedFilter("yes");
      } else if (manuallyUpdated === false) {
        setManuallyUpdatedFilter("no");
      } else {
        setManuallyUpdatedFilter("none");
      }

      setInputQuery("");

      if (filterForm) {
        flowType === "classification" && filterForm.type === "categories"
          ? setFilters({
              category:
                (filterForm as unknown as DocumentsFilterProps)?.category || "",
              operator: filterForm?.operator || "",
              type: filterForm?.type || "",
            })
          : setFilters({
              field: filterForm?.field || "",
              operator: filterForm?.operator || "",
              type: filterForm?.type || "",
              source: filterForm?.source || "",
            });
        setValues(filterForm?.values || []);
        return;
      }
      setFilters(DEFAULT_FORM);
      setValues([]);
    }
  }, [isOpened, filterForm, flowType, manuallyUpdated]);

  const handleFilterChange = (event: SelectChangeEvent<unknown>) => {
    const name = event.target.name as
      | "field"
      | "category"
      | "operator"
      | "inputQuery";
    const value = event.target.value as string;

    if (name === "inputQuery") {
      setValues(value as unknown as string[]);
      return;
    }

    //reset operator, input and values if field or category is set to none option
    if ((name === "field" || name === "category") && value === "") {
      setFilters({
        ...filters,
        [name]: "",
        operator: "",
      });
      setInputQuery("");
      setValues([]);
    }

    // Also clear previous values
    if (name === "field" || name === "category") {
      setValues([]);
    }
    if (name === "field" || name === "category") {
      setFilters({
        ...filters,
        [name]: value,
        type: fieldOptions?.find((field) => field.key === value)?.type,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const handleInputQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputQuery(event.target.value);
  };

  const handleQueryAdd = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      inputQuery &&
      inputQuery?.trim() !== "" &&
      ENTER_KEYS.includes(event.code)
    ) {
      // Add value only if its not a duplicate
      if (
        !appliedValues.some(
          (val) => val?.toLowerCase() === inputQuery?.toLowerCase()
        )
      ) {
        setValues([...(appliedValues || []), inputQuery]);
      }

      setInputQuery("");
    }
  };

  const handleValueRemove = (valueIndex: number) => {
    setValues(appliedValues?.filter((_, index) => index !== valueIndex));
  };

  const handleAdd = () => {
    if (inputQuery?.trim()) {
      // Add value only if its not a duplicate
      if (
        !appliedValues.some(
          (val) => val?.toLowerCase() === inputQuery?.toLowerCase()
        )
      ) {
        setValues([...(appliedValues || []), inputQuery]);
      }

      setInputQuery("");
    }
  };

  const handleAddFilter = () => {
    const hasFilters = appliedValues?.length > 0;

    if (hasFilters) {
      onConfirm({ ...filters, values: appliedValues } as DocumentsFilterProps);
    }

    if (showManuallyUpdatedFilter && updateManuallyUpdatedFilter) {
      const filterValue =
        manuallyUpdatedFilter === "none"
          ? undefined
          : manuallyUpdatedFilter === "yes";
      updateManuallyUpdatedFilter(filterValue);

      if (!hasFilters) {
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setFilters({});
    onClose();
  };

  const showSelectedFieldSuggestions = () => {
    if (selectedField) {
      return (
        <Box>
          <Typography>
            {translation("advanced_filters_drawer_value_title")}
          </Typography>
          <Box display="flex" flexDirection={"row"}>
            <CoreSelect
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "30%",
                    maxWidth: "20%",
                    overflowX: "auto",
                  },
                },
              }}
              isMultipleSelect
              hasCheckbox
              displayEmpty
              open={open.value}
              className={classes.fieldTitle}
              onClose={() => setOpen({ ...open, value: false })}
              onOpen={() => setOpen({ ...open, value: true })}
              options={selectedField?.suggestions ?? []}
              value={appliedValues}
              onChange={handleFilterChange}
              renderValue={(selected): React.ReactNode => {
                const allFlows = selected as string[];

                if (allFlows.length === 0) {
                  let translationKeyEmpty = "noOptions";
                  if (
                    selectedField?.suggestions &&
                    selectedField?.suggestions?.length > 0
                  ) {
                    if (filters?.field === "flowsFilter")
                      translationKeyEmpty = "flowsPlaceholder";
                    if (filters?.field === "eventTypeFilter")
                      translationKeyEmpty = "eventsPlaceholder";
                  }

                  return translation(translationKeyEmpty);
                }

                const translationKey =
                  filters?.field === "eventTypeFilter"
                    ? "eventSelected"
                    : "flowSelected";

                return `${allFlows?.length} ${translation(translationKey)}`;
              }}
              name="inputQuery"
            />
            <Box className={classes.clearButton}>
              {appliedValues.length > 0 && (
                <CoreTooltip title={translation("remove_filters")}>
                  <IconButton onClick={() => setValues([])}>
                    <ClearIcon />
                  </IconButton>
                </CoreTooltip>
              )}
            </Box>
          </Box>
        </Box>
      );
    } else {
      return translation("selectFieldFirst");
    }
  };

  const showInputBoxes = () => {
    if (showAddFilterButton === true) {
      return (
        <Box className={classes.valueContainer}>
          <CoreInput
            fullWidth
            onKeyPress={handleQueryAdd}
            value={inputQuery}
            name="inputQuery"
            className={classes.fieldTitle}
            onChange={handleInputQueryChange}
            title={translation("advanced_filters_drawer_value_title")}
            placeholder={translation(
              "advanced_filters_drawer_value_placeholder"
            )}
            endAdornment={
              <CoreTooltip
                title={translation("add_value_filter")}
                className={classes.addButtonContainer}
              >
                <span>
                  <IconButton onClick={handleAdd} disabled={!inputQuery.length}>
                    <AddIcon />
                  </IconButton>
                </span>
              </CoreTooltip>
            }
            disabled={
              filters.operator !== "" &&
              (filters?.field !== "" || filters.category !== "")
                ? false
                : true
            }
          />
          <CoreTooltip
            title={translation("addNewFilterValueTooltip")}
            placement={"bottom"}
            className={classes.infoTooltip}
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </CoreTooltip>
        </Box>
      );
    } else {
      return showSelectedFieldSuggestions();
    }
  };

  const infoContent = useMemo(() => {
    let subtitle = translation("advanced_filters_drawer_fields_subtitle");
    let operatorDisabled = filters.field !== "" ? false : true;

    if (flowType === "classification") {
      subtitle = translation("advanced_filters_drawer_categories_subtitle");
      operatorDisabled = filters.category !== "" ? false : true;
    }
    return { subtitle, operatorDisabled };
  }, [filters.category, filters.field, flowType, translation]);

  const manuallyUpdatedOptions = useMemo(() => {
    if (!showManuallyUpdatedFilter) {
      return [];
    }
    return [
      {
        key: "none",
        label: translation("manuallyUpdatedFilter_showAll_option"),
      },
      { key: "yes", label: translation("manuallyUpdatedFilter_yes_option") },
      { key: "no", label: translation("manuallyUpdatedFilter_no_option") },
    ];
  }, [showManuallyUpdatedFilter, translation]);

  return (
    <CoreDrawer
      isOpen={isOpened}
      onClose={handleClose}
      title={translation("advanced_filters_drawer_title")} // edit mode: translation("advanced_filters_drawer_edit_title")
    >
      {isLoading ? (
        <AppSuspense />
      ) : (
        <>
          <Box>
            {showManuallyUpdatedFilter ? (
              <CoreSelect
                title={translation("manuallyUpdatedFilter")}
                options={manuallyUpdatedOptions}
                value={manuallyUpdatedFilter}
                name={"manuallyUpdated"}
                onChange={(event: SelectChangeEvent<unknown>) =>
                  setManuallyUpdatedFilter(event.target.value as string)
                }
                className={classes.fieldTitle}
              />
            ) : (
              <></>
            )}
          </Box>

          <Typography>{infoContent.subtitle}</Typography>
          <Box className={classes.formContainer}>
            {fieldOptions.length > 0 ? (
              <Box>
                <CoreSelect
                  title={
                    flowType === "classification"
                      ? translation("advanced_filters_drawer_category_title")
                      : translation("advanced_filters_drawer_field_title")
                  }
                  options={formatFieldsCategoryOptions}
                  value={
                    flowType === "classification"
                      ? filters.category ?? filters.field
                      : filters.field
                  }
                  name={flowType === "classification" ? "category" : "field"}
                  onChange={handleFilterChange}
                  className={classes.fieldTitle}
                />
                {!disableOperator && (
                  <CoreSelect
                    title={translation(
                      "advanced_filters_drawer_operator_title"
                    )}
                    options={fieldOperators}
                    value={filters?.operator || ""}
                    name="operator"
                    onChange={handleFilterChange}
                    disabled={infoContent.operatorDisabled}
                    className={classes.fieldTitle}
                    placeholder={translation(
                      "advanced_filters_drawer_operator_title"
                    )}
                  />
                )}

                {showInputBoxes()}
              </Box>
            ) : (
              <CoreSelect
                title={
                  flowType === "classification"
                    ? translation("advanced_filters_drawer_category_title")
                    : translation("advanced_filters_drawer_field_title")
                }
                options={fieldOptions}
                value={
                  flowType === "classification"
                    ? filters.category
                    : filters.field
                }
                name={flowType === "classification" ? "category" : "field"}
                onChange={handleFilterChange}
                className={classes.fieldTitle}
              />
            )}

            {formattedAppliedValues?.length > 0 && (
              <Box className={classes.valuesContainer}>
                {formattedAppliedValues.map((value, index) => (
                  <CoreTag
                    key={`${value}-${index}`}
                    label={value}
                    onDelete={() => handleValueRemove(index)}
                    className={classes.valueFilterChip}
                  />
                ))}
              </Box>
            )}
          </Box>
          <Box className={classes.actionsContainer}>
            <CoreButton
              onClick={handleAddFilter}
              disabled={!isFilterValid}
              variant="contained"
            >
              {translation("advanced_filters_drawer_add_action")}
              {/* edit mode: {translation("advanced_filters_drawer_edit_action")} */}
            </CoreButton>
          </Box>
        </>
      )}
    </CoreDrawer>
  );
};
