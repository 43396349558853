import React, { useRef } from "react";
import { Connection, Handle, HandleType, Position } from "reactflow";

import { Box } from "@mui/material";

import { useStores } from "../../../../../stores/StoresProvider";
import DiagramNodeHandleButton from "./DiagramNodeHandleButton";
import { ConditionalTabHandler } from "../conditional/ConditionalTabHandler";
import { NODE_HANDLE_TYPES } from "../../../../../types/constants";
import { GroupTypeFlag } from "./GroupTypeFlag";

interface NodeHandleProps {
  type: NODE_HANDLE_TYPES;
  onHandleClick?: () => void;
  hidden?: boolean;
  identifier: string;
  groupFlag?: string;
}

const DiagramNodeHandle: React.FC<NodeHandleProps> = ({
  type,
  onHandleClick,
  hidden,
  identifier,
  groupFlag,
}) => {
  const { flowSettingsStore } = useStores();
  const btnRef = useRef<HTMLDivElement | null>(null);

  const position =
    type === NODE_HANDLE_TYPES.target ? Position.Top : Position.Bottom;

  // Note: node target handles are automatically hidden, use the handle on the edge to connect.
  // Source nodes are hidden if they are connected.
  const hiddenStyle = {
    visibility: "hidden",
    pointerEvents: "none",
  } as React.CSSProperties;

  const onConnect = (connection: Connection) => {
    console.log("connected", connection);
  };

  const isValidConnection = (connection: Connection) => {
    console.log("connection valid?", connection);
    return true;
  };

  const handleClickHandler = () => {
    if (onHandleClick) {
      flowSettingsStore.setDesignerModalAddMode(true);
      flowSettingsStore.setSelectedEdgeId(undefined);
      //TODO: Uncomment this for parallel node branches
      // flowSettingsStore.openNodeOptionsPopup(btnRef.current);
      onHandleClick();
    }
  };

  return (
    <Handle
      ref={btnRef}
      type={type.valueOf() as HandleType}
      position={position}
      style={{
        background: "unset",
        border: "unset",
      }}
      onClick={handleClickHandler}
      onConnect={onConnect}
      isValidConnection={isValidConnection}
    >
      {type === NODE_HANDLE_TYPES.target ? (
        <ConditionalTabHandler nodeIdentifier={identifier} />
      ) : (
        <></>
      )}

      {groupFlag ? <GroupTypeFlag label={groupFlag || ""} /> : <></>}

      <Box style={{ position: "relative", ...(hidden ? hiddenStyle : {}) }}>
        <DiagramNodeHandleButton />
      </Box>
    </Handle>
  );
};

export default DiagramNodeHandle;
