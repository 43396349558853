/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { TFunction } from "i18next";

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  CreateNewFolder,
  ChangeCircle,
  HighlightOff,
} from "@mui/icons-material";

import { FormData, FormField } from "../../types/interfaces";
import CoreInput from "../core/CoreInput";
import { backendRoutes } from "../../configs/backendRoutes";
import { useNotification } from "../../context/useNotification";
import { useStores } from "../../stores/StoresProvider";

interface OneDriveInterface {
  open: (options: any) => void;
  save: (options: any) => void;
}

interface OneDriveFiles {
  value: {
    id: string;
    name: string;
  }[];
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const OneDriveInstance = (window as any).OneDrive as OneDriveInterface;

interface Props {
  value: unknown;
  field: FormField;
  onChange: (value: FormData) => void;
  translation: TFunction;
  errorText?: string;
}

export const OneDriveRenderer: React.FC<Props> = ({
  value,
  onChange,
  field,
  translation,
  errorText,
}) => {
  const readOnly = field.props?.readOnly;
  const notification = useNotification();
  const { mainStore } = useStores();

  const login = () => {
    const scopes = (field.customScopes ?? []).join(" ");

    window.open(
      `${backendRoutes.OneDriveAuthorization()}?scopes=${scopes}`,
      "_blank",
      "width=600,height=600"
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const eventListener = (event: MessageEvent) => {
    const { data } = event as {
      data?: {
        accessToken?: string;
        refreshToken?: string;
        account?: string;
        source?: string;
      };
    };

    if (!data) return;
    if (data.source !== "claritext") return;

    if (data.accessToken && data.refreshToken && data.account)
      openOneDrivePopup(data.accessToken, data.refreshToken, data.account);
  };

  useEffect(() => {
    window.addEventListener("message", eventListener);

    return () => {
      window.removeEventListener("message", eventListener);
    };
  }, [eventListener]);

  const openOneDrivePopup = (
    accessToken: string,
    refreshToken: string,
    account: string
  ) => {
    const { username, name } = JSON.parse(account) as {
      username: string;
      name: string;
    };

    const odOptions = {
      clientId: mainStore.settings?.onedriveClientId ?? "",
      action: "query",
      multiSelect: false,
      openInNewWindow: false,
      viewType: "all",
      advanced: {
        accessToken,
        queryParameters: "select=id,name",
        redirectUri: mainStore.settings?.onedriveRedirectUriFrontend ?? "",
      },
      success: (files: OneDriveFiles) => {
        onChange({
          [field.key]: {
            credentials: {
              refreshToken,
            },
            directory: {
              id: files.value[0].id,
              name: files.value[0].name,
            },
            user: {
              email: username ?? "",
              name: name ?? "",
            },
          },
        });
      },
      error: () => {
        notification.error(translation("errorOneDrive"));
      },
    };
    OneDriveInstance.open(odOptions);
  };

  const switchAccount = () => {
    onChange({ [field.key]: undefined });
    login();
  };

  const theme = useTheme();

  const classes = makeStyles({
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    containerLoggedIn: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    typographyNameEmail: {
      margin: "auto",
    },
    switchUser: {
      marginRight: "10px",
    },
    containerNotLoggedIn: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    containerTextInput: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginRight: "5px",
    },
    containerInput: {
      display: "flex",
    },
  })();

  if (value) {
    const { directory, user } = value as {
      directory: {
        id: string;
        name: string;
      };
      user: {
        email: string;
        name: string;
      };
    };

    const handleClear = () => {
      onChange({ [field.key]: undefined });
    };

    const clearInput = (
      <IconButton onClick={handleClear} disabled={readOnly}>
        <HighlightOff />
      </IconButton>
    );
    return (
      <Box>
        <Typography>
          {translation("directory")}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
        <CoreInput value={directory.name} readOnly endAdornment={clearInput} />

        <Box className={classes.containerLoggedIn}>
          <Box>
            <Typography variant="body2" className={classes.typographyNameEmail}>
              {user.name}
            </Typography>
            <Typography variant="body2" className={classes.typographyNameEmail}>
              {user.email}
            </Typography>
          </Box>
          <Button onClick={switchAccount} disabled={readOnly}>
            <Typography className={classes.switchUser}>
              {translation("switchUser")}
            </Typography>
            <ChangeCircle />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.containerNotLoggedIn}>
      <Box className={classes.containerTextInput}>
        <Typography>
          {translation("directory")}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
        <Box className={classes.containerInput}>
          <CoreInput
            value={translation("noOneDriveFolder")}
            readOnly
            fullWidth
            error={!!field.errorText || !!errorText}
            errorText={field.errorText || errorText}
            icon={CreateNewFolder}
            iconOnClick={login}
          />
        </Box>
      </Box>
    </Box>
  );
};
