import React from "react";
import { useTranslation } from "react-i18next";
import _isUndefined from "lodash/isUndefined";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import CoreTooltip from "../../../core/CoreTooltip";
import { AppSuspense } from "../../../main/AppSuspense";
import CoreButton from "../../../core/CoreButton";

type TestConnectionStatusType = {
  isConnectionValid: boolean | undefined;
  handleSave: (isTest: boolean) => void;
  isTesting?: boolean;
};

const TestConnectionButton: React.FC<TestConnectionStatusType> = ({
  isConnectionValid,
  handleSave,
  isTesting,
}) => {
  const { t, ready } = useTranslation("addFlow");
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    testButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50px",
      height: "25px",
    },
  }));

  let tooltipTitle = t("connection_invalid_tooltip");
  let icon = (
    <ErrorIcon
      style={{
        color: theme.palette.error.main,
        marginLeft: "5px",
        width: "20px",
        height: "20px",
      }}
    />
  );

  const classes = useStyles();

  if (!ready) return <AppSuspense />;

  if (_isUndefined(isConnectionValid)) {
    tooltipTitle = t("connection_info_tooltip");
    icon = (
      <InfoIcon
        style={{
          color: theme.palette.info.main,
          marginLeft: "5px",
          width: "20px",
          height: "20px",
        }}
      />
    );
  }

  if (isConnectionValid) {
    tooltipTitle = t("connection_valid_tooltip");
    icon = (
      <CheckCircleIcon
        style={{
          color: theme.palette.success.dark,
          marginLeft: "5px",
          width: "20px",
          height: "20px",
        }}
      />
    );
  }

  return (
    <CoreTooltip title={isTesting ? "" : tooltipTitle ?? ""} placement="top">
      <span>
        <CoreButton
          onClick={() => handleSave(true)}
          isLoading={isTesting}
          disabled={isTesting}
        >
          <div className={classes.testButton}>
            {!isTesting && (
              <>
                {t("test_connection")}
                {icon}
              </>
            )}
          </div>
        </CoreButton>
      </span>
    </CoreTooltip>
  );
};

export default TestConnectionButton;
