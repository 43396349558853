import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

import { FlowDataCategoryItem } from "../../../../types/interfaces/flow";
import { TableContentDef, TableHeaderDef } from "../../../../types/interfaces";
import { PAGE_SIZES, SORT_DIRECTION } from "../../../../types/constants";
import { Order } from "../../../../types/types";
import {
  calculateNoOfPages,
  extractCurrentDataPage,
  getTableDefinition,
} from "./helper";
import DateHelper from "../../../../helper/dateHelper";
import CoreTable from "../../../core/CoreTable";
import DataCategoryTableActions from "./DataCategoryTableActions";
import ConfirmActionModal from "./ConfirmActionModal";
import FlowDataCategoryCrudDrawer from "./FlowDataCategoryCrudDrawer";
import { CoreStatusTag } from "../../../core/CoreStatusTag";

interface Props {
  t: TFunction;
  data: FlowDataCategoryItem[];
  textSearch: string;
  fetchData: () => void;
  isLoading: boolean;
}

const COL_WIDTH: TableContentDef = {
  actions: "7%",
};

const DEFAULT_FILTERS = {
  page: 1,
  pageSize: 10,
  sortBy: "createdAt",
  sortDirection: "desc" as Order,
};

const useStyles = makeStyles({
  container: {
    marginTop: "50px",
    width: "100%",
    minWidth: "600px",
    display: "flex",
    flexDirection: "row",
    "@media only screen and (max-width: 900px)": {
      display: "block",
    },
  },
  cellContainer: {
    marginLeft: "30px",
    "@media only screen and (min-width: 1692px)": {
      marginLeft: "60px",
    },
  },
});

export const DataCategoryTable: FC<Props> = ({
  t,
  textSearch,
  data,
  fetchData,
  isLoading,
}) => {
  const { type } = useParams<{ type: string }>();
  const [actionToExecute, setActionToExecute] = useState<null | {
    identifier: string;
    action: string;
  }>(null);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [itemToEdit, setItemToEdit] = useState<
    FlowDataCategoryItem | undefined
  >();

  const classes = useStyles();

  useEffect(() => {
    setFilters(DEFAULT_FILTERS);
  }, [textSearch]);

  const tableHeaders = useMemo(
    () =>
      getTableDefinition(t, type)
        // Handle sorting
        ?.map((item) =>
          filters.sortBy === item.accessor
            ? {
                ...item,
                options: {
                  ...item.options,
                  sortDirection: filters.sortDirection,
                  isSortActive: true,
                },
              }
            : {
                ...item,
                options: { ...item.options, isSortActive: false },
              }
        ),
    [filters.sortBy, filters.sortDirection, t, type]
  );

  const numberOfPages = useMemo(
    () => calculateNoOfPages(data?.length, filters?.pageSize),
    [data, filters.pageSize]
  );

  const pageData = useMemo(() => {
    const pageBatch = extractCurrentDataPage(data, filters, textSearch);

    if (pageBatch.length > 0) {
      return pageBatch.map((item) => {
        return {
          ...item,
          ...(item.updatedAt && {
            updatedAt: DateHelper.timeStringFormat(item.updatedAt),
          }),
          ...(item.createdAt && {
            createdAt: DateHelper.timeStringFormat(item.createdAt),
          }),
          ...(item.lastRun && {
            lastRun: DateHelper.timeStringFormat(item.lastRun),
          }),
          ...(item.status && {
            status: <CoreStatusTag label={t(item.status)} type={item.status} />,
          }),
          processedDocuments: (
            <Typography className={classes.cellContainer}>
              {item.processedDocuments}
            </Typography>
          ),
          actions: (
            <DataCategoryTableActions
              item={item}
              setActionToExecute={setActionToExecute}
              setItemToEdit={setItemToEdit}
            />
          ),
        };
      });
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filters, t, textSearch]);

  const onPageChange = (_event: unknown, page: number) => {
    setFilters({ ...filters, page });
  };

  const onPageSizeChange = (pageSize: number) => {
    setFilters({ ...filters, page: 1, pageSize });
  };

  const handleSort = (cell: TableHeaderDef) => {
    const newDirection = (cell.options?.sortDirection === SORT_DIRECTION.asc
      ? SORT_DIRECTION.desc
      : SORT_DIRECTION.asc) as unknown as Order;

    setFilters({
      ...filters,
      sortBy: cell.accessor,
      sortDirection: newDirection,
    });
  };

  return (
    <Box className={classes.container}>
      <CoreTable
        columnWidth={COL_WIDTH}
        data={pageData as unknown as TableContentDef[]}
        isLoading={isLoading}
        headers={tableHeaders}
        handleSorting={handleSort}
        numberOfPages={numberOfPages}
        totalCount={data?.length || 0}
        rowsPerPage={filters.pageSize}
        setRowsPerPage={(pageSize) => onPageSizeChange(pageSize as number)}
        rowsPerPageOptions={PAGE_SIZES}
        currentPage={filters.page}
        changePage={onPageChange}
      />

      <ConfirmActionModal
        isOpen={!!actionToExecute}
        data={actionToExecute}
        onClose={(refresh?: boolean) => {
          if (refresh) fetchData();
          setActionToExecute(null);
        }}
      />

      <FlowDataCategoryCrudDrawer
        isOpen={!!itemToEdit}
        itemToEdit={itemToEdit}
        type={type}
        onClose={(refresh?: boolean) => {
          if (refresh) fetchData();
          setItemToEdit(undefined);
        }}
      />
    </Box>
  );
};
