import React, { FC } from "react";

import { DocumentRetryAction } from "./DocumentRetryAction";
import { DocumentStore } from "../../../../stores/DocumentStore";
import { CoreStatusCell } from "../../../core/CoreStatusCell";
import { StatusDef } from "../../../../types/interfaces";

interface Props {
  status: StatusDef;
  documentStore: DocumentStore;
  page: number;
  applyFilters: (page: number) => void;
  identifier: string;
}

const StatusBox: FC<Props> = ({
  status,
  page,
  documentStore,
  applyFilters,
  identifier,
}) => {
  return (
    <>
      <CoreStatusCell status={status} />

      {status?.type === "error" && (
        <DocumentRetryAction
          page={page}
          id={identifier}
          documentStore={documentStore}
          applyFilters={applyFilters}
        />
      )}
    </>
  );
};

export default StatusBox;
