import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  loadingBox: {
    minWidth: "150px",
    minHeight: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContent: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  title: {
    marginBottom: "10px",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
  },
});

const CANVAS_SHORTCUTS_KEYS = {
  scrollVertical: "scrollVertical",
  scrollHorizontal: "scrollHorizontal",
  zoom: "zoom",
  navigate: "navigate",
  multiSelect: "multiSelect",
  space: "space",
};
const CANVAS_SHORTCUTS_KEYS_FOCUSED = {
  navBackward: "navBackward",
  enter: "enter",
  delete: "delete",
  tab: "tab",
  escape: "escape",
};

const CANVAS_SHORTCUTS = {
  [CANVAS_SHORTCUTS_KEYS.scrollVertical]: "canvas_shortcut_scrollVertical_name",
  [CANVAS_SHORTCUTS_KEYS.scrollHorizontal]:
    "canvas_shortcut_scrollHorizontal_name",
  [CANVAS_SHORTCUTS_KEYS.zoom]: "canvas_shortcut_zoom_name",
  [CANVAS_SHORTCUTS_KEYS.navigate]: "canvas_shortcut_navigate_name",
  [CANVAS_SHORTCUTS_KEYS.multiSelect]: "canvas_shortcut_multiSelect_name",
  [CANVAS_SHORTCUTS_KEYS.space]: "canvas_shortcut_space_name",
};

const CANVAS_SHORTCUTS_DESCRIPTIONS = {
  [CANVAS_SHORTCUTS_KEYS.scrollVertical]:
    "canvas_shortcut_scrollVertical_description",
  [CANVAS_SHORTCUTS_KEYS.scrollHorizontal]:
    "canvas_shortcut_scrollHorizontal_description",
  [CANVAS_SHORTCUTS_KEYS.zoom]: "canvas_shortcut_zoom_description",
  [CANVAS_SHORTCUTS_KEYS.navigate]: "canvas_shortcut_navigate_description",
  [CANVAS_SHORTCUTS_KEYS.multiSelect]:
    "canvas_shortcut_multiSelect_description",
  [CANVAS_SHORTCUTS_KEYS.space]: "canvas_shortcut_space_description",
};
const CANVAS_SHORTCUTS_FOCUSED_FIELD = {
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.enter]: "canvas_shortcut_enter_focused_name",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.delete]: "canvas_shortcut_delete_focused_name",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.navBackward]:
    "canvas_shortcut_navBackward_focused_name",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.tab]: "canvas_shortcut_tab_focused_name",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.escape]: "canvas_shortcut_escape_focused_name",
};
const CANVAS_SHORTCUTS_FOCUSED_FIELD_DESCRIPTIONS = {
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.enter]:
    "canvas_shortcut_enter_focused_description",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.delete]:
    "canvas_shortcut_delete_focused_description",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.navBackward]:
    "canvas_shortcut_navBackward_focused_description",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.tab]:
    "canvas_shortcut_tab_focused_description",
  [CANVAS_SHORTCUTS_KEYS_FOCUSED.escape]:
    "canvas_shortcut_escape_focused_description",
};

const CanvasShortcutsContent: FC = () => {
  const { t, ready } = useTranslation("core");
  const classes = useStyles();

  // TODO: Refactor - one config reguired, not splitted (reduce code)
  const shortcutKeys = useMemo(() => Object.keys(CANVAS_SHORTCUTS_KEYS), []);
  const shortcutsKeysFocused = useMemo(
    () => Object.keys(CANVAS_SHORTCUTS_KEYS_FOCUSED),
    []
  );

  if (!ready) {
    return (
      <Box className={classes.loadingBox}>
        <CircularProgress size="30px" color="inherit" />
      </Box>
    );
  }

  return (
    <Grid container direction="column">
      <Grid item className={classes.titleContent}>
        <Typography className={classes.title}>{t("shortcuts")}</Typography>
        <Divider />
      </Grid>

      {shortcutKeys.map((shortcutKey) => (
        <Grid container key={shortcutKey}>
          <Grid item className={classes.gridItem} xs={6}>
            <Typography key={shortcutKey} variant="caption">
              {t(CANVAS_SHORTCUTS[shortcutKey])} -
            </Typography>
          </Grid>

          <Grid item className={classes.gridItem} xs={6}>
            <Typography key={shortcutKey} variant="caption">
              {t(CANVAS_SHORTCUTS_DESCRIPTIONS[shortcutKey])}
            </Typography>
          </Grid>
        </Grid>
      ))}

      <Divider />

      <Grid item className={classes.titleContent}>
        <Typography className={classes.title}>
          {t("focusedFieldShortcuts")}
        </Typography>
        <Divider />
      </Grid>
      {shortcutsKeysFocused.map((shortcutKeyFocused) => (
        <Grid container key={shortcutKeyFocused}>
          <Grid item className={classes.gridItem} xs={6}>
            <Typography key={shortcutKeyFocused} variant="caption">
              {t(CANVAS_SHORTCUTS_FOCUSED_FIELD[shortcutKeyFocused])} -
            </Typography>
          </Grid>

          <Grid item className={classes.gridItem} xs={6}>
            <Typography key={shortcutKeyFocused} variant="caption">
              {t(
                CANVAS_SHORTCUTS_FOCUSED_FIELD_DESCRIPTIONS[shortcutKeyFocused]
              )}
            </Typography>
          </Grid>
        </Grid>
      ))}

      <Divider />

      <Grid item className={classes.titleContent}>
        <Typography className={classes.title}>
          {t("operationShortcuts")}
        </Typography>
        <Divider />
      </Grid>
      <Grid container>
        <Grid item className={classes.gridItem} xs={6}>
          <Typography variant="caption">
            {t("operationShortcuts_autoDetect_label")} -
          </Typography>
        </Grid>

        <Grid item className={classes.gridItem} xs={6}>
          <Typography variant="caption">
            {t("operationShortcuts_autoDetect_keyValue")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item className={classes.gridItem} xs={6}>
          <Typography variant="caption">
            {t("operationShortcuts_drawLine_label")} -
          </Typography>
        </Grid>

        <Grid item className={classes.gridItem} xs={6}>
          <Typography variant="caption">
            {t("operationShortcuts_drawLine_keyValue")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item className={classes.gridItem} xs={6}>
          <Typography variant="caption">
            {t("operationShortcuts_ignoreAddLine_label")} -
          </Typography>
        </Grid>

        <Grid item className={classes.gridItem} xs={6}>
          <Typography variant="caption">
            {t("operationShortcuts_ignoreAddLine_keyValue")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(CanvasShortcutsContent);
