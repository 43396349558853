import _isEmpty from "lodash/isEmpty";
import { TFunction } from "i18next";
import { NotificationContextProps } from "../../../context/useNotification";
import {
  ConnectionField,
  ConnectionFieldData,
  FormData,
} from "../../../types/interfaces";
import { infoConfig } from "./defaultConfig";

export class ConnectionsHelper {
  static populateFields = (
    fields: ConnectionField[],
    setMandatoryFields: (mandatoryFields: string[]) => void,
    mandatoryFields: string[],
    replace = false
  ): FormData => {
    let res = {};
    const mandatory: string[] = [];

    fields.forEach((field) => {
      if (field.isMandatory) {
        mandatory.push(field.key);
      }
      if (field.fields && field.fields.length > 0) {
        res = {
          ...res,
          [field.key]: field.value,
          ...ConnectionsHelper.populateFields(
            field.fields,
            setMandatoryFields,
            mandatoryFields
          ),
        };
      } else {
        res = { ...res, [field.key]: field.value };
      }
    });

    const newMandatoryFields = replace
      ? [...mandatory]
      : [...mandatoryFields, ...mandatory];
    setMandatoryFields(newMandatoryFields);
    return { ...res };
  };

  static onFileUpload = (
    event: React.FormEvent<HTMLInputElement>,
    onFormChange: (value: FormData) => void,
    notification: NotificationContextProps,
    t: TFunction
  ) => {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target?.files && target?.files.length > 0) {
      const file = target.files[0];
      file
        .text()
        .then((contents) => {
          const fileData = JSON.parse(contents) as ConnectionFieldData[];

          // File is valid if minimum one property is found in JSON
          const validFields = fileData.filter((item) =>
            infoConfig(t).some((field) => item.key === field.key)
          );

          if (!_isEmpty(validFields)) {
            onFormChange(
              Object.fromEntries(
                fileData.map((item) => [item.key, item.value])
              ) as FormData
            );
          } else {
            notification.error(t("uploadConfigError"));
          }
        })
        .catch(() => {
          notification.error(t("uploadJSONError"));
        });
    }
  };
}
