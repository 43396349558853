import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import ConstructionIcon from "@mui/icons-material/Construction";
import PauseIcon from "@mui/icons-material/Pause";
import StartIcon from "@mui/icons-material/Start";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import {
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";

import { FLOW_DATA_TABLE_ACTIONS } from "../../../../types/constants";
import { FlowDataCategoryItem } from "../../../../types/interfaces/flow";
import { FLOW_DATA_CATEGORY_ITEM_STATUSES } from "../../../../types/enums";
import CoreButton from "../../../core/CoreButton";

interface Props {
  setActionToExecute: (
    actionToExecute: {
      identifier: string;
      action: string;
    } | null
  ) => void;
  item: FlowDataCategoryItem;
  setItemToEdit: (itemToEdit: FlowDataCategoryItem) => void;
}

const DataCategoryTableActions: FC<Props> = ({
  setActionToExecute,
  item,
  setItemToEdit,
}) => {
  const { t } = useTranslation("flow");
  const theme = useTheme();
  const history = useHistory();

  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
    },
    icon: {
      fontSize: "20px",
      color: "inherit",
    },
    mobileList: {
      "& ul": {
        padding: 0,
        boxShadow: "11px 16px 7px -10px rgba(0,0,0,0.43)",
      },
    },
    headerActions: {
      margin: "3px",
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      color: "inherit",
    },
    menuItem: {
      background: theme.palette.background.paper,
      textAlign: "center",
      display: "flex",
      justifyContent: "flex-start",
      "&:hover": {
        color: `${theme.palette.highlight.main} !important`,
      },
    },
    coreButton: {
      borderRadius: 100,
      minWidth: 0,
      marginLeft: "10px",
      padding: "5px",
    },
    moreVertIcon: {
      fontSize: 20,
    },
  });

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const headerActions = useMemo(() => {
    return [
      {
        label: FLOW_DATA_TABLE_ACTIONS.edit,
        tooltip: t("editButton"),
        onClick: () => setItemToEdit(item),
        startIcon: <EditIcon />,
        visibility: true,
      },
      {
        label: FLOW_DATA_TABLE_ACTIONS.runNow,
        tooltip: t("runNowButton"),
        startIcon: <ConstructionIcon />,
        visibility: true,
      },
      {
        label: FLOW_DATA_TABLE_ACTIONS.pause,
        tooltip: t("pauseButton"),
        startIcon: <PauseIcon />,
        visibility: item.status === FLOW_DATA_CATEGORY_ITEM_STATUSES.RUNNING,
      },
      {
        label: FLOW_DATA_TABLE_ACTIONS.resume,
        tooltip: t("resumeButton"),
        startIcon: <StartIcon />,
        visibility: item.status === FLOW_DATA_CATEGORY_ITEM_STATUSES.PAUSED,
      },
      {
        label: FLOW_DATA_TABLE_ACTIONS.delete,
        tooltip: t("deleteButton"),
        startIcon: <DeleteOutlineOutlinedIcon />,
        visibility: true,
      },
      {
        label: FLOW_DATA_TABLE_ACTIONS.viewLogs,
        tooltip: t("viewLogsButton"),
        onClick: () => {
          history.push(`/activity-log/${item.identifier}`);
        },
        startIcon: <DataUsageIcon className={classes.icon} />,
        visibility: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.status, t]);

  const handleClickAction = (actionType: string) => {
    setActionToExecute({
      identifier: item.identifier,
      action: actionType,
    });
  };

  return (
    <Box className={classes.mainContainer}>
      {headerActions.length > 0 && (
        <>
          <CoreButton
            variant="neutral"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="medium"
            className={classes.coreButton}
            disabled={false}
          >
            <MoreVertIcon className={classes.moreVertIcon} />
          </CoreButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            className={classes.mobileList}
          >
            <MenuList dense>
              {headerActions.map((action, index) => {
                if (action.visibility) {
                  return (
                    <MenuItem
                      key={`submenu-${action.label}-${index}`}
                      onClick={() => {
                        action.onClick
                          ? action.onClick()
                          : handleClickAction(action.label ?? "");
                        setAnchorEl(null);
                      }}
                      className={classes.menuItem}
                    >
                      <ListItemIcon className={classes.icon}>
                        {action.startIcon}
                      </ListItemIcon>
                      <Typography className={classes.headerActions}>
                        {action.tooltip}
                      </Typography>
                    </MenuItem>
                  );
                } else return null;
              })}
            </MenuList>
          </Menu>
        </>
      )}
    </Box>
  );
};

export default DataCategoryTableActions;
