import React, { FC, useEffect } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { toPng } from "html-to-image";

import {
  useReactFlow,
  Panel,
  getNodesBounds,
  getViewportForBounds,
} from "reactflow";

import { makeStyles } from "@mui/styles";
import { IconButton, useTheme } from "@mui/material";

import { useStores } from "../../../../../stores/StoresProvider";

interface Props {
  t: TFunction;
}

const ExportAsPngButton: FC<Props> = observer(({ t }) => {
  const { getNodes } = useReactFlow();
  const { flowSettingsStore } = useStores();

  const theme = useTheme();
  const classes = makeStyles({
    iconButton: {
      visibility: "hidden",
      fontSize: "0px",
    },
  })();

  const downloadImage = (dataUrl: string) => {
    const a = document.createElement("a");

    a.setAttribute("download", "exported_diagram.png");
    a.setAttribute("href", dataUrl);
    a.click();
  };

  const onClick = async () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(getNodes());
    const transform = getViewportForBounds(nodesBounds, 1024, 768, 0.5, 2, 1);

    await toPng(
      document.querySelector(".react-flow__viewport") as unknown as HTMLElement,
      {
        backgroundColor: theme.palette.background.default,
        width: 1024,
        height: 768,
        style: {
          width: "1024",
          height: "768",
          transform: `translate(${transform.x}px, ${transform.y}px) scale(${transform.zoom})`,
        },
      }
    ).then(downloadImage);

    flowSettingsStore.setTriggerDiagramPngExport(false);
  };

  useEffect(() => {
    if (flowSettingsStore.triggerDiagramPngExport) void onClick();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.triggerDiagramPngExport]);

  return (
    <Panel position="top-right">
      <IconButton className={classes.iconButton}>{t("exportPNG")}</IconButton>
    </Panel>
  );
});

export default ExportAsPngButton;
