import { Components, ThemeOptions, alpha } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiTab = (theme: ThemeOptions): Components["MuiTab"] => {
  const palette = theme.palette as ThemePalette;
  return {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          color: palette.secondary.main,
        },
        "&:hover": {
          color: `${alpha(palette.highlight.main, 0.8)} !important`,
        },
      },
    },
  };
};
