import React, { useEffect, useMemo, useRef } from "react";
import AceEditor from "react-ace";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { useTheme, Typography, Box } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/worker-json";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-monokai"; // Mono
import "ace-builds/src-noconflict/theme-tomorrow"; // Light
import "ace-builds/src-noconflict/theme-twilight"; // Dark

import { THEME_MODES } from "../../../../types/constants";

import { useStores } from "../../../../stores/StoresProvider";
import { FormFieldLanguageTypes } from "../../../../types/enums";

interface Props {
  value?: string | undefined;
  errorText?: string;
  label?: string;
  isMandatory?: boolean;
  isReadOnly?: boolean;
  mode?: FormFieldLanguageTypes;
  editorHeight?: string;
  onChange?: (json: string) => void;
  showLabel?: boolean;
  textAtCursor?: string;
  disableDiagramField?: boolean;
}

const EDITOR_THEME = {
  [THEME_MODES.light]: "tomorrow",
  [THEME_MODES.mono]: "monokai",
  [THEME_MODES.dark]: "twilight",
};

const EditorJson: React.FC<Props> = observer(
  ({
    onChange,
    value = "",
    errorText,
    label,
    isMandatory,
    isReadOnly,
    textAtCursor,
    mode = FormFieldLanguageTypes.json,
    editorHeight = "300px",
    showLabel = true,
    disableDiagramField = false,
  }) => {
    const theme = useTheme();

    const { mainStore } = useStores();

    const editorRef = useRef<AceEditor | null>(null);

    //TODO: Analyze removing new lines characters with tabs (visible in editor)
    const parsedValue = useMemo(() => value?.replace(/^:\$/g, "\n\t"), [value]);

    const useStyles = makeStyles({
      cancelIcon: { color: theme.palette.error.dark, height: "1.25rem" },
      boxError: { display: "flex", flexDirection: "row", marginTop: "5px" },
      asterisk: {
        color: theme.palette.error.main,
        marginLeft: "5px",
      },
      formText: {
        margin: "auto 0",
        minHeight: "1.25rem",
        height: "auto",
      },
      container: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.neutral.dark,
        borderRadius: "2px",
        backgroundColor: theme.palette.background.paper,
        marginBottom: "10px",
      },
    });

    const classes = useStyles();

    const handleOnChange = (updatedValue: string) => {
      onChange && onChange(updatedValue);
    };

    useEffect(() => {
      if (textAtCursor) {
        const editor = editorRef.current?.editor;
        if (!editor) return;
        const cursorPosition = editor.getCursorPosition();
        editor.session.insert(cursorPosition, textAtCursor);
      }
    }, [textAtCursor]);

    return (
      <>
        {showLabel && (
          <Typography
            variant={!errorText ? "subtitle1" : "body2"}
            color={
              errorText
                ? theme.palette?.error.light
                : theme.palette?.highlight.light
            }
          >
            {label} {isMandatory && <span className={classes.asterisk}>*</span>}
          </Typography>
        )}

        <AceEditor
          ref={editorRef}
          wrapEnabled
          showGutter
          showPrintMargin
          highlightActiveLine
          fontSize={14}
          width="auto"
          height={editorHeight}
          mode={mode}
          readOnly={isReadOnly || disableDiagramField}
          theme={EDITOR_THEME[mainStore.currentTheme]}
          className={classes.container}
          setOptions={{
            enableBasicAutocompletion: true,
            showLineNumbers: true,
            tabSize: 2,
          }}
          value={parsedValue}
          onChange={handleOnChange}
        />

        {errorText && (
          <Box className={classes.boxError}>
            <CancelOutlinedIcon className={classes.cancelIcon} />

            <FormHelperText error={!!errorText} className={classes.formText}>
              {errorText}
            </FormHelperText>
          </Box>
        )}
      </>
    );
  }
);

export default EditorJson;
