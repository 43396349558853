import React, { FC, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

import CoreInput from "../../core/CoreInput";
import CoreButton from "../../core/CoreButton";
import { useStores } from "../../../stores/StoresProvider";
import { AppSuspense } from "../../main/AppSuspense";
import { emailRegex } from "../../../types/regex";
import { authRoutes } from "../../../configs/routes";
import Languages from "../../main/sidebar/account/Languages";

const ForgotPassword: FC = observer(() => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [operationInProgress, setOperationInProgress] = useState(false);

  const { userStore } = useStores();
  const client = useApolloClient();

  const theme = useTheme();
  const { t, ready } = useTranslation("forgot-password");

  const useStyle = makeStyles({
    mainContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    pageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
    resetContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      minWidth: "300px",
    },
    languageContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "20px",
    },
    textBoxAfterReset: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.dark,
      borderRadius: "5px",
      padding: "10px",
    },
    button: {
      textTransform: "none",
      height: "40px",
      margin: "10px 0px",
      display: "flex",
      alignItems: "center",
    },
    footer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    error: {
      alignSelf: "center",
      color: theme.palette.error.main,
    },
  });
  const classes = useStyle();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setEmail(value);
  };

  const handleReset = () => {
    const forgotInput =
      email?.trim() === ""
        ? t("error_input")
        : !emailRegex.test(email)
        ? t("email_invalidForm")
        : "";
    if (forgotInput) {
      setError(forgotInput);
      return;
    } else {
      setError("");
      setForgotError("");
    }

    setOperationInProgress(true);
    userStore
      .checkIfEmailIsUsed(client, email)
      .then((res) => {
        if (!res) {
          setForgotError(t("email_notFound"));
          setOperationInProgress(false);
          return;
        }
        userStore
          .forgotPassword(client, email)
          .then(() => {
            setShowMessage(true);
            setOperationInProgress(false);
          })
          .catch((error: Error) => {
            setForgotError(t(error?.message || "error"));
            setOperationInProgress(false);
          });
      })
      .catch((error: Error) => {
        setError(t(error?.message || "error"));
        setOperationInProgress(false);
      });
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleReset();
  };

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.languageContainer}>
        <Languages t={t} />
      </Box>

      <Container maxWidth="xs" className={classes.pageContainer}>
        <Box className={classes.resetContainer}>
          <Typography variant="h5" fontWeight="600" mb="25px">
            {t("page_title")}
          </Typography>

          {showMessage && (
            <Box className={classes.textBoxAfterReset}>
              <Typography>{`${t("start_resetMessage")} ${email} ${t(
                "end_resetMessage"
              )}`}</Typography>
            </Box>
          )}
          <form onSubmit={handleSubmit}>
            <CoreInput
              value={email}
              name="email"
              title={t("input_title")}
              placeholder={t("input_placeholder")}
              onChange={handleInput}
              errorText={error}
              disabled={operationInProgress}
            />
            <Box className={classes.button}>
              <CoreButton
                onClick={handleReset}
                disabled={operationInProgress}
                isLoading={operationInProgress}
                variant="contained"
                isFullWidth
                type="submit"
              >
                {t("button_message")}
              </CoreButton>
            </Box>
          </form>
          {forgotError && <Box className={classes.error}>{forgotError}</Box>}

          <Box className={classes.footer}>
            <Link href={authRoutes.Login()} mt="30px">
              {t("goBack_afterReset")}
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
});

export default ForgotPassword;
