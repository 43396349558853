import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { Box, Container, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { authRoutes } from "../../../configs/routes";
import { useNotification } from "../../../context/useNotification";
import { useStores } from "../../../stores/StoresProvider";
import { emailRegex } from "../../../types/regex";
import CoreButton from "../../core/CoreButton";
import { AppSuspense } from "../../main/AppSuspense";
import CoreInput from "../../core/CoreInput";

const useStyle = makeStyles({
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    minWidth: "300px",
  },
  form: {
    gap: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

const ResendActivation: React.FC = () => {
  const [isOperationInProgress, setIsOperationInProgress] = useState(false);
  const [errors, setErrors] = useState("");
  const [email, setEmail] = useState("");

  const client = useApolloClient();
  const notification = useNotification();
  const classes = useStyle();
  const { userStore } = useStores();
  const { t, ready } = useTranslation("login");
  const history = useHistory();

  const handleResend = () => {
    if (!emailRegex.test(email)) {
      setErrors(t("email_invalidForm"));
      return;
    }

    setIsOperationInProgress(true);
    userStore
      .resendActivationLink(client, email)
      .then(() => {
        notification.success(t("emailResend_success"));
        history.push(authRoutes.Login());
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "emailResend_error"));
      })
      .finally(() => {
        setIsOperationInProgress(false);
      });
  };

  if (!ready) {
    return <AppSuspense />;
  }

  return (
    <Container maxWidth="xs" className={classes.pageContainer}>
      <Box className={classes.mainContainer}>
        <Typography variant="h5" fontWeight="600" mb="25px">
          {t("resendActivationLink_title")}
        </Typography>

        <Box className={classes.form}>
          <CoreInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            title={t("email_inputTitle")}
            error={!!errors}
            errorText={errors}
          />
          <CoreButton
            onClick={handleResend}
            isLoading={isOperationInProgress}
            disabled={isOperationInProgress}
            variant="contained"
          >
            {t("resendActivationLink_button")}
          </CoreButton>
        </Box>
        <Box className={classes.footer}>
          <Link href={authRoutes.Login()} mt="30px">
            {t("goBack_afterReset")}
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default ResendActivation;
