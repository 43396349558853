import React, { FC, memo, useMemo } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import CoreTable from "../../../core/CoreTable";
import CoreTextLink from "../../../core/CoreTextLink";
import CoreTooltip from "../../../core/CoreTooltip";
import { useStores } from "../../../../stores/StoresProvider";
import { appRoutes } from "../../../../configs/routes";
import DateHelper from "../../../../helper/dateHelper";
import ActivityLogsHelper from "../../../../helper/activityLogsHelper";
import { CoreLevelIcon } from "../../../core/CoreLevelIcon";
import { OperationData } from "./OperationData";
import { PAGE_SIZES } from "../../../../types/constants";
import { CoreStatusTag } from "../../../core/CoreStatusTag";

interface Props {
  rowsPerPage: number;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  totalCount?: number;
}

const HEADERS = [
  {
    accessor: "operation",
    label: "table_col_operation_name",
  },
  {
    accessor: "status",
    label: "table_col_status",
  },
  {
    accessor: "time",
    label: "table_col_time",
  },
  {
    accessor: "createdAt",
    label: "table_col_createdAt",
  },
  {
    accessor: "initiatedBy",
    label: "table_col_initiatedBy",
  },
  {
    accessor: "event",
    label: "table_col_event",
  },
];

const useStyles = makeStyles({
  container: {
    margin: "20px",
  },
  operationBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  operationText: {
    marginLeft: "5px",
  },
  user: {
    fontSize: "0.875rem",
  },
});

const DataTable: FC<Props> = observer(
  ({ rowsPerPage, setRowsPerPage, totalCount }) => {
    const { t } = useTranslation("activityLogs");
    const classes = useStyles();
    const {
      activityLogStore: {
        isTracingLoading,
        activityLogs,
        currentPage,
        totalPages,
        setCurrentPage,
      },
    } = useStores();

    const formattedHeaders = useMemo(
      () =>
        HEADERS.map((header) => ({
          ...header,
          label: t(header.label),
        })),
      [t]
    );

    const formattedData = useMemo(
      () =>
        !activityLogs || activityLogs?.length === 0
          ? []
          : activityLogs.map((item) => ({
              ...item,
              status: (
                <CoreStatusTag label={t(item.status)} type={item.status} />
              ),
              time: DateHelper.getTimeDifference(item.time, t),
              createdAt: DateHelper.timeStringFormat(item.time),
              operation: (
                <Box className={classes.operationBox}>
                  <CoreLevelIcon level={item?.status} />
                  <Box className={classes.operationText}>
                    <OperationData event={item} translation={t} />
                  </Box>
                </Box>
              ),
              initiatedBy:
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                item.eventData?.user && item.eventData?.user?.email ? (
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  <CoreTooltip title={item.eventData?.user?.email}>
                    <Typography className={classes.user}>
                      {ActivityLogsHelper.initiatedByFormat(item.eventData)}
                    </Typography>
                  </CoreTooltip>
                ) : (
                  ActivityLogsHelper.initiatedByFormat(item.eventData)
                ),
              event: (
                <CoreTextLink
                  to={appRoutes.ContextEvent(item.eventIdentifier)}
                  label={t("table_view_details_button")}
                />
              ),
            })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [activityLogs, t]
    );

    const handleChangePage = (_: unknown, newPage: number) => {
      setCurrentPage(newPage);
    };

    return (
      <Box className={classes.container}>
        <CoreTable
          headers={formattedHeaders}
          isLoading={isTracingLoading}
          data={formattedData}
          currentPage={currentPage}
          numberOfPages={totalPages}
          totalCount={totalCount}
          changePage={handleChangePage}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={PAGE_SIZES}
          columnWidth={{
            operation: "40%",
            status: "6%",
            event: "7%",
            eventType: "6%",
            createdAt: "15%",
          }}
        />
      </Box>
    );
  }
);

export default memo(DataTable);
