import React, { useEffect, useState } from "react";
import { TFunction } from "i18next";

import { ApiKey } from "../../../../../stores/queries/apiKey";
import { SelectOptionDef } from "../../../../../types/interfaces";
import ApiKeyContent from "../../../apikeys/ApiKeyContent";
import IntegrationContent from "../../../apikeys/IntegrationContent";
import { useStores } from "../../../../../stores/StoresProvider";
import CoreTabContainer from "../../../../core/CoreTabContainer";
import { AppSuspense } from "../../../../main/AppSuspense";
import APIkeysTabHeaderActions from "../APIkeysTabHeaderActions";

interface Props {
  t: TFunction;
}

const APIkeys: React.FC<Props> = ({ t }) => {
  const { userStore, flowSettingsStore } = useStores();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [integrationCode, setIntegrationCode] = useState<SelectOptionDef[]>([]);

  const hasApiKeys = apiKeys && apiKeys?.length > 0;

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStore]);

  const loadData = () => {
    setIsDataLoading(true);

    Promise.all([userStore.loadApiKeys(), userStore.getIntegrationsCode()])
      .then(([apiKeysData, integrationCodeData]) => {
        setIsDataLoading(false);
        setIsPageLoading(false);
        setApiKeys(apiKeysData);
        setIntegrationCode(integrationCodeData);
      })
      .catch(() => {
        setIsPageLoading(false);
        setIsDataLoading(false);
      });
  };

  return (
    <CoreTabContainer
      t={t}
      title={t(flowSettingsStore.selectedTab || "")}
      extraHeaderActions={
        <APIkeysTabHeaderActions
          loadData={loadData}
          hasApiKeys={hasApiKeys}
          isLoading={isDataLoading || isPageLoading}
        />
      }
    >
      {isPageLoading || flowSettingsStore.loadingFlowSettings ? (
        <AppSuspense />
      ) : (
        <>
          <ApiKeyContent
            apiKeys={apiKeys}
            refreshData={loadData}
            isTableLoading={isDataLoading}
          />

          <IntegrationContent integrationCode={integrationCode} />
        </>
      )}
    </CoreTabContainer>
  );
};

export default APIkeys;
