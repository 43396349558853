import React, { useState } from "react";
import { TFunction } from "i18next";
import { useGoogleLogin } from "@react-oauth/google";

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { makeStyles } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { FormData, FormField } from "../../types/interfaces";
import CoreInput from "../core/CoreInput";
import GooglePicker from "../pages/addNewFlow/tabs/assetsCore/components/GooglePicker";

interface Props {
  value: unknown;
  field: FormField;
  onChange: (value: FormData) => void;
  translation: TFunction;
  errorText?: string;
}

export const GoogleDriveRenderer: React.FC<Props> = ({
  value,
  onChange,
  field,
  translation,
  errorText,
}) => {
  const readOnly = field.props?.readOnly;
  const theme = useTheme();
  const [code, setCode] = useState("");

  const switchAccount = () => {
    setCode("");
    onChange({ [field.key]: undefined });
    handleLoginGoogle();
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: (response) => {
      setCode(response.code);
    },
    scope: (field?.customScopes || []).join(" "),
    flow: "auth-code",
  });

  const classes = makeStyles({
    mainContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginRight: "5px",
    },
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
    box: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1rem",
    },
    auto: { margin: "auto" },
    typography: { marginRight: "10px" },
    newFolderIcon: {
      display: "flex",
      flexDirection: "row",
    },
  })();

  if (value) {
    const { directory, user } = value as {
      directory: {
        id: string;
        name: string;
      };
      user: {
        email: string;
        name: string;
      };
    };

    const handleClear = () => {
      setCode("");
      onChange({ [field.key]: undefined });
    };

    const clearInput = (
      <IconButton onClick={handleClear} disabled={readOnly}>
        <HighlightOffIcon />
      </IconButton>
    );

    return (
      <Box>
        <Typography>
          {translation("directory")}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
        <CoreInput value={directory.name} readOnly endAdornment={clearInput} />

        <Box className={classes.box}>
          <Box>
            <Typography variant="body2" className={classes.auto}>
              {user.name}
            </Typography>
            <Typography variant="body2" className={classes.auto}>
              {user.email}
            </Typography>
          </Box>
          <Button onClick={switchAccount} disabled={readOnly}>
            <Typography className={classes.typography}>
              {translation("switchUser")}
            </Typography>
            <ChangeCircleIcon />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Typography>
          {translation("directory")}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
        <Box className={classes.newFolderIcon}>
          <CoreInput
            value={translation("noGoogleDriveFolder")}
            readOnly
            fullWidth
            error={!!field.errorText || !!errorText}
            errorText={field.errorText || errorText}
          />

          <GooglePicker
            code={code}
            onChange={onChange}
            field={field}
            translation={translation}
            setCode={setCode}
            iconOnClick={handleLoginGoogle}
          />
        </Box>
      </Box>
    </Box>
  );
};
