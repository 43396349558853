import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { isBoolean } from "lodash";

import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

import CoreButton from "../../core/CoreButton";
import CoreConfirmModal from "../../core/CoreConfirmModal";
import {
  AssetTemplateConfiguration,
  FormData,
} from "../../../types/interfaces";
import FlowHelper from "../../../helper/flowHelper";
import { useStores } from "../../../stores/StoresProvider";
import { SETTINGS_TYPES } from "../../../types/constants";
import { useNotification } from "../../../context/useNotification";

interface Props {
  isEditable: boolean;
  formData: FormData;
  assetConfig: AssetTemplateConfiguration;
  onCancel: () => void;
  onToggleEditMode: (state: boolean) => void;
  setFormErrors: (errors: { [key: string]: string }) => void;
}

const validateForm = (
  t: TFunction,
  formData: FormData,
  assetConfig: AssetTemplateConfiguration
) => {
  const fieldsDef = assetConfig?.parameters || [];
  const currentErrors = {} as { [key: string]: string };

  if (fieldsDef) {
    fieldsDef?.forEach((config) => {
      if (
        config?.isMandatory &&
        !isBoolean(formData?.[config?.key]) &&
        FlowHelper.checkIfValueIsEmpty(
          formData?.[config?.key],
          config?.canContainSpacesOnly
        )
      ) {
        currentErrors[config?.key] = t("validationFieldsError");
      }
    });
  }

  if (Object.keys(currentErrors)?.length > 0) {
    return currentErrors;
  }

  return null;
};

const IndexingSettingsHeaderActions: FC<Props> = ({
  isEditable,
  formData,
  assetConfig,
  onCancel,
  onToggleEditMode,
  setFormErrors,
}) => {
  const { t } = useTranslation("settings");
  const notification = useNotification();
  const { userStore } = useStores();

  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSave = () => {
    const validatedForm = validateForm(t, formData, assetConfig);
    if (validatedForm) {
      setFormErrors(validatedForm);
      return;
    }

    setFormErrors({});
    setIsModalOpen(true);
  };

  const onConfirm = () => {
    setIsUpdateInProgress(true);

    userStore
      .updateContextSettings(SETTINGS_TYPES.indexing, formData)
      .then(() => {
        notification.success(t("settingsUpdated"));
        onToggleEditMode(false);
        setIsModalOpen(false);
        setIsUpdateInProgress(false);
      })
      .catch((error: Error) => {
        notification.error(t(error?.message || "settingsUpdateFailed"));
        setIsUpdateInProgress(false);
      });
  };

  return (
    <>
      {isEditable ? (
        <>
          <CoreButton onClick={onCancel} variant="neutral" size="large">
            {t("cancel")}
          </CoreButton>

          <CoreButton
            onClick={onSave}
            variant="neutral"
            size="large"
            startIcon={<DoneIcon />}
          >
            {t("save")}
          </CoreButton>
        </>
      ) : (
        <CoreButton
          onClick={() => onToggleEditMode(true)}
          variant="neutral"
          size="large"
          startIcon={<EditIcon />}
        >
          {t("edit")}
        </CoreButton>
      )}

      <CoreConfirmModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t("settingsConfirmModalTitle")}
        cancelButtonLabel={t("cancel")}
        confirmButtonLabel={t("confirm")}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={onConfirm}
        isDisable={isUpdateInProgress}
      />
    </>
  );
};

export default IndexingSettingsHeaderActions;
