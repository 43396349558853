import React, { FC, useState, useEffect } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";

import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
  Grid,
  TableContainer,
} from "@mui/material";

import {
  ContextEvent,
  ContextEventLog,
} from "../../../../types/interfaces/contextEvent";
import { LogExpander } from "./LogExpander";
import { useStores } from "../../../../stores/StoresProvider";

const useStyles = makeStyles({
  noDataContainer: {
    padding: "20px",
  },
});

export const LogsTab: FC<{
  data: ContextEvent;
  translate: TFunction;
}> = observer(({ data, translate }) => {
  const classes = useStyles();
  const { activityLogStore } = useStores();
  const [parsedLogs, setParsedLogs] = useState<ContextEventLog[]>([]);

  useEffect(() => {
    return () => {
      // Clear prev state
      setParsedLogs([]);
      activityLogStore.setCurrentPage(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    activityLogStore.setCurrentPage(activityLogStore.currentPage + 1);
  };

  useEffect(() => {
    setParsedLogs([
      ...parsedLogs,
      ...((data?.logs || []) as unknown as ContextEventLog[]),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.logs]);

  if (!parsedLogs || parsedLogs?.length === 0) {
    return (
      <Paper className={classes.noDataContainer}>
        {translate("context_event_logs_no_data")}
      </Paper>
    );
  }

  return (
    <div style={{ overflow: "hidden" }} id="scrollableDiv">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            height={window.innerHeight - 210}
            dataLength={parsedLogs.length}
            next={fetchMoreData}
            hasMore={parsedLogs.length < data?.totalLogs}
            loader={
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress size={24} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            }
            endMessage={
              parsedLogs.length > activityLogStore.pageSize && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography>
                          {translate("context_event_logs_no_more_data")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )
            }
          >
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {parsedLogs.length > 0 &&
                    parsedLogs.map((log, index) => (
                      <LogExpander
                        key={`${index}-${log.id}`}
                        eventIdentifier={data?.eventIdentifier}
                        log={log}
                        translate={translate}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Grid>
      </Grid>
    </div>
  );
});
