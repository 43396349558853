import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import { Box, Grid, Radio, Typography, useTheme } from "@mui/material";

import { useStores } from "../../../../../../stores/StoresProvider";
import { CoreStatusTag } from "../../../../../core/CoreStatusTag";
import CoreButton from "../../../../../core/CoreButton";
import {
  BILLING_CONTRACT_TERMS,
  PREDEFINED_PLANS,
} from "../../../../../../types/interfaces/subscription";
import { ContactSalesDrawer } from "../ContactSalesDrawer";
import { SubscriptionPrice } from "../SubscriptionPrice";

const useStyles = makeStyles({
  description: {
    opacity: 0.6,
    fontSize: "12px",
    marginBottom: "12px",
  },
  planName: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleName: {
    marginRight: "5px",
  },
});

export const PlanSuggestionDisplay = observer(() => {
  const classes = useStyles();
  const theme = useTheme();
  const { subscriptionStore } = useStores();
  const { t } = useTranslation("subscription");

  const [contactForPlanId, toggleContactSalesModal] = useState<null | string>(
    null
  );

  const isAnnualPlanActive =
    subscriptionStore.activePlan?.period === BILLING_CONTRACT_TERMS.year;

  const subscriptions = useMemo(
    () =>
      subscriptionStore.subscriptionPlans?.length > 0
        ? subscriptionStore.subscriptionPlans.filter((item) =>
            isAnnualPlanActive
              ? item.period === BILLING_CONTRACT_TERMS.year
              : item.period === BILLING_CONTRACT_TERMS.month
          )
        : [],
    [isAnnualPlanActive, subscriptionStore.subscriptionPlans]
  );

  const focusedPlan = useMemo(
    () => subscriptions?.find((plan) => plan.id === contactForPlanId),
    [contactForPlanId, subscriptions]
  );

  const onPlanChange = (planId: string) => {
    subscriptionStore.setPlanToUpgradeId(planId);
    subscriptionStore.setContractTermType(
      isAnnualPlanActive
        ? BILLING_CONTRACT_TERMS.year
        : BILLING_CONTRACT_TERMS.month
    );
  };

  if (!subscriptions || subscriptions?.length === 0) {
    return <Typography>{t("subscription_preview_no_data")}</Typography>;
  }

  return (
    <>
      {subscriptions.map((plan) => {
        // Free plan must not be displayed for user (a user cannot switch to free plan, only by canceling the current subscription)
        if (plan.plan === PREDEFINED_PLANS.free) {
          return null;
        }

        const isEnterprise = !plan.subscribable;
        const isSelected = plan.id === subscriptionStore.upgradePlanId;

        return (
          <Grid
            onClick={() => !isEnterprise && onPlanChange(plan.id)}
            container
            direction="row"
            sx={{
              cursor: isEnterprise ? "default" : "pointer",
              padding: "15px 10px",
              background: isSelected ? theme.palette.primary.dark : "",
            }}
            key={plan.id}
          >
            <Grid
              item
              sx={{
                width: "45px",
              }}
            >
              {!isEnterprise && <Radio checked={isSelected} />}
            </Grid>

            <Grid item sx={{ flex: 1 }}>
              <Box className={classes.planName}>
                <Typography className={classes.titleName}>
                  {plan.name}
                </Typography>
                {subscriptionStore.activePlan?.id === plan.id && (
                  <CoreStatusTag
                    label={t("subscription_upgrade_current_plan_tag")}
                    type={"done"}
                  />
                )}
              </Box>

              <Typography className={classes.description}>
                {plan.description}
              </Typography>
            </Grid>

            <Grid item>
              {isEnterprise ? (
                <CoreButton onClick={() => toggleContactSalesModal(plan.id)}>
                  {t("subscription_upgrade_contact_sales_action")}
                </CoreButton>
              ) : (
                <SubscriptionPrice
                  price={plan.price}
                  discount={plan?.discount}
                  currency={plan.currency}
                  styleProps={{ color: theme.palette.highlight.main }}
                />
              )}
            </Grid>
          </Grid>
        );
      })}

      <ContactSalesDrawer
        planId={contactForPlanId || ""}
        planName={focusedPlan?.name || ""}
        isOpen={!!contactForPlanId}
        onClose={() => toggleContactSalesModal(null)}
      />
    </>
  );
});
