import React, { FC, useMemo } from "react";
import { TFunction } from "i18next";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CoreButton from "../../../core/CoreButton";
import CoreCheckbox from "../../../core/CoreCheckbox";
import { AppSuspense } from "../../../main/AppSuspense";
import { DOCUMENT_STATUS } from "../../../../types/constants";
import CoreNotificationBox from "../../../core/CoreNotificationBox";
import { NotificationTypes } from "../../../../context/useNotification";

interface Props {
  t: TFunction;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  loadingRerun: boolean;
  loadingData: boolean;
  data: { [key: string]: number };
  checkedStatuses: { status: string; isChecked: boolean }[];
  setCheckedStatuses: (
    checkedStatuses: { status: string; isChecked: boolean }[]
  ) => void;
}

const RerunDocumentsDialog: FC<Props> = ({
  t,
  open,
  handleClose,
  handleConfirm,
  loadingRerun,
  loadingData,
  data,
  checkedStatuses,
  setCheckedStatuses,
}) => {
  const theme = useTheme();
  const classes = makeStyles({
    dialog: {
      backgroundImage: "unset",
      backgroundColor: theme.palette?.background.paper,
      width: "600px",
    },
    spacing: {
      marginBottom: "8px",
    },
    widthElement: {
      width: "100%",
      marginTop: "-10px",
    },
    subtitle: {
      marginLeft: "25px",
      marginTop: "20px",
      opacity: "0.7",
    },
    checkBox: {
      padding: "0",
    },
    appSuspense: {
      margin: "50px",
    },
    noDataToDisplay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "25px",
      marginBottom: "30px",
    },
    divider: {
      marginRight: "1rem",
      marginLeft: "1rem",
      border: `1px solid ${alpha(theme.palette.divider, 0.5)}`,
    },
    typography: {
      marginTop: "30px",
    },
    textStyle: {
      paddingTop: "10px",
    },
  })();

  const onChange = (status: string) => {
    const newStatuses = checkedStatuses.map((item) => {
      if (item.status === status) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setCheckedStatuses(newStatuses);
  };

  const dataIsValid = useMemo(() => {
    return (
      Object.keys(data)?.length === 0 ||
      (Object.keys(data)?.length === 1 &&
        Object.prototype.hasOwnProperty.call(data, DOCUMENT_STATUS.running))
    );
  }, [data]);

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <DialogTitle>{t("rerunDocuments")}</DialogTitle>
      <Divider className={classes.divider} />

      {!dataIsValid && (
        <Typography className={classes.subtitle}>
          {t("rerun_documents_subtitle")}
        </Typography>
      )}

      {loadingData ? (
        <Box className={classes.appSuspense}>
          <AppSuspense />
        </Box>
      ) : (
        <>
          <DialogContent>
            {dataIsValid ? (
              <>
                <CoreNotificationBox
                  description={t("noSimilarDocumentsFound")}
                  type={NotificationTypes.info}
                />
                <Typography className={classes.textStyle}>
                  {t("rerunJustSelectedDocs")}
                </Typography>
              </>
            ) : (
              <Box className={classes.spacing}>
                <List className={classes.widthElement}>
                  {Object.keys(data)
                    .filter((status) => status !== DOCUMENT_STATUS.running)
                    .map((status, index) => {
                      const documentsCount =
                        data[status] > 1
                          ? `${t(status)} (${data[status]} documents)`
                          : `${t(status)} (${data[status]} document)`;
                      return (
                        <ListItemButton
                          key={index}
                          onClick={() => {
                            onChange(status);
                          }}
                          disabled={loadingRerun}
                        >
                          <CoreCheckbox
                            className={classes.checkBox}
                            checked={
                              checkedStatuses?.find(
                                (item) => item.status === status
                              )?.isChecked
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={() => onChange(status)}
                            disabled={loadingRerun}
                          />
                          <Typography>{documentsCount}</Typography>
                        </ListItemButton>
                      );
                    })}
                </List>
                <Typography className={classes.typography}>
                  {t("rerun_info")}
                </Typography>
              </Box>
            )}
          </DialogContent>
        </>
      )}

      <DialogActions>
        <CoreButton
          onClick={handleClose}
          variant="neutral"
          disabled={loadingRerun || loadingData}
        >
          {t("cancel")}
        </CoreButton>
        <CoreButton
          disabled={loadingRerun || loadingData}
          autoFocus
          isLoading={loadingRerun}
          onClick={handleConfirm}
          variant="contained"
        >
          {t("confirm")}
        </CoreButton>
      </DialogActions>
    </Dialog>
  );
};

export default RerunDocumentsDialog;
