import React, { FC } from "react";
import classNames from "classnames";

import { makeStyles } from "@mui/styles";
import { alpha, useTheme, Box } from "@mui/material";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const CoreCard: FC<Props> = ({ children, className }) => {
  const theme = useTheme();

  const classes = makeStyles({
    container: {
      padding: "20px",
      border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      borderRadius: "2px",
    },
  })();

  const boxClasses = classNames([
    classes.container,
    ...(className ? [className] : []),
  ]);

  return <Box className={boxClasses}>{children}</Box>;
};
