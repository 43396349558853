import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiDrawer = (theme: ThemeOptions): Components["MuiDrawer"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      paper: {
        backgroundColor: palette.secondary.main,
        borderRight: "none",
      },
    },
  };
};
