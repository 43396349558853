import React, { FC, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";

import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Replay } from "@mui/icons-material";
import ConstructionIcon from "@mui/icons-material/Construction";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";

import { useStores } from "../../../../stores/StoresProvider";
import CoreConfirmModal from "../../../core/CoreConfirmModal";
import MoveDocumentsDialog from "./MoveDocumentsDialog";
import CoreTooltip from "../../../core/CoreTooltip";
import {
  DOCUMENT_STATUS,
  FILTER_PROPS,
  RERUN_STATUSES_LIMIT,
} from "../../../../types/constants";
import { DocumentsFilterProps } from "../../../../types/interfaces";
import { useNotification } from "../../../../context/useNotification";
import RerunDocumentsAction from "./RerunDocumentsAction";

interface Props {
  t: TFunction;
  isMoveDialogOpen: boolean;
  setIsMoveDialogOpen: (value: boolean) => void;
  handleDelete: () => void;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: (value: boolean) => void;
  handleMove: (flowId: string) => void;
  isMovingDocs: boolean;
  isDeleteLoading?: boolean;
  setFilters: (
    updatedFilters: DocumentsFilterProps,
    resetFilters?: boolean
  ) => void;
  refreshData: () => void;
}

const anchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

const useStyles = makeStyles({
  box: {
    padding: "5px",
    display: "flex",
    flexDirection: "row",
  },
  alert: {
    minWidth: "250px",
    "& .MuiPaper-root": {
      "& .MuiAlert-message": {
        width: "100%",
      },
      width: "100%",
    },
  },
  boxAlert: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divider: {
    height: "40px",
  },
});

const DocumentsActions: FC<Props> = observer(
  ({
    t,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    handleDelete,
    isMoveDialogOpen,
    setIsMoveDialogOpen,
    handleMove,
    isMovingDocs,
    isDeleteLoading,
    setFilters,
    refreshData,
  }) => {
    const classes = useStyles();
    const { documentStore, userStore, flowStore } = useStores();
    const { id } = useParams<{ id: string }>();
    const client = useApolloClient();
    const notification = useNotification();
    const [isRerunDialogOpen, setIsRerunDialogOpen] = useState(false);
    const [isRetryLoading, setIsRetryLoading] = useState(false);
    const [isSimilarTemplateLoading, setIsSimilarTemplateLoading] =
      useState(false);
    const [isRetryModalOpen, setIsRetryModalOpen] = useState(false);

    const retrySelected = () => {
      setIsRetryLoading(true);
      documentStore
        .retryDocuments(
          client,
          DOCUMENT_STATUS.error,
          documentStore.selectedDocuments.map((doc) => doc.identifier),
          null
        )
        .then(() => {
          documentStore.uncheckAllDocuments();
          notification.success(t("retrySelectedSuccess"));
          setIsRetryLoading(false);
          setIsRetryModalOpen(false);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "retryAllError"));
        });
    };

    const handleShowSimilarDocuments = (similarTemplatesIds: string[]) => {
      const customMetadata = {
        field: "template.id",
        operator: "IN",
        values: similarTemplatesIds,
      };

      setFilters(
        {
          ...flowStore?.filters?.flowFilters,
          [FILTER_PROPS.metadata]: [
            {
              ...customMetadata,
              type: "metadata",
            },
          ] as unknown as DocumentsFilterProps[],
        },
        true
      );
    };

    const getSimilarTemplates = () => {
      setIsSimilarTemplateLoading(true);

      documentStore
        .getSimilarTemplates(
          client,
          id,
          documentStore.selectedDocuments.map((doc) => doc.identifier)
        )
        .then((res) => {
          const similarTemplatesIds = res.data?.getSimilarTemplates
            ?.identifiers as string[];
          if (similarTemplatesIds?.length === 0) {
            notification.info(t("noSimilarTemplates"));
            return;
          }
          handleShowSimilarDocuments(similarTemplatesIds);
          documentStore.uncheckAllDocuments();
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "getSimilarTemplateError"));
        })
        .finally(() => {
          setIsSimilarTemplateLoading(false);
        });
    };

    const permissions = useMemo(
      () => ({
        canMoveDocuments:
          userStore.currentUserPermissions?.can("importDocuments", "flows") &&
          userStore.currentUserPermissions?.can("deleteDocuments", "flows"),
        canDeleteDocuments: userStore.currentUserPermissions?.can(
          "deleteDocuments",
          "flows"
        ),
        canRetryDocuments: userStore.currentUserPermissions?.can(
          "updateDocuments",
          "flows"
        ),
        canGetSimilarTemplate: userStore.currentUserPermissions?.can(
          "read",
          "flows"
        ),
      }),
      [userStore.currentUserPermissions]
    );

    return (
      <>
        <Snackbar
          open={
            documentStore.selectedDocuments.length > 0 &&
            !isMoveDialogOpen &&
            !isDeleteDialogOpen &&
            !isRerunDialogOpen
          }
          className={classes.alert}
          anchorOrigin={anchorOrigin}
        >
          <Alert icon={false}>
            <Box className={classes.boxAlert}>
              <Typography>
                {documentStore.selectedDocuments.length === 1
                  ? t("selectedDocument", {
                      number: documentStore.selectedDocuments.length,
                    })
                  : t("selectedDocuments", {
                      number: documentStore.selectedDocuments.length,
                    })}
              </Typography>

              <Box className={classes.box}>
                {permissions.canMoveDocuments &&
                  documentStore.selectedDocuments.every(
                    (doc) => doc.status.type !== DOCUMENT_STATUS.running
                  ) && (
                    <>
                      <CoreTooltip title={t("move_documents")} placement="top">
                        <IconButton onClick={() => setIsMoveDialogOpen(true)}>
                          <DriveFileMoveIcon />
                        </IconButton>
                      </CoreTooltip>

                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                      />
                    </>
                  )}
                {permissions.canDeleteDocuments && (
                  <>
                    <CoreTooltip title={t("deleteDocuments")} placement="top">
                      <IconButton onClick={() => setIsDeleteDialogOpen(true)}>
                        <DeleteForeverOutlinedIcon />
                      </IconButton>
                    </CoreTooltip>

                    {documentStore.selectedDocuments.every(
                      (doc) => doc.status.type !== DOCUMENT_STATUS.running
                    ) && (
                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                      />
                    )}
                  </>
                )}
                {permissions.canRetryDocuments &&
                  documentStore.selectedDocuments.every(
                    (doc) => doc.status.type === DOCUMENT_STATUS.error
                  ) && (
                    <>
                      <CoreTooltip title={t("retryDocuments")} placement="top">
                        <IconButton onClick={() => setIsRetryModalOpen(true)}>
                          <Replay />
                        </IconButton>
                      </CoreTooltip>

                      <Divider
                        orientation="vertical"
                        className={classes.divider}
                      />
                    </>
                  )}
                {permissions.canRetryDocuments &&
                  !documentStore.selectedDocuments.some((doc) =>
                    RERUN_STATUSES_LIMIT.includes(
                      doc.status.type as unknown as DOCUMENT_STATUS
                    )
                  ) && (
                    <CoreTooltip title={t("rerunDocuments")} placement="top">
                      <IconButton
                        onClick={() => {
                          setIsRerunDialogOpen(true);
                        }}
                      >
                        <ConstructionIcon />
                      </IconButton>
                    </CoreTooltip>
                  )}
                {permissions.canGetSimilarTemplate && id && (
                  <>
                    <Divider
                      orientation="vertical"
                      className={classes.divider}
                    />
                    {isSimilarTemplateLoading ? (
                      <IconButton>
                        <CircularProgress size={24} />
                      </IconButton>
                    ) : (
                      <CoreTooltip
                        title={t("viewSimilarDocuments")}
                        placement="top"
                      >
                        <IconButton onClick={getSimilarTemplates}>
                          <PlagiarismOutlinedIcon />
                        </IconButton>
                      </CoreTooltip>
                    )}
                  </>
                )}
              </Box>
              <CoreTooltip title={t("clearSelections")} placement="top">
                <IconButton onClick={() => documentStore.uncheckAllDocuments()}>
                  <CloseIcon />
                </IconButton>
              </CoreTooltip>
            </Box>
          </Alert>
        </Snackbar>

        <CoreConfirmModal
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          title={
            documentStore.selectedDocuments.length > 1
              ? t("delete_documents_title")
              : t("delete_document_title")
          }
          subtitle={
            documentStore.selectedDocuments.length > 1
              ? t("delete_documents_subtitle")
              : t("delete_document_subtitle")
          }
          cancelButtonLabel={t("deleteModal_cancelButton")}
          confirmButtonLabel={t("deleteModal_confirmButton")}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
          isDisable={isDeleteLoading}
        />

        <RerunDocumentsAction
          t={t}
          documents={documentStore.selectedDocuments.map(
            (doc) => doc.identifier
          )}
          isRerunDialogOpen={isRerunDialogOpen}
          setIsRerunDialogOpen={setIsRerunDialogOpen}
          successfullyRerun={() => {
            documentStore.uncheckAllDocuments();
          }}
          refreshData={refreshData}
        />

        <MoveDocumentsDialog
          open={isMoveDialogOpen}
          t={t}
          handleClose={() => setIsMoveDialogOpen(false)}
          handleMove={handleMove}
          loading={isMovingDocs}
        />

        <CoreConfirmModal
          open={isRetryModalOpen}
          onClose={() => {
            setIsRetryModalOpen(false);
          }}
          title={t("retryDocuments")}
          subtitle={t("retryDocumentsSubtitle")}
          cancelButtonLabel={t("cancel")}
          confirmButtonLabel={t("confirm")}
          onCancel={() => setIsRetryModalOpen(false)}
          onConfirm={retrySelected}
          isDisable={isRetryLoading}
        />
      </>
    );
  }
);

export default DocumentsActions;
