import React, { useEffect, useMemo } from "react";
import { TFunction } from "i18next";

import { Schema } from "json-schema-faker";

import {
  IconButton,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  alpha,
  useTheme,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import JsonSchemaConverter from "../../../helper/jsonSchemaConverter";

import EditorJson from "../../pages/connections/components/EditorJson";
import CoreNotificationBox from "../../core/CoreNotificationBox";
import { NotificationTypes } from "../../../context/useNotification";
import CoreButton from "../../core/CoreButton";
import { JSON_EDITOR_TYPES } from "../../../types/constants";

interface Props {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  type: string;
  editorValue: string;
  setEditorValue: (value: string) => void;
  setSchemaValue: (value: Schema) => void;
  showError: boolean;
  setShowError: (value: boolean) => void;
}

const GenerateSchemaModal: React.FC<Props> = ({
  t,
  isOpen,
  onClose,
  type,
  editorValue,
  setEditorValue,
  setSchemaValue,
  showError,
  setShowError,
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    dialogPaper: {
      "& .MuiDialog-paper": {
        padding: 0,
        maxWidth: "800px",
      },
    },
    content: {
      "&.MuiDialogContent-root": {
        alignSelf: "stretch",
      },
      padding: "0 40px 20px 40px",
    },
    iconButton: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    divider: {
      marginRight: "1rem",
      marginLeft: "1rem",
      border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
    },
    editorBox: {
      paddingTop: "20px",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    copyActionBox: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonsBox: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
      paddingTop: "10px",
    },
  });

  const classes = useStyles();

  const handleClickGenerate = () => {
    if (!JsonSchemaConverter.isDataValid(editorValue, type)) {
      setShowError(true);
    } else {
      const schemaValue = JsonSchemaConverter.convertToSchema(
        editorValue
      ) as Schema;

      setSchemaValue(JsonSchemaConverter.formatSchema(schemaValue));
    }
  };

  useEffect(() => {
    if (type === JSON_EDITOR_TYPES.list) {
      setEditorValue("[]");
    }

    if (type === JSON_EDITOR_TYPES.object) {
      setEditorValue("{}");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isOpen]);

  const handleEditorChange = (newValue: string) => {
    if (showError) {
      setShowError(false);
    }

    setEditorValue(newValue);
  };

  const handleErrorDescription = useMemo(() => {
    if (type === JSON_EDITOR_TYPES.list) {
      return t("schemaArrayError");
    }

    return t("schemaObjectError");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, type]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClose}
      className={classes.dialogPaper}
      BackdropProps={{
        style: { backgroundColor: "transparent" },
      }}
    >
      <DialogTitle>
        <Typography>{t("generateSchemaFromData")}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.iconButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider className={classes.divider} />

      <DialogContent className={classes.content}>
        <Box className={classes.editorBox}>
          <Typography>{t("inputYourData")}</Typography>

          <EditorJson value={editorValue} onChange={handleEditorChange} />
        </Box>

        {showError && (
          <CoreNotificationBox
            type={NotificationTypes.error}
            description={handleErrorDescription}
          />
        )}

        <Box className={classes.buttonsBox}>
          <CoreButton onClick={onClose}>{t("cancel")}</CoreButton>
          <CoreButton variant="contained" onClick={handleClickGenerate}>
            {t("checkAndGenerate")}
          </CoreButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateSchemaModal;
