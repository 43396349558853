import React from "react";
import { TFunction } from "i18next";

import CoreDrawer from "../../core/CoreDrawer";
import EditPermissions from "./EditPermissions";

interface EditMemberProps {
  t: TFunction;
  isOpen: boolean;
  onClose: () => void;
  userId: number | null;
}

export const EditMember: React.FC<EditMemberProps> = ({
  isOpen,
  t,
  onClose,
  userId,
}) => {
  return (
    <CoreDrawer
      isOpen={isOpen}
      onClose={onClose}
      title={t("changePermissions")}
    >
      <EditPermissions onClose={onClose} t={t} userId={userId} />
    </CoreDrawer>
  );
};
