import React, { FC, useMemo } from "react";
import { observer } from "mobx-react";

import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import LayersClearIcon from "@mui/icons-material/LayersClear";

import { FlowNode, HeaderActions } from "../../../../../types/interfaces";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../../../stores/StoresProvider";
import {
  DIAGRAM_NODE_DELETE_MODES,
  NODE_TYPES,
} from "../../../../../types/constants";
import { useReactFlow } from "reactflow";
import { useNotification } from "../../../../../context/useNotification";

interface Props {
  identifier: string;
  hasTarget: boolean;
  isStartNode?: boolean;
}

const DiagramNodeDeleteActions: FC<Props> = observer(
  ({ identifier, hasTarget, isStartNode = false }) => {
    const { t } = useTranslation("addFlow");
    const theme = useTheme();
    const reactFlow = useReactFlow();
    const notification = useNotification();
    const { flowSettingsStore } = useStores();

    const classes = makeStyles({
      deleteIcon: {
        fontSize: "8px",
        color: theme.palette.error.main,
      },
      deleteButton: {
        border: `1px solid ${theme.palette.neutral.dark}`,
        width: "4px",
        height: "4px",
        borderTop: "none",
        borderRight: "none",
        borderRadius: "0",
      },
      menuList: {
        "& ul": {
          padding: 0,
          boxShadow: "11px 16px 7px -10px rgba(0,0,0,0.43)",
        },
      },
      menuItemAction: {
        margin: "3px",
        marginLeft: "5px",
        textTransform: "none",
        transition: "none",
        display: "flex",
        alignItems: "stretch",
        color: "inherit",
      },
      menuItem: {
        background: theme.palette.background.paper,
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-start",
        "&:hover": {
          color: `${theme.palette.highlight.main} !important`,
        },
        "&.Mui-disabled": {
          pointerEvents: "auto",
        },
      },
      iconStyle: {
        color: "inherit",
      },
    })();

    const open = Boolean(flowSettingsStore.nodeAnchor?.anchor);

    const diagramNodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const isGroupWithChildren = useMemo(() => {
      const node = diagramNodes?.find((node) => node.identifier === identifier);

      const isGroup = node?.type === NODE_TYPES.group;

      const hasChildren = diagramNodes?.some(
        (node) => node?.parentId === identifier
      );

      return isGroup && hasChildren;
    }, [identifier, diagramNodes]);

    const onDeleteHierarchy = () => {
      if (isStartNode) {
        flowSettingsStore.deleteHierarchyFromStartNode(reactFlow, identifier);
        return;
      }

      flowSettingsStore.deleteDiagramNode(
        reactFlow,
        identifier,
        DIAGRAM_NODE_DELETE_MODES.hierarchy,
        notification,
        t
      );
    };

    const onDeleteNode = (deleteMode = DIAGRAM_NODE_DELETE_MODES.node) => {
      flowSettingsStore.deleteDiagramNode(
        reactFlow,
        identifier,
        deleteMode,
        notification,
        t
      );
    };

    const deleteOptions: HeaderActions[] = [
      ...(hasTarget
        ? [
            {
              label: t("deleteHierarchy"),
              tooltip: t("deleteHierarchy"),
              onClick: onDeleteHierarchy,
              startIcon: isStartNode ? <LayersClearIcon /> : <DeleteIcon />,
            },
          ]
        : []),
      ...(isStartNode
        ? []
        : [
            {
              label: t("deleteNode"),
              tooltip: t("deleteNode"),
              onClick: onDeleteNode,
              startIcon: <DeleteIcon />,
            },
            ...(isGroupWithChildren
              ? [
                  {
                    label: t("deleteChildren"),
                    tooltip: t("deleteChildren"),
                    onClick: () =>
                      onDeleteNode(DIAGRAM_NODE_DELETE_MODES.groupChildren),
                    startIcon: <DeleteIcon />,
                  },
                ]
              : []),
          ]),
    ];

    const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      flowSettingsStore.setNodeAnchor({
        anchor: event.currentTarget,
        id: identifier,
      });
    };

    const onDeleteOptionClick = (headerAction: HeaderActions) => {
      if (headerAction.onClick) {
        flowSettingsStore.setNodeAnchor(null);
        headerAction.onClick();
      }
    };

    return (
      <>
        {isStartNode && !hasTarget ? null : (
          <IconButton
            className={classes.deleteButton}
            onClick={(event) => handleClickDelete(event)}
          >
            {isStartNode ? (
              <LayersClearIcon className={classes.deleteIcon} />
            ) : (
              <DeleteIcon className={classes.deleteIcon} />
            )}
          </IconButton>
        )}

        {deleteOptions && deleteOptions?.length > 0 ? (
          <Menu
            anchorEl={flowSettingsStore.nodeAnchor?.anchor}
            open={open && identifier === flowSettingsStore.nodeAnchor?.id}
            onClose={() => {
              flowSettingsStore.setNodeAnchor(null);
            }}
            className={classes.menuList}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MenuList dense>
              {deleteOptions?.map((deleteOption, index) => (
                <MenuItem
                  key={`submenu-${deleteOption.label}-${index}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteOptionClick(deleteOption);
                  }}
                  disabled={deleteOption.disabled}
                  className={classes.menuItem}
                >
                  {deleteOption.startIcon && (
                    <ListItemIcon className={classes.iconStyle}>
                      {deleteOption.startIcon}
                    </ListItemIcon>
                  )}

                  <Typography className={classes.menuItemAction}>
                    {deleteOption.label}
                  </Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <></>
        )}
      </>
    );
  }
);

export default DiagramNodeDeleteActions;
