import React, { FC, useContext } from "react";
import { usePrevLocation } from "./CoreHistory";

interface HistoryContextProps {
  goBack: (back: { pagesBack?: number; redirectPage?: string }) => void;
}

const HistoryContext = React.createContext<HistoryContextProps | null>(null);

interface ProviderProps {
  children: React.ReactNode;
}

export const HistoryProvider: FC<ProviderProps> = ({ children }) => {
  const historyProps = usePrevLocation();

  return (
    <HistoryContext.Provider value={{ ...historyProps }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryContext = (): HistoryContextProps | null =>
  useContext(HistoryContext);
