import React, { FC } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

import { ExtendedFile } from "../../../../types/interfaces";
import FileItem from "./FileItem";

interface Props {
  t: TFunction;
  files: ExtendedFile[];
  isDisabled: boolean;
  onUpdate: (updatedFiles: ExtendedFile[]) => void;
}

const UploadedFilesDisplay: FC<Props> = ({
  t,
  files,
  isDisabled,
  onUpdate,
}) => {
  const useStyles = makeStyles({
    documentsBox: {
      overflow: "auto",
      height: "100%",
      flex: "auto",
      maxWidth: "60%",
      "@media screen and (max-width:1200px)": {
        maxWidth: "100%",
        height: "100%",
      },
    },
    docName: {
      overflowWrap: "break-word",
      width: "400px",
      "@media screen and (max-width:1200px)": {
        width: "500px",
      },
      paddingLeft: "5px",
    },
    status: {
      width: "50px",
      overflow: "hidden",
    },
    buttons: {
      justifyContent: "center",
      display: "flex",
      width: "100px",
      flexDirection: "row",
      padding: "5px 0",
    },
    cell: {
      padding: "5px 0",
    },
  });

  const classes = useStyles();

  const removeItem = (index: number) => {
    onUpdate(files.filter((el, currentInd) => index !== currentInd));
  };

  return (
    <Box className={classes.documentsBox}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>
              <Typography className={classes.status}>
                {t("docStatusTitle")}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography className={classes.docName}>
                {t("docNameTitle")}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography className={classes.buttons}>
                {files.length === 1
                  ? `1 ${t("file") || ""}`
                  : `${files.length} ${t("files") || ""}`}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {files.map((item, index) => {
            return (
              <FileItem
                t={t}
                key={`${item.name}-${index}`}
                file={item}
                isDisabled={isDisabled}
                index={index}
                removeItem={removeItem}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default UploadedFilesDisplay;
