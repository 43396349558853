import React, { CSSProperties } from "react";

import AddIcon from "@mui/icons-material/Add";
import { IconButton, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface NodeHandleButtonProps {
  onClick?: () => void;
  extraStyles?: CSSProperties;
}

const DiagramNodeHandleButton: React.FC<NodeHandleButtonProps> = ({
  onClick,
  extraStyles = {},
}) => {
  const theme = useTheme();
  const classes = makeStyles({
    addIconButton: {
      borderRadius: "25px",
      border: `1px solid ${theme.palette.neutral.dark}`,
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
      padding: "0",
      width: "14px",
      height: "14px",
      left: "-4px",
      top: "-2px",
      ...extraStyles,
    },
    addIcon: {
      fontSize: "14px",
      color: theme.palette.highlight.main,
      position: "absolute",
    },
  })();

  return (
    <IconButton className={classes.addIconButton} onClick={onClick}>
      <AddIcon className={classes.addIcon} />
    </IconButton>
  );
};

export default DiagramNodeHandleButton;
