import React, { useEffect } from "react";
import { TFunction } from "i18next";

import { Box, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  FormData,
  FormField,
  SelectOptionDef,
  Variable,
} from "../../types/interfaces";
import FileNameAndDynamicExportAutocomplete from "./FileNameAndDynamicExportAutocomplete";
import { DYNAMIC_FIELDS } from "../../types/constants";

interface Props {
  field: FormField;
  value: unknown;
  onChange: (value: FormData) => void;
  formData: FormData;
  translation: TFunction;
  variables?: Variable[];
  disableDiagramField?: boolean;
}

const DynamicPathRenderer: React.FC<Props> = ({
  field,
  value,
  onChange,
  formData,
  translation,
  variables,
  disableDiagramField = false,
}) => {
  const [options, setOptions] = React.useState<SelectOptionDef[]>([]);

  useEffect(() => {
    const variablesOptions = (variables ?? [])?.flatMap((variable) => {
      return {
        label: variable.name,
        key: variable.key,
        type: DYNAMIC_FIELDS.flowFields,
      };
    });

    let options = [...variablesOptions, ...(field.options ?? [])];

    options = options.sort((a, b) =>
      a?.type?.localeCompare(b?.type as string) ? 1 : -1
    );

    setOptions(options as SelectOptionDef[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const theme = useTheme();

  const classes = makeStyles({
    asterisk: {
      color: theme.palette.error.main,
      marginLeft: "5px",
    },
  })();

  return (
    <Box>
      {field.props?.title && (
        <Typography variant="subtitle2" color={theme.palette?.text.primary}>
          {field.props?.title}
          {field.isMandatory && <span className={classes.asterisk}>*</span>}
        </Typography>
      )}

      <FileNameAndDynamicExportAutocomplete
        field={field}
        value={value}
        onChange={onChange}
        formData={formData}
        translation={translation}
        options={options}
        disableDiagramField={disableDiagramField}
      />
    </Box>
  );
};

export default DynamicPathRenderer;
