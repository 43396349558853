import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import _isEmpty from "lodash/isEmpty";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Divider,
  Typography,
  Alert,
  useTheme,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";

import CoreButton from "../../../../../core/CoreButton";
import { AppSuspense } from "../../../../../main/AppSuspense";
import { useStores } from "../../../../../../stores/StoresProvider";

import { useNotification } from "../../../../../../context/useNotification";
import {
  AssetConfiguration,
  PreviewData,
} from "../../../../../../types/interfaces";
import { THEME_MODES } from "../../../../../../types/constants";

interface Props {
  open: AssetConfiguration | undefined;
  setOpenDialog: (value: AssetConfiguration | undefined) => void;
}

const FILES_DATA = {
  files: [],
  documentCount: 0,
  incomplete: false,
};

const PreviewDialog: React.FC<Props> = ({ open, setOpenDialog }) => {
  const [data, setData] = useState<Partial<PreviewData>>(FILES_DATA);
  const [isLoading, setIsLoading] = useState(false);
  const { t, ready } = useTranslation("drawer");

  const { storageStore, mainStore } = useStores();
  const apolloClient = useApolloClient();
  const notification = useNotification();
  const currentTheme = mainStore.currentTheme;
  const theme = useTheme();

  const useStyles = makeStyles({
    dialog: {
      backgroundImage: "unset",
      backgroundColor: theme.palette?.background.paper,
      maxWidth: "fit-content",
      maxHeight: "100%",
    },
    fileContainer: {
      marginBottom: "8px",
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
      marginBottom: "20px",
      marginTop: "20px",
      width: "100%",
    },
    contentText: {
      textDecoration: "underline",
      margin: "0 3px 0 3px",
    },
    displayRow: {
      display: "flex",
      flexDirection: "row",
    },
    warning: {
      marginTop: "16px",
      backgroundColor: theme.palette?.neutral.dark,
      width: "50%",
    },
    spacing: {
      marginBottom: "8px",
    },
    loaderStyle: {
      color:
        currentTheme === THEME_MODES.light ? theme.palette.neutral.main : "",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setData(FILES_DATA);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);

      storageStore
        .getPreview(apolloClient, open)
        .then((previewData) => {
          if (previewData) {
            setData(previewData);
          }
        })
        .catch((error: Error) =>
          notification.error(error?.message || "No data loaded!")
        )
        .finally(() => {
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { files, documentCount, incomplete } = data;
  const tenFiles = files?.slice(0, 10);

  return (
    <Dialog
      open={!!open}
      fullWidth
      classes={{ paper: classes.dialog }}
      onClose={() => setOpenDialog(undefined)}
    >
      {!ready ? (
        <AppSuspense />
      ) : (
        <>
          <DialogTitle>{t("drawerDialogTitle")}</DialogTitle>
          <DialogContent>
            <div className={classes.spacing}>
              <div className={classes.displayRow}>
                <Typography variant="body1">{t("dialogContentOne")}</Typography>
                <Typography variant="body1" className={classes.contentText}>
                  {files?.length ?? "0"}
                </Typography>
                <Typography variant="body1">{t("dialogContentTwo")}</Typography>
                <Typography variant="body1" className={classes.contentText}>
                  {documentCount ?? "0"}
                </Typography>
              </div>

              <Alert severity="info" className={classes.info}>
                {t("alertInfo")}
              </Alert>
            </div>
            <div className={classes.displayRow}>
              <FilterListIcon />
              <Typography variant="body1">{t("dialogData")}</Typography>
            </div>
            <Divider style={{ marginBottom: "8px" }} />

            {tenFiles?.map((item, index) => {
              return (
                <div key={index} className={classes.fileContainer}>
                  <Typography
                    variant="body1"
                    color={theme?.palette.highlight.main}
                  >
                    {item}
                  </Typography>
                  <Divider />
                </div>
              );
            })}
            {_isEmpty(tenFiles) && !isLoading && (
              <Typography variant="body1">{t("noData")}</Typography>
            )}
            {isLoading && <CircularProgress className={classes.loaderStyle} />}

            {incomplete && (
              <Alert severity="warning" className={classes.warning}>
                {t("incompleted")}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <CoreButton onClick={() => setOpenDialog(undefined)}>
              {t("drawer_button")}
            </CoreButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default PreviewDialog;
