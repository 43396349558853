import React from "react";

import { makeStyles } from "@mui/styles";

import { Box, Typography, useTheme } from "@mui/material";
import DiagramNodeIcon from "../../../flow/flow-settings/diagramBuilder/DiagramNodeIcon";

interface Props {
  name: string;
  description: string;
  nodeKey: string;
}

const AssetInfoBox: React.FC<Props> = ({ name, description, nodeKey }) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    mainBox: {
      display: "flex",
      flexDirection: "row",
      padding: "10px 5px",
    },
    name: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    description: {
      fontSize: "14px",
      opacity: "0.6",
    },
    info: {
      display: "flex",
      flexDirection: "column",
    },
    icon: {
      fontSize: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 10px 0 0",
    },
    iconTextBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      background: theme.palette.primary.dark,
      padding: "10px",
    },
    iconStyle: {
      fontSize: "25px",
    },
    svgIconStyle: {
      width: "25px",
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.icon}>
        <DiagramNodeIcon
          nodeKey={nodeKey}
          textBoxStyle={classes.iconTextBox}
          iconStyle={classes.iconStyle}
          svgIconStyle={classes.svgIconStyle}
        />
      </Box>

      <Box className={classes.info}>
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default AssetInfoBox;
