import { Components, ThemeOptions } from "@mui/material";

import { ThemePalette } from "../../types/interfaces";

export const initMuiMenuItem = (
  theme: ThemeOptions
): Components["MuiMenuItem"] => {
  const palette = theme.palette as ThemePalette;

  return {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          backgroundColor: palette.background.default,
          color: palette.text.secondary,
          "&.Mui-focusVisible": { background: palette.secondary.main },
          "&:hover": { backgroundColor: palette.secondary.main },
        },
      },
    },
  };
};
