import React, { FC } from "react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

import SearchFilter from "../filters/SearchFilter";
import FiltersDisplay from "../filters/FiltersDisplay";

interface Props {
  t: TFunction;
  textSearch: string;
  updateTextSearch: (newSearch: string) => void;
}

const useStyles = makeStyles({
  mainContainer: {
    margin: "5px 0 0 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  searchContainer: {
    margin: "5px 0 0 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  filtersContainer: {
    marginTop: "10px",
    gap: "10px",
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "5px",
  },
});

export const DataCategoryFilterBox: FC<Props> = ({
  t,
  textSearch,
  updateTextSearch,
}) => {
  const classes = useStyles();

  const setSearchFilters = (value: string[]) => {
    updateTextSearch(value[1]);
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.searchContainer}>
        <SearchFilter
          name={[textSearch]}
          setFilters={(value) => setSearchFilters(value)}
          placeholder={t("searchDataPlaceholder")}
        />
      </Box>

      <Box className={classes.filtersContainer}>
        <FiltersDisplay
          t={t}
          filters={{ name: [textSearch] }}
          setFilters={(key: string, value: unknown) =>
            setSearchFilters(value as string[])
          }
        />
      </Box>
    </Box>
  );
};
