import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { useTheme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import EditIcon from "@mui/icons-material/Edit";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CoreTooltip from "../../core/CoreTooltip";

import CoreTable from "../../core/CoreTable";
import {
  FIELD_TYPE,
  DEFAULT_TABLE_ROWS,
  SORT_DIRECTION,
} from "../../../types/constants";
import {
  TableContentDef,
  TableHeaderDef,
  FormField,
  HeaderActions,
} from "../../../types/interfaces";
import { useStores } from "../../../stores/StoresProvider";
import { useNotification } from "../../../context/useNotification";
import { CoreSwitch } from "../../core/CoreSwitch";
import { SearchInput } from "./SearchInput";
import PasswordUpdateModal from "./PasswordUpdateModal";
import { InviteMember } from "./InviteMember";
import { EditMember } from "./EditMember";
import CorePageContainer from "../../core/CorePageContainer";
import GenerateInviteLink from "./GenerateInviteLink";
import CoreButton from "../../core/CoreButton";
import CoreTag from "../../core/CoreTag";

const TABLE_HEADER: TableHeaderDef[] = [
  {
    tableName: "user",
    accessor: "first_name",
    translationKey: "firstName",
    options: {
      isSortable: true,
    },
  },
  {
    tableName: "user",
    accessor: "last_name",
    translationKey: "lastName",
    options: {
      isSortable: true,
    },
  },
  {
    tableName: "user",
    accessor: "email",
    translationKey: "tableHeaderMail",
    options: {
      isSortable: true,
    },
  },
  {
    tableName: "namespace_user_roles",
    accessor: "scope",
    translationKey: "tableHeaderScope",
    options: {
      isSortable: true,
    },
  },
  {
    tableName: "user",
    accessor: "active",
    translationKey: "tableHeaderStatus",
  },
];

const COL_WIDTH: TableContentDef = {
  firstName: "25%",
  lastName: "25%",
  email: "25%",
  scope: "15%",
  active: "10%",
};

const ROW_HEIGHT: TableContentDef = {
  email: "40px",
  active: "40px",
};

const OPTIONS = {
  email: "Email",
  first_name: "First Name",
  last_name: "Last Name",
};

const Team: FC = observer(() => {
  const theme = useTheme();
  const { t, ready } = useTranslation("team");

  const useStyle = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      padding: "15px 10px 10px 10px",
      "@media screen and (max-width:768px)": {
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
      },
    },
    headingControls: {
      marginBottom: 50,
      maxWidth: "350px",
    },
    emailStyle: {
      color: theme.palette?.highlight.main,
      textDecoration: "none",
      width: "100%",
      cursor: "pointer",
    },
    scopeStyle: {
      width: "100%",
      cursor: "pointer",
      textTransform: "capitalize",
    },
    iconStyle: {
      fontSize: "0.875rem",
      marginLeft: "5px",
      alignItems: "center",
    },
    emailBox: {
      width: "auto",
    },
    button: {
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: alpha(theme.palette.highlight.light, 0.08),
        color: `${theme.palette.highlight.main} !important`,
      },
    },
    tag: {
      paddingTop: "10px",
    },
  });

  const classes = useStyle();
  const { userStore } = useStores();
  const notification = useNotification();

  const [isOperationInProgress, setOperationInProgress] = useState(false);
  const [page, setPage] = useState(0);
  const [userId, setUserId] = useState<number | null>(null);
  const [formattedHeaders, setFormattedHeaders] = useState<TableHeaderDef[]>(
    []
  );
  //Drawer invite member
  const [isInviteMember, setInviteMember] = useState(false);
  //Drawer edit member
  const [isEditMember, setEditMember] = useState(false);

  //Dialog generate invitation link
  const [isGenerateLink, setGenerateLink] = useState(false);

  const inputField: FormField = {
    translationKey: "searchFilters",
    key: "email",
    type: FIELD_TYPE.input,
  };

  const totalPages = Math.ceil(userStore.totalUsers / DEFAULT_TABLE_ROWS);

  const formattedData: TableContentDef[] =
    userStore.users.length > 0
      ? userStore.users.map((user) => {
          const canEditUsers =
            userStore.currentUserPermissions?.can("update", "users") &&
            user.scope !== "Owner" &&
            user.id !== userStore.user?.id;

          return {
            first_name: <Box>{user?.first_name}</Box>,
            last_name: <Box>{user?.last_name}</Box>,
            email: (
              <Box className={`${classes.emailStyle} ${classes.emailBox}`}>
                <Box
                  onClick={() => {
                    canEditUsers && handleEdit(user.id);
                  }}
                >
                  {user.email}
                  {canEditUsers && <EditIcon className={classes.iconStyle} />}
                </Box>
              </Box>
            ),
            scope: <Box className={classes.scopeStyle}>{user.scope}</Box>,
            active:
              user.id === userId && isOperationInProgress ? (
                <Box style={{ paddingLeft: "20px" }}>
                  <CircularProgress size={25} />
                </Box>
              ) : (
                <CoreTooltip
                  title={user.is_active ? t("userEnabled") : t("userDisabled")}
                >
                  <Box>
                    <CoreSwitch
                      checked={Boolean(user.is_active)}
                      onChange={() => handleSwitch(user.id)}
                      disabled={user.id === userStore.user?.id || !canEditUsers}
                    />
                  </Box>
                </CoreTooltip>
              ),
          };
        })
      : [];

  useEffect(() => {
    if (ready) {
      setFormattedHeaders(
        TABLE_HEADER.map((header) => ({
          ...header,
          label: header.translationKey
            ? t(header.translationKey)
            : (header.label as string),
        }))
      );
    }
  }, [ready, t]);

  useEffect(() => {
    userStore.setFilters(Object.keys(OPTIONS));
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    const updatedPage = newPage - 1;

    // Update page only if it was changed
    if (page !== updatedPage) {
      setPage(newPage - 1);
      loadData(newPage - 1);
    }
  };

  const loadData = (tablePage = page, orderBy?: string[]) => {
    setOperationInProgress(true);
    userStore
      .getAllUsers(DEFAULT_TABLE_ROWS, DEFAULT_TABLE_ROWS * tablePage, orderBy)
      .catch((error: Error) => {
        notification.error(t(error?.message || "fetchUsersError"));
      })
      .finally(() => setOperationInProgress(false));
  };

  const searchByFields = () => {
    loadData(page);
  };

  const handleSwitch = (userId: number) => {
    setOperationInProgress(true);
    setUserId(userId);

    let user = userStore.users.find((user) => user.id === userId);

    if (user) {
      user = {
        ...user,
        is_active: !user.is_active,
      };
    }
    if (user)
      userStore
        .updateNamespaceIsActive(userId, user.is_active)
        .then(() => {
          setUserId(null);
        })
        .catch((error: Error) => {
          setUserId(null);
          notification.error(t(error?.message || "updateSwitch"));
        })
        .finally(() => {
          loadData();
          setOperationInProgress(false);
        });
  };

  const handleEdit = (id: number) => {
    setEditMember(true);
    setUserId(id);
  };

  const handleOnClose = () => {
    setEditMember(false);
    setUserId(null);
  };

  const handleSort = (cell: TableHeaderDef) => {
    const newDirection =
      cell.options?.sortDirection === SORT_DIRECTION.asc
        ? SORT_DIRECTION.desc
        : SORT_DIRECTION.asc;

    setFormattedHeaders([
      ...formattedHeaders.map((item) => {
        if (cell.accessor === item.accessor) {
          return {
            ...item,
            options: {
              ...item.options,
              sortDirection: newDirection,
              isSortActive: true,
            },
          } as TableHeaderDef;
        } else
          return { ...item, options: { ...item.options, isSortActive: false } };
      }),
    ]);

    loadData(page, [
      cell.tableName ?? "",
      cell.accessor,
      newDirection.toUpperCase(),
    ]);
  };

  const handleInviteMember = () => {
    setInviteMember(!isInviteMember);
  };

  const handleGenerateLink = () => {
    setGenerateLink(!isGenerateLink);
  };

  const handleDeleteTag = () => {
    userStore.setUserInputFilter("");
    userStore.setFiltersInput("");
    searchByFields();
  };

  const headerActions: HeaderActions[] = [
    {
      label: t("invite_user"),
      tooltip: t("invite_user"),
      startIcon: <ContactMailIcon />,
      onClick: handleInviteMember,
      disabled: isOperationInProgress,
      ability: [{ action: "invite", subject: "users" }],
    },
    {
      label: t("generateInviteLink"),
      tooltip: t("generateInviteLink"),
      startIcon: <AddLinkIcon />,
      onClick: handleGenerateLink,
      disabled: isOperationInProgress,
      ability: [{ action: "invite", subject: "users" }],
    },
  ];

  return (
    <CorePageContainer
      extraHeaderActions={headerActions}
      label={t("title")}
      isNotFlowPage={true}
      fixedExtraHeaderActions={
        <CoreTooltip title={t("refresh_table")}>
          <Box>
            <CoreButton
              variant="neutral"
              size="large"
              onClick={() => loadData()}
              startIcon={<CachedOutlinedIcon />}
              className={classes.button}
              disabled={isOperationInProgress}
            >
              {t("refresh_table")}
            </CoreButton>
          </Box>
        </CoreTooltip>
      }
      showMoreVertIcon={
        userStore.currentUserPermissions?.can("invite", "users") ?? false
      }
    >
      <Box className={classes.mainContainer}>
        <Box className={classes.headingControls}>
          <SearchInput
            field={inputField}
            userStore={userStore}
            translation={t}
            otherProps={inputField.props}
            searchByFields={searchByFields}
            tooltipTitle={"searchMemberTooltip"}
          />

          {userStore.userInputFilter && (
            <Box className={classes.tag}>
              <CoreTag
                label={userStore.userInputFilter}
                onDelete={handleDeleteTag}
              />
            </Box>
          )}
        </Box>

        <CoreTable
          isPaginated={userStore.totalUsers > 10 ? true : false}
          headers={formattedHeaders}
          isLoading={userStore.loadingUsers}
          handleSorting={handleSort}
          currentPage={page + 1}
          changePage={handlePageChange}
          numberOfPages={totalPages}
          data={formattedData}
          columnWidth={COL_WIDTH}
          rowHeight={ROW_HEIGHT}
        />

        {userId && <PasswordUpdateModal id={userId} setUserId={setUserId} />}

        <InviteMember
          t={t}
          isOpen={isInviteMember}
          onClose={handleInviteMember}
        />
        <GenerateInviteLink
          t={t}
          isOpen={isGenerateLink}
          onClose={handleGenerateLink}
        />
        <EditMember
          t={t}
          isOpen={isEditMember}
          onClose={handleOnClose}
          userId={userId}
        />
      </Box>
    </CorePageContainer>
  );
});

export default Team;
