import { TFunction } from "i18next";
import React, { FC } from "react";
import { observer } from "mobx-react";

import { IconButton } from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";

import { DocumentStore } from "../../../../../stores/DocumentStore";
import { DocumentLineItem } from "../../../../../types/interfaces";
import { CANVAS_ACTIONS } from "../../../../../types/constants";
import CoreButton from "../../../../core/CoreButton";
import CoreTooltip from "../../../../core/CoreTooltip";

interface Props {
  documentStore: DocumentStore;
  translation: TFunction;
  field: DocumentLineItem;
  iconButtonClass: string;
}

const LineItemFieldActions: FC<Props> = observer(
  ({ documentStore, translation, field, iconButtonClass }) => {
    const deleteLineItem = (fieldKey: string) => {
      const newLineItems = documentStore.lineItems?.map((lineItem) => {
        if (lineItem?.type === fieldKey) {
          return { ...lineItem, data: [] };
        }
        return lineItem;
      });

      documentStore.setLineItems(newLineItems);
    };

    const handleClose = () => {
      documentStore.setFocusZone(null);
      documentStore.changeCanvasAction(CANVAS_ACTIONS.pan);
    };

    return (
      <>
        {documentStore.viewMode ? (
          <CoreButton
            variant="text"
            onClick={() => documentStore.setFocusFieldCanvas(null)}
            tabIndex={-1}
          >
            {translation("canvasLineItemsActions_closeLineItem")}
          </CoreButton>
        ) : (
          <>
            <CoreTooltip
              title={`${translation("canvasLineItemsActions_clearTooltip")}`}
            >
              <span>
                <IconButton onClick={() => deleteLineItem(field.type)}>
                  <ClearIcon />
                </IconButton>
              </span>
            </CoreTooltip>
            <CoreTooltip
              title={`${translation("canvasLineItemsActions_closeTooltip")}`}
            >
              <span>
                <IconButton onClick={handleClose} className={iconButtonClass}>
                  <CheckIcon />
                </IconButton>
              </span>
            </CoreTooltip>
          </>
        )}
      </>
    );
  }
);

export default LineItemFieldActions;
