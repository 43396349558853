import Konva from "konva";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash";

import {
  CANVAS_ACTIONS,
  FlowEnum,
  LOCAL_STORAGE_KEYS,
  emptyBox,
} from "../../types/constants";
import {
  APIDocumentZone,
  APIDocumentZoneBox,
  DocumentLineItem,
  DocumentLineItemLine,
  DocumentLineItemRow,
  DocumentZone,
  DocumentZoneBox,
  FlowField,
} from "../../types/interfaces";
import { DocumentStageCoords } from "../../types/types";
import ZoneHelper from "./zoneHelper";
import LineItemHeaderHelper from "./lineItemHeaderHelper";

// Value used for point zooming (zoom with CTRL)
const ZOOM_FACTOR_PERCENT = 1.03;
// Value used for incrementing/decrementing x/y coordinated on vertical/horizontal scroll (No CTRL zooming)
const ZOOM_FACTOR_PX = 25;

export default class CanvasHelper {
  static getLocalStorageConfig(): {
    fitAction: string | null | undefined;
  } {
    let fitAction = undefined as undefined | string | null;

    try {
      // FIXME: Load other configs if available

      fitAction = localStorage.getItem(LOCAL_STORAGE_KEYS.validationFitAction);
    } catch {
      /** */
    }

    return { fitAction };
  }

  // TODO: Improve code
  static calculateScaleCoords(
    scale: number,
    rotation: number,
    canvasW: number,
    canvasH: number,
    imgW: number | undefined,
    imgH: number | undefined,
    fitAction: CANVAS_ACTIONS | undefined
  ): { x: number; y: number } {
    let x = 0;
    let y = 0;

    // Rotation is already applied where this function is being called, thus no need to apply it here also
    const imgWidth = imgW || 0;
    const imgHeight = imgH || 0;

    switch (rotation) {
      case 0: {
        switch (fitAction) {
          case CANVAS_ACTIONS.fitHeight: {
            // Calculate scale based on height
            x = (canvasW - (imgWidth || 1) * scale) / 2;
            y = (canvasH - (imgHeight || 1) * scale) / 2;
            break;
          }
          case CANVAS_ACTIONS.fitWidth: {
            // If image height is smaller than the canvas height, then set coords to 0 for top centering
            const isImageSmaller = (imgHeight || 1) * scale < canvasH;

            x = isImageSmaller ? (canvasW - (imgWidth || 1) * scale) / 2 : 0;
            y = isImageSmaller ? (canvasH - (imgHeight || 1) * scale) / 2 : 0;
            break;
          }
          default:
            break;
        }
        break;
      }
      case 90: {
        switch (fitAction) {
          case CANVAS_ACTIONS.fitHeight: {
            x =
              (canvasW - (imgWidth || 1) * scale) / 2 + (imgWidth || 1) * scale;
            y = (canvasH - (imgHeight || 1) * scale) / 2;
            break;
          }
          case CANVAS_ACTIONS.fitWidth: {
            // If image height is smaller than the canvas height, then set coords to 0 for top centering
            const isImageSmaller = (imgHeight || 1) * scale < canvasH;

            x = isImageSmaller
              ? (canvasW - (imgWidth || 1) * scale) / 2 +
                (imgWidth || 1) * scale
              : (imgWidth || 1) * scale;
            y = isImageSmaller ? (canvasH - (imgHeight || 1) * scale) / 2 : 0;
            break;
          }
          default:
            break;
        }
        break;
      }
      case 180: {
        switch (fitAction) {
          case CANVAS_ACTIONS.fitHeight: {
            x =
              (canvasW - (imgWidth || 1) * scale) / 2 + (imgWidth || 1) * scale;
            y =
              (canvasH - (imgHeight || 1) * scale) / 2 +
              (imgHeight || 1) * scale;
            break;
          }
          case CANVAS_ACTIONS.fitWidth: {
            // If image height is smaller than the canvas height, then set coords to 0 for top centering
            const isImageSmaller = (imgHeight || 1) * scale < canvasH;

            x = isImageSmaller
              ? (canvasW - (imgWidth || 1) * scale) / 2 +
                (imgWidth || 1) * scale
              : (imgWidth || 1) * scale;
            y = isImageSmaller
              ? (canvasH - (imgHeight || 1) * scale) / 2 +
                (imgHeight || 1) * scale
              : (imgHeight || 1) * scale;
            break;
          }
          default:
            break;
        }
        break;
      }
      case 270: {
        switch (fitAction) {
          case CANVAS_ACTIONS.fitHeight: {
            x = (canvasW - (imgWidth || 1) * scale) / 2;
            y =
              (canvasH - (imgHeight || 1) * scale) / 2 +
              (imgHeight || 1) * scale;
            break;
          }
          case CANVAS_ACTIONS.fitWidth: {
            // If image height is smaller than the canvas height, then set coords to 0 for top centering
            const isImageSmaller = true; //(imgHeight || 1) * scale < canvasH;

            x = isImageSmaller ? (canvasW - (imgWidth || 1) * scale) / 2 : 0;
            y = isImageSmaller
              ? (canvasH - (imgHeight || 1) * scale) / 2 +
                (imgHeight || 1) * scale
              : (imgHeight || 1) * scale;
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
    }

    return { x, y };
  }

  /**
   * Converts manipulated UI zones into API format zones
   * @param zones - UI zones
   * @returns
   */
  static zoneToApiZone(zones: DocumentZone[]): APIDocumentZone[] {
    return zones.map((zone) => {
      const {
        rotation,
        box,
        type,
        text,
        pageIdentifier,
        manuallyAdded,
        skipValidation,
        key,
      } = zone;
      const { x, y, ...extraZoneProps } = box || {};

      const processedBox = box
        ? {
            ...extraZoneProps,
            rotation: rotation || zone.box.rotation,
            top_left: {
              x,
              y,
            },
          }
        : {};

      return {
        type,
        text,
        pageIdentifier,
        manuallyAdded,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        skipValidation: skipValidation ?? false,
        box: processedBox,
        key: key,
      } as APIDocumentZone;
    });
  }

  static dotProduct = (v1: number[], v2: number[]): number => {
    return v1[0] * v2[0] + v1[1] * v2[1];
  };

  static polygonToBox = (polygon: number[][]): number[] => {
    const xs = polygon.map((point) => point[0]);
    const ys = polygon.map((point) => point[1]);
    const x = Math.min(...xs);
    const y = Math.min(...ys);
    const width = Math.max(...xs) - x;
    const height = Math.max(...ys) - y;
    return [x, y, width, height];
  };

  static rotateZoneBox = (
    apiZoneBox: APIDocumentZoneBox,
    angle: number,
    pageWidth: number,
    pageHeight: number
  ) => {
    const x = apiZoneBox?.top_left?.x || 0;
    const y = apiZoneBox?.top_left?.y || 0;
    const width = apiZoneBox?.width || 0;
    const height = apiZoneBox?.height || 0;

    const polyBox = [
      [x, y],
      [x + width, y],
      [x + width, y + height],
      [x, y + height],
    ];

    const radians = angle * (Math.PI / 180);

    const centerX = pageWidth / 2;
    const centerY = pageHeight / 2;

    const rotated = polyBox.map((point) => {
      const cos = Math.cos(radians);
      const sin = Math.sin(radians);
      const nx = cos * (point[0] - centerX) + sin * (point[1] - centerY);
      const ny = cos * (point[1] - centerY) - sin * (point[0] - centerX);

      const newW =
        centerX * Math.abs(Math.cos(radians)) +
        centerY * Math.abs(Math.sin(radians));
      const newH =
        centerX * Math.abs(Math.sin(radians)) +
        centerY * Math.abs(Math.cos(radians));

      return [nx + newW, ny + newH];
    });

    const newBox = this.polygonToBox(rotated);

    return {
      x: newBox[0],
      y: newBox[1],
      width: newBox[2],
      height: newBox[3],
    };
  };

  /**
   * Converts received OCR to zones which can be manipulated in canvas
   * @param zones - OCR zones received from engine
   * @returns
   */
  static apiZoneToZone(
    zones: DocumentZone[],
    rotationOffset = 0,
    pageWidth = 0,
    pageHeight = 0,
    isDefault = false,
    documentFields?: DocumentZone[] | null,
    currentDocId?: string | null
  ): DocumentZone[] {
    if (!zones || zones.length === 0) {
      return [];
    }

    return zones.map((zone, index) => {
      const apiZoneBox = zone?.box as unknown as APIDocumentZoneBox;

      const newBox = this.rotateZoneBox(
        apiZoneBox,
        rotationOffset,
        pageWidth,
        pageHeight
      );

      const box = {
        ...(apiZoneBox || {}),
        ...(apiZoneBox?.top_left || {}),
        ...newBox,
        rotation: apiZoneBox?.rotation || 0,
      };
      let category = "";

      // Set category for linked zones (used for simple mode validation)

      if (documentFields && documentFields.length > 0) {
        const intersectedField = documentFields?.find((field) => {
          //If there is no box, it is a text zone only and we don't need to check for intersection
          return (
            field.pageIdentifier === currentDocId &&
            Object.keys(field.box).length > 0 &&
            ZoneHelper.intersectRect(field.box, box)
          );
        });
        if (intersectedField) {
          category = intersectedField.type;
        }
      }

      return {
        ...zone,
        index,
        default: isDefault,
        identifier: uuidv4(),
        box,
        category,
        key: zone.key || zone?.type,
      } as DocumentZone;
    });
  }

  /**
   * Calculated new state coords based on received event
   * @param stageEvent - stage event
   * @param rawCoords - if received, function returns mouse coords directly without scale applied
   * @returns
   */
  static getScaleCoords(
    stageEvent:
      | Konva.KonvaEventObject<DragEvent>
      | Konva.KonvaEventObject<MouseEvent | TouchEvent>,
    rawCoords?: boolean
  ): DocumentStageCoords {
    const stage = stageEvent.target.getStage() as Konva.Stage;
    const currentScale = stage.scaleX();
    const pointerPosition = stage.getPointerPosition() as Konva.Vector2d;

    // Current mouse event
    const mouseXCoord =
      pointerPosition.x / currentScale - stage.x() / currentScale;

    const mouseYCoord =
      pointerPosition.y / currentScale - stage.y() / currentScale;

    if (rawCoords) {
      return {
        x: mouseXCoord,
        y: mouseYCoord,
      };
    }

    return {
      x: -(mouseXCoord - pointerPosition.x / currentScale) * currentScale,
      y: -(mouseYCoord - pointerPosition.y / currentScale) * currentScale,
    };
  }

  /**
   * Used for calculating coords for zooming (in/out) or scrolling (horizontally/vertically)  on wheel event
   * @param stageEvent - stage event
   * @param originalCoords - current coords
   * @returns Updated stage scale and x/y coords
   */
  static getOnWheelStageCoords(
    stageEvent: Konva.KonvaEventObject<WheelEvent>,
    originalCoords: DocumentStageCoords,
    rotation: number
  ): { scale: number; coords: DocumentStageCoords } {
    const stage = stageEvent.currentTarget.getStage() as Konva.Stage;
    const currentScale = stage.scaleX();
    const pointerPosition = stage.getPointerPosition() as Konva.Vector2d;

    const dividedScale = currentScale / ZOOM_FACTOR_PERCENT;
    const multiplicateScale = currentScale * ZOOM_FACTOR_PERCENT;
    const deltaY = stageEvent.evt.deltaY;

    let newScale = 0;
    if (stageEvent.evt.ctrlKey) {
      // Zoom scale on wheel
      newScale = deltaY > 0 ? dividedScale : multiplicateScale;
    }
    // Scroll scale on wheel
    else if (rotation === 180 || rotation === 270) {
      // Inverted image
      newScale = deltaY > 0 ? dividedScale : multiplicateScale;
    } else {
      newScale = deltaY > 0 ? multiplicateScale : dividedScale;
    }

    let newCoords = originalCoords;

    const calculateScaledPoint = (
      pointerPositionPoint: number,
      stagePoint: number
    ) => {
      const mouseCoord =
        pointerPositionPoint / currentScale - stagePoint / currentScale;
      return -(mouseCoord - pointerPositionPoint / newScale) * newScale;
    };

    const calculatePointIncrement = (pointValue: number) => {
      return deltaY > 0
        ? pointValue - ZOOM_FACTOR_PX
        : pointValue + ZOOM_FACTOR_PX;
    };

    // Calculate X coordinate
    if (stageEvent.evt.ctrlKey || stageEvent.evt.altKey) {
      // Scroll horizontally or zoom
      newCoords = {
        ...newCoords,
        x: stageEvent.evt.ctrlKey
          ? calculateScaledPoint(pointerPosition.x, stage.x())
          : calculatePointIncrement(originalCoords.x),
      };
    }

    // Calculate Y coordinate
    if (stageEvent.evt.ctrlKey || !stageEvent.evt.altKey) {
      // Scroll vertically or zoom
      newCoords = {
        ...newCoords,
        y: stageEvent.evt.ctrlKey
          ? calculateScaledPoint(pointerPosition.y, stage.y())
          : calculatePointIncrement(originalCoords.y),
      };
    }

    return { scale: newScale, coords: newCoords };
  }

  static getCanvasLimits(
    canvasDimensions: { width: number; height: number },
    image: { width: number; height: number } | undefined,
    scale: number,
    rotation: number
  ): { xMax: number; xMin: number; yMax: number; yMin: number } {
    const isVerticallyRotated = rotation === 90 || rotation === 270;

    const imageWidthScaled = isVerticallyRotated
      ? (image?.height || 1) * scale
      : (image?.width || 1) * scale;

    const imageHeightScaled = isVerticallyRotated
      ? (image?.width || 1) * scale
      : (image?.height || 1) * scale;

    let xMax = 0;
    let xMin = 0;
    let yMax = 0;
    let yMin = 0;

    if (rotation === 0) {
      xMax = canvasDimensions.width - imageWidthScaled / 2;
      xMin = -imageWidthScaled / 2;
      yMax = canvasDimensions.height - imageHeightScaled / 2;
      yMin = -imageHeightScaled / 2;

      if (imageWidthScaled / 2 > canvasDimensions.width) {
        xMax = canvasDimensions.width - 100;
        xMin = -imageWidthScaled + 100;
      }

      if (imageHeightScaled / 2 > canvasDimensions.height) {
        yMax = canvasDimensions.height - 100;
        yMin = -imageHeightScaled + 100;
      }
    }

    if (rotation === 90) {
      xMax = canvasDimensions.width + imageWidthScaled / 2;
      xMin = imageWidthScaled / 2;
      yMax = canvasDimensions.height - imageHeightScaled / 2;
      yMin = -imageHeightScaled / 2;

      if (imageWidthScaled / 2 > canvasDimensions.width) {
        xMax = canvasDimensions.width + imageWidthScaled - 100;
        xMin = 100;
      }

      if (imageHeightScaled / 2 > canvasDimensions.height) {
        yMax = canvasDimensions.height - 100;
        yMin = -imageHeightScaled + 100;
      }
    }

    if (rotation === 180) {
      xMax = canvasDimensions.width + imageWidthScaled / 2;
      xMin = imageWidthScaled / 2;
      yMax = canvasDimensions.height + imageHeightScaled / 2;
      yMin = imageHeightScaled / 2;

      if (imageWidthScaled / 2 > canvasDimensions.width) {
        xMax = canvasDimensions.width + imageWidthScaled - 100;
        xMin = 100;
      }

      if (imageHeightScaled / 2 > canvasDimensions.height) {
        yMax = canvasDimensions.height + imageHeightScaled - 100;
        yMin = 100;
      }
    }

    if (rotation === 270) {
      xMax = canvasDimensions.width - imageWidthScaled / 2;
      xMin = -imageWidthScaled / 2;
      yMax = canvasDimensions.height + imageHeightScaled / 2;
      yMin = imageHeightScaled / 2;

      if (imageWidthScaled / 2 > canvasDimensions.width) {
        xMax = canvasDimensions.width - 100;
        xMin = -imageWidthScaled + 100;
      }

      if (imageHeightScaled / 2 > canvasDimensions.height) {
        yMax = canvasDimensions.height + imageHeightScaled - 100;
        yMin = 100;
      }
    }

    return { xMax, xMin, yMax, yMin };
  }

  static getCanvasCoords(
    canvasLimits: {
      xMin: number;
      xMax: number;
      yMin: number;
      yMax: number;
    },
    stageCoords: { x: number; y: number }
  ): { x: number; y: number } {
    let x = stageCoords.x;
    let y = stageCoords.y;

    if (stageCoords.x < canvasLimits.xMin) {
      x = canvasLimits.xMin;
    }

    if (stageCoords.y < canvasLimits.yMin) {
      y = canvasLimits.yMin;
    }

    if (stageCoords.x > canvasLimits.xMax) {
      x = canvasLimits.xMax;
    }

    if (stageCoords.y > canvasLimits.yMax) {
      y = canvasLimits.yMax;
    }

    return { x, y };
  }

  static getLineIntersectedColumns = (
    intersectionLine: DocumentLineItemLine,
    lineItem: DocumentLineItem
  ): {
    rightColumn: string;
    leftColumn: string;
  } => {
    //find all vertical lines
    const verticalLines = lineItem?.coords?.lines?.filter((line) =>
      this.isVertical(line)
    );

    //find closest vertical line
    const closestVerticalLine =
      verticalLines.length > 0
        ? verticalLines?.reduce((prev, curr) => {
            const prevDistance = Math.abs(prev[0][0] - intersectionLine[0][0]);
            const currDistance = Math.abs(curr[0][0] - intersectionLine[0][0]);

            return prevDistance < currDistance ? prev : curr;
          })
        : ([[0][0]] as unknown as DocumentLineItemLine);

    let rightColumn = "";
    let leftColumn = "";

    lineItem.headers.forEach((header) => {
      if (
        this.isDifferenceLessThan2px(header.box.x, closestVerticalLine[0][0])
      ) {
        //right column
        rightColumn = header.key ?? "";
      }

      if (
        this.isDifferenceLessThan2px(
          header.box.x + header.box.width,
          closestVerticalLine[0][0]
        )
      ) {
        //left column
        leftColumn = header.key ?? "";
      }
    });

    const result = {
      leftColumn,
      rightColumn,
    };
    //find the column that ends at the closest vertical line

    return result;
  };

  // TODO: Test more
  static getLinkedZones(
    zones: DocumentZone[],
    fields: DocumentZone[],
    focusedZoneIdentifiers: string[]
  ): DocumentZone[] {
    let selections = [] as DocumentZone[];

    const focusedFields = fields?.filter((field) =>
      focusedZoneIdentifiers.includes(field.identifier)
    );
    const focusedZones = zones?.filter((field) =>
      focusedZoneIdentifiers.includes(field.identifier)
    );

    if (focusedFields && focusedFields.length > 0) {
      // TODO: Reduce intersection value
      // Get intersections
      focusedFields.forEach((field) => {
        const intersectedFieldZones = zones?.filter((zone) =>
          ZoneHelper.intersectRect(field.box, zone.box)
        );

        if (intersectedFieldZones?.length > 0) {
          selections = [...selections, ...intersectedFieldZones];
        }
      });
    }

    if (focusedZones && focusedZones?.length > 0) {
      selections = [...selections, ...focusedZones];
    }

    return selections;
  }

  static resizeCellWithinTableBounds = (
    cellBox: DocumentZoneBox,
    tableBox: DocumentZoneBox,
    oldTableBox: DocumentZoneBox
  ): DocumentZoneBox => {
    const deltaX = Math.round(tableBox.width) - Math.round(oldTableBox.width);
    const deltaY = Math.round(tableBox.height) - Math.round(oldTableBox.height);
    const xChanged = Math.round(tableBox.x) !== Math.round(oldTableBox.x);
    const yChanged = Math.round(tableBox.y) !== Math.round(oldTableBox.y);

    //find if cell is left
    if (this.isDifferenceLessThan2px(oldTableBox.x, cellBox.x) && xChanged) {
      // cell is left cell
      // change width and x
      cellBox.width = cellBox.width + deltaX;
      cellBox.x = tableBox.x;
    }

    //find if cell is top
    if (this.isDifferenceLessThan2px(oldTableBox.y, cellBox.y) && yChanged) {
      // cell is top cell
      // change height and y
      cellBox.height = cellBox.height + deltaY;
      cellBox.y = tableBox.y;
    }

    if (deltaX !== 0 && !xChanged) {
      //find if cell is right
      if (
        this.isDifferenceLessThan2px(
          oldTableBox.x + oldTableBox.width,
          cellBox.x + cellBox.width
        )
      ) {
        // cell is right cell
        // change width
        cellBox.width = cellBox.width + deltaX;
      }
    }

    if (deltaY !== 0 && !yChanged) {
      //find if cell is bottom
      if (
        this.isDifferenceLessThan2px(
          oldTableBox.y + oldTableBox.height,
          cellBox.y + cellBox.height
        )
      ) {
        // cell is bottom cell
        // change height
        cellBox.height = cellBox.height + deltaY;
      }
    }

    return cellBox;
  };

  static isDifferenceLessThan2px = (num1: number, num2: number): boolean => {
    return Math.abs(num1 - num2) < 2;
  };

  static isHorizontal = (line: DocumentLineItemLine): boolean => {
    const angle = Math.abs(
      Math.atan2(line[1][1] - line[0][1], line[1][0] - line[0][0])
    );
    return angle < Math.PI / 4 || angle > (3 * Math.PI) / 4;
  };

  static isVertical = (line: DocumentLineItemLine): boolean => {
    const angle = Math.abs(
      Math.atan2(line[1][1] - line[0][1], line[1][0] - line[0][0])
    );
    return angle > Math.PI / 4 && angle < (3 * Math.PI) / 4;
  };

  static resizeTableLines = (
    lines: DocumentLineItemLine[],
    scaledTableCoords: DocumentZone
  ): DocumentLineItemLine[] => {
    if (!lines || lines?.length === 0) {
      return [];
    }

    return lines.map((line) => {
      if (this.isHorizontal(line)) {
        // horizontal line
        line[0][0] = scaledTableCoords.box.x;
        line[1][0] = scaledTableCoords.box.x + scaledTableCoords.box.width;
      } else {
        // vertical line
        line[0][1] = scaledTableCoords.box.y;
        line[1][1] = scaledTableCoords.box.y + scaledTableCoords.box.height;
      }

      return line;
    });
  };

  static resizeHeaderCells = (
    headers: DocumentZone[],
    scaledBox: DocumentZoneBox,
    box: DocumentZoneBox
  ) => {
    const resizedHeaders = headers.map((header) => {
      const resizedHeader = CanvasHelper.resizeCellWithinTableBounds(
        header.box,
        scaledBox,
        box
      );
      header.box = resizedHeader;
      return header;
    });

    return resizedHeaders;
  };

  static resizeDataCells = (
    dataCells: DocumentLineItemRow[],
    scaledBox: DocumentZoneBox,
    box: DocumentZoneBox
  ) => {
    const resizedCells = dataCells.map((dataCell) => {
      const resizedHeader = CanvasHelper.resizeCellWithinTableBounds(
        dataCell.box,
        scaledBox,
        box
      );
      dataCell.box = resizedHeader;

      if (dataCell.cells) {
        Object.keys(dataCell.cells).forEach((key) => {
          const cell = this.resizeCellWithinTableBounds(
            dataCell.cells[key].box,
            scaledBox,
            box
          );

          dataCell.cells[key].box = cell;
        });
      }

      return dataCell;
    });

    return resizedCells;
  };

  static splitTableCell = (
    cell: DocumentZone,
    lineCoord: number,
    ocrZones: DocumentZone[],
    verticalSplit = false
  ) => {
    // if vertical split, then split the cell vertically
    // in this case, lineCoord will be the x coordinate of the vertical line
    // else, split the cell horizontally
    // meaning, lineCoord will be the y coordinate of the horizontal line

    const aboveCellBox = {
      ...cell.box,
      width: verticalSplit ? lineCoord - cell.box.x : cell.box.width,
      height: verticalSplit ? cell.box.height : lineCoord - cell.box.y,
    };

    const belowCellBox = {
      ...cell.box,
      x: verticalSplit ? lineCoord : cell.box.x,
      width: verticalSplit
        ? cell.box.width - (lineCoord - cell.box.x)
        : cell.box.width,
      y: verticalSplit ? cell.box.y : lineCoord,
      height: verticalSplit
        ? cell.box.height
        : cell.box.height - (lineCoord - cell.box.y),
    };

    return [
      {
        ...cell,
        box: aboveCellBox,
        text:
          ocrZones
            ?.filter((zone) =>
              ZoneHelper.intersectRect(aboveCellBox, zone.box, 0.3)
            )
            ?.sort((a, b) => a.index - b.index)
            ?.map((zone) => zone.text)
            ?.join(" ") || "",
      },
      {
        ...cell,
        box: belowCellBox,
        text:
          ocrZones
            ?.filter((zone) =>
              ZoneHelper.intersectRect(belowCellBox, zone.box, 0.3)
            )
            ?.sort((a, b) => a.index - b.index)
            ?.map((zone) => zone.text)
            ?.join(" ") || "",
      },
    ];
  };

  static initRowPoints = (box: DocumentZoneBox): number[][] => {
    const boxX = box.x || 0;
    const boxY = box.y || 0;
    const boxWidth = box.width || 0;
    const boxHeight = box.height || 0;

    return [
      [boxX, boxY],
      [boxX + boxWidth, boxY],
      [boxX + boxWidth, boxY + boxHeight],
      [boxX, boxY + boxHeight],
    ];
  };

  static splitTableRow = (
    lineItem: DocumentLineItem,
    newLine: DocumentLineItemLine,
    canvasZones: DocumentZone[]
  ) => {
    // There is a case when detect operation returns only the headers and no data (header is being splitted)
    // Need the first split to init the very first body line and adjust the header
    if (LineItemHeaderHelper.isHeaderBeingSplit(lineItem, newLine)) {
      return LineItemHeaderHelper.splitHeadersWithNoData(
        lineItem,
        newLine,
        canvasZones
      );
    }

    // Split intersected row
    let newRow = {} as DocumentLineItemRow;
    let splitIdx = -1;
    lineItem.data.forEach((row, index) => {
      if (
        Object.keys(row.cells).length === 0 ||
        Object.keys(newRow).length !== 0
      ) {
        return;
      }
      const cell = Object.values(row.cells)[0];

      const lineY = newLine[0][1];

      if (cell.box.y <= lineY && cell.box.y + cell.box.height >= lineY) {
        splitIdx = index;
        newRow = JSON.parse(
          JSON.stringify({
            ...row,
            box: { ...row.box, rotation: row.box?.rotation || 0 },
            points: [...(row.points || CanvasHelper.initRowPoints(row.box))],
            cells: { ...row.cells },
          })
        ) as DocumentLineItemRow;

        const origHeight = row.box.height;

        row.box.height = lineY - row.box.y;

        newRow.box.y = lineY;
        newRow.box.height = origHeight - row.box.height;

        Object.entries(row.cells).forEach(([key, cell]) => {
          [row.cells[key], newRow.cells[key]] = this.splitTableCell(
            cell,
            lineY,
            canvasZones
          );
        });
      }
    });

    if (Object.keys(newRow).length !== 0) {
      lineItem.data.splice(splitIdx + 1, 0, newRow);
    }

    return lineItem;
  };

  static lineToRect = (line: number[][]) => {
    const [x1, y1] = line[0];
    const [x2, y2] = line[1];

    // Calculate the x, y, width, and height of the rect
    const x = Math.min(x1, x2);
    const y = Math.min(y1, y2);
    const width = Math.abs(x2 - x1);
    const height = Math.abs(y2 - y1);

    return { x, y, width, height };
  };

  static mergeTableRow = (
    lineItem: DocumentLineItem,
    newLine: DocumentLineItemLine
  ) => {
    const closestRowIdx = lineItem.data
      ?.map(
        (row, index) =>
          ({
            y: Math.abs(Object.values(row.cells)[0].box.y - newLine[0][1]),
            idx: index,
          } as { y: number; idx: number })
      )
      ?.sort((a, b) => a.y - b.y)[0];

    if (!closestRowIdx || closestRowIdx?.idx === 0) {
      if (lineItem.data.length > 0) {
        Object.values(lineItem.headers).forEach((header: DocumentZone) => {
          const otherRow = lineItem.data[0];

          header.box.height += otherRow.box.height;

          header.text += header.key
            ? " " + otherRow.cells[header.key].text
            : "";
        });

        lineItem.data.shift();
      }

      return lineItem;
    }

    // row above
    const aboveRow = lineItem.data[closestRowIdx.idx - 1];
    const belowRow = lineItem.data[closestRowIdx.idx];
    aboveRow.box.height += belowRow.box.height;

    Object.entries(aboveRow.cells).forEach(([key, cell]) => {
      cell.box.height += belowRow.cells[key].box.height;

      cell.text += belowRow.cells[key].text
        ? " " + belowRow.cells[key].text
        : "";
    });

    const newLineItem = {
      ...lineItem,
      data: [
        ...lineItem.data.filter((row, index) => index !== closestRowIdx.idx),
      ],
    };

    return newLineItem;
  };

  static getColumnKeyToSplit(
    splitLine: DocumentLineItemLine,
    lineItem: DocumentLineItem
  ): string | undefined | null {
    const topXPoint = splitLine?.[0]?.[0];
    const firstRow = lineItem?.data?.[0];

    if (!firstRow) {
      // Search for column in lineItem headers
      const headers = lineItem?.headers;
      if (!headers || headers?.length === 0) {
        return null;
      }

      // Search in headers (manual config)
      return headers?.find(
        (header) =>
          header?.box?.x <= topXPoint &&
          header?.box?.x + header?.box?.width >= topXPoint
      )?.key;
    }

    return Object.keys(firstRow.cells || {})?.find(
      (cellKey) =>
        firstRow.cells[cellKey]?.box?.x <= topXPoint &&
        firstRow.cells[cellKey]?.box?.x + firstRow.cells[cellKey]?.box?.width >=
          topXPoint
    );
  }

  static splitTableColumn = (
    lineItem: DocumentLineItem, // LineItem object configuration
    newHeaderColumn: FlowField, // New defined header
    originalColumns: FlowField[], // Original headers (from flow config)
    newLine: DocumentLineItemLine, // New drawn line
    canvasZones: DocumentZone[],
    // By default, the new column will be added as second to original
    // If this property comes as true, then the new column will be added as first, then oeiginal as second column
    positionSwitched = false
  ): {
    updatedLineItem: DocumentLineItem;
    headers: FlowField[];
    splitSuccessful: boolean;
  } => {
    try {
      // If no table body rows, then split lineItem headers only
      if (!lineItem.data || lineItem.data?.length === 0) {
        return LineItemHeaderHelper.splitHeaderColumnWithNoData(
          lineItem,
          newHeaderColumn,
          originalColumns,
          newLine,
          canvasZones,
          positionSwitched
        );
      }

      let formattedOriginalColumns = originalColumns;

      // Used for adding the new line
      // If false, then no need to create a new line (will not be linked to any cell)
      let splitSuccessful = false;

      // Cell formatter
      const topPoint = { x: newLine[0][0], y: newLine[0][1] };

      const formattedData = lineItem.data.map((row, index) => {
        let formattedRow = { ...row };

        if (Object.keys(row.cells).length === 0) {
          return formattedRow;
        }

        Object.keys(row.cells).forEach((colKey) => {
          const column = row.cells[colKey];

          if (
            column.box.x <= topPoint.x &&
            column.box.x + column.box.width >= topPoint.x
          ) {
            splitSuccessful = true;

            // Altered original column information
            const originalColumn = this.updateCellText(
              {
                ...column,
                box: {
                  ...column.box,
                  width: topPoint.x - column.box.x,
                },
              },
              canvasZones
            );

            // Newly created column information
            const newColumn = this.updateCellText(
              {
                ...column,
                ...newHeaderColumn,
                identifier: uuidv4(),
                box: {
                  ...column.box,
                  x: topPoint.x,
                  y: column.box.y,
                  width: column.box.width + column.box.x - topPoint.x,
                  rotation: 0,
                },
                points: [
                  [topPoint.x, column.box.y],
                  [topPoint.x, column.box.y + column.box.height],
                  [
                    column.box.x + column.box.width,
                    column.box.y + column.box.height,
                  ],
                  [column.box.x + column.box.width, column.box.y],
                ],
              },
              canvasZones
            );

            // Define the new column only once
            if (index === 0) {
              let headersListPlaceholder = [] as FlowField[];

              // Splitted column is not within configuration
              if (!originalColumns?.some((col) => col.key === colKey)) {
                // Add two new columns
                const splittedLineItemHeader = lineItem?.headers?.find(
                  (item) => item.key === colKey
                );

                headersListPlaceholder = [
                  ...originalColumns,
                  {
                    ...newHeaderColumn,
                    category: FlowEnum.table,
                    description: "",
                    key: splittedLineItemHeader?.key as string,
                    name: splittedLineItemHeader?.text as string,
                  },
                  newHeaderColumn,
                ];
              } else {
                // Update current header and add new column
                originalColumns.forEach((header) => {
                  // If the new column exists in flow configuration, then use the new definition and skip adding it as duplicated
                  if (header.key !== newHeaderColumn.key) {
                    if (header.key === colKey) {
                      headersListPlaceholder = [
                        ...(headersListPlaceholder || []),
                        ...(positionSwitched
                          ? [newHeaderColumn, header]
                          : [header, newHeaderColumn]),
                      ];
                    } else {
                      headersListPlaceholder = [
                        ...headersListPlaceholder,
                        header,
                      ];
                    }
                  }
                });
              }

              formattedOriginalColumns = headersListPlaceholder;
            }

            formattedRow = {
              ...formattedRow,
              cells: {
                ...formattedRow.cells,
                [colKey]: positionSwitched
                  ? { ...originalColumn, box: newColumn.box }
                  : originalColumn,
                [newColumn?.["key"] as string]: positionSwitched
                  ? { ...newColumn, box: originalColumn.box }
                  : newColumn,
              },
            };
          }
        });

        return formattedRow;
      });

      const formattedHeaders = [];

      for (const header of lineItem.headers) {
        if (
          header.box.x <= topPoint.x &&
          header.box.x + header.box.width >= topPoint.x
        ) {
          const leftHeader = this.updateCellText(
            {
              ...header,
              box: {
                ...header.box,
                width: topPoint.x - header.box.x,
              },
            },
            canvasZones
          );

          const rightHeader = this.updateCellText(
            {
              ...header,
              key: newHeaderColumn.key,
              box: {
                ...header.box,
                x: topPoint.x,
                y: header.box.y,
                width: header.box.width + header.box.x - topPoint.x,
              },
            },
            canvasZones
          );

          positionSwitched
            ? formattedHeaders.push(rightHeader, leftHeader)
            : formattedHeaders.push(leftHeader, rightHeader);
        } else {
          formattedHeaders.push(header);
        }
      }

      return {
        updatedLineItem: {
          ...lineItem,
          data: formattedData,
          headers: formattedHeaders,
        } as DocumentLineItem,
        headers: formattedOriginalColumns,
        splitSuccessful,
      };
    } catch {
      return {
        updatedLineItem: lineItem,
        headers: originalColumns,
        splitSuccessful: false,
      };
    }
  };

  static remakeTableColumnsAfterLineMove = (
    lineItem: DocumentLineItem, // LineItem object configuration
    movedLineIndex: number, // Index of the moved line
    movedLine: DocumentLineItemLine,
    canvasZones: DocumentZone[] // Zones from which the text is extracted
  ) => {
    const movedLineOldX = movedLine[0][0];
    const movedLineNewX = lineItem.coords.lines[movedLineIndex][0][0];
    //moved columns are movedLineIndex and movedLineIndex + 1

    const formattedData = lineItem.data.map((row) => {
      let formattedRow = { ...row };

      if (Object.keys(row.cells).length === 0) {
        return formattedRow;
      }

      Object.keys(formattedRow.cells).forEach((colKey) => {
        let column = formattedRow.cells[colKey];

        //find cells that had the beginning X of the moved line or the ending X of the moved line
        if (column.box.x === movedLineOldX) {
          //right column
          column = this.updateCellText(
            {
              ...column,
              box: {
                ...column.box,
                x: movedLineNewX,
                width: column.box.width + movedLineOldX - movedLineNewX,
              },
            },
            canvasZones
          );
        }
        if (column.box.x + column.box.width === movedLineOldX) {
          //left column
          column = this.updateCellText(
            {
              ...column,
              box: {
                ...column.box,
                width: movedLineNewX - column.box.x,
              },
            },
            canvasZones
          );
        }

        formattedRow = {
          ...formattedRow,
          cells: {
            ...formattedRow.cells,
            [colKey]: column,
          },
        };
      });
      return formattedRow;
    });

    const formattedHeaders = lineItem.headers.map((header) => {
      let formattedHeader = { ...header };

      if (header.box.x === movedLineOldX) {
        //right column
        formattedHeader = {
          ...formattedHeader,
          box: {
            ...formattedHeader.box,
            x: movedLineNewX,
            width: formattedHeader.box.width + movedLineOldX - movedLineNewX,
          },
        };
      }
      if (header.box.x + header.box.width === movedLineOldX) {
        //left column
        formattedHeader = {
          ...formattedHeader,
          box: {
            ...formattedHeader.box,
            width: movedLineNewX - header.box.x,
          },
        };
      }

      return formattedHeader;
    });

    return {
      updatedLineItem: {
        ...lineItem,
        data: formattedData,
        headers: formattedHeaders,
      } as DocumentLineItem,
    };
  };

  static remakeTableRowsAfterLineMove = (
    lineItem: DocumentLineItem, // LineItem object configuration
    movedLineIndex: number, // Index of the moved line
    movedLine: DocumentLineItemLine,
    canvasZones: DocumentZone[] // Zones from which the text is extracted
  ) => {
    const movedLineOldY = movedLine[0][1];
    const movedLineNewY = lineItem.coords.lines[movedLineIndex][0][1];

    // Function to update cell text based on intersecting zones

    const formattedData = lineItem.data.map((row) => {
      let formattedRow = { ...row };

      if (Object.keys(row.cells).length === 0) {
        return formattedRow;
      }

      Object.keys(formattedRow.cells).forEach((colKey) => {
        let column = formattedRow.cells[colKey];

        // Find cells that had the beginning Y of the moved line or the ending Y of the moved line
        if (column.box.y === movedLineOldY) {
          // Bottom cell
          column = this.updateCellText(
            {
              ...column,
              box: {
                ...column.box,
                y: movedLineNewY,
                height: column.box.height + movedLineOldY - movedLineNewY,
              },
            },
            canvasZones
          );
        }
        if (column.box.y + column.box.height === movedLineOldY) {
          // Top cell
          column = this.updateCellText(
            {
              ...column,
              box: {
                ...column.box,
                height: movedLineNewY - column.box.y,
              },
            },
            canvasZones
          );
        }

        formattedRow = {
          ...formattedRow,
          cells: {
            ...formattedRow.cells,
            [colKey]: column,
          },
        };
      });

      return formattedRow;
    });

    return {
      updatedLineItem: { ...lineItem, data: formattedData } as DocumentLineItem,
    };
  };

  static updateCellText = (
    cell: DocumentZone,
    canvasZones: DocumentZone[]
  ): DocumentZone => {
    return {
      ...cell,
      text:
        canvasZones
          ?.filter((zone) => ZoneHelper.intersectRect(cell.box, zone.box, 0.3))
          ?.sort((a, b) => a.index - b.index)
          ?.map((zone) => zone.text)
          ?.join(" ") || "",
    };
  };

  static mergeTableColumns = (
    columnInformation: {
      isLeftColumnKept: boolean;
      keep: string;
      remove: string;
    }, // Column to keep and column to remove
    lineItem: DocumentLineItem, // LineItem object configuration
    originalColumns: FlowField[], // Original headers (from flow config)
    canvasZones: DocumentZone[] // Zones from which the text is extracted
  ): {
    updatedLineItem: DocumentLineItem;
    headers: FlowField[];
  } => {
    try {
      //find the left most part of the intersection
      let leftX: number;
      let rightX: number;
      let lineXToRemove: number;
      const headers = lineItem.headers;

      const headerBoxKeep =
        headers.find((header) => header.key === columnInformation.keep)?.box ??
        (emptyBox.box as DocumentZoneBox);

      const headerBoxRemove =
        headers.find((header) => header.key === columnInformation.remove)
          ?.box ?? (emptyBox.box as DocumentZoneBox);

      if (columnInformation.isLeftColumnKept) {
        //find x coord of the cell

        lineXToRemove = headerBoxKeep.x + headerBoxKeep.width;
        leftX = headerBoxKeep.x;
        rightX = headerBoxRemove.x + headerBoxRemove.width;
      } else {
        lineXToRemove = headerBoxRemove.x;
        leftX = headerBoxRemove.x;
        rightX = headerBoxKeep.x + headerBoxKeep.width;
      }

      // Merge in original headers (flow config)
      const columnToRemoveRef = originalColumns?.find(
        (header) => header.key === columnInformation.remove
      );
      const formattedOriginalColumns = originalColumns
        ?.filter((header) => header.key !== columnInformation.remove)
        ?.map((header) =>
          header.key === columnInformation.keep && columnToRemoveRef?.key
            ? {
                ...header,
                name: `${header.name} ${columnToRemoveRef.name || ""}`,
              }
            : header
        );

      const formattedData = lineItem.data.map((row) => {
        if (Object.keys(row.cells).length === 0) {
          return row;
        }

        const rowCells = row.cells;
        let formattedCells = {};

        Object.keys(row.cells).forEach((colKey) => {
          // Add column which is not to be altered
          if (colKey === columnInformation.keep) {
            formattedCells = {
              ...formattedCells,
              [colKey]: this.updateCellText(
                {
                  ...rowCells[colKey],
                  box: {
                    ...rowCells[colKey].box,
                    x: leftX,
                    width: rightX - leftX,
                  },
                },
                canvasZones
              ),
            };
          } else if (colKey !== columnInformation.remove) {
            // Add column which is not to be altered
            formattedCells = {
              ...formattedCells,
              [colKey]: this.updateCellText(rowCells[colKey], canvasZones),
            };
          }
        });

        return { ...row, cells: formattedCells };
      });

      // Merge headers in lineItem object
      const columnRefToRemove = lineItem?.headers?.find(
        (header) => header.key === columnInformation.remove
      );
      const formattedHeaders = lineItem?.headers
        ?.filter((header) => header.key !== columnInformation.remove)
        ?.map((header) =>
          header.key === columnInformation.keep
            ? {
                ...header,
                name: `${header.text} ${columnRefToRemove?.["text"] || ""}`,
                box: {
                  ...header.box,
                  x: leftX,
                  width: rightX - leftX,
                },
              }
            : header
        );

      const formattedLines = lineItem?.coords?.lines?.filter((line) => {
        return line[0][0] !== lineXToRemove && line[1][0] !== lineXToRemove;
      });

      return {
        updatedLineItem: {
          ...lineItem,
          coords: {
            ...(lineItem.coords || {}),
            lines: formattedLines,
          },
          data: formattedData,
          headers: formattedHeaders,
        },
        headers: formattedOriginalColumns,
      };
    } catch {
      return {
        updatedLineItem: lineItem,
        headers: originalColumns,
      };
    }
  };

  static isHorizontalLineBetween = (
    line: DocumentLineItemLine,
    table: DocumentLineItem,
    index: number
  ) => {
    const lineY = line[0][1];

    // find line above

    const allHorizontalLines = table.coords.lines.filter((line) =>
      this.isHorizontal(line)
    );

    //sort by y
    const sortedHorizontalLines = allHorizontalLines.sort(
      (a, b) => a[0][1] - b[0][1]
    );

    const currentTableLine = table.coords.lines[index];

    const myLineIndex = allHorizontalLines.findIndex((line) =>
      isEqual(line, currentTableLine)
    );

    let lineAbove;
    let lineBelow;

    if (myLineIndex > 0) {
      lineAbove = sortedHorizontalLines[myLineIndex - 1];
    } else {
      lineAbove = this.getTableMarginLines(table)
        .topLine as DocumentLineItemLine;
    }

    if (myLineIndex < sortedHorizontalLines.length - 1) {
      lineBelow = sortedHorizontalLines[myLineIndex + 1];
    } else {
      lineBelow = this.getTableMarginLines(table)
        .bottomLine as DocumentLineItemLine;
    }

    if (lineY > lineAbove[0][1] + 15 && lineY < lineBelow[0][1] - 15) {
      return true;
    }

    return false;
  };

  static isVerticalLineBetween = (
    line: DocumentLineItemLine,
    table: DocumentLineItem,
    index: number
  ) => {
    const lineX = line[0][0];

    const allVerticalLines = table.coords.lines.filter((line) =>
      this.isVertical(line)
    );

    const sortByX = allVerticalLines.sort((a, b) => a[0][0] - b[0][0]);

    const currentTableLine = table.coords.lines[index];

    const myLineIndex = sortByX.findIndex((line) =>
      isEqual(line, currentTableLine)
    );

    let lineLeft;
    let lineRight;

    if (myLineIndex > 0) {
      lineLeft = sortByX[myLineIndex - 1];
    } else {
      lineLeft = this.getTableMarginLines(table)
        .leftLine as DocumentLineItemLine;
    }

    if (myLineIndex < sortByX.length - 1) {
      lineRight = sortByX[myLineIndex + 1];
    } else {
      lineRight = this.getTableMarginLines(table)
        .rightLine as DocumentLineItemLine;
    }

    if (lineX > lineLeft[0][0] + 15 && lineX < lineRight[0][0] - 15) {
      return true;
    }

    return false;
  };

  static getTableMarginLines = (table: DocumentLineItem) => {
    const tableCoords = table.coords.root;
    const tableRotation = tableCoords.rotation;
    const tableX = tableCoords.x;
    const tableY = tableCoords.y;
    const tableWidth = tableCoords.width;
    const tableHeight = tableCoords.height;

    const points = ZoneHelper.getRotatedRectangleAroundTopLeftCoords(
      tableX,
      tableY,
      tableWidth,
      tableHeight,
      tableRotation
    )[0];

    const topLine = [
      [points[0][0], points[0][1]],
      [points[1][0], points[1][1]],
    ];

    const bottomLine = [
      [points[2][0], points[2][1]],
      [points[3][0], points[3][1]],
    ];

    const leftLine = [
      [points[0][0], points[0][1]],
      [points[3][0], points[3][1]],
    ];

    const rightLine = [
      [points[1][0], points[1][1]],
      [points[2][0], points[2][1]],
    ];

    return {
      topLine,
      bottomLine,
      leftLine,
      rightLine,
    };
  };

  static linkDetectedHeaders = (
    lineItem: DocumentLineItem,
    mappedKeys: { [key: string]: string }
  ): DocumentLineItem => {
    const originalMappedKeys = Object.keys(mappedKeys);

    // Change detected header key with linked flow header key
    const headers = lineItem?.headers?.map((header) => {
      const refKey = originalMappedKeys?.find(
        (originalKey) => mappedKeys[originalKey] === header.key
      );
      if (refKey) {
        return { ...header, key: refKey };
      }
      return header;
    });

    // Update each column
    const data = lineItem?.data?.map((row) => {
      const rowCells = { ...row.cells };
      let formattedRowCells = {};

      Object.keys(rowCells).forEach((detectedKey) => {
        const refKey = originalMappedKeys?.find(
          (originalKey) => mappedKeys[originalKey] === detectedKey
        );

        const keyToKeep = refKey || detectedKey;

        formattedRowCells = {
          ...formattedRowCells,
          [keyToKeep]: rowCells[detectedKey],
        };
      });

      return { ...row, cells: formattedRowCells };
    });

    return {
      ...lineItem,
      headers,
      data,
    };
  };

  static replaceData(
    lineItemHeaders: DocumentZone[],
    originalData: DocumentLineItemRow[],
    originalColumn: string,
    newColumn: string
  ): DocumentLineItemRow[] {
    let data = [] as DocumentLineItemRow[];

    // Key has been changed to GUID (in order to become ignored)
    const newOriginal = lineItemHeaders?.find(
      (header) => header.originalKey === newColumn
    );

    for (const row of originalData) {
      let formattedCells = {} as Record<string, DocumentZone>;

      Object.keys(row.cells).map((cellKey) => {
        // Change key for original column
        if (cellKey === originalColumn) {
          formattedCells = {
            ...formattedCells,
            [newColumn]: {
              ...row.cells?.[cellKey],
              key: newColumn,
            },
          };
        }
        // Original column is configured, then replace original and ignore newColumn
        else if (cellKey === newColumn && newOriginal) {
          formattedCells = {
            ...formattedCells,
            [newOriginal?.key as string]: {
              ...row.cells?.[cellKey],
              key: newOriginal?.key as string,
            },
          };
        } else {
          // Add rest
          formattedCells = {
            ...formattedCells,
            [cellKey]: row.cells?.[cellKey],
          };
        }
      });

      data = [
        ...data,
        {
          ...row,
          cells: formattedCells,
        },
      ];
    }

    return data;
  }

  static getTargetNodeCoords(
    targetCoords: DocumentZoneBox,
    currentScale: number,
    canvasDimensions: { width: number; height: number },
    rotation: number
  ): { x: number; y: number } {
    let x = 0;
    let y = 0;

    switch (rotation) {
      case 0: {
        x = -(
          (targetCoords.x + targetCoords.width / 2) * currentScale -
          canvasDimensions.width / 2
        );
        y = -(
          (targetCoords.y + targetCoords.height / 2) * currentScale -
          canvasDimensions.height / 2
        );
        break;
      }
      case 90: {
        x =
          (targetCoords.y + targetCoords.width / 2) * currentScale +
          canvasDimensions.width / 2;
        y = -(
          (targetCoords.x + targetCoords.height / 2) * currentScale -
          canvasDimensions.height / 2
        );
        break;
      }
      case 180: {
        x =
          (targetCoords.x + targetCoords.width / 2) * currentScale +
          canvasDimensions.width / 2;
        y =
          (targetCoords.y + targetCoords.height / 2) * currentScale +
          canvasDimensions.height / 2;
        break;
      }
      case 270: {
        x = -(
          (targetCoords.y + targetCoords.width / 2) * currentScale -
          canvasDimensions.width / 2
        );
        y =
          (targetCoords.x + targetCoords.height / 2) * currentScale +
          canvasDimensions.height / 2;
        break;
      }
      default:
    }

    return { x, y };
  }
}
