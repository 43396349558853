import { DateRange } from "@mui/lab";
import AmazonIcon from "../icons/AmazonIcon";
import AzureIcon from "../icons/AzureIcon";
import GoogleIcon from "../icons/GoogleIcon";
import { DocumentsFilterProps } from "./interfaces";
import FTPIcon from "../icons/FTPIcon";
import SFTPIcon from "../icons/SFTPIcon";
import { Email } from "@mui/icons-material";
import DropboxIcon from "../icons/DropboxIcon";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import CloudIcon from "@mui/icons-material/Cloud";
import OpenAiIcon from "../icons/OpenAiIcon";

export enum THEME_MODES {
  light = "light",
  dark = "dark",
  mono = "mono",
}

export enum THEME_MODES_RO {
  light = "deschis",
  dark = "închis",
  mono = "mediu",
}

export enum FIELD_TYPE {
  input = "input",
  textarea = "textarea",
  number = "number",
  select = "select",
  checkbox = "checkbox",
  switch = "switch",
  password = "password",
  json = "json",
  googleDriveConnection = "googleDriveConnection",
  oneDriveConnection = "oneDriveConnection",
  dropboxConnection = "dropboxConnection",
  autocomplete = "autocomplete",
  autocompleteNoAdd = "autocompleteNoAdd",
  fileUpload = "fileUpload",
  exportFields = "exportFields",
  fileName = "fileName",
  filePath = "filePath",
  fields = "fields",
  code = "code",
  nodeInput = "nodeInput",
  nodeOutput = "nodeOutput",
  list = "list",
  inputList = "inputList",
  conditional = "conditional",
}

export enum VALIDATION_MODES {
  simple = "simple",
  advanced = "advanced",
}

export enum CANVAS_ACTIONS {
  pan = "pan",
  drawNewZone = "drawNewZone",
  zoomIn = "zoomIn",
  zoomOut = "zoomOut",
  resetCanvas = "resetCanvas",
  rotateRight = "rotateRight",
  rotateLeft = "rotateLeft",
  fitWidth = "fitWidth",
  fitHeight = "fitHeight",
}

export enum IMAGE_SIZE {
  original = "original",
  high = "high",
  thumbnail = "thumbnail",
  preview = "preview", // FIXME: Remvoe from flow, NOT used
}

export const CANVAS_ACTIONS_VIEW_MODE: Array<CANVAS_ACTIONS> = [
  CANVAS_ACTIONS.pan,
  CANVAS_ACTIONS.zoomIn,
  CANVAS_ACTIONS.zoomOut,
];

export const CANVAS_ACTIONS_EDIT_MODE: Array<CANVAS_ACTIONS> = [
  CANVAS_ACTIONS.pan,
  CANVAS_ACTIONS.drawNewZone,
  CANVAS_ACTIONS.zoomIn,
  CANVAS_ACTIONS.zoomOut,
];

export const CANVAS_EXTRA_INSTRUMENTS_ROTATE_ACTIONS: Array<CANVAS_ACTIONS> = [
  CANVAS_ACTIONS.resetCanvas,
  CANVAS_ACTIONS.rotateLeft,
  CANVAS_ACTIONS.rotateRight,
];

export const CANVAS_EXTRA_INSTRUMENTS_FIT_ACTIONS: Array<CANVAS_ACTIONS> = [
  CANVAS_ACTIONS.fitWidth,
  CANVAS_ACTIONS.fitHeight,
];

export const CANVAS_CURSOR_STYLES = {
  [CANVAS_ACTIONS.pan]: "grab",
  [CANVAS_ACTIONS.drawNewZone]: "auto",
  [CANVAS_ACTIONS.zoomIn]: "zoom-in",
  [CANVAS_ACTIONS.zoomOut]: "zoom-out",
  [CANVAS_ACTIONS.resetCanvas]: "auto",
};

export const EDITABLE_ZONE_ACTIONS = [
  // CANVAS_ACTIONS.pan,
  CANVAS_ACTIONS.drawNewZone,
];

export const NONEDITABLE_ZONE_ACTIONS = [
  CANVAS_ACTIONS.pan,
  // CANVAS_ACTIONS.drawNewZone,
];

export const CANVAS_COORDS_RULE = {
  width: 20,
  height: 10,
};

export const CANVAS_LINE_ITEMS_COORDS_RULE = {
  width: 400,
  height: 50,
};

export enum TABLE_VARIANTS {
  outlined = "outlined",
  default = "default",
}

export const DOCUMENT_COLUMNS = {
  name: "Name",
  statusInfo: "Status Info",
  active: "Active",
  createdDate: "Created Date",
} as { [key: string]: string };

export const DOCUMENT_FILTERS = {
  name: "Name",
  numberOfPages: "Number of pages",
  createdAt: "Created at",
  updatedAt: "Updated at",
  status: "Status",
} as { [key: string]: string };

export const DOCUMENT_FILTERS_ALL_FLOWS = {
  flowName: "Flow Name",
  name: "Name",
  createdAt: "Created at",
  updatedAt: "Updated at",
  status: "Status",
} as { [key: string]: string };

export const DOCUMENT_FILTERS_RO = {
  name: "Nume document",
  numberOfPages: "Număr de pagini",
  createdAt: "Creat la",
  updatedAt: "Actualizat la",
  status: "Status",
} as { [key: string]: string };

export const DOCUMENT_FILTERS_ALL_FLOWS_RO = {
  flowName: "Nume flow",
  name: "Nume document",
  createdAt: "Creat la",
  updatedAt: "Actualizat la",
  status: "Status",
} as { [key: string]: string };

export enum DOCUMENT_STATUS {
  created = "created",
  running = "running",
  waiting = "waiting",
  error = "error",
  rejected = "rejected",
  requiresUserInput = "requiresUserInput",
  done = "done",
}

export enum FILTER_TYPE_KEYS {
  filter_all = "filter_all",
  filter_review = "filter_review",
  filter_waiting = "filter_waiting",
  filter_error = "filter_error",
  filter_rejected = "filter_rejected",
  filter_completed = "filter_completed",
}

export const FILTER_STATUS = {
  [FILTER_TYPE_KEYS.filter_all]: [
    DOCUMENT_STATUS.created,
    DOCUMENT_STATUS.running,
    DOCUMENT_STATUS.waiting,
    DOCUMENT_STATUS.error,
    DOCUMENT_STATUS.rejected,
    DOCUMENT_STATUS.requiresUserInput,
    DOCUMENT_STATUS.done,
  ],
  [FILTER_TYPE_KEYS.filter_error]: [DOCUMENT_STATUS.error],
  [FILTER_TYPE_KEYS.filter_completed]: [DOCUMENT_STATUS.done],
  [FILTER_TYPE_KEYS.filter_rejected]: [DOCUMENT_STATUS.rejected],
  [FILTER_TYPE_KEYS.filter_review]: [DOCUMENT_STATUS.requiresUserInput],
  [FILTER_TYPE_KEYS.filter_waiting]: [DOCUMENT_STATUS.waiting],
};

export const HOVER_COLUMNS = {
  identifier: "identifier",
  name: "name",
  select: "select",
  actions: "actions",
  flowName: "flowName",
};

export const DATE_FORMAT = {
  date: "DD/MM/YYYY",
  dateTime: "DD/MM/YYYY hh:mm A",
};

export const INPUT_TYPES = {
  input: "input",
  password: "password",
  number: "number",
};
export enum FlowEnum {
  identifier = "identifier",
  name = "name",
  key = "key",
  category = "category",
  description = "description",
  isCustom = "isCustom",
  threshold = "threshold",
  fieldType = "fieldType",
  lineItems = "Line Items",
  table = "table",
  fieldFormat = "fieldFormat",
  dropdownInput = "dropdownInput",
  labels = "labels",
  string = "string",
  int = "integer",
  float = "float",
  date = "date",
  mergeTechnique = "mergeTechnique",
  manualValidate = "manualValidate",
  dataRetention = "dataRetention",
  deleteDoc = "deleteDoc",
  timeToKeep = "timeToKeep",
  deleteLocalFile = "deleteLocalFile",
  customRepo = "customRepo",
  rootPath = "rootPath",
  flow = "flow",
  connectionId = "connectionId",
  extraction = "extraction",
  dynamic = "dynamic",
  default = "default",
  emptyPath = "/",
}

export const DEFAULT_TABLE_ROWS = 10;

export const DEFAULT_CANVAS_DIMENSIONS = {
  width: 1160,
  height: 730,
};

export enum HeaderSize {
  small = "small",
  large = "large",
}

export const APP_IDS = {
  // Y axe
  appTopBar: "app-top-bar",
  appToolbar: "app-toolbar",
  lineItemTable: "lineItemTable",
  // X axe
  sidebar: "app-sidebar",
  canvasContainer: "canvasContainer",
  canvasToolbar: "canvasToolbar",
  thumbnailPreviewer: "thumbnailPreviewer",
  docEditor: "document-editor",
};

export const APP_SIDEBAR_WIDTH = 275;

export const DOCUMENT_STATUSES = {
  running: "running",
  requiresUserInput: "requiresUserInput",
  error: "error",
  done: "done",
};

export const LOCAL_STORAGE_KEYS = {
  expandedSidebar: "expandedSidebar",
  selectedTheme: "selectedTheme",
  validationFitAction: "validationFitAction",
  validationScaleFactor: "validationScaleFactor",
  prevPageRoot: "prev-page-root",
  appSettings: "appSettings",
  flowSettings: "flowSettings",
  activityLogsPagination: "activityLogsPagination",
};

// for the edit user drawer

export const LINK_LIFETIME_OPTIONS_GB = [
  {
    key: "1d",
    label: "One day",
  },
  {
    key: "7d",
    label: "One week",
  },
  {
    key: "30d",
    label: "One month",
  },
];

export const LINK_LIFETIME_OPTIONS_RO = [
  {
    key: "1d",
    label: "O zi",
  },
  {
    key: "7d",
    label: "O săptămână",
  },
  {
    key: "30d",
    label: "O lună",
  },
];

export enum ASSET_TYPES {
  dataSource = "dataSource",
  dataExport = "dataExport",
  enhancements = "enhancements",
  approvals = "approvals",
  algorithms = "dataAlgorithms",
}

export const CONTEXT_LABEL = "Context";

export enum NODE_ASSET_TYPE {
  node = "node",
  startNode = "startNode",
  nodeInputSpecs = "nodeInputSpecs",
  initializeVariables = "initializeVariables",
  dataEncoding = "dataEncodings",
  nodeCategory = "nodeCategory",
  approvalRules = "approvalRules",
  conditional = "conditional",
  whileIteration = "whileIteration",
  claritextOcr = "claritextOcr",
  parallelIteration = "parallelIteration",
}

export enum EXTENDED_ASSET_TYPES {
  apiSamples = "apiSamples",
}

export enum SETTINGS_TYPES {
  indexing = "indexing",
  contextSettings = "contextSettings",
  indexingSettings = "indexingSettings",
}

export const ASSET_PROPERTIES = {
  [ASSET_TYPES.dataExport]: "dataExports",
  [ASSET_TYPES.dataSource]: "sources",
  [ASSET_TYPES.enhancements]: "rules",
  [ASSET_TYPES.approvals]: "approvals",
  [ASSET_TYPES.algorithms]: "algorithms",
};

export enum ASSET_BACKEND_TYPES {
  approval = "approval",
  enhancement = "enhancement",
}

export const STRATEGY_OPTIONS = [
  { key: "random", label: "Random" },
  { key: "balancedByUser", label: "Balanced by User" },
  { key: "balancedByFlow", label: "Balanced by Flow" },
];

export const STRATEGY_OPTIONS_RO = [
  { key: "random", label: "Aleatoriu" },
  { key: "balancedByUser", label: "Echilibrat de utilizator" },
  { key: "balancedByFlow", label: "Echilibrat de Flow" },
];

export const DEFAULT_FILTER_FLOW = {
  sortBy: "createdAt",
  sortDirection: "desc",
  name: [],
  status: FILTER_STATUS[FILTER_TYPE_KEYS.filter_all],
  fields: [],
  categories: [],
  metadata: [],
  date: {
    startDate: null,
    endDate: null,
  },
  manuallyUpdated: undefined,
};

export const DEFAULT_FILTER_ALL_FLOWS = {
  sortBy: "createdAt",
  sortDirection: "desc",
  name: [],
  flows: [],
  status: FILTER_STATUS[FILTER_TYPE_KEYS.filter_all],
  fields: [],
  categories: [],
  metadata: [],
  date: {
    startDate: null,
    endDate: null,
  },
  manuallyUpdated: undefined,
} as DocumentsFilterProps;

export const ICONS: Record<string, unknown> = {
  sftp: SFTPIcon,
  s3: AmazonIcon,
  azure: AzureIcon,
  google: GoogleIcon,
  ftp: FTPIcon,
  email: Email,
  googleDrive: AddToDriveIcon,
  oneDrive: CloudIcon,
  dropbox: DropboxIcon,
  azureOpenAi: OpenAiIcon,
  openAi: OpenAiIcon,
};

export enum DRAWER_STATE {
  open = "open",
  close = "close",
}

export enum SORT_DIRECTION {
  asc = "asc",
  desc = "desc",
}

export enum HISTORY_ACTIONS {
  push = "PUSH",
  pop = "POP",
}

export const ENTER_KEYS = ["Enter", "NumpadEnter"];

export enum REASONS_FIELDS {
  code = "code",
  message = "message",
  description = "description",
}

export enum APPROVAL_FIELDS {
  name = "name",
  rules = "rules",
}

export enum APPROVAL_OPERATOR {
  or = "or",
  and = "and",
}

export const APPROVAL_OPERATOR_OPTIONS = [
  {
    key: APPROVAL_OPERATOR.or,
    label: "OR",
  },
  {
    key: APPROVAL_OPERATOR.and,
    label: "AND",
  },
];

export enum RULE_FIELDS {
  field = "field",
  condition = "condition",
  value = "value",
}

export enum RULE_FIELD {
  invoiceIdentifier = "invoice identifier",
  dueDate = "due date",
  total = "total",
}

export const RULE_FIELD_OPTIONS = [
  {
    key: RULE_FIELD.invoiceIdentifier,
    label: "Invoice identifier",
  },
  {
    key: RULE_FIELD.dueDate,
    label: "Due date",
  },
  {
    key: RULE_FIELD.total,
    label: "Total",
  },
];

export enum RULE_CONDITION {
  isGreater = ">",
  isSmaller = "<",
  isEqual = "=",
}

export const RULE_CONDITION_OPTIONS = [
  {
    key: RULE_CONDITION.isGreater,
    label: "Is greater",
  },
  {
    key: RULE_CONDITION.isSmaller,
    label: "Is smaller",
  },
  {
    key: RULE_CONDITION.isEqual,
    label: "Is equal",
  },
];

export enum STRING_BOOLEANS {
  true = "true",
  false = "false",
}

export enum CANVAS_ATTRS_AND_ACTIONS {
  document = "document",
  keyUp = "keyup",
  keyDown = "keydown",
  resize = "resize",
  delete = "Delete",
}

export enum VAL_TYPES {
  string = "string",
  number = "number",
  boolean = "boolean",
  integer = "integer",
  text = "text",
  array = "array",
  object = "object",
  null = "null",
  undefined = "undefined",
}

export enum STORAGE_DEFAULT_FIELDS {
  name = "name",
  type = "type",
  description = "description",
  default = "default",
  dynamic = "dynamic",
}

export enum OTHER_ENUMS {
  type = "type",
}

// Document history
export const HISTORY_EVENT_TYPES = {
  UPDATE_METADATA: "UPDATE_METADATA",
  STATUS_UPDATE: "STATUS_UPDATE",
  IMPORT: "IMPORT",
  DISCOVER: "DISCOVER",
  UPDATE_OCR: "UPDATE_OCR",
  EXTRACT_PAGES: "EXTRACT_PAGES",
  DELETE_PAGES: "DELETE_PAGES",
  EXPORT_DOCUMENT: "EXPORT_DOCUMENT",
  MOVE_DOCUMENT: "MOVE_DOCUMENT",
  LOCK_UNLOCK: "LOCK_UNLOCK",
  ASSIGNMENT: "ASSIGNMENT",
  REORDER_PAGES: "REORDER_PAGES",
};

export enum USER_STATUS {
  Created = 0,
  Active = 1,
  Suspended = 2,
}

export interface AppSettings {
  recaptchaSiteKey: string;
  googleClientId: string;
  googleDeveloperKey: string;
  dropboxAppKey: string;
  onedriveRedirectUriFrontend: string;
  onedriveClientId: string;
}

export const DRAWER_WIDTH = {
  small: 450,
  medium: 600,
  large: 900,
  extraLarge: 1200,
};

export const PRIVATE_ASSETS = [
  "oneDriveConnection",
  "googleDriveConnection",
  "dropboxConnection",
];

export interface ActivityLogsFilters {
  dateFilter: DateRange<Date> | null;
  flowsFilter: string[];
  statusFilter: string[];
  eventTypeFilter: string[];
  inputSearchFilter: string[];
}

export const DEFAULT_ACTIVITY_LOGS_FILTERS: ActivityLogsFilters = {
  dateFilter: null,
  flowsFilter: [],
  statusFilter: [],
  eventTypeFilter: [],
  inputSearchFilter: [],
};

export enum FILTER_PROPS {
  sortBy = "sortBy",
  sortDirection = "sortDirection",
  name = "name",
  status = "status",
  metadata = "metadata",
  fields = "fields",
  categories = "categories",
  flows = "flows",
  date = "date",
  dateFilter = "dateFilter",
  manuallyUpdated = "manuallyUpdated",
}

export const CATEGORY_HEADERS = [
  {
    accessor: "id",
    label: "category_apiKey",
  },
  {
    accessor: "name",
    label: "category_name",
  },
  {
    accessor: "description",
    label: "category_description",
  },
  {
    accessor: "labels",
    label: "category_labels",
  },
  {
    accessor: "multipleSelect",
    label: "category_checkbox",
  },
  {
    accessor: "optional",
    label: "category_optional",
  },
  {
    accessor: "edit",
    label: "",
  },
];

export const EXPORT_HEADERS = [
  {
    label: "Export",
    accessor: "willExport",
  },
  {
    label: "Source Field",
    accessor: "name",
  },
  {
    label: "Target",
    accessor: "exportName",
  },
];

export enum DYNAMIC_FIELDS {
  userInput = "userInput",
  property = "property",
  flowFields = "flowField",
  flowCategory = "flowCategory",
}

export const dataTypes = {
  numberDataType: "number",
  textDataType: "string",
  dateDataType: "date",
};

export enum DOCUMENT_UPLOAD_STATUS {
  draft = "draft",
  upload_running = "upload_running",
  upload_failed = "upload_failed",
  upload_success = "upload_success",
}

export const LINE_DISTANCE = 5;

// Value used to change column to hovered style
export const LINE_SPACING = 20;

// Value used to test if the user is near a line to delete
export const DELETE_LINE_RANGE = 5;

export const PAGE_SIZES = [
  {
    key: "5",
    label: "5",
  },
  {
    key: "10",
    label: "10",
  },
  {
    key: "20",
    label: "20",
  },
  {
    key: "50",
    label: "50",
  },
];

export const emptyBox = {
  box: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
  },
};

// TODO: Add more permissions

export const NAMESPACE_PERMISSIONS = {
  activityLogs: ["read"],
  apiKeys: ["create", "read", "update", "delete"],
  connections: ["create", "read", "update", "delete"],
  flows: [
    "create",
    "read",
    "update",
    "delete",
    "importDocuments",
    "exportDocuments",
    "deleteDocuments",
    "validateDocuments",
    "updateDocuments",
  ],
  subscription: ["read", "update"],
  //FIXME: Keep for later user
  // qa: ["assign", "validate"],
  // statistics: ["read"],
  users: ["read", "invite", "update", "delete"],
};

export const LANGUAGES = [
  //FIXME: Keep for later user { code: "ro", name: "Română" },
  { code: "en", name: "English" },
];

export const RERUN_STATUSES_LIMIT = [DOCUMENT_STATUS.running];

export enum USER_SCOPES {
  user = "User",
  owner = "Owner",
}
export const WEEK_DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

export const WEEK_DAYS_RO = ["Du", "Lu", "Ma", "Mi", "Jo", "Vi", "Sâ"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTHS_RO = [
  "Ianuarie",
  "Februarie",
  "Martie",
  "Aprilie",
  "Mai",
  "Iunie",
  "Iulie",
  "August",
  "Septembrie",
  "Octombrie",
  "Noiembrie",
  "Decembrie",
];

export enum FLOW_DATA_TYPES_FRONTEND {
  dataSources = "data-sources",
  dataExports = "data-exports",
}

export const FLOW_DATA_TYPES_BACKEND = {
  [FLOW_DATA_TYPES_FRONTEND.dataSources]: "dataSource",
  [FLOW_DATA_TYPES_FRONTEND.dataExports]: "dataExport",
};

export enum FLOW_DATA_TABLE_ACTIONS {
  edit = "edit",
  runNow = "runNow",
  pause = "pause",
  resume = "resume",
  delete = "delete",
  viewLogs = "viewLogs",
}

export const VALID_SETTINGS_URLS = [
  "/settings/account",
  "/settings/team",
  "/settings/billing",
  "/settings/subscription/upgrade",
  "/settings/invoices",
  "/settings/rejections",
  "/settings/connections",
  "/settings/indexing",
  "/settings/api-keys",
  "/settings/dev-settings",
];

export const ChatGptTabs = {
  home: "home",
  newChat: "newChat",
  clariChat: "clariChat",
};

export const CHAT_GPT_MESSAGE_TYPES = {
  user: "user",
  claritext: "claritext",
};

export const FLOW_SETTINGS_TABS = {
  overview: "overview",
  accessControl: "accessControl",
  designer: "designer",
  versions: "versions",
  dataSource: "dataSource",
  dataExport: "dataExport",
  repository: "repository",
  rejectReasons: "rejectReasons",
  accessKeys: "accessKeys",
  alerts: "alerts",
  logs: "logs",
  metrics: "metrics",
};

export const FLOW_SETTINGS_CATEGORY = {
  developmentTools: "developmentTools",
  repositoryTools: "repositoryTools",
  monitoring: "monitoring",
};

export const DIAGRAM_NODE_CATEGORIES = {
  start: "start",
  // TODO: Remove this type
  diagramNode: "diagramNode",
  group: "group",
  placeholder: "placeholder",
  node: "node",
};

export enum NODE_CONDITIONAL_BRANCHES {
  falseBranch = "falseBranch",
  trueBranch = "trueBranch",
}

export const DIAGRAM_NODE_DELETE_MODES = {
  node: "node",
  hierarchy: "hierarchy",
  groupChildren: "groupChildren",
};

export enum NODE_TYPES {
  input = "input",
  output = "output",
  passthrough = "passthrough",
  group = "group",
}

export const GROUP_NODE_HEIGHT = 40;
export const GROUP_NODE_WIDTH = 150;

// FIXME: This property is for testing only, will be removed after group is fully implemented
export const GROUP_NODE_HEIGHT_TEST = 300;

export enum NODE_HANDLE_TYPES {
  target = "target",
  source = "source",
}

export const SuggestionsModalTabs = {
  function: "function",
  dynamicContent: "dynamicContent",
};

export const START_NODE_STRUCTURE = {
  identifier: NODE_ASSET_TYPE.startNode,
  type: DIAGRAM_NODE_CATEGORIES.start,

  name: "Start",
  description: "Start node",

  id: NODE_ASSET_TYPE.startNode,
  key: NODE_ASSET_TYPE.startNode,
  position: { x: 0, y: 0 },
  data: {
    value: "Start",
    type: NODE_TYPES.input,
    identifier: NODE_ASSET_TYPE.startNode,
  },
  parameters: {},
};

export enum ContextVariableEnum {
  key = "key",
  name = "name",
  type = "type",
}

export enum JSON_EDITOR_TYPES {
  object = "object",
  list = "list",
  ocrObject = "ocrObject",
}

export enum NodeInputOutputCategories {
  flow = "flow",
  document = "document",
  contextVariables = "contextVariables",
}

export enum VariablesAndFields {
  variables = "variables",
  fields = "fields",
}

export enum NodeInputAllowedTypes {
  text = "text",
  int = "int",
  bool = "bool",
  float = "float",
  date = "date",
  object = "object",
  list = "list",
}

export const SCHEMA_ERROR_TYPES = {
  invalidSchema: "invalidSchema",
  missingProperties: "missingProperties",
  missingItems: "missingItems",
  missingSchema: "missingSchema",
  invalidJSON: "invalidJSON",
  invalidObject: "invalidObject",
  invalidArraySchema: "invalidArraySchema",
};

export const NODES_PARAMETERS_KEYS = {
  inputVariables: "inputVariables",
  executionSchema: "executionSchema",
};

export const NODES_ALLOWED_TYPES = {
  any: "any",
  iteration: "iteration",
};
