import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { Box, Divider } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";

import CoreTabContainer from "../../../../core/CoreTabContainer";
import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import CoreNotificationBox from "../../../../core/CoreNotificationBox";
import CoreCardFlow from "../../../../core/CoreCardFlow";
import {
  NotificationTypes,
  useNotification,
} from "../../../../../context/useNotification";
import {
  CardActions,
  AssetConfiguration,
  FlowInterface,
} from "../../../../../types/interfaces";
import { ASSET_TYPES } from "../../../../../types/constants";
import AssetsDrawer from "../../../addNewFlow/tabs/assetsCore/AssetsDrawer";
import CoreConfirmModal from "../../../../core/CoreConfirmModal";
import CoreButton from "../../../../core/CoreButton";
import { AppSuspense } from "../../../../main/AppSuspense";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
}

const useStyles = makeStyles({
  mainContainer: {
    maxWidth: "800px",
    minHeight: "90px",
  },
  button: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonBox: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
});

const DataExportTab: React.FC<Props> = observer(({ t, flowSettingsStore }) => {
  const [flowDataExport, setFlowDataExport] = useState<AssetConfiguration[]>(
    []
  );
  const [deletableItemIndex, setDeletableItemIndex] = useState<
    number | undefined
  >();
  const [selectedItemIndex, setSelectedItemIndex] = useState<
    number | undefined
  >();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOnConfig, setIsOnConfig] = useState<boolean | null>(null);
  const classes = useStyles();
  const notification = useNotification();
  const toggleDrawer = (value: boolean) => {
    setIsDrawerOpen(value);
    if (!value) setSelectedItemIndex(undefined);
    if (selectedItemIndex === undefined) setIsOnConfig(false);
  };

  const handleClickEdit = useCallback((args: string[]) => {
    const [index] = args;

    if (parseInt(index) !== -1) {
      setSelectedItemIndex(parseInt(index));
      setIsOnConfig(true);
      toggleDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFlowDataExport(flowSettingsStore.flow?.dataExport?.dataExports ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowSettingsStore.flow?.dataExport?.dataExports]);

  const handleClickDelete = useCallback((args: string[]) => {
    const [index] = args;
    const intIndex = parseInt(index);
    const item = flowDataExport?.find((_, i) => i === intIndex);

    if (item?.canDelete && !item.canDelete.enabled) {
      notification.error(t(item?.canDelete.message ?? ""));
      return;
    }

    if (intIndex !== -1) {
      setDeletableItemIndex(parseInt(index));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listActions = useMemo(
    () =>
      [
        {
          tooltip: "edit_tooltip",
          icon: <EditIcon />,
          onClick: handleClickEdit,
          label: t("editLabel"),
        },
        {
          tooltip: "delete_tooltip",
          icon: <DeleteIcon />,
          onClick: handleClickDelete,
          label: t("delete"),
        },
      ] as unknown as CardActions[],
    [handleClickDelete, handleClickEdit, t]
  );

  const handleConfirmDelete = () => {
    const updatedDataExports = flowDataExport.filter(
      (_, index) => index !== deletableItemIndex
    );

    if (deletableItemIndex !== undefined && deletableItemIndex !== -1)
      flowSettingsStore
        .updateFlow({
          dataExport: {
            ...flowSettingsStore.flow?.dataExport,
            dataExports: updatedDataExports,
          },
        } as FlowInterface)
        .then(() => {
          setDeletableItemIndex(undefined);
          notification.success(t("updateFlowSuccess"));
          flowSettingsStore.triggerRefetchFlowSettings(true);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "updateFlowError"));
        });
  };

  useEffect(() => {
    if (selectedItemIndex !== undefined) {
      setIsOnConfig(true);
    }
  }, [selectedItemIndex]);

  return (
    <CoreTabContainer t={t} title={t(flowSettingsStore.selectedTab)}>
      {flowSettingsStore.loadingFlowSettings ? (
        <AppSuspense />
      ) : (
        <Box className={classes.mainContainer}>
          <CoreNotificationBox
            description={t("dataExport_description")}
            type={NotificationTypes.info}
          >
            <Box className={classes.buttonBox}>
              <Divider orientation="vertical" />

              <CoreButton
                variant="contained"
                onClick={() => toggleDrawer(true)}
                className={classes.button}
              >
                {t("dataExport_add")}
              </CoreButton>
            </Box>
          </CoreNotificationBox>

          <AssetsDrawer
            t={t}
            type={ASSET_TYPES.dataExport}
            selectedItemPosition={selectedItemIndex}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            isOnConfig={isOnConfig}
            setIsOnConfig={setIsOnConfig}
          />

          {flowDataExport &&
            flowDataExport?.length > 0 &&
            flowDataExport?.map((dataExport, index) => (
              <CoreCardFlow
                t={t}
                key={`${index}-${dataExport.key}`}
                title={dataExport.name}
                subtitle={dataExport.description}
                listActions={listActions}
                identifier={[index.toString()]}
              />
            ))}

          <CoreConfirmModal
            open={deletableItemIndex !== undefined && !isDrawerOpen}
            onClose={() => setDeletableItemIndex(undefined)}
            title={
              deletableItemIndex !== undefined
                ? t("modal_title", {
                    modalTitle: flowDataExport?.[deletableItemIndex]?.name,
                  })
                : ""
            }
            subtitle={t("modal_subtitle_delete", {
              title: t(`${ASSET_TYPES.dataExport}_title`),
            })}
            onCancel={() => setDeletableItemIndex(undefined)}
            onConfirm={handleConfirmDelete}
            cancelButtonLabel={t("cancelButtonLabel")}
            confirmButtonLabel={t("confirmButtonLabel")}
            isDisable={flowSettingsStore.loadingUpdateFlow}
          />
        </Box>
      )}
    </CoreTabContainer>
  );
});

export default DataExportTab;
