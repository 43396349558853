import React from "react";
import { TFunction } from "i18next";
import Moment from "react-moment";

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { DocumentHistory } from "../../../../types/interfaces";
import { DATE_FORMAT, HISTORY_EVENT_TYPES } from "../../../../types/constants";
import { CoreStatusTag } from "../../../core/CoreStatusTag";
import CoreTextLink from "../../../core/CoreTextLink";
import { useStores } from "../../../../stores/StoresProvider";

interface Props {
  translation: TFunction;
  history: DocumentHistory;
  displayEventDetails: () => void;
}

const ACTION_LABELS = {
  [HISTORY_EVENT_TYPES.UPDATE_METADATA]:
    "history_metadata_update_metadata_label",
  [HISTORY_EVENT_TYPES.STATUS_UPDATE]: "history_metadata_status_update_label",
  [HISTORY_EVENT_TYPES.IMPORT]: "history_metadata_import_label",
  [HISTORY_EVENT_TYPES.DISCOVER]: "history_metadata_discover_label",
  [HISTORY_EVENT_TYPES.UPDATE_OCR]: "history_metadata_update_ocr_label",
  [HISTORY_EVENT_TYPES.EXTRACT_PAGES]: "history_metadata_extract_pages_label",
  [HISTORY_EVENT_TYPES.DELETE_PAGES]: "history_metadata_delete_pages_label",
  [HISTORY_EVENT_TYPES.EXPORT_DOCUMENT]:
    "history_metadata_export_document_label",
  [HISTORY_EVENT_TYPES.MOVE_DOCUMENT]: "history_metadata_move_document_label",
  [HISTORY_EVENT_TYPES.LOCK_UNLOCK]: "history_metadata_lock_unlock_label",
  [HISTORY_EVENT_TYPES.ASSIGNMENT]: "history_metadata_assignment_label",
  [HISTORY_EVENT_TYPES.REORDER_PAGES]: "history_metadata_reorder_pages_label",
} as { [key: string]: string };

// FIXME: Add more events here in order to enable -View details- action
const EVENT_WITH_DETAILS = [HISTORY_EVENT_TYPES.UPDATE_METADATA];

const HistoryEvent: React.FC<Props> = ({
  translation,
  history,
  displayEventDetails,
}) => {
  const { userStore } = useStores();

  const theme = useTheme();

  const useStyles = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    time: {
      fontSize: "11px",
      marginLeft: "10px",
      color: theme.palette.text.disabled,
    },
    userStatusBox: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    userContainer: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "10px",
    },
    statusUpdateBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "10px",
    },
    statusUpdateIcon: {
      marginLeft: "5px",
      marginRight: "5px",
    },
    moreDetails: {
      fontWeight: "normal",
      fontStyle: "italic",
      fontSize: "13px",
      border: "none",
      "&:hover": {
        textDecoration: "underline",
        border: "none",
      },
    },
  });

  const classes = useStyles();

  const userName =
    history.userId && history.user
      ? `${history.user.first_name} ${history.user.last_name}`
      : history.userId
      ? translation("user")
      : translation("claritext");

  const isStatusChangeReceived =
    history && history.statusDetails && history?.statusDetails?.["new"];

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.userStatusBox}>
        <Typography className={classes.time}>
          <Moment date={history.time} format={DATE_FORMAT.dateTime} />
        </Typography>

        <Box className={classes.userContainer}>
          <div>
            {userStore.currentUserPermissions?.can("read", "activityLogs") ? (
              <CoreTextLink
                to={`/activity-log/${history.eventIdentifier}`}
                label={`${userName} ${translation(
                  ACTION_LABELS[history.actionCode] ||
                    ACTION_LABELS.UPDATE_METADATA
                )}`}
              />
            ) : (
              <Typography>
                {`${userName} ${translation(
                  ACTION_LABELS[history.actionCode] ||
                    ACTION_LABELS.UPDATE_METADATA
                )}`}
              </Typography>
            )}
          </div>
        </Box>

        {isStatusChangeReceived && (
          <Box className={classes.statusUpdateBox}>
            <CoreStatusTag
              label={history?.statusDetails?.old || "-"}
              type={history?.statusDetails?.old || "-"}
            />

            <ArrowRightAltIcon className={classes.statusUpdateIcon} />

            <CoreStatusTag
              label={history?.statusDetails?.new}
              type={history?.statusDetails?.new}
            />
          </Box>
        )}
      </Box>

      {EVENT_WITH_DETAILS.includes(history.actionCode) &&
        userStore.currentUserPermissions?.can("read", "activityLogs") && (
          <Box>
            <Button
              className={classes.moreDetails}
              onClick={displayEventDetails}
            >
              {translation("history_more_details_action")}
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default HistoryEvent;
