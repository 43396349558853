import {
  DocumentLineItem,
  FlowField,
  DocumentZone,
  CheckedStatuses,
} from "../types/interfaces";
import { FIELD_DATA_TYPES } from "../types/enums";
import TextHelper from "./textHelper";

export default class DocumentHelper {
  static checkFilledFields(
    lineItems: DocumentLineItem[],
    fields: FlowField[],
    documentFields: DocumentZone[]
  ): string[] {
    const emptyFields: string[] = [];

    fields?.forEach((field) => {
      if (field.source === "enhancement") return;

      switch (field.dataType?.key) {
        case FIELD_DATA_TYPES.tableDataType: {
          if (!field.isMandatory) {
            return;
          }

          const headers = field?.dataType?.parameters?.headers as FlowField[];

          const tableData = lineItems
            ?.find((line) => line.type === field.key)
            ?.data?.filter((row) => !row.isIgnored);

          if (!tableData || tableData?.length === 0) {
            emptyFields.push(field.key);
            return;
          }

          const emptyHeaders = headers.filter(
            (header) =>
              header.isMandatory &&
              header.source !== "enhancement" &&
              tableData.some(
                (row) =>
                  !row.cells[header.key] ||
                  row.cells[header.key].text.trim() === ""
              )
          );

          if (emptyHeaders && emptyHeaders.length > 0) {
            emptyFields.push(
              `${field.name} (${emptyHeaders
                .map((item) => TextHelper.capitalizeFirstLetter(item.name))
                .join(", ")})`
            );
          }
          break;
        }

        default: {
          const fieldValue = documentFields?.find(
            (docField) => field.key === (docField.key || docField.type)
          );

          if (field.isMandatory && !fieldValue) {
            emptyFields.push(TextHelper.capitalizeFirstLetter(field.name));
          }
          break;
        }
      }
    });

    return emptyFields;
  }

  static getCheckedStatuses(statuses: CheckedStatuses[]): string[] {
    const checkedStatuses = statuses
      .filter((item) => item.isChecked === true)
      .map((item) => item.status);

    return checkedStatuses;
  }

  //TODO: To be deleted
  // static formatLineItems(variables: Variable[]): DocumentLineItem[] {
  //   const result: DocumentLineItem[] = [];

  //   for (const variable of variables) {
  //     if (
  //       variable?.schema &&
  //       variable?.schema?.items &&
  //       variable?.schema?.items?.properties
  //     ) {
  //       const propertyKeys = Object.keys(variable?.schema?.items?.properties);
  //       const formattedObject = {
  //         key: variable.key,
  //         type: variable.key,
  //         category: "",
  //         coords: {
  //           root: {
  //             x: 0,
  //             y: 0,
  //             width: 0,
  //             height: 0,
  //           },
  //           lines: [],
  //         },
  //         headers: propertyKeys.map((key) => ({
  //           key,
  //           index: 0,
  //           box: { x: 0, y: 0, width: 0, height: 0 },
  //           disableTextAlter: false,
  //           points: [],
  //           identifier: "",
  //           text: "",
  //           segmentationScore: null,
  //           textScore: null,
  //           score: null,
  //           rotation: null,
  //           name: null,
  //         })),
  //         data: [],
  //         pageIdentifier: "",
  //       };
  //       result.push(formattedObject);
  //     }
  //   }

  //   return result;
  // }
}
