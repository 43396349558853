import React from "react";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import {
  useTheme,
  alpha,
  Box,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
} from "@mui/material";

import { FlowSettingsMenuItem } from "../../../../types/interfaces";
import { useStores } from "../../../../stores/StoresProvider";
import { AppSuspense } from "../../../main/AppSuspense";

interface Props {
  category: string | undefined;
  options: FlowSettingsMenuItem[];
}

const FlowSettingsMenuContainer: React.FC<Props> = ({ category, options }) => {
  const { t, ready } = useTranslation("addFlow");
  const { flowSettingsStore } = useStores();
  const theme = useTheme();

  const useStyles = makeStyles({
    menuItemBox: {
      display: "block",
      marginTop: "10px",
      marginBottom: "10px",
      textDecoration: "none",
      "&.Mui-selected": {
        color: theme.palette.highlight.main,
        "& .MuiTypography-root": {
          color: theme.palette.highlight.main,
        },
      },
    },
    menuItem: {
      margin: "0 10px",
      height: "32px",
      padding: "8px 16px 8px 16px",
      color: alpha(theme.palette.text.primary, 0.9),
      "&.Mui-selected": {
        color: theme.palette.highlight.main,
        "& .MuiTypography-root": {
          color: theme.palette.highlight.main,
        },
      },
    },
    text: {
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    categoryStyle: {
      fontSize: "14px",
      padding: "5px 10px",
      color: theme.palette.text.disabled,
    },
  });

  const classes = useStyles();

  if (!ready) return <AppSuspense />;

  return (
    <>
      {category && (
        <>
          <Typography className={classes.categoryStyle}>
            {t(category)}
          </Typography>

          <Divider />
        </>
      )}
      {options.map(({ name, onClick }, index) => (
        <Box key={index} className={classes.menuItemBox}>
          <ListItemButton
            className={classes.menuItem}
            onClick={() => onClick()}
            selected={flowSettingsStore.selectedTab === name}
          >
            <ListItemText className={classes.text}>{t(name)}</ListItemText>
          </ListItemButton>
        </Box>
      ))}
    </>
  );
};

export default FlowSettingsMenuContainer;
