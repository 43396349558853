import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { observer } from "mobx-react";
import Konva from "konva";
import { Stage, Layer, Image as Img } from "react-konva";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { useTheme, Box, alpha } from "@mui/material";

import { backendRoutes } from "../../../../configs/backendRoutes";
import {
  APP_IDS,
  CANVAS_ACTIONS,
  CANVAS_CURSOR_STYLES,
  CANVAS_ACTIONS_EDIT_MODE,
  CANVAS_ACTIONS_VIEW_MODE,
  IMAGE_SIZE,
} from "../../../../types/constants";
import Toolbar from "./canvas/Toolbar";
import ThumbnailPreviewer from "./thumbnailPreviewer/ThumbnailPreviewer";
import { DocumentZone } from "../../../../types/interfaces";
import { AppSuspense } from "../../../main/AppSuspense";
import { useStores } from "../../../../stores/StoresProvider";
import ZoneHelper from "../../../../helper/canvas/zoneHelper";
import CanvasHelper from "../../../../helper/canvas/canvasHelper";
import { LineItemsRenderer } from "./canvas/lineItems/LineItemsRenderer";
import { ZonesRenderer } from "./canvas/zones/ZonesRenderer";
import { LineDetectingLoader } from "./canvas/lineItems/LineDetectingLoader";
import { useNotification } from "../../../../context/useNotification";
import TableLineItem from "./editor/TableLineItem";
import CoreResizableHeight from "../../../core/CoreResizableHeight";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  loading: {
    height: "100%",
    alignSelf: "center",
    width: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
  },
});

// The values are just estimations
const DEFAULT_CANVAS_ESTIMATIONS = {
  width: window.innerWidth - 40 - 380 - 20 - 45 - 15 - 100,
  height: window.innerHeight - 70 - 5,
};

const ZOOM_FACTOR_PERCENT = 1.06;

const DocumentCanvas: FC = observer(() => {
  const { documentStore } = useStores();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("document-validate");
  const notifier = useNotification();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLinkingModalOpen, setIsLinkingModalOpen] = useState(false);
  const [isDetectSuccessfully, setIsDetectSuccessfully] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState(
    DEFAULT_CANVAS_ESTIMATIONS
  );

  // Required canvas refs
  const stageRef = useRef<Konva.Stage>(null);
  const zonesLayer = useRef<Konva.Layer>(null);

  const [usedImage, setImg] = useState<HTMLImageElement>();

  const showRotationNotification = useCallback(() => {
    notifier.warning(t("pageRotated"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isLineItemsMode,
    canvasZones,
    rotation,
    documentPage,
    documentFields,
    isSimpleMode,
  } = documentStore;

  useEffect(() => {
    const lowSrc = backendRoutes.GetImagePreview(
      documentPage?.uri as string,
      documentStore.document?.identifier ?? "",
      IMAGE_SIZE.original,
      "low"
    );
    const highSrc = backendRoutes.GetImagePreview(
      documentPage?.uri as string,
      documentStore.document?.identifier ?? "",
      IMAGE_SIZE.original,
      "high"
    );

    // Cleanup previous image loading if documentPage.uri changes
    setImageLoaded(false);

    const lowResImg = new Image();
    const highResImg = new Image();
    lowResImg.src = lowSrc;
    lowResImg.onload = () => {
      setImg(lowResImg);
      setImageLoaded(true);
      highResImg.src = highSrc;
      highResImg.onload = () => {
        setImg(highResImg);
        setImageLoaded(true);
      };
    };

    return () => {
      lowResImg.src = "";
      highResImg.src = "";
      // Clean up image resources if component unmounts or documentPage.uri changes
      lowResImg.onload = null;
      highResImg.onload = null;
    };
  }, [documentPage?.uri, documentStore.document?.identifier]);

  useEffect(() => {
    if (usedImage) setImageLoaded(true);
  }, [usedImage]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
      documentStore.clearCanvas(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasFocusedLineItem = !!documentStore.focusedLineItem;

  useEffect(() => {
    if (usedImage) {
      updateCanvasCoords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageLoaded]);

  useEffect(() => {
    if (usedImage) {
      updateCanvasCoords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.expandedThumbPreviewer, rotation, hasFocusedLineItem]);

  useEffect(() => {
    if (documentStore.lineItemsMode) {
      // Do not perform any action for the moment
      return;
    }

    // This useEffect will calculate new scale coords when a field editor is clicked/focused
    // Focus to linked zone if exists
    if (documentStore.focusedFieldCanvas) {
      const providerList = documentStore.isSimpleMode
        ? documentStore.canvasZones
        : documentFields;

      // If in simple mode, get first zone as reference/target
      const selectedField = providerList?.find(
        (item) =>
          item?.type === documentStore.focusedFieldCanvas ||
          item?.category === documentStore.focusedFieldCanvas
      );

      if (
        stageRef.current &&
        zonesLayer.current &&
        selectedField &&
        selectedField?.box
      ) {
        const stage = stageRef.current;
        const targetCoords = selectedField.box;
        const currentScale = stage.scaleX();
        const targetNode = stage.findOne(
          (node: Konva.Node) =>
            node.x() === targetCoords.x &&
            node.y() === targetCoords.y &&
            node.width() === targetCoords.width &&
            node.height() === targetCoords.height
        );

        // Focus in canvas linked zone(s)
        if (documentStore.isSimpleMode) {
          const identifiers = documentStore.canvasZones
            ?.filter(
              (item) => item.category === documentStore.focusedFieldCanvas
            )
            ?.map((item) => item.identifier);
          documentStore.setFocusZones(identifiers || []);
          documentStore.linkCurrentSelections();
        } else {
          documentStore.setFocusZones(
            selectedField?.identifier ? [selectedField?.identifier] : []
          );
        }

        // Calculate new scale coords based on linked zone position
        // Will bring into view zone
        if (targetNode && !targetNode.isClientRectOnScreen()) {
          const targetNodeCoords = CanvasHelper.getTargetNodeCoords(
            targetCoords,
            currentScale,
            canvasDimensions,
            documentStore.rotation
          );
          documentStore.setScaleCoords(targetNodeCoords);
        }
      } else {
        documentStore.setFocusZones([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.focusedFieldCanvas, documentStore.lineItemsMode]);

  useEffect(() => {
    if (documentStore.focusedFieldCanvas) {
      if (documentStore.manualLineItemMode) {
        notifier.info(t("manualTableRowEnabledInfo"));
      } else {
        notifier.info(t("manualTableRowDisabledInfo"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.manualLineItemMode]);

  const getExternalContainerCoords = () => {
    // Coords for height
    const topBar = document
      .getElementById(APP_IDS.appTopBar)
      ?.getBoundingClientRect();
    const appToolbar = document
      .getElementById(APP_IDS.appToolbar)
      ?.getBoundingClientRect();
    const toolBar = document
      .getElementById(APP_IDS.canvasToolbar)
      ?.getBoundingClientRect();
    const table = document
      .getElementById(APP_IDS.lineItemTable)
      ?.getBoundingClientRect();

    // Coords for width
    const sidebar = document
      .getElementById(APP_IDS.sidebar)
      ?.getBoundingClientRect();
    const docEditor = document
      .getElementById(APP_IDS.docEditor)
      ?.getBoundingClientRect();
    const thumbnailPreviewer = document
      .getElementById(APP_IDS.thumbnailPreviewer)
      ?.getBoundingClientRect();

    // Calculation for new width and height which must be based on external containers
    const canvasW =
      window.innerWidth -
      (sidebar?.width ?? 0) -
      (docEditor?.width ?? 0) -
      (thumbnailPreviewer?.width ?? 0);
    const canvasH =
      window.innerHeight -
      (table?.height ?? 0) -
      (topBar?.height ?? 0) -
      (appToolbar?.height ?? 0) -
      (toolBar?.height ?? 0);

    return { canvasW, canvasH };
  };

  const updateCanvasCoords = useCallback(
    (disableSetScale?: boolean) => {
      try {
        const { canvasW, canvasH } = getExternalContainerCoords();
        let scale = documentStore.canvasScaleSize;

        const isVerticallyRotated = rotation === 90 || rotation === 270;

        const imgWidth = isVerticallyRotated
          ? documentPage?.pageInformation?.height || usedImage?.height || 1
          : documentPage?.pageInformation?.width || usedImage?.width || 1;
        const imgHeight = isVerticallyRotated
          ? documentPage?.pageInformation?.width || usedImage?.width || 1
          : documentPage?.pageInformation?.height || usedImage?.height || 1;

        switch (documentStore.fitAction) {
          case CANVAS_ACTIONS.fitHeight: {
            // Calculate scale based on height
            scale = canvasH / imgHeight;
            break;
          }
          case CANVAS_ACTIONS.fitWidth: {
            // Calculate scale based on width
            scale = canvasW / imgWidth;
            break;
          }
          default:
            break;
        }

        const scaleCoords = CanvasHelper.calculateScaleCoords(
          scale,
          rotation,
          canvasW,
          canvasH,
          imgWidth,
          imgHeight,
          documentStore.fitAction
        );

        if (!disableSetScale) {
          documentStore.setScale(scale);
        }

        const dataGridHeight =
          document?.getElementById("resizable")?.getBoundingClientRect()
            .height ?? 0;

        documentStore.setScaleCoords(scaleCoords);
        setCanvasDimensions({
          width: canvasW,
          height: canvasH - dataGridHeight,
        });
      } catch {
        // This is currently an estimation
        setCanvasDimensions({
          width: DEFAULT_CANVAS_ESTIMATIONS.width,
          height: DEFAULT_CANVAS_ESTIMATIONS.height,
        });
      }
    },
    [
      documentStore,
      rotation,
      usedImage?.width,
      usedImage?.height,
      documentPage?.pageInformation.height,
      documentPage?.pageInformation.width,
    ]
  );

  const resizeEventCallback = useCallback(() => {
    updateCanvasCoords();
  }, [updateCanvasCoords]);

  useEffect(() => {
    if (usedImage) {
      updateCanvasCoords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentStore.shouldUpdateDocumentCoords]);

  useEffect(() => {
    window.addEventListener("resize", resizeEventCallback);

    return () => {
      window.removeEventListener("resize", resizeEventCallback);
    };
  }, [resizeEventCallback]);

  const onCanvasKeyUp = (event?: React.KeyboardEvent<HTMLDivElement>) => {
    if (event) {
      switch (event.key) {
        // This action will unlink a zone
        case "Delete":
          if (!documentStore.viewMode) {
            documentStore.removeFieldZones();
          }
          documentStore.clearFocusZones();
          break;
        case "Enter":
          if (documentStore.focusedFieldCanvas) {
            documentStore.getNextFocusedFieldCanvas(false);
          }
          break;
        case "Escape":
          documentStore.handleCancelFocusedFieldCanvas(true);
          break;
      }
    }
  };

  // Click on stage for zooming
  const onStageClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      // ZOOM CANVAS on click
      if (
        documentStore.canvasAction === CANVAS_ACTIONS.zoomIn ||
        documentStore.canvasAction === CANVAS_ACTIONS.zoomOut
      ) {
        const stage = e.target.getStage() as Konva.Stage;
        const currentScale = stage.scaleX();
        const pointerPosition = stage.getPointerPosition() as Konva.Vector2d;

        const calcCoords = CanvasHelper.getScaleCoords(e, true);

        const newScale =
          documentStore.canvasAction === CANVAS_ACTIONS.zoomIn
            ? currentScale * ZOOM_FACTOR_PERCENT
            : currentScale / ZOOM_FACTOR_PERCENT;

        if (newScale > 2 || newScale < 0.2) {
          return;
        }

        const newStageCoords = {
          x: -(calcCoords.x - pointerPosition.x / newScale) * newScale,
          y: -(calcCoords.y - pointerPosition.y / newScale) * newScale,
        };

        documentStore.setScale(newScale);
        documentStore.setScaleCoords(newStageCoords);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentStore.canvasAction]
  );

  // Prepare for draw new zone or select existing one
  const handleMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (
        documentStore.lineItemsMode &&
        !documentStore.manualLineItemMode &&
        !!documentStore.focusedLineItem
      )
        return;

      // Start drawing new zone/Multiple select
      if (
        CANVAS_ACTIONS.drawNewZone === documentStore.canvasAction &&
        !documentStore.isTransforming &&
        !documentStore.drawingZone &&
        documentStore.movingLineIndex === null &&
        documentStore.viewMode === false
      ) {
        const { x = 0, y = 0 } =
          e?.target?.getStage()?.getRelativePointerPosition() || {};

        documentStore.initDrawingZone({
          box: {
            x,
            y,
            width: 0,
            height: 0,
          },
        } as DocumentZone);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      documentStore.canvasScaleSize,
      documentStore.scaleCoords,
      documentStore.multiSelectMode,
    ]
  );

  // Finalize draw new zone if started
  const handleMouseUp = (
    event: Konva.KonvaEventObject<MouseEvent | TouchEvent>
  ) => {
    // Update zone coords if we are in draw zone action
    if (
      [CANVAS_ACTIONS.drawNewZone].includes(documentStore.canvasAction) &&
      documentStore.drawingZone &&
      documentStore.viewMode === false
    ) {
      if (
        !ZoneHelper.isZoneDimensionValid(
          documentStore.drawingZone,
          isLineItemsMode && !documentStore.manualLineItemMode
        )
      ) {
        documentStore.setDrawingZone(undefined);
        return;
      }

      const formattedZone = ZoneHelper.normalizeZoneCoords(
        ZoneHelper.preprocessZone(
          event,
          documentStore.drawingZone,
          documentStore.scaleCoords,
          documentStore.canvasScaleSize
        ),
        usedImage?.width || 1,
        usedImage?.height || 1
      );

      // If focused field is of type lineItem and no data exists, then manually extract data
      if (isLineItemsMode && !documentStore.focusedLineItem) {
        // Manual lineItem construct
        if (!event.evt.ctrlKey) {
          const newLineItemIdentifier = documentStore.initLineItem();
          documentStore.setDrawingZone(undefined);
          documentStore.setFocusZone(newLineItemIdentifier);
          return;
        }

        // If CTRL key is pressed, then automatically extract table data
        setIsLinkingModalOpen(true);
        setIsDetectSuccessfully(true);
        void documentStore
          .handleLineItemBorderDetect(formattedZone)
          .then((newLineItemIdentifier) => {
            documentStore.setDrawingZone(undefined);
            documentStore.setFocusZone(newLineItemIdentifier);
          })
          .catch((error: Error) => {
            // Switch to manual lineItem construct
            notifier.error(t(error?.message || "lineItemsDetectionFailed"));
            const newLineItemIdentifier = documentStore.initLineItem();
            documentStore.setDrawingZone(undefined);
            documentStore.setFocusZone(newLineItemIdentifier);
            setIsDetectSuccessfully(false);
          });

        return;
      }

      // Select only fields with linked zones
      const intersectedZones = canvasZones?.filter((zone) =>
        ZoneHelper.intersectRect(formattedZone.box, zone.box)
      );

      // Click on empty canvas for unselect zone
      if (formattedZone.box.width === 0 && formattedZone.box.height === 0) {
        documentStore.setDrawingZone(undefined);
        documentStore.setFocusZones([formattedZone.identifier]);
        return;
      }

      if (isSimpleMode) {
        documentStore.setFocusZones(
          intersectedZones?.map((zone) => zone.identifier)
        );
        documentStore.linkCurrentSelections();
        return;
      }

      if (isLineItemsMode && documentStore.manualLineItemMode) {
        // Assign intersected zones text to lineItem cell
        documentStore.initManualLineItemCell(intersectedZones);
        documentStore.setDrawingZone(undefined);
        return;
      }

      documentStore.updateZone({
        ...formattedZone,
        default: false,
        text: intersectedZones
          ?.sort((a, b) => a.index - b.index)
          ?.map((zone) => zone.text)
          ?.join(" "),
        type: documentStore.focusedFieldCanvas,
      } as DocumentZone);

      documentStore.setDrawingZone(undefined);
      documentStore.setFocusZones([formattedZone.identifier]);
    }
  };

  // Handles zone coords which is being drawn
  const handleMouseMove = (
    event: Konva.KonvaEventObject<MouseEvent | TouchEvent>
  ) => {
    const mousePos = event.target?.getStage()?.getRelativePointerPosition();

    if (documentStore.focusedFieldCanvas) {
      if (mousePos) {
        documentStore.setCanvasMousePos(mousePos);
      }
    }

    // Complete zone if we are in draw zone action
    if (
      [CANVAS_ACTIONS.drawNewZone].includes(documentStore.canvasAction) &&
      documentStore.drawingZone
    ) {
      const formattedZone = ZoneHelper.preprocessZone(
        event,
        documentStore.drawingZone,
        documentStore.scaleCoords,
        documentStore.canvasScaleSize,
        false
      );
      documentStore.setDrawingZone(formattedZone);

      // Highlight zones
      if (isSimpleMode) {
        // Select only fields with linked zones
        const intersectedZones = documentStore.canvasZones
          .filter((zone) =>
            ZoneHelper.intersectRect(formattedZone.box, zone.box)
          )
          .map((zone) => zone.identifier);

        documentStore.setFocusZones(intersectedZones);
      }
    }
  };

  // Move stage (PAN, view mode)
  const handleDragStage = (e: Konva.KonvaEventObject<DragEvent>) => {
    const newStageCoords = CanvasHelper.getScaleCoords(e);

    const dragLimitsCoords = CanvasHelper.getCanvasLimits(
      canvasDimensions,
      usedImage,
      documentStore.canvasScaleSize,
      rotation
    );

    const canvasCoords = CanvasHelper.getCanvasCoords(
      dragLimitsCoords,
      newStageCoords
    );

    stageRef.current?.setAttrs({
      x: canvasCoords.x,
      y: canvasCoords.y,
    });
    documentStore.setScaleCoords(canvasCoords);
  };

  const handleChangeAction = useCallback(
    (newAction: CANVAS_ACTIONS) => {
      switch (newAction) {
        case CANVAS_ACTIONS.resetCanvas:
          // Reset scale, state coords and rotation
          updateCanvasCoords();
          documentStore.resetRotation();
          break;
        case CANVAS_ACTIONS.drawNewZone:
          !documentStore.focusedFieldCanvas &&
            documentStore.getNextFocusedFieldCanvas(true);
          break;
        case CANVAS_ACTIONS.fitHeight:
        case CANVAS_ACTIONS.fitWidth:
          documentStore.setFitAction(newAction);
          updateCanvasCoords();
          break;
        case CANVAS_ACTIONS.rotateRight: {
          // Reset rotation in case if current rotation is 270
          const newRotation =
            documentStore.rotation !== 270 ? documentStore.rotation + 90 : 0;
          documentStore.setRotation(newRotation, showRotationNotification);
          if (documentStore.viewMode) {
            documentStore.changeCanvasAction(CANVAS_ACTIONS.pan);
          }
          break;
        }
        case CANVAS_ACTIONS.rotateLeft: {
          // Reset rotation in case if current rotation is 270
          let newRotation = 0;
          if (documentStore.rotation === 0) {
            newRotation = 270;
          } else if (documentStore.rotation === 90) {
            newRotation = 0;
          } else {
            newRotation = documentStore.rotation - 90;
          }
          documentStore.setRotation(newRotation, showRotationNotification);
          if (documentStore.viewMode) {
            documentStore.changeCanvasAction(CANVAS_ACTIONS.pan);
          }
          break;
        }
        default:
          break;
      }
      (documentStore.viewMode
        ? CANVAS_ACTIONS_EDIT_MODE.includes(newAction)
        : CANVAS_ACTIONS_VIEW_MODE.includes(newAction)) &&
        documentStore.changeCanvasAction(newAction);
    },
    [documentStore, showRotationNotification, updateCanvasCoords]
  );

  // Scroll or zoom on mouse wheel
  const handleOnWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();

    const stageCoords = CanvasHelper.getOnWheelStageCoords(
      e,
      documentStore.scaleCoords,
      documentStore.rotation
    );
    // Set new scale if CTRL is pressed for zooming
    if (e.evt.ctrlKey) {
      if (stageCoords.scale > 2 || stageCoords.scale < 0.2) return;
      documentStore.setScale(stageCoords.scale);
    }

    const scrollLimitsCoords = CanvasHelper.getCanvasLimits(
      canvasDimensions,
      usedImage,
      documentStore.canvasScaleSize,
      rotation
    );

    const canvasCoords = CanvasHelper.getCanvasCoords(
      scrollLimitsCoords,
      stageCoords.coords
    );

    documentStore.setScaleCoords(canvasCoords);
  };

  // Handles cursor style for canvas
  const handleMouseEnter = (
    e: Konva.KonvaEventObject<MouseEvent | TouchEvent>
  ) => {
    documentStore.setMouseOnCanvas(true);
    const stage = e.target?.getStage() as Konva.Stage;
    const stageContainer = stage?.container();

    switch (documentStore.canvasAction) {
      case CANVAS_ACTIONS.pan:
        stageContainer.style.cursor = documentStore.focusedFieldCanvas
          ? CANVAS_CURSOR_STYLES.drawNewZone
          : CANVAS_CURSOR_STYLES.pan;
        break;
      case CANVAS_ACTIONS.zoomIn:
        stageContainer.style.cursor = CANVAS_CURSOR_STYLES.zoomIn;
        break;
      case CANVAS_ACTIONS.zoomOut:
        stageContainer.style.cursor = CANVAS_CURSOR_STYLES.zoomOut;
        break;
      default:
        stageContainer.style.cursor = CANVAS_CURSOR_STYLES.drawNewZone;
    }
  };

  // Resets cursor style for canvas
  const handleMouseLeave = (
    e: Konva.KonvaEventObject<MouseEvent | TouchEvent>
  ) => {
    documentStore.setMouseOnCanvas(false);
    const stage = e.target?.getStage() as Konva.Stage;
    const stageContainer = stage?.container();

    stageContainer.style.cursor = CANVAS_CURSOR_STYLES.drawNewZone;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!documentStore.isRejectReasonsPopupOpen) {
      const stage = stageRef.current as Konva.Stage;

      if (event.ctrlKey && !documentStore.multiSelectMode) {
        documentStore.setMultiselect(true);
      }
      //Space key down
      if (
        (event.key === " " || event.code === "Space") &&
        documentStore.canvasAction !== CANVAS_ACTIONS.pan &&
        stage &&
        documentStore.isMouseOnCanvas
      ) {
        event.preventDefault();
        stage.container().style.cursor = "grab";
        documentStore.changeTemporaryAction(CANVAS_ACTIONS.pan);
      }
    } else {
      return;
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (!documentStore.isRejectReasonsPopupOpen) {
      if (documentStore.multiSelectMode) {
        documentStore.setMultiselect(false);
      }
      //Space key released
      if (
        (event.key === " " || event.code === "Space") &&
        documentStore.canvasAction !== CANVAS_ACTIONS.pan
      ) {
        const stage = stageRef.current as Konva.Stage;
        documentStore.changeTemporaryAction(null);
        switch (documentStore.canvasAction as CANVAS_ACTIONS) {
          case CANVAS_ACTIONS.zoomIn:
            stage.container().style.cursor = CANVAS_CURSOR_STYLES.zoomIn;
            break;
          case CANVAS_ACTIONS.zoomOut:
            stage.container().style.cursor = CANVAS_CURSOR_STYLES.zoomOut;
            break;
          case CANVAS_ACTIONS.pan:
            stage.container().style.cursor = CANVAS_CURSOR_STYLES.pan;
            break;
          default:
            stage.container().style.cursor = CANVAS_CURSOR_STYLES.drawNewZone;
        }
      }
    } else {
      return;
    }
  };

  const toolbarContent = useMemo(
    () => (
      <Toolbar
        activeAction={
          documentStore.temporaryAction
            ? documentStore.temporaryAction
            : documentStore.canvasAction
        }
        fitAction={documentStore.fitAction}
        changeAction={handleChangeAction}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      documentStore.canvasAction,
      documentStore.fitAction,
      canvasDimensions,
      documentStore.temporaryAction,
    ]
  );

  return (
    <>
      {toolbarContent}
      <Box className={classes.root}>
        <ThumbnailPreviewer />

        {documentStore.pageLoading || !usedImage ? (
          <Box className={classes.loading}>
            <AppSuspense />
          </Box>
        ) : (
          <Box className={classes.box}>
            <Box tabIndex={1} onKeyUp={onCanvasKeyUp} className={classes.box}>
              <Stage
                ref={stageRef}
                width={canvasDimensions.width}
                height={canvasDimensions.height}
                scaleX={documentStore.canvasScaleSize}
                scaleY={documentStore.canvasScaleSize}
                rotation={rotation}
                x={documentStore.scaleCoords.x}
                y={documentStore.scaleCoords.y}
                draggable={
                  documentStore.canvasAction === CANVAS_ACTIONS.pan ||
                  documentStore.temporaryAction === CANVAS_ACTIONS.pan
                }
                tabIndex={-1}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleMouseDown}
                onClick={onStageClick}
                onDragEnd={handleDragStage}
                onWheel={handleOnWheel}
              >
                <Layer ref={zonesLayer}>
                  <Img
                    image={usedImage}
                    width={usedImage?.width}
                    height={usedImage?.height}
                  />

                  {documentStore.hasLineItemFieldTypes && (
                    <LineItemsRenderer
                      documentStore={documentStore}
                      theme={theme}
                    />
                  )}

                  {!documentStore.isLineItemsMode && (
                    <ZonesRenderer
                      documentStore={documentStore}
                      theme={theme}
                    />
                  )}
                </Layer>
              </Stage>
            </Box>
            {documentStore.focusedLineItem && isLineItemsMode && (
              <CoreResizableHeight
                containerSizes={{
                  min: 200,
                  default: 300,
                  max: window.innerHeight / 2 || 400,
                  maxWidth: canvasDimensions.width,
                }}
                onResizeExtraFunction={() => {
                  documentStore.setShouldUpdateDocumentCoords(new Date());
                }}
                handleStyles={{
                  top: {
                    height: "10px",
                    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
                    borderLeft: `1px solid ${alpha(
                      theme.palette.divider,
                      0.2
                    )}`,
                    borderRight: `1px solid ${alpha(
                      theme.palette.divider,
                      0.2
                    )}`,
                  },
                }}
                enable={{
                  top: true,
                  right: false,
                  bottom: false,
                  left: false,
                  topRight: false,
                  bottomRight: false,
                  bottomLeft: false,
                  topLeft: false,
                }}
              >
                <TableLineItem
                  t={t}
                  width={canvasDimensions.width}
                  documentStore={documentStore}
                />
              </CoreResizableHeight>
            )}
          </Box>
        )}
      </Box>

      <LineDetectingLoader
        t={t}
        isOpen={isLinkingModalOpen}
        setIsOpen={setIsLinkingModalOpen}
        isDetectInProgress={documentStore.isLineItemBorderFetching}
        isDetectSuccessfully={isDetectSuccessfully}
        title={t("lineItems_detectingModalTitle") || ""}
        subtitle={t("lineItems_detectingModalSubtitle") || ""}
        color={theme.palette.highlight.main}
      />
    </>
  );
});

export default memo(DocumentCanvas);
