import { TFunction } from "react-i18next";
import moment from "moment";

import { FlowDataCategoryItem } from "../../../../types/interfaces/flow";
import { Order } from "../../../../types/types";
import {
  FLOW_DATA_TYPES_FRONTEND,
  SORT_DIRECTION,
} from "../../../../types/constants";

export const getTableDefinition = (
  t: TFunction<"flow", undefined>,
  type: string
) => {
  return [
    {
      accessor: "name",
      label: t("tableHeader_name"),
      options: {
        isSortable: true,
      },
    },
    {
      accessor: "description",
      label: t("tableHeader_description"),
      options: {
        isSortable: true,
      },
    },
    {
      accessor: "createdAt",
      label: t("tableHeader_createdAt"),
      options: {
        isSortable: true,
      },
    },
    {
      accessor: "updatedAt",
      label: t("tableHeader_updatedAt"),
      options: {
        isSortable: true,
      },
    },
    {
      accessor: "lastRun",
      label: t("tableHeader_lastRunAt"),
      options: {
        isSortable: true,
      },
    },
    {
      accessor: "processedDocuments",
      label:
        type === FLOW_DATA_TYPES_FRONTEND.dataSources
          ? t("tableHeader_importedDocuments")
          : t("tableHeader_exportedDocuments"),
      options: {
        isSortable: true,
      },
    },
    {
      accessor: "status",
      label: t("tableHeader_status"),
    },
    {
      accessor: "actions",
      label: t("tableHeader_actions"),
    },
  ];
};

// Returns total pages based on total received items and current page size
export const calculateNoOfPages = (totalItems: number, pageSize: number) => {
  if (!totalItems) {
    return 0;
  }

  return Math.ceil(totalItems / pageSize);
};

// Returns current page slice which will be displayed in table
export const extractCurrentDataPage = (
  data: FlowDataCategoryItem[],
  filters: {
    page: number;
    pageSize: number;
    sortBy: string;
    sortDirection: Order;
  },
  textSearch: string
) => {
  if (!data || data?.length === 0) {
    return [];
  }

  let formattedData = data;

  // Filter based on sort
  if (filters.sortBy && filters.sortBy !== "" && filters.sortDirection) {
    const sortBy = filters.sortBy;

    switch (sortBy) {
      case "name":
      case "description":
        formattedData = [
          ...(formattedData as unknown as { [key: string]: string }[]),
        ].sort((a, b) => {
          if (a?.[sortBy]) {
            if (filters.sortDirection === SORT_DIRECTION.asc)
              return a?.[sortBy].localeCompare(b?.[sortBy]);

            if (filters.sortDirection === SORT_DIRECTION.desc)
              return -1 * a?.[sortBy].localeCompare(b?.[sortBy]);
          }

          return 0;
        }) as unknown as FlowDataCategoryItem[];

        break;

      case "processedDocuments":
        formattedData = [
          ...(formattedData as unknown as { [key: string]: number }[]),
        ].sort((a, b) => {
          if (filters.sortDirection === SORT_DIRECTION.asc)
            return a?.[sortBy] - b?.[sortBy];

          if (filters.sortDirection === SORT_DIRECTION.desc)
            return b?.[sortBy] - a?.[sortBy];

          return 0;
        }) as unknown as FlowDataCategoryItem[];

        break;

      case "createdAt":
      case "updatedAt":
      case "lastRun":
        formattedData = [
          ...(formattedData as unknown as {
            [key: string]: string | number | Date;
          }[]),
        ].sort((a, b) => {
          const firstElement = moment(a?.[sortBy]) as unknown as number;
          const secondElement = moment(b?.[sortBy]) as unknown as number;

          if (filters.sortDirection === SORT_DIRECTION.asc)
            return firstElement - secondElement;
          if (filters.sortDirection === SORT_DIRECTION.desc)
            return secondElement - firstElement;

          return 0;
        }) as unknown as FlowDataCategoryItem[];

        break;

      default:
        break;
    }
  }

  // Filter based on text search
  if (textSearch?.trim()) {
    const keyword = textSearch?.toLowerCase();
    formattedData = formattedData.filter(
      (row) =>
        row?.name?.toLowerCase()?.includes(keyword) ||
        row?.description?.toLowerCase()?.includes(keyword)
    );
  }

  // Slice page
  const limit = filters.page === 1 ? 0 : (filters.page - 1) * filters.pageSize;
  const offset = filters.page * filters.pageSize;

  return formattedData?.slice(limit, offset);
};
