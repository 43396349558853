import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";

import { getApiClient } from "./client";

export function useApiClient(
  configOverride: Partial<AxiosRequestConfig> = {}
): AxiosInstance | undefined {
  const [client, setClient] = useState<{ instance: AxiosInstance }>();

  useEffect(() => {
    setClient({ instance: getApiClient(configOverride) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return client?.instance;
}
