import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "@apollo/client";

import { Box, Typography, useTheme, alpha, IconButton } from "@mui/material";
import { TreeView } from "@mui/lab";

import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ArticleIcon from "@mui/icons-material/Article";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import ArrowBack from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

import CoreTooltip from "../../../../../core/CoreTooltip";
import CoreInput from "../../../../../core/CoreInput";
import { AppSuspense } from "../../../../../main/AppSuspense";
import { useStores } from "../../../../../../stores/StoresProvider";
import { FormData, RenderTree } from "../../../../../../types/interfaces";
import { FileBrowserHelper } from "./FileBrowserHelper";
import CoreButton from "../../../../../core/CoreButton";
import { useNotification } from "../../../../../../context/useNotification";
import { FlowEnum } from "../../../../../../types/constants";

interface Props {
  onFormChange: (value: FormData, key: string) => void;
  formData: FormData;
  connectionId?: string;
  onClose: () => void;
}

const FileBrowser: React.FC<Props> = ({
  connectionId,
  onFormChange,
  formData,
  onClose,
}) => {
  const client = useApolloClient();
  const { flowStore } = useStores();
  const theme = useTheme();
  const { t } = useTranslation("flow");
  const notification = useNotification();

  const [expanded, setExpanded] = useState<string[]>([FlowEnum.emptyPath]);
  const [selected, setSelected] = useState<string>(FlowEnum.emptyPath);
  const [search, setSearch] = useState<string>("");
  const [resultSearch, setResultSearch] = useState<RenderTree[]>([]);
  const [treeData, setTreeData] = useState<RenderTree | null>(null);

  const useStyles = makeStyles({
    container: {
      width: "100%",
      height: "100%",
      padding: "20px 30px 30px 10px",
      display: "flex",
      flexDirection: "row",
      borderTop: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
    },
    tree: {
      height: "100%",
      paddingRight: "10px",
      overflow: "auto",
      "& .Mui-selected": { color: theme.palette.highlight.main },
      "& > .MuiTreeItem-iconContainer": {
        width: 0,
      },
    },
    leftPanel: {
      width: "35%",
      flexGrow: 1,
    },
    treeItem: {
      marginTop: "5px",
    },
    rightPanel: {
      width: "65%",
      flexGrow: 1,
      height: "100%",
      overflow: "auto",
      borderLeft: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
    },
    fileName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    optionsContainer: {
      display: "flex",
      alignItems: "center",
      "& input": {
        height: "17px",
      },
    },
    treeBox: {
      display: "flex",
      alignItems: "center",
    },
    box: {
      marginRight: "10px",
      display: "flex",
      alignItems: "center",
      padding: "5px 0",
    },
    input: {
      marginRight: "10px",
    },
    searchIcon: {
      marginRight: "14px",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-between",
      margin: "2px 5px",
    },
  });

  const classes = useStyles();

  useEffect(() => {
    flowStore
      .getConnectionFiles(client, connectionId ?? "")
      .then((res) => {
        setTreeData(FileBrowserHelper.transformDataToNodes(res.data));
        if (formData.rootPath) {
          const rootPath = formData.rootPath as string;
          if (
            FileBrowserHelper.getAllIdsFromTree(
              FileBrowserHelper.transformDataToNodes(res.data)
            ).includes(rootPath)
          ) {
            setSelected(rootPath);
            //Expand all parents of selected node
            setExpanded([
              FlowEnum.emptyPath,
              ...FileBrowserHelper.getParentPaths(rootPath),
              rootPath,
            ]);
          } else {
            setSelected(FlowEnum.emptyPath);
            setExpanded([FlowEnum.emptyPath]);
          }
        }
      })
      .catch((error: Error) =>
        notification.error(t(error?.message || "errorGettingFiles"))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionId]);

  if (!treeData) {
    return <AppSuspense />;
  }

  // get children from id with subfolders and files

  const handleToggle = (_: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleExpandAll = () => {
    setExpanded(FileBrowserHelper.getAllIdsFromTree(treeData));
  };

  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleSelect = (_: React.SyntheticEvent, nodeIds: string) => {
    setSelected(nodeIds);
  };

  const renderTree = (nodes: RenderTree) => {
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.id}
        className={classes.treeItem}
        disabled={!nodes.children}
        label={
          <CoreTooltip title={nodes.name} placement="right">
            <Box
              className={classes.treeBox}
              onClick={() => (nodes.children ? setSelected(nodes.id) : null)}
            >
              <Box className={classes.box}>
                {nodes.children ? <FolderOpenIcon /> : <ArticleIcon />}
              </Box>
              <Typography className={classes.fileName} variant="body1">
                {nodes.name}
              </Typography>
            </Box>
          </CoreTooltip>
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  const handleConfirm = () => {
    onFormChange({ ...formData, rootPath: selected }, FlowEnum.rootPath);
    onClose();
  };

  const handleGoBackParent = () => {
    if (selected) {
      const parentPath = FileBrowserHelper.getParentPaths(selected)[0];

      setSelected(parentPath ?? "/");
      setExpanded(expanded.filter((path) => path !== selected));
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.leftPanel}>
          <Box className={classes.optionsContainer}>
            <CoreInput
              fullWidth
              className={classes.input}
              startAdornment={<SearchIcon className={classes.searchIcon} />}
              placeholder={t("search")}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setSelected("");
                  setResultSearch(
                    FileBrowserHelper.searchChildrenByName(search, treeData)
                  );
                }
              }}
            />
            <CoreTooltip title={t("expandAll")} placement="top">
              <IconButton onClick={handleExpandAll}>
                <UnfoldMoreIcon />
              </IconButton>
            </CoreTooltip>
            <CoreTooltip title={t("collapseAll")} placement="top">
              <IconButton onClick={handleCollapseAll}>
                <UnfoldLessIcon />
              </IconButton>
            </CoreTooltip>
          </Box>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeToggle={handleToggle}
            expanded={expanded}
            className={classes.tree}
            selected={selected}
            onNodeSelect={handleSelect}
            onNodeFocus={handleSelect}
          >
            {renderTree(treeData)}
          </TreeView>
        </Box>

        <Box className={classes.rightPanel}>
          <Box className={classes.buttons}>
            <IconButton
              onClick={handleGoBackParent}
              disabled={selected === "/"}
            >
              <ArrowBack />
            </IconButton>

            <CoreButton onClick={handleConfirm} variant="contained">
              {t("confirm")}
            </CoreButton>
          </Box>
          <TreeView
            onNodeFocus={(e: React.SyntheticEvent, nodeIds: string) => {
              handleSelect(e, nodeIds);
              setExpanded([
                ...expanded,
                ...FileBrowserHelper.getParentPaths(nodeIds),
                nodeIds,
              ]);
            }}
          >
            {selected
              ? FileBrowserHelper.getChildrenFromId(selected, treeData).map(
                  (child) => renderTree(child)
                )
              : resultSearch.map((child) => renderTree(child))}
          </TreeView>
        </Box>
      </Box>
    </>
  );
};

export default FileBrowser;
