import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { TFunction } from "i18next";

import { makeStyles } from "@mui/styles";
import { Box, Divider, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import CoreTabContainer from "../../../../core/CoreTabContainer";
import { FlowSettingsStore } from "../../../../../stores/FlowSettingsStore";
import { UserStore } from "../../../../../stores/UserStore";
import CoreNotificationBox from "../../../../core/CoreNotificationBox";
import CoreConfirmModal from "../../../../core/CoreConfirmModal";
import CoreCardFlow from "../../../../core/CoreCardFlow";
import CoreInput from "../../../../core/CoreInput";
import CoreButton from "../../../../core/CoreButton";
import MainDrawer from "../../../addNewFlow/tabs/access/drawer/Drawer";
import {
  NotificationTypes,
  useNotification,
} from "../../../../../context/useNotification";
import { CardActions, FlowInterface } from "../../../../../types/interfaces";
import { AppSuspense } from "../../../../main/AppSuspense";

interface Props {
  t: TFunction;
  flowSettingsStore: FlowSettingsStore;
  userStore: UserStore;
}

const useStyles = makeStyles({
  inputInviteBox: {
    maxWidth: "600px",
    display: "flex",
    flexDirection: "row",
    border: "none",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "30px",
    marginBottom: "20px",
  },
  input: {
    width: "200px",
    marginRight: "auto",
  },
  icon: {
    opacity: 0.4,
    marginRight: "10px",
  },
  loadingContainer: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    width: "100%",
  },
  card: {
    maxWidth: "800px",
    minHeight: "90px",
    display: "flex",
    marginTop: "50px",
  },
  button: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  buttonBox: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
});

const RestrictTab: React.FC<Props> = observer(
  ({ t, flowSettingsStore, userStore }) => {
    const notification = useNotification();
    const [flowAccess, setFlowAccess] = useState<Record<string, string>>({});
    const [userFilter, setUserFilter] = useState("");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const classes = useStyles();

    useEffect(() => {
      setFlowAccess(flowSettingsStore.flow?.access ?? {});
    }, [flowSettingsStore.flow?.access]);

    const userWithRestrictedFlowAccess = useMemo(() => {
      return userStore.users?.filter(
        (user) => flowAccess?.[user.id] === "restricted"
      );
    }, [userStore.users, flowAccess]);

    const filteredUsers = userWithRestrictedFlowAccess?.filter((flowUser) => {
      if (!userFilter) return true;
      const user = userStore.users?.find((user) => user.id === flowUser.id);
      const filteredByEmail = user?.email.toLowerCase();
      const filteredByFirstName = user?.first_name.toLowerCase();
      const filteredByLastName = user?.last_name.toLowerCase();

      if (
        filteredByEmail?.includes(userFilter.toLowerCase()) ||
        filteredByFirstName?.includes(userFilter.toLowerCase()) ||
        filteredByLastName?.includes(userFilter.toLowerCase())
      ) {
        return true;
      }

      return false;
    });

    const handleClickDelete = (args: string[]) => {
      const [id] = args;
      const userToDelete = flowAccess?.[id];

      if (userToDelete) {
        setDeleteUserId(id);
        setIsModalOpen(true);
      }
    };

    const listActions = [
      {
        tooltip: "delete_tooltip",
        icon: <DeleteIcon />,
        onClick: handleClickDelete,
        label: t("delete"),
      },
    ] as unknown as CardActions[];

    const getListActions = () => {
      return listActions;
    };

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserFilter(event.target.value);
    };

    const handleClose = () => {
      setIsOpen(false);
    };

    const handleConfirmDelete = () => {
      const updatedFlowRestrictedUsers = Object.fromEntries(
        Object.entries(flowAccess ?? {}).filter(([key]) => key !== deleteUserId)
      );

      flowSettingsStore
        .updateFlow({
          access: updatedFlowRestrictedUsers,
        } as FlowInterface)
        .then(() => {
          setIsModalOpen(false);
          notification.success(t("updateFlowSuccess"));
          flowSettingsStore.triggerRefetchFlowSettings(true);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "updateFlowError"));
        });
    };

    return (
      <CoreTabContainer t={t} title={t(flowSettingsStore.selectedTab)}>
        {flowSettingsStore.loadingFlowSettings ? (
          <AppSuspense />
        ) : (
          <>
            <CoreNotificationBox
              description={t("restrict_notification")}
              type={NotificationTypes.info}
              alignButtonToEnd={true}
            >
              <Box className={classes.buttonBox}>
                <Divider orientation="vertical" />

                <CoreButton
                  variant="contained"
                  onClick={() => setIsOpen(true)}
                  className={classes.button}
                >
                  {t("addUsers")}
                </CoreButton>
              </Box>
            </CoreNotificationBox>

            <Box className={classes.inputInviteBox}>
              <CoreInput
                value={userFilter}
                onChange={handleInput}
                placeholder={t("searchInput_users")}
                startAdornment={<SearchIcon className={classes.icon} />}
                className={classes.input}
                fullWidth={false}
                size="small"
              />
            </Box>

            {filteredUsers?.length > 0 ? (
              filteredUsers?.map((flowUser, index) => {
                return (
                  <CoreCardFlow
                    key={index}
                    title={`${flowUser.first_name} ${flowUser.last_name}`}
                    subtitle={flowUser.email}
                    t={t}
                    listActions={getListActions()}
                    identifier={[flowUser?.id ? flowUser.id.toString() : ""]}
                  />
                );
              })
            ) : (
              <Box className={classes.card}>
                <Box className={classes.loadingContainer}>
                  <Typography>{t("no_users_found")}</Typography>
                </Box>
              </Box>
            )}

            <MainDrawer
              isOpen={Boolean(isOpen)}
              onClose={() => handleClose()}
            />

            <CoreConfirmModal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              onCancel={() => setIsModalOpen(false)}
              onConfirm={handleConfirmDelete}
              title={t("user_deleteTitle")}
              subtitle={t("user_deleteSubtitle")}
              cancelButtonLabel={t("cancelButtonLabel")}
              confirmButtonLabel={t("confirmButtonLabel")}
              isDisable={flowSettingsStore.loadingUpdateFlow}
            />
          </>
        )}
      </CoreTabContainer>
    );
  }
);

export default RestrictTab;
