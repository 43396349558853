import React, { useState, useEffect } from "react";
import { TFunction } from "i18next";

import { Box, Typography } from "@mui/material";

import { CoreToggle } from "../core/CoreToggle";
import {
  FormData,
  FormField,
  SelectOptionDef,
  Variable,
} from "../../types/interfaces";
import FileNameAndDynamicExportAutocomplete from "./FileNameAndDynamicExportAutocomplete";
import { FlowEnum, DYNAMIC_FIELDS } from "../../types/constants";

interface Props {
  field: FormField;
  value: unknown;
  onChange: (value: FormData) => void;
  formData: FormData;
  translation: TFunction;
  variables?: Variable[];
  disableDiagramField?: boolean;
}

type toggleValue = FlowEnum.default | FlowEnum.dynamic;

const FileNameRenderer: React.FC<Props> = ({
  field,
  value,
  onChange,
  formData,
  translation,
  variables,
  disableDiagramField = false,
}) => {
  const [nameToggleValue, setNameToggleValue] = useState<toggleValue>(
    ((value as SelectOptionDef[]) ?? []).length
      ? FlowEnum.dynamic
      : FlowEnum.default
  );

  const [options, setOptions] = React.useState<SelectOptionDef[]>([]);

  useEffect(() => {
    const variablesOptions = (variables ?? [])?.flatMap((field) => {
      return {
        label: field.name,
        key: field.key,
        type: DYNAMIC_FIELDS.flowFields,
      };
    });

    let options = [...variablesOptions, ...(field.options ?? [])];

    options = options.sort((a, b) =>
      a?.type?.localeCompare(b?.type as string) ? 1 : -1
    );

    setOptions(options as SelectOptionDef[]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeToggle = (value: string) => {
    setNameToggleValue(value as toggleValue);
    if (value === FlowEnum.default)
      onChange({
        ...formData,
        [field.key]: [],
      });
  };

  return (
    <Box>
      <Typography>{translation("fileNameSettings")}</Typography>
      <CoreToggle
        options={[
          { displayName: translation("default"), value: FlowEnum.default },
          { displayName: translation("dynamic"), value: FlowEnum.dynamic },
        ]}
        onChange={handleChangeToggle}
        value={nameToggleValue}
        disabled={disableDiagramField || false}
      />
      {nameToggleValue === FlowEnum.dynamic && (
        <FileNameAndDynamicExportAutocomplete
          field={field}
          formData={formData}
          onChange={onChange}
          translation={translation}
          options={options}
          value={value}
        />
      )}
    </Box>
  );
};

export default FileNameRenderer;
