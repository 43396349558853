import React, { FC, memo, useCallback, useState, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import { ApolloClient, useApolloClient } from "@apollo/client";
import _ from "lodash";

import { makeStyles } from "@mui/styles";
import { useTheme, Box, Divider, alpha } from "@mui/material";

import ZoneCategoryGroup from "./editor/ZoneCategoryGroup";
import { DocumentStore } from "../../../../stores/DocumentStore";
import { CoreSwitch } from "../../../core/CoreSwitch";
import { FlowStore } from "../../../../stores/FlowStore";

import { appRoutes } from "../../../../configs/routes";
import { useNotification } from "../../../../context/useNotification";
import { MUTATION_UPDATE_DOCUMENT_RESPONSE } from "../../../../stores/mutations/documents";
import ViewModeButtons from "../buttons/ViewModeButtons";
import EditModeButtons from "../buttons/EditModeButtons";
import { CANVAS_ACTIONS, VALIDATION_MODES } from "../../../../types/constants";
import {
  FlowField,
  SelectOptionDef,
  DocumentZone,
} from "../../../../types/interfaces";
import CanvasCategories from "./editor/CanvasCategories";
import DocumentRejectPopup from "./editor/DocumentRejectPopup";
import DocumentHelper from "../../../../helper/documentHelper";
import { MessagesPanel } from "./canvas/messagePanel/MessagesPanel";

interface Props {
  translation: TFunction;
  documentStore: DocumentStore;
  flowStore: FlowStore;
  goToNextDoc: () => void;
}

const DocumentEditor: FC<Props> = observer(
  ({ documentStore, flowStore, translation, goToNextDoc }) => {
    const { flowId } = useParams<{ flowId: string }>();
    const theme = useTheme();
    const client = useApolloClient();
    const history = useHistory();
    const notification = useNotification();

    const [showEmpty, setShowEmpty] = useState(false);

    //TODO: Check this
    const categories = documentStore.sortFieldsByCategory(
      documentStore.fields as unknown as FlowField[]
    );

    const classes = makeStyles({
      headerActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingLeft: "10px",
        width: "100%",
        height: "45px",
        "& .MuiTypography-root": {
          fontSize: "14px",
        },
      },
      zonesContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 1,
        overflowY: "auto",
      },
      actionsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        padding: "7px 5px 5px 5px",
        height: "60px",
        margin: "0 7px",
      },
      container: {
        height: "calc(100vh - 138px)",
        display: "flex",
        flexDirection: "column",
        margin: "0px !important",
        marginLeft: "0px !important",
        borderLeft: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        borderRadius: "0px !important",
      },
      actionButton: {
        width: "100%",
        marginLeft: "3px",
        marginRight: "3px",
      },
    })();

    const thePagesOrderIsUnchanged = useMemo(() => {
      return _.isEqual(
        documentStore?.document?.pages,
        documentStore.newDocumentPagesOrder
      );
    }, [
      documentStore?.document?.pages,
      documentStore.newDocumentPagesOrder,
    ]) as unknown as boolean;

    const onSaveUpdates = useCallback(() => {
      const incompleteFields = DocumentHelper.checkFilledFields(
        documentStore?.lineItems,
        flowStore.flow?.fields as FlowField[],
        documentStore?.documentFields as DocumentZone[]
      );

      if (incompleteFields.length > 0) {
        const errorMessage = translation("incompletedRequiredFieldsError", {
          incompleteFields: incompleteFields.join(", "),
        });
        notification.error(errorMessage);
        return;
      }

      documentStore.changeCanvasAction(CANVAS_ACTIONS.pan);

      if (!thePagesOrderIsUnchanged) {
        documentStore
          .reorderDocumentPages(client, documentStore.newDocumentPagesOrder)
          .catch((error: Error) => {
            notification.error(
              translation(error?.message || "errorSavingUpdates")
            );
          });
      }

      documentStore
        .updateDocumentPage(
          client as ApolloClient<MUTATION_UPDATE_DOCUMENT_RESPONSE>,
          false
        )
        .then(() => {
          if (documentStore.isGetNextDocumentChecked) {
            documentStore.setViewMode(true);
            goToNextDoc();
          } else {
            history.push(appRoutes.FlowDetails(flowId));
          }
        })
        .catch((error: Error) => {
          notification.error(
            translation(error?.message || "errorSavingUpdates")
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSimpleMode = !documentStore.isSimpleMode;

    const toggleEncodingMode = () => {
      const updatedMode = isSimpleMode
        ? VALIDATION_MODES.simple
        : VALIDATION_MODES.advanced;

      documentStore.setValidationMode(updatedMode);
    };

    const handleShowEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowEmpty(event.target.checked);
    };

    const handleCancel = () => {
      documentStore.clearDocument();

      const redirectUrl = appRoutes.FlowDetails(flowId);
      //FIXME: Keep for later use
      // if (documentStore.isQaMode) {
      //   redirectUrl = appRoutes.QA();
      // }

      history.push(redirectUrl);
    };

    const handleSkip = useCallback(() => {
      documentStore
        .loadDocument(
          client,
          undefined,
          undefined,
          flowId,
          documentStore.document?.identifier ?? ""
        )
        .then((hasNextDoc) => {
          if (hasNextDoc)
            history.replace(
              appRoutes.DocumentView(flowId, documentStore.document?.identifier)
            );
          else {
            history.push(appRoutes.FlowDetails(flowId));
            notification.info(translation("noMoreSkipDocs"));
          }
        })
        .catch((error: Error) => {
          history.push(appRoutes.RetryNextDocument(flowId));
          notification.error(
            translation(error?.message || "errorGettingNextDoc")
          );
        });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <Box className={classes.container} id="containerZones">
          {documentStore.messagePanelData ? (
            <MessagesPanel />
          ) : (
            <>
              <Box className={classes.headerActions}>
                {documentStore.documentFields?.length !== 0 && (
                  <CoreSwitch
                    value={showEmpty}
                    label={translation("editor_showEmptyLabel")}
                    onChange={handleShowEmpty}
                    tabIndex={-1}
                    size="small"
                  />
                )}
                {!documentStore.viewMode &&
                  !documentStore.updatingPage &&
                  flowStore.flow?.flowType !== "classification" && (
                    <CoreSwitch
                      checked={isSimpleMode}
                      label={translation("validation_selection_driven_label")}
                      description={translation(
                        "validation_selection_driven_description"
                      )}
                      onChange={toggleEncodingMode}
                      tabIndex={-1}
                      size="small"
                    />
                  )}
              </Box>

              <Divider />

              <Box className={classes.zonesContainer}>
                {categories?.length > 0 ? (
                  categories
                    .filter(
                      (category) =>
                        category?.fields?.length > 0 &&
                        category?.fields.some(
                          (field) => field.isVisible !== false
                        )
                    )
                    .map((category, index) => (
                      <ZoneCategoryGroup
                        //TODO: Fix this
                        key={index}
                        category={
                          {
                            name: category.name,
                            key: category.name,
                            label: category.name,
                          } as SelectOptionDef
                        }
                        documentStore={documentStore}
                        translation={translation}
                        theme={theme}
                        showEmpty={showEmpty}
                      />
                    ))
                ) : (
                  <CanvasCategories
                    categories={flowStore?.flow?.categories}
                    isDisabled={documentStore.viewMode || false}
                  />
                )}
              </Box>

              <Box className={classes.actionsContainer}>
                {documentStore.viewMode && !documentStore.disabledMode ? (
                  <ViewModeButtons
                    handleCancel={handleCancel}
                    buttonsClass={classes.actionButton}
                    translation={translation}
                    handleReject={() =>
                      documentStore.setIsRejectReasonsPopupOpen(true)
                    }
                  />
                ) : (
                  <EditModeButtons
                    translation={translation}
                    buttonsClass={classes.actionButton}
                    handleCancel={handleCancel}
                    handleSave={onSaveUpdates}
                    handleReject={() =>
                      documentStore.setIsRejectReasonsPopupOpen(true)
                    }
                    handleSkip={handleSkip}
                  />
                )}
              </Box>
            </>
          )}

          <DocumentRejectPopup
            open={documentStore.isRejectReasonsPopupOpen}
            client={client}
          />
        </Box>
      </>
    );
  }
);

export default memo(DocumentEditor);
