import React from "react";
import { TFunction } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { alpha, Card, Typography, Box, useTheme, Divider } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import { useStores } from "../../../stores/StoresProvider";
import CoreButton from "../../core/CoreButton";
import InfoField from "./InfoField";
import { USER_SCOPES } from "../../../types/constants";

type Props = {
  t: TFunction;
  title?: string;
  titleDividerOn?: boolean;
  hasDivider?: boolean;
  hasFields?: boolean;
  cardTitle?: string;
  cardInfo?: string;
  buttonTitle?: string;
  isDeleteAccount?: boolean;
  isChangePassword?: boolean;
  warning?: string;
  openDrawer?: (value: boolean) => void;
  handleDeleteModal?: (value: boolean) => void;
};

const AccountCard: React.FC<Props> = ({
  t,
  title,
  titleDividerOn = true,
  hasDivider = false,
  hasFields = false,
  cardTitle,
  cardInfo,
  buttonTitle,
  isDeleteAccount = false,
  isChangePassword = false,
  warning,
  openDrawer,
  handleDeleteModal,
}) => {
  const { userStore } = useStores();
  const theme = useTheme();

  const useStyle = makeStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    infoCardStyle: {
      maxWidth: "1200px",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "none",
      border: isDeleteAccount
        ? `1px solid ${alpha(theme.palette.error.main, 0.5)}`
        : `1px solid ${alpha(theme.palette.divider, 0.2)}`,
    },
    infoCardBox: {
      display: "flex",
      flexDirection: "row-reverse",
      marginTop: "auto",
      width: "100%",
      backgroundColor: isDeleteAccount
        ? `${alpha(theme.palette.error.main, 0.2)}`
        : "",
      padding: "10px 20px 10px 0",
    },
    titleDivider: {
      maxWidth: "1200px",
      margin: "5px 0",
    },
    divider: {
      border: isDeleteAccount ? `${alpha(theme.palette.error.main, 0.5)}` : "",
      width: "100%",
    },
    cardTitle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      padding: "20px 20px 10px 20px",
      gap: "10px",
    },
    cardInfo: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      padding: "0px 20px 10px 20px",
      opacity: 0.6,
      fontSize: "12px",
    },
    additionalInfo: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      padding: "0px 20px 10px 20px",
      fontSize: "12px",
      fontStyle: "italic",
    },
    dangerButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
    title: {
      fontSize: "18px",
    },
    warningIcon: {
      color: theme.palette.error.main,
    },
  });

  const classes = useStyle();

  const handleButtonClick = () => {
    if (isChangePassword && openDrawer) openDrawer(true);
    if (isDeleteAccount && handleDeleteModal) {
      handleDeleteModal(true);
    }
  };

  const fields = [
    {
      title: t("email"),
      value: userStore.user?.email ?? "",
    },
    { title: t("firstName"), value: userStore.user?.first_name ?? "" },
    { title: t("lastName"), value: userStore.user?.last_name ?? "" },
  ];

  return (
    <Box className={classes.mainContainer}>
      <Box>
        {!!title && (
          <Typography className={classes.title}>{t(title)}</Typography>
        )}
        {titleDividerOn && <Divider className={classes.titleDivider} />}
      </Box>
      <Card className={classes.infoCardStyle} sx={{ boxShadow: "none" }}>
        {hasFields &&
          fields.map((field, index) => (
            <InfoField
              key={index}
              title={field.title ?? ""}
              value={field.value ?? ""}
              translate={t}
            />
          ))}
        {!!cardTitle && (
          <Box className={classes.cardTitle}>
            <Typography>{t(cardTitle)}</Typography>
            {isDeleteAccount && <WarningIcon className={classes.warningIcon} />}
          </Box>
        )}
        {!!cardInfo && (
          <Typography className={classes.cardInfo}>{t(cardInfo)}</Typography>
        )}
        {!!warning && userStore.user?.scope === USER_SCOPES.owner && (
          <Typography className={classes.additionalInfo}>
            {t(warning)}
          </Typography>
        )}
        {hasDivider && (
          <>
            <Divider className={classes.divider} />
            <Box className={classes.infoCardBox}>
              <CoreButton
                onClick={handleButtonClick}
                className={isDeleteAccount ? classes.dangerButton : ""}
                variant="contained"
              >
                {t(buttonTitle ?? "")}
              </CoreButton>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

export default AccountCard;
