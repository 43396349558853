import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import Draggable from "react-draggable";

import { makeStyles } from "@mui/styles";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

import { Box, Collapse, Icon, alpha, useTheme } from "@mui/material";

import { useStores } from "../../../../stores/StoresProvider";
import { ChatGptTabs } from "../../../../types/constants";
import CoreTooltip from "../../../core/CoreTooltip";
import ChatBoxPopup from "./ChatBoxPopup";

interface Props {
  children: React.ReactElement;
}

const ChatGptActionComponent: FC<Props> = observer(({ children }) => {
  const { t } = useTranslation("chatGpt");
  const {
    chatGptStore: {
      isChatGptPopupOpen,
      setIsChatGptPopupOpen,
      setChatGptViewTab,
      setTabToReturn,
    },
  } = useStores();

  const [isDragging, setIsDragging] = useState(false);

  const theme = useTheme();

  const useStyle = makeStyles({
    div: {
      position: "relative",
      width: "auto",
      display: "contents",
      overflowX: "hidden",
    },
    box: {
      position: "absolute",
      top: `calc(50% - 75px)`,
      right: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "end",
      width: "30px",
      height: "150px",
      zIndex: 100,
    },
    floatingBox: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: alpha(theme.palette.highlight.main, 0.5),
      alignItems: "center",
      borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      borderTop: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      borderLeft: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
      opacity: 0.7,
      "&:hover": {
        opacity: 1,
      },
      height: "200px",
      cursor: "pointer",
      boxShadow: `0 0.5rem 2rem rgba(0, 0, 0, 0.3)`,
      width: isChatGptPopupOpen ? "auto" : "30px",
    },
  });

  const classes = useStyle();

  useEffect(() => {
    return () => {
      setIsChatGptPopupOpen(false);
    };
    // eslint-disable-next-line
  }, []);

  const handleOnClose = () => {
    setIsChatGptPopupOpen(false);
    setTabToReturn("");
  };

  const handleClick = () => {
    setChatGptViewTab(ChatGptTabs.home);
    setIsChatGptPopupOpen(true);
  };

  return (
    <Box className={classes.div}>
      <Box className={classes.box}>
        <Draggable
          axis="y"
          bounds={{
            top: -window.innerHeight / 2 + 64 + 100,
            bottom: window.innerHeight / 2 - 100,
          }}
          disabled={isChatGptPopupOpen}
          onDrag={() => {
            if (!isDragging) setIsDragging(true);
          }}
          onStop={() => {
            if (isDragging) setIsDragging(false);
          }}
        >
          <div>
            <CoreTooltip
              title={
                isDragging || isChatGptPopupOpen
                  ? ""
                  : t("chat_gpt_ask_something")
              }
              placement="left"
            >
              <Box
                className={classes.floatingBox}
                onClick={isDragging ? undefined : handleClick}
              >
                <Collapse orientation="horizontal" in={!isChatGptPopupOpen}>
                  <Box>
                    <Icon>
                      <ContactSupportIcon />
                    </Icon>
                  </Box>
                </Collapse>
              </Box>
            </CoreTooltip>
          </div>
        </Draggable>

        <ChatBoxPopup
          t={t}
          isOpen={isChatGptPopupOpen}
          onClose={handleOnClose}
        />
      </Box>
      {children}
    </Box>
  );
});

export default ChatGptActionComponent;
