import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";
import { observer } from "mobx-react";
import { TFunction } from "i18next";
import moment from "moment";

import { DefaultTheme, makeStyles, Styles } from "@mui/styles";
import UploadIcon from "@mui/icons-material/Upload";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

import {
  useTheme,
  Box,
  Menu,
  MenuList,
  Typography,
  ListItemIcon,
  MenuItem,
  Divider,
  Theme,
  CircularProgress,
} from "@mui/material";

import CoreButton from "../../../core/CoreButton";
import CoreConfirmModal from "../../../core/CoreConfirmModal";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { FlowNode, HeaderActions } from "../../../../types/interfaces";
import DiagramVersionSelector from "./diagramBuilder/DiagramVersionSelector";
import CoreTooltip from "../../../core/CoreTooltip";
import { ImportDiagramModal } from "./ImportDiagramModal";

interface Props {
  t: TFunction;
  toggleDiagramDrawer: () => void;
  setLoadingNodes: (loading: boolean) => void;
}

const getStyles = (theme: Theme) => {
  return {
    extraActionsBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    button: {
      "&:hover": {
        color: `${theme.palette.highlight.main} !important`,
      },
    },
    buttonsBox: {
      display: "flex",
      paddingRight: "10px",
      gap: "10px",
    },
    editButtonBox: {
      paddingRight: "10px",
    },
    moreVertIcon: {
      borderRadius: 100,
      minWidth: 0,
      padding: "4px 5px",
    },
    extraHeaderActions: {
      textAlign: "right",
      marginTop: 5,
      display: "block",
    },
    divider: {
      marginTop: "0 !important",
      marginBottom: "0 !important",
    },
    menuList: {
      "& ul": {
        padding: 0,
        boxShadow: "11px 16px 7px -10px rgba(0,0,0,0.43)",
      },
    },
    menuItemAction: {
      margin: "3px",
      marginLeft: "5px",
      textTransform: "none",
      transition: "none",
      display: "flex",
      alignItems: "stretch",
    },
    menuItem: {
      background: theme.palette.background.paper,
      textAlign: "center",
      display: "flex",
      justifyContent: "flex-start",
      "&:hover": {
        color: `${theme.palette.highlight.main} !important`,
      },
      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
    menuCategory: {
      background: theme.palette.background.paper,
      "&:hover": {
        background: theme.palette.background.paper,
      },
      textAlign: "center",
      display: "flex",
      justifyContent: "flex-start",
      cursor: "default",
      opacity: 0.8,
      fontSize: "14px",
    },
    iconStyle: {
      color: "inherit",
    },
    loadingVariablesBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "10px",
    },
  } as Styles<DefaultTheme, object, string>;
};

const DesignerTabHeaderActions: React.FC<Props> = observer(
  ({ t, toggleDiagramDrawer, setLoadingNodes }) => {
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();
    const { flowSettingsStore } = useStores();
    const notification = useNotification();
    const reactFlow = useReactFlow();

    const classes = makeStyles(getStyles(theme))();

    const [modalOpen, setModalOpen] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPromoteLoading, setIsPromoteLoading] = useState(false);

    const open = Boolean(anchorEl);

    const isCurrentVersion = useMemo(
      () =>
        flowSettingsStore.flowDiagrams.some(
          (flowDiagram) =>
            flowDiagram.current === true &&
            flowDiagram.identifier === flowSettingsStore.currentDiagramId
        ),
      [flowSettingsStore.currentDiagramId, flowSettingsStore.flowDiagrams]
    );

    const handleCloseModal = () => {
      setModalOpen("");
    };

    const handleConfirm = () => {
      flowSettingsStore
        .createFlowDiagram({
          nodes: reactFlow.getNodes() as FlowNode[],
          edges: reactFlow.getEdges(),
        })
        .then(() => {
          notification.success(t("diagramSavedSuccessfully"));
          flowSettingsStore.setIsDiagramEditable(false);
          flowSettingsStore.setDiagramHasBeenUpdated(false);
          handleCloseModal();
          //TODO: Consider handling the case where the first diagram is created, but then the latest diagram fetch throws an error, and the nodes remain in the designer tab in UI
          flowSettingsStore.getLatestFlowDiagram(id).catch((error: Error) => {
            notification.error(t(error?.message || "getFlowDiagramError"));
          });
          flowSettingsStore.getFlowDiagrams(id).catch((error: Error) => {
            notification.error(t(error?.message || "flowVersionsFetchFail"));
          });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "errorSaveDiagramChanges"));
        });
    };

    const handleClose = () => {
      flowSettingsStore.setIsDiagramEditable(false);

      if (
        flowSettingsStore.diagramHasBeenUpdated &&
        flowSettingsStore.flowDiagram
      ) {
        //Used to reset the diagram to initial value
        flowSettingsStore.resetFlowDiagram();
        flowSettingsStore.setDiagramHasBeenUpdated(false);
        flowSettingsStore
          .loadContextObjects({
            nodes: flowSettingsStore.nonAlteredFlowDiagram?.specs?.nodes || [],
            edges: flowSettingsStore.nonAlteredFlowDiagram?.specs?.edges || [],
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "contextObjectsError"));
          });
      }
    };

    const handlePromoteToCurrent = () => {
      setIsPromoteLoading(true);

      flowSettingsStore
        .promoteToCurrent(id)
        .then(() => {
          flowSettingsStore
            .getFlowDiagrams(id)
            .then(() => {
              notification.success(t("success_promoteToCurrent"));
            })
            .catch((error: Error) => {
              flowSettingsStore.setFlowDiagramsList([]);
              notification.error(t(error?.message || "flowVersionsFetchFail"));
            })
            .finally(() => {
              handleCloseModal();
              setIsPromoteLoading(false);
            });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "error_promoteToCurrent"));
        });
    };

    const deleteDiagramVersion = () => {
      setIsLoading(true);

      const flowId = flowSettingsStore.flow?.identifier || "";
      const diagramId = flowSettingsStore.currentDiagramId || "";

      flowSettingsStore
        .deleteDiagramVersion(flowId, diagramId)
        .then(() => {
          notification.success(t("success_deleteDiagramVersion"));
          setLoadingNodes(true);

          flowSettingsStore
            .loadDiagramData(id, notification, t)
            .catch((error: Error) => {
              notification.error(t(error?.message || "getFlowDiagramError"));
            })
            .finally(() => {
              setLoadingNodes(false);
            });
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "error_deleteDiagramVersion"));
        })
        .finally(() => {
          setIsLoading(false);
          handleCloseModal();
        });
    };

    const exportJson = () => {
      const jsonPayload = JSON.stringify(
        flowSettingsStore.flowDiagram,
        null,
        2
      );

      if (jsonPayload) {
        const blob = new Blob([jsonPayload], { type: "application/json" });

        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Exported diagram - ${
          flowSettingsStore.flow?.identifier as unknown as string
        } - ${moment().format("DD-MM-YY-HH-mm")}.json`;

        a.click();
        return true;
      }

      return false;
    };

    const exportPng = () => flowSettingsStore.setTriggerDiagramPngExport(true);

    const exportHeaderActions: HeaderActions[] = [
      {
        label: t("exportJSON"),
        tooltip: t("exportJSON"),
        onClick: () => exportJson(),
        startIcon: <InsertDriveFileIcon />,
        disabled:
          flowSettingsStore.loadingDiagram || !flowSettingsStore.flowDiagram,
        disabledTooltip: "disableExportTooltip",
      },
      {
        label: t("exportPNG"),
        tooltip: t("exportPNG"),
        onClick: () => exportPng(),
        startIcon: <ImageIcon />,
        disabled:
          flowSettingsStore.loadingDiagram || !flowSettingsStore.flowDiagram,
        disabledTooltip: "disableExportTooltip",
      },
      {
        label: "Load template",
        tooltip: "Load template",
        onClick: toggleDiagramDrawer,
        startIcon: <AccountTreeIcon />,
        disabled: flowSettingsStore.loadingDiagram,
        disabledTooltip: "disableExportTooltip",
        divider: true,
      },
    ];

    const fixedHeaderActions: HeaderActions[] =
      flowSettingsStore.flowDiagram !== null &&
      !flowSettingsStore.loadingDiagram
        ? [
            ...exportHeaderActions,
            {
              label: t("deleteVersion"),
              tooltip: t("deleteVersion"),
              disabledTooltip: t("deleteVersionTooltip"),
              onClick: () => {
                if (!isCurrentVersion) setModalOpen("delete");
              },
              startIcon: (
                <DeleteForeverOutlinedIcon
                  sx={{ color: theme.palette.error.main }}
                />
              ),
              disabled: isCurrentVersion,
              color: theme.palette.error.main,
            },
          ]
        : exportHeaderActions;

    const headerActions: HeaderActions[] =
      !isCurrentVersion &&
      flowSettingsStore.flowDiagram !== null &&
      !flowSettingsStore.loadingDiagram
        ? [
            ...fixedHeaderActions,
            {
              label: t("promoteToCurrent"),
              tooltip: t("promoteToCurrent"),
              onClick: () => setModalOpen("promote"),
              startIcon: <PublishedWithChangesIcon />,
              disabled:
                isPromoteLoading ||
                !flowSettingsStore.flowDiagrams ||
                flowSettingsStore.flowDiagrams?.length === 0,
            },
          ]
        : fixedHeaderActions;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const onOptionClick = (headerAction: HeaderActions) => {
      if (headerAction.onClick && !headerAction.disabled) {
        setAnchorEl(null);
        headerAction.onClick();
      }
    };

    return (
      <Box className={classes.extraActionsBox}>
        {flowSettingsStore.loadingContextObjects && (
          <CoreTooltip title={t("loadingContextObjects")}>
            <Box className={classes.loadingVariablesBox}>
              <CircularProgress size={24} />
            </Box>
          </CoreTooltip>
        )}

        {flowSettingsStore.isDiagramEditable ? (
          <Box className={classes.buttonsBox}>
            <CoreButton
              onClick={handleClose}
              variant="neutral"
              size="large"
              className={classes.button}
            >
              {t("cancel")}
            </CoreButton>

            <CoreButton
              onClick={() => setModalOpen("change")}
              variant="neutral"
              size="large"
              startIcon={<DoneIcon />}
              className={classes.button}
              disabled={!flowSettingsStore.diagramHasBeenUpdated}
            >
              {t("save")}
            </CoreButton>

            <Divider orientation="vertical" flexItem />

            <CoreButton
              onClick={() => setModalOpen("import")}
              variant="neutral"
              size="large"
              startIcon={<UploadIcon />}
              className={classes.button}
              disabled={flowSettingsStore.loadingDiagram}
            >
              {t("import")}
            </CoreButton>
          </Box>
        ) : (
          <Box className={classes.editButtonBox}>
            <CoreButton
              onClick={() => flowSettingsStore.setIsDiagramEditable(true)}
              variant="neutral"
              size="large"
              startIcon={<EditIcon />}
              className={classes.button}
              disabled={flowSettingsStore.loadingDiagram}
            >
              {t("edit")}
            </CoreButton>
          </Box>
        )}

        {flowSettingsStore.currentDiagramId !== null && (
          <DiagramVersionSelector t={t} />
        )}

        <Box className={classes.extraHeaderActions}>
          {headerActions.length > 0 && (
            <div>
              {!flowSettingsStore.isDiagramEditable && (
                <CoreButton
                  variant="neutral"
                  onClick={(event) => handleClick(event)}
                  size="medium"
                  className={classes.moreVertIcon}
                >
                  <MoreVertIcon sx={{ fontSize: 20 }} />
                </CoreButton>
              )}

              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                className={classes.menuList}
              >
                <MenuList dense>
                  {headerActions.map((headerAction, index) => {
                    return (
                      <div key={`submenu-${headerAction.label}-${index}`}>
                        <MenuItem
                          key={index}
                          onClick={() => onOptionClick(headerAction)}
                          disabled={headerAction.disabled}
                          className={
                            headerAction.onClick
                              ? classes.menuItem
                              : classes.menuCategory
                          }
                        >
                          {headerAction.startIcon && (
                            <ListItemIcon className={classes.iconStyle}>
                              {headerAction.startIcon}
                            </ListItemIcon>
                          )}

                          <Typography
                            className={classes.menuItemAction}
                            sx={{
                              color:
                                (headerAction?.color as string) || "inherit",
                            }}
                          >
                            {headerAction.disabledTooltip &&
                            headerAction.disabled === true ? (
                              <CoreTooltip
                                title={t(headerAction.disabledTooltip)}
                              >
                                <span>{headerAction.label}</span>
                              </CoreTooltip>
                            ) : (
                              headerAction.label
                            )}
                          </Typography>
                        </MenuItem>
                        {headerAction.divider && (
                          <Divider className={classes.divider} />
                        )}
                      </div>
                    );
                  })}
                </MenuList>
              </Menu>
            </div>
          )}
        </Box>

        <CoreConfirmModal
          open={modalOpen === "change"}
          onClose={handleCloseModal}
          title={t("changeDiagramModalTitle")}
          cancelButtonLabel={t("cancel")}
          confirmButtonLabel={t("confirm")}
          onCancel={handleCloseModal}
          onConfirm={handleConfirm}
          isDisable={flowSettingsStore.loadingCreateDiagram}
        />

        <CoreConfirmModal
          open={modalOpen === "promote"}
          onClose={handleCloseModal}
          title={t("promoteToCurrent_title")}
          cancelButtonLabel={t("deleteModal_cancelButton")}
          confirmButtonLabel={t("deleteModal_confirmButton")}
          onCancel={handleCloseModal}
          onConfirm={handlePromoteToCurrent}
          isDisable={isPromoteLoading}
        />

        <CoreConfirmModal
          open={modalOpen === "delete"}
          onClose={handleCloseModal}
          title={t("deleteDiagramVersion_title")}
          subtitle={t("deleteDiagramVersion_subtitle")}
          cancelButtonLabel={t("deleteModal_cancelButton")}
          confirmButtonLabel={t("deleteModal_confirmButton")}
          onCancel={handleCloseModal}
          onConfirm={deleteDiagramVersion}
          isDisable={isLoading}
          disableForCancel={isLoading}
        />

        <ImportDiagramModal
          isOpen={modalOpen === "import"}
          handleClose={handleCloseModal}
          t={t}
        />
      </Box>
    );
  }
);

export default DesignerTabHeaderActions;
