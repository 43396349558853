import React, { FC } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";

import CoreTooltip from "./CoreTooltip";

interface Props extends SwitchProps {
  label?: string;
  labelPlacement?: "bottom" | "end" | "start" | "top";
  description?: string;
}

const useStyles = makeStyles({
  icon: {
    alignSelf: "center",
    fontSize: "20px",
    marginLeft: "5px",
  },
  box: {
    display: "flex",
  },
  formControl: {
    margin: "0px",
  },
});

export const CoreSwitch: FC<Props> = ({
  label,
  labelPlacement = "end",
  description,
  ...extended
}) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <Box className={classes.box}>
        <FormControlLabel
          //TODO: check future switch
          control={<Switch {...extended} />}
          label={label || ""}
          labelPlacement={labelPlacement}
          className={classes.formControl}
        />

        {description && (
          <CoreTooltip title={description}>
            <InfoOutlinedIcon className={classes.icon} />
          </CoreTooltip>
        )}
      </Box>
    </FormGroup>
  );
};
