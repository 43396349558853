import React from "react";
import { observer } from "mobx-react";

import { useStores } from "../../../stores/StoresProvider";

import OwnerRegistration from "./registration/OwnerRegistration";
import ReviewPlan from "./review/ReviewPlan";
import EnvironmentCreation from "./environment/EnvironmentCreation";

interface Types {
  [key: number]: React.FC;
}

const SIGN_UP_COMPONENTS: Types = {
  0: OwnerRegistration,
  1: EnvironmentCreation,
  2: ReviewPlan,
};

const SignUpJourney: React.FC = observer(() => {
  const { landingStore } = useStores();
  const { registrationStep } = landingStore;

  const CurrentStep = SIGN_UP_COMPONENTS[registrationStep] || null;

  return <CurrentStep />;
});

export default SignUpJourney;
