import React, { FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import { useStores } from "../../../../../../stores/StoresProvider";
import { SUBSCRIPTION_TAB_KEYS } from "../../../../../../types/interfaces/subscription";
import CoreTabs from "../../../../../core/CoreTabs";
import { BillingDetails } from "./tabs/BillingDetails";
import { Receipts } from "./tabs/Receipts";
import { SubscriptionOverview } from "./tabs/SubscriptionOverview";
import { UsageHistory } from "./tabs/UsageHistory";

const useStyles = makeStyles({
  container: {
    marginTop: "20px",
  },
});

const TABS = [
  {
    key: SUBSCRIPTION_TAB_KEYS.overview,
    label: "tab_content_overview_label",
    component: SubscriptionOverview,
  },
  {
    key: SUBSCRIPTION_TAB_KEYS.usageHistory,
    label: "tab_content_usage_history_label",
    component: UsageHistory,
  },
  {
    key: SUBSCRIPTION_TAB_KEYS.receipts,
    label: "tab_content_receipts_label",
    component: Receipts,
  },
  {
    key: SUBSCRIPTION_TAB_KEYS.billingDetails,
    label: "tab_content_billing_details_label",
    component: BillingDetails,
  },
];

const Component: FC<{ translate: TFunction }> = observer(({ translate }) => {
  const classes = useStyles();
  const { t } = useTranslation("subscription");
  const { subscriptionStore } = useStores();

  const TabComponent = useMemo(
    () => TABS[subscriptionStore.currentTabIndex]?.component,
    [subscriptionStore.currentTabIndex]
  );

  const onTabChange = (_: React.SyntheticEvent, newIndex: number) => {
    subscriptionStore.setTabIndex(newIndex);
  };

  return (
    <Box className={classes.container}>
      <CoreTabs
        scrollButtons
        t={t}
        variant="scrollable"
        options={TABS}
        value={subscriptionStore.currentTabIndex}
        onChange={onTabChange}
      />

      {TabComponent && <TabComponent translate={translate} />}
    </Box>
  );
});

export const TabsContent = memo(Component);
