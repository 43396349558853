import { gql } from "@apollo/client";

export interface CREATE_IDENTITY_TYPE {
  createIdentity: boolean;
}

export const CREATE_IDENTITY = gql`
  mutation createIdentity($identities: [IdentityInput]) {
    createIdentity(identities: $identities)
  }
`;

export const EDIT_IDENTITY = gql`
  mutation editIdentity($identity: EditIdentityInput) {
    editIdentity(identity: $identity)
  }
`;

export interface EDIT_IDENTITY_TYPE {
  editIdentity: boolean;
}

export const DELETE_IDENTITY = gql`
  mutation deleteIdentity($appIds: [String!]!) {
    deleteIdentity(appIds: $appIds)
  }
`;

export interface DELETE_IDENTITY_TYPE {
  deleteIdentity: boolean;
}

export const UPDATE_TARGET_IDENTITY = gql`
  mutation updateTargetIdentity(
    $appId: String!
    $target: String!
    $updatedSchema: JSON!
  ) {
    updateTargetIdentity(
      appId: $appId
      target: $target
      updatedSchema: $updatedSchema
    )
  }
`;

export interface UPDATE_TARGET_IDENTITY_TYPE {
  updateTargetIdentity: boolean;
}
