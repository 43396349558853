//Module imports
import React, { FC, memo } from "react";

//Design imports
import { Box } from "@mui/system";
import {
  Alert,
  AlertColor,
  AlertProps,
  AlertTitle,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

//Internal imports
import { NotificationTypes } from "../../context/useNotification";

interface Props extends AlertProps {
  description?: string | React.ReactNode;
  type?: NotificationTypes;
  noBackground?: boolean;
  isLoading?: boolean;
  alignButtonToEnd?: boolean;
}

const CoreNotificationBox: FC<Props> = ({
  description,
  type,
  noBackground = false,
  isLoading = false,
  children,
  alignButtonToEnd,
  ...otherProps
}) => {
  const theme = useTheme();

  const useStyles = makeStyles({
    container: {
      maxWidth: "800px",
      display: "flex",
      paddingLeft: 0,
      margin: "10px 0",
      flexWrap: "wrap",
    },
    alert: {
      borderRadius: 0,
      alignItems: "center",
      backgroundColor: theme.palette.primary.dark,
      background: noBackground ? "none" : undefined,
      boxShadow: "none",
      width: "100%",
      display: "flex",
      padding: "10px 20px",
      "& .MuiAlert-message": {
        padding: 0,
        display: "flex",
        gap: "25px",
        flex: alignButtonToEnd ? 1 : undefined,
        justifyContent: alignButtonToEnd ? "space-between" : undefined,
      },
    },
    alertTitle: {
      padding: "0",
      margin: "auto 0",
      fontSize: "14px",
    },
    icon: {
      margin: "auto",
    },
  });

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Alert
        severity={type as AlertColor | undefined}
        elevation={2}
        iconMapping={{
          error: <ErrorOutlineOutlinedIcon className={classes.icon} />,
          warning: <WarningAmberOutlinedIcon className={classes.icon} />,
          success: <CheckCircleOutlineOutlinedIcon className={classes.icon} />,
          info: isLoading ? (
            <CircularProgress
              size={25}
              sx={{ color: theme.palette.highlight.main }}
            />
          ) : (
            <InfoOutlinedIcon className={classes.icon} />
          ),
        }}
        className={classes.alert}
        {...otherProps}
      >
        <AlertTitle className={classes.alertTitle}>{description}</AlertTitle>
        {children}
      </Alert>
    </Box>
  );
};

export default memo(CoreNotificationBox);
