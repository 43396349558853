/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { useReactFlow } from "reactflow";
import { useTranslation } from "react-i18next";

import { useStores } from "../../../../../stores/StoresProvider";
import CoreDrawer from "../../../../core/CoreDrawer";
import NodesListDisplay from "../NodesListDisplay";
import AssetConfig from "../../../addNewFlow/tabs/assetsCore/AssetConfig";
import CreateConnection from "../../../connections/CreateConnection";
import {
  ASSET_TYPES,
  DIAGRAM_NODE_CATEGORIES,
  NODE_ASSET_TYPE,
  NODE_TYPES,
} from "../../../../../types/constants";
import {
  AssetConfiguration,
  FlowNode,
  FormData,
} from "../../../../../types/interfaces";
import { useNotification } from "../../../../../context/useNotification";

/**
 * Handles create new node and edit existing node
 */
interface Props {
  loading: boolean;
}

export const DiagramNodeHandlerDrawer: React.FC<Props> = observer(
  ({ loading }) => {
    const { t } = useTranslation("addFlow");
    const { flowSettingsStore } = useStores();
    const notification = useNotification();
    const reactFlow = useReactFlow();

    const [nodeKey, setNodeKey] = useState("");
    const [openConn, setOpenConn] = useState(false);
    const [shouldRefetchAsset, setShouldRefetchAsset] = useState(false);
    const [openFileBrowser, setOpenFileBrowser] = useState(false);

    const diagramNodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const isOpen = useMemo(
      () =>
        !!flowSettingsStore?.nodeIdToEdit || flowSettingsStore?.isAddNodeOpened,
      [flowSettingsStore.nodeIdToEdit, flowSettingsStore.isAddNodeOpened]
    );

    const drawerTitle = useMemo(() => {
      if (flowSettingsStore.nodeIdToEdit) {
        return !flowSettingsStore.isDiagramEditable
          ? t("viewNodeDetails")
          : t("editNode");
      }

      return t("addNode");
    }, [
      flowSettingsStore.nodeIdToEdit,
      t,
      flowSettingsStore.isDiagramEditable,
    ]);

    const selectedItemPosition = useMemo(() => {
      const position = diagramNodes?.findIndex(
        (node) => node.identifier === flowSettingsStore.nodeIdToEdit
      );
      return position < 0 || position === undefined ? undefined : position;
    }, [flowSettingsStore.nodeIdToEdit, diagramNodes]);

    //TODO: To be tested for file browser
    const hasBackButton = useMemo(() => {
      return (
        (nodeKey !== "" &&
          !flowSettingsStore.nodeIdToEdit &&
          (selectedItemPosition === undefined ||
            selectedItemPosition === -1)) ||
        openFileBrowser
      );
    }, [
      nodeKey,
      selectedItemPosition,
      openFileBrowser,
      flowSettingsStore.nodeIdToEdit,
    ]);

    useEffect(() => {
      if (flowSettingsStore.nodeIdToEdit) {
        const nodeKey = diagramNodes?.find(
          (node) => node.identifier === flowSettingsStore.nodeIdToEdit
        )?.key;
        setNodeKey(nodeKey || "");
      }
    }, [flowSettingsStore.nodeIdToEdit, diagramNodes]);

    const handleSelectNode = (key: string) => {
      setNodeKey(key);
    };

    const onConnClose = (goBack = false) => {
      if (!goBack) {
        handleClose();
      }
      setOpenConn(false);
    };

    const formatExecutionSchema = (executionSchema: string[]) => {
      if (!executionSchema || executionSchema?.length === 0) {
        return [];
      }

      return executionSchema;
    };

    const onHandleClick = (identifier: string) => {
      flowSettingsStore.setParentNodeId(identifier);
    };

    const handleOnConfirm = (formData: FormData) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const nodeParameters: { [key: string]: string | string[] } = Object.keys(
        formData
      )
        .filter((value) => !(value in ["name", "description"]))
        .reduce((obj: any, key: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          obj[key] = formData[key];
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return obj;
        }, {});

      const inputProps = {
        inputPath: (nodeParameters?.nodeInput as string) || null,
      };

      const outputProps = {
        outputPath: (nodeParameters?.nodeOutput as string) || null,
      };

      const executionSchema = formatExecutionSchema(
        nodeParameters?.executionSchema as string[]
      );

      const origConfig = flowSettingsStore.nodesList.find(
        (item) => item.key === nodeKey
      );

      const isGroupType = origConfig?.type === DIAGRAM_NODE_CATEGORIES.group;

      const nodeIdentifier = uuidv4();

      let parentId;
      let extraProps = {} as Partial<FlowNode>;

      if (flowSettingsStore.parentNodeId) {
        const parentNode = reactFlow.getNode(
          flowSettingsStore.parentNodeId
        ) as unknown as FlowNode;

        if (parentNode && parentNode.parentId) {
          parentId = parentNode.parentId;
        }

        if (parentNode?.trueBranch) {
          extraProps = { trueBranch: true };
        } else if (parentNode?.falseBranch) {
          extraProps = { falseBranch: true };
        }
      }

      if (flowSettingsStore.nodeIdToEdit) {
        const extraProps = {
          ...inputProps,
          ...outputProps,
          parameters: { ...nodeParameters, executionSchema } as unknown as {
            [key: string]: string | string[];
          },
        };
        flowSettingsStore.updateDiagramNode(
          reactFlow,
          {
            ...formData,
            ...extraProps,
          },
          {
            name: formData.name,
            description: formData.description,
            ...extraProps,
          },
          notification,
          t
        );
      } else {
        const newNode = {
          id: nodeIdentifier,
          key: nodeKey,
          name: formData.name,
          description: formData.description,
          identifier: nodeIdentifier,
          ...inputProps,
          ...outputProps,
          parameters: { ...nodeParameters, executionSchema } as unknown as {
            [key: string]: string | string[];
          },
          data: {
            value: formData.name,
            onHandleClick: onHandleClick,
            identifier: nodeIdentifier,
            type: isGroupType ? NODE_TYPES.group : NODE_TYPES.passthrough,
          },
          position: { x: 0, y: 0 },
          type: isGroupType
            ? NODE_TYPES.group
            : origConfig?.type || DIAGRAM_NODE_CATEGORIES.diagramNode,
          parentId,
          extent: "parent",
          ...extraProps,
        };

        flowSettingsStore.addDiagramNode(
          reactFlow,
          newNode as FlowNode,
          notification,
          t
        );
      }

      handleClose();
    };

    const handleOnBack = () => {
      if (openFileBrowser) {
        setOpenFileBrowser(false);
      }
      if (nodeKey !== "") {
        setNodeKey("");
      }
      if (flowSettingsStore.isSuggestionsModalOpen) {
        flowSettingsStore.setSuggestionsModalOpen(false);
      }
    };

    const handleClose = () => {
      flowSettingsStore.setSelectedEdgeId();
      flowSettingsStore.setConditionalPlaceholderFocus(null);

      if (flowSettingsStore.nodeIdToEdit) {
        flowSettingsStore.setNodeId(null);
      }
      if (flowSettingsStore.isAddNodeOpened) {
        flowSettingsStore.setDesignerModalAddMode(false);
      }
      if (flowSettingsStore.isSuggestionsModalOpen) {
        flowSettingsStore.setSuggestionsModalOpen(false);
      }
      if (openFileBrowser) setOpenFileBrowser(false);
      setNodeKey("");
    };

    return (
      <CoreDrawer
        isOpen={isOpen}
        onClose={handleClose}
        title={drawerTitle}
        hasBackButton={hasBackButton}
        onBack={handleOnBack}
        isForNodes={true}
      >
        {!nodeKey && (
          <NodesListDisplay
            t={t}
            onSelect={handleSelectNode}
            loading={loading}
          />
        )}

        {nodeKey && (
          <AssetConfig
            t={t}
            type={NODE_ASSET_TYPE.node as unknown as ASSET_TYPES}
            onClose={handleClose}
            assetCategoryKey={nodeKey}
            list={diagramNodes as unknown as AssetConfiguration[]}
            selectedItemPosition={selectedItemPosition}
            openFileBrowser={openFileBrowser}
            setOpenFileBrowser={setOpenFileBrowser}
            shouldRefetchAsset={shouldRefetchAsset}
            setShouldRefetchAsset={setShouldRefetchAsset}
            setOpenConn={setOpenConn}
            onConfirm={handleOnConfirm}
            forDiagram={true}
          />
        )}

        <CreateConnection
          isOpen={openConn}
          onClose={onConnClose}
          hasBackButton
          onBack={onConnClose}
          setShouldRefetchAsset={setShouldRefetchAsset}
        />
      </CoreDrawer>
    );
  }
);
