import React, { FC, useState, useMemo, useEffect } from "react";
import { Group, Line, Rect } from "react-konva";
import { KonvaEventObject } from "konva/lib/Node";

import {
  DocumentLineItem,
  DocumentLineItemLine,
} from "../../../../../../types/interfaces";
import { Theme } from "@mui/material";
import CanvasHelper from "../../../../../../helper/canvas/canvasHelper";

interface MovingLineIndex {
  position: [[number, number], [number, number]];
  index: number;
}

interface Props {
  line: DocumentLineItemLine;
  theme: Theme;
  viewMode: boolean | null;
  setMovingLineIndex: (movingLine: MovingLineIndex | null) => void;
  movingLineIndex: MovingLineIndex | null;
  index: number;
  table: DocumentLineItem;
  mousePos: { x: number; y: number } | null;
  setLines: (
    lineItemIdentifier: string,
    line: DocumentLineItemLine,
    index: number
  ) => void;
  remakeColumns: (
    table: DocumentLineItem,
    index: number,
    newLine: DocumentLineItemLine
  ) => void;

  remakeRows: (
    table: DocumentLineItem,
    index: number,
    newLine: DocumentLineItemLine
  ) => void;
}

export const ZoneLine: FC<Props> = ({
  line,
  theme,
  index,
  setMovingLineIndex,
  movingLineIndex,
  setLines,
  table,
  mousePos,
  remakeColumns,
  remakeRows,
  viewMode,
}) => {
  const [isHovered, toggleHover] = useState(false);
  const [lineX, setLineX] = useState(line[0][0]);
  const [lineY, setLineY] = useState(line[0][1]);

  const startCoords = line[0];
  const endCoords = line[1];

  const isHorizontal = CanvasHelper.isHorizontal(line);

  if (movingLineIndex?.index === index && isHorizontal) {
    startCoords[1] = lineY;
    endCoords[1] = lineY;
  }

  if (movingLineIndex?.index === index && !isHorizontal) {
    startCoords[0] = lineX;
    endCoords[0] = lineX;
  }

  const hoverRectCoords = useMemo(() => {
    // Calculate width or height depending on orientation
    const lineDistance = Math.sqrt(
      Math.pow(startCoords[0] - endCoords[0], 2) +
        Math.pow(startCoords[1] - endCoords[1], 2)
    );

    // Line orientation
    const isHorizontal = CanvasHelper.isHorizontal(line);

    return {
      x: isHorizontal ? startCoords[0] : startCoords[0] - 5,
      y: isHorizontal ? startCoords[1] - 5 : startCoords[1],
      width: isHorizontal ? lineDistance : 10,
      height: isHorizontal ? 10 : lineDistance,
      isHorizontal: isHorizontal,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endCoords, startCoords]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getNewLine = () => {
    const x = mousePos?.x ?? 0;
    const y = mousePos?.y ?? 0;

    const newLine = isHorizontal
      ? ([
          [startCoords[0], y],
          [endCoords[0], y],
        ] as DocumentLineItemLine)
      : ([
          [x, startCoords[1]],
          [x, endCoords[1]],
        ] as DocumentLineItemLine);

    return { newLine, x, y };
  };

  const calculateNewLine = () => {
    if (movingLineIndex?.index === index) {
      const { newLine, x, y } = getNewLine();
      // if line is too close to another line, don't update
      const isBetween = isHorizontal
        ? CanvasHelper.isHorizontalLineBetween(newLine, table, index)
        : CanvasHelper.isVerticalLineBetween(newLine, table, index);

      if (isBetween) {
        setLines(table.type, newLine, index);
        isHorizontal ? setLineY(y) : setLineX(x);
      } else {
        finishMoveLine();
        setMovingLineIndex(null);
      }
    }
  };

  const finishMoveLine = () => {
    if (isHorizontal) {
      remakeRows(
        table,
        index,
        movingLineIndex?.position ?? [
          [0, 0],
          [0, 0],
        ]
      );
    } else {
      remakeColumns(
        table,
        index,
        movingLineIndex?.position ?? [
          [0, 0],
          [0, 0],
        ]
      );
    }
  };

  const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    toggleHover(true);
    const stage = e.target.getStage();
    if (stage && !viewMode) {
      const container = stage.container();
      container.style.cursor = hoverRectCoords.isHorizontal
        ? "ns-resize"
        : "ew-resize";
    }
  };

  const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    toggleHover(false);
    const stage = e.target.getStage();
    if (stage) {
      const container = stage.container();
      container.style.cursor = "default";
    }
  };

  useEffect(() => {
    calculateNewLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mousePos]);

  return (
    <Group
      onMouseDown={() => {
        if (viewMode) return;
        setMovingLineIndex({
          position: [startCoords, endCoords],
          index: index,
        });
      }}
      onMouseUp={() => {
        if (viewMode) return;
        finishMoveLine();
        setMovingLineIndex(null);
      }}
    >
      <Line
        points={[...(startCoords || []), ...(endCoords || [])]}
        closed
        strokeWidth={3}
        stroke={
          isHovered && !viewMode
            ? theme.palette.surface.dark
            : theme.palette.highlight.main
        }
      />

      {/* Used for hovering */}
      <Rect
        {...hoverRectCoords}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </Group>
  );
};
