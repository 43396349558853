// Module exports
import React, { useState, useEffect, useMemo } from "react";
import { useReactFlow } from "reactflow";
import { TFunction } from "i18next";
import { observer } from "mobx-react";
import { useApolloClient } from "@apollo/client";
import { isBoolean } from "lodash";

// Design imports
import { makeStyles } from "@mui/styles";
import { Box, IconButton, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// Internal imports
import CoreButton from "../../../../core/CoreButton";
import { CoreFormRenderer } from "../../../../core/CoreFormRenderer";
import {
  AssetCanDeleteCanEdit,
  AssetConfiguration,
  AssetTemplateConfiguration,
  AssetTemplateParameters,
  EXPORT_FIELDS,
  FormData,
  FormField,
  FlowInterface,
  Flow,
  FIELD_MAX_LENGTH,
  FlowNode,
  NodeRule,
} from "../../../../../types/interfaces";
import {
  FlowEnum,
  ASSET_TYPES,
  FIELD_TYPE,
  ASSET_PROPERTIES,
  NODE_ASSET_TYPE,
  SETTINGS_TYPES,
} from "../../../../../types/constants";
import { emailRegex } from "../../../../../types/regex";
import {
  NotificationTypes,
  useNotification,
} from "../../../../../context/useNotification";
import PreviewDialog from "./components/PreviewDialog";
import { useStores } from "../../../../../stores/StoresProvider";
import FileBrowser from "./components/FileBrowser";
import FlowHelper from "../../../../../helper/flowHelper";
import ConfigHelper from "../../../../../helper/configHelper";
import CoreNotificationBox from "../../../../core/CoreNotificationBox";
import { AppSuspense } from "../../../../main/AppSuspense";
import AssetInfoBox from "./AssetInfoBox";
import CoreTooltip from "../../../../core/CoreTooltip";
import SchemaPreviewerModal from "../../../../formFields/nodeList/SchemaPreviewerModal";
import JsonSchemaConverter from "../../../../../helper/jsonSchemaConverter";
import TextHelper from "../../../../../helper/textHelper";

const useStyles = makeStyles({
  footer: {
    marginTop: "auto",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    alignItems: "center",
    flexDirection: "row-reverse",
    paddingTop: "30px",
  },
  schemaBox: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "16px",
    paddingRight: "39px",
    alignItems: "center",
    gap: "10px",
  },
});

const getFieldsConfig = (
  t: TFunction,
  asset: AssetTemplateConfiguration,
  fieldsErrors: {
    [key: string]: string;
  },
  formData: FormData,
  flow: FlowInterface,
  flows: Flow[],
  onClickConnection: () => void,
  onClickRootPath: () => void,
  setFormData: (data: FormData) => void,
  canCreateConnection: boolean,
  canEdit?: AssetCanDeleteCanEdit,
  forDiagram?: boolean,
  existingNodes?: string[],
  existingSources?: string[],
  existingExports?: string[],
  hideNameAndDescription?: boolean,
  disableOption?: boolean
) => {
  const fields = asset?.parameters ?? [];
  const getValue = (key: string) => {
    if (key === FlowEnum.rootPath) {
      const path = formData[key] as string;
      if (path) {
        const parts = path.split("/").filter((part) => part);
        if (parts.length > 1) return `.../${parts[parts.length - 1]}`;
        if (parts.length === 1) return parts[0];
        return "/";
      }
    }
    return formData[key] ?? "";
  };

  const getExtraActions = (field: AssetTemplateParameters) => {
    if (field.allowAddConnection && canCreateConnection)
      return {
        extraActions: (
          <IconButton
            onClick={onClickConnection}
            disabled={disableOption || false}
          >
            <AddIcon />
          </IconButton>
        ),
      };

    if (field.key === FlowEnum.rootPath)
      return {
        extraActions: (
          <IconButton
            onClick={onClickRootPath}
            disabled={!formData.connectionId}
          >
            <AddIcon />
          </IconButton>
        ),
      };

    return {};
  };

  const notForDiagramConfig = [
    ...ConfigHelper.getFieldsConfig(t, fieldsErrors),
    {
      translationKey: "description",
      key: "description",
      type: FIELD_TYPE.input,
      errorText: fieldsErrors?.["description"],
      isMandatory: false,
      tooltipLocation: "input",
      props: {
        title: t("description"),
        description: t("description"),
        inputProps: {
          maxLength: FIELD_MAX_LENGTH.DESCRIPTION,
        },
      },
    },
  ];

  return [
    ...(!hideNameAndDescription
      ? !forDiagram
        ? notForDiagramConfig
        : ConfigHelper.getFieldsConfig(t, fieldsErrors)
      : []),
    ...fields.map((field) => {
      const parentKeys = Object.keys(field.parents ?? {});
      let render = true;
      parentKeys.forEach((key) => {
        if (!field.parents?.[key].includes(formData[key])) {
          render = false;
        }
      });

      if (!render) return {} as FormField;

      // Fields that are not visible during creation, only after saving the diagram
      if (field?.isVisibleDuringCreation === false) {
        if (
          !existingNodes?.includes(formData?.identifier as string) ||
          !existingSources?.includes(formData?.key as string) ||
          !existingExports?.includes(formData?.key as string)
        ) {
          return {} as FormField;
        }
      }

      if (formData[field.key] === undefined) {
        setFormData({ ...formData, [field.key]: field.value });
      }

      return {
        ...field,
        translationKey: field.name,
        key: field.key,
        type:
          (field.type as unknown as string) === FlowEnum.flow
            ? FIELD_TYPE.select
            : field.type,
        errorText: fieldsErrors?.[field.key],
        isMandatory: field.isMandatory || false,
        tooltipLocation: "input",
        canContainSpacesOnly: field.canContainSpacesOnly,
        ...getExtraActions(field),
        props: {
          title: field.name,
          readOnly:
            field.key === FlowEnum.rootPath ||
            (asset.nonEditableFields.includes(field.key) &&
              canEdit?.enabled === false),
          description: field.description,
          value: getValue(field.key),
        },
        ...ConfigHelper.getOptions(field, flow, flows, formData),
      };
    }),
  ] as FormField[];
};

interface Props {
  t: TFunction;
  onClose: () => void;
  assetCategoryKey: string;
  selectedItemPosition: number | undefined;
  type: ASSET_TYPES;
  list: AssetConfiguration[];
  setOpenConn: (open: boolean) => void;
  openFileBrowser: boolean;
  setOpenFileBrowser: (open: boolean) => void;
  isAlgorithmConfig?: boolean;
  shouldRefetchAsset: boolean;
  setShouldRefetchAsset: (value: boolean) => void;
  onConfirm?: (formData: FormData) => void;
  forDiagram?: boolean;
}

const AssetConfig: React.FC<Props> = observer(
  ({
    t,
    onClose,
    assetCategoryKey,
    selectedItemPosition,
    type,
    list,
    setOpenConn,
    openFileBrowser,
    setOpenFileBrowser,
    isAlgorithmConfig,
    setShouldRefetchAsset,
    shouldRefetchAsset,
    onConfirm,
    forDiagram = false,
  }) => {
    const { flowStore, userStore, flowSettingsStore } = useStores();
    const client = useApolloClient();
    const reactFlow = useReactFlow();

    const classes = useStyles();
    const theme = useTheme();
    const notification = useNotification();

    const [canEdit, setCanEdit] = useState<AssetCanDeleteCanEdit>();
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [formData, setFormData] = useState<FormData>({
      key: assetCategoryKey,
    });
    const [openDialog, setOpenDialog] = useState<
      AssetConfiguration | undefined
    >(undefined);

    const [assetConfig, setAssetConfig] =
      useState<AssetTemplateConfiguration>();
    const [fieldsErrors, setFieldsErrors] = useState<{
      [key: string]: string;
    }>({});
    const [showSchemaPreviewModal, setShowSchemaPreviewModal] = useState(false);

    const onClickConnection = () => setOpenConn(true);
    const onClickRootPath = () => setOpenFileBrowser(true);

    const canCreateConnection =
      userStore.currentUserPermissions?.can("create", "connections") ?? false;

    const existingNodes = (
      flowSettingsStore.flowDiagram?.specs?.nodes || []
    )?.map((node) => node.identifier || node.id);

    const existingSources = (
      flowSettingsStore.flow?.dataSource?.sources || []
    )?.map((source) => source?.key);

    const existingExports = (
      flowSettingsStore.flow?.dataExport?.dataExports ?? []
    )?.map((dataExport) => dataExport?.key);

    const nodes = useMemo(
      () => reactFlow.getNodes() as FlowNode[],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reactFlow, flowSettingsStore.layoutingNeeded]
    );

    const hideNameAndDescription = useMemo(() => {
      return (type as string) === SETTINGS_TYPES.contextSettings;
    }, [type]);

    const fieldShouldBeDisabled = useMemo(() => {
      return forDiagram && !flowSettingsStore.isDiagramEditable;
    }, [forDiagram, flowSettingsStore.isDiagramEditable]);

    const formattedFields = useMemo(
      () =>
        getFieldsConfig(
          t,
          assetConfig as AssetTemplateConfiguration,
          fieldsErrors,
          formData,
          flowSettingsStore.flow as FlowInterface,
          flowStore.flows,
          onClickConnection,
          onClickRootPath,
          setFormData,
          canCreateConnection,
          canEdit,
          forDiagram,
          existingNodes,
          existingSources,
          existingExports,
          hideNameAndDescription,
          fieldShouldBeDisabled
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        t,
        assetConfig?.parameters,
        fieldsErrors,
        formData,
        setOpenConn,
        canEdit,
        existingNodes,
        hideNameAndDescription,
      ]
    );

    const showPreviewButton =
      type === ASSET_TYPES.dataSource || type === ASSET_TYPES.dataExport;

    const getExportFields = (exportFields?: EXPORT_FIELDS) => {
      return FlowHelper.getExportFields(exportFields, flowSettingsStore.flow);
    };

    useEffect(() => {
      if (selectedItemPosition !== undefined && selectedItemPosition > -1) {
        const {
          key,
          identifier,
          name,
          description,
          canEdit,
          parameters: { exportFields, ...others },
        } = list[selectedItemPosition];
        setCanEdit(canEdit);

        setFormData({
          key,
          identifier,
          name,
          description,
          ...(exportFields && {
            exportFields: getExportFields(
              exportFields as unknown as EXPORT_FIELDS
            ),
          }),
          ...others,
        });
      }

      setLoading(true);
      flowStore
        .getAssetConfiguration(
          selectedItemPosition !== undefined
            ? list[selectedItemPosition]?.key
            : assetCategoryKey
        )
        .then((res) => {
          setAssetConfig(res.getAssetConfig);

          if (selectedItemPosition === undefined) {
            setFormData({
              ...res.getAssetConfig.parameters.reduce(
                (acc, field) => ({
                  ...acc,
                  [field.key]: field.value,
                  ...(field.type === FIELD_TYPE.exportFields && {
                    exportFields: getExportFields(),
                  }),
                }),
                {}
              ),
              name: res.getAssetConfig.name,
              description: res.getAssetConfig.description,
              ...formData,
            });
          }
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "configError"));
        })
        .finally(() => setLoading(false));
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFormChange = (value: FormData, key: string) => {
      if (key === FlowEnum.category) {
        setFormData({
          ...formData,
          ...value,
          labels: undefined,
        });
        return;
      }
      setFieldsErrors({
        ...fieldsErrors,
        [key]: "",
      });
      setFormData({ ...formData, ...value });
    };

    const formattedData = (): AssetConfiguration | undefined => {
      const { name, description, key, identifier, ...parameters } = formData;

      const assetConfiguration = {
        name,
        identifier,
        description,
        key: key || assetCategoryKey,
        type,
        parameters: {
          ...parameters,
          ...(parameters.exportFields
            ? {
                exportFields: FlowHelper.buildExportFields(parameters),
              }
            : null),
        },
      } as unknown as AssetConfiguration;

      const currentErrors = {} as { [key: string]: string };

      //Check if mandatory field is empty or has undefined value

      if (formattedFields) {
        formattedFields.forEach((config) => {
          if (
            config.isMandatory &&
            !isBoolean(formData[config.key]) &&
            FlowHelper.checkIfValueIsEmpty(
              formData[config.key],
              config.canContainSpacesOnly
            )
          ) {
            currentErrors[config.key] = t("validationFieldsError");
          }

          if (
            (config.key === "to" || config.key === "cc") &&
            !FlowHelper.checkIfValueIsEmpty(
              formData[config.key],
              config.canContainSpacesOnly
            ) &&
            !emailRegex.test(config?.props?.value as string)
          ) {
            currentErrors[config.key] = t("emailNotValid");
          }
        });
      }

      if (Object.keys(currentErrors).length > 0) {
        setFieldsErrors(currentErrors);
        return;
      } else {
        setFieldsErrors({});
      }

      return assetConfiguration;
    };

    const refetchAssetConfig = () => {
      setLoading(true);
      flowStore
        .getAssetConfiguration(
          selectedItemPosition !== undefined
            ? list[selectedItemPosition]?.key
            : assetCategoryKey
        )
        .then((res) => {
          setAssetConfig(res.getAssetConfig);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "configError"));
        })
        .finally(() => setLoading(false));
    };

    const handleUpdateFlow = (updatedAssets: AssetConfiguration[]) => {
      const assetType = isAlgorithmConfig ? ASSET_TYPES.algorithms : type;

      flowSettingsStore
        .updateFlow({
          [assetType]: {
            ...(flowSettingsStore.flow?.[assetType] ?? {}),
            [ASSET_PROPERTIES[type]]: updatedAssets,
          } as AssetConfiguration,
        } as unknown as FlowInterface)
        .then(() => {
          notification.success(t("updateFlowSuccess"));
          onClose();
          flowSettingsStore.triggerRefetchFlowSettings(true);
        })
        .catch((error: Error) => {
          notification.error(t(error?.message || "updateFlowError"));
        });
    };

    const handleConfirm = () => {
      //Used for view node details in node drawer
      if (forDiagram && !flowSettingsStore.isDiagramEditable) {
        flowSettingsStore.setNodeId(null);
        return;
      }

      const warningGroups = Object.keys(fieldsErrors)?.flatMap(
        (fieldErrorKey) => {
          return formattedFields
            ?.flatMap((field) => {
              if (fieldErrorKey === field.key) {
                return field.group;
              }
              return;
            })
            ?.filter((item) => item !== undefined);
        }
      );

      const groups = assetConfig?.groups?.flatMap((group) => {
        if (warningGroups?.includes(group.key)) {
          return {
            key: group.key as unknown as string,
            name: group.name,
            description: group.description || "",
            warning: t("mandatoryFieldsWarning") || "",
          };
        } else {
          return group;
        }
      });

      setAssetConfig({
        ...assetConfig,
        groups: groups,
      } as unknown as AssetTemplateConfiguration);

      const assetConfigurationData = formattedData();

      if (!assetConfigurationData) return;

      if (assetConfig?.refreshContextObjects) {
        formData.refreshContextObjects = assetConfig.refreshContextObjects;
      }

      if (onConfirm) {
        onConfirm(formData);
        return;
      }

      if (selectedItemPosition !== undefined) {
        // Used for edit existing asset
        const updatedAssets = list.map((asset, index) =>
          index === selectedItemPosition ? assetConfigurationData : asset
        );

        handleUpdateFlow(updatedAssets);
      } else {
        // Used for add new asset
        handleUpdateFlow([...(list || []), assetConfigurationData]);
      }
    };

    const handlePreviewDataSource = () => {
      const assetConfigurationData = formattedData();
      if (assetConfigurationData) {
        setOpenDialog(assetConfigurationData);
      }
    };

    useEffect(() => {
      if (openDialog) {
        setLoadingButton(true);
      } else {
        setLoadingButton(false);
      }
    }, [openDialog]);

    useEffect(() => {
      if (shouldRefetchAsset) {
        refetchAssetConfig();
        setShouldRefetchAsset(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRefetchAsset]);

    const handleTest = () => {
      const assetConfigurationData = formattedData();
      if (assetConfigurationData) {
        setLoadingButton(true);
        flowStore
          .dataExportTest(client, assetConfigurationData)
          .then(() => {
            notification.success(t("data_export_test_success"));
            setLoadingButton(false);
          })
          .catch((error: Error) => {
            notification.error(t(error?.message || "data_export_test_error"));
            setLoadingButton(false);
          });
      }
    };

    const handleTestAndPreview = () => {
      switch (type) {
        case ASSET_TYPES.dataSource:
          handlePreviewDataSource();
          break;
        case ASSET_TYPES.dataExport:
          handleTest();
          break;
        default:
          break;
      }
    };

    const onFileBrowserClose = () => {
      setOpenFileBrowser(false);
    };

    const isButtonDisabled = useMemo(() => {
      if (!forDiagram) {
        return loadingButton || flowSettingsStore.loadingUpdateFlow;
      }

      const conditionsMapping: { [key: string]: boolean } = {
        [NODE_ASSET_TYPE.initializeVariables]:
          !formData?.variables ||
          (formData?.variables as FormData[])?.length === 0,
        [NODE_ASSET_TYPE.conditional]:
          !formData?.rules || (formData?.rules as NodeRule[])?.length === 0,
        [NODE_ASSET_TYPE.whileIteration]:
          !formData?.rules || (formData?.rules as NodeRule[])?.length === 0,
      };

      return conditionsMapping?.[assetCategoryKey] || false;
    }, [
      assetCategoryKey,
      formData,
      forDiagram,
      loadingButton,
      flowSettingsStore.loadingUpdateFlow,
    ]);

    const handleClickCopySchema = () => {
      TextHelper.copyToClipboard(
        JSON.stringify(assetConfig?.output?.schema_, null, 4)
      );
      notification.info(t("schemaCopiedSuccess"));
    };

    const isOutputObjectOrList = useMemo(() => {
      const outputType = flowSettingsStore
        .getAllContextVariables()
        ?.find((variable) => variable.key === formData?.nodeOutput)?.type;

      return (
        formData?.nodeOutput &&
        JsonSchemaConverter.isObjectOrList(outputType as string) &&
        assetConfig?.output?.schema_ &&
        JsonSchemaConverter.isObjectOrList(assetConfig?.output?.type)
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.nodeOutput, assetConfig, nodes]);

    const onDelete = (keyToDelete: string) => {
      const updatedData = { ...formData };

      if (formData?.variables) {
        updatedData.variables = (formData?.variables as FormData[])?.filter(
          (item) => item.key !== keyToDelete
        );
      }

      if (formData?.rules) {
        updatedData.rules = (formData?.rules as NodeRule[])?.filter(
          (item) => item.key !== keyToDelete
        );
      }

      setFormData(updatedData);
    };

    return (
      <>
        {!openFileBrowser ? (
          <>
            {loading ? (
              <AppSuspense />
            ) : (
              <>
                <Box>
                  <Box>
                    {canEdit?.enabled === false && (
                      <CoreNotificationBox
                        description={t(canEdit?.message ?? "")}
                        type={NotificationTypes.info}
                      />
                    )}
                  </Box>

                  {forDiagram && (
                    <AssetInfoBox
                      name={assetConfig?.name as unknown as string}
                      description={
                        assetConfig?.description as unknown as string
                      }
                      nodeKey={assetCategoryKey}
                    />
                  )}

                  <CoreFormRenderer
                    fields={formattedFields as unknown as FormField[]}
                    translation={t}
                    onChange={onFormChange}
                    data={formData}
                    groups={assetConfig?.groups ?? []}
                    isViewMode={
                      type === (NODE_ASSET_TYPE.node as unknown as ASSET_TYPES)
                        ? !flowSettingsStore.isDiagramEditable
                        : false
                    }
                    onDelete={onDelete}
                    disableDiagramField={fieldShouldBeDisabled}
                  />

                  {isOutputObjectOrList && (
                    <Box className={classes.schemaBox}>
                      <CoreTooltip title={t("copyOutputSchema")}>
                        <IconButton
                          onClick={handleClickCopySchema}
                          style={{ color: theme.palette.neutral.main }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </CoreTooltip>
                      <CoreButton
                        onClick={() => setShowSchemaPreviewModal(true)}
                      >
                        {t("viewSchema")}
                      </CoreButton>
                    </Box>
                  )}
                </Box>

                <Box className={classes.footer}>
                  <CoreButton
                    variant={
                      forDiagram && !flowSettingsStore.isDiagramEditable
                        ? "outlined"
                        : "contained"
                    }
                    onClick={handleConfirm}
                    disabled={isButtonDisabled}
                    isLoading={
                      forDiagram ? false : flowSettingsStore.loadingUpdateFlow
                    }
                  >
                    {forDiagram && !flowSettingsStore.isDiagramEditable
                      ? t("close")
                      : selectedItemPosition !== undefined
                      ? t("edit")
                      : t("add")}
                  </CoreButton>
                  {showPreviewButton && (
                    <CoreButton
                      variant="outlined"
                      onClick={handleTestAndPreview}
                      isLoading={loadingButton}
                      disabled={loadingButton}
                    >
                      {t(`${type}_btn`)}
                    </CoreButton>
                  )}
                </Box>

                <SchemaPreviewerModal
                  t={t}
                  isOpen={showSchemaPreviewModal}
                  onClose={() => setShowSchemaPreviewModal(false)}
                  editorValue={JSON.stringify(
                    assetConfig?.output?.schema_,
                    null,
                    4
                  )}
                  modalMaxWidth="1200px"
                  editorHeight="550px"
                  copySchemaAction={handleClickCopySchema}
                />
              </>
            )}
          </>
        ) : (
          <FileBrowser
            onClose={onFileBrowserClose}
            connectionId={formData.connectionId as string}
            onFormChange={onFormChange}
            formData={formData}
          />
        )}
        <PreviewDialog open={openDialog} setOpenDialog={setOpenDialog} />
      </>
    );
  }
);

export default AssetConfig;
