import React, { FC } from "react";
import { observer } from "mobx-react";

import { IconButton, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useStores } from "../../../../../stores/StoresProvider";
import { NODE_ASSET_TYPE, NODE_TYPES } from "../../../../../types/constants";
import DiagramNodeDeleteActions from "./DiagramNodeDeleteActions";

interface Props {
  type: NODE_TYPES;
  nodeId: string;
  hasTarget: boolean;
}

const DiagramNodeActions: FC<Props> = observer(({ nodeId, hasTarget }) => {
  const theme = useTheme();
  const { flowSettingsStore } = useStores();

  const classes = makeStyles({
    editIcon: {
      fontSize: "8px",
      color: theme.palette.highlight.main,
    },
    editButton: {
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderTop: "none",
      borderRight: "none",
      width: "3px",
      height: "3px",
      borderRadius: "0",
      borderBottomLeftRadius: "2px",
    },
    editableActionsContent: {
      marginRight: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  })();

  const onViewActionClick = () => {
    if (nodeId !== NODE_ASSET_TYPE.startNode) {
      flowSettingsStore.setNodeId(nodeId);
    }
  };

  return (
    <div className={classes.editableActionsContent}>
      {flowSettingsStore.isDiagramEditable ? (
        <DiagramNodeDeleteActions identifier={nodeId} hasTarget={hasTarget} />
      ) : (
        <IconButton className={classes.editButton} onClick={onViewActionClick}>
          <VisibilityIcon className={classes.editIcon} />
        </IconButton>
      )}
    </div>
  );
});

export default DiagramNodeActions;
