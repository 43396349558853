import React, { FC } from "react";
import { observer } from "mobx-react";

import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { IconButton, Typography, useTheme } from "@mui/material";
import { useStores } from "../../../../stores/StoresProvider";
import { THEME_MODES } from "../../../../types/constants";

interface Props {
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
}

const ChatGptMessagesPagination: FC<Props> = observer(
  ({ totalPages, currentPage, onChangePage }) => {
    const theme = useTheme();
    const { mainStore } = useStores();
    const classes = makeStyles({
      icon: {
        width: "15px",
        height: "15px",
      },
      iconButton: {
        color:
          mainStore.currentTheme === THEME_MODES.light
            ? theme.palette.canvas.dark
            : theme.palette.primary.light,
        "&:hover": {
          color: theme.palette.highlight.main,
        },
        "&.Mui-disabled": {
          opacity: 0.5,
        },
      },
      pagination: {
        padding: "0 5px",
        color:
          mainStore.currentTheme === THEME_MODES.light
            ? theme.palette.canvas.dark
            : theme.palette.primary.light,
      },
    })();

    const handleClickBack = () => {
      if (currentPage > 0) onChangePage(currentPage - 1);
    };

    const handleClickForward = () => {
      if (currentPage < totalPages - 1) onChangePage(currentPage + 1);
    };

    return (
      <>
        <IconButton
          size="small"
          onClick={handleClickBack}
          disabled={currentPage === 0}
          className={classes.iconButton}
        >
          <ArrowBackIosIcon fontSize="small" className={classes.icon} />
        </IconButton>

        <Typography variant="caption" className={classes.pagination}>{`${
          currentPage + 1
        } / ${totalPages}`}</Typography>

        <IconButton
          size="small"
          onClick={handleClickForward}
          disabled={currentPage === totalPages - 1}
          className={classes.iconButton}
        >
          <ArrowForwardIosIcon fontSize="small" className={classes.icon} />
        </IconButton>
      </>
    );
  }
);

export default ChatGptMessagesPagination;
