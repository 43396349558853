import React, { FC, useEffect, useState, useMemo, useCallback } from "react";
import { TFunction } from "i18next";
import { useApolloClient } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import CoreDrawer from "../../../core/CoreDrawer";
import CoreAccordion from "../../../core/CoreAccordion";
import { DocumentHistory } from "../../../../types/interfaces";
import { useStores } from "../../../../stores/StoresProvider";
import { useNotification } from "../../../../context/useNotification";
import { Status } from "../../../core/Status";
import HistoryEvent from "./HistoryEvent";
import HistoryEventDetails from "./HistoryEventDetails";
import HistoryEventFilterBox from "./HistoryEventFilterBox";

const useStyles = makeStyles({
  loading: { alignSelf: "center" },
  drawerSubtitle: {
    marginBottom: "20px",
  },
  noDataAvailable: { textAlign: "center" },
  statusContainer: {
    transform: "rotate(180deg)",
  },
  boxAccordion: {
    display: "flex",
    padding: "10px 0 10px 0",
  },
  optionsContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: "12px",
  },
});

interface Props {
  translation: TFunction;
  isOpened: boolean;
  onClose: () => void;
}

const HistoryDrawer: FC<Props> = ({ translation, isOpened, onClose }) => {
  const classes = useStyles();
  const notification = useNotification();
  const { documentStore } = useStores();
  const apolloClient = useApolloClient();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState<DocumentHistory[]>([]);
  const [eventToPreview, setEventToPreview] = useState<string | null>(null);
  const [showEventsDetails, setShowEventsDetails] = useState<boolean>(false);
  const [selectedFilterEvents, setSelectedFilterEvents] = useState<
    { id: string; label: string }[]
  >([]);

  useEffect(() => {
    if (isOpened) {
      setIsDataLoading(true);
      setSelectedFilterEvents([]);

      documentStore
        .getDocumentHistory(apolloClient)
        .then((history) => {
          const result = history.map((item, index) => ({
            ...item,
            eventIdentifier: `${item.eventIdentifier}-${index}`,
          }));
          setData(result);
          setIsDataLoading(false);
        })
        .catch((error: Error) => {
          notification.error(
            translation(error?.message || "history_fetch_all_error")
          );
          setIsDataLoading(false);
          setData([]);
        });
    }
  }, [apolloClient, documentStore, isOpened, notification, translation]);

  const updateSelectedFilterEvents = useCallback(
    (updatedList: { id: string; label: string }[]) =>
      setSelectedFilterEvents(updatedList),
    []
  );

  const filteredEvents = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }

    if (selectedFilterEvents?.length > 0) {
      return data?.filter((historyItem) =>
        selectedFilterEvents.some((item) => item.id === historyItem.actionCode)
      );
    }

    return data;
  }, [selectedFilterEvents, data]);

  if (isDataLoading) {
    return (
      <CoreDrawer
        isOpen={isOpened}
        onClose={onClose}
        title={translation("history_drawer_title")}
      >
        <CircularProgress className={classes.loading} size={30} />
      </CoreDrawer>
    );
  }

  if (!data || data.length === 0) {
    return (
      <CoreDrawer
        isOpen={isOpened}
        onClose={onClose}
        title={translation("history_drawer_title")}
      >
        <Typography className={classes.noDataAvailable}>
          {translation("history_no_history_available")}
        </Typography>
      </CoreDrawer>
    );
  }

  const handleOnClose = () => {
    if (showEventsDetails && eventToPreview) setEventToPreview(null);
    setShowEventsDetails(false);
    onClose();
  };

  return (
    <CoreDrawer
      isOpen={isOpened}
      onClose={handleOnClose}
      hasBackButton={showEventsDetails}
      onBack={() => {
        setShowEventsDetails(false);
        setEventToPreview(null);
      }}
      title={
        !showEventsDetails
          ? translation("history_drawer_title")
          : translation("history_item_preview_drawer_title")
      }
    >
      {!showEventsDetails ? (
        <>
          <Typography className={classes.drawerSubtitle}>
            {translation("history_drawer_subtitle")}
          </Typography>

          <HistoryEventFilterBox
            translation={translation}
            selectedFilterEvents={selectedFilterEvents}
            setSelectedFilterEvents={updateSelectedFilterEvents}
          />

          <Box className={classes.optionsContainer}>
            {!filteredEvents || filteredEvents?.length === 0 ? (
              <Typography className={classes.noDataAvailable}>
                {translation("history_no_history_available")}
              </Typography>
            ) : (
              filteredEvents.map((history) => {
                const isDataMissing =
                  !history.data || Object.keys(history.data).length === 0;

                return (
                  <CoreAccordion
                    key={history.eventIdentifier}
                    {...(isDataMissing ? { expanded: true } : {})}
                    showAccordionDetails={false}
                    expandIconPosition="left"
                    customAccordionBoxStyle={classes.boxAccordion}
                    summaryChildren={
                      <HistoryEvent
                        translation={translation}
                        history={history}
                        displayEventDetails={() => {
                          setEventToPreview(history.eventIdentifier);
                          setShowEventsDetails(true);
                        }}
                      />
                    }
                    customExpandIcon={
                      <Box className={classes.statusContainer}>
                        <Status
                          type={history.actionStatus}
                          tooltip={{
                            message: history?.statusDetails?.message,
                          }}
                        />
                      </Box>
                    }
                    accordionCustomBackground={true}
                  />
                );
              })
            )}
          </Box>
        </>
      ) : (
        showEventsDetails &&
        !!eventToPreview && (
          <HistoryEventDetails
            translation={translation}
            eventIdentifier={eventToPreview}
          />
        )
      )}
    </CoreDrawer>
  );
};

export default HistoryDrawer;
